<div class="card mt-3">
    <div class="card-header">
        <h5>Reviews</h5>
        <div class="ms-auto">
            <button class="btn btn-primary" (click)="updateProjectStatus();">
                <fa-icon class="button-icon-left" [icon]="faCheckCircle"></fa-icon>
                <span>Complete Project</span>
            </button>
        </div>
    </div>
    <div class="card-body">
        <p class="card-text">Reviews are important for any business.<br>They can help your business appear at the top of the search results.<br>Consider sending your client an email to remind them.</p>
        <button type="button" class="btn btn-secondary" (click)="openSendEmailModal();">
            <fa-icon class="button-icon-left" [icon]="faEnvelope"></fa-icon>
            <span>Send</span>
        </button>
    </div>
</div>