import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faAngleLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalRef, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { Observable, debounceTime, distinctUntilChanged, merge, Subject, switchMap, map } from 'rxjs';
import { AlertService } from '../alert.service';
import { AuthService } from '../auth.service';
import { Client } from '../client';
import { ClientService } from '../client.service';
import { NewSiteModalComponent } from '../new-site-modal/new-site-modal.component';
import { ProjectService } from '../project.service';
import { Site } from '../site';
import { SiteService } from '../site.service';
import { User } from '../user';
import { UserService } from '../user.service';

@Component({
  selector: 'app-new-project-modal',
  templateUrl: './new-project-modal.component.html',
  styleUrls: ['./new-project-modal.component.css']
})
export class NewProjectModalComponent implements OnInit {

  // Modals
  private modalReference: NgbModalRef;

  // Properties
  projectForm: UntypedFormGroup;
  @Input() clientId?: string;
  @Input() siteId?: string;
  sites: Site[] = [];
  users: User[] = [];
  showAddSiteButton: boolean = false;

  // Clients
  clientTypeaheadInput: (text$: Observable<string>) => Observable<Client[]>;
  clientTypeaheadInputFocus$ = new Subject<string>();
  clientResultFormatter = (client: Client) => client.name;
  selectedClient!: Client;

  // Project Names
  projectNameTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  projectNameTypeaheadInputFocus$ = new Subject<string>();

  // Project Categories
  projectCategoryTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  projectCategoryTypeaheadInputFocus$ = new Subject<string>();

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faPlus = faPlus;

  constructor(private clientService: ClientService,
    private siteService: SiteService,
    private userService: UserService,
    private projectService: ProjectService,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router,
    private modalService: NgbModal,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.projectForm = new UntypedFormGroup({
      client: new UntypedFormControl(),
      site: new UntypedFormControl(),
      salesperson: new UntypedFormControl(),
      name: new UntypedFormControl(),
      category: new UntypedFormControl(),
      year: new UntypedFormControl(dayjs().get('year'))
    });
    this.projectForm.controls.site.disable();
    this.getUsers();
    this.prepareClientTypeahead();
    this.prepareProjectNameTypeahead();
    this.prepareProjectCategoryTypeahead();
    if (this.clientId) this.getClient();
  }

  // Open New Site Modal
  openNewSiteModal(): void {
    this.modalReference = this.modalService.open(NewSiteModalComponent);
    this.modalReference.componentInstance.client = this.selectedClient;
    this.modalReference.componentInstance.siteCreated.subscribe(() => {
      this.getSites();
    });
  }

  /* ----- Users ----- */

  // Get Users
  getUsers(): void {
    this.userService.getUsers().subscribe(async (users) => {
      this.users = users.filter(user => !user.isArchived);
      const currentUser = await this.authService.getCurrentUser();
      this.projectForm.controls.salesperson.setValue(currentUser.id);
    });
  }

  /* ----- Clients ----- */

  // Get Client
  private getClient(): void {
    this.clientService.getClient(this.clientId).subscribe((client) => {
      this.selectedClient = client;
      this.projectForm.controls.client.setValue(this.selectedClient.name);
      this.getSites();
    });
  }

  // Get Clients
  private getClients(term: string): Observable<Client[]> {
    const params = {
      searchTerm: term,
      sortBy: 'client_name',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0
    };
    return this.clientService.getClients(params).pipe(map((res) => { return res.clients; }));
  }

  // Prepare Client Typeahead
  private prepareClientTypeahead(): void {
    this.clientTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.clientTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getClients((term.length == 0) ? null : term);
      }));
    }
  }

  // Select Client
  selectClient(event: NgbTypeaheadSelectItemEvent): void {
    event.preventDefault();
    this.selectedClient = event.item;
    this.projectForm.controls.client.setValue(this.selectedClient.name);
    this.getSites();
  }

  /* ----- Sites ----- */

  // Get Sites
  getSites(): void {
    const params = {
      searchTerm: null,
      sortBy: 'site_name',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:client_id': JSON.stringify([this.selectedClient.id])
    };
    this.siteService.getSites(params).subscribe((res) => {
      this.sites = res.sites;
      if (this.sites.length > 0) {
        this.projectForm.controls.site.enable();
        if (this.siteId) this.projectForm.controls.site.setValue(this.siteId!);
        else this.projectForm.controls.site.setValue(this.sites[0].id);
        this.showAddSiteButton = false;
      } else {
        this.projectForm.controls.site.disable();
        this.alertService.showWarningAlert('No Sites Available');
        this.showAddSiteButton = true;
      }
    });
  }

  // Get Project Names
  private getProjectNames(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'project_name',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'project_name'
    };
    return this.projectService.getDistinctColumnValues(params);
  }

  // Prepare Project Name Typeahead
  private prepareProjectNameTypeahead(): void {
    this.projectNameTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.projectNameTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getProjectNames((term.length == 0) ? null : term);
      }));
    }
  }

  // Get Project Categories
  private getProjectCategories(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'project_category',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'project_category'
    };
    return this.projectService.getDistinctColumnValues(params);
  }

  // Prepare Project Category Typeahead
  private prepareProjectCategoryTypeahead(): void {
    this.projectCategoryTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.projectCategoryTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getProjectCategories((term.length == 0) ? null : term);
      }));
    }
  }

  // Add Project
  addProject(): void {
    if (this.projectForm.valid) {
      const project = {
        siteId: this.projectForm.value.site,
        salesperson: this.projectForm.value.salesperson,
        name: this.projectForm.value.name,
        category: this.projectForm.value.category,
        year: this.projectForm.value.year
      };
      this.projectService.addProject(project).subscribe((projectId) => {
        this.modal.close();
        this.router.navigateByUrl(`/projects/${projectId}`);
      });
    } else {
      this.projectForm.markAllAsTouched();
    }
  }

  // Project Form Accessors
  get site() { return this.projectForm.controls.site; }
  get name() { return this.projectForm.controls.name; }
  get category() { return this.projectForm.controls.category; }
  get year() { return this.projectForm.controls.year; }
}
