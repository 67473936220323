import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sms-call-filter-modal',
  templateUrl: './sms-call-filter-modal.component.html',
  styleUrls: ['./sms-call-filter-modal.component.css']
})
export class SmsCallFilterModalComponent {
  @Output() filtersApplied = new EventEmitter<any>();

  // properties
  filterForm: FormGroup;
  showStatus: boolean = false;
  status:any = [];
  isSms: boolean = false;
  title: string = 'Filter Call History';

  // font
  faCalendar = faCalendar;

  constructor(private fb: FormBuilder, private modalService: NgbModal, private dateParserFormatter: NgbDateParserFormatter) {
    this.filterForm = this.fb.group({
      startDate:[null],
      endDate:[null],
      contactNumber: [null],
      status: [null],
      dateSent: [null],
      fromContact: [null],
      toContact: [null]
    });
  }


  selectStatus(status: string) {
    this.filterForm.get('status')?.setValue(status);
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  applyFilters() {
    this.filtersApplied.emit(this.filterForm.value);
    this.closeModal();
  }
}
