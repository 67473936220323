import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { faAngleLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from '../alert.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent implements OnInit {

  // Properties
  passwordForm: UntypedFormGroup;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faSave = faSave;

  constructor(private userService: UserService,
    private alertService: AlertService,
    public location: Location) { }

  ngOnInit(): void {
    this.passwordForm = new UntypedFormGroup({
      currentPassword: new UntypedFormControl(),
      newPassword: new UntypedFormControl(),
      confirmNewPassword: new UntypedFormControl()
    });
    this.passwordForm.controls.confirmNewPassword.setValidators(this.passwordMatchValidator());
  }

  // Password Match Validator
  private passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      return (control.value != this.passwordForm.value.newPassword) ? { doesNotMatch: true } : null;
    }
  }

  // Change User Password
  changeUserPassword(): void {
    if (this.passwordForm.valid) {
      const data = {
        currentPassword: this.passwordForm.value.currentPassword,
        newPassword: this.passwordForm.value.newPassword,
        confirmNewPassword: this.passwordForm.value.confirmNewPassword
      };
      this.userService.changeUserPassword(data).subscribe(() => {
        this.alertService.showSuccessAlert('Password Updated');
        this.resetPasswordForm();
      });
    } else {
      this.passwordForm.markAllAsTouched();
    }
  }

  // Reset Password Form
  private resetPasswordForm(): void {
    this.passwordForm.reset();
    this.passwordForm.controls.currentPassword.setValue(null);
    this.passwordForm.controls.newPassword.setValue(null);
    this.passwordForm.controls.confirmNewPassword.setValue(null);
  }

  // Password Form Accessors
  get currentPassword() { return this.passwordForm.controls.currentPassword; }
  get newPassword() { return this.passwordForm.controls.newPassword; }
  get confirmNewPassword() { return this.passwordForm.controls.confirmNewPassword; }

  /* ----- Table Configurations ----- */

  // Reset Table Configurations
  resetTableConfigurations(): void {
    localStorage.clear();
  }
}
