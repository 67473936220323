import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectPrepStatus'
})
export class ProjectPrepStatusPipe implements PipeTransform {

  transform(status: string): string {
    switch (status) {
      case 'CANCELED':
        return 'Canceled';
      case 'PENDING':
        return 'Pending';
      case 'PULLING':
        return 'Need to Pull';
      case 'TO BE ORDERED':
        return 'To Be Ordered';
      case 'TO-BE-ORDERED':
        return 'Need to Order';
      case 'ORDERED':
        return 'Ordered';
      case 'CHECKED IN':
      case 'CHECK-IN-PULLING':
      case 'CHECK-IN-ORDERED':
        return 'Checked In';
      default:
        return 'Unknown';
    }
  }
}
