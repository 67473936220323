<div class="row flex-column mt-2">
    <div class="col">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">View</h3>
                <h6 class="card-subtitle text-muted">These settings affect the information displayed on the Invoice View
                    page.</h6>
            </div>
        </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col-lg-4">
        <div class="card">
            <div class="card-header d-flex">
                <h5 class="card-title my-auto mb-auto">Business Information</h5>
                <button type="button" class="btn btn-primary ms-auto" (click)="updateBusinessInformation();">
                    <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                    <span>Save</span>
                </button>
            </div>
            <div class="card-body">
                <form [formGroup]="businessInformationForm">
                    <div class="mb-3">
                        <label for="INVOICE_VIEW_BUSINESS_NAME">Name</label>
                        <input id="INVOICE_VIEW_BUSINESS_NAME" type="text" class="form-control"
                            placeholder="Appleseed Contractors" formControlName="name" maxlength="150" required
                            [class.is-invalid]="businessName.invalid && (businessName.dirty || businessName.touched)">
                        <div *ngIf="businessName.invalid && (businessName.dirty || businessName.touched)">
                            <div *ngIf="businessName.errors.required" class="input-validation-msg">Name is required.
                            </div>
                            <div *ngIf="businessName.errors.maxlength" class="input-validation-msg">Name cannot exceed
                                150 characters.</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="INVOICE_VIEW_BUSINESS_EMAIL">Email</label>
                        <input id="INVOICE_VIEW_BUSINESS_EMAIL" type="text" class="form-control"
                            placeholder="jappleseed@example.com" formControlName="email" autocapitalize="off"
                            maxlength="320"
                            pattern="^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$"
                            required
                            [class.is-invalid]="businessEmail.invalid && (businessEmail.dirty || businessEmail.touched)">
                        <div *ngIf="businessEmail.invalid && (businessEmail.dirty || businessEmail.touched)">
                            <div *ngIf="businessEmail.errors.required" class="input-validation-msg">Email is required.
                            </div>
                            <div *ngIf="businessEmail.errors.maxlength" class="input-validation-msg">Email cannot exceed
                                320.</div>
                            <div *ngIf="businessEmail.errors.pattern" class="input-validation-msg">Email is formatted
                                incorrectly.</div>
                        </div>
                    </div>
                    <div>
                        <label for="INVOICE_VIEW_BUSINESS_PHONE">Phone</label>
                        <input id="INVOICE_VIEW_BUSINESS_PHONE" type="tel" class="form-control" appPhoneMask
                            placeholder="(000) 000-0000" formControlName="phone" maxlength="14"
                            pattern="^\((\d{3})\)\s(\d{3})-(\d{4})"
                            [class.is-invalid]="businessPhone.invalid && (businessPhone.dirty || businessPhone.touched)">
                        <div *ngIf="businessPhone.invalid && (businessPhone.dirty || businessPhone.touched)">
                            <div *ngIf="businessPhone.errors.required" class="input-validation-msg">Phone is required.
                            </div>
                            <div *ngIf="businessPhone.errors.maxlength" class="input-validation-msg">Phone cannot exceed
                                14 characters.</div>
                            <div *ngIf="businessPhone.errors.pattern" class="input-validation-msg">Phone is formatted
                                incorrectly.</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-lg-4 mt-lg-0 mt-2">
        <div class="card">
            <div class="card-header d-flex">
                <h5 class="card-title my-auto mb-auto">Business Address</h5>
                <button type="button" class="btn ms-auto" [class.btn-primary]="displayAddress"
                    [class.btn-danger]="!displayAddress" (click)="toggleDisplayBusinessAddress();">
                    <fa-icon [icon]="(displayAddress) ? faToggleOn : faToggleOff"></fa-icon>
                </button>
                <button type="button" class="btn btn-primary ms-2" (click)="updateBusinessAddress();">
                    <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                    <span>Save</span>
                </button>
            </div>
            <div class="card-body">
                <app-address-form [form]="businessAddressForm"></app-address-form>
            </div>
        </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col-lg-8">
        <div class="card">
            <form [formGroup]="invoiceTermsForm">
                <div class="card-header d-flex">
                    <h5 class="card-title my-auto mb-auto">Terms & Conditions</h5>
                    <button type="button" class="btn btn-primary ms-auto" (click)="setInvoiceTerms();">
                        <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                        <span>Save</span>
                    </button>
                </div>
                <div class="card-body">
                    <div class="mt-2 mb-0">
                        <div class="row">
                            <quill-editor #editor [sanitize]="true" formControlName="body"
                                [styles]="{height: '200px', padding: 0}"></quill-editor>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>