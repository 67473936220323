import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { faAngleLeft, faAngleRight, faCalendar, faFileDownload, faRotate } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { ProjectService } from '../project.service';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-reports-at-a-glance',
  templateUrl: './reports-at-a-glance.component.html',
  styleUrls: ['./reports-at-a-glance.component.css']
})
export class ReportsAtAGlanceComponent implements OnInit {

  // Properties
  reportData: any;
  selectedYear: string;
  years: string[] = [];

  isReportLoading: boolean = true;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  faCalendar = faCalendar;
  faFileDownload = faFileDownload;
  faRotate = faRotate;

  constructor(private reportService: ReportService,
    private projectService: ProjectService,
    public location: Location) { }

  ngOnInit(): void {
    this.getDistinctProjectYears();
  }

  // Year Selected
  yearSelected(year: string): void {
    this.selectedYear = year;
    this.getAtAGlanceReport();
  }

  // Get Distinct Project Years
  private getDistinctProjectYears(): void {
    const params = {
      searchTerm: null,
      sortBy: 'p.project_year',
      sortDirection: 'DESC',
      limit: null,
      offset: null,
      column: 'p.project_year'
    };
    this.projectService.getDistinctColumnValues(params).subscribe((values) => {
      this.years = values.map((value) => { return value.toString(); });
      if (this.years.length > 0) {
        const currentYear = dayjs().year().toString();
        this.selectedYear = this.years.includes(currentYear) ? currentYear : this.years[0];
        this.getAtAGlanceReport();
      }
    });
  }

  // Get At A Glance Report
  private getAtAGlanceReport(): void {
    this.reportService.getAtAGlanceReport(this.selectedYear).subscribe((reportData) => {
      this.reportData = reportData;
      this.isReportLoading = false;
    });
  }
}
