<form [formGroup]="form">
    <div class="row">
        <div class="col mb-3">
            <label for="ADDRESS_STREET">Street</label>
            <input #addressTypeahead="ngbTypeahead" id="ADDRESS_STREET" type="text" class="form-control" formControlName="street" placeholder="123 Main Street" maxlength="150" required [ngbTypeahead]="addressTypeaheadInput" [resultFormatter]="addressResultFormatter" (selectItem)="addressSelected($event);" [class.is-invalid]="street.invalid && (street.dirty || street.touched)">
            <div *ngIf="street.invalid && (street.dirty || street.touched)">
                <div *ngIf="street.errors.required" class="input-validation-msg">Street is required.</div>
                <div *ngIf="street.errors.maxlength" class="input-validation-msg">Street cannot exceed 150 characters.</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 pe-0 mb-3" [class.col-md-6]="isCompact && county">
            <label for="ADDRESS_CITY">City</label>
            <input id="ADDRESS_CITY" type="text" class="form-control" formControlName="city" placeholder="Some City" maxlength="150" required [class.is-invalid]="city.invalid && (city.dirty || city.touched)">
            <div *ngIf="city.invalid && (city.dirty || city.touched)">
                <div *ngIf="city.errors.required" class="input-validation-msg">City is required.</div>
                <div *ngIf="city.errors.maxlength" class="input-validation-msg">City cannot exceed 150 characters.</div>
            </div>
        </div>
        <div *ngIf="county" class="col-12 mb-3" [class.col-md-6]="isCompact">
            <label for="ADDRESS_COUNTY">County</label>
            <input id="ADDRESS_COUNTY" type="text" class="form-control" formControlName="county" placeholder="Some County" maxlength="150" [class.is-invalid]="county.invalid && (county.dirty || county.touched)">
            <div *ngIf="county.invalid && (county.dirty || county.touched)">
                <div *ngIf="county.errors.maxlength" class="input-validation-msg">County cannot exceed 150 characters.</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 mb-md-0 mb-3">
            <div>
                <label for="ADDRESS_STATE">State</label>
                <select id="ADDRESS_STATE" class="form-select" formControlName="state" appForbiddenValue="SAS" required [class.is-invalid]="state.invalid && (state.dirty || state.touched)">
                    <option value="SAS">Select a State</option>
                    <option *ngFor="let state of states" [value]="state.abbreviation">{{state.name}}</option>
                </select>
                <div *ngIf="state.invalid && (state.dirty || state.touched)">
                    <div *ngIf="state.errors.required || state.errors.forbiddenValue" class="input-validation-msg">State is required.</div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div>
                <label for="ADDRESS_POSTAL_CODE">Postal Code</label>
                <input id="ADDRESS_POSTAL_CODE" type="text" class="form-control" formControlName="postalCode" placeholder="00000" maxlength="15" required [class.is-invalid]="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
                <div *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
                    <div *ngIf="postalCode.errors.required" class="input-validation-msg">Postal Code is required.</div>
                    <div *ngIf="postalCode.errors.maxlength" class="input-validation-msg">Postal Code cannot exceed 15 characters.</div>
                </div>
            </div>
        </div>
    </div>
</form>