<nav class="navbar navbar-expand-md navbar-dark bg-primary fixed-top">
  <a class="navbar-brand" href="#">Tinsel CRM</a>
  <button class="navbar-toggler" type="button" (click)="toggle();" data-target="#MOBILE_NAVBAR" aria-controls="MOBILE_NAVBAR" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div id="MOBILE_NAVBAR" class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
    <ul class="navbar-nav me-auto nav-div">
      <li class="nav-item">
        <a class="nav-link" routerLink="/dashboard" routerLinkActive="active" (click)="toggle();">Dashboard</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/clients" routerLinkActive="active" (click)="toggle();">Clients</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/contact-list" routerLinkActive="active" (click)="toggle();">Contacts</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/conversation" routerLinkActive="active" (click)="toggle();">Communications</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/projects" routerLinkActive="active" (click)="toggle();">Projects</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/invoices" routerLinkActive="active" (click)="toggle();">Invoices</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/inventory" routerLinkActive="active" (click)="toggle();">Inventory</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/projectPrepOverview" routerLinkActive="active" (click)="toggle();">Inventory Prep</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/schedule" routerLinkActive="active" (click)="toggle();">Schedule</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/projectGlance" routerLinkActive="active" (click)="toggle();">Project Glance</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/timeClock" routerLinkActive="active" (click)="toggle();">Time Clock</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/reports" routerLinkActive="active" (click)="toggle();">Reports</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://support.tinselcrm.com" target="_blank" routerLinkActive=active (click)="toggle();">Help</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/helpdesk" routerLinkActive="active" (click)="toggle();">Help Desk</a>
      </li>
      <li *ngIf="isAuthorized()" class="nav-item">
        <a class="nav-link" routerLink="/settings" routerLinkActive="active" (click)="toggle();">Settings</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/userTimeClock" routerLinkActive="active" (click)="toggle();">User Time Clock</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/userSettings" routerLinkActive="active" (click)="toggle();">User Settings</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="logout();" routerLinkActive="active">Sign Out</a>
      </li>
    </ul>
  </div>
</nav>
