<div class="row flex-column mt-2">
    <div class="col">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">General</h3>
                <h6 class="card-subtitle text-muted">Manage your subscriptions, billing information, and payment
                    methods.</h6>
            </div>
        </div>
    </div>
</div>

<div class="banner mt-2" *ngIf="cardOnFile && subscriptions && subscriptions[0]?.monthDelinquent > 1">Your account has not been paid in <strong>{{
    subscriptions[0].monthDelinquent | number: '1.0-0' }} months. </strong> Kindly update your card on file to be able to continue using Tinsel CRM. Once updated,
    <strong> you will be charged with the total amount of {{ subscriptions[0]?.amountOverdue | currency: 'USD'
        }}</strong>. To cancel your
    subscription, please submit a support ticket on the Help Desk or email us at <a href='mailto:support@tinselcrm.com'>support@tinselcrm.com</a>.
</div>

<div class="row mt-2">
    <div class="col-lg-4">
        <app-settings-subscriptions-list [subscriptions]="subscriptions"></app-settings-subscriptions-list>
    </div>
    <div class="col-lg-4 mt-lg-0 mt-2">
        <div class="card">
            <div class="card-header d-flex">
                <h5 class="card-title my-auto mb-auto">Billing Information</h5>
                <button type="button" class="btn btn-primary ms-auto" (click)="updateBillingInformation();">
                    <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                    <span>Save</span>
                </button>
            </div>
            <div class="card-body">
                <form [formGroup]="billingInformationForm">
                    <div class="mb-3">
                        <label for="BILLING_NAME">Name</label>
                        <input id="BILLING_NAME" class="form-control" formControlName="name"
                            placeholder="John Appleseed" required maxlength="150"
                            [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
                        <div *ngIf="name.invalid && (name.dirty || name.touched)">
                            <div *ngIf="name.errors.required" class="input-validation-msg">Name is required.</div>
                            <div *ngIf="name.errors.maxlength" class="input-validation-msg">Name cannot exceed 150
                                characters.</div>
                        </div>
                    </div>
                </form>
                <app-address-form [form]="billingInformationForm"></app-address-form>
            </div>
        </div>
    </div>
    <div class="col-lg-4 mt-lg-0 mt-2">
        <app-settings-subscriptions-payment-options></app-settings-subscriptions-payment-options>
    </div>
</div>
<div class="row mt-2">
    <div class="col-lg-4 mt-lg-0 mt-2">
        <app-settings-subscriptions-invoices></app-settings-subscriptions-invoices>
    </div>
    <div class="col-lg-4"></div>
    <div class="col-lg-4"></div>
</div>