import { Component, Input } from '@angular/core';
import * as Model from '../sms-call';

@Component({
  selector: 'app-numbers',
  templateUrl: './numbers.component.html',
  styleUrls: ['./numbers.component.css']
})
export class NumbersComponent {
  @Input() phoneNumbers: Model.OrgNumber[];
  @Input() isMobile: boolean = false;
}
