import { Component, HostBinding, OnInit } from '@angular/core';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'app-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.css']
})
export class NotificationBannerComponent implements OnInit {

  // Properties
  @HostBinding('style.display') private display: string;

  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.notificationService.notificationBannerUpdated.subscribe((event) => {
      if (event.show) this.display = 'block';
      else this.display = 'none';
    });
  }

  // Reload Page
  reloadPage(): void {
    location.reload();
  }
}
