import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureItem } from './feature-item';

@Injectable({
  providedIn: 'root'
})
export class FeatureItemService {

  constructor(private http: HttpClient) { }

  // Add Feature Item
  addFeatureItem(featureItem: any): Observable<any> {
    return this.http.post<any>(`/featureItems`, featureItem);
  }

  // Update Feature Item
  updateFeatureItem(featureItem: any): Observable<any> {
    return this.http.put<any>(`/featureItems/${featureItem.id}`, featureItem);
  }

  // Update Feature Item Property
  updateFeatureItemProperty(featureItemId: string, property: string, value: any): Observable<any> {
    return this.http.put<any>(`/featureItems/${featureItemId}/${property}`, { value: value });
  }

  // Bulk Update Feature Item Property
  bulkUpdateFeatureItemProperty(proposalId: string, property: string, value: any): Observable<any> {
    return this.http.put<any>(`/featureItems/bulk/${proposalId}/${property}`, { value: value });
  }

  // Update Feature Item Project Prep Status
  updateFeatureItemProjectPrepStatus(featureItemId: string, projectPrepStatus: string): Observable<any> {
    return this.http.put<any>(`/featureItems/${featureItemId}/projectPrepStatus`, { projectPrepStatus: projectPrepStatus });
  }

  // Update Feature Item Installed Quantity
  updateFeatureItemInstalledQuantity(featureItemId: string, installedQuantity: number): Observable<any> {
    return this.http.put<any>(`/featureItems/${featureItemId}/installedQuantity`, { installedQuantity: installedQuantity.toString() });
  }

  // Delete Feature Item
  deleteFeatureItem(featureItemId: string): Observable<any> {
    return this.http.delete<any>(`/featureItems/${featureItemId}`);
  }

  // Get Feature Items
  getFeatureItems(params: any): Observable<{ featureItems: FeatureItem[], count: number }> {
    return this.http.get<{ featureItems: FeatureItem[], count: number }>(`/featureItems`, {
      params: params
    });
  }

  // Pull Feature Item From Inventory
  pullFeatureItemFromInventory(featureItemId: string): Observable<any> {
    return this.http.get<any>(`/featureItems/${featureItemId}/pull`);
  }

  // Return Feature Item To Inventory
  returnFeatureItemToInventory(featureItemId: string): Observable<any> {
    return this.http.get<any>(`/featureItems/${featureItemId}/return`);
  }
}
