<div class="card mt-3">
    <div class="card-header">
        <h5>Projects</h5>
        <button class="btn btn-primary ms-auto" type="button" (click)="openNewProjectModal();">
            <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
            <span>New</span>
        </button>
        <button *ngIf="tableControls.isNotDefault()" type="button" class="btn btn-primary ms-2" (click)="tableControls.resetTableConfiguration();">
            <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
            <span>Reset</span>
        </button>
    </div>

    <div class="row button-container">
      <div class="sort" *ngIf="isMobile"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>
      <div class="filter" *ngIf="isMobile"><ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container></div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table *ngIf="!isMobile">
                <thead>
                    <tr>
                        <th>
                            <div class="d-flex" style="position: relative;">
                                <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #projectName="ngbPopover" (click)="showColumnFilterPopover(projectName, 'p.project_name');">
                                    <span *ngIf="tableControls.hasActiveFilter('p.project_name')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('p.project_name')}}</span>
                                    <fa-icon [icon]="faFilter"></fa-icon>
                                </div>
                                <div class="w-100 px-3" (click)="tableControls.sort('p.project_name');">
                                    Name
                                </div>
                                <fa-icon *ngIf="tableControls.isSortedBy('p.project_name')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex" style="position: relative;">
                                <div class="header-filter-container" [ngbPopover]="multiFilterPopContent" [popoverTitle]="multiFilterPopTitle" triggers="manual" autoClose="outside" placement="bottom" #site="ngbPopover" (click)="site.open();">
                                    <span *ngIf="tableControls.getFilterCount(['s.site_address_city', 's.site_address_county', 's.site_address_state', 's.site_address_postal_code']) > 0" class="custom-badge">{{tableControls.getFilterCount(['s.site_address_city', 's.site_address_county', 's.site_address_state', 's.site_address_postal_code'])}}</span>
                                    <fa-icon [icon]="faFilter"></fa-icon>
                                </div>
                                <div class="w-100 px-3" (click)="tableControls.sort('s.site_address_street');">
                                    Site
                                </div>
                                <fa-icon *ngIf="tableControls.isSortedBy('s.site_address_street')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                            </div>
                        </th>
                        <!-- Category -->
                        <th>
                            <div class="d-flex" style="position: relative;">
                                <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #projectCategory="ngbPopover" (click)="showColumnFilterPopover(projectCategory, 'p.project_category');">
                                    <span *ngIf="tableControls.hasActiveFilter('p.project_category')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('p.project_category')}}</span>
                                    <fa-icon [icon]="faFilter"></fa-icon>
                                </div>
                                <div class="w-100 px-3" (click)="tableControls.sort('p.project_category');">
                                    Category
                                </div>
                                <fa-icon *ngIf="tableControls.isSortedBy('p.project_category')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                            </div>
                        </th>
                        <!-- Storage Location -->
                        <th>Stor. Loc.</th>
                        <!-- Stage -->
                        <th>
                            <div class="d-flex" style="position: relative;">
                                <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #projectStage="ngbPopover" (click)="showColumnFilterPopover(projectStage, 'p.project_stage');">
                                    <span *ngIf="tableControls.hasActiveFilter('p.project_stage')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('p.project_stage')}}</span>
                                    <fa-icon [icon]="faFilter"></fa-icon>
                                </div>
                                <div class="w-100 px-3" (click)="tableControls.sort('p.project_stage');">
                                    Stage
                                </div>
                                <fa-icon *ngIf="tableControls.isSortedBy('p.project_stage')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                            </div>
                        </th>
                        <!-- Status -->
                        <th>
                            <div class="d-flex" style="position: relative;">
                                <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #projectStatus="ngbPopover" (click)="showColumnFilterPopover(projectStatus, 'p.project_status');">
                                    <span *ngIf="tableControls.hasActiveFilter('p.project_status')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('p.project_status')}}</span>
                                    <fa-icon [icon]="faFilter"></fa-icon>
                                </div>
                                <div class="w-100 px-3" (click)="tableControls.sort('p.project_status');">
                                    Status
                                </div>
                                <fa-icon *ngIf="tableControls.isSortedBy('p.project_status')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex" style="position: relative;">
                                <div class="w-100 px-3" (click)="tableControls.sort('p.project_updated_at');">
                                    Updated
                                </div>
                                <fa-icon *ngIf="tableControls.isSortedBy('p.project_updated_at')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let project of projects" routerLink="/projects/{{project.id}}">
                        <td data-label="Name">{{project.name}}</td>
                        <td data-label="Site">{{project.site.name}}</td>
                        <td data-label="Category">{{project.category}}</td>
                        <td data-label="Stor. Loc.">{{project.storageLocation}}</td>
                        <td data-label="Stage">{{project.stage | projectStage}}</td>
                        <td data-label="Status">{{project.status | projectStatus}}</td>
                        <td data-label="Updated">{{project.updatedAt | formatDateTime}}</td>
                    </tr>
                </tbody>
            </table>

            <div class="scrollable" *ngIf="isMobile">
              <ul class="list-group">
                  <li *ngFor="let project of projects" class="list-group-item" style="cursor: pointer;" routerLink="/projects/{{project.id}}">
                      <div class="d-flex list-group-container">
                          <div>
                              <strong>{{project.name}}</strong>
                              <div>{{project.site.name}}</div>
                              <div>{{project.category}}</div>
                              <div>{{project.storageLocation}}</div>
                              <div><strong>Stage:</strong> {{project.stage | projectStage}}</div>
                              <div><strong>Status:</strong> {{project.status | projectStatus}}</div>
                              <small class="text-muted">CrUpdatedeated: {{project.updatedAt | formatDateTime}}</small>
                          </div>
                      </div>
                  </li>
                  <li *ngIf="projects.length == 0" class="list-group-item">No Projects</li>
              </ul>
            </div>
        </div>
    </div>
</div>

<!-- Filter Popover -->
<ng-template #popTitleFilter>Filter</ng-template>
<ng-template let-column="column" #popContentFilter>
    <div *ngFor="let element of this.columnFilterValues.get(column)" class="form-check">
        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter(column, element.value);" [checked]="tableControls.filterExists(column, element.value)">
        <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
    </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #multiFilterPopTitle>Filters</ng-template>
<ng-template #multiFilterPopContent>
    <!-- City -->
    <div class="multi-filter-item" (click)="getDistinctColumnValues('s.site_address_city'); cityCollapse.toggle();">City</div>
    <div #cityCollapse="ngbCollapse" [ngbCollapse]="true" style="overflow-y: hidden; max-height: none;">
        <div *ngFor="let element of columnFilterValues.get('s.site_address_city');" class="form-check">
            <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter('s.site_address_city', element.value);" [checked]="tableControls.filterExists('s.site_address_city', element.value)">
            <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
        </div>
    </div>
    <!-- County -->
    <div class="multi-filter-item" (click)="getDistinctColumnValues('s.site_address_county'); countyCollapse.toggle();">County</div>
    <div #countyCollapse="ngbCollapse" [ngbCollapse]="true" style="overflow-y: hidden; max-height: none;">
        <div *ngFor="let element of columnFilterValues.get('s.site_address_county');" class="form-check">
            <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter('s.site_address_county', element.value);" [checked]="tableControls.filterExists('s.site_address_county', element.value)">
            <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
        </div>
    </div>
    <!-- State -->
    <div class="multi-filter-item" (click)="getDistinctColumnValues('s.site_address_state'); stateCollapse.toggle();">State</div>
    <div #stateCollapse="ngbCollapse" [ngbCollapse]="true" style="overflow-y: hidden; max-height: none;">
        <div *ngFor="let element of columnFilterValues.get('s.site_address_state');" class="form-check">
            <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter('s.site_address_state', element.value);" [checked]="tableControls.filterExists('s.site_address_state', element.value)">
            <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
        </div>
    </div>
    <!-- Postal Code -->
    <div class="multi-filter-item" (click)="getDistinctColumnValues('s.site_address_postal_code'); postalCodeCollapse.toggle();">Postal Code</div>
    <div #postalCodeCollapse="ngbCollapse" [ngbCollapse]="true" style="overflow-y: hidden; max-height: none;">
        <div *ngFor="let element of columnFilterValues.get('s.site_address_postal_code');" class="form-check">
            <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter('s.site_address_postal_code', element.value);" [checked]="tableControls.filterExists('s.site_address_postal_code', element.value)">
            <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
        </div>
    </div>
    <!-- Reset -->
    <div class="multi-filter-item" (click)="tableControls.resetFilters(['s.site_address_city', 's.site_address_county', 's.site_address_state', 's.site_address_postal_code']);">Reset</div>
</ng-template>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block" [autoClose]="true" placement="bottom-left">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
  <div ngbDropdown class="d-inline-block filter-container" autoClose="outside" placement="bottom-left">
      <span *ngIf="activeFilterCount > 0" class="custom-badge-lg">{{activeFilterCount}}</span>
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Filter">
          <fa-icon [icon]="faFilter"></fa-icon>
      </button>
      <div ngbDropdownMenu>
          <!-- Filter Options -->
          <div *ngFor="let filter of filterColumns">
            <button ngbDropdownItem class="button-filter" (click)="getDistinctColumnValues(filter.value); filters.toggle();">{{filter.label}}</button>
            <div class="px-4 py-1" #filters="ngbCollapse" [ngbCollapse]="filter.value === selectedFilterColumn ? false : true">
                <div class="d-flex flex-column">
                    <div *ngFor="let element of columnFilterValues.get(filter.value);" class="form-check form-check-inline">
                        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter(filter.value, element.value);" [checked]="filterExists(filter.value, element.value)">
                        <label [for]="element.randomId" class="form-check-label" style="white-space: nowrap;">{{element.name}}</label>
                    </div>
                </div>
            </div>
          </div>

          <!-- Divider -->
          <div class="dropdown-divider"></div>
          <!-- Reset -->
          <button class="reset-button" ngbDropdownItem (click)="resetActiveFilters(); selectedFilterColumn = ''">Reset</button>
      </div>
  </div>
</ng-template>

