import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alertTriggered = new Subject<any>();
  alertTriggered$ = this.alertTriggered.asObservable();

  private alertProgressUpdated = new Subject<any>();
  alertProgressUpdated$ = this.alertProgressUpdated.asObservable();

  constructor() { }

  // Update Progress
  updateProgress(loaded: number, total: number): void {
    this.alertProgressUpdated.next({ loaded: loaded, total: total });
  }

  // Show Error Alert
  showErrorAlert(msg: string, customClass?: string): void {
    this.alertTriggered.next({ msg: msg, type: 'ERROR', class: customClass});
  }

  // Show Warning Alert
  showWarningAlert(msg: string): void {
    this.alertTriggered.next({ msg: msg, type: 'WARNING' });
  }

  // Show Info Alert
  showInfoAlert(msg: string): void {
    this.alertTriggered.next({ msg: msg, type: 'INFO' });
  }

  // Show Success Alert
  showSuccessAlert(msg: string): void {
    this.alertTriggered.next({ msg: msg, type: 'SUCCESS' });
  }
}
