import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from './invoice';
import { Payment } from './payment';

@Injectable({
  providedIn: 'root'
})
export class ContactsSerivce {

  constructor(private http: HttpClient) { }

  // Get Invoices
  getContacts(params?: any): Observable<{ contacts: any[], count: number }> {
    return this.http.get<{ contacts: any[], count: number }>(`/contacts/clientcontacts`, {
      params: params
    });
  }
}
