<form id="payment-form">
    <div class="d-flex">
        <div class="input-group">
            <span class="input-group-text">$</span>
            <input id="AMOUNT" type="number" class="form-control" step="0.01" [value]="amount" [disabled]="amountConfirmed">
            <button *ngIf="!amountConfirmed" type="button" class="btn btn-outline-primary" (click)="confirmAmount();">
                <fa-icon [icon]="faCheck" class="button-icon-left"></fa-icon>
                <span>Confirm</span>
            </button>
        </div>
    </div>
    <div *ngIf="amountConfirmed">
        <div *ngIf="applePayIsAvailable && !payWithCardIsActive" id="apple-pay-button" class="mt-2"></div>
        <div *ngIf="googlePayIsAvailable && !payWithCardIsActive" id="google-pay-button" class="mt-2"></div>
        <button *ngIf="!payWithCardIsActive" type="button" class="btn btn-primary w-100 mt-2" (click)="payWithCard();">Pay with Card</button>
        <div *ngIf="payWithCardIsActive" class="mt-2">
            <div id="card-container"></div>
            <button id="card-button" type="button" class="btn btn-primary w-100" [disabled]="paymentInProgress">{{payWithCardBtnText}}</button>
        </div>
    </div>
    <div class="d-flex mt-2">
        <small class="text-muted mx-auto">Powered by Square</small>
    </div>
</form>