import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-subscription-page',
  templateUrl: './change-subscription-page.component.html',
  styleUrls: ['./change-subscription-page.component.css']
})
export class ChangeSubscriptionPageComponent implements OnInit {

  showCardAlert: boolean = false;
  login = JSON.parse(localStorage.getItem("login"));

  ngOnInit(): void {
    console.log(this.login)
    if( !this.login?.isWithCardOnFile && this.login?.role === "SUPER" && this.login?.monthDelinquent > 1){
      this.showCardAlert = true;
    }
  }

}
