import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-number-connect-form',
  templateUrl: './number-connect-form.component.html',
  styleUrls: ['./number-connect-form.component.css']
})
export class NumberConnectFormComponent implements OnInit {
  numberConnectForm!: FormGroup;
  numberArray: any[] = [];

  constructor(
    private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.numberConnectForm = this.formBuilder.group({
      number: ['', Validators.required],
    });
  }
}
