import { HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FileService } from '../file.service';
import { AlertService } from '../alert.service';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.css']
})
export class FileUploadModalComponent {

  // Properties:
  getPresignedURL: (data: any) => Promise<string>;
  completed: EventEmitter<void> = new EventEmitter<void>();

  // Files
  files: File[] = [];
  uploadTotal: number = 0;
  uploadLoaded: number = 0;
  lastUploadLoaded: number = 0;
  uploadSubscription: Subscription;

  // Font Awesome Properties
  faTimes = faTimes;

  constructor(private fileService: FileService,
    public modal: NgbActiveModal, private alertService: AlertService) { }

  // Browse Files
  browseFiles(): void {
    document.getElementById('FILE_INPUT').click();
  }

  // File Input Changed
  fileInputChanged(files: File[]): void {
    if(!this.isValidFilename(files[0].name)){
      let fileInput = document.getElementById('FILE_INPUT');
      fileInput['value'] = '';
      this.files = [];
      return this.alertService.showErrorAlert("<br/>Filename is invalid. <br/> Only alphanumeric characters, <br/> hyphens, dash, underscores, dots, <br/> parenthesis and spaces are allowed.", 'uploading-msg');
    }
    for (const file of files) this.files.push(file);
  }

  // Remove File
  removeFile(index: number) {
    const tempArray = [];
    for (var i = 0; i < this.files.length; i++) {
      if (i !== index) tempArray.push(this.files[i]);
    }
    this.files = tempArray;
  }

  // Upload Files
  async uploadFiles() {
    try {
      const fileNameElements = document.getElementsByName('FILE_NAME');
      this.uploadTotal = this.files.reduce((acc, file) => { return acc + file.size; }, 0);
      for (var i = 0; i < this.files.length; i++) {
        this.lastUploadLoaded = this.uploadLoaded;
        const data: any = {
          name: (<HTMLInputElement>fileNameElements[i]).value,
          type: this.files[i].type ? this.files[i].type : this.files[i].name.toLocaleLowerCase().includes("heic") ? "image/heif" : "",
          size: this.files[i].size
        };
        const presignedUrl = await this.getPresignedURL(data);
        await new Promise<void>((resolve, reject) => {
          this.uploadSubscription = this.fileService.uploadFileDirectToS3(presignedUrl, this.files[i]).subscribe((event: HttpEvent<any>) => {
            if (event.type == HttpEventType.UploadProgress) {
              this.uploadLoaded = event.loaded + this.lastUploadLoaded;
            }
            if (event.type == HttpEventType.Response) {
              (event.ok) ? resolve() : reject();
            }
          });
        });
      }
      this.modal.close();
      this.resetUploads();
      this.completed.emit();
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        if (error.error.category == 'QUOTA_REACHED') this.resetUploads(false);
      }
    }
  }

  // Cancel Uploads
  cancelUploads(): void {
    if (this.uploadSubscription) this.uploadSubscription.unsubscribe();
    this.modal.dismiss();
    this.resetUploads();
    this.completed.emit();
  }

  // Reset Uploads
  private resetUploads(resetFiles: boolean = true): void {
    this.modal.close();
    if (resetFiles) this.files.length = 0;
    this.uploadTotal = 0;
    this.uploadLoaded = 0;
    this.lastUploadLoaded = 0;
  }

  //Validate file name using regex pattern
  isValidFilename(filename) {
    const pattern = /^[a-zA-Z0-9-_.() ]+$/;
    console.log(pattern.test(filename))
    return pattern.test(filename);
  }
}
