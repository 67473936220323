import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-name-dialog',
  templateUrl: './name-dialog.component.html',
  styleUrls: ['./name-dialog.component.css']
})
export class NameDialogComponent {
  nameForm!: FormGroup;
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.nameForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  close(){
    this.modal.close();
  }

  submit(){
    this.saved.emit(this.nameForm.controls['name'].value)
  }

}
