import { Component, Input, OnInit } from '@angular/core';
import { OrganizationSubscription } from '../organization-subscription';
import { SettingService } from '../setting.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationUpdateSubscriptionComponent } from '../organization-update-subscription/organization-update-subscription.component';
import { AlertService } from '../alert.service';
import { AlertComponent } from '../alert/alert.component';
import { SubscriptionAlertModalComponent } from '../subscription-alert-modal/subscription-alert-modal.component';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-settings-subscriptions-list',
  templateUrl: './settings-subscriptions-list.component.html',
  styleUrls: ['./settings-subscriptions-list.component.css']
})
export class SettingsSubscriptionsListComponent implements OnInit {

  // Properties
  @Input() subscriptions: OrganizationSubscription[] = [];

  constructor(
    private settingService: SettingService,
    private authService: AuthService,
    private modalService: NgbModal,
    private alertService: AlertService) { }

  ngOnInit(): void {
    // this.getOrganizationSubscriptions();
  }

  // Get Organization Subscriptions
  private getOrganizationSubscriptions(): void {
    this.settingService.getOrganizationSubscriptions().subscribe((subscriptions) => {
      this.subscriptions = subscriptions;
    });
  }

  openUpdateSubscriptionModal() {
    const modalReference = this.modalService.open(OrganizationUpdateSubscriptionComponent, { size: 'lg' });
    modalReference.componentInstance.selectedSubscription = this.subscriptions[0];
    modalReference.componentInstance.saved.subscribe((subs: OrganizationSubscription) => {
      console.log(this.subscriptions)

      this.settingService.updateSubscription({
        organizationId: this.subscriptions[0].organizationId,
        subscriptionId: subs.id,
        name: subs.name,
        price: subs.price,
        frequency: subs.frequency,
        hasMultiple: false,
        isExpired: false,
        quantity: 1,
        shouldRenew: true,
        type: 'STANDARD',
        renewalDate: this.subscriptions[0].renewalDate,
        from: this.subscriptions && this.subscriptions.length != 0 ? this.subscriptions[0].subscriptionId : null
      }).subscribe((res) => {
        this.openModalAlert();
        this.alertService.showSuccessAlert('Successfully updated your subscription');
        this.getOrganizationSubscriptions();
      });
    })
  }

  openModalAlert() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'sm'
    };
    const modalReference = this.modalService.open(SubscriptionAlertModalComponent, ngbModalOptions);
    modalReference.componentInstance.type = "Success";
    modalReference.componentInstance.message = "You have successfully updated your subscription! We need to log you out in order for the application to get the latest details.";
    modalReference.closed.subscribe(data => {
      this.authService.logout();
    })
  }
}
