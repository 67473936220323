import { Component, OnInit } from '@angular/core';
import { faBold, faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Stepper from 'bs-stepper';
import { SmsCallService } from '../sms-call.service';
import { SmsCallSettingsService } from '../sms-call-settings.service';
import { LoaderService } from '../shared/services/loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../alert.service';

@Component({
  selector: 'app-number-configuration-modal',
  templateUrl: './number-configuration-modal.component.html',
  styleUrls: ['./number-configuration-modal.component.css']
})
export class NumberConfigurationModalComponent implements OnInit {

  // Font Awesome Properties
  faTimes = faTimes;
  faBold = faBold;
  faQuestionCircle = faQuestionCircle;
  loading: boolean = false;
  private stepper: Stepper;
  public useCases: any[] = [];
  public numbers: any[] = [];
  public isLoading: boolean = false;
  public campaignForm!: FormGroup;

  constructor(
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private loaderService: LoaderService,
    public smsCallSettingService: SmsCallSettingsService,
    private fb: FormBuilder,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.getUseCases();
    this.getNumbers();
    this.campaignForm = this.fb.group({
      name: [null, Validators.required],
      description: [null, Validators.required],
      messageFlow: [null, Validators.required],
      messageSamples: this.fb.array([null], Validators.required),
      usAppToPersonUsecase: [null],
      hasEmbeddedLinks: [false, Validators.required],
      hasEmbeddedPhone: [false, Validators.required],
      numbersToUse: this.fb.array([], Validators.required),
    });
  }

  getUseCases() {
    this.useCases = [
      {
        value: "LOW_VOLUME",
        label: "Low Volume Mixed",
      },
      {
        value: "ACCOUNT_NOTIFICATION",
        label: "Account Notification",
      },
      {
        value: "MARKETING",
        label: "Marketing",
      }
    ]
    
  }

  getNumbers() {
    // TODO
    // this.smsCallSettingService.getUseCases().subscribe((data: any) => {
    //   this.useCases = data;
    //   this.loaderService.hideSpinner();
    //   this.isLoading = false;
    // },
    // (error) => {
    //   this.loaderService.hideSpinner();
    // });

    this.numbers = [
      { id: 1, text: '(586) 420-0351', selected: false },
      { id: 2, text: '(734) 559-4007', selected: false },
      { id: 3, text: '(248) 220-9557', selected: false }
    ]
  }

  submit() {
    if (this.campaignForm.valid) {
      let data = {
        ...this.campaignForm.value
      }
      this.loaderService.showSpinner();
      this.smsCallSettingService.postCreateCampaign(data).subscribe(() => {
        this.alertService.showSuccessAlert('Campaign Created!');
      },
        (error) => {
          this.loaderService.hideSpinner();
        });
    }
  }

}
