import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from './user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  /* ----- User Requests ----- */

  // Add User
  addUser(user: any): Observable<any> {
    return this.http.post<any>(`/settings/users`, user);
  }

  // Update User
  updateUser(data: any): Observable<any> {
    return this.http.put<any>(`/settings/users/${data.id}`, data);
  }

  // Reset User Password
  resetUserPassword(userId: string, data: any): Observable<void> {
    return this.http.put<void>(`/users/${userId}/resetPassword`, data);
  }

  // Change User Password
  changeUserPassword(data: any): Observable<any> {
    return this.http.put<any>(`/users/${data.userId}/changePassword`, data);
  }

  // Get Users
  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`/settings/users`);
  }

  // Get User
  getUser(userId: string): Observable<User> {
    return this.http.get<User>(`/settings/users/${userId}`);
  }

  // Check User Email Exists
  checkUserEmailExists(email: string): Observable<boolean> {
    return this.http.get<boolean>(`/settings/users/checkUserEmailExists`, {
      params: {
        email: email
      }
    });
  }

  /* ----- Forgot Password Requests ----- */

  // Initiate Password Reset
  initiatePasswordReset(email: string): Observable<any> {
    return this.http.post<any>(`/settings/users/initatePasswordReset`, { email: email });
  }

  // Verify Verification Code
  verifyVerificationCode(email: string, verificationCode: number): Observable<any> {
    return this.http.post<any>(`/settings/users/verifyVerificationCode`, { email: email, verificationCode: verificationCode });
  }

  // Reset Forgotten Password
  resetForgottenPassword(data: any): Observable<any> {
    return this.http.post<any>(`/settings/users/resetForgottenPassword`, data);
  }

  /* ----- Archive User ----- */
  archiveStatusChange(userId: string, status: boolean): Observable<any> {
    return this.http.put<any>(`/settings/users/archiveuser/${userId}`, {
      status
    });
  }
}