<div class="d-flex">
    <button *ngIf="showButtons" type="button" class="btn btn-primary me-2 my-auto" (click)="previous();">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
    </button>
    <small class="text-muted text-end ms-auto my-auto" style="display: inline-block;">{{startDate | formatDateTime: true}} to <br>{{endDate | formatDateTime: true}}</small>
    <button *ngIf="showButtons" type="button" class="btn btn-primary ms-2 my-auto" (click)="next();">
        <fa-icon [icon]="faAngleRight"></fa-icon>
    </button>
    <button type="button" class="btn btn-primary ms-2 my-auto" [ngbPopover]="dateRangePopContent" popoverTitle="Date Range" autoClose="outside" placement="bottom-right" popoverClass="date-picker-custom-popover" #dateRangePopover="ngbPopover">
        <fa-icon [icon]="faCalendar"></fa-icon>
    </button>
</div>

<!-- Date Range Popover -->
<ng-template #dateRangePopContent>
    <div>
        <button type="button" class="btn btn-sm btn-primary" [ngClass]="preset !=='YTD' ? 'unselected' : ''" (click)="showPreset('YTD');">YTD</button>
        <button type="button" class="btn btn-sm btn-primary ms-2" [ngClass]="preset !=='LST_YR' ? 'unselected' : ''" (click)="showPreset('LST_YR');">Last Year</button>
        <button type="button" class="btn btn-sm btn-primary ms-2" [ngClass]="preset !=='CUR_MTH' ? 'unselected' : ''" (click)="showPreset('CUR_MTH');">Current Month</button>
        <button type="button" class="btn btn-sm btn-primary ms-2" [ngClass]="preset !=='LST_MTH' ? 'unselected' : ''" (click)="showPreset('LST_MTH');">Last Month</button>
        <button type="button" class="btn btn-sm btn-primary ms-2" [ngClass]="preset !=='CUR_WK' ? 'unselected' : ''" (click)="showPreset('CUR_WK');">Current Week</button>
    </div>
    <div class="row">
        <div class="col mt-2">
            <ngb-datepicker #startDatePicker [startDate]="startDateNgb"
            (dateSelect)="startDateSelected($event)" (click)="preset = ''; applyButtonDisabled=false" (navigate)="startDatePicker.focusSelect();"></ngb-datepicker>
        </div>
        <div class="col mt-2">
            <ngb-datepicker #endDatePicker [startDate]="endDateNgb"
            (dateSelect)="startDateSelected($event)" (click)="preset = ''; applyButtonDisabled=false" (navigate)="endDatePicker.focusSelect();"></ngb-datepicker>
        </div>
    </div>
    <div class="container d-flex justify-content-end mt-2 apply-button pr-0 pl-0 mr-0 ml-0">
      <button type="button" class="btn btn-primary" [disabled]="isApplyButtonDisabled"
      (click)="finalizeDateChange(true)">
        Apply
      </button>
    </div>
</ng-template>
