<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">Reports</h2>
        </div>
    </nav>
    <div class="row">
        <div class="col-lg-4 mt-3">
            <div class="card">
                <div class="card-header d-flex">
                    <h5 class="card-title my-auto mb-auto">Clients</h5>
                    <fa-icon class="button-icon-left ms-auto" [icon]="faUsers" size="lg"></fa-icon>
                </div>
                <div class="card-body">
                    <ul class="list-group scrollable">
                        <li class="list-group-item" routerLink="/reports/last-proposal-accepted-date">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <span>Last Proposal Accepted Date</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div *ngIf="user && user.hasSalesAccess" class="col-lg-4 mt-3">
            <div class="card">
                <div class="card-header d-flex">
                    <h5 class="card-title my-auto mb-auto">Finances</h5>
                    <fa-icon class="button-icon-left ms-auto" [icon]="faFileInvoiceDollar" size="lg"></fa-icon>
                </div>
                <div class="card-body">
                    <ul class="list-group scrollable">
                        <li class="list-group-item" routerLink="/reports/accounts-receivable">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <span>Accounts Receivable</span>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item" routerLink="/reports/at-a-glance">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <span>At A Glance</span>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item" routerLink="/reports/invoices">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <span>Invoices</span>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item" routerLink="/reports/partially-invoiced-projects">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <span>Partially Invoiced Projects</span>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item" routerLink="/reports/payroll-summary">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <span>Payroll Summary</span>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item" routerLink="/reports/payments">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <span>Payments</span>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item" routerLink="/reports/time-clock">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <span>Time Clock - Detailed</span>
                                </div>
                            </div>
                        </li>
                        <!-- TODO show once fix -->
                        <li class="list-group-item" routerLink="/reports/sales-tax-invoiced">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <span>Sales Tax (Invoiced)</span>
                                </div>
                            </div>
                        </li>
                        <!-- <li class="list-group-item" routerLink="/reports/sales-tax-paid">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <span>Sales Tax (Paid)</span>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-4 mt-3">
            <div class="card">
                <div class="card-header d-flex">
                    <h5 class="card-title my-auto mb-auto">Projects</h5>
                    <fa-icon class="button-icon-left ms-auto" [icon]="faProjectDiagram" size="lg"></fa-icon>
                </div>
                <div class="card-body">
                    <ul class="list-group scrollable">
                        <li class="list-group-item" routerLink="/reports/proposals">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <span>Proposals</span>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item" routerLink="/reports/storage-locations">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <span>Storage Locations</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-4 mt-3 padd-right">
            <div class="card">
                <div class="card-header d-flex">
                    <h5 class="card-title my-auto mb-auto">Schedule</h5>
                    <fa-icon class="button-icon-left ms-auto" [icon]="faCalendar" size="lg"></fa-icon>
                </div>
                <div class="card-body">
                    <ul class="list-group scrollable">
                        <li class="list-group-item" routerLink="/reports/preferredInstallationAndRemovalDates">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <span>Preferred Installation And Removal Dates</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        
        <div class="col-lg-4"></div>
        <div class="col-lg-4"></div>
    </div>
</div>