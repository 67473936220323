import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Feature } from './feature';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  constructor(private http: HttpClient) { }

  // Add Feature
  addFeature(feature: any): Observable<any> {
    return this.http.post<any>(`/features`, feature);
  }

  // Update Feature
  updateFeature(featureId: string, data: any): Observable<any> {
    return this.http.put<any>(`/features/${featureId}`, data);
  }

  // Update Feature Order
  updateFeatureOrder(data: any): Observable<any> {
    return this.http.put<any>(`/features`, data);
  }

  // Update Feature Included
  updateFeatureIncluded(featureId: string, included: boolean): Observable<any> {
    return this.http.put<any>(`/features/${featureId}/included`, { included: included });
  }

  // Bulk Update Feature Property
  bulkUpdateFeatureProperty(proposalId: string, property: string, value: any): Observable<any> {
    return this.http.put<any>(`/features/${proposalId}/${property}`, { value: value });
  }

  // Delete Feature
  deleteFeature(featureId: string): Observable<any> {
    return this.http.delete<any>(`/features/${featureId}`);
  }

  // Get Features
  getFeatures(params: any): Observable<{ features: Feature[], count: number }> {
    return this.http.get<{ features: Feature[], count: number }>(`/features`, {
      params: params
    });
  }

  // Get Distinct Column Values
  getDistinctColumnValues(params: any): Observable<string[]> {
    return this.http.get<string[]>(`/features/distinctColumnValues`, {
      params: params
    });
  }

  // Update Feature Installation Status
  updateFetureInstallationStatus(feature: any): Observable<any> {
    return this.http.post<any>(`/features/installationstatus`, feature);
  }

  // Update Feature Removal Status
  updateFetureRemovalStatus(feature: any): Observable<any> {
    return this.http.post<any>(`/features/removalstatus`, feature);
  }
}
