import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MockupService } from '../mockup.service';
import { Mockup } from '../mockup';
import { Router } from '@angular/router';
import { faQuestion, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { AlertService } from '../alert.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-project-mockups',
  templateUrl: './project-mockups.component.html',
  styleUrls: ['./project-mockups.component.css']
})
export class ProjectMockupsComponent implements OnInit {
  //Screensize event Listener
 @HostListener('window:resize', ['$event'])
 onResize(event: Event) {
   this.resizeSubject.next((event.target as Window).innerWidth);
 }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Properties
  @Input() projectId: string;
  mockups: Mockup[] = [];
  isMobileView: boolean = false;

  // Font Awesome Properties
  faTrashCan = faTrashCan;
  faQuestion = faQuestion;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private router: Router,
    private mockupService: MockupService,
    private alertService: AlertService,
    private modalService: NgbModal) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
        }
      });
    }

  ngOnInit(): void {
    if (this.projectId) this.getMockups();
    this.checkScreenSize();
  }

  // Open Delete Mockup Confirmation Modal
  openDeleteMockupConfirmationModal(mockupId: string): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this mockup?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteMockup(mockupId);
    });
  }

  // Navigate To Mockup Tool
  navigateToMockupTool(mockupId: string = null): void {
    // if(this.isMobile) {
    //   return this.alertService.showErrorAlert("This feature's is for desktop/laptop");
    // }
    this.router.navigate(['/mockupTool'], { queryParams: { mockupId: mockupId } });
  }

  // Get Mockups
  private getMockups(): void {
    this.mockupService.getMockups(this.projectId).subscribe((mockups) => {
      this.mockups = mockups;
    });
  }

  // Delete Mockup
  private deleteMockup(mockupId: string): void {
    this.mockupService.deleteMockup(mockupId).subscribe(() => {
      this.alertService.showSuccessAlert('Mockup Deleted');
      this.getMockups();
    });
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }
}
