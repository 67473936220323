import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { faSave, faPlus, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from '../alert.service';
import { SettingService } from '../setting.service';

@Component({
  selector: 'app-settings-invoices',
  templateUrl: './settings-invoices.component.html',
  styleUrls: ['./settings-invoices.component.css']
})
export class SettingsInvoicesComponent implements OnInit {

  // Properties
  businessInformationForm: UntypedFormGroup;
  businessAddressForm: UntypedFormGroup;
  invoiceTermsForm:UntypedFormGroup;

  displayAddress: boolean;

  // Invoice Terms
  invoiceTerms: string = null;

  // Font Awesome Properties
  faSave = faSave;
  faPlus = faPlus;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;

  editorForm: UntypedFormGroup;

  constructor(private settingService: SettingService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.businessInformationForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      email: new UntypedFormControl(),
      phone: new UntypedFormControl()
    });
    this.businessAddressForm = new UntypedFormGroup({
      street: new UntypedFormControl(),
      city: new UntypedFormControl(),
      state: new UntypedFormControl('SAS'),
      postalCode: new UntypedFormControl()
    });
    this.invoiceTermsForm = new UntypedFormGroup({
      body: new UntypedFormControl(),
    });
    this.getInvoiceSettings();
    this.getInvoiceTerms();
    // this.editor = new Editor();
  }

  /* ----- Invoice View ----- */

  // Get Invoice Settings
  private getInvoiceSettings(): void {
    this.settingService.getInvoiceSettings().subscribe((invoiceSettings) => {
      // Business Information Form
      this.businessInformationForm.controls.name.setValue(invoiceSettings.business.name);
      this.businessInformationForm.controls.email.setValue(invoiceSettings.business.email);
      this.businessInformationForm.controls.phone.setValue(invoiceSettings.business.phone);
      // Business Address Form
      const address = invoiceSettings.business.address;
      this.businessAddressForm.controls.street.setValue(address.street);
      this.businessAddressForm.controls.city.setValue(address.city);
      this.businessAddressForm.controls.state.setValue(address.state);
      this.businessAddressForm.controls.postalCode.setValue(address.postalCode);
      this.displayAddress = invoiceSettings.business.displayAddress;
    });
  }

  // Update Business Information
  updateBusinessInformation(): void {
    if (this.businessInformationForm.valid) {
      this.settingService.updateInvoiceSettings(this.businessInformationForm.value).subscribe(() => {
        this.alertService.showSuccessAlert('Business Information Updated');
        this.getInvoiceSettings();
      });
    } else {
      this.businessInformationForm.markAllAsTouched();
    }
  }

  // Update Business Address
  updateBusinessAddress(): void {
    if (this.businessAddressForm.valid) {
      this.settingService.updateInvoiceSettings({ address: this.businessAddressForm.value }).subscribe(() => {
        this.alertService.showSuccessAlert('Business Address Updated');
        this.getInvoiceSettings();
      });
    } else {
      this.businessAddressForm.markAllAsTouched();
    }
  }

  // Toggle Display Business Address
  toggleDisplayBusinessAddress(): void {
    this.settingService.updateInvoiceSettings({ displayAddress: !this.displayAddress }).subscribe(() => {
      this.alertService.showSuccessAlert('Business Address Updated');
      this.getInvoiceSettings();
    });
  }

  // Get Invoice Terms
  private getInvoiceTerms(): void {
    this.settingService.getInvoiceTerms().subscribe((invoiceTerms) => {
      this.invoiceTerms = invoiceTerms;
      this.invoiceTermsForm.controls['body'].setValue(invoiceTerms.toString());
    });
  }

  // Set Invoice Terms
  setInvoiceTerms(): void {
    const invoiceTerms = this.invoiceTermsForm.controls['body'].value;
    this.settingService.setInvoiceTerms(invoiceTerms).subscribe(() => {
      this.alertService.showSuccessAlert('Invoice Terms Saved');
      this.getInvoiceTerms();
    });
  }

  // Business Information Form 
  get businessName() { return this.businessInformationForm.controls.name; }
  get businessEmail() { return this.businessInformationForm.controls.email; }
  get businessPhone() { return this.businessInformationForm.controls.phone; }
}
