import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(private spinner: NgxSpinnerService) {}

  showSpinner() {
    this.spinner.show(undefined, {
      type: 'ball-scale-multiple',
      size: 'medium',
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
  }

  hideSpinner(timer = 0) {
    setTimeout(() => {
      this.spinner.hide();
    },timer)
  }

}
