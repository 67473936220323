<ngb-carousel #carousel [interval]="0" [activeId]="selectedLightboxItemId">
    <ng-template ngbSlide *ngFor="let lightboxItem of lightboxItems" [id]="lightboxItem.id">
        <div *ngIf="isVideo(lightboxItem.type)" class="carousel-video-controls">
            <div class="card mx-auto" style="width: fit-content;">
                <div class="card-body">
                    <!-- Desktop/Tablet -->
                    <div class="d-none d-md-flex">
                        <!-- Play -->
                        <button *ngIf="isPaused()" type="button" class="btn btn-primary" style="width: 42px; padding-left: 0.6rem;" (click)="play();">
                            <fa-icon [icon]="faPlay"></fa-icon>
                        </button>
                        <!-- Pause -->
                        <button *ngIf="!isPaused()" type="button" class="btn btn-primary" style="width: 42px;" (click)="pause();">
                            <fa-icon [icon]="faPause"></fa-icon>
                        </button>
                        <div class="mx-2">
                            <!-- RWD -->
                            <button type="button" class="btn btn-primary" style="width: 42px;" (click)="rewind();">
                                <fa-icon [icon]="faArrowRotateBackward"></fa-icon>
                            </button>
                            <!-- Time -->
                            <span class="mx-1" style="min-width: 115px;">{{formatTime(currentTime)}} / {{formatTime(duration)}}</span>
                            <!-- FWD -->
                            <button type="button" class="btn btn-primary" style="width: 42px;" (click)="fastForward();">
                                <fa-icon [icon]="faArrowRotateForward"></fa-icon>
                            </button>
                        </div>
                        <!-- Mute/Unmute -->
                        <button type="button" class="btn btn-primary" style="width: 42px; padding-left: 0.6rem;" (click)="toggleMute();">
                            <fa-icon *ngIf="isMuted()" [icon]="faVolumeXmark"></fa-icon>
                            <fa-icon *ngIf="!isMuted()" [icon]="faVolumeHigh"></fa-icon>
                        </button>
                        <!-- Fullscreen -->
                        <button type="button" class="btn btn-primary ms-2" style="width: 42px;" (click)="fullscreen();">
                            <fa-icon [icon]="faExpand"></fa-icon>
                        </button>
                    </div>
                    <!-- Mobile -->
                    <div class="d-md-none">
                        <div class="d-flex justify-content-between">
                            <!-- Play -->
                            <button *ngIf="isPaused()" type="button" class="btn btn-primary" style="width: 42px;" (click)="play();">
                                <fa-icon [icon]="faPlay"></fa-icon>
                            </button>
                            <!-- Pause -->
                            <button *ngIf="!isPaused()" type="button" class="btn btn-primary" style="width: 42px;" (click)="pause();">
                                <fa-icon [icon]="faPause"></fa-icon>
                            </button>
                            <!-- Mute/Unmute -->
                            <button type="button" class="btn btn-primary" style="width: 42px; padding-left: 0.6rem;" (click)="toggleMute();">
                                <fa-icon *ngIf="isMuted()" [icon]="faVolumeXmark"></fa-icon>
                                <fa-icon *ngIf="!isMuted()" [icon]="faVolumeHigh"></fa-icon>
                            </button>
                            <!-- Fullscreen -->
                            <button type="button" class="btn btn-primary" style="width: 42px;" (click)="fullscreen();">
                                <fa-icon [icon]="faExpand"></fa-icon>
                            </button>
                            <!-- Close -->
                            <button type="button" class="btn btn-primary" style="width: 42px;" (click)="modal.close();">
                                <fa-icon [icon]="faXmark"></fa-icon>
                            </button>
                        </div>
                        <div class="d-flex mt-2">
                            <!-- RWD -->
                            <button type="button" class="btn btn-primary" style="width: 42px;" (click)="rewind();">
                                <fa-icon [icon]="faArrowRotateBackward"></fa-icon>
                            </button>
                            <!-- Time -->
                            <span class="mx-auto my-auto text-center" style="min-width: 115px;">{{formatTime(currentTime)}} / {{formatTime(duration)}}</span>
                            <!-- FWD -->
                            <button type="button" class="btn btn-primary" style="width: 42px;" (click)="fastForward();">
                                <fa-icon [icon]="faArrowRotateForward"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img *ngIf="isImage(lightboxItem.type)" class="img-fluid" [src]="lightboxItem.url">
        <video *ngIf="isVideo(lightboxItem.type)" id="VIDEO-{{lightboxItem.id}}" [src]="lightboxItem.url" width="100%"></video>
        <!-- <div class="carousel-caption">
            <h3 style="-webkit-text-stroke: 2px black; font-weight: 700;">{{lightboxItem.name}}</h3>
        </div> -->
    </ng-template>
</ngb-carousel>