import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GeoJSONSource, LngLatBounds, Map, NavigationControl, Popup } from 'maplibre-gl';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map-display',
  templateUrl: './map-display.component.html',
  styleUrls: ['./map-display.component.css']
})
export class MapDisplayComponent implements OnInit, AfterViewInit {

  // Properties
  map: Map;
  bounds: LngLatBounds;

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    const apiKey = environment.map;
    const mapName = "tinsel-crm-multi-dev";
    const region = "us-east-1";

    const style: any = environment.apiUrl + '/maps/style-descriptor'
    this.map = new Map({
      container: 'map',
      // style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
      style
    });
    this.map.addControl(new NavigationControl({}), 'top-left');
    this.map.on('load', () => {
      this.map.resize();
    });
  }

  // Add Map Collection
  addMapCollection(id: string, collection: any[], showRoute: boolean = false): void {

    // Check If Map Is Loaded
    if (!this.map.loaded()) {
      setTimeout(() => { this.addMapCollection(id, collection, showRoute); }, 1500);
      return;
    }

    // IDs
    const routeId = id + '.Route';
    const featuresId = id + '.Features';
    const clustersId = id + '.Clusters';
    const clusterCountId = id + '.ClusterCount';
    const unclusteredPointId = id + '.UnclusteredPoint';

    // Route
    if (this.map.getLayer(routeId)) this.map.removeLayer(routeId);
    if (this.map.getSource(routeId)) this.map.removeSource(routeId);

    if (showRoute) {
      this.map.addSource(routeId, {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: collection.map((feature) => { return [feature.geometry.coordinates[0], feature.geometry.coordinates[1]] })
          }
        }
      });

      this.map.addLayer({
        id: routeId,
        type: 'line',
        source: routeId,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#888',
          'line-width': 6
        }
      });
    }

    // Markers
    if (this.map.getLayer(unclusteredPointId)) this.map.removeLayer(unclusteredPointId);
    if (this.map.getLayer(clusterCountId)) this.map.removeLayer(clusterCountId);
    if (this.map.getLayer(clustersId)) this.map.removeLayer(clustersId);
    if (this.map.getSource(featuresId)) this.map.removeSource(featuresId);

    this.map.addSource(featuresId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: collection
      },
      cluster: true,
      clusterMaxZoom: 15,
      clusterRadius: 25
    });

    this.map.addLayer({
      id: clustersId,
      type: 'circle',
      source: featuresId,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6' as any,
          10,
          '#f1f075',
          20,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          10,
          30,
          20,
          40
        ],
        'circle-stroke-width': 1.5,
        'circle-stroke-color': '#fff'
      }
    });

    this.map.addLayer({
      id: clusterCountId,
      type: 'symbol',
      source: featuresId,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['Noto Sans Regular'],
        'text-size': 12
      }
    });

    this.map.addLayer({
      id: unclusteredPointId,
      type: 'circle',
      source: featuresId,
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': ['get', 'color'],
        'circle-radius': 7,
        'circle-stroke-width': 1.5,
        'circle-stroke-color': '#fff'
      }
    });

    this.map.on('click', clustersId, (event) => {
      const features = this.map.queryRenderedFeatures(event.point, { layers: [clustersId] });
      const clusterId = features[0].properties.cluster_id;
      (this.map.getSource(featuresId) as GeoJSONSource).getClusterExpansionZoom(clusterId, (error, zoom) => {
        if (error) return;
        this.map.easeTo({ center: (features[0].geometry as any).coordinates, zoom: zoom });
      });
    });

    this.map.on('click', unclusteredPointId, (event) => {
      const coordinates = (event.features[0].geometry as any).coordinates;
      new Popup().setLngLat(coordinates).setHTML(event.features[0].properties.popupHtml).addTo(this.map);
      // this.map.easeTo({ center: coordinates, zoom: 15 });
    });

    this.map.on('mouseenter', unclusteredPointId, () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });

    this.map.on('mouseleave', unclusteredPointId, () => {
      this.map.getCanvas().style.cursor = null;
    });

    // if (!this.bounds) this.bounds = new LngLatBounds();
    const bounds = new LngLatBounds();
    for (const feature of collection) bounds.extend(feature.geometry.coordinates);
    if (!bounds.isEmpty()) this.map.fitBounds(bounds, { padding: 100, maxZoom: 12 });
  }
}
