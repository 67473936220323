<div class="modal-header">
    <div>
        <h5 class="modal-title">Order Details</h5>
        <h5 class="text-muted">{{ itemName }}</h5>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="orderForm" *ngIf="renderForm()">
        <div class="row mt-2">
            <div class="col table-responsive">
                <table *ngIf="!isMobile">
                    <thead>
                        <tr>
                            <th>
                                Order <br>
                                (<input class="form-check-input" type="checkbox" name="radioNoLabel"
                                formControlName="allItem"> All)
                            </th>
                            <th>
                                Client
                            </th>
                            <th>
                                Project
                            </th>
                            <th>
                                Quantity
                            </th>
                        </tr>
                    </thead>
                    <tbody formArrayName="choices">
                        <tr [formGroupName]="i"
                            *ngFor="let projectPrepItem of orderForm.controls.choices.controls; let i = index">
                            <td data-label="Order">
                                <input class="form-check-input" type="checkbox" name="radioNoLabel"
                                    [formControlName]="i + '-checkbox'"
                                    (change)="chosenQuantity($event, projectPrepItem.value.quantity, i)">
                            </td>
                            <td data-label="Client">{{projectPrepItem.value.clientName}}</td>
                            <td data-label="Project">{{projectPrepItem.value.projectName}}</td>
                            <td data-label="Quantity">{{projectPrepItem.value.quantity}}</td>
                        </tr>
                        <tr>
                            <td colspan="3">TOTAL</td>
                            <td>{{totalQuantity}}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="scrollable" *ngIf="isMobile">
                  Order&nbsp;(<input class="form-check-input" type="checkbox" name="radioNoLabel"
                  formControlName="allItem"> All)
                  <ul class="list-group" formArrayName="choices">
                      <li [formGroupName]="i" *ngFor="let projectPrepItem of orderForm.controls.choices.controls; let i = index">
                          <div class="d-flex list-group-container">
                              <div>
                                <div class="item-header mb-2">
                                  <div>
                                    <strong>{{projectPrepItem.value.clientName}}</strong>
                                  </div>
                                  <div>
                                    Order:&nbsp;
                                    <input class="form-check-input" type="checkbox" name="radioNoLabel"
                                    [formControlName]="i + '-checkbox'"
                                    (change)="chosenQuantity($event, projectPrepItem.value.quantity, i)">
                                  </div>
                                </div>
                                  <div><strong>Project: </strong>{{projectPrepItem.value.projectName}}</div>
                                  <div><strong>Quantity: </strong>{{projectPrepItem.value.quantity}}</div>
                              </div>
                          </div>
                      </li>
                      <li *ngIf="orderForm.controls.choices.controls.length == 0" class="list-group-item">No Orders</li>
                  </ul>
                </div>
            </div>

        </div>

        <div class="row mt-2 align-items-center">
            <div class="col-md-7">
                <label for="TOTAL_ORDER_QTY" class="">Total Quantity Ordering</label>
            </div>
            <div class="col-md-5">
                <input id="TOTAL_ORDER_QTY" type="number" class="form-control text-end" formControlName="orderQty">
                <div *ngIf="orderQty.invalid && (orderQty.dirty || orderQty.touched)">
                    <div *ngIf="orderQty.errors.min" class="input-validation-msg">
                        Quantity order can't be less than quantity needed
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2 align-items-center">
            <div class="col-md-7">
                <label for="ORDER_SOURCE">Item Source</label>
            </div>
            <div class="col-md-5">

                <input *ngIf="sourceTypeaheadInput" id="ORDER_SOURCE" type="text" class="form-control text-end"
                    formControlName="itemSource" placeholder="Bulb Manufacturer" maxlength="150"
                    [ngbTypeahead]="sourceTypeaheadInput"
                    (focus)="sourceTypeaheadInputFocus$.next($any($event).target.value)"
                    [class.is-invalid]="itemSource.invalid && (itemSource.dirty || itemSource.touched)">
                <div *ngIf="itemSource.invalid && (itemSource.dirty || itemSource.touched)">
                    <div *ngIf="itemSource.errors.maxlength" class="input-validation-msg">Source cannot exceed 150
                        characters.</div>
                </div>
            </div>
            <!-- <input *ngIf="leadTypeTypeaheadInput" id="ORDER_SOURCE" type="text" class="form-control"
                formControlName="itemSource" placeholder="Word of Mouth" maxlength="150" required
                [ngbTypeahead]="leadTypeTypeaheadInput"
                (focus)="leadTypeTypeaheadInputFocus$.next($any($event).target.value)"
                [class.is-invalid]="leadType.invalid && (leadType.dirty || leadType.touched)">
            <div *ngIf="leadType.invalid && (leadType.dirty || leadType.touched)">
                <div *ngIf="leadType.errors.required" class="input-validation-msg">Lead Type is required.</div>
                <div *ngIf="leadType.errors.maxlength" class="input-validation-msg">Lead Type cannot exceed 150
                    characters.</div>
            </div> -->
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onSubmit()">Order</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
</div>
