import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Site } from './site';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private http: HttpClient) { }

  // Add Site
  addSite(site: any): Observable<any> {
    return this.http.post<any>(`/sites`, site);
  }

  // Update Site
  updateSite(site: any): Observable<any> {
    return this.http.put<any>(`/sites/${site.id}`, site);
  }

  // Delete Site
  deleteSite(siteId: string): Observable<any> {
    return this.http.delete<any>(`/sites/${siteId}`);
  }

  // Get Sites
  getSites(params: any): Observable<{ sites: Site[], count: number }> {
    return this.http.get<{ sites: Site[], count: number }>(`/sites`, {
      params: params
    });
  }

  // Get Site
  getSite(siteId: string): Observable<Site> {
    return this.http.get<Site>(`/sites/${siteId}`);
  }
}
