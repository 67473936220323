<div class="card mt-3">
    <div class="card-header d-xl-flex">
        <h5>Prospects ({{prospects.length}})</h5>
        <div class="ms-auto mt-xl-0">
            <button type="button" class="btn btn-outline-primary" (click)="refreshProspects();" ngbTooltip="Refresh" aria-label="Refresh">
                <fa-icon [icon]="faRotateRight" [spin]="isRefreshing"></fa-icon>
            </button>
            <span class="ms-2"></span>
            <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container>
            <span class="ms-2"></span>
            <ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container>
        </div>
    </div>
    <div class="card-body">
        <ul class="list-group scrollable" style="height: 368px;">
            <li *ngFor="let prospect of prospects" class="list-group-item" style="cursor: pointer;" (click)="prospectSelected(prospect);">
                <div class="d-flex align-items-center">
                    <div class="d-flex flex-column">
                        <strong>{{prospect.firstName}} {{prospect.lastName}}</strong>
                        <small>{{prospect.email}}</small>
                        <small>{{prospect.phone}}</small>
                        <small class="text-muted">{{prospect.createdAt | formatDateTime}}</small>
                    </div>
                    <div *ngIf="prospect.status == 'PENDING'" class="d-flex flex-xl-row flex-column ms-auto" style="display: flex; flex-direction: column;">
                        <button type="button" class="btn btn-primary my-xl-auto" (click)="$event.stopPropagation(); acceptProspect(prospect.id, false);" ngbTooltip="Accept" aria-label="Accept" >
                            <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                        <button type="button" class="btn wait-list-btn mt-2 mt-xl-0 ms-xl-2 my-xl-auto" (click)="$event.stopPropagation(); acceptProspect(prospect.id, true);" ngbTooltip="Wait List" aria-label="Wait List">
                            <fa-icon [icon]="faClock"></fa-icon>
                        </button>
                    </div>
                </div>
            </li>
            <li *ngIf="prospects.length == 0" class="list-group-item">No New Prospects</li>
        </ul>
    </div>
</div>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
    <div ngbDropdown class="d-inline-block" [autoClose]="true">
        <button class="btn btn-outline-primary" ngbDropdownToggle ngbTooltip="Sort" aria-label="Sort">
            <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
        </button>
        <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="sort('prospect_created_at');">
                Created
                <fa-icon *ngIf="sortBy == 'prospect_created_at'" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
            </button>
        </div>
    </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
    <div ngbDropdown class="d-inline-block" autoClose="outside">
        <span *ngIf="activeFilterCount > 0" class="custom-badge-lg">{{activeFilterCount}}</span>
        <button class="btn btn-outline-primary" ngbDropdownToggle ngbTooltip="Filter" aria-label="Filter">
            <fa-icon [icon]="faFilter"></fa-icon>
        </button>
        <div ngbDropdownMenu>
            <!-- Status -->
            <button ngbDropdownItem (click)="this.getDistinctColumnValues('prospect_status'); statusCollapse.toggle();">Status</button>
            <div class="px-4 py-1" #statusCollapse="ngbCollapse" [ngbCollapse]="true">
                <div class="d-flex flex-column">
                    <div *ngFor="let element of columnFilterValues.get('prospect_status');" class="form-check form-check-inline">
                        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter('prospect_status', element.value);" [checked]="filterExists('prospect_status', element.value)">
                        <label [for]="element.randomId" class="form-check-label" style="white-space: nowrap;">{{element.name}}</label>
                    </div>
                </div>
            </div>
            <!-- Divider -->
            <div class="dropdown-divider"></div>
            <!-- Reset -->
            <button ngbDropdownItem (click)="resetActiveFilters();">Reset</button>
        </div>
    </div>
</ng-template>