import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AlertService } from '../alert.service';
import { FeatureItemService } from '../feature-item.service';
import { faCartPlus, faArrowCircleDown, faArrowCircleUp, faFilter, faSortDown, faSortUp, faRotateLeft, faArrowsSplitUpAndLeft, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { ProjectPrepService } from '../project-prep.service';
import { ProjectPrepStatusPipe } from '../project-prep-status.pipe';
import { TableControlService } from '../table-control.service';
import { InventoryPrepService } from '../inventory-prep.service';
import { InventoryPrep } from '../inventory-prep';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectPrepSplitModalComponent } from '../project-prep-split-modal/project-prep-split-modal.component';
import { AdminSettingService } from '../admin-setting.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-project-prep-global',
  templateUrl: './project-prep-global.component.html',
  styleUrls: ['./project-prep-global.component.css'],
  providers: [ProjectPrepStatusPipe]
})
export class ProjectPrepGlobalComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  @Input() projectId: string;
  // Properties
  projectPrepItems: any[] = [];
  count: number = 0;
  isMobileView: boolean = false;

  sortOptions = [
    { label: 'Item', value: 'i.item_name' },
    { label: 'Feature', value: 'f.feature_name' },
    { label: 'Project', value: 'p.project_name' },
    { label: 'Client', value: 'c.client_name' },
    { label: 'Source', value: 'i.item_source' },
    { label: 'Quantity', value: 'ip.inventory_prep_quantity' },
    { label: 'Status', value: 'ip.inventory_prep_status' },
    { label: 'Updated', value: 'ip.inventory_prep_updated_at' },
  ];
  filterColumns = [
    { label: 'Item', value: 'i.item_name' },
    { label: 'Feature', value: 'f.feature_name' },
    { label: 'Project', value: 'p.project_name' },
    { label: 'Client', value: 'c.client_name' },
    { label: 'Source', value: 'i.item_source' },
    { label: 'Status', value: 'ip.inventory_prep_status' }
  ];
  sortBy: string = 'i.item_name';
  sortDirection: string = 'ASC';


  // Table Controls
  tableControls: TableControlService;
  storedSearchValue: string;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;
  selectedFilterColumn: string ='';

  // Font Awesome Properties
  faCartPlus = faCartPlus;
  faArrowCircleDown = faArrowCircleDown;
  faArrowCircleUp = faArrowCircleUp;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;
  faRotateLeft = faRotateLeft;
  faArrowsSplitUpAndLeft = faArrowsSplitUpAndLeft;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private featureItemService: FeatureItemService,
    private projectPrepService: ProjectPrepService,
    private inventoryPrepService: InventoryPrepService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private projectPrepStatusPipe: ProjectPrepStatusPipe,
    private adminSettingService: AdminSettingService) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
          this.tableControls.setLimit(20);
        }
      });
    }

  ngOnInit(): void {
    this.tableControls = new TableControlService('ProjectPrep.Global', true, 'ip.inventory_prep_created_at', 'ASC', [['ip.inventory_prep_status', ['PENDING']]]);

    if (this.projectId) {
      this.tableControls = new TableControlService('ProjectPrep.Inventory', true, null, 'DESC', [['ip.inventory_prep_project_id', [this.projectId]], ['ip.inventory_prep_status', ['PENDING']]]);
    } else {
      this.tableControls = new TableControlService('ProjectPrep.Inventory', true, null, 'DESC', [['ip.inventory_prep_status', ['PENDING']]]);
    }
    this.checkScreenSize();
    if(this.isMobile) {
      let activeFilters = this.tableControls.getActiveFilters()
      let columns = [];
      for (const [key, value] of activeFilters) {
        columns.push(key)
      }
      this.tableControls.resetFilters(columns)
    }
    this.tableControls.resetTableConfiguration();
    this.tableControls.refresh.subscribe(() => {
      this.getProjectPrepItems();
    });
    if (this.tableControls.hasTableConfiguration()) this.storedSearchValue = this.tableControls.getSearchTerm();
    this.getProjectPrepItems();

  }

  /* ----- Project Prep Items ----- */

  // Get Project Prep Items
  private getProjectPrepItems(): void {
    let params = this.tableControls.getParams();
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
      for (const [key, value] of this.activeFilters) {
        if (key.includes('range')) params[`filter:${key}`] = value;
        else params[`filter:${key}`] = JSON.stringify(value);
      }
    };
    this.inventoryPrepService.getInventoryPrep(params).subscribe((res) => {
      this.projectPrepItems = res.inventoryPrep;
      this.count = res.count;
    });
  }

  updateOrMerge(arrayOne, key) {
    const mergeObj = [...arrayOne];
    const res = mergeObj.reduce((acc, obj) => {
      let found = false;
      for (let i = 0; i < acc.length; i++) {
        if (acc[i][key] === obj[key]) {
          found = true;
          acc[i] = {
            ...acc[i],
            ...obj,
            quantity: parseInt(acc[i].quantity) + parseInt(obj.quantity)
          };
        }
      }
      if (!found) {
        obj.count = 1;
        acc.push(obj);
      }
      return acc;
    }, []);

    return res;
  };

  // Place On Order
  placeOnOrder(itemId: string, prepId: string, quantity: number): void {
    this.inventoryPrepService.updateInventoryPrepStatus(itemId, prepId, 'TO-BE-ORDERED', quantity).subscribe(() => {
      this.alertService.showSuccessAlert('Item Placed on Order');
      this.getProjectPrepItems();
    });
  }

  // Pull Feature Item From Inventory
  pullFeatureItemFromInventory(itemId: string, prepId: string, quantity: number): void {
    this.inventoryPrepService.updateInventoryPrepStatus(itemId, prepId, 'PULLING', quantity).subscribe(() => {
      this.alertService.showSuccessAlert('Item Pulled');
      this.getProjectPrepItems();
    });
  }

  splitItem(itemPrep: InventoryPrep) {
    const modalRef = this.modalService.open(ProjectPrepSplitModalComponent);
    modalRef.componentInstance.itemPrep = itemPrep;
    modalRef.componentInstance.itemName = itemPrep.itemName;
    modalRef.componentInstance.maxQuantity = itemPrep.quantity;
    modalRef.componentInstance.saved.subscribe((res) => {
      if (res == 1) {
        this.alertService.showSuccessAlert('Item has benn Split to order and inventory.');
        this.getProjectPrepItems();
      }
    });
  }

  /* ----- Filters and Sorting ----- */

  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    this.selectedFilterColumn = column;
    let params = null;

    if (!this.columnFilterValues.has(column)) {
      params = {
        searchTerm: null,
        sortBy: column,
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        column: column,
        'filter:ip.inventory_prep_status': JSON.stringify(['PENDING'])
      };

      if (this.projectId) {
        params = {
          ...params,
          'filter:ip.inventory_prep_project_id': JSON.stringify([this.projectId])
        }
      }
      if(this.isMobile) {
        for (const [key, value] of this.activeFilters) {
          if (key.includes('range')) params[`filter:${key}`] = value;
          else params[`filter:${key}`] = JSON.stringify(value);
        }
      }
      this.projectPrepService.getDistinctColumnValues(params).subscribe((values) => {
        const tempArray = [];
        for (const value of values) {
          let name = null;
          switch (column) {
            case 'ip.inventory_prep_status':
              name = this.projectPrepStatusPipe.transform(value);
              break;
            default:
              name = (value === null || value.length == 0) ? 'BLANK' : value;
              break;
          }
          tempArray.push({ name: name, value: value, randomId: Math.random().toString(36) });
        }
        this.columnFilterValues.set(column, tempArray);
      });
    }
  }


  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getProjectPrepItems();
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getProjectPrepItems();
    this.getActiveFilterCount();
  }

   // Get Active Filter Count
   getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getProjectPrepItems();
    this.getActiveFilterCount();
  }

  // Show Column Filter Popover
  showColumnFilterPopover(popover, column: string): void {
    this.getDistinctColumnValues(column);
    if (popover.isOpen()) {
      popover.close();
    } else {
      setTimeout(() => {
        popover.open({ column });
      }, 250);
    }
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
    this.isMobileView ? this.tableControls.setLimit(5): this.tableControls.setLimit(20);
  }

}
