<div>
    <div class="input-group">
        <span class="input-group-text">$</span>
        <input id="AMOUNT" type="number" class="form-control" step="0.01" [value]="amount" [disabled]="amountConfirmed" [class.is-invalid]="remainingBalance > 0 && remainingBalance < 1">
        <button *ngIf="!amountConfirmed" type="button" class="btn btn-outline-primary" (click)="confirmAmountWithAch();" [disabled]="remainingBalance > 0 && remainingBalance < 1">
            <fa-icon [icon]="faCheck" class="button-icon-left"></fa-icon>
            <span>Confirm</span>
        </button>
    </div>
    <div *ngIf="remainingBalance > 0 && remainingBalance < 1" class="input-validation-msg">Remaining balance cannot be less than $1.00.</div>
</div>
<div *ngIf="amountConfirmed">
    <div *ngIf="tinselPayAchVaults.length > 0" class="mt-2">
        <ul class="list-group scrollable" style="max-height: 87px;">
            <li *ngFor="let tinselPayAchVault of tinselPayAchVaults" class="list-group-item" style="cursor: pointer;" (click)="achVaultSelected(tinselPayAchVault.id);">
                <div class="d-flex">
                    <div class="my-auto">
                        <fa-icon [icon]="faLandmark" size="lg"></fa-icon>
                    </div>
                    <div class="ms-3">Ending in <span style="font-weight: 600;">{{tinselPayAchVault.details.lastFour}}</span></div>
                    <div *ngIf="selectedAchVaultId && selectedAchVaultId == tinselPayAchVault.id" class="ms-auto">
                        <fa-icon [icon]="faCheck" size="lg"></fa-icon>
                    </div>
                    <div *ngIf="!selectedAchVaultId || (selectedAchVaultId && selectedAchVaultId != tinselPayAchVault.id)" class="ms-auto" (click)="$event.stopPropagation(); deleteTinselPayAchVault(tinselPayAchVault.id);">
                        <fa-icon [icon]="faTrash"></fa-icon>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div *ngIf="!selectedAchVaultId" class="mt-2">
        <form [formGroup]="achPaymentForm">
            <div class="mb-2">
                <input id="ACH_PAYMENT_CUSTOMER_NAME" type="text" class="form-control" formControlName="customerName" placeholder="John Appleseed" autocomplete="off" maxlength="22" required [class.is-invalid]="customerName.invalid && (customerName.dirty || customerName.touched)">
                <div *ngIf="customerName.invalid && (customerName.dirty || customerName.touched)">
                    <div *ngIf="customerName.errors.required" class="input-validation-msg">Customer Name is required.</div>
                    <div *ngIf="customerName.errors.maxlength" class="input-validation-msg">Customer Name cannot exceed 22 characters.</div>
                </div>
            </div>
            <div class="row">
                <div class="col mb-2">
                    <input id="ACH_PAYMENT_BANK_ACCOUNT_NUMBER" type="text" class="form-control" formControlName="bankAccountNumber" placeholder="Account Number" autocomplete="off" maxlength="17" pattern="^\d+$" required [class.is-invalid]="bankAccountNumber.invalid && (bankAccountNumber.dirty || bankAccountNumber.touched)">
                    <div *ngIf="bankAccountNumber.invalid && (bankAccountNumber.dirty || bankAccountNumber.touched)">
                        <div *ngIf="bankAccountNumber.errors.required" class="input-validation-msg">Account Number is required.</div>
                        <div *ngIf="bankAccountNumber.errors.maxlength" class="input-validation-msg">Account Number cannot exceed 17 characters.</div>
                        <div *ngIf="bankAccountNumber.errors.pattern" class="input-validation-msg">Account Number must only contain numbers.</div>
                    </div>
                </div>
                <div class="col mb-2">
                    <input id="ACH_PAYMENT_BANK_ROUTING_NUMBER" type="text" class="form-control" formControlName="bankRoutingNumber" placeholder="Routing Number" autocomplete="off" maxlength="9" pattern="^\d+$" required [class.is-invalid]="bankRoutingNumber.invalid && (bankRoutingNumber.dirty || bankRoutingNumber.touched)">
                    <div *ngIf="bankRoutingNumber.invalid && (bankRoutingNumber.dirty || bankRoutingNumber.touched)">
                        <div *ngIf="bankRoutingNumber.errors.required" class="input-validation-msg">Routing Number is required.</div>
                        <div *ngIf="bankRoutingNumber.errors.maxlength" class="input-validation-msg">Routing Number cannot exceed 9 characters.</div>
                        <div *ngIf="bankRoutingNumber.errors.pattern" class="input-validation-msg">Routing Number must only contain numbers.</div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-evenly">
                <div class="form-check form-check-inline">
                    <input id="ACH_PAYMENT_BANK_ACCOUNT_TYPE_CHECKING" class="form-check-input" type="radio" formControlName="bankAccountType" value="CHECKING">
                    <label for="ACH_PAYMENT_BANK_ACCOUNT_TYPE_CHECKING" class="form-check-label" style="font-weight: normal;">Checking</label>
                </div>
                <div class="form-check form-check-inline">
                    <input id="ACH_PAYMENT_BANK_ACCOUNT_TYPE_SAVINGS" class="form-check-input" type="radio" formControlName="bankAccountType" value="SAVINGS">
                    <label for="ACH_PAYMENT_BANK_ACCOUNT_TYPE_SAVINGS" class="form-check-label" style="font-weight: normal;">Savings</label>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="!selectedAchVaultId" class="mt-2">
        <div class="form-check">
            <input id="SAVE_ACH_PROFILE" type="checkbox" class="form-check-input" style="margin-left: -1.25em;">
            <label for="SAVE_ACH_PROFILE" class="form-check-label">Save Profile</label>
        </div>
    </div>
    <div class="mt-2">
        <button type="button" class="btn btn-primary w-100" [disabled]="paymentInProgress" (click)="payWithAch();">{{paymentButtonText}}</button>
    </div>
</div>