<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">Invoices</h2>
        </div>
        <div class="row ms-auto">
            <div class="col-auto">
                <app-date-picker-controller #datePickerController componentId="Reports.Invoices" preset="YTD" [showButtons]="false" (dateChanged)="dateChanged($event);" [onLoad]="true"></app-date-picker-controller>
            </div>
        </div>
    </nav>
    <div class="row mt-3">
        <div class="card p-0">
            <div class="card-body">
                <h5 class="card-title">Description</h5>
                <p class="card-text m-0">This report can be used to review invoice data over a period of time.</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col mt-3">
            <div class="card">
                <div class="card-header">
                    <h5>Volume by Month ({{monthlyVolumeAmountTotal | currency}}) ({{monthlyVolumeCountTotal}})</h5>
                </div>
                <div class="card-body" style="height: 400px;">
                    <div style="position: relative; width: auto; height: 368px;">
                        <canvas id="MONTHLY_VOLUME_CHART"></canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- LOADER -->
<ngx-spinner>
  <p class="loading-spinner">Loading...</p>
</ngx-spinner>
