import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsageService {

  constructor(private http: HttpClient) { }

  // Get Usage Metric
  getUsageMetric(metric: string): Observable<{ usage: number, limit: number }> {
    return this.http.get<{ usage: number, limit: number }>(`/usage/${metric}`);
  }

  // Get Usage Limit
  getUsageLimit(metric: string): Observable<number> {
    return this.http.get<number>(`/usage/${metric}/limit`);
  }
}
