<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">Time Clock</h2>
        </div>
        <div class="row calendar-selection-section">
            <div class="col-auto">
                <app-date-picker-controller preset="CUR_WK" [showButtons]="true" (dateChanged)="dateChanged($event);" [onLoad]="true"></app-date-picker-controller>
            </div>
        </div>
    </nav>
    <div class="row" style="height: 50%;">
        <div class="col mt-3">
            <app-map-display #MAP></app-map-display>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-3 mt-3">
            <ul class="list-group scrollable" style="max-height: 475px;">
                <li *ngFor="let user of users" class="list-group-item" [class.selectedUser]="selectedUser && selectedUser.id == user.id" style="cursor: pointer;" (click)="selectUser(user.id);">
                    <p class="mb-0" style="font-weight: 600;">{{user.firstName}} {{user.lastName}}</p>
                </li>
            </ul>
        </div>
        <div class="col-sm-9 mt-3">
            <div class="d-grid gap-2">
                <button type="button" class="btn btn-primary btn-lg mb-3" (click)="openTimeEntryModal();">New</button>
            </div>
            <ul class="list-group scrollable" style="max-height: 412px;">
                <li *ngFor="let timeEntry of timeEntries" class="list-group-item" style="cursor: pointer;" (click)="selectTimeEntry(timeEntry);">
                    <div class="d-flex">
                        <div>
                            <div><strong>{{timeEntry.type}}</strong></div>
                            <div><strong>{{timeEntry.clientName}}</strong></div>
                            <div><strong>{{timeEntry.projectName}}</strong></div>
                            <div><strong>Start:</strong> {{timeEntry.start | formatDateTime}} <span *ngIf="!hasLocationData(timeEntry.startLocation)">(No Location Data)</span></div>
                            <div *ngIf="timeEntry.end"><strong>End:</strong> {{timeEntry.end | formatDateTime}} <span *ngIf="!hasLocationData(timeEntry.endLocation)">(No Location Data)</span></div>
                        </div>
                        <div class="ms-auto my-auto">
                            <h5 *ngIf="timeEntry.duration">{{formatDuration(timeEntry.duration)}}</h5>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<!-- LOADER -->
<ngx-spinner>
  <p class="loading-spinner">Loading...</p>
</ngx-spinner>
