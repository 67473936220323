import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Notification, NotificationRequest } from './notification';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgbModalRef, NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationCenterComponent } from './notification-center/notification-center.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  // Properties
  webSocket: WebSocket;
  notifications: Notification[] = [];
  conversationNotifications: Notification[] = [];
  smsCallnotifications: Notification[] = [];
  activeNotifications: Notification[] = [];
  retryCounter: number = 0;

  notificationsUpdated: EventEmitter<void> = new EventEmitter<void>();
  activeNotificationsUpdated: EventEmitter<void> = new EventEmitter<void>();

  notificationBannerUpdated: EventEmitter<{ show: boolean }> = new EventEmitter<{ show: boolean }>();
  private canvasRef: NgbOffcanvasRef;

  constructor(private router: Router,
    private authService: AuthService,
    private http: HttpClient,
    private offcanvasService: NgbOffcanvas) { }

  // Load
  load(): void {
    this.authService.userAuthenticated$.subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        if (this.webSocket !== undefined) this.webSocket.close(1000);
      } else {
        let login = JSON.parse(localStorage.getItem("login"))
        if (!login.isMobile) {
          if (this.webSocket !== undefined) {
            if (this.webSocket.readyState !== WebSocket.OPEN && this.webSocket.readyState !== WebSocket.CONNECTING) this.connectWebSocket();
          } else {
            this.connectWebSocket();
          }
        }
      }
    });
  }

  // Connect WebSocket
  private connectWebSocket(): void {
    this.webSocket = new WebSocket(`wss://${document.location.host}/webSocketServer`);
    this.webSocket.addEventListener('open', () => {
      this.retryCounter = 0;
      console.log('WebSocket Open');
      if (!environment.production) console.log('WebSocket Open');
      this.heartbeat(this.webSocket);
    });
    this.webSocket.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      const notification: Notification = {
        organizationId: data.organizationId,
        id: data.id,
        referenceId: data.referenceId,
        title: data.title,
        message: data.message,
        type: data.type,
        isRead: data.isRead,
        createdAt: data.createdAt,
        externalUrl: data.externalUrl
      };
      if(data.type != 'SMS') {
        this.show(notification);
      } else {
        this.refreshNotifications()
      }
    });
    this.webSocket.addEventListener('close', (event) => {
      if (event.code == 1006) {
        console.log('WebSocket Closed');
        setTimeout(() => {
          if (this.authService.isAuthenticated() && this.retryCounter < 5) {
            this.retryCounter++;
            this.connectWebSocket();
          }
        }, 5000);
      } else {
        console.log('WebSocket Closed');
        if (!environment.production) console.log('WebSocket Closed');
      }
    });
  }

  // Heartbeat
  private heartbeat(socket: WebSocket): void {
    if (!socket) return;
    if (socket.readyState !== WebSocket.OPEN) return;
    socket.send('Heartbeat');
    // console.log('HEARTBEAT SENT');
    setTimeout(() => {
      this.heartbeat(socket);
    }, 30000);
  }

  // Show Notification
  private show(notification: Notification): void {
    this.refreshNotifications();
    if (this.offcanvasService.hasOpenOffcanvas()) return;
    this.activeNotifications.push(notification);
    this.activeNotifications = this.activeNotifications.sort((n1: Notification, n2: Notification) => {
      return n2.createdAt - n1.createdAt;
    });
    this.activeNotificationsUpdated.emit();
  }

  // Dismiss Notification
  dismissNotification(notification: Notification): void {
    this.activeNotifications = this.activeNotifications.filter((n) => { return n.id != notification.id; });
    this.activeNotificationsUpdated.emit();
  }

  // Dismiss All Notifications
  dismissAllNotification(): void {
    this.activeNotifications.length = 0;
  }

  // Go To
  goTo(notification: Notification): void {
    switch (notification.type) {
      case 'PROSPECT':
        this.router.navigateByUrl('/dashboard');
        break;
      case 'INVOICE':
        this.router.navigateByUrl(`/invoices/${notification.referenceId}`);
        break;
      case 'PROPOSAL':
        this.router.navigateByUrl(`/projects/${notification.referenceId}`);
        break;
      case 'UPDATE':
        window.open("https://support.tinselcrm.com/release-log");
        break;
      case 'EXTERNAL':
        window.open(notification.externalUrl);
        break;
      case 'SMS':
        this.router.navigateByUrl(`/conversation/${notification.referenceId}`);
        break;
      default:
        break;
    }
    if (this.isClickable(notification.type)) this.dismissNotification(notification);
    this.readNotification(notification.id).subscribe();
  }

  // Is Clickable
  isClickable(type: string): boolean {
    const types = ['PROSPECT', 'PROPOSAL', 'INVOICE', 'UPDATE', 'EXTERNAL', 'SMS', 'CALLS'];
    return types.includes(type);
  }

  // Refresh Notifications
  refreshNotifications(): void {
    this.getNotifications().subscribe((notifications) => {
      this.notifications = notifications.filter(notif => !(notif.type == 'SMS' || notif.type == 'CALLS'));
      this.conversationNotifications = notifications.filter(notif => notif.type == 'SMS' || notif.type == 'CALLS');
      this.notificationsUpdated.emit();
    });
  }

  /* ----- Notification Requests ----- */

  // Delete Notification
  deleteNotification(notificationId: string): Observable<any> {
    return this.http.delete<any>(`/notifications/${notificationId}`);
  }

  // Delete All Notifications
  deleteAllNotifications(): Observable<any> {
    return this.http.delete<any>(`/notifications/deleteAll`);
  }

  // Get Notifications
  getNotifications(): Observable<Notification[]> {
    return this.http.get<Notification[]>(`/notifications`);
  }

  // Send Notifications
  sendNotifications(notification: NotificationRequest): Observable<string> {
    return this.http.post<string>(`/notifications`, notification);
  }
  
  // Send Mobile push Notification
  pushNotification(notification: NotificationRequest): Observable<string> {
    return this.http.post<string>(`/sendfirebasenotif`, notification);
  }

  // Read Notification
  readNotification(notificationId: string): Observable<any> {
    return this.http.put<any>(`/notifications/${notificationId}/read`, null);
  }

  // Read All Notifications
  readAllNotifications(): Observable<any> {
    return this.http.put<any>(`/notifications/readAll`, null);
  }

  /* ----- Notification Container ----- */

  // Open Notification Container
  openNotificationContainer(): void {
    this.offcanvasService.open(NotificationCenterComponent, { position: 'end' });
  }

  openConversationNotificationContainer(): void {
    this.canvasRef = this.offcanvasService.open(NotificationCenterComponent, { position: 'end' });
    this.canvasRef.componentInstance.isSMS = true;
  }
}
