import { Component, OnInit, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AlertService } from '../alert.service';
import { Client } from '../client';
import { ClientService } from '../client.service';
import { Contact } from '../contact';
import { ContactService } from '../contact.service';
import { Site } from '../site';
import { SiteService } from '../site.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faAngleLeft, faSave, faTrashCan, faStar, faDownload, faTriangleExclamation, faPlus, faSortUp, faSortDown, faFilter } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { InvoiceService } from '../invoice.service';
import { NewSiteModalComponent } from '../new-site-modal/new-site-modal.component';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { TableControlService } from '../table-control.service';
import { MediaLightboxComponent } from '../media-lightbox/media-lightbox.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css']
})
export class ClientDetailComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Modal
  private modalReference: NgbModalRef;

  // Client
  clientForm: UntypedFormGroup;
  client: Client;
  clientId: string;

  // Contacts
  contacts: Contact[];

  // Sites
  sites: Site[] = [];

  // Lead Type Typeahead
  leadTypeTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  leadTypeTypeaheadInputFocus$ = new Subject<string>();

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faSave = faSave;
  faTrashCan = faTrashCan;
  faStar = faStar;
  faDownload = faDownload;
  faTriangleExclamation = faTriangleExclamation;
  faPlus = faPlus;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;

  // Properties
  isMobileView: boolean = false;

  // Table Controls
  tableControls: TableControlService;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(public location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private contactService: ContactService,
    private siteService: SiteService,
    private alertService: AlertService,
    private modalService: NgbModal,
    public sanitizer: DomSanitizer) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.isMobileView = true;
        } else {
          this.isMobileView = false;
        }
      });
     }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    this.clientForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      type: new UntypedFormControl('RESIDENTIAL'),
      leadType: new UntypedFormControl(),
      status: new UntypedFormControl('PROSPECT'),
      street: new UntypedFormControl(),
      city: new UntypedFormControl(),
      county: new UntypedFormControl(),
      state: new UntypedFormControl('SAS'),
      postalCode: new UntypedFormControl()
    });
    this.getClient();
    this.getContactsForClient();
    this.prepareLeadTypeTypeahead();
    if (window.innerWidth <= 768) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
    this.resizeSubject.unsubscribe();
  }

  // Open Contact Modal
  openContactModal(shouldBePrimary = false, contact: Contact = null): void {
    this.modalReference = this.modalService.open(ContactModalComponent);
    this.modalReference.componentInstance.clientId = this.clientId;
    this.modalReference.componentInstance.selectedContact = contact;
    this.modalReference.componentInstance.shouldBePrimary = shouldBePrimary;
    this.modalReference.componentInstance.saved.subscribe(() => {
      this.getContactsForClient();
    });
  }

  // Open New Site Modal
  openNewSiteModal(): void {
    this.modalReference = this.modalService.open(NewSiteModalComponent);
    this.modalReference.componentInstance.client = this.client;
    this.modalReference.componentInstance.siteCreated.subscribe((siteId: string) => {
      this.router.navigateByUrl(`/sites/${siteId}`);
    });
  }

  // Open Delete Client Confirmation Modal
  openDeleteClientConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this client?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteClient();
    });
  }


  // Get Client
  getClient(): void {
    this.clientService.getClient(this.clientId).subscribe((client) => {
      this.client = client;
      this.setClientFormValues(client);
    });
  }

  // Set Client Form Values
  setClientFormValues(client: Client): void {
    this.clientForm.controls.name.setValue(client.name);
    this.clientForm.controls.type.setValue(client.type);
    this.clientForm.controls.leadType.setValue(client.leadType);
    this.clientForm.controls.status.setValue(client.status);
    this.clientForm.controls.street.setValue(client.address.street);
    this.clientForm.controls.city.setValue(client.address.city);
    this.clientForm.controls.county.setValue(client.address.county);
    this.clientForm.controls.state.setValue(client.address.state);
    this.clientForm.controls.postalCode.setValue(client.address.postalCode);
  }

  // Update Client
  updateClient(): void {
    if (this.clientForm.valid) {
      const client = {
        id: this.clientId,
        name: this.clientForm.value.name,
        address: {
          street: this.clientForm.value.street,
          city: this.clientForm.value.city,
          county: this.clientForm.value.county,
          state: this.clientForm.value.state,
          postalCode: this.clientForm.value.postalCode,
        },
        type: this.clientForm.value.type,
        leadType: this.clientForm.value.leadType,
        status: this.clientForm.value.status
      };
      this.clientService.updateClient(client).subscribe(() => {
        this.alertService.showSuccessAlert('Client Updated');
        this.getClient();
      });
    } else {
      this.clientForm.markAllAsTouched();
    }
  }

  // Delete Client
  deleteClient(): void {
    this.clientService.deleteClient(this.clientId).subscribe(() => {
      this.location.back();
    });
  }

  // Get Lead Types
  private getLeadTypes(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'client_lead_type',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'client_lead_type',
      hideBlanks: true
    };
    return this.clientService.getDistinctColumnValues(params);
  }

  // Prepare Lead Type Typeahead
  private prepareLeadTypeTypeahead(): void {
    this.leadTypeTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.leadTypeTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getLeadTypes((term.length == 0) ? null : term);
      }));
    }
  }

  /* ----- Client Form Accessors ----- */

  get name() { return this.clientForm.controls.name; }
  get type() { return this.clientForm.controls.type; }
  get leadType() { return this.clientForm.controls.leadType; }
  get status() { return this.clientForm.controls.status; }

  /* ----- Contacts ----- */

  // Get Contacts For Client
  getContactsForClient(): void {
    const params = {
      searchTerm: null,
      sortBy: 'contact_first_name',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:client_id': JSON.stringify([this.clientId])
    };
    this.contactService.getContacts(params).subscribe((res) => {
      this.contacts = res.contacts;
    });
  }

  /* ----- Sites ----- */

  // Get Sites For Client
  getSitesForClient(): void {
    const params = {
      searchTerm: null,
      sortBy: 'site_name',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:client_id': JSON.stringify([this.clientId])
    };
    this.siteService.getSites(params).subscribe((res) => {
      this.sites = res.sites;
    });
  }

}
