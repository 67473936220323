<div class="modal-header">
    <h5 class="modal-title">Update Subscription</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="subscriptionForm">
        <div class="row mt-3">
            <div *ngFor="let subscription of subscriptions" class="col">
                <div class="card" 
                [class.selected]="selectedSubscription && subscription.id == selectedSubscription.subscriptionId"
                [class.border-success]="newSubscription && subscription.id == newSubscription.id" style="height: 100%;">
                    <h5 class="card-header">{{subscription.name}}</h5>
                    <div class="card-body d-flex flex-column">
                        <div>
                            <h4 *ngIf="!subscription.isDefault" class="text-muted old-price me-2"><sup>$</sup>{{subscription.defaultPrice}}</h4>
                            <h3 style="display: inline-block;">
                                <sup>$</sup>
                                <span>{{subscription.price}}</span>
                                <span *ngIf="subscription.frequency == 'MONTHLY'" style="font-size: 16px;">/month</span>
                                <span *ngIf="subscription.frequency == 'ANNUALLY'" style="font-size: 16px;">/year</span>
                            </h3>
                        </div>
                        <div *ngIf="selectedPromotion && displayTagline(subscription)" class="text-success">{{selectedPromotion.tagline}}</div>
                        <div class="text-muted my-2" [class.mt-0]="selectedPromotion && displayTagline(subscription)">{{subscription.description}}</div>
                        <div *ngIf="(!newSubscription || subscription.id != newSubscription.id) && (selectedSubscription && subscription.id != selectedSubscription.subscriptionId)" class="d-flex mt-auto">
                            <button type="button" class="btn btn-primary mx-auto" (click)="selectSubscription(subscription);">Select</button>
                        </div>
                        <div *ngIf="newSubscription && subscription.id == newSubscription.id" class="d-flex mt-auto mx-auto">
                            <h6 class="font-italic"><fa-icon [icon]="faCheck"></fa-icon> Selected</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <div class="input-group">
                    <input id="PROMO_CODE" type="text" class="form-control" placeholder="CODE" style="text-transform: uppercase;" (keyup)="applyPromoCode($event);">
                    <button type="button" class="btn btn-outline-primary" (click)="applyPromoCode();">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                </div>
                <div class="col" *ngIf="errorMsg">
                    <label class="text-danger">
                        {{ errorMsg }}
                    </label>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveSelection()" [disabled]="!newSubscription">
        Save</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
</div>