<div class="modal-header">
    <h5 class="modal-title">SMS and Call Terms and Condition</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <div *ngIf="switchedOn; else switchedOff">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
        nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse 
        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui 
        officia deserunt mollit anim id est laborum
    </div>
    <ng-template #switchedOff>
        Are you surre you would like to turned off these features? You will not be able to receive SMS and call but you will still be charge if you have active numbers on your account. You can turn it back on anytime.
    </ng-template>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="acceptTerms();">
        <span>{{ switchedOn ? 'Agree':'Continue' }}</span>
    </button>
    <button type="button" class="btn btn-secondary ms-2" (click)="modal.dismiss();">Cancel</button>
</div>