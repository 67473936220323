import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCheck, faCircleExclamation, faCircleNotch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { lastValueFrom, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert.service';
import { Invoice } from '../invoice';
import { PaymentService } from '../payment.service';
import { SettingService } from '../setting.service';
import { TinselPayVault } from '../tinsel-pay-vault';

@Component({
  selector: 'app-tinsel-pay-card-payment-form',
  templateUrl: './tinsel-pay-card-payment-form.component.html',
  styleUrls: ['./tinsel-pay-card-payment-form.component.css']
})
export class TinselPayCardPaymentFormComponent implements OnInit {

  // Properties
  @Input() clientId: string;
  @Input() invoice: Invoice;
  @Input() defaultAmount: number;
  @Output() paymentSuccessful: EventEmitter<void> = new EventEmitter<void>();
  tokenizationKey: string;
  amount: string;
  remainingBalance: number = Infinity;

  passFee: boolean = false;
  passFeePercent: number = 0;
  fee: number = 0;
  amountWithFee: number = 0;
  showFeeCalculation: boolean = false;
  paymentFormIsLoading: boolean = true;

  confirmedAmount: string;

  amountConfirmed: boolean = false;
  paymentInProgress: boolean = false;
  paymentButtonText: string;
  paymentFormValidation: any = {
    ccnumber: {
      isValid: false,
      hasError: false,
      errorMessage: null
    },
    ccexp: {
      isValid: false,
      hasError: false,
      errorMessage: null
    },
    cvv: {
      isValid: false,
      hasError: false,
      errorMessage: null
    }
  };
  paymentProcessingError: any;

  // Card Vault
  tinselPayCardVaults: TinselPayVault[] = [];
  selectedCardVaultId: string;

  // Font Awesome Properties
  faCheck = faCheck;
  faCircleExclamation = faCircleExclamation;
  faTrash = faTrash;
  faCircleNotch = faCircleNotch;

  constructor(private paymentService: PaymentService,
    private settingService: SettingService,
    private alertService: AlertService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.amount = this.defaultAmount.toFixed(2);
  }

  async ngAfterViewInit(): Promise<void> {
    const tinselPayFormSettings = await lastValueFrom(this.settingService.getTinselPayFormSettings(this.invoice.organizationId));
    this.tokenizationKey = tinselPayFormSettings.tokenizationKey;
    this.passFee = tinselPayFormSettings.passFee;
    await this.loadCollectJsScript();
  }

  // Amount Changed
  amountChanged(event: Event): void {
    this.amount = (event.target as HTMLInputElement).value;
    this.remainingBalance = this.invoice.amountDue - parseFloat(this.amount);
  }

  // Load CollectJs Script
  private loadCollectJsScript(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (document.getElementById('COLLECT_JS_SCRIPT') !== null) return resolve();
      const script = document.createElement('script');
      script.id = 'COLLECT_JS_SCRIPT';
      script.type = 'text/javascript';
      script.src = 'https://secure.cardflexonline.com/token/Collect.js';
      script.async = true;
      script.defer = true;
      script.dataset.tokenizationKey = this.tokenizationKey;
      script.addEventListener('load', () => {
        resolve();
      });
      script.addEventListener('error', (error) => {
        reject(error);
      });
      document.body.appendChild(script);
    });
  }

  // Confirm Amount With Card
  confirmAmountWithCard(): void {
    this.remainingBalance = this.invoice.amountDue - parseFloat(this.amount);
    if (this.remainingBalance > 0 && this.remainingBalance < 1) return;
    this.calculateCardProcessingFee(this.amount);
    this.confirmedAmount = (this.passFee) ? this.amountWithFee.toFixed(2) : (document.getElementById('AMOUNT') as HTMLInputElement).value;
    this.amountConfirmed = true;
    this.getTinselPayCardVaults();
    setTimeout(() => {
      this.initializeCardForm();
    }, 250);
  }

  // Get Tinsel Pay Card Vaults
  private getTinselPayCardVaults(): void {
    this.paymentService.getTinselPayVaults(this.clientId, 'CARD').subscribe((vaults) => {
      this.tinselPayCardVaults = vaults;
    });
  }

  // Delete Tinsel Pay Card Vault
  deleteTinselPayCardVault(vaultId: string): void {
    this.paymentService.deleteTinselPayCardVault(this.invoice.organizationId, vaultId).subscribe(() => {
      this.alertService.showSuccessAlert('Card Deleted');
      this.getTinselPayCardVaults();
    });
  }

  // Card Vault Selected
  cardVaultSelected(id: string): void {
    if (this.selectedCardVaultId == id) {
      this.selectedCardVaultId = null;
      this.paymentFormIsLoading = true;
      setTimeout(() => {
        this.initializeCardForm();
      }, 250);
      return;
    }
    this.selectedCardVaultId = id;
  }

  // Initialize Card Form
  private initializeCardForm(): void {
    (window as any).CollectJS.configure({
      variant: 'inline',
      fields: {
        ccnumber: {
          placeholder: '0000 0000 0000 0000'
        },
        ccexp: {
          placeholder: 'MM/YY'
        },
        cvv: {
          placeholder: 'CVV'
        }
      },
      styleSniffer: false,
      googleFont: 'Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
      customCss: {
        'height': '40px',
        'padding': '0.375rem 0.75rem',
        'font-family': "'Poppins', sans-serif",
        'font-size': '1rem',
        'font-weight': '400',
        'line-height': '1.5',
        'color': '#212529',
        'background-clip': 'padding-box',
        'border': '1px solid #CED4DA',
        '-webkit-appearance': 'none',
        'appearance': 'none',
        'border-radius': '0.25rem'
      },
      invalidCss: {
        'border-color': '#DC3545'
      },
      placeholderCss: {
        'color': 'rgb(200,200,200)',
        'opacity': '1',
        'font-family': "'Poppins', sans-serif"
      },
      fieldsAvailableCallback: () => {
        this.paymentFormIsLoading = false;
        this.changeDetectorRef.detectChanges();
      },
      validationCallback: (field, status, message) => {
        this.paymentFormValidation[field].isValid = status;
        this.paymentFormValidation[field].hasError = !status;
        if (!status) {
          if ((message as string).includes('Field')) {
            if (field == 'ccnumber') this.paymentFormValidation[field].errorMessage = `Card Number: ${message}.`;
            else if (field == 'ccexp') this.paymentFormValidation[field].errorMessage = `Expiration: ${message}.`;
            else if (field == 'cvv') this.paymentFormValidation[field].errorMessage = `CVV: ${message}.`;
            else this.paymentFormValidation[field].errorMessage = `Other: ${message}.`;
          } else {
            this.paymentFormValidation[field].errorMessage = message + '.';
          }
        } else {
          this.paymentFormValidation[field].errorMessage = null;
        }
        this.changeDetectorRef.detectChanges();
      },
      callback: (response) => {
        this.processTinselPayCardPayment(response.token);
      }
    });
    this.paymentButtonText = `Pay $${this.confirmedAmount}`;
  }

  // Pay With Card
  payWithCard(): void {
    this.paymentInProgress = true;
    this.paymentButtonText = 'Processing...';
    if (!this.selectedCardVaultId) (window as any).CollectJS.startPaymentRequest();
    else this.processTinselPayCardPayment();
  }

  // Process Tinsel Pay Card Payment
  private processTinselPayCardPayment(token: string = null): void {
    const data: any = {
      organizationId: this.invoice.organizationId,
      invoiceId: this.invoice.id,
      type: (this.selectedCardVaultId) ? 'VAULT' : 'MANUAL',
      amount: this.amount,
      fee: this.fee,
      confirmedAmount: this.confirmedAmount
    };
    if (this.selectedCardVaultId) {
      data.vaultId = this.selectedCardVaultId;
    } else {
      data.token = token;
      data.saveCard = (document.getElementById('SAVE_CARD') as HTMLInputElement).checked;
    }
    this.paymentService.processTinselPayCardSale(data).pipe(catchError((error: HttpErrorResponse) => {
      if (error.error.category == 'TINSEL_PAY_CARD_PAYMENT' && error.error.description) {
        this.paymentProcessingError = { message: error.error.message, description: error.error.description };
      } else {
        this.paymentProcessingError = null;
      }
      this.paymentButtonText = `Pay $${this.confirmedAmount}`;
      this.paymentInProgress = false;
      this.changeDetectorRef.detectChanges();
      return throwError(() => new Error('API Request Error'));
    })).subscribe(() => {
      this.alertService.showSuccessAlert('Payment Successful');
      this.paymentSuccessful.emit();
    });
  }

  // Is Payment Form Valid
  isPaymentFormValid(): boolean {
    return this.paymentFormValidation.ccnumber.isValid
      && this.paymentFormValidation.ccexp.isValid
      && this.paymentFormValidation.cvv.isValid;
  }

  // Does Payment Form Have Error
  doesPaymentFormHaveError(): boolean {
    return this.paymentFormValidation.ccnumber.hasError
      || this.paymentFormValidation.ccexp.hasError
      || this.paymentFormValidation.cvv.hasError;
  }

  // Calculate Card Processing Fee
  private calculateCardProcessingFee(amount: number | string): void {
    this.showFeeCalculation = this.passFee && (typeof amount == 'number' || (amount as string).length !== 0);
    if (typeof amount == 'string') amount = parseFloat(amount);
    this.passFeePercent = environment.tinselPay.passFeePercent;
    this.fee = this.round(amount * (this.passFeePercent / 100), 2);
    this.amountWithFee = this.round(amount + this.fee, 2);
  }

  // Round
  private round(value: number, precision: number = 0): number {
    var multiplier = Math.pow(10, precision);
    return Math.round(value * multiplier) / multiplier;
  }

  // Get Card Payment Icon
  getCardPaymentIcon(brand: string): string {
    return this.paymentService.getCardPaymentIcon(brand);
  }
}
