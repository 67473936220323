import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sms-call-terms-modal',
  templateUrl: './sms-call-terms-modal.component.html',
  styleUrls: ['./sms-call-terms-modal.component.css']
})
export class SmsCallTermsModalComponent {
  @Output() agree: EventEmitter<any> = new EventEmitter<any>();
  switchedOn!: boolean;

  constructor(public modal: NgbActiveModal) {}

  acceptTerms() {
    this.agree.emit(true);
    this.modal.close(true)
  }

}
