<div class="content-container">
  <nav class="navbar navbar-white bg-white sticky-top">
    <div class="navbar-brand">
      <h2 *ngIf="user">Welcome, {{user.firstName}}!</h2>
    </div>
    <div style="margin-left: auto; margin-right: 10px;">
      <span *ngIf="unreadNotificationCount > 0" class="custom-badge-lg" style="top: 2px; right: -8px;"
        [style]="{ 'padding': unreadNotificationCount > 9 ? '0.125rem 0.25rem' : '0' }">{{unreadNotificationCount}}</span>
      <button aria-label="Notifications" type="button" class="btn btn-outline-primary"
        style="padding: 0.5625rem 0.75rem;" (click)="openConversationNotificationContainer();">
        <fa-icon [icon]="faMobile" size="xl"></fa-icon>
      </button>
    </div>
    <div>
      <span *ngIf="unreadNotificationCount > 0" class="custom-badge-lg" style="top: 2px; right: -8px;"
        [style]="{ 'padding': unreadNotificationCount > 9 ? '0.125rem 0.25rem' : '0' }">{{unreadNotificationCount}}</span>
      <button aria-label="Notifications" type="button" class="btn btn-outline-primary"
        style="padding: 0.5625rem 0.75rem;" (click)="openNotificationContainer();">
        <fa-icon [icon]="faBell" size="xl"></fa-icon>
      </button>
    </div>
  </nav>
  <div class="banner mt-2" *ngIf="login?.isInTrial">Your free trial of Tinsel CRM will expire in <strong>{{ login?.daysLeft | number: '1.0-0' }} days</strong> and you will
    be automatically charged. If you are enjoying Tinsel CRM and plan to continue using it, there is no action needed.
    If you are no longer interested in using Tinsel CRM, please cancel your subscription before the trial is up.
    <strong>There will be no refunds given after you are automatically charged in {{ login?.daysLeft | number: '1.0-0' }} days.</strong> To cancel your
    subscription, please submit a support ticket on the Help Desk.
    <span *ngIf="user?.role == 'SUPER'">To edit your subscription, <a class="link cursor-pointer" (click)="redirectToSubs()">CLICK HERE.</a></span>
  </div>
  <div class="row">
    <div
      [ngClass]="user && (user.hasSalesAccess || !user.hasOwnProperty('hasSalesAccess')) ? 'col-lg-6 col-xl-4' : 'col-lg-6 col-xl-6'">
      <app-dashboard-prospects></app-dashboard-prospects>
    </div>
    <div class="pe-0 pe-xl-2"
      [ngClass]="user && (user.hasSalesAccess || !user.hasOwnProperty('hasSalesAccess')) ? 'col-lg-6 col-xl-4' : 'col-lg-6 col-xl-6'">
      <app-dashboard-up-next></app-dashboard-up-next>
    </div>
    <div *ngIf="user && (user.hasSalesAccess || !user.hasOwnProperty('hasSalesAccess'))" class="pe-2 pe-xl-0"
      [ngClass]="user && (user.hasSalesAccess || !user.hasOwnProperty('hasSalesAccess')) ? 'col-lg-6 col-xl-4' : 'col-lg-6 col-xl-6'">
      <app-dashboard-stats></app-dashboard-stats>
    </div>
  </div>
</div>

<!-- Notifications Popover -->
<ng-template *ngIf="!login.isMobile" #popContentNotifications>
  <app-dashboard-notifications></app-dashboard-notifications>
</ng-template>
