<div class="card mt-3">
    <div class="card-header d-xl-flex">
        <h5>Up Next ({{scheduleItems.length}})</h5>
        <small class="text-muted text-end ms-auto">{{startDate}} to<br>{{endDate}}</small>
    </div>
    <div class="card-body">
        <ul class="list-group scrollable" style="height: 368px;">
            <li *ngFor="let scheduleItem of scheduleItems" class="list-group-item px-3 py-2" style="cursor: pointer;" routerLink="/projects/{{scheduleItem.projectId}}">
                <div class="d-flex">
                    <div class="my-auto" style="width: calc(100% - 20px);">
                        <strong *ngIf="scheduleItem.type != 'CUSTOM'">{{scheduleItem.projectName}}</strong>
                        <strong *ngIf="scheduleItem.type == 'CUSTOM'">{{scheduleItem.name}}</strong>
                        <div class="d-flex">
                            <div *ngIf="scheduleItem.clientId" style="text-overflow: ellipsis; overflow-x: hidden; white-space: nowrap;">{{scheduleItem.clientName}}</div>
                            <div *ngIf="!scheduleItem.clientId" style="text-overflow: ellipsis; overflow-x: hidden; white-space: nowrap;">No Client Assigned</div>
                        </div>
                        <small *ngIf="scheduleItem.isAllDay" class="text-muted">{{scheduleItem.date | formatDateTime: true}}</small>
                        <small *ngIf="!scheduleItem.isAllDay" class="text-muted">{{scheduleItem.date | formatDateTime}} to {{scheduleItem.endDate | formatDateTime}}</small>
                    </div>
                    <div class="ms-auto my-auto">
                        <fa-icon 
                        [icon]="getScheduleItemTypeIcon(scheduleItem.type)" size="lg" [style.color]="scheduleItem.crews && scheduleItem.crews.length && scheduleItem.crews[0]?.crewColor > 0 ? scheduleItem.crews[0].crewColor: '#000000'" [ngbTooltip]="getCrewsNames(scheduleItem.crews)" [disableTooltip]="scheduleItem.type == 'CUSTOM'"></fa-icon>
                    </div>
                </div>
            </li>
            <li *ngIf="scheduleItems.length == 0" class="list-group-item">No Projects Up Next</li>
        </ul>
    </div>
</div>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
    <div ngbDropdown class="d-inline-block" [autoClose]="true">
        <button class="btn btn-outline-primary" ngbDropdownToggle ngbTooltip="Sort">
            <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
        </button>
        <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="sort('sc.schedule_item_position');">
                Date
                <fa-icon *ngIf="sortBy == 'sc.schedule_item_position'" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
            </button>
            <button ngbDropdownItem (click)="sort('c.client_name');">
                Client
                <fa-icon *ngIf="sortBy == 'c.client_name'" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
            </button>
        </div>
    </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
    <div ngbDropdown class="d-inline-block" autoClose="outside" placement="bottom-right">
        <button class="btn btn-outline-primary" ngbDropdownToggle ngbTooltip="Filter">
            <fa-icon [icon]="faFilter"></fa-icon> ({{activeFilterCount}})
        </button>
        <div ngbDropdownMenu>
            <!-- Color -->
            <button ngbDropdownItem (click)="this.getDistinctColumnValues('cc.hex_color'); colorCollapse.toggle();">Color</button>
            <div class="px-4 py-1" #colorCollapse="ngbCollapse" [ngbCollapse]="true">
                <div *ngFor="let element of columnFilterValues.get('cc.hex_color');" class="form-check form-check-inline">
                    <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter('cc.hex_color', element.value);" [checked]="filterExists('cc.hex_color', element.value)">
                    <label [for]="element.randomId" class="form-check-label">
                        <fa-icon [icon]="faCircle" class="my-auto countyColorIcon" size="xs" [style.color]="element.value"></fa-icon>
                    </label>
                </div>
            </div>
            <!-- Client -->
            <button ngbDropdownItem (click)="this.getDistinctColumnValues('c.client_name'); clientCollapse.toggle();">Client</button>
            <div class="px-4 py-1" #clientCollapse="ngbCollapse" [ngbCollapse]="true">
                <div class="d-flex flex-column">
                    <div *ngFor="let element of columnFilterValues.get('c.client_name');" class="form-check form-check-inline">
                        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter('c.client_name', element.value);" [checked]="filterExists('c.client_name', element.value)">
                        <label [for]="element.randomId" class="form-check-label" style="white-space: nowrap;">{{element.value}}</label>
                    </div>
                </div>
            </div>
            <div class="dropdown-divider"></div>
            <!-- Reset -->
            <button ngbDropdownItem (click)="resetActiveFilters();">Reset</button>
        </div>
    </div>
</ng-template>