import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BugReport } from './bug-report';

@Injectable({
  providedIn: 'root'
})
export class BugReportService {

  constructor(private http: HttpClient) { }

  // Add Bug Report
  addBugReport(body: any): Observable<void> {
    return of(body)
    // return this.http.post<void>(`/bugReports/`, body);
    // return this.http.post<void>(`/bugReports/`, body);
  }



  // Get Bug Reports For User
  getBugReportsForUser(): Observable<BugReport[]> {
    return this.http.get<BugReport[]>(`/bugReports/user`);
  }
}
