<div class="modal-header">
    <h5 class="modal-title">Update Card on File</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <div *ngIf="paymentFormIsLoading" class="d-flex">
        <div class="mx-auto d-flex">
            <strong>Loading...</strong>
            <fa-icon [icon]="faCircleNotch" spin="true" class="ms-2"></fa-icon>
        </div>
    </div>
    <div class="row" [class.mb-2]="doesPaymentFormHaveError()">
        <div id="ccnumber" class="col-6"></div>
        <div id="ccexp" class="col-3"></div>
        <div id="cvv" class="col-3"></div>
    </div>
    <div class="d-flex flex-column">
        <small *ngIf="paymentFormValidation.ccnumber.hasError" class="input-validation-msg">
            <fa-icon [icon]="faCircleExclamation"></fa-icon>
            <span class="ms-2">{{paymentFormValidation.ccnumber.errorMessage}}</span>
        </small>
        <small *ngIf="paymentFormValidation.ccexp.hasError" class="input-validation-msg">
            <fa-icon [icon]="faCircleExclamation"></fa-icon>
            <span class="ms-2">{{paymentFormValidation.ccexp.errorMessage}}</span>
        </small>
        <small *ngIf="paymentFormValidation.cvv.hasError" class="input-validation-msg">
            <fa-icon [icon]="faCircleExclamation"></fa-icon>
            <span class="ms-2">{{paymentFormValidation.cvv.errorMessage}}</span>
        </small>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveCardOnFile();" [disabled]="!isPaymentFormValid() || saveInProgress">
        <span>Save</span>
        <fa-icon *ngIf="saveInProgress" [icon]="faRotate" [spin]="true" class="button-icon-right"></fa-icon>
    </button>
    <button type="button" class="btn btn-secondary ms-2" (click)="modal.dismiss();">Cancel</button>
</div>