<div class="modal-header">
    <h5 class="modal-title">New Client</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="clientForm">
        <div class="mb-3">
            <label for="CLIENT_NAME">Name</label>
            <input id="CLIENT_NAME" type="text" class="form-control" formControlName="name" placeholder="John Appleseed" maxlength="250" required [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
                <div *ngIf="name.errors.required" class="input-validation-msg">Name is required.</div>
                <div *ngIf="name.errors.maxlength" class="input-validation-msg">Name cannot exceed 250 characters.</div>
            </div>
        </div>
        <label>Type</label>
        <div class="mb-2">
            <div class="form-check form-check-inline">
                <input id="CLIENT_TYPE_RESIDENTIAL" class="form-check-input" type="radio" formControlName="type" value="RESIDENTIAL">
                <label for="CLIENT_TYPE_RESIDENTIAL" class="form-check-label" style="font-weight: normal;">Residential</label>
            </div>
            <div class="form-check form-check-inline">
                <input id="CLIENT_TYPE_COMMERCIAL" class="form-check-input" type="radio" formControlName="type" value="COMMERCIAL">
                <label for="CLIENT_TYPE_COMMERCIAL" class="form-check-label" style="font-weight: normal;">Commercial</label>
            </div>
            <div class="form-check form-check-inline">
                <input id="CLIENT_TYPE_OTHER" class="form-check-input" type="radio" formControlName="type" value="OTHER">
                <label for="CLIENT_TYPE_OTHER" class="form-check-label" style="font-weight: normal;">Other</label>
            </div>
        </div>
        <div class="mb-3">
            <label for="CLIENT_LEAD_TYPE">Lead Type</label>
            <input *ngIf="leadTypeTypeaheadInput" id="CLIENT_LEAD_TYPE" type="text" class="form-control" formControlName="leadType" placeholder="Word of Mouth" maxlength="150" required [ngbTypeahead]="leadTypeTypeaheadInput" (focus)="leadTypeTypeaheadInputFocus$.next($any($event).target.value)" [class.is-invalid]="leadType.invalid && (leadType.dirty || leadType.touched)">
            <div *ngIf="leadType.invalid && (leadType.dirty || leadType.touched)">
                <div *ngIf="leadType.errors.required" class="input-validation-msg">Lead Type is required.</div>
                <div *ngIf="leadType.errors.maxlength" class="input-validation-msg">Lead Type cannot exceed 150 characters.</div>
            </div>
        </div>
        <div class="mb-3">
            <label for="CLIENT_STATUS">Status</label>
            <select id="CLIENT_STATUS" class="form-select" formControlName="status" required [class.is-invalid]="status.invalid && (status.dirty || status.touched)">
                <option value="PROSPECT">Prospect</option>
                <option value="WAIT LIST">Wait List</option>
                <option value="CUSTOMER">Customer</option>
                <option value="INACTIVE">Inactive</option>
            </select>
            <div *ngIf="status.invalid && (status.dirty || status.touched)">
                <div *ngIf="status.errors.required" class="input-validation-msg">Status is required.</div>
            </div>
        </div>
        <app-address-form [form]="clientForm"></app-address-form>
        <div class="d-flex mt-3">
            <div class="form-check mb-0">
                <input id="AUTO_CREATE_SITE" type="checkbox" class="form-check-input" formControlName="autoCreateSite">
                <label for="AUTO_CREATE_SITE" class="form-check-label">Auto Create Site</label>
            </div>
            <span class="ms-2" style="cursor: pointer;" ngbPopover="Selecting this option will automatically create a site with the same address as the client." triggers="click">
                <fa-icon [icon]="faQuestionCircle"></fa-icon>
            </span>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addClient();">Save</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>