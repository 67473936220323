import { Location } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { faAngleLeft, faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuditLogEvent } from '../audit-log-event';
import { AuditLogService } from '../audit-log.service';
import { TableControlService } from '../table-control.service';
import { AdminSettingService } from '../admin-setting.service';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.css']
})
export class AuditLogComponent implements OnInit {
  //Screensize Listener
  @HostListener('window:resize', ['$event.target.innerWidth'])
    onResize(width: number) {
      this.isMobile =  width <= 360 || width <= 768;
    }
  // Properties
  @ViewChild('AUDIT_LOG_EVENT_MODAL') private auditLogEventModal: ElementRef;
  private modalReference: NgbModalRef;
  auditLogEvents: AuditLogEvent[] = [];
  auditLogEventCount: number = 0;
  selectedAuditLogEvent: AuditLogEvent;
  selectedAuditLogEventRequestData: string;
  maxSize: number = 10;
  isMobile: boolean = false;

  // Table Controls
  tableControls: TableControlService;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faRotateLeft = faRotateLeft;

  constructor(private auditLogService: AuditLogService,
    private modalService: NgbModal,
    public location: Location) { }

  ngOnInit(): void {
    this.tableControls = new TableControlService('AuditLogEvents', true, 'date', 'DESC');
    this.tableControls.refresh.subscribe(() => {
      this.getAuditLogEvents();
    });
    this.getAuditLogEvents();

    this.isMobile =  window.innerWidth <= 360 || window.innerWidth <= 768;
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Get Audit Log Events
  private getAuditLogEvents(): void {
    const params = this.tableControls.getParams();
    this.auditLogService.getAuditLogEvents(params).subscribe((res) => {
      this.auditLogEvents = res.auditLogEvents;
      this.auditLogEventCount = res.count;
    });
  }

  // Select Audit Log Event
  selectAuditLogEvent(auditLogEvent: AuditLogEvent): void {
    this.selectedAuditLogEvent = auditLogEvent;
    this.selectedAuditLogEventRequestData = (this.selectedAuditLogEvent.requestData !== null) ? JSON.stringify(this.selectedAuditLogEvent.requestData, null, 2) : null;
    this.modalReference = this.modalService.open(this.auditLogEventModal, { scrollable: true });
  }
}
