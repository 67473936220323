import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectStage'
})
export class ProjectStagePipe implements PipeTransform {

  transform(stage: string): string {
    switch (stage) {
      case 'PROPOSAL':
        return 'Proposal';
      case 'INVOICE':
        return 'Invoice';
      case 'PREPARATION':
        return 'Inventory Prep';
      case 'INSTALLATION':
        return 'Installation';
      case 'BASES':
        return 'Bases';
      case 'REMOVAL':
        return 'Removal';
      case 'REVIEWS':
        return 'Reviews';
      default:
        return 'Unknown';
    }
  }
}
