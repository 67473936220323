import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin.guard';

import { AppComponent } from './app.component';
import { App2Component } from './app2.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientDetailComponent } from './client-detail/client-detail.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { ProjectSummaryComponent } from './project-summary/project-summary.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoiceViewComponent } from './invoice-view/invoice-view.component';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import { SiteDetailComponent } from './site-detail/site-detail.component';
import { InventoryComponent } from './inventory/inventory.component';
import { SettingsComponent } from './settings/settings.component';
import { ProposalDetailComponent } from './proposal-detail/proposal-detail.component';
import { ProposalViewComponent } from './proposal-view/proposal-view.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ProjectPrepOverviewComponent } from './project-prep-overview/project-prep-overview.component';
import { SnapshotComponent } from './snapshot/snapshot.component';
import { TimeClockComponent } from './time-clock/time-clock.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsPayrollSummaryComponent } from './reports-payroll-summary/reports-payroll-summary.component';
import { ReportsAtAGlanceComponent } from './reports-at-a-glance/reports-at-a-glance.component';
import { ReportsAccountsReceivableComponent } from './reports-accounts-receivable/reports-accounts-receivable.component';
import { ReportsStorageLocationsComponent } from './reports-storage-locations/reports-storage-locations.component';
import { ReportsLastProposalAcceptedDateComponent } from './reports-last-proposal-accepted-date/reports-last-proposal-accepted-date.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RegistrationComponent } from './registration/registration.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { BugReportComponent } from './bug-report/bug-report.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { UserTimeClockComponent } from './user-time-clock/user-time-clock.component';
import { TinselPayOverviewComponent } from './tinsel-pay-overview/tinsel-pay-overview.component';
import { ReportsPaymentsComponent } from './reports-payments/reports-payments.component';
import { ReportsProposalsComponent } from './reports-proposals/reports-proposals.component';
import { ReportsProjectBalancesComponent } from './reports-project-balances/reports-project-balances.component';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { ReportsPreferredInstallationAndRemovalDatesComponent } from './reports-preferred-installation-and-removal-dates/reports-preferred-installation-and-removal-dates.component';
import { ReportsInvoicesComponent } from './reports-invoices/reports-invoices.component';
import { ReportsSalesTaxInvoicedComponent } from './reports-sales-tax-invoiced/reports-sales-tax-invoiced.component';
import { ReportsSalesTaxPaidComponent } from './reports-sales-tax-paid/reports-sales-tax-paid.component';
import { MockupToolComponent } from './mockup-tool/mockup-tool.component';
import { ReportsTimeClockComponent } from './reports-time-clock/reports-time-clock.component';
import { PendingChangesGuard } from './pending-changes.guard';
import { ChangeSubscriptionPageComponent } from './change-subscription-page/change-subscription-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
  { path: '404', component: NotFoundComponent },
  {
    path: '',
    component: AppComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegistrationComponent },
      { path: 'forgot', component: ForgotPasswordComponent },
      { path: 'proposals/:proposalId/preview', component: ProposalViewComponent },
      { path: 'proposals/:proposalId/view', component: ProposalViewComponent },
      { path: 'invoices/:invoiceId/view', component: InvoiceViewComponent }
    ]
  },
  {
    path: '',
    component: App2Component,
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'clients', component: ClientsComponent, canActivate: [AuthGuard] },
      { path: 'clients/:clientId', component: ClientDetailComponent, canActivate: [AuthGuard] },
      { path: 'projects', component: ProjectsComponent, canActivate: [AuthGuard] },
      { path: 'projects/:projectId', component: ProjectDetailComponent, canActivate: [AuthGuard] },
      { path: 'projects/:projectId/summary', component: ProjectSummaryComponent, canActivate: [AuthGuard] },
      { path: 'proposals/:proposalId', component: ProposalDetailComponent, canActivate: [AuthGuard] },
      { path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuard] },
      { path: 'invoices/:invoiceId', component: InvoiceDetailComponent, canActivate: [AuthGuard] },
      { path: 'sites/:siteId', component: SiteDetailComponent, canActivate: [AuthGuard] },
      { path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard] },
      { path: 'projectPrepOverview', component: ProjectPrepOverviewComponent, canActivate: [AuthGuard] },
      { path: 'schedule', component: ScheduleComponent, canActivate: [AuthGuard], canDeactivate:[PendingChangesGuard] },
      { path: 'projectGlance', component: SnapshotComponent, canActivate: [AuthGuard] },
      { path: 'timeClock', component: TimeClockComponent, canActivate: [AuthGuard] },
      { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard, AdminGuard] },
      { path: 'userSettings', component: UserSettingsComponent, canActivate: [AuthGuard] },
      { path: 'userTimeClock', component: UserTimeClockComponent, canActivate: [AuthGuard] },
      // { path: 'bugReport', component: BugReportComponent, canActivate: [AuthGuard] },
      { path: 'tinselPayOverview', component: TinselPayOverviewComponent, canActivate: [AuthGuard] },
      { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'auditLog', component: AuditLogComponent, canActivate: [AuthGuard, AdminGuard] },
      { path: 'mockupTool', component: MockupToolComponent, canActivate: [AuthGuard] },
      // Reports - Clients
      { path: 'reports/last-proposal-accepted-date', component: ReportsLastProposalAcceptedDateComponent, canActivate: [AuthGuard] },
      // Reports - Finance
      { path: 'reports/accounts-receivable', component: ReportsAccountsReceivableComponent, canActivate: [AuthGuard] },
      { path: 'reports/at-a-glance', component: ReportsAtAGlanceComponent, canActivate: [AuthGuard] },
      { path: 'reports/invoices', component: ReportsInvoicesComponent, canActivate: [AuthGuard] },
      { path: 'reports/partially-invoiced-projects', component: ReportsProjectBalancesComponent, canActivate: [AuthGuard] },
      { path: 'reports/payroll-summary', component: ReportsPayrollSummaryComponent, canActivate: [AuthGuard] },
      { path: 'reports/payments', component: ReportsPaymentsComponent, canActivate: [AuthGuard] },
      { path: 'reports/sales-tax-invoiced', component: ReportsSalesTaxInvoicedComponent, canActivate: [AuthGuard] },
      { path: 'reports/sales-tax-paid', component: ReportsSalesTaxPaidComponent, canActivate: [AuthGuard] },
      { path: 'reports/time-clock', component: ReportsTimeClockComponent, canActivate: [AuthGuard] },
      // Reports - Projects
      { path: 'reports/storage-locations', component: ReportsStorageLocationsComponent, canActivate: [AuthGuard] },
      { path: 'reports/proposals', component: ReportsProposalsComponent, canActivate: [AuthGuard] },
      // Reports - Schedule
      { path: 'reports/preferredInstallationAndRemovalDates', component: ReportsPreferredInstallationAndRemovalDatesComponent, canActivate: [AuthGuard] },
      { path: 'change-subscription', component: ChangeSubscriptionPageComponent, canActivate: [AuthGuard] },
      // helpdesk
      {
        path: 'helpdesk',
        loadChildren: () => import('./helpdesk/helpdesk.module').then(m => m.HelpdeskModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'conversation',
        loadChildren: () => import('./conversation/conversation.module').then(m => m.ConversationModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'conversation/:id',
        loadChildren: () => import('./conversation/conversation.module').then(m => m.ConversationModule),
        canActivate: [AuthGuard],
      },
      { path: 'contact-list', component: ContactPageComponent, canActivate: [AuthGuard] },
    ]
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
