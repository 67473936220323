<div class="card mt-3">
  <div class="card-header">
    <h5>Invoices</h5>
  </div>
  <div class="row button-container" *ngIf="isMobile">
    <div class="sort" *ngIf="isMobile"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>
    <div class="filter" *ngIf="isMobile"><ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container></div>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table *ngIf="!isMobile">
        <thead>
          <tr>
            <th (click)="tableControls.sort('i.invoice_number');">
              Invoice #
              <fa-icon *ngIf="tableControls.isSortedBy('i.invoice_number')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
            </th>
            <th (click)="tableControls.sort('i.invoice_amount');">
              Amount
              <fa-icon *ngIf="tableControls.isSortedBy('i.invoice_amount')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
            </th>
            <th>Paid</th>
            <th>Due</th>
            <th>
              <div class="d-flex" style="position: relative;">
                <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #invoiceStatus="ngbPopover" (click)="showColumnFilterPopover(invoiceStatus, 'i.invoice_status');">
                  <span *ngIf="tableControls.hasActiveFilter('i.invoice_status')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('i.invoice_status')}}</span>
                  <fa-icon [icon]="faFilter"></fa-icon>
                </div>
                <div class="w-100 px-3" (click)="tableControls.sort('i.invoice_status');">
                  Status
                </div>
                <fa-icon *ngIf="tableControls.isSortedBy('i.invoice_status')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
              </div>
            </th>
            <th (click)="tableControls.sort('i.invoice_issue_date');">
              Issue Date
              <fa-icon *ngIf="tableControls.isSortedBy('i.invoice_issue_date')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
            </th>
            <th (click)="tableControls.sort('i.invoice_due_date');">
              Due Date
              <fa-icon *ngIf="tableControls.isSortedBy('i.invoice_due_date')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
            </th>
            <th>Last Emailed</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let invoice of invoices" routerLink="/invoices/{{invoice.id}}">
            <td data-label="Invoice #">{{invoice.number}}</td>
            <td data-label="Amount">{{invoice.amount | currency}}</td>
            <td data-label="Paid">{{invoice.totalPaymentAmount | currency}}</td>
            <td data-label="Due">
              <div class="d-flex mx-auto" style="width: fit-content;">
                <div>{{invoice.amountDue | currency}}</div>
                <fa-icon *ngIf="invoice.amountDue < 0" [icon]="faTriangleExclamation" class="ms-2" style="color: var(--bs-red);"></fa-icon>
              </div>
            </td>
            <td data-label="Status">{{invoice.status | invoiceStatus}}</td>
            <td data-label="Issue Date">{{invoice.issueDate | formatDateTime: true}}</td>
            <td data-label="Due Date">{{invoice.dueDate | formatDateTime: true}}</td>
            <td data-label="Last Emailed">{{invoice.lastEmailedAt | formatDateTime}}</td>
          </tr>
        </tbody>
      </table>

      <div class="scrollable" *ngIf="isMobile">
        <ul class="list-group">
            <li *ngFor="let invoice of invoices" class="list-group-item" style="cursor: pointer;" routerLink="/invoices/{{invoice.id}}">
                <div class="d-flex list-group-container">
                    <div>
                        <strong>{{invoice.number}}</strong>
                        <div><strong>Amount: </strong>{{invoice.amount | currency}}</div>
                        <div><strong>Paid: </strong>{{invoice.totalPaymentAmount | currency}}</div>
                        <div><strong>Due: </strong>{{invoice.amountDue | currency}}&nbsp;<fa-icon *ngIf="invoice.amountDue < 0" [icon]="faTriangleExclamation" class="ms-2" style="color: var(--bs-red);"></fa-icon></div>
                        <div><strong>Status: </strong>{{invoice.status | invoiceStatus}}</div>
                        <small class="text-muted">Issue Date: {{invoice.issueDate | formatDateTime: true}}</small>
                        <br/>
                        <small class="text-muted">Due Date: {{invoice.dueDate | formatDateTime: true}}</small>
                        <br/>
                        <small class="text-muted">Last Emailed: {{invoice.lastEmailedAt | formatDateTime}}</small>

                    </div>
                </div>
            </li>
            <li *ngIf="invoices.length == 0" class="list-group-item">No Invoices</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- Filter -->
<ng-template #popTitleFilter>Filter</ng-template>
<ng-template let-column="column" #popContentFilter>
  <div *ngFor="let element of this.columnFilterValues.get(column)" class="form-check">
    <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter(column, element.value);" [checked]="tableControls.filterExists(column, element.value)">
    <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
  </div>
</ng-template>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block" [autoClose]="true" placement="bottom-left">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
  <div ngbDropdown class="d-inline-block filter-container" autoClose="outside" placement="bottom-left">
      <span *ngIf="activeFilterCount > 0" class="custom-badge-lg">{{activeFilterCount}}</span>
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Filter">
          <fa-icon [icon]="faFilter"></fa-icon>
      </button>
      <div ngbDropdownMenu>
          <!-- Filter Options -->
          <div *ngFor="let filter of filterColumns">
            <button ngbDropdownItem class="button-filter" (click)="getDistinctColumnValues(filter.value); filters.toggle();">{{filter.label}}</button>
            <div class="px-4 py-1" #filters="ngbCollapse" [ngbCollapse]="filter.value === selectedFilterColumn ? false : true">
                <div class="d-flex flex-column">
                    <div *ngFor="let element of columnFilterValues.get(filter.value);" class="form-check form-check-inline">
                        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter(filter.value, element.value);" [checked]="filterExists(filter.value, element.value)">
                        <label [for]="element.randomId" class="form-check-label" style="white-space: nowrap;">{{element.name}}</label>
                    </div>
                </div>
            </div>
          </div>

          <!-- Divider -->
          <div class="dropdown-divider"></div>
          <!-- Reset -->
          <button class="reset-button" ngbDropdownItem (click)="resetActiveFilters(); selectedFilterColumn = ''">Reset</button>
      </div>
  </div>
</ng-template>

