import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InventoryPrep, SplitItem } from './inventory-prep';

@Injectable({
    providedIn: 'root'
})
export class InventoryPrepService {

    constructor(private http: HttpClient) { }

    getInventoryPrep(params?: any): Observable<{inventoryPrep: InventoryPrep[], count: number}> {
        const user = JSON.parse(sessionStorage.getItem('CurrentUser'));
        const organizationId = user.organizationId;
        // if(params) {
        //     return this.http.get<InventoryPrep[]>(`/inventoryprep/${params}`);
        // }
        return this.http.get<{inventoryPrep: InventoryPrep[], count: number}>(`/inventoryprep/${organizationId}`, {
            params: params
        });
    }

    getInventoryPrepStatAndQty(inventoryPrepId: string): Observable<{status: string, quantity: number}> {
        return this.http.get<{status: string, quantity: number}>(`/inventoryprep/getstatus/${inventoryPrepId}`);
    }

    updateInventoryPrepStatus(itemId: string, inventoryPrepId: string, status: string, quantity: number, fromSplit?: string): Observable<any> {
        return this.http.put<any>(`/inventoryprep/updateinventoryprepstatus`, {
            itemId,
            inventoryPrepId,
            status,
            quantity,
            fromSplit
        });
    }

    orderInventoryPrep(orders: { inventoryPrepId: string, quantity: number, source?: string }, itemId: string): Observable<any> {
        return this.http.put<any>(`/inventoryprep/orderinventoryprep`, { orders, itemId });
    }

    splitInventoryPrep(splitItem: SplitItem): Observable<any> {
        return this.http.put<any>(`/inventoryprep/splitinventoryprep`, splitItem);
    }

    checkIfProjectNeedsToMove(proposalId: string, projectId: string): Observable<any> {
        return this.http.put<any>(`/inventoryprep/movetonextstage`, {
            proposalId, projectId
        });
    }
}
