import { Component, Input, OnInit } from '@angular/core';
import { faCheckCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Project } from '../project';
import { ProjectService } from '../project.service';
import { SendEmailComponent } from '../send-email/send-email.component';

@Component({
  selector: 'app-project-reviews',
  templateUrl: './project-reviews.component.html',
  styleUrls: ['./project-reviews.component.css']
})
export class ProjectReviewsComponent implements OnInit {

  // Properties
  private _project: Project;
  @Input()
  set project(value: Project) {
    this._project = value;
  }
  get project(): Project {
    return this._project;
  }

  // Font Awesome Properties
  faEnvelope = faEnvelope;
  faCheckCircle = faCheckCircle;

  constructor(private projectService: ProjectService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    
  }

  // Open Send Email Modal
  openSendEmailModal(): void {
    const modalRef = this.modalService.open(SendEmailComponent);
    modalRef.componentInstance.clientId = this.project.clientId;
    modalRef.componentInstance.projectId = this.project.id;
    modalRef.componentInstance.type = 'REVIEW';
    modalRef.componentInstance.subject = 'Please Leave Us A Review';
  }

  // Update Project Status
  updateProjectStatus(): void {
    this.projectService.updateProjectStatus(this.project.id, 'COMPLETED').subscribe(() => {
      this.projectService.projectUpdated.emit();
    });
  }
}
