import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { faPlay, faPause, faExpand, faArrowRotateBackward, faArrowRotateForward, faVolumeXmark, faVolumeHigh, faXmark } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';

interface LightboxItem {
  id: string;
  type: string;
  name: string;
  url: string;
}

@Component({
  selector: 'app-media-lightbox',
  templateUrl: './media-lightbox.component.html',
  styleUrls: ['./media-lightbox.component.css']
})
export class MediaLightboxComponent implements OnInit, AfterViewInit {

  // Properties
  lightboxItems: LightboxItem[] = [];
  selectedLightboxItemId: string;

  @ViewChild('carousel') carousel: NgbCarousel;

  currentTime: number = 0;
  duration: number = 0;

  currentVideoPlayer: HTMLVideoElement;

  // Font Awesome Properties
  faPlay = faPlay;
  faPause = faPause;
  faExpand = faExpand;
  faArrowRotateBackward = faArrowRotateBackward;
  faArrowRotateForward = faArrowRotateForward;
  faVolumeXmark = faVolumeXmark;
  faVolumeHigh = faVolumeHigh;
  faXmark = faXmark;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
    if (!this.selectedLightboxItemId) this.selectedLightboxItemId = this.lightboxItems[0].id;
  }

  ngAfterViewInit(): void {
    if (this.selectedLightboxItemId) this.loadVideoOnOpen();
    this.listenForSlides();
  }

  // Load Video On Open
  private loadVideoOnOpen(): void {
    const lightboxItem = this.lightboxItems.find((lightboxItem) => { return lightboxItem.id == this.selectedLightboxItemId; });
    if (this.isVideo(lightboxItem.type)) {
      this.currentVideoPlayer = document.getElementById('VIDEO-' + lightboxItem.id) as HTMLVideoElement;
      this.currentVideoPlayer.addEventListener('loadedmetadata', () => {
        this.currentTime = this.currentVideoPlayer.currentTime;
        this.duration = this.currentVideoPlayer.duration;
      });
      this.currentVideoPlayer.addEventListener('timeupdate', () => {
        this.currentTime = this.currentVideoPlayer.currentTime;
      });
    }
  }

  // Listen For Slides
  private listenForSlides(): void {
    this.carousel.slide.subscribe((event) => {
      const prevLightboxItem = this.lightboxItems.find((lightboxItem) => { return lightboxItem.id == event.prev; });
      const currLightboxItem = this.lightboxItems.find((lightboxItem) => { return lightboxItem.id == event.current; });
      if (this.isVideo(prevLightboxItem.type)) {
        if (!this.isPaused()) this.pause();
        this.currentVideoPlayer.removeAllListeners();
      }
      if (this.isVideo(currLightboxItem.type)) {
        this.currentVideoPlayer = document.getElementById('VIDEO-' + currLightboxItem.id) as HTMLVideoElement;
        this.currentTime = this.currentVideoPlayer.currentTime;
        this.duration = this.currentVideoPlayer.duration
        this.currentVideoPlayer.addEventListener('timeupdate', () => {
          this.currentTime = this.currentVideoPlayer.currentTime;
        });
      }
    });
  }

  // Is Image
  isImage(type: string): boolean {
    return type.includes('image');
  }

  // Is Video
  isVideo(type: string): boolean {
    return type.includes('video');
  }

  /* ----- Video Controls ----- */

  // Is Paused
  isPaused(): boolean {
    return (this.currentVideoPlayer) ? this.currentVideoPlayer.paused : true;
  }

  // Is Muted
  isMuted(): boolean {
    return (this.currentVideoPlayer) ? this.currentVideoPlayer.muted : false;
  }

  // Play
  play(): void {
    if (this.currentVideoPlayer.paused) this.currentVideoPlayer.play();
  }

  // Pause
  pause(): void {
    if (!this.currentVideoPlayer.paused) this.currentVideoPlayer.pause();
  }

  // Rewind
  rewind(): void {
    this.currentVideoPlayer.currentTime = this.currentVideoPlayer.currentTime - 5;
  }

  // Fast Forward
  fastForward(): void {
    this.currentVideoPlayer.currentTime = this.currentVideoPlayer.currentTime + 5;
  }

  // Toggle Mute
  toggleMute(): void {
    this.currentVideoPlayer.muted = !this.currentVideoPlayer.muted;
  }

  // Fullscreen
  fullscreen(): void {
    this.currentVideoPlayer.requestFullscreen();
  }

  /* ----- Helper Functions ----- */

  // Format Time
  formatTime(seconds: number): string {
    return dayjs.duration(seconds, 'seconds').format('mm:ss');
  }
}
