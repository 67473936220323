<div class="content-container">
  <nav class="navbar navbar-white bg-white sticky-top">
    <div class="d-grid gap-2 d-flex navbar-brand">
      <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>
      <h2 *ngIf="project" style="white-space: normal;">{{project.name}} Summary</h2>
    </div>
  </nav>
  <div class="row">
    <div class="col-lg-8 col-xl-8 mt-2">
      <div class="card" style="height: 100%;">
        <div class="card-header">
          <h5>Client & Site</h5>
        </div>
        <div *ngIf="client && site && primaryContact" class="card-body">
          <div class="row">
            <div class="col-md-4 col-12">
              <strong>Client</strong>
              <p>{{client.name}}</p>
            </div>
            <div class="col-md-4 col-12 mt-md-0 mt-3">
              <strong>Site</strong>
              <p>{{site.name}}</p>
              <p>{{site.address.street}}</p>
              <p>{{site.address.city}}, {{site.address.state}} {{site.address.postalCode}}</p>
            </div>
            <div class="col-md-4 col-12 mt-md-0 mt-3">
              <strong>Primary Contact</strong>
              <p>{{primaryContact.firstName}} {{primaryContact.lastName}}</p>
              <p><a href="mailto:{{primaryContact.email}}">{{primaryContact.email}}</a></p>
              <p><a href="tel:{{primaryContact.phone}}">{{primaryContact.phone}}</a> <span *ngIf="primaryContact.phoneExtension">x{{primaryContact.phoneExtension}}</span></p>
            </div>
            <div *ngIf="project" class="col-md-4 col-12 mt-3">
              <strong>Storage Location</strong>
              <p *ngIf="project.storageLocation" class="mb-0">{{project.storageLocation}}</p>
              <p *ngIf="!project.storageLocation" class="mb-0">N/A</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-xl-4 mt-2">
      <div class="card">
        <div class="card-header">
          <h5>Notes</h5>
        </div>
        <div class="card-body" style="height: 296px;">
          <ul class="list-group scrollable" style="height: 264px;">
            <li *ngFor="let note of notes" class="list-group-item">
              <div class="d-flex">
                <div>
                  <strong>{{note.title}}</strong>
                  <p>{{note.content}}</p>
                  <small class="text-muted">{{note.updatedAt | formatDateTime}}</small>
                </div>
              </div>
            </li>
            <li *ngIf="notes.length == 0" class="list-group-item">No Notes</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-4 mt-2 pe-md-0 pe-lg-2">
      <div class="card">
        <div class="card-header">
          <h5>Time Clock</h5>
        </div>
        <div class="card-body">
          <ul class="list-group scrollable" style="height: 503px">
            <li *ngFor="let timeEntry of timeEntries" class="list-group-item">
              <div class="d-flex">
                <div>
                  <div><strong>{{timeEntry.user.firstName}} {{timeEntry.user.lastName}}</strong></div>
                  <div><strong>Type:</strong> {{timeEntry.type}}</div>
                  <div><strong>In:</strong> {{timeEntry.start | formatDateTime}}</div>
                  <div *ngIf="timeEntry.end"><strong>Out:</strong> {{timeEntry.end | formatDateTime}}</div>
                </div>
                <div class="ms-auto my-auto">
                  <h5 *ngIf="timeEntry.duration">{{formatDuration(timeEntry.duration)}}</h5>
                </div>
              </div>
            </li>
            <li *ngIf="timeEntries.length == 0" class="list-group-item">No Time Entries</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-4 mt-2 pe-md-0 pe-xl-2">
      <div class="card">
        <div class="card-header">
          <h5>Features & Feature Items</h5>
        </div>
        <div class="card-body">
          <div class="scrollable" style="height: 503px">
            <ul *ngIf="!acceptedProposal" class="list-group">
              <li class="list-group-item">No Accepted Proposal</li>
            </ul>
            <ul *ngFor="let feature of features; index as i;" class="list-group">
              <li [class.mt-2]="i > 0" class="list-group-item" style="border-radius: 0.375rem;" *ngIf="feature.isSelected">
                <h4>{{feature.name}}</h4>
                <h6 *ngIf="feature.description" class="text-muted" style="word-break: break-word;">{{feature.description}}</h6>
                <ul class="list-group">
                  <li class="list-group-item mt-2">
                    <div style="display: grid; grid-template-columns: repeat(4, minmax(0, 1fr));">
                      <div style="grid-column: 1;" class="my-auto">
                        <strong>Item</strong>
                      </div>
                      <div style="grid-column: 2;" class="text-center my-auto">
                        <strong>Type</strong>
                      </div>
                      <div style="grid-column: 3;" class="text-center my-auto">
                        <strong>Quoted<br>Qty</strong>
                      </div>
                      <div style="grid-column: 4;" class="text-center my-auto">
                        <strong>Actual<br>Qty</strong>
                      </div>
                    </div>
                  </li>
                  <li *ngFor="let featureItem of featureItems.get(feature.id); index as j;" class="list-group-item">
                    <div style="display: grid; grid-template-columns: repeat(4, minmax(0, 1fr));">
                      <div style="grid-column: 1;" class="my-auto">
                        <strong style="word-break: break-word;">{{featureItem.name}}</strong>
                      </div>
                      <div style="grid-column: 2;" class="text-center my-auto">
                        <p>{{featureItem.type}}</p>
                      </div>
                      <div style="grid-column: 3;" class="text-center my-auto">
                        <p>{{featureItem.quantity}}</p>
                      </div>
                      <div style="grid-column: 4;" class="text-center my-auto">
                        <p>{{featureItem.installedQuantity}}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-4 mt-2 pe-lg-2 pe-xl-0">
      <div class="card">
        <div class="card-header">
          <h5>Files ({{projectFiles.length}})</h5>
          <div class="ms-auto">
            <button type="button" class="btn btn-primary" (click)="openMediaLightbox();" [disabled]="!isGalleryAvailable()">
              <fa-icon [icon]="faImages" class="button-icon-left"></fa-icon>
              <span>Gallery</span>
            </button>
          </div>
        </div>
        <div class="card-body">
          <ul class="list-group scrollable" style="height: 503px;">
            <li *ngFor="let projectFile of projectFiles" class="list-group-item" [style.cursor]="isMedia(projectFile) ? 'pointer' : 'default'" (click)="openMediaLightbox(projectFile);">
              <div class="d-flex">
                <div>
                  <div><strong>{{projectFile.name}}</strong></div>
                  <small class="text-muted">{{$any(projectFile.size) | formatBytes}}, {{projectFile.createdAt | formatDateTime}}</small>
                </div>
                <button type="button" class="btn btn-primary ms-auto my-auto" (click)="$event.stopPropagation(); downloadFile(projectFile);" ngbTooltip="Download">
                  <fa-icon [icon]="faDownload"></fa-icon>
                </button>
              </div>
            </li>
            <li *ngIf="projectFiles.length == 0" class="list-group-item">No Files</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>