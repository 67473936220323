import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { AlertService } from '../alert.service';
import { OrganizationSubscription } from '../organization-subscription';
import { OrganizationService } from '../organization.service';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { SettingService } from '../setting.service';
import { SubscriptionService } from '../subscription.service';
import { Subscription } from '../subscription';

@Component({
  selector: 'app-organization-update-subscription',
  templateUrl: './organization-update-subscription.component.html',
  styleUrls: ['./organization-update-subscription.component.css']
})
export class OrganizationUpdateSubscriptionComponent implements OnInit {
  @Output() saved: EventEmitter<OrganizationSubscription> = new EventEmitter<OrganizationSubscription>();

  // subscriptions: OrganizationSubscription[] = [];

  selectedSubscription: any;
  newSubscription: any;
  selectedPromotion: any;
  subscriptionForm!: UntypedFormGroup;
  errorMsg: string;
  subscriptions: Subscription[] = [];

  faCheck = faCheck;

  constructor(
    private settingService: SettingService,
    private organizationService: OrganizationService,
    private subscriptionService: SubscriptionService,
    private alertService: AlertService,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.subscriptionForm = new UntypedFormGroup({
      shouldRenew: new UntypedFormControl(false),
      renewalDate: new UntypedFormControl()
    });

    this.getSubscriptions();
    console.log(this.selectedSubscription);
  }

  // private getSubscriptions(): void {
  //   this.settingService.getSubscriptionsList().subscribe((subscriptions) => {
  //     console.log(subscriptions)
  //     this.subscriptions = subscriptions;
  //   });
  // }

  // Get Subscriptions
  private getSubscriptions(promoCode: string | null = null): void {
    if (typeof promoCode == 'string') promoCode = promoCode.toUpperCase();
    const params = {
      type: 'STANDARD',
      promoCode: promoCode
    };
    this.settingService.getSubscriptionsList().subscribe((subscriptions) => {
    // this.subscriptionService.getSubscriptions(params).subscribe((subscriptions) => {
      console.log(subscriptions)
      if (promoCode && this.subscriptions.length > 0) {
        const subscription = subscriptions.find((sub) => { return sub.promotions.map((p) => { return p.code }).includes(promoCode) });
        if (subscription) {
          this.selectedPromotion = subscription.promotions.find((p) => { 
            return p.code == promoCode && p.isActive; 
          });
          this.errorMsg = null;
          console.log(this.subscriptions)

          if(this.selectedPromotion) {
            this.subscriptions =  subscriptions.filter(subs => subs.isDefault || subs.price == subscription.price);
          } else {
            this.errorMsg = 'Invalid Promo Code'
          }
        } else {
          this.errorMsg = 'Invalid Promo Code'
        }
      } else {
        this.subscriptions = subscriptions.filter(subs => subs.isDefault);
      }
    });
  }

  selectSubscription(subscription: Subscription){
    this.newSubscription = subscription;
  }

  displayTagline(subscription: any): boolean {
    const promotion = subscription.promotions.find((promo: any) => { return promo.code == this.selectedPromotion.code });
    return promotion !== undefined;
  }

  // Apply Promo Code
  applyPromoCode(event: KeyboardEvent = null): void {
    try {
      if (event && event.code !== 'Enter') return;
      const promoCode = (<HTMLInputElement>document.getElementById('PROMO_CODE')).value;
      if (promoCode === null || promoCode.length == 0) throw new Error('Promo Code cannot be blank.');
      this.getSubscriptions(promoCode);
      (<HTMLInputElement>document.getElementById('PROMO_CODE')).value = null;
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  saveSelection() {
    this.modal.close();
    this.saved.emit(this.newSubscription);
  }
}
