<div class="content-container">
  <nav class="navbar navbar-white bg-white sticky-top">
    <div class="d-grid gap-2 d-flex navbar-brand">
      <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>
      <h2 class="me-md-0 me-4 mobileHeading">Invoices</h2>
    </div>
    <div class="row button-container">
      <div class="col-md-auto col-xs-12">
        <input type="search" class="form-control" placeholder="Search Invoices" [value]="storedSearchValue" (keyup)="tableControls.search($event.target.value);" (search)="tableControls.search($event.target.value);">
      </div>
      <div class="col-auto">
        <div ngbDropdown class="d-inline-block" display="dynamic" [placement]="isMobile ? 'bottom-left': 'bottom-right'">
          <button id="INVOICES_DROPDOWN" type="button" class="btn btn-primary mt-md-0 mt-1" ngbDropdownToggle>Options</button>
          <div ngbDropdownMenu aria-labelledby="INVOICES_DROPDOWN">
            <button ngbDropdownItem (click)="downloadInvoices();">
              <fa-icon class="button-icon-left" [icon]="faFileDownload"></fa-icon>
              <span>Export</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-auto" *ngIf="isMobile">
        <div class="sort"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>
      </div>
      <div class="col-auto" *ngIf="isMobile">
        <div class="filter"><ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container></div>
      </div>
    </div>
  </nav>
  <div class="table-responsive mt-3">
    <table *ngIf="!isMobile">
      <thead>
        <tr>
          <!-- Invoice # -->
          <th (click)="tableControls.sort('i.invoice_number');">
            Invoice #
            <fa-icon *ngIf="tableControls.isSortedBy('i.invoice_number')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </th>
          <!-- Client -->
          <th>
            <div class="d-flex" style="position: relative;">
              <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #clientName="ngbPopover" (click)="showColumnFilterPopover(clientName, 'c.client_name');">
                <span *ngIf="tableControls.hasActiveFilter('c.client_name')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('c.client_name')}}</span>
                <fa-icon [icon]="faFilter"></fa-icon>
              </div>
              <div class="w-100 px-3" (click)="tableControls.sort('c.client_name');">
                Client
              </div>
              <fa-icon *ngIf="tableControls.isSortedBy('c.client_name')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
            </div>
          </th>
          <!-- Project -->
          <th>
            <div class="d-flex" style="position: relative;">
              <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #projectName="ngbPopover" (click)="showColumnFilterPopover(projectName, 'proj.project_name');">
                <span *ngIf="tableControls.hasActiveFilter('proj.project_name')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('proj.project_name')}}</span>
                <fa-icon [icon]="faFilter"></fa-icon>
              </div>
              <div class="w-100 px-3" (click)="tableControls.sort('proj.project_name');">
                Project
              </div>
              <fa-icon *ngIf="tableControls.isSortedBy('proj.project_name')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
            </div>
          </th>
          <!-- Amount -->
          <th (click)="tableControls.sort('i.invoice_amount');">
            Amount
            <fa-icon *ngIf="tableControls.isSortedBy('i.invoice_amount')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </th>
          <!-- Paid -->
          <th>Paid</th>
          <!-- Due -->
          <th>Due</th>
          <!-- Status -->
          <th>
            <div class="d-flex" style="position: relative;">
              <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #invoiceStatus="ngbPopover" (click)="showColumnFilterPopover(invoiceStatus, 'i.invoice_status');">
                <span *ngIf="tableControls.hasActiveFilter('i.invoice_status')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('i.invoice_status')}}</span>
                <fa-icon [icon]="faFilter"></fa-icon>
              </div>
              <div class="w-100 px-3" (click)="tableControls.sort('i.invoice_status');">
                Status
              </div>
              <fa-icon *ngIf="tableControls.isSortedBy('i.invoice_status')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
            </div>
          </th>
          <!-- Issue Date -->
          <th (click)="tableControls.sort('i.invoice_issue_date');">
            Issue Date
            <fa-icon *ngIf="tableControls.isSortedBy('i.invoice_issue_date')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </th>
          <!-- Due Date -->
          <th (click)="tableControls.sort('i.invoice_due_date');">
            Due Date
            <fa-icon *ngIf="tableControls.isSortedBy('i.invoice_due_date')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </th>
          <!-- Last Emailed -->
          <th>Last Emailed</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let invoice of invoices" routerLink="/invoices/{{invoice.id}}">
          <td data-label="Invoice #">{{invoice.number}}</td>
          <td data-label="Client">{{invoice.clientName}}</td>
          <td data-label="Project">{{invoice.projectName}}</td>
          <td data-label="Amount">{{invoice.amount | currency}}</td>
          <td data-label="Paid">{{invoice.totalPaymentAmount | currency}}</td>
          <td data-label="Due">
            <div class="d-flex mx-auto" style="width: fit-content;">
              <div>{{invoice.amountDue | currency}}</div>
              <fa-icon *ngIf="invoice.amountDue < 0" [icon]="faTriangleExclamation" class="ms-2" style="color: var(--bs-red);"></fa-icon>
            </div>
          </td>
          <td data-label="Status">{{invoice.status | invoiceStatus}}</td>
          <td data-label="Issue Date">{{invoice.issueDate | formatDateTime: true}}</td>
          <td data-label="Due Date">{{invoice.dueDate | formatDateTime: true}}</td>
          <td data-label="Last Emailed">{{invoice.lastEmailedAt | formatDateTime}}</td>
        </tr>
      </tbody>
    </table>

    <div class="scrollable" *ngIf="isMobile">
      <ul class="list-group">
          <li *ngFor="let invoice of invoices" class="list-group-item" style="cursor: pointer;"  routerLink="/invoices/{{invoice.id}}">
              <div class="d-flex list-group-container">
                  <div>
                      <strong>{{invoice.clientName}}</strong>
                      <div><strong>Invoice #: </strong> {{invoice.number}}</div>
                      <div><strong>Project: </strong>{{invoice.projectName}}</div>
                      <div><strong>Amount: </strong>{{invoice.amount | currency}}</div>
                      <div><strong>Paid: </strong>{{invoice.totalPaymentAmount | currency}}</div>
                      <div><strong>Due: </strong>{{invoice.amountDue | currency}} &nbsp; <fa-icon *ngIf="invoice.amountDue < 0" [icon]="faTriangleExclamation" class="ms-2" style="color: var(--bs-red);"></fa-icon></div>
                      <div><strong>Status: </strong> {{invoice.status | invoiceStatus}}</div>
                      <small class="text-muted">Issue Date: {{invoice.issueDate | formatDateTime: true}}</small>
                      <br/>
                      <small class="text-muted">Due Date: {{invoice.dueDate | formatDateTime: true}}</small>
                      <br/>
                      <small class="text-muted">Last Emailed: {{invoice.lastEmailedAt | formatDateTime}}</small>
                  </div>
              </div>
          </li>
          <li *ngIf="invoices.length == 0" class="list-group-item">No Invoices</li>
      </ul>
    </div>
  </div>
  <div class="row table-controls">
    <div *ngIf="tableControls.isNotDefault() && !isMobile" class="col-auto mt-3 me-2 me-md-0">
      <button type="button" class="btn btn-outline-danger" (click)="tableControls.resetTableConfiguration();">
        <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
        <span>Reset</span>
      </button>
    </div>
    <div class="col mt-3">
      <div ngbDropdown placement="top-left" class="d-inline-block">
        <button class="btn btn-outline-primary" ngbDropdownToggle><span *ngIf="!isMobile">Page Size: </span> {{tableControls.getLimit()}}</button>
        <div ngbDropdownMenu class="page-size-dropdown" *ngIf="!isMobile">
          <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
          <button ngbDropdownItem (click)="tableControls.setLimit(50);">50</button>
          <button ngbDropdownItem (click)="tableControls.setLimit(100);">100</button>
        </div>
        <div ngbDropdownMenu class="page-size-dropdown" *ngIf="isMobile">
          <button ngbDropdownItem (click)="tableControls.setLimit(5);">5</button>
          <button ngbDropdownItem (click)="tableControls.setLimit(10);">10</button>
          <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
        </div>
      </div>
    </div>
    <div class="col my-3">
      <ngb-pagination class="d-flex justify-content-end" *ngIf="invoiceCount > 0" [page]="tableControls.getCurrentPage()" [pageSize]="tableControls.getLimit()" [collectionSize]="invoiceCount" [boundaryLinks]="true" [maxSize]="(isMobile) ? 1 : 10" [ellipses]="true" [rotate]="true" (pageChange)="tableControls.setCurrentPage($event);"></ngb-pagination>
    </div>
  </div>
</div>

<!-- Filter -->
<ng-template #popTitleFilter>Filter</ng-template>
<ng-template let-column="column" #popContentFilter>
  <div *ngFor="let element of this.columnFilterValues.get(column)" class="form-check">
    <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter(column, element.value);" [checked]="tableControls.filterExists(column, element.value)">
    <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
  </div>
</ng-template>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block" [autoClose]="true" placement="bottom-left">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
  <div ngbDropdown class="d-inline-block filter-container" autoClose="outside" placement="bottom-left">
      <span *ngIf="activeFilterCount > 0" class="custom-badge-lg">{{activeFilterCount}}</span>
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Filter">
          <fa-icon [icon]="faFilter"></fa-icon>
      </button>
      <div ngbDropdownMenu>
          <!-- Filter Options -->
          <div *ngFor="let filter of filterColumns">
            <button ngbDropdownItem class="button-filter" (click)="getDistinctColumnValues(filter.value); filters.toggle();">{{filter.label}}</button>
            <div class="px-4 py-1" #filters="ngbCollapse" [ngbCollapse]="filter.value === selectedFilterColumn ? false : true">
                <div class="d-flex flex-column">
                    <div *ngFor="let element of columnFilterValues.get(filter.value);" class="form-check form-check-inline">
                        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter(filter.value, element.value);" [checked]="filterExists(filter.value, element.value)">
                        <label [for]="element.randomId" class="form-check-label" style="white-space: nowrap;">{{element.name}}</label>
                    </div>
                </div>
            </div>
          </div>

          <!-- Divider -->
          <div class="dropdown-divider"></div>
          <!-- Reset -->
          <button class="reset-button" ngbDropdownItem (click)="resetActiveFilters(); selectedFilterColumn = ''">Reset</button>
      </div>
  </div>
</ng-template>
