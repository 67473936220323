import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-number-name-form',
  templateUrl: './number-name-form.component.html',
  styleUrls: ['./number-name-form.component.css']
})
export class NumberNameFormComponent implements OnInit {

  numberNameForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.numberNameForm = this.formBuilder.group({
      numnberName: ['', Validators.required],
    });
  }

}
