import { Component, Input, OnInit, HostListener } from '@angular/core';
import { faArrowRight, faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from '../alert.service';
import { ProposalService } from '../proposal.service';
import { AcceptedProposal } from '../accepted-proposal';
import { FeatureItemService } from '../feature-item.service';
import { Subject, lastValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ProjectService } from '../project.service';
import { Feature } from 'maplibre-gl';
import { FeatureService } from '../feature.service';
import { LoaderService } from '../shared/services/loader.service';
import { FormatDateTimePipe } from '../format-date-time.pipe';


@Component({
  selector: 'app-project-installation',
  templateUrl: './project-installation.component.html',
  styleUrls: ['./project-installation.component.css'],
  providers: [FormatDateTimePipe]
})
export class ProjectInstallationComponent implements OnInit {
  //Screensize event Listener
 @HostListener('window:resize', ['$event'])
 onResize(event: Event) {
   this.resizeSubject.next((event.target as Window).innerWidth);
 }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();


  // Properties
  @Input() projectId: string;
  acceptedProposal: AcceptedProposal = null;
  installedQuantitySubject: Subject<{ featureItemId: string, quantity: number }> = new Subject<{ featureItemId: string, quantity: number }>();
  isMobileView: boolean = false;
  features: Feature[] = [];
  featureItems = new Map();
  tooltipDefaultMessage: string = 'Mark this feature complete once it is fully installed. Once completed, come back here to see the user that marked it complete and the time it was marked complete. ';


  // Font Awesome Properties
  faArrowRight = faArrowRight;
  faCheckCircle = faCheckCircle;
  faInfoCircle = faInfoCircle;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private projectService: ProjectService,
    private proposalService: ProposalService,
    private featureItemService: FeatureItemService,
    private alertService: AlertService,
    private featureService: FeatureService,
    private loaderService: LoaderService,
    private formatDateTimePipe: FormatDateTimePipe) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
        }
      });
    }

  ngOnInit(): void {
    this.getAcceptedProposalForProject();
    this.checkScreenSize();
    this.prepareInstalledQuantity();
  }

  /* ----- Item Review ----- */

  // Get Accepted Proposal For Project
  private getAcceptedProposalForProject(): void {
    this.proposalService.getAcceptedProposalForProject(this.projectId).subscribe((acceptedProposal) => {
      this.acceptedProposal = acceptedProposal;
      this.getFeaturesAndFeatureItems();
    });
  }


  // Get Feature Items
  private async getFeaturesAndFeatureItems(): Promise<void> {
    this.featureItems.clear();
    this.features = ((await lastValueFrom(this.featureService.getFeatures({ searchTerm: null, sortBy: 'f.feature_name', sortDirection: 'ASC', limit: null, offset: null, 'filter:f.proposal_id': JSON.stringify([(this.acceptedProposal as AcceptedProposal).proposalId]), 'filter:f.feature_included': JSON.stringify(['1']) }))) as any).features;
    const featureItems = ((await lastValueFrom(this.featureItemService.getFeatureItems({ searchTerm: null, sortBy: 'fi.feature_item_name', sortDirection: 'ASC', limit: null, offset: null, 'filter:f.proposal_id': JSON.stringify([(this.acceptedProposal as AcceptedProposal).proposalId]) }))) as any).featureItems;
    for (const featureItem of featureItems) {
      if (this.featureItems.has(featureItem.featureId)) {
        const tempArray = this.featureItems.get(featureItem.featureId);
        tempArray.push(featureItem);
        this.featureItems.set(featureItem.featureId, tempArray);
      } else {
        this.featureItems.set(featureItem.featureId, [featureItem]);
      }
    }
    this.loaderService.hideSpinner();
  }

  // Prepare Installed Quantity
  private prepareInstalledQuantity(): void {
    this.installedQuantitySubject.pipe(debounceTime(500), distinctUntilChanged()).subscribe((res) => {
      this.featureItemService.updateFeatureItemInstalledQuantity(res.featureItemId, res.quantity).subscribe({
        next:() => {
        this.alertService.showSuccessAlert('Actual Quantity Updated');
        this.getFeaturesAndFeatureItems();
      }, error: () => {
        this.loaderService.hideSpinner();
      }
      });
    });
  }

  // Update Feature Item Installed Quantity
  updateFeatureItemInstalledQuantity(event: Event, featureItemId: string): void {
    this.loaderService.showSpinner();
    const quantity = parseInt((event.target as HTMLInputElement).value);
    this.installedQuantitySubject.next({ featureItemId: featureItemId, quantity: quantity });
  }

  // Update Project Stage
  updateProjectStage(): void {
    this.projectService.updateProjectStage(this.projectId, 'BASES').subscribe(() => {
      this.projectService.projectUpdated.emit();
    });
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }
  // Update Feature Installation Status
  updateFetureInstallationStatus(feature: any): void {
    this.loaderService.showSpinner();
    this.featureService.updateFetureInstallationStatus({status: !feature.isInstalled, featureId: feature.id}).subscribe(
    {
      next:() => {
        this.getFeaturesAndFeatureItems();
      },
      error: () => {
        this.loaderService.hideSpinner();
      }
    });
  }

  public getInstallationTooltip(feature: any, isCheck?: boolean) {
    let date = feature?.installedAt ? this.formatDateTimePipe.transform(feature?.installedAt, false) : '';
    let tooltip = `${feature?.installedBy || ''} ${date || ''}`;
    if(!isCheck) {
      tooltip = this.tooltipDefaultMessage;
    }
    return tooltip;
  }

}
