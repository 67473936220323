import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AcceptedProposal } from '../accepted-proposal';
import { Client } from '../client';
import { ClientService } from '../client.service';
import { Contact } from '../contact';
import { ContactService } from '../contact.service';
import { Feature } from '../feature';
import { FeatureItemService } from '../feature-item.service';
import { FeatureService } from '../feature.service';
import { Project } from '../project';
import { ProjectFile } from '../project-file';
import { ProjectService } from '../project.service';
import { ProposalService } from '../proposal.service';
import { Site } from '../site';
import { SiteService } from '../site.service';
import { faAngleLeft, faDownload, faImages, faPrint } from '@fortawesome/free-solid-svg-icons';
import { Note } from '../note';
import { NoteService } from '../note.service';
import { ProjectFileService } from '../project-file.service';
import { Location } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TimeEntry } from '../time-entry';
import { TimeEntryService } from '../time-entry.service';
import dayjs from 'dayjs';
import { MediaLightboxComponent } from '../media-lightbox/media-lightbox.component';

@Component({
  selector: 'app-project-summary',
  templateUrl: './project-summary.component.html',
  styleUrls: ['./project-summary.component.css']
})
export class ProjectSummaryComponent implements OnInit {

  // Properties
  private modalReference: NgbModalRef;
  projectId: string;
  project: Project;
  site: Site;
  client: Client;
  primaryContact: Contact;
  acceptedProposal: AcceptedProposal = null;
  notes: Note[] = [];
  features: Feature[] = [];
  featureItems = new Map();
  projectFiles: ProjectFile[] = [];

  // Time Entries
  timeEntries: TimeEntry[] = [];

  // Font Awesome Properties
  faPrint = faPrint;
  faAngleLeft = faAngleLeft;
  faImages = faImages;
  faDownload = faDownload;

  constructor(private route: ActivatedRoute,
    public location: Location,
    private projectService: ProjectService,
    private projectFileService: ProjectFileService,
    private siteService: SiteService,
    private clientService: ClientService,
    private contactService: ContactService,
    private proposalService: ProposalService,
    private featureService: FeatureService,
    private featureItemService: FeatureItemService,
    private noteService: NoteService,
    private timeEntryService: TimeEntryService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.paramMap.get('projectId');
    this.setup();
  }

  // Open Media Lightbox
  openMediaLightbox(projectFile?: ProjectFile) {
    if (projectFile && !this.isMedia(projectFile)) return;
    this.modalReference = this.modalService.open(MediaLightboxComponent, { centered: true, modalDialogClass: 'custom-modal-dialog', size: 'xl' });
    this.modalReference.componentInstance.lightboxItems = this.projectFiles.filter((projectFile) => { return this.isMedia(projectFile); }).map((projectFile) => { return { id: projectFile.id, type: projectFile.type, name: projectFile.name, url: projectFile.presignedUrl }; });
    if (projectFile) this.modalReference.componentInstance.selectedLightboxItemId = projectFile.id;
  }

  // Setup
  private async setup(): Promise<void> {
    this.project = ((await lastValueFrom(this.projectService.getProject(this.projectId))) as any).project;
    this.site = await lastValueFrom(this.siteService.getSite(this.project.siteId));
    this.client = await lastValueFrom(this.clientService.getClient(this.site.clientId));
    const contacts = ((await lastValueFrom(this.contactService.getContacts({ searchTerm: null, sortBy: null, sortDirection: null, limit: null, offset: null, 'filter:client_id': JSON.stringify([this.site.clientId]) }))) as any).contacts;
    this.primaryContact = contacts.find((contact: Contact) => { return contact.isPrimary; });
    this.notes = await this.getNotes([this.client.id, this.site.id, this.project.id]);
    this.projectFiles = await lastValueFrom(this.projectFileService.getProjectFiles({ searchTerm: null, sortBy: 'pf.project_file_name', sortDirection: 'ASC', limit: null, offset: null, 'filter:pf.project_id': JSON.stringify([this.project.id]) }));
    this.acceptedProposal = await lastValueFrom(this.proposalService.getAcceptedProposalForProject(this.projectId));
    if (this.acceptedProposal) this.getFeaturesAndFeatureItems();
    this.getTimeEntriesForProject();
  }

  // Get Features And Feature Items
  private async getFeaturesAndFeatureItems(): Promise<void> {
    this.featureItems.clear();
    this.features = ((await lastValueFrom(this.featureService.getFeatures({ searchTerm: null, sortBy: 'f.feature_order', sortDirection: 'ASC', limit: null, offset: null, 'filter:f.proposal_id': JSON.stringify([(this.acceptedProposal as AcceptedProposal).proposalId]), 'filter:f.feature_included': JSON.stringify(['1']) }))) as any).features;
    const featureItems = ((await lastValueFrom(this.featureItemService.getFeatureItems({ searchTerm: null, sortBy: 'fi.feature_item_name', sortDirection: 'ASC', limit: null, offset: null, 'filter:f.proposal_id': JSON.stringify([(this.acceptedProposal as AcceptedProposal).proposalId]) }))) as any).featureItems;
    for (const featureItem of featureItems) {
      if (this.featureItems.has(featureItem.featureId)) {
        const tempArray = this.featureItems.get(featureItem.featureId);
        tempArray.push(featureItem);
        this.featureItems.set(featureItem.featureId, tempArray);
      } else {
        this.featureItems.set(featureItem.featureId, [featureItem]);
      }
    }
  }

  /* ----- Notes ----- */

  // Get Notes
  private getNotes(referenceIds: string[]): Promise<Note[]> {
    const params = {
      'filter:reference_id': JSON.stringify(referenceIds)
    };
    return lastValueFrom(this.noteService.getNotes(params));
  }

  /* ----- Project Files ----- */

  // Download File
  downloadFile(file: ProjectFile): void {
    this.projectFileService.getProjectFilePresignedDownloadUrl(file.id).subscribe((presignedUrl) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = presignedUrl;
      downloadLink.setAttribute('download', file.name!);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    });
  }

  // Open File
  openFile(file: ProjectFile): void {
    window.open(file.presignedUrl);
  }

  /* ----- Time Entries ----- */

  // Get Time Entries For Project
  private getTimeEntriesForProject(): void {
    this.timeEntryService.getTimeEntriesForProject(this.project.id).subscribe((timeEntries) => {
      this.timeEntries = timeEntries;
    });
  }

  /* ----- Helper Functions ----- */

  // Is Media
  isMedia(projectFile: ProjectFile): boolean {
    return projectFile.type.includes('image') || projectFile.type.includes('video');
  }

  // Is Gallery Available
  isGalleryAvailable(): boolean {
    return this.projectFiles.filter((projectFile) => { return this.isMedia(projectFile); }).length > 0;
  }

  // Format Duration
  formatDuration(duration: number): string {
    return dayjs.duration(duration, 'seconds').asHours().toFixed(2) + ' Hour(s)';
  }
}
