import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { AlertService } from '../alert.service';
import { OrganizationSubscription } from '../organization-subscription';
import { SettingService } from '../setting.service';

@Component({
  selector: 'app-subscription-invoice-modal',
  templateUrl: './subscription-invoice-modal.component.html',
  styleUrls: ['./subscription-invoice-modal.component.css']
})
export class SubscriptionInvoiceModalComponent implements OnInit {

  // Properties
  @Input() organizationId!: string;
  @Input() subscriptionInvoice: any;
  @Output() saved: EventEmitter<string> = new EventEmitter<string>();
  subscriptionInvoiceForm!: UntypedFormGroup;
  subscriptions: OrganizationSubscription[] = [];
  showReason: boolean = false;

  constructor(
    private settingService: SettingService,
    private alertService: AlertService,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.subscriptionInvoiceForm = new UntypedFormGroup({
      subscriptionName: new UntypedFormControl(),
      transactionId: new UntypedFormControl(),
      quantity: new UntypedFormControl(1),
      total: new UntypedFormControl(),
      createdAt: new UntypedFormControl(),
      refundReason: new UntypedFormControl()
    });
    if (this.subscriptionInvoice) {
      this.prepareSubscriptionInvoiceForm();
    } else {
      const createdAt = dayjs().format('YYYY-MM-DDTHH:mm');
      this.subscriptionInvoiceForm.controls['createdAt'].setValue(createdAt);
    }
  }

  // Prepare Subscription Invoice Form
  private prepareSubscriptionInvoiceForm(): void {
    this.subscriptionInvoiceForm.controls['subscriptionName'].setValue(this.subscriptionInvoice.subscription.name);
    this.subscriptionInvoiceForm.controls['transactionId'].setValue(this.subscriptionInvoice.transactionId);
    this.subscriptionInvoiceForm.controls['quantity'].setValue(this.subscriptionInvoice.subscription.quantity);
    this.subscriptionInvoiceForm.controls['total'].setValue(this.subscriptionInvoice.total);
    const createdAt = dayjs(this.subscriptionInvoice.createdAt).utc().format('YYYY-MM-DDTHH:mm');
    this.subscriptionInvoiceForm.controls['createdAt'].setValue(createdAt);
    this.subscriptionInvoiceForm.disable()
  }

  // Get Organization Subscriptions
  private getOrganizationSubscriptions(): void {
    this.settingService.getSubscriptions(this.organizationId).subscribe((subscriptions) => {
      this.subscriptions = subscriptions;
      if (!this.subscriptionInvoice && this.subscriptions.length > 0) {
        this.subscriptionInvoiceForm.controls['subscriptionId'].setValue(this.subscriptions[0].subscriptionId);
        this.subscriptionInvoiceForm.controls['total'].setValue(this.subscriptions[0].price);
      }
    });
  }

  // Select Subscription
  selectSubscription(subscriptionId: string): void {
    const subscription = this.subscriptions.find((subscription) => { return subscription.id == subscriptionId; });
    if (subscription !== undefined) this.subscriptionInvoiceForm.controls['total'].setValue(subscription.price);
  }
}
