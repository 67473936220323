import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { faCalendar, faCaretDown, faCaretRight, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbCalendar, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../alert.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { Payment } from '../payment';
import { PaymentRefundModalComponent } from '../payment-refund-modal/payment-refund-modal.component';
import { PaymentService } from '../payment.service';
import { SendEmailComponent } from '../send-email/send-email.component';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.css']
})
export class PaymentModalComponent implements OnInit {

  // Properties
  @Input() invoiceId: string;
  @Input() invoiceNumber: number;
  @Input() clientId: string;
  @Input() payment: Payment;
  @Input() showInvoiceLink: boolean = false;
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();
  showPaymentEmail: boolean = false;
  paymentForm: UntypedFormGroup;
  transactionDetailsAreCollapsed: boolean = true;

  // Font Awesome Properties
  faCalendar = faCalendar;
  faCaretRight = faCaretRight;
  faCaretDown = faCaretDown;
  faExternalLinkAlt = faExternalLinkAlt;

  constructor(private paymentService: PaymentService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private calendar: NgbCalendar,
    private dateParserFormatter: NgbDateParserFormatter,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.paymentForm = new UntypedFormGroup({
      amount: new UntypedFormControl(),
      date: new UntypedFormControl(this.calendar.getToday()),
      category: new UntypedFormControl('CARD'),
      status: new UntypedFormControl('CLOSED'),
      note: new UntypedFormControl()
    });
    if (this.payment) this.preparePaymentForm();
  }

  // Open Delete Payment Confirmation Modal
  openDeletePaymentConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this payment?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deletePayment();
    });
  }

  // Open Send Email Modal For Payment
  private openSendEmailModalForPayment(paymentId: string = null): void {
    const modalRef = this.modalService.open(SendEmailComponent);
    modalRef.componentInstance.clientId = (this.payment) ? this.payment.clientId : this.clientId;
    modalRef.componentInstance.invoiceId = (this.payment) ? this.payment.invoiceId : this.invoiceId;
    modalRef.componentInstance.paymentId = (this.payment) ? this.payment.id : paymentId;
    modalRef.componentInstance.type = 'PAYMENT';
    modalRef.componentInstance.subject = `Receipt for Invoice #${this.invoiceNumber}`;
  }

  // Open Payment Refund Modal
  openPaymentRefundModal(): void {
    const modalRef = this.modalService.open(PaymentRefundModalComponent);
    modalRef.componentInstance.payment = this.payment;
    modalRef.componentInstance.saved.subscribe(() => {
      this.saved.emit();
      this.modal.close();
    });
  }

  // Prepare Payment Form
  private preparePaymentForm(): void {
    this.paymentForm.controls.amount.setValue(this.payment.amount.toFixed(2));
    this.paymentForm.controls.date.setValue(this.dateParserFormatter.parse(this.payment.date));
    this.paymentForm.controls.category.setValue(this.payment.category);
    this.paymentForm.controls.status.setValue(this.payment.status);
    this.paymentForm.controls.note.setValue(this.payment.note);
    if (this.payment.isExternal) {
      this.paymentForm.controls.amount.disable();
      this.paymentForm.controls.date.disable();
      this.paymentForm.controls.category.disable();
    }
  }

  // Save Payment
  savePayment(): void {
    if (this.payment) this.updatePayment();
    else this.addPayment();
  }

  // Add Payment
  private addPayment(): void {
    if (this.paymentForm.valid) {
      let payment: any = {
        invoiceId: this.invoiceId,
        amount: this.paymentForm.value.amount,
        date: this.dateParserFormatter.format(this.paymentForm.value.date),
        type: 'DEBIT',
        category: this.paymentForm.value.category,
        status: this.paymentForm.value.status,
        note: this.paymentForm.value.note
      };
      this.paymentService.addPayment(payment).subscribe((paymentId) => {
        this.alertService.showSuccessAlert('Payment Added');
        this.saved.emit();
        this.modal.close();
        if (this.showPaymentEmail) this.openSendEmailModalForPayment(paymentId);
      });
    }
  }

  // Update Payment
  private updatePayment(): void {
    if (this.paymentForm.valid) {
      const payment = {
        id: this.payment.id,
        amount: this.paymentForm.controls.amount.value,
        date: this.dateParserFormatter.format(this.paymentForm.controls.date.value),
        category: this.paymentForm.controls.category.value,
        status: this.paymentForm.controls.status.value,
        note: this.paymentForm.controls.note.value
      };
      this.paymentService.updatePayment(payment).subscribe(() => {
        this.alertService.showSuccessAlert('Payment Updated');
        this.saved.emit();
        this.modal.close();
        if (this.showPaymentEmail) this.openSendEmailModalForPayment();
      });
    }
  }

  // Delete Payment
  deletePayment(): void {
    this.paymentService.deletePayment(this.payment.id).subscribe(() => {
      this.alertService.showSuccessAlert('Payment Deleted');
      this.saved.emit();
      this.modal.close();
    });
  }

  // Toggle Show Payment Email
  toggleShowPaymentEmail(show: boolean): void {
    this.showPaymentEmail = show;
  }

  // Payment Form Accessors
  get amount() { return this.paymentForm.controls.amount; }
  get date() { return this.paymentForm.controls.date; }
  get category() { return this.paymentForm.controls.category; }
  get status() { return this.paymentForm.controls.status; }
  get note() { return this.paymentForm.controls.note; }
}
