<div class="main-wrapper">
    <div class="d-flex align-items-center position-relative min-vh-100 login-cover">
        <!-- start left panel  -->
        <div class="col-lg-5 col-xl-5 d-none d-lg-flex align-items-center px-0 bg-img"></div>
        <!-- end left panel  -->
        <div class="container">
            <div class="row justify-content-center justify-content-lg-start">
                <div class="col-md-8 col-lg-8 col-xl-5 m-auto">
                    <div>
                        <div class="text-center">
                            <picture>
                                <source type="image/webp" srcset="../assets/TinselLogo.webp">
                                <source type="image/png" srcset="../assets/TinselLogo.png">
                                <img src="../assets/TinselLogo.png" width="40%" class="logo" alt="Tinsel Logo" loading="eager">
                            </picture>
                        </div>
                    </div>
                    <ngb-progressbar *ngIf="stage <= 4" class="mb-3" type="success" [value]="stage" max="4" height="15px" [striped]="true"></ngb-progressbar>
                    <!-- User -->
                    <div *ngIf="stage == 0">
                        <h2 class="font-weight-normal">User Registration</h2>
                        <p class="mt-2">Complete the form to create your user. You can make changes later in the Settings page.</p>
                        <form [formGroup]="userForm">
                            <div class="row">
                                <div class="col-sm">
                                    <div class="mb-3">
                                        <label for="USER_FIRST_NAME">First Name</label>
                                        <input id="USER_FIRST_NAME" type="text" class="form-control" placeholder="John" formControlName="firstName" maxlength="150" required autocomplete="given-name" [class.is-invalid]="firstName.invalid && (firstName.dirty || firstName.touched)" [class.is-valid]="firstName.valid && (firstName.dirty || firstName.touched)">
                                        <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                                            <div *ngIf="firstName.errors.required" class="input-validation-msg">First Name is required.</div>
                                            <div *ngIf="firstName.errors.maxlength" class="input-validation-msg">First Name cannot exceed 150 characters.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm">
                                    <div class="mb-3">
                                        <label for="USER_LAST_NAME">Last Name</label>
                                        <input id="USER_LAST_NAME" type="text" class="form-control" placeholder="Appleseed" formControlName="lastName" maxlength="150" required autocomplete="family-name" [class.is-invalid]="lastName.invalid && (lastName.dirty || lastName.touched)" [class.is-valid]="lastName.valid && (lastName.dirty || lastName.touched)">
                                        <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                                            <div *ngIf="lastName.errors.required" class="input-validation-msg">Last Name is required.</div>
                                            <div *ngIf="lastName.errors.maxlength" class="input-validation-msg">Last Name cannot exceed 150 characters.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="mb-3">
                                        <label for="USER_EMAIL">Email</label>
                                        <input id="USER_EMAIL" type="text" class="form-control" placeholder="jappleseed@example.com" formControlName="email" maxlength="320" pattern="^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$" required autocapitalize="off" autocomplete="email" [class.is-invalid]="userEmail.invalid && (userEmail.dirty || userEmail.touched)" [class.is-valid]="userEmail.valid && (userEmail.dirty || userEmail.touched)">
                                        <div *ngIf="userEmail.invalid && (userEmail.dirty || userEmail.touched)">
                                            <div *ngIf="userEmail.errors.required" class="input-validation-msg">Email is required.</div>
                                            <div *ngIf="userEmail.errors.maxlength" class="input-validation-msg">Email cannot exceed 320 characters.</div>
                                            <div *ngIf="userEmail.errors.pattern" class="input-validation-msg">Email is formatted incorrectly.</div>
                                            <div *ngIf="userEmail.errors.alreadyExists" class="input-validation-msg">Email already exists. Please try a different email.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="mb-3">
                                        <label for="USER_PHONE">Phone</label>
                                        <input id="USER_PHONE" type="tel" class="form-control" appPhoneMask placeholder="(000) 000-0000" formControlName="phone" maxlength="14" pattern="^\((\d{3})\)\s(\d{3})-(\d{4})" required autocomplete="tel-national" [class.is-invalid]="phone.invalid && (phone.dirty || phone.touched)" [class.is-valid]="phone.valid && (phone.dirty || phone.touched)">
                                        <div *ngIf="phone.invalid && (phone.dirty || phone.touched)">
                                            <div *ngIf="phone.errors.required" class="input-validation-msg">Phone is required.</div>
                                            <div *ngIf="phone.errors.maxlength" class="input-validation-msg">Phone cannot exceed 14 characters.</div>
                                            <div *ngIf="phone.errors.pattern" class="input-validation-msg">Phone is formatted incorrectly.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="mb-3">
                                        <label for="USER_PASSWORD">Password</label>
                                        <input id="USER_PASSWORD" type="password" class="form-control" placeholder="Password" formControlName="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required autocomplete="new-password" [class.is-invalid]="password.invalid && (password.dirty || password.touched)" [class.is-valid]="password.valid && (password.dirty || password.touched)">
                                        <div *ngIf="password.invalid && (password.dirty || password.touched)">
                                            <div *ngIf="password.errors.required" class="input-validation-msg">Password is required.</div>
                                            <div *ngIf="password.errors.pattern" class="input-validation-msg">Password must be 8 characters and contain uppercase, lowercase, and number characters.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="mb-3">
                                        <label for="USER_CONFIRM_PASSWORD">Confirm Password</label>
                                        <input id="USER_CONFIRM_PASSWORD" type="password" class="form-control" placeholder="Password" formControlName="confirmPassword" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required autocomplete="new-password" [class.is-invalid]="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)" [class.is-valid]="confirmPassword.valid && (confirmPassword.dirty || confirmPassword.touched)">
                                        <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                                            <div *ngIf="confirmPassword.errors.required" class="input-validation-msg">Confirm Password is required.</div>
                                            <div *ngIf="confirmPassword.errors.pattern" class="input-validation-msg">Confirm Password must be 8 characters and contain uppercase, lowercase, and number characters.</div>
                                            <div *ngIf="confirmPassword.errors.doesNotMatch" class="input-validation-msg">Passwords do not match.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="d-flex">
                            <button type="button" class="btn btn-primary ms-auto" (click)="validateUser();">Next</button>
                        </div>
                    </div>
                    <!-- Organization -->
                    <div *ngIf="stage == 1">
                        <h2 class="font-weight-normal">Organization Registration</h2>
                        <p class="mt-2">Complete the form to create your organization. You can make changes later in the Settings page.</p>
                        <form [formGroup]="organizationForm">
                            <div class="mb-3">
                                <label for="ORGANIZATION_NAME">Organization Name</label>
                                <input id="ORGANIZATION_NAME" type="text" class="form-control" formControlName="name" placeholder="Appleseed Contractors" maxlength="150" required autocomplete="organization-title" [class.is-invalid]="name.invalid && (name.dirty || name.touched)" [class.is-valid]="name.valid && (name.dirty || name.touched)">
                                <div *ngIf="name.invalid && (name.dirty || name.touched)">
                                    <div *ngIf="name.errors.required" class="input-validation-msg">Name is required.</div>
                                    <div *ngIf="name.errors.maxlength" class="input-validation-msg">Name cannot exceed 150 characters.</div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="ORGANIZATION_EMAIL">Email</label>
                                <input id="ORGANIZATION_EMAIL" type="text" class="form-control" placeholder="jappleseed@example.com" formControlName="email" maxlength="320" pattern="^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$" required autocapitalize="off" autocomplete="email" [class.is-invalid]="orgEmail.invalid && (orgEmail.dirty || orgEmail.touched)" [class.is-valid]="orgEmail.valid && (orgEmail.dirty || orgEmail.touched)">
                                <div *ngIf="orgEmail.invalid && (orgEmail.dirty || orgEmail.touched)">
                                    <div *ngIf="orgEmail.errors.required" class="input-validation-msg">Email is required.</div>
                                    <div *ngIf="orgEmail.errors.maxlength" class="input-validation-msg">Email cannot exceed 320 characters.</div>
                                    <div *ngIf="orgEmail.errors.pattern" class="input-validation-msg">Email is formatted incorrectly.</div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="ADDRESS_STREET">Street</label>
                                <input id="ADDRESS_STREET" type="text" class="form-control" formControlName="street" placeholder="123 Main Street" maxlength="150" required [class.is-invalid]="street.invalid && (street.dirty || street.touched)" [class.is-valid]="street.valid && (street.dirty || street.touched)">
                                <div *ngIf="street.invalid && (street.dirty || street.touched)">
                                    <div *ngIf="street.errors.required" class="input-validation-msg">Street is required.</div>
                                    <div *ngIf="street.errors.maxlength" class="input-validation-msg">Street cannot exceed 150 characters.</div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="ADDRESS_CITY">City</label>
                                <input id="ADDRESS_CITY" type="text" class="form-control" formControlName="city" placeholder="Some City" maxlength="150" required [class.is-invalid]="city.invalid && (city.dirty || city.touched)" [class.is-valid]="city.valid && (city.dirty || city.touched)">
                                <div *ngIf="city.invalid && (city.dirty || city.touched)">
                                    <div *ngIf="city.errors.required" class="input-validation-msg">City is required.</div>
                                    <div *ngIf="city.errors.maxlength" class="input-validation-msg">City cannot exceed 150 characters.</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="mb-3">
                                        <label for="ADDRESS_STATE">State</label>
                                        <select id="ADDRESS_STATE" class="form-select" formControlName="state" appForbiddenValue="SAS" required [class.is-invalid]="state.invalid && (state.dirty || state.touched)" [class.is-valid]="state.valid && (state.dirty || state.touched)">
                                            <option value="SAS">Select State</option>
                                            <option *ngFor="let state of states" [value]="state.abbreviation">{{state.name}}</option>
                                        </select>
                                        <div *ngIf="state.invalid && (state.dirty || state.touched)">
                                            <div *ngIf="state.errors.required || state.errors.forbiddenValue" class="input-validation-msg">State is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="mb-3">
                                        <label for="ADDRESS_POSTAL_CODE">Postal Code</label>
                                        <input id="ADDRESS_POSTAL_CODE" type="text" class="form-control" formControlName="postalCode" placeholder="00000" maxlength="15" required [class.is-invalid]="postalCode.invalid && (postalCode.dirty || postalCode.touched)" [class.is-valid]="postalCode.valid && (postalCode.dirty || postalCode.touched)">
                                        <div *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
                                            <div *ngIf="postalCode.errors.required" class="input-validation-msg">Postal Code is required.</div>
                                            <div *ngIf="postalCode.errors.maxlength" class="input-validation-msg">Postal Code cannot exceed 15 characters.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="d-flex">
                            <button type="button" class="btn btn-primary" (click)="back()">Back</button>
                            <button type="button" class="btn btn-primary ms-auto" (click)="validateOrganization();">Next</button>
                        </div>
                    </div>
                    <!-- Subscription -->
                    <div *ngIf="stage == 2">
                        <h2 class="font-weight-normal">Subscriptions</h2>
                        <p class="mt-2 mb-0">Choose a subscription.</p>
                        <div class="row mt-3">
                            <div *ngFor="let subscription of subscriptions" class="col" [ngClass]="selectedPromotion && displayTagline(subscription) ? 'promotion': ''">
                                <div class="card" [class.border-success]="selectedSubscription && subscription.id == selectedSubscription.id" style="height: 100%;">
                                    <h5 class="card-header">{{subscription.name}}</h5>
                                    <div class="card-body d-flex flex-column">
                                        <div>
                                            <h4 *ngIf="!subscription.isDefault" [class.text-muted]="!selectedPromotion && !displayTagline(subscription)" class="old-price me-2"><sup>$</sup>{{subscription.defaultPrice}}</h4>
                                            <h3  [class.color-white]="selectedPromotion && displayTagline(subscription)" style="display: inline-block;">
                                                <sup>$</sup>
                                                <span>{{subscription.price}}</span>
                                                <span *ngIf="subscription.frequency == 'MONTHLY'" style="font-size: 16px;">/month</span>
                                                <span *ngIf="subscription.frequency == 'ANNUALLY'" style="font-size: 16px;">/year</span>
                                            </h3>
                                        </div>
                                        <div *ngIf="selectedPromotion && displayTagline(subscription)" class="color-white">{{selectedPromotion.tagline}}</div>
                                        <div class="my-2" [class.text-muted]="!selectedPromotion && !displayTagline(subscription)" [class.mt-0]="selectedPromotion && displayTagline(subscription)">Includes 10 Users<br>Additional users $7.50 per month.</div>
                                        <div class="d-flex mt-auto">
                                            <button type="button" [disabled]="selectedSubscription && subscription.id === selectedSubscription.id" 
                                                [ngClass]="selectedPromotion && displayTagline(subscription) ? 'btn-danger': 'btn-primary'" 
                                                class="btn mx-auto" (click)="selectSubscription(subscription);">
                                            {{selectedSubscription && subscription.id === selectedSubscription.id ? 'Selected' : 'Select'}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <form [formGroup]="promoCodeForm">
                                    <div class="input-group">
                                        <input id="PROMO_CODE" formControlName="promoCode" type="text" class="form-control" placeholder="CODE" style="text-transform: uppercase;">
                                        <button type="button" class="btn btn-outline-primary" (click)="applyPromoCode(promoCodeForm.controls['promoCode'].value);">
                                            Apply
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="col" *ngIf="errorMsg">
                                <label class="text-danger">
                                    {{ errorMsg }}
                                </label>
                            </div>
                        </div>
                        <div class="d-flex mt-3">
                            <button type="button" class="btn btn-primary" (click)="back()">Back</button>
                            <button *ngIf="selectedSubscription" type="button" class="btn btn-primary ms-auto" (click)="stage = 3">Next</button>
                        </div>
                    </div>
                    <!-- Billing -->
                    <div *ngIf="stage == 3">
                        <h2 class="font-weight-normal">Billing Information</h2>
                        <p class="mt-2">Fill out your billing information.</p>
                        <div class="form-check mb-3">
                            <input id="SAME_AS_ORGANIZATION" type="checkbox" class="form-check-input" (change)="toggleSameAddress($event);">
                            <label for="SAME_AS_ORGANIZATION" class="form-check-label">Same as Organization</label>
                        </div>
                        <form [formGroup]="billingForm">
                            <div class="mb-3">
                                <label for="BILLING_NAME">Name</label>
                                <input id="BILLING_NAME" type="text" class="form-control" formControlName="name" placeholder="John Appleseed" maxlength="150" required autocomplete="name" [class.is-invalid]="billingName.invalid && (billingName.dirty || billingName.touched)" [class.is-valid]="billingName.valid && (billingName.dirty || billingName.touched)">
                                <div *ngIf="billingName.invalid && (billingName.dirty || billingName.touched)">
                                    <div *ngIf="billingName.errors.required" class="input-validation-msg">Name is required.</div>
                                    <div *ngIf="billingName.errors.maxlength" class="input-validation-msg">Name cannot exceed 150 characters.</div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="BILLING_ADDRESS_STREET">Street</label>
                                <input id="BILLING_ADDRESS_STREET" type="text" class="form-control" formControlName="street" placeholder="123 Main Street" maxlength="150" required [class.is-invalid]="billingStreet.invalid && (billingStreet.dirty || billingStreet.touched)" [class.is-valid]="billingStreet.valid && (billingStreet.dirty || billingStreet.touched)">
                                <div *ngIf="billingStreet.invalid && (billingStreet.dirty || billingStreet.touched)">
                                    <div *ngIf="billingStreet.errors.required" class="input-validation-msg">Street is required.</div>
                                    <div *ngIf="billingStreet.errors.maxlength" class="input-validation-msg">Street cannot exceed 150 characters.</div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="BILLING_ADDRESS_CITY">City</label>
                                <input id="BILLING_ADDRESS_CITY" type="text" class="form-control" formControlName="city" placeholder="Some City" maxlength="150" required [class.is-invalid]="billingCity.invalid && (billingCity.dirty || billingCity.touched)" [class.is-valid]="billingCity.valid && (billingCity.dirty || billingCity.touched)">
                                <div *ngIf="billingCity.invalid && (billingCity.dirty || billingCity.touched)">
                                    <div *ngIf="billingCity.errors.required" class="input-validation-msg">City is required.</div>
                                    <div *ngIf="billingCity.errors.maxlength" class="input-validation-msg">City cannot exceed 150 characters.</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="mb-3">
                                        <label for="BILLING_ADDRESS_STATE">State</label>
                                        <select id="BILLING_ADDRESS_STATE" class="form-select" formControlName="state" appForbiddenValue="SAS" required [class.is-invalid]="billingState.invalid && (billingState.dirty || billingState.touched)" [class.is-valid]="billingState.valid && (billingState.dirty || billingState.touched)">
                                            <option value="SAS">Select State</option>
                                            <option *ngFor="let state of states" [value]="state.abbreviation">{{state.name}}</option>
                                        </select>
                                        <div *ngIf="billingState.invalid && (billingState.dirty || billingState.touched)">
                                            <div *ngIf="billingState.errors.required || billingState.errors.forbiddenValue" class="input-validation-msg">State is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="mb-3">
                                        <label for="BILLING_ADDRESS_POSTAL_CODE">Postal Code</label>
                                        <input id="BILLING_ADDRESS_POSTAL_CODE" type="text" class="form-control" formControlName="postalCode" placeholder="00000" maxlength="15" required [class.is-invalid]="billingPostalCode.invalid && (billingPostalCode.dirty || billingPostalCode.touched)" [class.is-valid]="billingPostalCode.valid && (billingPostalCode.dirty || billingPostalCode.touched)">
                                        <div *ngIf="billingPostalCode.invalid && (billingPostalCode.dirty || billingPostalCode.touched)">
                                            <div *ngIf="billingPostalCode.errors.required" class="input-validation-msg">Postal Code is required.</div>
                                            <div *ngIf="billingPostalCode.errors.maxlength" class="input-validation-msg">Postal Code cannot exceed 15 characters.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="d-flex">
                            <button type="button" class="btn btn-primary" (click)="back()">Back</button>
                            <button type="button" class="btn btn-primary ms-auto" (click)="validateBillingForm();">Next</button>
                        </div>
                    </div>
                    <!-- Review -->
                    <div *ngIf="stage == 4">
                        <h2 class="font-weight-normal">Review</h2>
                        <div class="row flex-column mt-2">
                            <div class="col">
                                <h5>User</h5>
                                <div>{{firstName.value}} {{lastName.value}}</div>
                                <div>{{userEmail.value}}</div>
                                <div>{{phone.value}}</div>
                            </div>
                            <div class="col mt-2">
                                <h5>Organization</h5>
                                <div>{{name.value}}</div>
                                <div>{{orgEmail.value}}</div>
                                <div>{{street.value}}</div>
                                <div>{{city.value}}, {{state.value}} {{postalCode.value}}</div>
                            </div>
                            <div *ngIf="selectedSubscription" class="col mt-2">
                                <h5>Subscription</h5>
                                <div>{{selectedSubscription.name}}</div>
                                <div>
                                    <span>${{selectedSubscription.price}}</span>
                                    <span *ngIf="selectedSubscription.frequency == 'MONTHLY'" style="font-size: 16px;">/month</span>
                                    <span *ngIf="selectedSubscription.frequency == 'ANNUALLY'" style="font-size: 16px;">/year</span>
                                </div>
                                <div *ngIf="selectedPromotion && displayTagline(selectedSubscription)">{{selectedPromotion.tagline}}</div>
                            </div>
                            <div *ngIf="selectedSubscription" class="col mt-2">
                                <h5>Billing Information</h5>
                                <div>{{billingName.value}}</div>
                                <div>{{billingStreet.value}}</div>
                                <div>{{billingCity.value}}, {{billingState.value}} {{billingPostalCode.value}}</div>
                            </div>
                            <div *ngIf="!paymentSuccessful && selectedSubscription" class="col mt-2">
                                <app-registration-payment-form [amount]="selectedSubscription.price" [payNow]="(this.selectedPromotion) ? this.selectedPromotion.payNow : true" [subscriptionId]="selectedSubscription.id" [billingInformation]="billingForm.value" (subscriptionSuccessful)="subscriptionSuccessful($event);"></app-registration-payment-form>
                            </div>
                            <div *ngIf="processingRegistration" class="col d-flex mt-2">
                                <h5>Creating your account...</h5>
                                <fa-icon [icon]="faRotate" [spin]="true" class="ms-2"></fa-icon>
                            </div>
                            <div *ngIf="registrationFailed" class="col">
                                <div style="color: var(--bs-red);">There was an issue creating your account. Please try again. If the issue persists, please contact support.</div>
                            </div>
                            <div class="col mt-2 d-flex">
                                <button *ngIf="!paymentSuccessful" type="button" class="btn btn-primary" (click)="back()">Back</button>
                                <button *ngIf="registrationFailed" type="button" class="btn btn-primary" [class.ms-auto]="!registrationFailed" (click)="registerOrganization();">Register</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div *ngIf="stage == 5">
                        <div class="d-flex flex-column">
                            <h2 class="font-weight-normal mx-auto">Creating Your Account</h2>
                            <div class="mt-2">We are now creating your account. <span style="font-weight: bold; color: var(--bs-red);">Please do not leave this page.</span> You will be redirected to the login page when registration completes.</div>
                            <fa-icon [icon]="faRotate" [spin]="true" size="4x" class="mt-2 mx-auto"></fa-icon>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>