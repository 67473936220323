<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">Employee Time Clock - Detailed</h2>
        </div>
        <div class="row w-100 justify-content-end">
            <div class="col-auto pe-2">
                <app-date-picker-controller #datePickerController componentId="Reports.PayrollSummary" preset="CUR_WK" [showButtons]="true" (dateChanged)="dateChanged($event);" [onLoad]="true"></app-date-picker-controller>
            </div>
            <div class="col-auto"  style="padding-top:.15rem">
                <button type="button" class="btn btn-primary" (click)="downloadReport();">
                    <fa-icon [icon]="faFileDownload"></fa-icon><span class="ms-2 d-none d-md-inline">Export</span>
                </button>
            </div>
        </div>
    </nav>
    <div class="row mt-3">
        <div class="card p-0">
            <div class="card-body">
                <h5 class="card-title">Description</h5>
                <p class="card-text m-0">This report can be used to see the clock in and clock out of employees per project.</p>
            </div>
        </div>
    </div>
    <div class="row button-container mt-2" *ngIf="isMobile">
      <div class="sort"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>
    </div>
    <div class="row mt-1">
        <div class="col-sm-12" *ngIf="!isMobile" class="col table-responsive">
            <table>
                <thead>
                    <tr>
                        <!-- Employee -->
                        <th (click)="tableControls.sort('user_last_name');">
                            Employee
                            <fa-icon [icon]="(tableControls.isSortedBy('user_last_name') && tableControls.isSortedASC() ? faSortDown : tableControls.isSortedBy('user_last_name') &&  tableControls.isSortedDesc()  ? faSortUp : faSort)" class="sortButton"></fa-icon>
                        </th>
                        <th (click)="tableControls.sort('client_name');">
                            Client
                            <fa-icon [icon]="(tableControls.isSortedBy('client_name') && tableControls.isSortedASC() ? faSortDown : tableControls.isSortedBy('client_name') &&  tableControls.isSortedDesc()  ? faSortUp : faSort)" class="sortButton"></fa-icon>
                        </th>
                        <th (click)="tableControls.sort('project_name');">
                            Project
                            <fa-icon [icon]="(tableControls.isSortedBy('project_name') && tableControls.isSortedASC() ? faSortDown : tableControls.isSortedBy('project_name') &&  tableControls.isSortedDesc()  ? faSortUp : faSort)" class="sortButton"></fa-icon>
                        </th>
                        <th>Task</th>
                        <th (click)="tableControls.sort('time_entry_start');">
                            Time IN
                            <fa-icon [icon]="(tableControls.isSortedBy('time_entry_start') && tableControls.isSortedASC() ? faSortDown : tableControls.isSortedBy('time_entry_start') &&  tableControls.isSortedDesc()  ? faSortUp : faSort)" class="sortButton"></fa-icon>
                        </th>
                        <th (click)="tableControls.sort('time_entry_end');">
                            Time OUT
                            <fa-icon [icon]="(tableControls.isSortedBy('time_entry_end') && tableControls.isSortedASC() ? faSortDown : tableControls.isSortedBy('time_entry_end') &&  tableControls.isSortedDesc()  ? faSortUp : faSort)" class="sortButton"></fa-icon>
                        </th>
                        <th>Hours</th>
                        <th>Job Cost (No Tax)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let entry of entries">
                        <td data-label="Employee">{{entry.lastName}}, {{entry.firstName}}</td>
                        <td data-label="Client">{{entry.client}}</td>
                        <td data-label="Project">{{entry.project}}</td>
                        <td data-label="Task">{{entry.task}}</td>
                        <td data-label="Time IN">{{entry.timeIn}}</td>
                        <td data-label="Time OUT">{{entry.timeOut}}</td>
                        <td data-label="Hours">{{entry.hours | number : '1.2-2'}}</td>
                        <td data-label="Job Cost (No Tax)">{{entry.jobCost | currency}}</td>
                    </tr>
                </tbody>
                <!-- <tfoot>
                    <tr>
                        <td>Total</td>
                        <td>{{totalRegularHours}}</td>
                        <td>{{totalOvertimeHours}}</td>
                        <td></td>
                        <td>{{totalGrossPay | currency}}</td>
                    </tr>
                </tfoot> -->
            </table>
        </div>
    </div>
    <div *ngIf="!isMobile" class="row table-controls">
        <div *ngIf="tableControls.isNotDefault()" class="col-md-auto my-3">
            <button type="button" class="btn btn-outline-danger" (click)="tableControls.resetTableConfiguration();">
                <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
                <span>Reset</span>
            </button>
        </div>
    </div>
    <div *ngIf="isMobile" class="mt-2">
        <div class="scrollable">
            <ul class="list-group">
                <li *ngFor="let entry of entries" class="list-group-item">
                    <div class="d-flex">
                        <div>
                            <strong>{{entry.lastName}}, {{entry.firstName}}</strong>
                            <div><strong>Date:</strong> {{entry.date}}</div>
                            <div><strong>Client:</strong> {{entry.client}}</div>
                            <div><strong>Project:</strong> {{entry.project}}</div>
                            <div><strong>Task:</strong> {{entry.task}}</div>
                            <div><strong>Time IN:</strong> {{entry.timeIn}}</div>
                            <div><strong>Time OUT:</strong> {{entry.timeOut}}</div>
                            <div><strong>Hours:</strong> {{entry.hours}}</div>
                            <div><strong>Job Cost (No Tax):</strong> {{entry.jobCost}}</div>
                        </div>
                    </div>
                </li>
                <!-- <li class="list-group-item list-group-item-secondary">
                    <div class="d-flex">
                        <div>
                            <strong>Total</strong>
                            <div><strong>Regular Hours:</strong> {{totalRegularHours}}</div>
                            <div><strong>Overtime Hours:</strong> {{totalOvertimeHours}}</div>
                            <div><strong>Gross Pay:</strong> {{totalGrossPay | currency}}</div>
                        </div>
                    </div>
                </li> -->
                <li *ngIf="entries.length == 0" class="list-group-item">No Employee Time Clock Entries</li>
            </ul>
        </div>
    </div>
</div>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block" [autoClose]="true" placement="bottom-left">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>

<!-- LOADER -->
<ngx-spinner>
  <p class="loading-spinner">Loading...</p>
</ngx-spinner>
