<div class="proposal-accordion" ngbAccordion class="col-lg-12">
  <div ngbAccordionItem>
    <h5 ngbAccordionHeader>
      <button ngbAccordionButton>
        <h5 class="px-3">Crew Manager</h5>
      </button>
    </h5>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <div class="row">
            <div class="col-lg-12">
              <ngb-alert *ngIf="showAlert" #closingAlert type="danger" (closed)="closeAlert()">
                <div>
                  You have pending changes, do you want to save them?
                </div>
                <div style="margin-top: 15px;">
                  <button (click)="updateCrewMembers()" style="margin-right: 10px; width: 200px;"
                    class="btn btn-primary" type="button">
                    Save Changes
                  </button>
                  <button (click)="continueWithoutSave()" style="margin-right: 10px; width: 200px;"
                    class="btn btn-danger" type="button">
                    Leave
                  </button>
                </div>
              </ngb-alert>
            </div>
            <div class="col-lg-4">
              <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                  <h5>Crews</h5>
                  <div class="form-check form-switch" [formGroup]="archiveCrewsFormGroup">
                    <input class="form-check-input" type="checkbox" role="switch" formControlName="showArchive">
                    <label class="form-check-label" for="flexSwitchCheckDefault">
                      Show Archived Crews
                    </label>
                  </div>
                  <div class="">
                    <button #popOver="ngbPopover" [ngbPopover]="popContent" popoverTitle="New Crew" type="button"
                      (click)="crewForm.reset()" class="btn btn-primary ms-2" [autoClose]="'outside'">
                      <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
                      <span>New</span>
                    </button>
                  </div>
                </div>
                <div class="card-body crew-box py-2 overflow-auto">
                  <div class="row crew-line-item align-items-center" *ngFor="let crew of crews"
                    (click)="setSelectedCrew(crew)" [class.selected-option]="crew.crewId == selectedCrew.crewId">
                    <div class="col-2">
                      <fa-icon [icon]="faSquare" class="display-6"
                        [ngStyle]="{ 'color': crew.crewColor ? crew.crewColor: '#000000' }"></fa-icon>
                    </div>
                    <div class="col-6 text-left">
                      <span>{{crew.crewName}}</span>
                    </div>
                    <div class="col-4 p-2 flex text-end" *ngIf="!showArchive.value">
                      <button #popOverUpdate="ngbPopover" [ngbPopover]="popContentUpdate" popoverTitle="Edit Crew"
                        [autoClose]="false" class="btn btn-secondary me-2" type="button" (click)="modifyCrew(crew)"
                        ngbTooltip="Edit">
                        <fa-icon [icon]="faPencil"></fa-icon>
                      </button>
                      <button class="btn btn-danger float-end" type="button"
                        (click)="deleteCrew(crew.crewName, crew.crewId);" ngbTooltip="Delete">
                        <fa-icon [icon]="faTrashCan"></fa-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card">
                <div class="card-header">
                  <h5>Employees on Crew</h5>
                </div>
                <div class="card-body crew-box py-2 overflow-auto">
                  <ng-container *ngFor="let user of membersOnCrew; let i = index">
                    <div class="row crew-line-item"
                      [class.selected-option]="selectedMemberInCrew && selectedMemberInCrew.id == user.id"
                      (click)="setSelectedMemberInCrew(user)">
                      <div class="col-12 text-left">
                        <span>{{user.firstName + ' ' + user.lastName}}</span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col-lg-2 d-flex flex-grow-1 justify-content-center align-items-center">
              <div class="row justify-content-center">
                <div class="col-lg-7 p-0" style="margin-bottom: 10px;">
                  <button (click)="moveToMemberToCrew()" ngbTooltip="Move selected employee to the crew" type="button"
                    [disabled]="!selectedMemberNotInCrew || showArchive.value" class="btn btn-primary w-100 control-btn">
                    <fa-icon [icon]="faChevronLeft"></fa-icon> Add to Crew
                  </button>
                </div>
                <div class="col-lg-7 p-0" style="margin-bottom: 10px;">
                  <button (click)="removeToMemberToCrew()" ngbTooltip="Remove selected employee from the crew"
                    [disabled]="!selectedMemberInCrew || showArchive.value" type="button" class="btn btn-danger w-100 control-btn">
                    Remove from Crew <fa-icon [icon]="faChevronRight"></fa-icon>
                  </button>
                </div>
                <div class="col-lg-7 p-0" style="margin-bottom: 10px;">
                  <button (click)="updateCrewMembers()" ngbTooltip="Save crew" type="button"
                    class="btn btn-primary w-100 control-btn save-class" [disabled]="isSaveDisabled || showArchive.value">
                    Save Changes <fa-icon [icon]="faSave"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card">
                <div class="card-header">
                  <h5>Employees NOT on Crew</h5>
                </div>
                <div class="card-body crew-box py-2 overflow-auto">
                  <ng-container *ngFor="let user of membersNotOnCrew; let i = index">
                    <div [class.selected-option]="selectedMemberNotInCrew && selectedMemberNotInCrew.id == user.id"
                      (click)="setSelectedMemberNotInCrew(user)" class="row crew-line-item">
                      <div class="col-12 text-left">
                        <span>{{user.firstName + ' ' + user.lastName}}</span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<ng-template #popContent>
  <ng-container *ngTemplateOutlet="forms"></ng-container>
</ng-template>
<ng-template #popContentUpdate>
  <ng-container *ngTemplateOutlet="forms"></ng-container>
</ng-template>

<ng-template #forms>
  <div class="input-group mb-3" [formGroup]="crewForm">
    <input type="text" class="form-control" placeholder="Crew Name" aria-label="Crew Name"
      aria-describedby="basic-addon2" formControlName="crewName" (focus)="$event.stopPropagation()">
    <div class="input-group-append">
      <input class="ms-auto h-100" style="cursor: pointer;" type="color" formControlName="crewColor">
    </div>
  </div>
  <button class="btn btn-primary w-100" type="button" [disabled]="!crewForm.valid" (click)="createCrew()">
    <fa-icon [icon]="faSave"></fa-icon>
    {{ crewForm.get('crewId').value ? 'Update': 'Create' }} Crew
  </button>
</ng-template>