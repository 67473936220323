import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ProjectFile } from '../project-file';
import { NgbActiveModal, NgbModal, NgbModalRef, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { AlertService } from '../alert.service';
import { faTrashCan, faDownload, faEye, faEyeSlash, faImages, faUpload, faPlus, faPencil, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ProjectFileService } from '../project-file.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { Router } from '@angular/router';
import { MockupService } from '../mockup.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MediaLightboxComponent } from '../media-lightbox/media-lightbox.component';
import { FileUploadModalComponent } from '../file-upload-modal/file-upload-modal.component';
import { DomSanitizer  } from '@angular/platform-browser';
@Component({
  selector: 'app-project-files',
  templateUrl: './project-files.component.html',
  styleUrls: ['./project-files.component.css']
})
export class ProjectFilesComponent implements OnInit {
  // For specific template modal projectFilePdf
  @ViewChild('projectFilePdf') projectFilePdf: TemplateRef<any>;

  @Input() private projectId: string;

  private modalReference: NgbModalRef;

  // Project Files
  projectFiles: ProjectFile[] = [];
  projectImages: ProjectFile[] = [];
  projectFileForm: UntypedFormGroup;
  selectedProjectFile: ProjectFile;
  selectedPdfProjectFile: ProjectFile;

  // Font Awesome Properties
  faTrashCan = faTrashCan;
  faDownload = faDownload;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faImages = faImages;
  faUpload = faUpload;
  faPlus = faPlus;
  faPencil = faPencil;
  faEllipsis = faEllipsis;

  constructor(private projectFileService: ProjectFileService,
    private mockupService: MockupService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private router: Router,
    public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.projectFileForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      includedOnProposal: new UntypedFormControl(false)
    });
    this.getProjectFiles();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open File Upload Modal
  openFileUploadModal(): void {
    const modalReference = this.modalService.open(FileUploadModalComponent);
    modalReference.componentInstance.getPresignedURL = (data: any) => {
      data.isFromMockup = false;
      return lastValueFrom(this.projectFileService.addProjectFile(this.projectId, data));
    };
    modalReference.componentInstance.completed.subscribe(() => {
      this.getProjectFiles();
    });
  }

  // Open Rename Project File Modal
  openRenameProjectFileModal(content: NgbActiveModal, projectFile: ProjectFile): void {
    this.selectedProjectFile = projectFile;
    this.projectFileForm.controls.name.setValue(projectFile.name);
    this.projectFileForm.controls.includedOnProposal.setValue(projectFile.includedOnProposal);
    this.modalReference = this.modalService.open(content);
  }

  // Open Delete File Confirmation Modal
  openDeleteFileConfirmationModal(projectFileId: string): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Deleting this file will remove it from all projects. Are you sure you would like to delete this file?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteProjectFile(projectFileId);
    });
  }

  // Open Media Lightbox
  openMediaLightbox(projectFile?: ProjectFile) {
    if(projectFile?.type?.includes('pdf')) {
      this.selectedPdfProjectFile = projectFile;
      this.modalService.open(this.projectFilePdf, { centered: true, modalDialogClass: 'custom-modal-dialog', size: 'xl' });
      this.modalReference.componentInstance.lightboxItems = this.projectFiles.filter((projectFile) => { return this.isMedia(projectFile); }).map((projectFile) => { return { id: projectFile.id, type: projectFile.type, name: projectFile.name, url: this.sanitizer.bypassSecurityTrustUrl(projectFile.presignedUrl) }; });
      this.modalReference.componentInstance.selectedLightboxItemId = projectFile.id;
      return;
    }
    if (projectFile && !this.isMedia(projectFile)) return;
    this.modalReference = this.modalService.open(MediaLightboxComponent, { centered: true, modalDialogClass: 'custom-modal-dialog', size: 'xl' });
    this.modalReference.componentInstance.lightboxItems = this.projectFiles.filter((projectFile) => { return this.isMedia(projectFile); }).map((projectFile) => { return { id: projectFile.id, type: projectFile.type, name: projectFile.name, url: projectFile.presignedUrl }; });
    if (projectFile) this.modalReference.componentInstance.selectedLightboxItemId = projectFile.id;
  }

  // Open Additional Project File Options
  openAdditionalProjectFileOptions(popover: NgbPopover, projectFile: ProjectFile): void {
    if (popover.isOpen()) popover.close();
    else popover.open({ popover, projectFile });
  }

  /* ----- Project Files ----- */

  // Get Project Files
  private getProjectFiles(): void {
    const params = {
      searchTerm: null,
      sortBy: 'pf.project_file_name',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:pf.project_id': JSON.stringify([this.projectId])
    };
    this.projectFileService.getProjectFiles(params).subscribe((projectFiles) => {
      this.projectFiles = projectFiles;
    });
  }

  // Update Project File Name
  updateProjectFileName(): void {
    if (this.projectFileForm.valid) {
      const data: any = {
        id: this.selectedProjectFile.id,
        name: this.projectFileForm.value.name,
        includedOnProposal: this.projectFileForm.value.includedOnProposal
      };
      this.projectFileService.updateProjectFile(data).subscribe(() => {
        this.alertService.showSuccessAlert('Project File Updated');
        this.getProjectFiles();
        this.modalReference.close();
      });
    } else {
      this.projectFileForm.markAllAsTouched();
    }
  }

  // Update Project File Included On Proposal
  updateProjectFileIncludedOnProposal(projectFile: ProjectFile): void {
    const data: any = {
      id: projectFile.id,
      name: projectFile.name,
      includedOnProposal: !projectFile.includedOnProposal
    };
    this.projectFileService.updateProjectFile(data).subscribe(() => {
      this.alertService.showSuccessAlert('Project File Updated');
      this.getProjectFiles();
    });
  }

  // Delete Project File
  deleteProjectFile(projectFileId: string): void {
    this.projectFileService.deleteProjectFile(projectFileId).subscribe(() => {
      this.alertService.showSuccessAlert('Project File Deleted');
      this.getProjectFiles();
    });
  }

  // Download File
  downloadFile(file: ProjectFile): void {
    this.projectFileService.getProjectFilePresignedDownloadUrl(file.id).subscribe((presignedUrl) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = presignedUrl;
      downloadLink.setAttribute('download', file.name);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    });
  }

  // Open File
  openFile(projectFile: ProjectFile): void {
    window.open(projectFile.presignedUrl);
  }

  // Is Image
  isImage(projectFile: ProjectFile): boolean {
    return projectFile.type.includes('image');
  }

  // Is Media
  isMedia(projectFile: ProjectFile): boolean {
    return projectFile.type.includes('image') || projectFile.type.includes('video');
  }

  // Is File Clickable and Viewable
  isFileClickable(projectFile: ProjectFile): boolean {
    return projectFile.type.includes('image') || projectFile.type.includes('video') || projectFile.type.includes('pdf');
  }

  // Is Gallery Available
  isGalleryAvailable(): boolean {
    return this.projectFiles.filter((projectFile) => { return this.isMedia(projectFile); }).length > 0;
  }

  /* ----- Mockups ----- */

  // Add Mockup
  addMockup(projectFile: ProjectFile): void {
    const mockup: any = {
      projectFileId: projectFile.id,
      name: projectFile.name
    };
    this.mockupService.addMockup(mockup).subscribe((mockupId) => {
      this.alertService.showSuccessAlert('Mockup Added');
      this.router.navigate(['/mockupTool'], { queryParams: { mockupId: mockupId } });
    });
  }
}
