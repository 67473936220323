<form [formGroup]="campaignForm">

  <div class="mb-3">
    <div class="row mb-2">
      <div class="col d-flex">
        <label class="my-auto">Friendly Name&nbsp;
          <span class="ms-auto" style="cursor: pointer;" [ngbPopover]="friendlyNameInfo" triggers="click">
            <fa-icon [icon]="faInfoCircle"></fa-icon>
          </span>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <input type="text" class="form-control" formControlName="name"
          placeholder="Ex. Marketing Campaign Early Bird deals" />
      </div>
    </div>
  </div>

  <div class="mb-3">
    <div class="row mb-1">
      <div class="col d-flex">
        <label class="my-auto">Number(s) to use</label>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col d-flex" *ngIf="numbersToUse.controls.length > 0">
        <ngb-alert class="w-100 number-use-info" [type]="'info'" [dismissible]="false">
          <strong>Note:</strong> Once a number is assigned to a campaign, it can only be used in that campaign.
        </ngb-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="tags-input">
          <div class="tags">
            <span *ngFor="let tag of numbersToUse.controls; let i = index" class="badge bg-primary me-2">
              {{ tag.value }}
              <button type="button" class="btn-close btn-close-white" aria-label="Close"
                (click)="removeNumbersToUse(tag)"></button>
            </span>
          </div>
        </div>
        <div ngbDropdown class="d-inline-block" autoClose="outside">
          <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
            Select from the options
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button *ngFor="let option of numbers" ngbDropdownItem (click)="toggleOption(option)"
              [class.active]="isSelected(option)">
              {{ option.text }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3">
    <div class="row mb-2">
      <div class="col d-flex">
        <label class="my-auto">Use Case</label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <select formControlName="usAppToPersonUsecase" class="form-control">
          <option [value]="null">Select from the option</option>
          <option *ngFor="let option of useCases" [value]="option.value">{{ option.label }}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="mb-3">
    <div class="row mb-2">
      <div class="col d-flex">
        <label class="my-auto">Description</label><br />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <textarea rows="5" placeholder="Ex. We are a Christmas Light installation company that sends reminders leading up to scheduled appointments. We send promotional sms with our newly released services and offers. We communicate with our customers to determine the most appropriate services for the needs."
          formControlName="description" class="form-control" type="text">
          </textarea>
      </div>
    </div>
  </div>

  <div class="mb-3">
    <div class="row mb-2">
      <div class="col d-flex">
        <label class="my-auto">Message Flow</label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <textarea rows="5"
          placeholder="Ex. Customers can opt-in by visiting www.website.com and adding their phone number. They then check a box agreeing to receive text messages from Example Brand. Additionally, customers can give verbal consent for us to message them when discussing services."
          formControlName="messageFlow" class="form-control" type="text">
          </textarea>
      </div>
    </div>
  </div>

  <div class="mb-3" formArrayName="messageSamples">
    <div class="row mb-1">
      <div class="col d-flex">
        <label class="my-auto">Message Samples</label>
        <button (click)="addMessageSample()" type="button" class="btn btn-primary ms-auto">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
      </div>
      <small style="padding-left: 0px;">Must include lead name, your name, business name & opt-out language</small>
    </div>
    <div class="row mb-2">
      <div class="col d-flex">
        <ngb-alert class="w-100 message-sample-warning" [type]="'warning'" [dismissible]="false">
          <strong>Note:</strong> We recommend adding at least 2 sample messages to improve your chances of getting approved. Click the green + button on the right to add more messages.
        </ngb-alert>
      </div>
    </div>
    <div class="row" *ngFor="let sample of messageSamples.controls; let i=index">
      <div class="col-sm-11">
        <textarea rows="5"
          [placeholder]="i != 1 ? 'Ex. Hi [first.name], this is Zack from Christmas Brand. Your installation has been scheduled for [installation.date]. Please call us at [phone.number] to reschedule. You may reply STOP at anytime to opt-out.': 'Hi [first.name]. This is Zack from Christmas Brand. I wanted to extend an offer of $250 off your next holiday installation. Please check your email for more details. You may reply STOP at anytime to opt-out.'"
          [formControlName]="i" class="form-control my-2" type="text">
            </textarea>
      </div>
      <div class="col-sm-1" *ngIf="i != 0">
        <button type="button" class="btn btn-danger" (click)="removeMessageSample(i)"><fa-icon
            [icon]="faTrash"></fa-icon></button>
      </div>
    </div>
  </div>

  <div class="mb-3">
    <div class="row mb-2">
      <div class="col d-flex">
        <label class="my-auto">Has Embedded Links</label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-check form-check-inline">
          <input id="EMBEDDED_LINKS_TRUE" class="form-check-input" type="radio" formControlName="hasEmbeddedLinks"
            [value]="true">
          <label for="EMBEDDED_LINKS_TRUE" class="form-check-label" style="font-weight: normal;">True</label>
        </div>
        <div class="form-check form-check-inline">
          <input id="EMBEDDED_LINKS_FALSE" class="form-check-input" type="radio" formControlName="hasEmbeddedLinks"
            [value]="false">
          <label for="EMBEDDED_LINKS_FALSE" class="form-check-label" style="font-weight: normal;">False</label>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3">
    <div class="row mb-2">
      <div class="col d-flex">
        <label class="my-auto">Has Embedded Phone</label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-check form-check-inline">
          <input id="EMBEDDED_PHONE_TRUE" class="form-check-input" type="radio" formControlName="hasEmbeddedPhone"
            [value]="true">
          <label for="EMBEDDED_PHONE_TRUE" class="form-check-label" style="font-weight: normal;">True</label>
        </div>
        <div class="form-check form-check-inline">
          <input id="EMBEDDED_PHONE_FALSE" class="form-check-input" type="radio" formControlName="hasEmbeddedPhone"
            [value]="false">
          <label for="EMBEDDED_PHONE_FALSE" class="form-check-label" style="font-weight: normal;">False</label>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #friendlyNameInfo>
  <p>
    Friendly Name is simply a name for this campaign. It is only used internally and not shown to the client.
    Some examples of Friendly Names are: Main Office Line, Marketing Numbers, or Account Notifications Numbers.
    This is not to be confused with Caller ID which will be set up in a later step.
  </p>
</ng-template>
