<div class="row">
    <div class="col-sm-12">
        <form [formGroup]="businessIdentityForm">
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">
                            Business Name
                            <span class="ms-auto" style="cursor: pointer;" placement="top" ngbPopover="Enter the exact legal business name as 
                            registered with the EIN, which can be found on the CP 575 EIN Confirmation Letter.">
                                <fa-icon [icon]="faQuestionCircle"></fa-icon>
                            </span> 
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. Elves and Ladders" formControlName="business_name" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">
                            Business Industry
                            <span class="ms-auto" style="cursor: pointer;" placement="top" ngbPopover="The industry that most closely matches that of the business">
                                <fa-icon [icon]="faQuestionCircle"></fa-icon>
                            </span>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <select id="CLIENT_STATUS" class="form-select" formControlName="business_industry" required>
                            <option selected disabled value="">Ex. Profession services</option>
                            <option *ngFor="let bus of businessIndustryValues" [value]="bus.value">{{bus.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Registration Identifier</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <select id="CLIENT_STATUS" class="form-select" formControlName="business_registration_identifier" required>
                            <option selected disabled value="">Ex. EIN</option>
                            <option *ngFor="let bus of businessRegistrationIdentifierValues" [value]="bus.value">{{bus.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Business Registration Number</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. K808227" formControlName="business_registration_number" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Business Type</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <select id="CLIENT_STATUS" class="form-select" formControlName="business_type" required>
                            <option selected disabled value="">Ex. Corporation</option>
                            <option *ngFor="let bus of businessTypeValues" [value]="bus.value">{{bus.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Social Media Profile URL (optional)</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. www.facebook.com/TinselCRM" formControlName="social_media_profile_urls" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Website URL (optional)</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. www.tinselcrm.com" formControlName="website_url" class="form-control" type="text">
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-sm-12 d-flex">
        <button (click)="submitForm()" type="button" class="btn btn-primary ms-auto">
            Submit
        </button>
    </div>
</div>