<div *ngIf="!proposalLoaded" class="loading-overlay d-flex">
  <div class="d-flex flex-column m-auto">
    <h2>Loading Proposal</h2>
    <fa-icon [icon]="faCircleNotch" size="2xl" spin="true" class="mx-auto mt-2"></fa-icon>
  </div>
</div>
<div class="proposal" [class.d-none]="!proposalLoaded">
  <div class="container">
    <div class="row">
      <div class="col">
        <h2 *ngIf="project" style="font-weight: 600;">{{project.name}}</h2>
        <h3 *ngIf="proposal">{{proposal.name}}
          <span class="text-muted h5" *ngIf="proposal.status == 'MODIFIED' || proposal.rowNumber > 1">
            (Version {{ proposal.rowNumber }})</span>
        </h3>
        <h4 *ngIf="proposal && proposal.expirationDate" class="mt-1">Expires {{proposal.expirationDate | formatDateTime:
          true}}</h4>
        <button type="button" class="btn btn-primary mt-1 d-print-none" (click)="print();">
          <fa-icon class="button-icon-left" [icon]="faPrint"></fa-icon>
          <span>Print Proposal</span>
        </button>
      </div>
      <div class="col text-end d-none d-sm-block d-print-block">
        <img *ngIf="logoUrl" [src]="logoUrl" width="40%" class="logo" alt="Organization Logo">
      </div>
    </div>
    <!-- Client, Site, and Business Attributes -->
    <div class="row mt-2">
      <div class="col pe-3">
        <div class="row">
          <div class="col-md-8">
            <div *ngIf="client">
              <h5>{{client.name}} (Billing)</h5>
              <p class="mb-0">{{client.address.street}}</p>
              <p class="mb-0">{{client.address.city}}, {{client.address.state}} {{client.address.postalCode}}</p>
            </div>
            <div *ngIf="primaryContact" class="d-block text-break">
              <a href="mailto:{{primaryContact.email}}">{{primaryContact.email}}</a>
              <br>
              <a href="tel:{{primaryContact.phone}}">{{primaryContact.phone}}</a><span
                *ngIf="primaryContact.phoneExtension">x{{primaryContact.phoneExtension}}</span>
            </div>
          </div>
          <div class="col-md-4 mt-md-0 mt-2">
            <div *ngIf="site">
              <h5>{{site.name}} (Site)</h5>
              <p class="mb-0">{{site.address.street}}</p>
              <p class="mb-0">{{site.address.city}}, {{site.address.state}} {{site.address.postalCode}}</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="businessAttributes" class="col text-md-end">
        <div class="row">
          <div class="col">
            <div>
              <h5>{{businessAttributes.name}}</h5>
              <div *ngIf="businessAttributes.displayAddress">
                <p class="mb-0">{{businessAttributes.address.street}}</p>
                <p class="mb-0">{{businessAttributes.address.city}}, {{businessAttributes.address.state}}
                  {{businessAttributes.address.postalCode}}</p>
              </div>
              <div class="d-block text-break">
                <a class="pe-0 ps-0" href="mailto:{{businessAttributes.email}}">{{businessAttributes.email}}</a>
                <br>
                <a class="pe-0 ps-0" href="tel:{{businessAttributes.phone}}">{{businessAttributes.phone}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Features -->
    <div class="row mt-1">
      <h2 class="p-0" style="border-bottom: 2px solid black; font-weight: 600;">Features</h2>
      <div class="col mt-2">
        <ng-container *ngFor="let feature of features; index as i;">
          <div class="card" [class.mt-2]="i > 0" *ngIf="!feature.isHidden">
            <div class="card-body">
              <div class="d-flex">
                <div class="d-flex flex-column w-100">
                  <div class="d-flex">
                    <input id="ACCEPT-{{feature.id}}" type="checkbox" class="form-check-input"
                      (change)="updateFeatureSelected(feature, $event);" [checked]="feature.isSelected"
                      [disabled]="acceptedProposal || !feature.isOptional">
                    <h5 class="card-title mb-0 ms-2">{{feature.name}}</h5>
                    <p *ngIf="proposal.agreementType == 'SINGLE' && !feature.costHidden"
                      class="text-nowrap ms-auto my-auto">{{feature.totalPrice | currency}}</p>
                  </div>
                  <h6 *ngIf="feature.description" class="card-subtitle text-muted mt-1">{{feature.description}}</h6>
                  <div *ngIf="proposal.agreementType == 'MULTI' && !feature.costHidden" class="row mt-1">
                    <ng-container *ngFor="let key of multiOptionKeys; index as i;">
                      <div *ngIf="multiOptionIsEnabled(key);" class="col-auto p-0 d-md-flex flex-md-row"
                        [class.ms-2]="i > 0">
                        <strong>{{proposal.multiOptions[key].acronym}}</strong>
                        <div class="ms-md-1">{{feature.totalPricingMulti[key] | currency}}</div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <ul class="list-group list-group-flush">
                <li *ngFor="let featureItem of featureItems.get(feature.id)" class="list-group-item d-flex py-3">
                  <div *ngIf="featureItemHasImage(featureItem)" class="d-flex flex-column my-auto"
                    style="cursor: pointer;" (click)="openMediaLightbox(featureItem);">
                    <img [src]="featureItem.imageUrl" alt="Feature Item Image" width="60" height="60" class="rounded"
                      style="object-fit: cover;" loading="lazy">
                    <small class="text-muted text-center" style="font-size: 8pt;">Expand</small>
                  </div>
                  <div class="d-flex w-100 justify-content-between" [class.ms-3]="featureItemHasImage(featureItem)"
                    [style.margin-left.px]="featureHasAtLeastOneImage(feature.id) && !featureItemHasImage(featureItem) ? 76 : 0">
                    <div class="h-fit my-auto">
                      <h6 class="mb-0">{{featureItem.name}}</h6>
                      <p *ngIf="!featureItem.quantityHidden" class="mb-0 opacity-75">Quantity: {{featureItem.quantity}}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- Pricing -->
    <div class="row mt-2">
      <h2 class="p-0" style="border-bottom: 2px solid black; font-weight: 600;">Pricing</h2>
      <div class="col mt-2">
        <!-- Multi Pricing Description -->
        <div id="MULTI_PRICING_DESCRIPTION" hidden></div>
        <!-- Single Pricing -->
        <div *ngIf="proposal && proposal.agreementType == 'SINGLE' && proposalPricing" class="pricing">
          <div class="row">
            <div class="col-md-5 mt-2">
              <div class="card" [class.bg-secondary]="acceptedProposal && proposal.status == 'REJECTED'"
                [class.border-success]="acceptedProposal">
                <h5 class="card-header">Pricing</h5>
                <div class="card-body">
                  <!-- Subtotal -->
                  <div class="d-flex">
                    <div class="my-auto">
                      <p class="mb-1" style="font-weight: 600;">Subtotal</p>
                    </div>
                    <p class="ms-auto my-auto mb-0">{{proposalPricing.subtotal | currency}}</p>
                  </div>
                  <!-- Discount -->
                  <div *ngIf="proposal.discount > 0" class="d-flex">
                    <div class="my-auto">
                      <p class="mb-1" style="font-weight: 600;">Discount</p>
                    </div>
                    <div class="ms-auto my-auto">
                      <p *ngIf="proposal.discountType == 'PERCENT'" class="mb-0">{{proposal.discount}}%</p>
                      <p *ngIf="proposal.discountType == 'DOLLAR'" class="mb-0">{{proposal.discount | currency}}</p>
                    </div>
                  </div>
                  <!-- Tax -->
                  <div class="d-flex">
                    <div class="my-auto">
                      <p class="mb-1" style="font-weight: 600;">Tax</p>
                    </div>
                    <p class="ms-auto my-auto mb-0">{{proposalPricing.tax | currency}}</p>
                  </div>
                  <!-- Total -->
                  <div class="d-flex pt-1 pb-2" style="border-top: 2px solid #212529;">
                    <div class="my-auto">
                      <p class="mb-0" style="font-weight: 600;">Total</p>
                    </div>
                    <p class="ms-auto my-auto mb-0">{{ proposalPricing.total | currency}}</p>
                  </div>

                  <ng-container [ngTemplateOutlet]="due"
                    [ngTemplateOutletContext]="{proposal:proposal,proposalPricing: proposalPricing }">
                  </ng-container>
                  <!-- Tinsel Pay Processing Fee -->
                  <small *ngIf="showTinselPayCardPassFee" class="text-muted">A 3.95% card processing fee may
                    apply.</small>
                </div>
                <div class="card-footer d-print-none d-flex">Please review the Terms & Conditions and then click
                  "Accept" on the bottom of the proposal.</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Multi Pricing -->
        <div *ngIf="proposal && proposal.agreementType == 'MULTI' && proposalPricing" class="pricing">
          <div class="row">
            <ng-container *ngFor="let key of multiOptionKeys">
              <div *ngIf="multiOptionIsEnabled(key);"
                class="col-md-4 mt-2 d-flex justify-content-evenly align-items-md-stretch">
                <div class="card flex-grow-1" [class.bg-secondary]="selectedMultiOption && selectedMultiOption != key"
                  [class.border-success]="selectedMultiOption && selectedMultiOption == key">
                  <h5 class="card-header">{{proposal.multiOptions[key].title}}</h5>
                  <div class="card-body">
                    <!-- Subtotal -->
                    <div class="d-flex">
                      <div class="my-auto">
                        <p class="mb-1" style="font-weight: 600;">Subtotal</p>
                      </div>
                      <p class="ms-auto my-auto mb-0">{{proposalPricing.multiPricing[key]?.subtotal | currency}}</p>
                    </div>
                    <!-- Discount -->
                    <div *ngIf="proposal.discount > 0" class="d-flex">
                      <div class="my-auto">
                        <p class="mb-1" style="font-weight: 600;">Discount</p>
                      </div>
                      <div class="ms-auto my-auto">
                        <p *ngIf="proposal.discountType == 'PERCENT'" class="mb-0">{{proposal.discount}}%</p>
                        <p *ngIf="proposal.discountType == 'DOLLAR'" class="mb-0">{{proposal.discount | currency}}</p>
                      </div>
                    </div>
                    <!-- Tax -->
                    <div class="d-flex">
                      <div class="my-auto">
                        <p class="mb-1" style="font-weight: 600;">Tax</p>
                      </div>
                      <p class="ms-auto my-auto mb-0">{{proposalPricing.multiPricing[key]?.tax | currency}}</p>
                    </div>
                    <!-- Total -->
                    <div class="d-flex pt-1 pb-2" style="border-top: 2px solid #212529;">
                      <div class="my-auto">
                        <p class="mb-0" style="font-weight: 600;">Total</p>
                      </div>
                      <p class="ms-auto my-auto mb-0">{{proposalPricing.multiPricing[key]?.total | currency}}/year</p>
                    </div>
                    <ng-container [ngTemplateOutlet]="due"
                      [ngTemplateOutletContext]="{proposal:proposal,proposalPricing: proposalPricing.multiPricing[key] }">
                    </ng-container>

                    <!-- Tinsel Pay Processing Fee -->
                    <small *ngIf="showTinselPayCardPassFee" class="text-muted">A 3.95% card processing fee may
                      apply.</small>
                  </div>
                  <div *ngIf="!acceptedProposal" class="card-footer d-print-none d-flex" style="height: 65px;">
                    <button *ngIf="!selectedMultiOption || (selectedMultiOption && selectedMultiOption != key)"
                      type="button" class="btn btn-primary m-auto" (click)="selectMultiOption(key);">Select</button>
                    <div *ngIf="selectedMultiOption && selectedMultiOption == key">Please review the Terms & Conditions
                      and then click "Accept" on the bottom of the proposal.</div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!-- Files -->
    <div *ngIf="projectFiles.length > 0" class="row flex-column mt-2">
      <h2 class="p-0" style="border-bottom: 2px solid black; font-weight: 600;">Files</h2>
      <div class="col">
        <div *ngFor="let projectFile of projectFiles" class="mt-2">
          <img [src]="projectFile.presignedUrl" class="img-fluid" loading="lazy">
        </div>
      </div>
    </div>
    <!-- Terms & Conditions -->
    <div class="row mt-2 text-break">
      <h2 class="p-0" style="border-bottom: 2px solid black; font-weight: 600;">Terms & Conditions</h2>
      <div class="col mt-2">
        <div id="PROPOSAL_TERM"></div>
      </div>
    </div>
    <div class="row mt-2 justify-content-end" *ngIf="acceptedProposal && acceptedProposal.signature">
      <div class="col-md-2 text-center">
        <img [src]="getSignatureImageDataURI()" class="w-100" />
        <h5 class="p-0" style="border-bottom: 2px solid black; font-weight: 600;">
          {{ proposal.clientName }}
        </h5>
        <small>Signed & Accepted by</small>
        <h6>Date: {{ acceptedProposal.dateSigned | date : 'short' }}</h6>
      </div>
    </div>
    <!-- Footer Buttons -->
    <div class="row mt-2">
      <div class="col">
        <div *ngIf="proposal" class="d-print-none d-flex mt-2">
          <div *ngIf="proposal.status == 'PENDING' && !proposalIsExpired" class="mx-auto">
            <button type="button" class="btn btn-primary" (click)="selectProposal();"
              [disabled]="proposal.agreementType == 'MULTI' && !selectedMultiOption">Accept</button>
            <button type="button" class="btn btn-danger ms-2" (click)="rejectProposal();">Reject</button>
          </div>
          <div *ngIf="proposal.status == 'PENDING' && proposalIsExpired" class="d-flex flex-column mx-auto">
            <p class="text-danger mb-0 mx-auto fs-2" style="font-weight: 600;">Expired on {{proposal.expirationDate |
              formatDateTime: true}}</p>
            <small class="text-danger mx-auto">Please contact your sales representative if you would like to accept this
              proposal.</small>
          </div>
          <div *ngIf="proposal.status == 'ACCEPTED'" class="d-flex w-100 justify-content-center">
            <button type="button" class="btn btn-primary mx-2" (click)="viewFollowUpQuestions();">View</button>
            <button type="button" class="btn btn-secondary mx-2" (click)="print();">Print Proposal</button>
          </div>
          <p *ngIf="proposal.status == 'REJECTED'" class="text-danger mb-0 mx-auto fs-2" style="font-weight: 600;">
            Rejected</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Follow Up Questions Modal -->
<ng-template #FOLLOW_UP_QUESTIONS_MODAL let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Follow Up Questions</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
  </div>
  <div *ngIf="!acceptedProposal" class="modal-body" style="padding-bottom: 0;">
    <small style="color: var(--bs-red); font-weight: bold;">The proposal is not accepted until the terms are read, these
      questions are answered, and you click the "Accept" button.</small>
  </div>
  <div class="modal-body">
    <form [formGroup]="followUpQuestionsForm">
      <div class="mb-3"
        *ngIf="!acceptedProposal && proposal.status == 'PENDING' ? preferredInstallation:acceptedProposal && acceptedProposal.installationWeek != null">
        <label for="INSTALL_WEEK" class="control-label">Preferred Installation Week</label>
        <select id="INSTALL_WEEK" class="form-select" formControlName="installationWeek">
          <!-- <option value="NONE">No Preference</option>
          <option *ngFor="let installationWeek of preferredInstallationOptions" [value]="installationWeek.date">
            {{installationWeek.label}}</option> -->
          <ng-container *ngIf="acceptedProposal && acceptedProposal.installationWeek != null; else options">
            <option [value]="acceptedProposal.installationWeek">{{acceptedProposal.installationWeek}}</option>
          </ng-container>
          <ng-template #options>
            <option value="NONE">No Preference</option>
            <option *ngFor="let installationWeek of preferredInstallationOptions" [value]="installationWeek.date">
              {{installationWeek.label}}</option>
          </ng-template>
        </select>
        <small *ngIf="installationWeek.value != 'NONE'" class="text-muted">* We will do our best, but this is not
          guaranteed.</small>
      </div>
      <div class="mb-3"
        *ngIf="!acceptedProposal && proposal.status == 'PENDING' ? preferredRemoval:acceptedProposal && acceptedProposal.removalType != null">
        <label for="REMOVAL_TYPE" class="control-label">Preferred Removal Type</label>
        <select id="REMOVAL_TYPE" class="form-select" formControlName="removalType">
          <option value="NONE">No Preference</option>
          <option value="EARLY">Early Removal (January 1-7)</option>
          <option value="REGULAR">Regular Removal (January 7-15)</option>
          <option value="LATE">Late Removal (January 15-30)</option>
        </select>
        <small *ngIf="removalType.value != 'NONE'" class="text-muted">* We will do our best, but this is not
          guaranteed.</small>
      </div>
      <div [class.mb-3]="!acceptedProposal && acceptProposalTerms.length > 0">
        <label for="ADDITIONAL_INFORMATION">Additional Information</label>
        <textarea id="ADDITIONAL_INFORMATION" class="form-control" formControlName="additionalInfo"
          placeholder="Gate Entry Code is 12345" rows="3" maxlength="65535"></textarea>
        <div *ngIf="additionalInfo.invalid && (additionalInfo.dirty || additionalInfo.touched)">
          <div *ngIf="additionalInfo.errors.maxlength" class="input-validation-msg">Additional Info cannot exceed 65535
            characters.</div>
        </div>
      </div>
      <div *ngIf="!acceptedProposal && acceptProposalTerms.length > 0">
        <label>Terms</label>
        <div *ngFor="let acceptProposalTerm of acceptProposalTerms; index as i;" class="form-check mb-0"
          [class.mb-1]="i != acceptProposalTerms.length - 1">
          <input type="checkbox" id="ACCEPT_TERM_{{i}}" name="ACCEPT_TERM" class="form-check-input"
            (change)="termAcceptanceChanged();">
          <label class="form-check-label" for="ACCEPT_TERM_{{i}}"
            style="font-weight: normal;">{{acceptProposalTerm.content}}</label>
        </div>
      </div>
      <!-- Signature Canvas -->
      <div *ngIf="!acceptedProposal && signaturesAreEnabled" class="mt-3">
        <app-signature-canvas></app-signature-canvas>
      </div>
      <!-- Signature Image -->
      <div *ngIf="acceptedProposal && acceptedProposal.signature" class="d-flex flex-column mt-3">
        <label>Signature</label>
        <img [src]="getSignatureImageDataURI()" style="border: 1px solid #CED4DA; border-radius: 0.375rem;">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button *ngIf="!acceptedProposal && !loading" type="button" class="btn btn-primary d-print-none"
      (click)="acceptProposal();" [disabled]="canAcceptProposal()">
      <fa-icon class="button-icon-left" [icon]="faCheck"></fa-icon>
      <span>Accept</span>
    </button>
    <button *ngIf="loading" type="button" class="btn btn-primary d-print-none" [disabled]="true">
      <fa-icon class="button-icon-left" [icon]="faSpinner"></fa-icon>
      <span>Loading</span>
    </button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">{{(acceptedProposal) ? 'Close' :
      'Cancel'}}</button>
  </div>
</ng-template>

<!-- Amount DUE and DUE date -->
<ng-template #due let-proposal="proposal" let-proposalPricing="proposalPricing">
  <div class="d-flex pt-1 pb-2" *ngIf="proposal.isInvoiceRequired">
    <div class="my-auto">
      <p class="mb-0" style="font-weight: 600;">Deposit Amount</p>
    </div>
    <p class="ms-auto my-auto mb-0" *ngIf="proposal.amountType == 'DOLLAR'">
      {{ proposal.amount | currency }}</p>
    <p class="ms-auto my-auto mb-0" *ngIf="proposal.amountType == 'PERCENT'">
      {{ proposalPricing?.total * (proposal.amount / 100) | currency }}</p>
  </div>

  <div class="d-flex pt-1 pb-2" *ngIf="proposal.isInvoiceRequired">
    <div class="my-auto">
      <p class="mb-0" style="font-weight: 600;">Due date</p>
    </div>
    <p class="ms-auto my-auto mb-0" *ngIf="proposal.daysUntilDue != 0">{{ proposal.daysUntilDue }} days
    </p>
    <p class="ms-auto my-auto mb-0" *ngIf="proposal.daysUntilDue == 0">Immediately</p>
  </div>
</ng-template>
