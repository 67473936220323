import { Component, HostListener, OnInit } from '@angular/core';
import { faAngleLeft, faChevronLeft, faChevronRight, faSave } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { Observable, Subject, debounceTime } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Properties
  showMap: boolean = false;
  isMobileView: boolean = false;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faSave = faSave;
  hasChanges:boolean = false;
  showPendingChanges: boolean = false;

  private nextRoute: any ="";

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(
    public location: Location,
    private router: Router,) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
        }
      });
    }

  ngOnInit(): void {
    // this.router.events.subscribe((val) => {
    //   console.log(val)
    // });
    this.checkScreenSize();
  }

  canDeactivate(data?): Observable<boolean> | boolean {
    this.nextRoute = data ? data.routeConfig?.path: null;
    if(this.hasChanges){
      this.showPendingChanges = true;
      return false;
    }
    else{
      this.showPendingChanges = false;
      return true;
    }
  }

  checkForCrewManagementChanges(data){
    this.hasChanges = data;
  }

  // Toggle Map
  toggleMap(): void {
    this.showMap = !this.showMap;
  }

  resetPendingChanges(data?: any){
    this.showPendingChanges = false;
  }

  leaveWithoutSaving(data?: any){
    this.resetPendingChanges();
    this.hasChanges = false;
    this.canDeactivate();
    window.location.href = `${window.location.origin}/${sessionStorage.getItem("nextRoute")}`
  }

   //Set isMobile value and set table limit base on screen size
   checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }
}
