import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-snapshot',
  templateUrl: './snapshot.component.html',
  styleUrls: ['./snapshot.component.css']
})
export class SnapshotComponent implements OnInit {

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;

  constructor(public location: Location) { }

  ngOnInit(): void {

  }
}
