import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentStatus'
})
export class PaymentStatusPipe implements PipeTransform {

  transform(type: string): string {
    switch (type) {
      case 'PENDING':
        return 'Pending';
      case 'REJECTED':
        return 'Rejected';
      case 'CLOSED':
        return 'Closed';
      default:
        return 'Unknown';
    }
  }
}
