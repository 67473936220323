<div class="row">
    <div class="col-auto ms-auto">
        <app-date-picker-controller componentId="TinselPayOverview.Card" preset="CUR_WK" [showButtons]="true" (dateChanged)="dateChanged($event);" [onLoad]="true"></app-date-picker-controller>
    </div>
</div>
<div class="table-responsive mt-2">
    <table *ngIf="!isMobile">
        <thead>
            <tr>
                <th>Transaction ID</th>
                <th>Date</th>
                <th>Type</th>
                <th>Card</th>
                <th>Amount</th>
                <th>Condition</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let transaction of transactions" (click)="selectTransaction(transaction);">
                <td data-label="Transaction ID">{{transaction.id}}</td>
                <td data-label="Date">{{formatDateTime(transaction.actions[0].date)}}</td>
                <td data-label="Type">{{transaction.actions[0].type}}</td>
                <td data-label="Card">{{transaction.ccNumber}} ({{transaction.ccType}})</td>
                <td data-label="Amount">{{transaction.actions[0].amount}}</td>
                <td data-label="Condition">{{transaction.condition}}</td>
            </tr>
        </tbody>
    </table>

    <div class="scrollable" *ngIf="isMobile">
      <ul class="list-group">
          <li *ngFor="let transaction of transactions" class="list-group-item" (click)="selectTransaction(transaction);">
              <div class="d-flex list-group-container">
                  <div>
                      <strong>{{transaction.id}}</strong>
                      <div><strong>Amount: </strong>{{formatDateTime(transaction.actions[0].date)}}</div>
                      <div><strong>Type: </strong>{{transaction.actions[0].type}}</div>
                      <div><strong>Card: </strong>{{transaction.ccNumber}} ({{transaction.ccType}})</div>
                      <div><strong>Amount: </strong>{{transaction.actions[0].amount}}</div>
                      <small>Condition: {{transaction.condition}}</small>
                  </div>
              </div>
          </li>
          <li *ngIf="clients.length == 0" class="list-group-item">No Card Transactions</li>
      </ul>
    </div>
</div>

<!-- Transaction Details Modal -->
<ng-template #TRANSACTION_DETAILS_MODAL let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Transaction Details</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
    </div>
    <div class="modal-body">
        <!-- Transaction -->
        <ul class="list-group">
            <li class="list-group-item d-flex">
                <strong>Transaction ID</strong>
                <div class="ms-auto">{{selectedTransaction.id}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Customer ID</strong>
                <div *ngIf="selectedTransaction.customerId" class="ms-auto">{{selectedTransaction.customerId}}</div>
                <div *ngIf="!selectedTransaction.customerId" class="ms-auto">N/A</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Processor ID</strong>
                <div class="ms-auto">{{selectedTransaction.processorId}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Condition</strong>
                <div class="ms-auto">{{selectedTransaction.condition}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Auth Code</strong>
                <div class="ms-auto">{{selectedTransaction.authorizationCode}}</div>
            </li>
        </ul>
        <!-- CC Info -->
        <ul class="list-group mt-3">
            <li class="list-group-item d-flex">
                <strong>CC Number</strong>
                <div class="ms-auto">{{selectedTransaction.ccNumber}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>CC Exp</strong>
                <div class="ms-auto">{{selectedTransaction.ccExp}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>CC Bin</strong>
                <div class="ms-auto">{{selectedTransaction.ccBin}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>CC Type</strong>
                <div class="ms-auto">{{selectedTransaction.ccType}}</div>
            </li>
        </ul>
        <!-- Action -->
        <ul *ngFor="let action of selectedTransaction.actions" class="list-group mt-3">
            <li class="list-group-item d-flex">
                <strong>Type</strong>
                <div class="ms-auto">{{action.type}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Amount</strong>
                <div class="ms-auto">{{action.amount}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Date</strong>
                <div class="ms-auto">{{formatDateTime(action.date)}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Success</strong>
                <div class="ms-auto">{{action.success}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Source</strong>
                <div class="ms-auto">{{action.source}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Response Text</strong>
                <div class="ms-auto">{{action.responseText}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Response Code</strong>
                <div class="ms-auto">{{action.responseCode}}</div>
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
    </div>
</ng-template>

<!-- LOADER -->
<ngx-spinner>
  <p class="loading-spinner">Loading...</p>
</ngx-spinner>
