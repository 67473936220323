import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormBuilder } from '@angular/forms';
import { faBold, faTimes, faRotateRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Conversation } from '@twilio/conversations';
import { from, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { AlertService } from 'src/app/alert.service';
import { ContactsSerivce } from 'src/app/contacts.service';
import { ConversationService } from 'src/app/conversation.service';
import { SmsCallService } from 'src/app/sms-call.service';
import { TableControlService } from 'src/app/table-control.service';

@Component({
  selector: 'app-send-sms-dialog',
  templateUrl: './send-sms-dialog.component.html',
  styleUrls: ['./send-sms-dialog.component.css']
})
export class SendSmsDialogComponent implements OnInit {
  @Output() sent: EventEmitter<void> = new EventEmitter<any>();
  tableControls: TableControlService;

  currentConversation: Conversation;
  clientId: string = null;
  contact: any = null;
  phoneNumber: any;
  contacts: any[] = [];
  isLoading: boolean;
  twilioClient: any;
  contactId: any;
  clientName: string = '';
  defaultMessage: string = '';

  search: (text$: Observable<string>) => Observable<string[]>;

  // Properties
  sendSmsForm: UntypedFormGroup;
  toContactNumbers: any[] = [];

  // Font Awesome Properties
  faTimes = faTimes;
  faBold = faBold;
  faRotateRight = faRotateRight;
  faPlus = faPlus;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  @ViewChild('instance') instance: NgbTypeahead;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private ContactsService: ContactsSerivce,
    private conversationService: ConversationService,
    private smsCallService: SmsCallService,
    private alertService: AlertService,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {

    let contactsParam = new TableControlService('SMSContacts', true, 'c.contact_last_name', 'DESC');
    this.getContacts(contactsParam);
    console.log(this.contact)

    if (this.contact?.length > 0) {
      const primaryContact = this.contact?.find((con) => con.isPrimary);
      this.toContactNumbers.push(primaryContact)
    }

    if (this.contact?.id) {
      this.toContactNumbers.push(this.contact);
    }
    this.sendSmsForm = this.formBuilder.group({
      number: [''],
      body: [this.defaultMessage || null]
    });
  }

  sendSms(conversationId: string) {
    const defaultNumber = JSON.parse(localStorage.getItem('login'))?.defaultNumber;
    this.conversationService.sendMessage({
      conversationId: conversationId, message: this.sendSmsForm.controls['body'].value, defaultNumber
    }).pipe().subscribe(res => {
      this.isLoading = false;
      this.sent.emit()
      this.modal.close();
      this.markSentMessageToRead(conversationId)
    })

  }

  async markSentMessageToRead(conversationId: string) {
    const currentConversation = await this.twilioClient.getConversationBySid(conversationId)
    // currentConversation.sendMessage(this.sendSmsForm.controls['body'].value)
    console.log(currentConversation)
    currentConversation.updateLastReadMessageIndex(currentConversation.lastMessage.index)
  }

  async prepareSMS() {
    const defaultNumber = JSON.parse(localStorage.getItem('login'))?.defaultNumber;

    try {
      this.isLoading = true;

      if (this.sendSmsForm.valid) {
        this.toContactNumbers.forEach(async (contact) => {

          console.log(contact)
          if (!contact.conversationId && this.twilioClient) {

            let data: any = {
              contactId: contact.id,
              clientId: contact.clientId,
              name: `${contact.firstName} ${contact.lastName}`,
              phone: Number(contact.phone.replace(/\D/g, '')),
              message: this.sendSmsForm.controls['body'].value,
            }
            await this.twilioClient.createConversation({
              friendlyName: data.name ? data.name: `+1${data.phone}`,
              uniqueName: data.contactId ? data.contactId: `+1${data.phone}`,
              attributes: {
                contactNumber: data.phone,
                contactName: data.name
              }
            }).then(async (channel: Conversation) => {
              console.log(channel)
              this.conversationService.updateContactConversation(channel.sid, data.contactId).subscribe(async () => {

                const addRecipient = await channel.addNonChatParticipant(defaultNumber, `+1${data.phone}`);
                channel.join().then(async () => {
                  await channel.setAllMessagesUnread();
                  // await channel.updateUniqueName(data.contactId);

                  this.currentConversation = channel;
                  console.log(this.currentConversation)
                  this.sendSms(this.currentConversation.sid)
                })
              })

            });

            //END HERE

            // const conversation = this.twilioClient.createConversation({
            //   friendlyName: data.name,
            //   uniqueName: this.contactId
            // });

            // console.log('Created convo:', conversation);

            // if (conversation) {
            //   const newConvo = this.twilioClient.getConversationBySid(conversation.sid)
            //   newConvo.join().then(async () => {
            //     await newConvo.setAllMessagesUnread();

            //     this.currentConversation = newConvo;
            //     console.log(this.currentConversation)

            //     this.sendSms(conversation.sid)

            //   });
            // }

            // this.conversationService.newConversation(data.name, this.contactId, `+1${data.phone}`)
            //   .pipe().subscribe(res => {

            //     const newConvo = this.twilioClient.getConversationBySid(res.sid)
            //     // this.currentConversation = newConvo;
            //     // this.sendSms(res.sid)

            //     newConvo.join().then(async () => {
            //       await newConvo.setAllMessagesUnread();

            //       this.currentConversation = newConvo;
            //       console.log(this.currentConversation)

            //       this.sendSms(res.sid)

            //     });
            //   })


          } else if (contact.conversationId) {
            this.sendSms(contact.conversationId)
          } else {
            this.alertService.showWarningAlert('Missing Conversation ID')
          }
        });

      }
    } catch (err) {
      console.log(err)
      this.isLoading = false;
    }

  }

  async getContacts(table: any): Promise<any> {
      const params = table.getParams();

      return new Promise((resolve, reject) => {
        this.ContactsService.getContacts(params).subscribe(
          (res) => {
            let defaultList = [];
            if (this.contact?.length > 0) {
              this.contacts = this.contact;
              defaultList = this.contact;
              this.searchInputTypeAhead();
            } else {
              this.contacts = res.contacts;
              defaultList = res.contacts;
              this.searchInputTypeAhead();
            }

            resolve(defaultList);
          },
          (error) => {
            reject(error);
          }
        );
      });
    }

  private searchInputTypeAhead() {
    this.search = (text$: Observable<string>) => {

      let params = new TableControlService('SMSContacts', false, 'c.contact_last_name', 'DESC');
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
      const clicksWithClosedPopup$ = this.click$.pipe(filter(() => this.instance && !this.instance.isPopupOpen()));
      const inputFocus$ = this.focus$;

      return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
        switchMap((term: string) => {
          params['searchTerm'] = term;

          if (term === '') {
            return of(this.contacts); // convert array to observable using of()
          } else {
            // Convert array returned by promise getContacts to observable using from()
            return from(this.getContacts(params)).pipe(
              map((contacts) => {
                const formattedList = contacts.filter((v) => {
                  let phone = v['phone'];
                  if(this.isFormatted(v['phone'])) {
                    phone = v['phone']?.replace(/\D/g, ''); // remove phone format
                  }
                  return (
                    phone?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                    v['firstName']?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                    v['lastName'].toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                    v['clientName']?.toLowerCase().indexOf(term.toLowerCase()) > -1
                  );
                }).slice(0, 10);

                return formattedList;
              })
            );
          }
        })
      );
    };
  }

  isFormatted(phone) {
    const nonDigitPattern = /\D/;
    return nonDigitPattern.test(phone);  // Returns true if formatted
  }

  formatter = (result: any) => `${result.firstName} ${result.lastName} - ${result.phone} (${result?.clientName || this.clientName})`;

  selectContact(event: NgbTypeaheadSelectItemEvent): void {
    if (event) event.preventDefault();
    this.sendSmsForm.controls['number'].setValue(null);
    try {
      if (event.item?.phone == undefined || event.item?.phone == null || event.item?.phone.length == 0) throw new Error('Contact Number cannot be blank.');
      if (this.toContactNumbers.filter((contact: any) => contact?.phone === event.item?.phone).length > 0) throw new Error('Cannot add duplicate contact number.');
      this.toContactNumbers.push(event.item);
    } catch (error) {
      this.alertService.showSuccessAlert(error.message);
    }

  }

  removeToContactNumber(index: number): void {
    const tempArray = [];
    for (var i = 0; i < this.toContactNumbers.length; i++) {
      if (i != index) tempArray.push(this.toContactNumbers[i]);
    }
    this.toContactNumbers = tempArray;
  }

  addToContactNumber(value) {
    try {
      if (value == undefined || value == null || value.length == 0) throw new Error('Contact Number cannot be blank.');
      if (this.toContactNumbers.filter((contact: any) => contact?.phone === value).length > 0) throw new Error('Cannot add duplicate contact number.');
      this.toContactNumbers.push({
        phone: value,
        firstName: '',
        lastName: '',
        clientName: '',
      });
     this.sendSmsForm.controls.number.setValue('')
  } catch (error) {
    this.alertService.showSuccessAlert(error.message);
  }
  }

}
