<div class="card">
    <div class="card-header d-flex">
        <h5 class="card-title my-auto mb-auto">Agreement Types</h5>
        <button type="button" class="btn btn-primary ms-auto" (click)="updateMultiAgreementTypeOptions();">
            <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
            <span>Save</span>
        </button>
    </div>
    <div class="card-body scrollable" style="height: 400px;">
        <!-- Single -->
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Single</h5>
                <p class="card-text">This agreement type allows you to offer a single pricing option.</p>
            </div>
        </div>
        <!-- Multi -->
        <div class="card mt-2">
            <div class="card-body">
                <h5 class="card-title">Multi Year</h5>
                <p class="card-text">This agreement type allows you to offer up to five different pricing options for multi-year contracts.</p>
                <div *ngIf="multiAgreementTypeOptions">
                    <!-- A -->
                    <div class="input-group">
                        <div class="input-group-text">
                            <input id="MULTI_AGREEMENT_A_IS_ENABLED" type="checkbox" class="form-check-input mt-0" [checked]="multiAgreementTypeOptions.A.isEnabled" [disabled]="!multiAgreementTypeOptionIsValid('A')" (input)="formInputChanged('A', 'isEnabled', $event.target.checked);">
                        </div>
                        <span class="input-group-text" style="width: 42px; display: block;">A</span>
                        <input id="MULTI_AGREEMENT_A_TITLE" type="text" class="form-control" placeholder="1 Year Agreement" [value]="multiAgreementTypeOptions.A.title" (input)="formInputChanged('A', 'title', $event.target.value);">
                        <input id="MULTI_AGREEMENT_A_ACRONYM" type="text" class="form-control" placeholder="1YR" maxlength="5" [value]="multiAgreementTypeOptions.A.acronym" (input)="formInputChanged('A', 'acronym', $event.target.value);">
                    </div>
                    <!-- B -->
                    <div class="input-group mt-2">
                        <div class="input-group-text">
                            <input id="MULTI_AGREEMENT_B_IS_ENABLED" type="checkbox" class="form-check-input mt-0" [checked]="multiAgreementTypeOptions.B.isEnabled" [disabled]="!multiAgreementTypeOptionIsValid('B')" (input)="formInputChanged('B', 'isEnabled', $event.target.checked);">
                        </div>
                        <span class="input-group-text" style="width: 42px; display: block;">B</span>
                        <input id="MULTI_AGREEMENT_B_TITLE" type="text" class="form-control" placeholder="2 Year Agreement" [value]="multiAgreementTypeOptions.B.title" (input)="formInputChanged('B', 'title', $event.target.value);">
                        <input id="MULTI_AGREEMENT_B_ACRONYM" type="text" class="form-control" placeholder="2YR" maxlength="5" [value]="multiAgreementTypeOptions.B.acronym" (input)="formInputChanged('B', 'acronym', $event.target.value);">
                    </div>
                    <!-- C -->
                    <div class="input-group mt-2">
                        <div class="input-group-text">
                            <input id="MULTI_AGREEMENT_C_IS_ENABLED" type="checkbox" class="form-check-input mt-0" [checked]="multiAgreementTypeOptions.C.isEnabled" [disabled]="!multiAgreementTypeOptionIsValid('C')" (input)="formInputChanged('C', 'isEnabled', $event.target.checked);">
                        </div>
                        <span class="input-group-text" style="width: 42px; display: block;">C</span>
                        <input id="MULTI_AGREEMENT_C_TITLE" type="text" class="form-control" placeholder="3 Year Agreement" [value]="multiAgreementTypeOptions.C.title" (input)="formInputChanged('C', 'title', $event.target.value);">
                        <input id="MULTI_AGREEMENT_C_ACRONYM" type="text" class="form-control" placeholder="3YR" maxlength="5" [value]="multiAgreementTypeOptions.C.acronym" (input)="formInputChanged('C', 'acronym', $event.target.value);">
                    </div>
                    <!-- D -->
                    <div class="input-group mt-2">
                        <div class="input-group-text">
                            <input id="MULTI_AGREEMENT_D_IS_ENABLED" type="checkbox" class="form-check-input mt-0" [checked]="multiAgreementTypeOptions.D.isEnabled" [disabled]="!multiAgreementTypeOptionIsValid('D')" (input)="formInputChanged('D', 'isEnabled', $event.target.checked);">
                        </div>
                        <span class="input-group-text" style="width: 42px; display: block;">D</span>
                        <input id="MULTI_AGREEMENT_D_TITLE" type="text" class="form-control" placeholder="4 Year Agreement" [value]="multiAgreementTypeOptions.D.title" (input)="formInputChanged('D', 'title', $event.target.value);">
                        <input id="MULTI_AGREEMENT_D_ACRONYM" type="text" class="form-control" placeholder="4YR" maxlength="5" [value]="multiAgreementTypeOptions.D.acronym" (input)="formInputChanged('D', 'acronym', $event.target.value);">
                    </div>
                    <!-- E -->
                    <div class="input-group mt-2">
                        <div class="input-group-text">
                            <input id="MULTI_AGREEMENT_E_IS_ENABLED" type="checkbox" class="form-check-input mt-0" [checked]="multiAgreementTypeOptions.E.isEnabled" [disabled]="!multiAgreementTypeOptionIsValid('E')" (input)="formInputChanged('E', 'isEnabled', $event.target.checked);">
                        </div>
                        <span class="input-group-text" style="width: 42px; display: block;">E</span>
                        <input id="MULTI_AGREEMENT_E_TITLE" type="text" class="form-control" placeholder="5 Year Agreement" [value]="multiAgreementTypeOptions.E.title" (input)="formInputChanged('E', 'title', $event.target.value);">
                        <input id="MULTI_AGREEMENT_E_ACRONYM" type="text" class="form-control" placeholder="5YR" maxlength="5" [value]="multiAgreementTypeOptions.E.acronym" (input)="formInputChanged('E', 'acronym', $event.target.value);">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>