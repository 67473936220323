<div class="modal-header">
    <h5 class="modal-title">Refund</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="refundForm">
        <div class="mb-3">
            <label for="REFUND_AMOUNT">Amount</label>
            <div class="input-group">
                <div class="input-group-text">$</div>
                <input id="REFUND_AMOUNT" type="number" class="form-control" placeholder="5.00" step="1.00" formControlName="amount" min="0" [max]="remainingAmount" required [class.is-invalid]="amount.invalid && (amount.dirty || amount.touched)">
                <div class="input-group-text">of</div>
                <input id="PAYMENT_AMOUNT" type="number" class="form-control" [value]="remainingAmount" disabled readonly>
            </div>
            <div *ngIf="amount.invalid && (amount.dirty || amount.touched)">
                <div *ngIf="amount.errors.required" class="input-validation-msg">Amount is required.</div>
                <div *ngIf="amount.errors.min" class="input-validation-msg">Amount cannot be less than 0.</div>
                <div *ngIf="amount.errors.max" class="input-validation-msg">Amount cannot be greater than {{remainingAmount}}.</div>
            </div>
        </div>
        <div *ngIf="payment.surcharge > 0" class="mb-3">
            <label for="REFUND_SURCHARGE">Surcharge</label>
            <div class="input-group">
                <div class="input-group-text">$</div>
                <input id="REFUND_SURCHARGE" type="number" class="form-control" formControlName="surcharge">
                <div class="input-group-text">of</div>
                <input id="PAYMENT_SURCHARGE" type="number" class="form-control" [value]="remainingSurcharge" disabled readonly>
            </div>
        </div>
        <div *ngIf="payment.surcharge > 0" class="mb-3">
            <label for="REFUND_TOTAL">Total</label>
            <div class="input-group">
                <div class="input-group-text">$</div>
                <input id="REFUND_TOTAL" type="number" class="form-control" formControlName="total">
                <div class="input-group-text">of</div>
                <input id="PAYMENT_TOTAL" type="number" class="form-control" [value]="remainingTotal" disabled readonly>
            </div>
        </div>
        <div>
            <label for="REFUND_NOTE">Note</label>
            <textarea id="REFUND_NOTE" class="form-control" formControlName="note" placeholder="Check # 123456789" rows="3" maxlength="65535"></textarea>
            <div *ngIf="note.invalid && (note.dirty || note.touched)">
                <div *ngIf="note.errors.maxlength" class="input-validation-msg">Note cannot exceed 65535 characters.</div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="processRefund();">Submit</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
</div>