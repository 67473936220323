<div class="row warning-banner my-2">
    <p>
        NOTE: Submitting the address of a local branch or any address different from the official registered company address might produce a mismatch with a negative impact on the Trust Score.
    </p>
</div>
<div class="row">
    <div class="col-sm-12">
        <form [formGroup]="businessAddressForm">
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Name</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. John Carter" formControlName="customer_name" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Street</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. North Kierland Blvd" formControlName="street" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Street (secondary)</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. 854 Avocado Ave" formControlName="street_secondary" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">City</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. Philadelphia" formControlName="city" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">State</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. CA for California" formControlName="region" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Postal Code</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. 90210" formControlName="postal_code" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Country (ISO format)</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. US" formControlName="iso_country" class="form-control" type="text">
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-sm-12 d-flex">
        <button (click)="submitForm()" type="button" class="btn btn-primary ms-auto">
            Submit
        </button>
    </div>
</div>