<div class="modal-header">
    <h5 class="modal-title">Archiving Crew</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body d-flex flex-column justify-content-center align-items-center">
    <p class="mb-0 text-center" *ngIf="!isReplacing; else chooseReplace">
        This crew is associated with projects that have already been scheduled.
        <br>
        How would you like to proceed?
    </p>
    <ng-template #chooseReplace>
        <p class="mb-0 text-center">
            Select New Crew to replace {{ crewNameToReplace }}
        </p>
    </ng-template>

    <ul class="my-2 w-100">
        <form [formGroup]="crewsFormGroup">
            <li *ngFor="let schedule of schedules; let i = index" class="my-1">
                {{ schedule.projectName }} - {{ schedule.clientName }} : {{ schedule.date | date : 'MM-dd-yyyy' }} ({{
                schedule.type }})
                <div *ngIf="schedule.crews && schedule.crews.length > 0" class="d-flex flex-wrap">
                    <small [ngStyle]="setBgColor(crew.crewColor)" *ngFor="let crew of schedule.crews">
                        {{crew.crewName}}
                    </small>
                </div>
                <div class="d-flex">
                    <div class="input-group mb-3">
                        <select id="CREW_REPLACEMENT" class="form-select mb-1" *ngIf="isReplacing"
                            [formControlName]="schedule.id" [required]="true"
                            [class.is-invalid]="crewsFormGroup.get(schedule.id)?.invalid">
                            <option [ngValue]="null" class="font-italic">Select New Crew ...</option>
                            <option [ngValue]="'skip'" class="font-italic">
                                Continue without Replacing Crew
                            </option>
                            <ng-container *ngFor="let crew of removeOptionOfExitingCrew(crewOptions, schedule.crews)">
                                <option [ngValue]="crew.crewId">
                                    {{ crew.crewName }}
                                </option>
                            </ng-container>
                        </select>
                        <div class="input-group-append" *ngIf="isReplacing && crewsFormGroup.get(schedule.id)?.value == 'skip'">
                            <span class="input-group-text text-danger" style="cursor: pointer;" [ngbPopover]="infoSelection" triggers="hover" placement="right">
                                <fa-icon [icon]="faExclamationCircle"></fa-icon>
                            </span>
                        </div>
                    </div>
                    <button class="btn btn-link" (click)="applyToAll(i)" *ngIf="crewsFormGroup.get(schedule.id).value">
                        Apply this change to all projects in this list
                    </button>
                </div>
                <small class="text-danger font-italic"
                    *ngIf="isReplacing && crewsFormGroup.get(schedule.id)?.getError('notInOption')">
                    Crew is already assigned to this project
                </small>
            </li>
        </form>
    </ul>

</div>
<div class="modal-footer justify-content-center">
    <ng-container *ngIf="!isReplacing; else saveReplacement">
        <button ngbAutofocus type="button" class="btn btn-primary" (click)="onSelection('removeAll')">
            Archive Crew & Unschedule Projects
        </button>
        <button ngbAutofocus type="button" class="btn btn-primary" (click)="onSelection('removeCrewOnly')">
            Keep Projects Scheduled & Remove This Crew from These Projects
        </button>
        <button type="button" class="btn btn-primary" (click)="onSelection('replace')">
            Replace Crew on These Projects with Another Crew
        </button>
    </ng-container>
    <ng-template #saveReplacement>
        <button ngbAutofocus type="button" class="btn btn-primary" (click)="saveReplacementBtn()"
            [disabled]="!this.crewsFormGroup.valid">
            Save Replacement
        </button>
        <button ngbAutofocus type="button" class="btn btn-secondary" (click)="cancelReplacement()">
            Cancel Replacement
        </button>
    </ng-template>
</div>

<ng-template #infoSelection>
    <p>{{ "By not replacing " + crewNameToReplace + ", this project may not have an assigned crew. This project will remain on the schedule." }}</p>
</ng-template>