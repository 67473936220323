import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from '../alert.service';
import { SettingService } from '../setting.service';
import { OrganizationSubscription } from '../organization-subscription';
import { CardOnFile } from '../card-on-file';


@Component({
  selector: 'app-settings-subscriptions',
  templateUrl: './settings-subscriptions.component.html',
  styleUrls: ['./settings-subscriptions.component.css']
})
export class SettingsSubscriptionsComponent implements OnInit {

  // Properties
  billingInformationForm: UntypedFormGroup;
  subscriptions: OrganizationSubscription[] = [];
  cardOnFile: CardOnFile;

  // Font Awesome Properties
  faSave = faSave;

  constructor(private settingService: SettingService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.billingInformationForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      street: new UntypedFormControl(),
      city: new UntypedFormControl(),
      state: new UntypedFormControl('SAS'),
      postalCode: new UntypedFormControl()
    });
    this.getBillingInformation();
    this.getOrganizationSubscriptions()
    this.getCardOnFile()
  }

  // Get Billing Information
  private getBillingInformation(): void {
    this.settingService.getBillingInformation().subscribe((billingSettings) => {
      this.billingInformationForm.controls.name.setValue(billingSettings.name);
      const address = billingSettings.address;
      this.billingInformationForm.controls.street.setValue(address.street);
      this.billingInformationForm.controls.city.setValue(address.city);
      this.billingInformationForm.controls.state.setValue(address.state);
      this.billingInformationForm.controls.postalCode.setValue(address.postalCode);
    });
  }

  private getOrganizationSubscriptions(): void {
    this.settingService.getOrganizationSubscriptions().subscribe((subscriptions) => {
      this.subscriptions = subscriptions;
    });
  }

  // Get Card On File
  private getCardOnFile(): void {
    this.settingService.getCardOnFile().subscribe((cardOnFile) => {
      this.cardOnFile = cardOnFile;
    });
  }

  // Update Billing Information
  updateBillingInformation(): void {
    const data = {
      name: this.billingInformationForm.value.name,
      address: {
        street: this.billingInformationForm.value.street,
        city: this.billingInformationForm.value.city,
        state: this.billingInformationForm.value.state,
        postalCode: this.billingInformationForm.value.postalCode
      }
    };
    this.settingService.updateBillingInformation(data).subscribe(() => {
      this.alertService.showSuccessAlert('Billing Information Updated');
      this.getBillingInformation();
    });
  }

  // Billing Information Form Accessors
  get name() { return this.billingInformationForm.controls.name; }
}
