import { Component, OnInit, Input } from '@angular/core';
import { faArrowRight, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { debounceTime, Subject } from 'rxjs';
import { Project } from '../project';
import { ProjectBase } from '../project-base';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-project-bases',
  templateUrl: './project-bases.component.html',
  styleUrls: ['./project-bases.component.css']
})
export class ProjectBasesComponent implements OnInit {

  // Properties
  private _project: Project;
  @Input()
  set project(value: Project) {
    this._project = value;
    this.bases = value.bases;
  }
  get project(): Project {
    return this._project;
  }

  bases: ProjectBase[] = [];
  editProjectBaseSubject: Subject<ProjectBase> = new Subject();

  // Font Awesome Properties
  faArrowRight = faArrowRight;
  faCheck = faCheck;
  faTimes = faTimes;

  constructor(private projectService: ProjectService) { }

  ngOnInit(): void {
    this.prepareProjectBaseEditSubject();
  }

  // Prepare Project Base Edit Subject
  private prepareProjectBaseEditSubject(): void {
    this.editProjectBaseSubject.pipe(debounceTime(250)).subscribe((base) => {
      this.updateProjectBase(base);
    });
  }

  // Add Project Base
  addProjectBase(): void {
    const newProjectBaseContent = (document.getElementById('NEW_PROJECT_BASE') as HTMLInputElement).value;
    if (newProjectBaseContent) {
      this.projectService.addProjectBase(this.project.id, newProjectBaseContent).subscribe(() => {
        this.projectService.projectUpdated.emit();
        (document.getElementById('NEW_PROJECT_BASE') as HTMLInputElement).value = null;
      });
    }
  }

  // Project Base Editied
  projectBaseEdited(base: ProjectBase, event: InputEvent): void {
    base.content = (event.target as HTMLInputElement).value;
    this.editProjectBaseSubject.next(base);
  }

  // Toggle Base Completion
  toggleBaseCompletion(base: ProjectBase, isComplete: boolean): void {
    base.isComplete = isComplete;
    this.updateProjectBase(base);
  }

  // Update Project Base
  updateProjectBase(base: ProjectBase): void {
    this.projectService.updateProjectBase(this.project.id, base).subscribe(() => {
      this.projectService.projectUpdated.emit();
    });
  }

  // Delete Project Base
  deleteProjectBase(base: ProjectBase): void {
    this.projectService.deleteProjectBase(this.project.id, base.id).subscribe(() => {
      this.projectService.projectUpdated.emit();
    });
  }

  // Update Project Stage
  updateProjectStage(): void {
    this.projectService.updateProjectStage(this.project.id, 'REMOVAL').subscribe(() => {
      this.projectService.projectUpdated.emit();
    });
  }
}
