<div class="modal-header">
    <h5 class="modal-title">Tinsel Pay Sign Up</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <label style="font-weight: bold; text-decoration: underline;">What's Tinsel Pay?</label>
    <p class="mb-2">Tinsel Pay is a whitelabel payment processing service that offers competitive rates and a first-class integration with Tinsel CRM.</p>
    <label style="font-weight: bold; text-decoration: underline;">Sign Up</label>
    <p class="mb-2">Please answer the following questions. You will be directed to a different application depending on your answers, so if your answers change, please fill out this form again.</p>
    <form [formGroup]="tinselPaySignUpForm">
        <div class="mb-2">
            <label>Do you want to process credit/debit cards?</label>
            <div>
                <div class="form-check form-check-inline">
                    <input id="PROCESS_CARD_YES" class="form-check-input" type="radio" formControlName="processCard" [value]="true">
                    <label for="PROCESS_CARD_YES" class="form-check-label" style="font-weight: normal;">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                    <input id="PROCESS_CARD_NO" class="form-check-input" type="radio" formControlName="processCard" [value]="false">
                    <label for="PROCESS_CARD_NO" class="form-check-label" style="font-weight: normal;">No</label>
                </div>
            </div>
        </div>
        <div *ngIf="processCard.value" class="mb-2">
            <label>Do you want to pass the fee on to your clients?</label>
            <div>
                <div class="form-check form-check-inline">
                    <input id="PASS_FEE_YES" class="form-check-input" type="radio" formControlName="passFee" [value]="true">
                    <label for="PASS_FEE_YES" class="form-check-label" style="font-weight: normal;">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                    <input id="PASS_FEE_NO" class="form-check-input" type="radio" formControlName="passFee" [value]="false">
                    <label for="PASS_FEE_NO" class="form-check-label" style="font-weight: normal;">No</label>
                </div>
            </div>
            <small *ngIf="!passFee.value" class="text-muted">A fee of 2.95% + 25&#162; will be paid by <strong>you</strong>, the merchant.</small>
            <small *ngIf="passFee.value" class="text-muted">A fee of 3.95% will be paid by the customer.</small>
        </div>
        <div>
            <label>Do you want to process ACH?</label>
            <div>
                <div class="form-check form-check-inline">
                    <input id="PROCESS_ACH_YES" class="form-check-input" type="radio" formControlName="processAch" [value]="true">
                    <label for="PROCESS_ACH_YES" class="form-check-label" style="font-weight: normal;">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                    <input id="PROCESS_ACH_NO" class="form-check-input" type="radio" formControlName="processAch" [value]="false">
                    <label for="PROCESS_ACH_NO" class="form-check-label" style="font-weight: normal;">No</label>
                </div>
            </div>
            <small *ngIf="processAch.value" class="text-muted">A fee of 0.75% will be paid by <strong>you</strong>, the merchant.</small>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="goToApplication();" [disabled]="!processCard.value && !processAch.value">Go</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>