import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-general',
  templateUrl: './settings-general.component.html',
  styleUrls: ['./settings-general.component.css']
})
export class SettingsGeneralComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
}
