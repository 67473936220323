import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'proposalInteraction'
})
export class ProposalInteractionPipe implements PipeTransform {

  transform(type: string): string {
    switch (type) {
      case 'VIEWED':
        return 'Viewed';
      case 'ACCEPTED':
        return 'Accepted';
      case 'REJECTED':
        return 'Rejected';
      default:
        return 'Unknown';
    }
  }
}
