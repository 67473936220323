import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuditLogEvent } from './audit-log-event';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  constructor(private http: HttpClient) { }

  // Get Audit Log Events
  getAuditLogEvents(params: any): Observable<{ auditLogEvents: AuditLogEvent[], count: number }> {
    return this.http.get<{ auditLogEvents: AuditLogEvent[], count: number }>(`/audit-log`, {
      params: params
    });
  }
}
