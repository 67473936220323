<div class="modal-header">
    <div>
        <h5 class="modal-title">Cancelation of Order</h5>
        <h5 class="text-muted">{{ itemName }}</h5>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="cancelForm" *ngIf="renderForm()">
        <div class="row mt-2">
            <div class="col table-responsive">
                <table *ngIf="!isMobile">
                    <thead>
                        <tr>
                            <th>
                                Cancel <br>
                                (<input class="form-check-input" type="checkbox" name="radioNoLabel"
                                formControlName="allItem"> All)
                            </th>
                            <th>
                                Client
                            </th>
                            <th>
                                Project
                            </th>
                            <th>
                                Quantity Needed
                            </th>
                            <th>
                                Quantity Ordered
                            </th>
                        </tr>
                    </thead>
                    <tbody formArrayName="choices">
                        <tr [formGroupName]="i"
                            *ngFor="let projectPrepItem of cancelForm.controls.choices.controls; let i = index">
                            <td data-label="Cancel">
                                <input class="form-check-input" type="checkbox" name="radioNoLabel"
                                    [formControlName]="i + '-checkbox'"
                                    (change)="chosenQuantity($event, projectPrepItem.value.quantity, i)">
                            </td>
                            <td data-label="Client">{{projectPrepItem.value.clientName}}</td>
                            <td data-label="Project">{{projectPrepItem.value.projectName}}</td>
                            <td data-label="Quantity Needed">{{projectPrepItem.value.quantity}}</td>
                            <td data-label="Quantity Ordered">{{projectPrepItem.value.quantityOrdered}}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="scrollable" *ngIf="isMobile">
                  Cancel&nbsp;(<input class="form-check-input" type="checkbox" name="radioNoLabel"
                  formControlName="allItem"> All)
                  <ul class="list-group" formArrayName="choices">
                      <li [formGroupName]="i" *ngFor="let projectPrepItem of cancelForm.controls.choices.controls; let i = index">
                          <div class="d-flex list-group-container">
                              <div>
                                <div class="item-header mb-2">
                                  <div>
                                    <strong>{{projectPrepItem.value.clientName}}</strong>
                                  </div>
                                  <div>
                                    Cancel:&nbsp;
                                    <input class="form-check-input" type="checkbox" name="radioNoLabel"
                                    [formControlName]="i + '-checkbox'"
                                    (change)="chosenQuantity($event, projectPrepItem.value.quantity, i)">
                                  </div>
                                </div>
                                  <div><strong>Project: </strong>{{projectPrepItem.value.projectName}}</div>
                                  <div><strong>Quantity Needed: </strong>{{projectPrepItem.value.quantity}}</div>
                                  <div><strong>Quantity Ordered: </strong>{{projectPrepItem.value.quantityOrdered}}</div>
                              </div>
                          </div>
                      </li>
                      <li *ngIf="cancelForm.controls.choices.controls.length == 0" class="list-group-item">No Orders</li>
                  </ul>
                </div>
            </div>

        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="chosenItems.length == 0">Confirm Cancelation</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
</div>
