<div class="card">
    <div class="card-header">
        <h5 *ngIf="notes" class="me-auto">Notes ({{notes.length}})</h5>
        <div class="btn-group" role="group">
            <button class="btn btn-primary" (click)="selectedNote = null; resetNoteForm(); openModal(NOTE_MODAL);">
                <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
                <span>New</span>
            </button>
        </div>
    </div>
    <div class="card-body" [style.height]="cardBodyHeight">
        <div class="scrollable">
            <ul class="list-group">
                <li *ngFor="let note of notes" class="list-group-item" style="cursor: pointer;">
                    <div class="d-flex">
                        <div>
                            <strong>{{note.title}}</strong>
                            <p class="mb-0" style="word-break: break-word;">{{note.content}}</p>
                            <br>
                            <small class="text-muted">Created by: {{note.fullName}} {{note.createdAt | formatDateTime}}</small>
                            <br>
                            <small *ngIf="note.noteHistory.length > 0" class="text-muted"><a (click)="openNoteHistory(note.noteHistory)" href="javascript:void(0);">View History</a></small>
                        </div>
                        <div class="ms-auto d-flex">
                            <button style="margin-left: 5px; margin-right: 5px;" class="btn btn-primary my-auto" type="button" (click)="noteSelected(note);" ngbTooltip="Edit">
                                <fa-icon [icon]="faEdit"></fa-icon>
                            </button>
                            <button style="margin-left: 5px; margin-right: 5px;" class="btn btn-danger my-auto" type="button" (click)="$event.stopPropagation(); selectedNote = note; deleteNote();" ngbTooltip="Delete">
                                <fa-icon [icon]="faTrashCan"></fa-icon>
                            </button>
                        </div>
                    </div>
                </li>
                <li *ngIf="notes.length == 0" class="list-group-item">No Notes</li>
            </ul>
        </div>
    </div>
</div>

<!-- Note Modal -->
<ng-template #NOTE_MODAL let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{(selectedNote) ? 'Edit' : 'New'}} Note</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="noteForm">
            <div class="mb-3">
                <label for="NOTE_TITLE">Title</label>
                <input id="NOTE_TITLE" type="text" class="form-control" placeholder="Gate Code" formControlName="title" maxlength="150" required>
            </div>
            <div>
                <label for="NOTE_CONTENT">Notes</label>
                <textarea id="NOTE_CONTENT" class="form-control" formControlName="content" placeholder="12345" rows="5" maxlength="65535" required></textarea>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="saveNote();">Save</button>
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
    </div>
</ng-template>

<ng-template #NOTE_HISTORY let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Note History</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
    </div>
    <div class="modal-body">
        <div class="scrollable">
            <ul class="list-group">
                <li *ngFor="let note of noteHistory" class="list-group-item" style="cursor: pointer;">
                    <div class="d-flex">
                        <div>
                            <strong>{{note.title}}</strong>
                            <p class="mb-0" style="word-break: break-word;">{{note.content}}</p>
                            <br>
                            <small class="text-muted">Updated by: {{note.fullName}} {{note.createdAt | formatDateTime}}</small>
                        </div>
                    </div>
                </li>
                <li *ngIf="notes.length == 0" class="list-group-item">No Notes</li>
            </ul>
        </div>
    </div>
    <!-- <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="saveNote();">Save</button>
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
    </div> -->
</ng-template>