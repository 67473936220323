<div class="row flex-column mt-2">
    <div class="col">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">General</h3>
                <h6 class="card-subtitle text-muted">Manage your email settings.</h6>
            </div>
        </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col-lg-4">
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <h5>Sending</h5>
                        <button type="button" class="btn btn-primary ms-auto" (click)="updateEmailSendingSettings();">
                            <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                            <span>Save</span>
                        </button>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="emailSettingsForm">
                            <div class="mb-3">
                                <label for="EMAIL_DISPLAY_NAME">Display Name</label>
                                <input id="EMAIL_DISPLAY_NAME" type="text" class="form-control"
                                    formControlName="displayName" placeholder="Appleseed Contractors">
                            </div>
                            <div>
                                <label for="EMAIL_REPLY_TO">Reply To</label>
                                <input id="EMAIL_REPLY_TO" type="text" class="form-control" formControlName="replyTo"
                                    placeholder="shared@example.com" autocapitalize="off">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <h5>Receiving</h5>
                    </div>
                    <div class="card-body">
                        <label for="PROSPECTS_EMAIL">Prospects Email</label>
                        <div class="input-group">
                            <input id="PROSPECTS_EMAIL" type="text" class="form-control" [value]="prospectEmail"
                                disabled>
                            <button type="button" class="btn btn-outline-primary" (click)="copyProspectsEmail();"
                                ngbTooltip="Copy" aria-label="Copy">
                                <fa-icon [icon]="faCopy"></fa-icon>
                            </button>
                            <button type="button" class="btn btn-outline-primary"
                                (click)="openRegenerateProspectsEmailConfirmationModal();" ngbTooltip="Regenerate"
                                aria-label="Regenerate">
                                <fa-icon [icon]="faRotate"></fa-icon>
                            </button>
                            <a href="https://support.tinselcrm.com/knowledge-base/prospect-email-receiving/"
                                target="_blank" class="btn btn-outline-primary" ngbTooltip="Help" aria-label="Help">
                                <fa-icon [icon]="faQuestion"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 mt-lg-0 mt-2">
        <div class="card">
            <div class="card-header">
                <h5 class="my-auto">Templates</h5>
                <button type="button" class="btn btn-primary ms-auto" (click)="updateEmailTemplate();"
                    aria-label="Save Email Template">
                    <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                    <span>Save</span>
                </button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <label for="EMAIL_TEMPLATE">Template</label>
                        <select id="EMAIL_TEMPLATE" class="form-select"
                            (change)="emailTemplateSelected($event.target.value);">
                            <option value="PROPOSAL">Proposal</option>
                            <option value="INVOICE">Invoice</option>
                            <option value="PAYMENT">Payment</option>
                            <option value="REVIEW">Review</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-3 mb-2">
                    <div class="col d-flex">
                        <label for="EMAIL_BODY" class="my-auto">Body</label>
                    </div>
                </div>
                <form [formGroup]="editorForm">
                    <div class="row">
                        <quill-editor #editor [sanitize]="true" formControlName="emailBody"
                            [styles]="{height: '200px', padding: 0}"></quill-editor>
                    </div>

                </form>
            </div>
        </div>
    </div>
    <div class="col-lg-4 mt-lg-0 mt-2">
        <app-settings-emails-logs></app-settings-emails-logs>
    </div>
</div>