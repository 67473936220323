import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../alert.service';
import { Note } from '../note';
import { NoteService } from '../note.service';
import { faTrashCan, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {

  // Inputs and Outputs
  @Input() cardBodyHeight: string = "100%";
  @Input() private referenceId: string = null;
  @Input() private referenceIds: string[] = [];
  @Input() private type: string;

  // Modals
  private modalReference: NgbModalRef;
  @ViewChild('NOTE_MODAL', { static: false }) private noteModal: NgbModalRef;
  @ViewChild('NOTE_HISTORY', { static: false }) private noteHistoryModal: NgbModalRef;
  // Forms
  noteForm: UntypedFormGroup;

  // Notes
  notes: Note[] = [];
  noteHistory: any[] = [];

  // Selected Note
  selectedNote: Note = null;

  // Font Awesome Properties
  faTrashCan = faTrashCan;
  faPlus = faPlus;
  faEdit = faEdit;

  constructor(private noteService: NoteService,
    private modalService: NgbModal,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.noteForm = new UntypedFormGroup({
      title: new UntypedFormControl(),
      content: new UntypedFormControl()
    });
    this.getNotes();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Modal
  openModal(content: any): void {
    this.modalReference = this.modalService.open(content);
  }

  // Get Notes
  private getNotes(): void {
    const params = {
      searchTerm: null,
      sortBy: 'n.note_updated_at',
      sortDirection: 'DESC',
      limit: null,
      offset: null,
      'filter:reference_id': JSON.stringify(this.referenceIds)
    };
    this.noteService.getNotes(params).subscribe((notes) => {
      this.notes = notes;
    });
  }

  // Save Note
  saveNote(): void {
    if (this.selectedNote === null) {
      this.addNote();
    } else {
      this.updateNote();
    }
  }

  // Add Note
  private addNote(): void {
    const note = {
      referenceId: this.referenceId,
      type: this.type,
      title: this.noteForm.value.title,
      content: this.noteForm.value.content
    };

    this.noteService.addNote(note).subscribe(() => {
      this.alertService.showSuccessAlert('Note Added');
      this.modalReference.close();
      this.resetNoteForm();
      this.getNotes();
    });
  }

  // Update Note
  private updateNote(): void {
    const note = {
      referenceId: this.referenceId,
      type: this.type,
      noteParentId: this.selectedNote.noteParentId ? this.selectedNote.noteParentId: this.selectedNote.id,
      title: this.noteForm.value.title,
      content: this.noteForm.value.content
    };

    this.noteService.addNote(note).subscribe(() => {
      this.alertService.showSuccessAlert('Note Updated');
      this.modalReference.close();
      this.resetNoteForm();
      this.getNotes();
      this.selectedNote = null;
    });
  }

  // Delete Note
  deleteNote(): void {
    console.log(this.selectedNote);
    this.noteService.deleteNote(this.selectedNote.noteParentId || this.selectedNote.id).subscribe(() => {
      this.alertService.showSuccessAlert('Note Deleted');
      this.getNotes();
      this.selectedNote = null;
    });
  }

  // Note Selected
  noteSelected(note: Note): void {
    this.selectedNote = note;
    this.noteForm.controls.title.setValue(note.title);
    this.noteForm.controls.content.setValue(note.content);
    this.openModal(this.noteModal);
  }

  openNoteHistory(noteHistory: any[]) {
    this.noteHistory = noteHistory;
    this.openModal(this.noteHistoryModal);
  }

  // Reset Note Form
  resetNoteForm(): void {
    this.noteForm.reset();
    this.noteForm.controls.title.setValue(null);
    this.noteForm.controls.content.setValue(null);
  }

  viewNoteHistory(){

  }
}
