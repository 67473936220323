<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">Project Glance</h2>
        </div>
    </nav>
    <div class="row">
        <!-- Proposal -->
        <div class="col-lg-4">
            <app-snapshot-project-list cardTitle="Proposal" stage="PROPOSAL"></app-snapshot-project-list>
        </div>
        <!-- Invoice -->
        <div class="col-lg-4">
            <app-snapshot-project-list cardTitle="Invoice" stage="INVOICE"></app-snapshot-project-list>
        </div>
        <!-- Inventory Prep -->
        <div class="col-lg-4">
            <app-snapshot-project-list cardTitle="Inventory Prep" stage="PREPARATION"></app-snapshot-project-list>
        </div>
    </div>
    <div class="row mb-3">
        <!-- Installation -->
        <div class="col-lg-3">
            <app-snapshot-project-list cardTitle="Installation" stage="INSTALLATION"></app-snapshot-project-list>
        </div>
        <!-- Bases -->
        <div class="col-lg-3">
            <app-snapshot-project-list cardTitle="Bases" stage="BASES"></app-snapshot-project-list>
        </div>
        <!-- Removal -->
        <div class="col-lg-3">
            <app-snapshot-project-list cardTitle="Removal" stage="REMOVAL"></app-snapshot-project-list>
        </div>
        <!-- Reviews -->
        <div class="col-lg-3">
            <app-snapshot-project-list cardTitle="Reviews" stage="REVIEWS"></app-snapshot-project-list>
        </div>
    </div>
</div>