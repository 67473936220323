import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { faCalendar, faCheck, faExternalLinkAlt, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbNav, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { Observable, Subject, map, debounceTime, distinctUntilChanged, merge, switchMap } from 'rxjs';
import { AlertService } from '../alert.service';
import { Client } from '../client';
import { ClientService } from '../client.service';
import { Project } from '../project';
import { ProjectService } from '../project.service';
import { Crew, ScheduleItem } from '../schedule-item';
import { ScheduleService } from '../schedule.service';
import { User } from '../user';
import { UserService } from '../user.service';

@Component({
  selector: 'app-schedule-item-modal',
  templateUrl: './schedule-item-modal.component.html',
  styleUrls: ['./schedule-item-modal.component.css']
})
export class ScheduleItemModalComponent implements OnInit, AfterViewInit {
  @ViewChild('nav') private nav: NgbNav;

  // Properties
  @Input() scheduleItem: ScheduleItem;
  scheduleItemForm: UntypedFormGroup;
  users: User[] = [];
  selectedUserIds: string[] = [];
  projects: Project[] = [];
  selectedCrews: Crew[] = [];
  selectedCrewIds: string[] = [];

  isClientUnassigned: boolean = true;
  isProjectUnassigned: boolean = true;
  isAllDay: boolean = true;

  // Clients
  clientTypeaheadInput: (text$: Observable<string>) => Observable<Client[]>;
  clientTypeaheadInputFocus$ = new Subject<string>();
  clientResultFormatter = (client: Client) => client.name;
  selectedClient!: Client;

  // Crews
  crews: Crew[];

  // Font Awesome Properties
  faCalendar = faCalendar;
  faCheck = faCheck;
  faExternalLinkAlt = faExternalLinkAlt;
  faQuestionCircle = faQuestionCircle;

  constructor(private userService: UserService,
    private clientService: ClientService,
    private projectService: ProjectService,
    private scheduleService: ScheduleService,
    private alertService: AlertService,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.scheduleItemForm = new UntypedFormGroup({
      client: new UntypedFormControl(),
      project: new UntypedFormControl(),
      name: new UntypedFormControl(),
      generateInvoice: new UntypedFormControl(),
      date: new UntypedFormControl(),
      duration: new UntypedFormControl(),
      notes: new UntypedFormControl()
    });
    this.getUsers();
    this.getListOfCrews();
    this.prepareClientTypeahead();
    if (this.scheduleItem && this.scheduleItem.status == 'SCHEDULED') this.prepareScheduleItemForm();
    if (this.scheduleItem && this.scheduleItem.status == 'UNSCHEDULED') {
      this.scheduleItemForm.controls.generateInvoice.setValue(this.scheduleItem.acceptedProposalExists);
      if (!this.scheduleItem.acceptedProposalExists) this.scheduleItemForm.controls.generateInvoice.disable();
    }
  }

  // Prepare Schedule Item Form
  private prepareScheduleItemForm(): void {
    if (this.scheduleItem.type == 'CUSTOM') {
      if (this.scheduleItem.clientId) {
        this.isClientUnassigned = false;
        this.getProjectsForClient(this.scheduleItem.clientId);
        this.getClient(this.scheduleItem.clientId);
        this.scheduleItemForm.controls.client.setValue(this.scheduleItem.clientName);
      }
      this.scheduleItemForm.controls.name.setValue(this.scheduleItem.name);
    } else {
      this.isClientUnassigned = false;
      this.getProjectsForClient(this.scheduleItem.clientId);
      this.getClient(this.scheduleItem.clientId);
      this.scheduleItemForm.controls.project.disable();
      this.scheduleItemForm.controls.generateInvoice.setValue(this.scheduleItem.generateFinalInvoice);
      if (!this.scheduleItem.acceptedProposalExists) this.scheduleItemForm.controls.generateInvoice.disable();
    }
    this.isAllDay = this.scheduleItem.isAllDay;
    if (this.isAllDay) {
      this.scheduleItemForm.controls.date.setValue(dayjs(this.scheduleItem.date).format('YYYY-MM-DD'));
      this.scheduleItemForm.controls.duration.setValue(1);
    } else {
      this.scheduleItemForm.controls.date.setValue(this.scheduleItem.date);
      const duration = dayjs(this.scheduleItem.endDate).diff(dayjs(this.scheduleItem.date), 'hours', true).toFixed(2);
      this.scheduleItemForm.controls.duration.setValue(duration);
    }
    this.selectedCrewIds = this.scheduleItem.crews && this.scheduleItem.crews.length != 0 ? this.scheduleItem.crews.map(crew => crew.crewId) : []
    this.selectedUserIds = this.scheduleItem.users;
    this.scheduleItemForm.controls.notes.setValue(this.scheduleItem.notes);
  }

  /* ----- Clients ----- */

  // Get Client
  private getClient(clientId: string): void {
    this.clientService.getClient(clientId).subscribe((client) => {
      this.selectedClient = client;
    });
  }

  // Get Clients
  private getClients(term: string): Observable<Client[]> {
    const params = {
      searchTerm: term,
      sortBy: 'client_name',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0
    };
    return this.clientService.getClients(params).pipe(map((res) => { return res.clients; }));
  }

  // Prepare Client Typeahead
  private prepareClientTypeahead(): void {
    this.clientTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.clientTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getClients((term.length == 0) ? null : term);
      }));
    }
  }

  // Select Client
  selectClient(event: NgbTypeaheadSelectItemEvent): void {
    event.preventDefault();
    this.selectedClient = event.item;
    this.scheduleItemForm.controls.client.setValue(this.selectedClient.name);
    this.getProjectsForClient(this.selectedClient.id);
  }

  // Toggle Client Unassigned
  toggleClientUnassigned(isChecked: boolean): void {
    this.isClientUnassigned = isChecked;
    if (isChecked) this.isProjectUnassigned = true;
  }

  /* ----- Projects ----- */

  // Get Projects For Client
  getProjectsForClient(clientId: string): void {
    if (this.isClientUnassigned) {
      this.isProjectUnassigned = true;
    } else {
      const params = {
        searchTerm: null,
        sortBy: 'p.project_name',
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        'filter:c.client_id': JSON.stringify([clientId])
      };
      this.projectService.getProjects(params).subscribe((res) => {
        this.projects = res.projects;
        if (this.scheduleItem && this.scheduleItem.projectId) {
          this.isProjectUnassigned = false;
          this.scheduleItemForm.controls.project.setValue(this.scheduleItem.projectId);
        } else {
          if (this.projects.length > 0) this.scheduleItemForm.controls.project.setValue(this.projects[0].id);
        }
      });
    }
  }

  // Toggle Project Unassigned
  toggleProjectUnassigned(isChecked: boolean): void {
    this.isProjectUnassigned = isChecked;
  }

  /* ----- Users ----- */

  // Get Users
  private getUsers(): void {
    this.userService.getUsers().subscribe((users) => {
      if (this.scheduleItem && this.scheduleItem.status == 'SCHEDULED') {
        this.users = users;
      } else {
        this.users = users.filter(user => !user.isArchived);
      }
    });
  }

  // Select User
  selectUser(userId: string, isArchived: boolean): void {
    if(!isArchived) {
      if (this.selectedUserIds.includes(userId)) {
        this.selectedUserIds = this.selectedUserIds.filter((id) => { return id != userId });
      } else {
        this.selectedUserIds.push(userId);
      }
    }
  }

  /* ----- Crews ----- */

  ngAfterViewInit(): void {
    this.nav.activeIdChange.subscribe((activeId) => {
      sessionStorage.setItem('crewNavActiveId', activeId);
    });
  }

  // Get Current Tab
  getCurrentTab(): string {
    const activeId = sessionStorage.getItem('crewNavActiveId');
    return (activeId === undefined) ? 'CREW' : activeId;
  }

  getListOfCrews() {
    this.scheduleService.getCrews().subscribe(res => {
      this.crews = res;
    })
  }

  selectCrews(crew: Crew): void {
    if (this.selectedCrewIds.includes(crew.crewId)) {
      this.selectedCrewIds = this.selectedCrewIds.filter((id) => { return id != crew.crewId });
    } else {
      this.selectedCrews.push(crew);
      this.selectedCrewIds.push(crew.crewId)
    }
  }

  /* ----- Schedule ----- */

  // Add Item To Schedule
  addItemToSchedule(): void {
    if (this.scheduleItemForm.valid) {
      const scheduleItem = {
        id: this.scheduleItem.id,
        clientId: this.scheduleItem.clientId,
        projectId: this.scheduleItem.projectId,
        generateInvoice: this.scheduleItemForm.getRawValue().generateInvoice,
        date: this.scheduleItemForm.value.date,
        duration: this.scheduleItemForm.value.duration,
        isAllDay: this.isAllDay,
        userIds: this.selectedUserIds,
        notes: this.scheduleItemForm.value.notes,
        crews: this.selectedCrews
      };
      this.scheduleService.addItemToSchedule(scheduleItem).subscribe(() => {
        this.alertService.showSuccessAlert('Scheduled');
        this.scheduleService.scheduleUpdated.next();
        this.modal.close();
      });
    } else {
      this.scheduleItemForm.markAllAsTouched();
    }
  }

  // Remove Item From Schedule
  removeItemFromSchedule(): void {
    this.scheduleService.removeItemFromSchedule(this.scheduleItem.id).subscribe(() => {
      this.alertService.showSuccessAlert('Unscheduled');
      this.scheduleService.scheduleUpdated.next();
      this.modal.close();
    });
  }

  // Add Custom Schedule Item
  addCustomScheduleItem(): void {
    try {
      if (!this.isClientUnassigned && !this.selectedClient) throw new Error('No Client Assigned');
      if (this.scheduleItemForm.valid) {
        const scheduleItem = {
          clientId: (this.isClientUnassigned) ? null : this.selectedClient.id,
          projectId: (this.isProjectUnassigned) ? null : this.scheduleItemForm.value.project,
          name: this.scheduleItemForm.value.name,
          date: this.scheduleItemForm.value.date,
          duration: this.scheduleItemForm.value.duration,
          isAllDay: this.isAllDay,
          userIds: this.selectedUserIds,
          notes: this.scheduleItemForm.value.notes,
          crewIds: this.selectedCrewIds,
          crews: this.selectedCrews
        };
        this.scheduleService.addCustomScheduleItem(scheduleItem).subscribe(() => {
          this.alertService.showSuccessAlert('Custom Schedule Item Added');
          this.scheduleService.scheduleUpdated.next();
          this.modal.close();
        })
      } else {
        this.scheduleItemForm.markAllAsTouched();
      }
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  // Update Schedule Item
  updateScheduleItem(): void {
    if (this.scheduleItemForm.valid) {
      const scheduleItem = {
        id: this.scheduleItem.id,
        clientId: (this.isClientUnassigned) ? null : this.selectedClient.id,
        projectId: (this.isProjectUnassigned) ? null : this.scheduleItemForm.getRawValue().project,
        generateInvoice: this.scheduleItemForm.getRawValue().generateInvoice,
        name: this.scheduleItemForm.value.name,
        date: this.scheduleItemForm.value.date,
        duration: this.scheduleItemForm.value.duration,
        isAllDay: this.isAllDay,
        userIds: this.selectedUserIds,
        notes: this.scheduleItemForm.value.notes,
        crewIds: this.selectedCrewIds,
        crews: this.selectedCrews
      };
      this.scheduleService.updateScheduleItem(scheduleItem).subscribe(() => {
        this.alertService.showSuccessAlert('Schedule Item Updated');
        this.scheduleService.scheduleUpdated.next();
        this.modal.close();
      });
    } else {
      this.scheduleItemForm.markAllAsTouched();
    }
  }

  // Delete Schedule Item
  deleteScheduleItem(): void {
    this.scheduleService.deleteScheduleItem(this.scheduleItem.id).subscribe(() => {
      this.alertService.showSuccessAlert('Schedule Item Deleted');
      this.scheduleService.scheduleUpdated.next();
      this.modal.close();
    });
  }

  // Toggle All Day
  toggleAllDay(isChecked: boolean): void {
    const currentDate = this.scheduleItemForm.value.date;
    this.isAllDay = isChecked;
    if (currentDate === null) return;
    setTimeout(() => {
      if (this.isAllDay) this.scheduleItemForm.controls.date.setValue(dayjs(currentDate).format('YYYY-MM-DD'));
      else this.scheduleItemForm.controls.date.setValue(dayjs(currentDate).format('YYYY-MM-DDTHH:mm'));
    }, 125);
  }

  // Get End Date Time
  getEndDateTime(): string {
    return dayjs(this.scheduleItemForm.value.date).add(this.scheduleItemForm.value.duration, 'hours').format('MM/DD/YYYY, h:mm A');
  }

  /* ----- Schedule Item Form Accessors ----- */

  get name() { return this.scheduleItemForm.controls.name; }
  get date() { return this.scheduleItemForm.controls.date; }
  get duration() { return this.scheduleItemForm.controls.duration; }
  get notes() { return this.scheduleItemForm.controls.notes; }
}
