import { Component, Input, OnInit } from '@angular/core';
import { faLandmark, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../alert.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { PaymentService } from '../payment.service';
import { TinselPayVault } from '../tinsel-pay-vault';

@Component({
  selector: 'app-client-detail-vaults',
  templateUrl: './client-detail-vaults.component.html',
  styleUrls: ['./client-detail-vaults.component.css']
})
export class ClientDetailVaultsComponent implements OnInit {

  // Properties
  @Input() clientId: string;
  @Input() type: string;
  @Input() title: string;
  vaults: TinselPayVault[] = [];

  // Font Awesome Properties
  faLandmark = faLandmark;
  faTrashCan = faTrashCan;

  constructor(private paymentService: PaymentService,
    private alertService: AlertService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getTinselPayVaults();
  }

  // Open Delete Card Vault Confirmation Modal
  openDeleteCardVaultConfirmationModal(vault: TinselPayVault): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this Tinsel Pay vault?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteTinselPayCardVault(vault);
    });
  }

  // Open Delete ACH Vault Confirmation Modal
  openDeleteAchVaultConfirmationModal(vault: TinselPayVault): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this Tinsel Pay vault?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteTinselPayAchVault(vault);
    });
  }

  /* ----- Tinsel Pay Vaults ----- */

  // Get Tinsel Pay Vaults
  private getTinselPayVaults(): void {
    this.paymentService.getTinselPayVaults(this.clientId, this.type).subscribe((vaults) => {
      this.vaults = vaults;
    });
  }

  /* ----- Tinsel Pay Card ----- */

  // Delete Tinsel Pay Card Vault
  deleteTinselPayCardVault(vault: TinselPayVault): void {
    this.paymentService.deleteTinselPayCardVault(vault.organizationId, vault.id).subscribe(() => {
      this.alertService.showSuccessAlert('Card Vault Deleted');
      this.getTinselPayVaults();
    });
  }

  // Get Card Payment Icon
  getCardPaymentIcon(brand: string): string {
    return this.paymentService.getCardPaymentIcon(brand);
  }

  /* -----Tinsel Pay ACH ----- */

  // Delete Tinsel Pay ACH Vault
  deleteTinselPayAchVault(vault: TinselPayVault): void {
    this.paymentService.deleteTinselPayAchVault(vault.organizationId, vault.id).subscribe(() => {
      this.alertService.showSuccessAlert('ACH Vault Deleted');
      this.getTinselPayVaults();
    });
  }
}
