import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { ProjectFile } from './project-file';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectFileService {

  constructor(private http: HttpClient) { }

  // Add Project File
  addProjectFile(projectId: string, projectFile: any): Observable<string> {
    return this.http.post<string>(`/projectFiles/${projectId}`, projectFile);
  }

  // Update Project File
  updateProjectFile(projectFile: any): Observable<any> {
    return this.http.put<any>(`/projectFiles/${projectFile.id}`, projectFile);
  }

  // Delete Project File
  deleteProjectFile(projectFileId: string): Observable<any> {
    return this.http.delete<any>(`/projectFiles/${projectFileId}`);
  }

  // Get Project File
  getProjectFiles(params: any): Observable<ProjectFile[]> {
    return this.http.get<ProjectFile[]>(`/projectFiles`, {
      params: params
    });
  }

  // Get Project File
  getProjectFile(projectFileId: string): Observable<ProjectFile> {
    return this.http.get<ProjectFile>(`/projectFiles/${projectFileId}`);
  }

  // Get Project File Direct URL
  getProjectFileDirectURL(projectFileId: string): string {
    return environment.apiUrl + '/projectFiles/direct/' + projectFileId;
  }

  // Get Project File Presigned Download URL
  getProjectFilePresignedDownloadUrl(projectFileId: string): Observable<string> {
    return this.http.get<string>(`/projectFiles/${projectFileId}/download`);
  }
}
