<div class="modal-header">
    <h5 class="modal-title">Send Email</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="sendEmailForm">
        <div class="mb-3">
            <label for="SEND_EMAIL_TO">To</label>
            <input *ngIf="toEmailAddressTypeaheadInput" id="SEND_EMAIL_TO" type="text" class="form-control"
                placeholder="john.appleseed@example.com" autocapitalize="off" maxlength="320"
                [ngbTypeahead]="toEmailAddressTypeaheadInput"
                (focus)="toEmailAddressTypeaheadInputFocus$.next($any($event).target.value)"
                (change)="addToEmailAddress()" (selectItem)="addToEmailAddress($event)">
            <div *ngFor="let toEmailAddress of toEmailAddresses; index as i;" class="selectedEmail">
                <span>{{toEmailAddress}}</span>
                <button type="button" class="btn ms-1" (click)="removeToEmailAddress(i);">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </button>
            </div>
        </div>
        <div class="mb-3">
            <label for="SEND_EMAIL_CC">Cc</label>
            <input *ngIf="ccEmailAddressTypeaheadInput" id="SEND_EMAIL_CC" type="text" class="form-control"
                placeholder="jane.appleseed@example.com" autocapitalize="off" maxlength="320"
                [ngbTypeahead]="ccEmailAddressTypeaheadInput"
                (focus)="ccEmailAddressTypeaheadInputFocus$.next($any($event).target.value)"
                (change)="addCcEmailAddress()" (selectItem)="addToEmailAddress($event)">
            <div *ngFor="let ccEmailAddress of ccEmailAddresses; index as i;" class="selectedEmail">
                <span>{{ccEmailAddress}}</span>
                <button type="button" class="btn ms-1" (click)="removeCcEmailAddress(i);">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </button>
            </div>
        </div>
        <div class="mb-3">
            <label for="SEND_EMAIL_SUBJECT">Subject</label>
            <input id="SEND_EMAIL_SUBJECT" class="form-control" placeholder="Subject" formControlName="subject"
                maxlength="250" required
                [class.is-invalid]="sendEmailSubject.invalid && (sendEmailSubject.dirty || sendEmailSubject.touched)">
            <div *ngIf="sendEmailSubject.invalid && (sendEmailSubject.dirty || sendEmailSubject.touched)">
                <div *ngIf="sendEmailSubject.errors.required" class="input-validation-msg">Subject is required.</div>
                <div *ngIf="sendEmailSubject.errors.maxlength" class="input-validation-msg">Subject cannot exceed 250
                    characters.</div>
            </div>
        </div>
        <div class="mb-3">
            <div class="row mb-2">
                <div class="col d-flex">
                    <label for="SEND_EMAIL_BODY" class="my-auto">Body</label>
                </div>
            </div>
            <!-- <div class="editor">
                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                </ngx-editor-menu>
                <ngx-editor formControlName="body" [editor]="editor">
                </ngx-editor>
            </div> -->

            <div class="row">
                <quill-editor #editor [sanitize]="true" formControlName="body" [styles]="{height: '200px', padding: 0}"></quill-editor>
            </div>

            <!-- <div id="SEND_EMAIL_BODY" class="form-control scrollable" contenteditable="true"></div> -->
        </div>
        <div>
            <label style="font-weight: bold;">History ({{emailLogs.length}})</label>
            <ul class="list-group scrollable" style="max-height: 165px;">
                <li *ngFor="let log of emailLogs; index as i;" class="list-group-item" style="cursor: pointer;"
                    (click)="openEmailViewModal(log);">
                    <div class="d-flex">
                        <div>{{log.subject}}</div>
                        <small class="text-muted my-auto ms-auto">{{log.createdAt | formatDateTime}}</small>
                    </div>
                </li>
                <li *ngIf="emailLogs.length == 0" class="list-group-item">No History</li>
            </ul>
        </div>
    </form>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="sendEmail();">Send</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>