<div class="row flex-column mt-2">
    <div class="col">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">General</h3>
                <h6 class="card-subtitle text-muted">Manage your proposal settings.</h6>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <!-- Taxes -->
    <div class="col-lg-4 mt-2">
        <div class="card">
            <div class="card-header d-flex">
                <h5 class="card-title my-auto mb-auto">Taxes</h5>
                <button type="button" class="btn btn-primary ms-auto"
                    (click)="selectedTax = null; resetTaxForm(); openModal(TAX_MODAL);">
                    <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
                    <span>New</span>
                </button>
            </div>
            <div class="card-body">
                <ul class="list-group scrollable" style="height: 368px;">
                    <li *ngFor="let tax of taxes" class="list-group-item" style="cursor: pointer;"
                        (click)="taxSelected(tax);">
                        <div class="d-flex">
                            <div class="h-fit my-auto">
                                <div>{{tax.name}} ({{tax.multiplier | percent: '1.0-3'}})</div>
                            </div>
                        </div>
                    </li>
                    <li *ngIf="taxes.length == 0" class="list-group-item">No Taxes</li>
                </ul>
            </div>
        </div>
    </div>
    <!-- Agreement Types -->
    <div class="col-lg-4 mt-2">
        <app-settings-proposals-agreement-types></app-settings-proposals-agreement-types>
    </div>
    <div class="col-lg-4 mt-2">
        <div class="card">
            <div class="card-header d-flex">
                <h5 class="card-title my-auto mb-auto">Email Notifications</h5>
                <button type="button" class="btn btn-primary ms-auto"
                    ngbPopover="Email notifications will be sent to the sales person assigned the the project. You can also send notifications to the Super Admin. ">
                    <fa-icon [icon]="faQuestion"></fa-icon>
                </button>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="form-check form-switch">
                        <input id="PROPOSAL_ON_VIEW" type="checkbox" class="form-check-input" role="switch"
                            [checked]="onProposalView" (change)="updateNotifOnProposalView($event);">
                        <label for="PROPOSAL_ON_VIEW" class="form-check-label" style="font-weight: bold;">
                            When proposal is Viewed</label>
                    </div>
                </div>
                <div class="row">
                    <div class="form-check form-switch">
                        <input id="PROPOSAL_ON_ACCEPTANCE" type="checkbox" class="form-check-input" role="switch"
                            [checked]="onProposalAcceptance" (change)="updateNotifOnProposalAcceptance($event);">
                        <label for="PROPOSAL_ON_ACCEPTANCE" class="form-check-label" style="font-weight: bold;">
                            When proposal is Accepted
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="form-check form-switch">
                        <input id="PROPOSAL_ON_REJECTION" type="checkbox" class="form-check-input" role="switch"
                            [checked]="onProposalRejection" (change)="updateNotifOnProposalRejection($event);">
                        <label for="PROPOSAL_ON_REJECTION" class="form-check-label" style="font-weight: bold;">
                            When proposal is Rejected
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="form-check form-switch">
                        <input id="SEND_A_COPY_ARE_ENABLED" type="checkbox" class="form-check-input" role="switch"
                            [checked]="sendACopyToSuperAdmin" (change)="updateSendACopyToSuperAdmin($event);">
                        <label for="SEND_A_COPY_ARE_ENABLED" class="form-check-label" style="font-weight: bold;">
                            Send notification to Super Admin
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row flex-column mt-2">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h3 class="card-title">View</h3>
                    <h6 class="card-subtitle text-muted">These settings affect the information displayed on the Proposal
                        View page.</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <!-- Business Information -->
        <div class="col-lg-4">
            <div class="card">
                <div class="card-header d-flex">
                    <h5 class="card-title my-auto mb-auto">Business Information</h5>
                    <button type="button" class="btn btn-primary ms-auto" (click)="updateBusinessInformation();">
                        <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                        <span>Save</span>
                    </button>
                </div>
                <div class="card-body" style="min-height: 250px;">
                    <form [formGroup]="businessInformationForm">
                        <div class="mb-3">
                            <label for="PROPOSAL_VIEW_BUSINESS_NAME">Name</label>
                            <input id="PROPOSAL_VIEW_BUSINESS_NAME" type="text" class="form-control"
                                placeholder="Appleseed Contractors" formControlName="name" maxlength="150" required
                                [class.is-invalid]="businessName.invalid && (businessName.dirty || businessName.touched)">
                            <div *ngIf="businessName.invalid && (businessName.dirty || businessName.touched)">
                                <div *ngIf="businessName.errors.required" class="input-validation-msg">Name is required.
                                </div>
                                <div *ngIf="businessName.errors.maxlength" class="input-validation-msg">Name cannot
                                    exceed
                                    150 characters.</div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="PROPOSAL_VIEW_BUSINESS_EMAIL">Email</label>
                            <input id="PROPOSAL_VIEW_BUSINESS_EMAIL" type="text" class="form-control"
                                placeholder="jappleseed@example.com" formControlName="email" autocapitalize="off"
                                maxlength="320"
                                pattern="^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$"
                                required
                                [class.is-invalid]="businessEmail.invalid && (businessEmail.dirty || businessEmail.touched)">
                            <div *ngIf="businessEmail.invalid && (businessEmail.dirty || businessEmail.touched)">
                                <div *ngIf="businessEmail.errors.required" class="input-validation-msg">Email is
                                    required.
                                </div>
                                <div *ngIf="businessEmail.errors.maxlength" class="input-validation-msg">Email cannot
                                    exceed
                                    320.</div>
                                <div *ngIf="businessEmail.errors.pattern" class="input-validation-msg">Email is
                                    formatted
                                    incorrectly.</div>
                            </div>
                        </div>
                        <div>
                            <label for="PROPOSAL_VIEW_BUSINESS_PHONE">Phone</label>
                            <input id="PROPOSAL_VIEW_BUSINESS_PHONE" type="tel" class="form-control" appPhoneMask
                                placeholder="(000) 000-0000" formControlName="phone" maxlength="14"
                                pattern="^\((\d{3})\)\s(\d{3})-(\d{4})"
                                [class.is-invalid]="businessPhone.invalid && (businessPhone.dirty || businessPhone.touched)">
                            <div *ngIf="businessPhone.invalid && (businessPhone.dirty || businessPhone.touched)">
                                <div *ngIf="businessPhone.errors.required" class="input-validation-msg">Phone is
                                    required.
                                </div>
                                <div *ngIf="businessPhone.errors.maxlength" class="input-validation-msg">Phone cannot
                                    exceed
                                    14 characters.</div>
                                <div *ngIf="businessPhone.errors.pattern" class="input-validation-msg">Phone is
                                    formatted
                                    incorrectly.</div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Business Address -->
        <div class="col-lg-4 mt-lg-0 mt-2">
            <div class="card">
                <div class="card-header d-flex">
                    <h5 class="card-title my-auto mb-auto">Business Address</h5>
                    <button type="button" class="btn ms-auto" [class.btn-primary]="displayAddress"
                        [class.btn-danger]="!displayAddress" (click)="toggleDisplayBusinessAddress();">
                        <fa-icon [icon]="(displayAddress) ? faToggleOn : faToggleOff"></fa-icon>
                    </button>
                    <button type="button" class="btn btn-primary ms-2" (click)="updateBusinessAddress();">
                        <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                        <span>Save</span>
                    </button>
                </div>
                <div class="card-body" style="min-height: 250px;">
                    <app-address-form [form]="businessAddressForm"></app-address-form>
                </div>
            </div>
        </div>
        <!-- Accept Proposal Terms -->
        <div class="col-lg-4 mt-lg-0 mt-2">
            <div class="card">
                <div class="card-header d-flex">
                    <h5 class="card-title my-auto mb-auto">Accept Proposal Terms</h5>
                    <button type="button" class="btn btn-primary ms-auto"
                        ngbPopover="Accept Proposal Terms are the checkboxes that a client is required to select before they can accept a proposal.">
                        <fa-icon [icon]="faQuestion"></fa-icon>
                    </button>
                    <button type="button" class="btn btn-primary ms-2"
                        (click)="selectedAcceptProposalTerm = null; resetAcceptProposalTermForm(); openModal(ACCEPT_PROPOSAL_TERM_MODAL);">
                        <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
                        <span>New</span>
                    </button>
                </div>
                <div class="card-body">
                    <ul class="list-group scrollable" style="height: 218px;">
                        <li *ngFor="let acceptProposalTerm of acceptProposalTerms" class="list-group-item"
                            style="cursor: pointer;" (click)="acceptProposalTermSelected(acceptProposalTerm);">
                            <div class="d-flex">
                                <div class="h-fit my-auto">
                                    <p class="mb-0" style="word-break: break-word;">
                                        <strong>{{acceptProposalTerm.position}}.</strong><span>
                                            {{acceptProposalTerm.content}}</span>
                                    </p>
                                </div>
                                <div class="ms-auto d-flex">
                                    <button type="button" class="btn btn-danger my-auto ms-2"
                                        (click)="$event.stopPropagation(); selectedAcceptProposalTerm = acceptProposalTerm; deleteAcceptProposalTerm();"
                                        ngbTooltip="Delete">
                                        <fa-icon [icon]="faTrashCan"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </li>
                        <li *ngIf="acceptProposalTerms.length == 0" class="list-group-item">No Accept Proposal Terms
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row flex-column mt-2">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h3 class="card-title">Accept Proposal Next Action</h3>
                    <h6 class="card-subtitle text-muted">These settings affect the modal appear after user click
                        proposal
                        accept buttons</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 mt-2">
            <div class="card">
                <div class="card-header d-flex">
                    <h5 class="card-title my-auto mb-auto">Follow Up Questions</h5>
                    <button type="button" class="btn btn-primary ms-auto" *ngIf="preferredInstallation"
                        (click)="updatePreferredInstallationOptions();">
                        <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                        <span>Save</span>
                    </button>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="form-check form-switch">
                            <input id="SIGNATURES_ARE_ENABLED" type="checkbox" class="form-check-input" role="switch"
                                [checked]="signaturesAreEnabled" (change)="updateSignatureEnabledStatus($event);">
                            <label for="SIGNATURES_ARE_ENABLED" class="form-check-label"
                                style="font-weight: bold;">Require
                                Signature</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-check form-switch">
                            <input id="PREFFERED_REMOVAL_ARE_ENABLED" type="checkbox" class="form-check-input"
                                role="switch" [checked]="preferredRemoval"
                                (change)="updatePreferredRemovalEnabledStatus($event);">
                            <label for="PREFFERED_REMOVAL_ARE_ENABLED" class="form-check-label"
                                style="font-weight: bold;">
                                Preferred Removal Type
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-check form-switch">
                            <input id="PREFFERED_INSTALLATIONS_ARE_ENABLED" type="checkbox" class="form-check-input"
                                role="switch" [checked]="preferredInstallation"
                                (change)="updatePreferredInstallationEnabledStatus($event);">
                            <label for="PREFFERED_INSTALLATIONS_ARE_ENABLED" class="form-check-label"
                                style="font-weight: bold;">
                                Preferred Installation Week
                            </label>
                        </div>
                    </div>
                    <div class="row my-2 parent-stack" *ngIf="preferredInstallation && installationWeeks.length != 0">
                        <div class="child-stackable " *ngFor="let week of installationWeeks; let i = index">
                            <div class="form-check">
                                <input [id]="'INSTALLATION_OPTIONS_' + i" type="checkbox" class="form-check-input"
                                    (change)="addChosenPreferrences($event, i)"
                                    [checked]="checkIfPrefIsSelected(week.date)">
                                <label [for]="'INSTALLATION_OPTIONS_' + i"
                                    class="form-check-label">{{week.label}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form [formGroup]="editorFormGroup">
        <div class="row">
            <!-- Multi Pricing Description -->
            <div class="col-lg-6 mt-2">
                <div class="card">
                    <div class="card-header d-flex">
                        <h5 class="card-title my-auto mb-auto">Multi Pricing Description</h5>
                        <button type="button" class="btn btn-primary ms-auto"
                            (click)="updateMultiPricingDescription();">
                            <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                            <span>Save</span>
                        </button>
                    </div>
                    <div class="card-body">
                        <!-- <div class="d-flex">
                    <button type="button" class="btn btn-primary ms-auto" (click)="boldSelection();">Bold</button>
                    <button type="button" class="btn btn-primary ms-1" (click)="underlineSelection();">Underline</button>
                </div> -->

                        <quill-editor #editor [sanitize]="true" formControlName="multiPricingDescription"
                            [styles]="{height: '250px', padding: 0}"></quill-editor>
                    </div>
                </div>
            </div>
            <!-- Terms & Conditions -->
            <div class="col-lg-6 mt-2">
                <div class="card">
                    <div class="card-header d-flex">
                        <h5 class="card-title my-auto mb-auto">Terms & Conditions</h5>
                        <button type="button" class="btn btn-primary ms-auto" (click)="addProposalTerm();">
                            <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
                            <span>New</span>
                        </button>
                        <button type="button" class="btn btn-primary ms-2" (click)="updateProposalTerm();">
                            <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                            <span>Save</span>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="mb-3">
                            <div class="row">
                                <div class="col-4 d-flex flex-column">
                                    <label for="PROPOSAL_TERM_SELECT" style="font-weight: bold;">Version</label>
                                    <select id="PROPOSAL_TERM_SELECT" class="form-select" style="width: auto;"
                                        (change)="selectProposalTerm($event.target.value);">
                                        <option *ngFor="let proposalTerm of proposalTerms" [value]="proposalTerm.id">
                                            {{proposalTerm.name}} <span *ngIf="proposalTerm.isDefault">(Default)</span>
                                        </option>
                                    </select>
                                </div>
                                <div class="col-8 d-flex flex-column">
                                    <label for="PROPOSAL_TERM_NAME" style="font-weight: bold;">Name</label>
                                    <div class="input-group">
                                        <input id="PROPOSAL_TERM_NAME" type="text" class="form-control">
                                        <button *ngIf="selectedProposalTerm" type="button"
                                            class="btn btn-outline-primary" (click)="setDefaultProposalTerm();"
                                            [disabled]="selectedProposalTerm.isDefault" ngbTooltip="Set as Default">
                                            <fa-icon [icon]="faCheck"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-0">
                            <quill-editor #editor [sanitize]="true" formControlName="termsAndCondition"
                                [styles]="{height: '200px', padding: 0}"></quill-editor>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </form>

    <!-- Accept Proposal Term Modal -->
    <ng-template #ACCEPT_PROPOSAL_TERM_MODAL let-modal>
        <div class="modal-header">
            <h5 class="modal-title">{{(selectedAcceptProposalTerm) ? 'Edit' : 'New'}} Accept Proposal Term</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
        </div>
        <div class="modal-body">
            <form [formGroup]="acceptProposalTermForm">
                <div class="row">
                    <div class="col">
                        <div class="mb-0">
                            <textarea id="ACCEPT_PROPOSAL_TERM_CONTENT" type="text" class="form-control"
                                placeholder="I agree to ..." formControlName="content" maxlength="65535"
                                required></textarea>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="saveAcceptProposalTerm();">Save</button>
            <!-- Delete Button -->
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
        </div>
    </ng-template>

    <!-- Tax Modal -->
    <ng-template #TAX_MODAL let-modal>
        <div class="modal-header">
            <h5 class="modal-title">{{(selectedTax) ? 'Edit' : 'New'}} Tax</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
        </div>
        <div class="modal-body">
            <form [formGroup]="taxForm">
                <div class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label for="TAX_NAME">Name</label>
                            <input id="TAX_NAME" type="text" class="form-control" placeholder="EXEMPT"
                                formControlName="name" maxlength="150" required
                                [class.is-invalid]="taxName.invalid && (taxName.dirty || taxName.touched)">
                            <div *ngIf="taxName.invalid && (taxName.dirty || taxName.touched)">
                                <div *ngIf="taxName.errors.required" class="input-validation-msg">Name is required.
                                </div>
                                <div *ngIf="taxName.errors.maxlength" class="input-validation-msg">Name cannot exceed
                                    150
                                    characters.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="mb-0">
                            <label for="TAX_PERCENT">Percent</label>
                            <input id="TAX_PERCENT" type="number" class="form-control" step="1.00" placeholder="5.00"
                                formControlName="percent" min="0" required
                                [class.is-invalid]="taxPercent.invalid && (taxPercent.dirty || taxPercent.touched)">
                            <div *ngIf="taxPercent.invalid && (taxPercent.dirty || taxPercent.touched)">
                                <div *ngIf="taxPercent.errors.required" class="input-validation-msg">Percent is
                                    required.
                                </div>
                                <div *ngIf="taxPercent.errors.min" class="input-validation-msg">Percent cannot be less
                                    than
                                    0.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="saveTax();">Save</button>
            <!-- Delete Button -->
            <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
        </div>
    </ng-template>