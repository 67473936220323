import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { faBold, faPhone, faPhoneSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { interval, lastValueFrom, merge, Observable, Subject, takeUntil } from 'rxjs';
import { ConversationService } from '../conversation.service';
import { CallService } from '../call.service';

@Component({
  selector: 'app-incoming-call-dialog',
  templateUrl: './incoming-call-dialog.component.html',
  styleUrls: ['./incoming-call-dialog.component.css'],
})
export class IncomingCallDialogComponent implements OnInit {

  @Input() contact: any = {
    contact: {
      firstName: "Gaston",
      lastName: "Velarde",
      clientId: "123121231"
    }
  };
  isMobileDevice: boolean = /Mobi/i.test(window.navigator.userAgent);

  incomingCallNumber: string | null = null
  public isIncall: boolean = false;
  public inCommingCall: boolean = false;
  private destroyed$ = new Subject();
  public callDetails: any = {
    created: null,
    id: null
  }

  activeCall: any;
  sound: HTMLAudioElement;

  faPhone = faPhone;
  faPhoneSlash = faPhoneSlash;

  @Output() acceptCallEmit: EventEmitter<any> = new EventEmitter<any>();;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private conversationSerivce: ConversationService,
    private callService: CallService
  ) { }

  ngOnInit(): void {
    this.callService.activeCall
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        if (val) {
          this.activeCall = val;
          this.contact.number = this.activeCall?.parameters.From;
          this.getContactByNumber(this.activeCall?.parameters.From);
          this.callEvents();
        }
      });

    this.callService.incomingCallValue.subscribe((val) => {
      this.inCommingCall = val;
      if (val) {
        console.log(val)
        this.sound = new Audio();
        this.sound.src = './assets/sounds/incoming-call.mp3';
        this.sound.load();
        this.sound.loop = true;
        this.sound.muted = true;
        this.sound.muted = false;
        this.sound.play();
      }

    })

    this.callService.fromNumber
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => this.incomingCallNumber = val)
  }


  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  acceptCall() {
    console.log('Accepting call')
    this.callService.incomingCall = false;
    this.sound.pause();
    this.sound.currentTime = 0;
    if (this.activeCall) {
      this.activeCall.accept();
      this.acceptCallEmit.emit(true);
      this.inCommingCall = false;
      this.callService.isCallAccept = true;
    }
  }

  rejectCall() {
    console.log('Rejecting call')
    if (this.activeCall) {
      this.activeCall.reject();
      this.inCommingCall = false;

      this.callService.incomingCall = false;
      this.callService.caller = null;
      this.sound.pause();
      this.sound.currentTime = 0;
    }
  }

  callEvents() {

    this.activeCall.on('disconnect', call => {
      //this.callService.isCallAccept = false;
    });

    this.activeCall.on('cancel', call => {
      this.inCommingCall = false;
      if (this.sound) {
        this.sound.pause();
        this.sound.currentTime = 0;
      }
    });

  }

  currentContactDisplay(){
    return this.contact.contact?.hasOwnProperty('clientId') ? `${this.contact.contact?.firstName} ${this.contact.contact?.lastName} (${this.contact.number})` : this.contact.number;
  }

  getContactByNumber(number: string){
    this.conversationSerivce.getContactByNumber(number).subscribe(res => {
      this.contact.contact = res;
    })
  }

}



export interface TimeSpan {
  hours: number;
  minutes: number;
  seconds: number;
}