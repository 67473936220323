<div class="row flex-column mt-2">
    <div class="col">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">General</h3>
                <h6 class="card-subtitle text-muted">Manage your project settings.</h6>
            </div>
        </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col-lg-4">
        <div class="card">
            <div class="card-header d-flex">
                <h5 class="card-title my-auto mb-auto">Bases</h5>
                <button type="button" class="btn btn-primary ms-auto" ngbPopover="Bases are the default checkboxes that appear in the Bases tab on the Project page. They can be added and removed on a per-project basis as well.">
                    <fa-icon [icon]="faQuestion"></fa-icon>
                </button>
            </div>
            <div class="card-body">
                <div class="input-group">
                    <input id="NEW_BASE_CONTENT" type="text" class="form-control" placeholder="Did you follow up with the client?" maxlength="150">
                    <button type="button" class="btn btn-outline-primary" (click)="addProjectBase();">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </button>
                </div>
                <div class="mt-2">
                    <ul class="list-group scrollable" style="height: 276px;">
                        <li *ngFor="let base of bases" class="list-group-item" style="cursor: pointer;">
                            <div class="d-flex">
                                <div class="h-fit my-auto">
                                    <div>{{base.content}}</div>
                                </div>
                                <div class="ms-auto d-flex">
                                    <button type="button" class="btn btn-danger my-auto ms-2" (click)="deleteProjectBase(base.id);" ngbTooltip="Delete">
                                        <fa-icon [icon]="faTrashCan"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </li>
                        <li *ngIf="bases.length == 0" class="list-group-item">No Bases</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>