import { Component, HostListener, OnInit } from '@angular/core';
import { Client } from '../client';
import { ClientService } from '../client.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faAngleLeft, faPlus, faSortUp, faSortDown, faFilter, faTrashCan, faFileDownload, faFileUpload, faRotateLeft, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { Subject, throwError } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';
import { ClientStatusPipe } from '../client-status.pipe';
import { Location } from '@angular/common';
import { NewClientModalComponent } from '../new-client-modal/new-client-modal.component';
import { AlertService } from '../alert.service';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { TableControlService } from '../table-control.service';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css'],
  providers: [ClientStatusPipe]
})
export class ClientsComponent implements OnInit {
 //Screensize event Listener
 @HostListener('window:resize', ['$event'])
 onResize(event: Event) {
   this.resizeSubject.next((event.target as Window).innerWidth);
 }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Modals
  private modalReference: NgbModalRef;

  // Properties
  clients: Client[] = [];
  clientCount: number = 0;
  isMobileView: boolean = false;

  public selectedSort: string = '';
  public selectedOrder: string = '';
  public isModalVisible: boolean = false;
  currentFilterColumnAndValues: any;
  sortOptions = [
    { label: 'Name', value: 'client_name' },
    { label: 'Street', value: 'client_address_street' },
    { label: 'City', value: 'client_address_city' },
    { label: 'Country', value: 'client_address_county' },
    { label: 'Status', value: 'client_status' },
    { label: 'Created', value: 'client_created_at' },
  ];
  filterColumns = [
    { label: 'City', value: 'client_address_city' },
    { label: 'Country', value: 'client_address_county' },
    { label: 'Status', value: 'client_status' },
  ];
  sortBy: string = 'client_name';
  sortDirection: string = 'ASC';

  // Actions
  selectedClientId: string;

  // Table Controls
  tableControls: TableControlService;
  storedSearchValue: string;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;
  selectedFilterColumn: string ='';

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faPlus = faPlus;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;
  faTrashCan = faTrashCan;
  faFileDownload = faFileDownload;
  faFileUpload = faFileUpload;
  faRotateLeft = faRotateLeft;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private clientService: ClientService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private clientStatusPipe: ClientStatusPipe,
    public location: Location,
    private loaderService: LoaderService) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
          this.tableControls.setLimit(20);
        }
      });
     }

  ngOnInit(): void {
    this.tableControls = new TableControlService('Clients', true, 'client_name', 'ASC');
    this.checkScreenSize();
    if(this.isMobile) {
      let activeFilters = this.tableControls.getActiveFilters()
      let columns = [];
      for (const [key, value] of activeFilters) {
        columns.push(key)
      }
      this.tableControls.resetFilters(columns)
    }
    this.tableControls.refresh.subscribe(() => {
      this.getClients();
    });
    if (this.tableControls.hasTableConfiguration()) this.storedSearchValue = this.tableControls.getSearchTerm();
    this.getClients();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
    this.resizeSubject.unsubscribe();
  }

  // Open Modal
  openModal(content: any): void {
    this.modalReference = this.modalService.open(content);
  }

  // Open New Client Modal
  openNewClientModal(): void {
    this.modalReference = this.modalService.open(NewClientModalComponent);
  }

  // Get Clients
  getClients(): void {
    this.loaderService.showSpinner();
    let params = this.tableControls.getParams();
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
      for (const [key, value] of this.activeFilters) {
        if (key.includes('range')) params[`filter:${key}`] = value;
        else params[`filter:${key}`] = JSON.stringify(value);
      }
    };

    // this.currentSortAndOrderValues = { sortBy: params?.sortBy, order: params?.sortDirection?.toLowerCase() };
    this.clientService.getClients(params).subscribe( {
      next: (res) => {
        this.clients = res.clients;
        this.clientCount = res.count;
        this.loaderService.hideSpinner();
      },
      error:() => {
        this.loaderService.hideSpinner();
      }
    });
  }

  // Delete Client
  deleteClient(): void {
    this.clientService.deleteClient(this.selectedClientId).subscribe(() => {
      this.getClients();
      this.modalReference.close();
      this.selectedClientId = null;
    });
  }

  /* ----- Filters and Sorting ----- */
  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    this.selectedFilterColumn = column;
    if (!this.columnFilterValues.has(column)) {
      const params = {
        searchTerm: null,
        sortBy: column,
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        column: column
      };
      if(this.isMobile) {
        for (const [key, value] of this.activeFilters) {
          if (key.includes('range')) params[`filter:${key}`] = value;
          else params[`filter:${key}`] = JSON.stringify(value);
        }
      }
      this.clientService.getDistinctColumnValues(params).subscribe((values) => {
        const tempArray = [];
        for (const value of values) {
          let name = null;
          switch (column) {
            case 'client_status':
              name = this.clientStatusPipe.transform(value);
              break;
            default:
              name = (value === null || value.length == 0) ? 'BLANK' : value;
              break;
          }
          tempArray.push({ name: name, value: value, randomId: Math.random().toString(36) });
        }
        this.columnFilterValues.set(column, tempArray);
      });
    }
  }

  // Show Column Filter Popover
  showColumnFilterPopover(popover, column: string): void {
    this.getDistinctColumnValues(column);
    if (popover.isOpen()) {
      popover.close();
    } else {
      setTimeout(() => {
        popover.open({ column });
      }, 250);
    }
  }

   // Sorting
   sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getClients();
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getClients();
    this.getActiveFilterCount();
  }

   // Get Active Filter Count
   getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getClients();
    this.getActiveFilterCount();
  }


  /* ----- Import/Export ----- */

  // Export Clients
  exportClients(): void {
    window.open(`${environment.apiUrl}/clients/export`);
  }

  // File Input Changed
  fileInputChanged(files: File[]): void {
    const file = files[0];
    if (files.length !== 0) {
      if (file.type != 'application/vnd.ms-excel' && file.type != 'text/csv') {
        this.alertService.showWarningAlert('File must be a CSV.');
      } else {
        this.alertService.showInfoAlert('Importing...');
        this.clientService.importClients(file).pipe(catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 400) (document.getElementById('FILE_INPUT') as HTMLInputElement).value = null;
          }
          return throwError(() => new Error('API Request Error'));
        })).subscribe((res) => {
          this.alertService.showSuccessAlert(`Imported ${res.newClientCount} Clients and ${res.newContactCount} Contacts`);
          this.getClients();
          (document.getElementById('FILE_INPUT') as HTMLInputElement).value = null;
        });
      }
    }
  }

  // Browse Files
  browseFiles(): void {
    (document.getElementById('FILE_INPUT') as HTMLInputElement).click();
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
    this.isMobileView ? this.tableControls.setLimit(5): this.tableControls.setLimit(20);
  }

}
