import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faInfoCircle, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-number-campaign',
  templateUrl: './number-campaign.component.html',
  styleUrls: ['./number-campaign.component.css']
})
export class NumberCampaignComponent {

  @Input() campaignForm!: FormGroup;
  usecaseOptions: any[] = [];
  @Input() useCases: any[] = []
  @Input() numbers: any[] = []

  faPlus = faPlus;
  faTrash = faTrash;
  faInfoCircle = faInfoCircle;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

  }

  get messageSamples() {
    return this.campaignForm.get('messageSamples') as FormArray;
  }

  get numbersToUse() {
    return this.campaignForm.get('numbersToUse') as FormArray;
  }

  addMessageSample() {
    this.messageSamples.push(this.fb.control(null));
  }

  addNumbersToUse(numberOption) {
    this.numbersToUse.push(this.fb.control(numberOption.text));
  }

  removeNumbersToUse(numberOptionCtrl) {
    console.log(numberOptionCtrl)
    this.numbersToUse.controls = this.numbersToUse.controls.filter(control => control.valid && control.value != numberOptionCtrl.value);

    console.log(this.numbersToUse.controls)

  }

  removeMessageSample(i) {
    this.messageSamples.removeAt(i);
  }

  toggleOption(option: any) {
    option.selected = !option.selected;
    if (option.selected) {
      this.addNumbersToUse(option)
    } else {
      const chosenCtrl = this.numbersToUse.controls.filter(control => control.value == option.text)
      this.removeNumbersToUse(chosenCtrl[0])
    }
  }

  isSelected(option: any) {
    return option.selected;
  }

}
