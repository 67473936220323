<div class="modal-header">
    <h5 class="modal-title">New Project</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="projectForm">
        <div class="mb-3">
            <label for="CLIENT">Client</label>
            <input *ngIf="clientTypeaheadInput" id="CLIENT" type="text" class="form-control" formControlName="client" placeholder="Type to Search" [ngbTypeahead]="clientTypeaheadInput" (focus)="clientTypeaheadInputFocus$.next($any($event).target.value)" [resultFormatter]="clientResultFormatter" (selectItem)="selectClient($event);">
        </div>
        <div class="mb-3">
            <label for="SITE">Site</label>
            <div class="input-group">
                <select id="SITE" class="form-select" formControlName="site" required [class.is-invalid]="site.invalid && (site.dirty || site.touched)">
                    <option *ngFor="let site of sites" [value]="site.id">{{site.address.street}} {{site.address.city}} {{site.address.state}} {{site.address.postalCode}} ({{site.name}})</option>
                </select>
                <button *ngIf="showAddSiteButton" type="button" class="btn btn-primary ms-auto" (click)="openNewSiteModal();" ngbTooltip="New Site">
                    <fa-icon [icon]="faPlus"></fa-icon>
                </button>
            </div>
            <div *ngIf="site.invalid && (site.dirty || site.touched)">
                <div *ngIf="site.errors.required" class="input-validation-msg">Site is required.</div>
            </div>
        </div>
        <div class="mb-3">
            <label for="PROJECT_SALESPERSON">Salesperson</label>
            <select id="PROJECT_SALESPERSON" class="form-select" formControlName="salesperson">
                <option *ngFor="let user of users" [value]="user.id">{{user.firstName}} {{user.lastName}}</option>
            </select>
        </div>
        <div class="mb-3">
            <label for="PROJECT_NAME">Name</label>
            <input *ngIf="projectNameTypeaheadInput" id="PROJECT_NAME" type="text" class="form-control" placeholder="Lighting" formControlName="name" maxlength="150" required [ngbTypeahead]="projectNameTypeaheadInput" (focus)="projectNameTypeaheadInputFocus$.next($any($event).target.value)" [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
                <div *ngIf="name.errors.required" class="input-validation-msg">Name is required.</div>
                <div *ngIf="name.errors.maxlength" class="input-validation-msg">Name cannot exceed 150 characters.</div>
            </div>
        </div>
        <div class="mb-3">
            <label for="PROJECT_CATEGORY">Category</label>
            <input *ngIf="projectCategoryTypeaheadInput" id="PROJECT_CATEGORY" type="text" class="form-control" formControlName="category" placeholder="Christmas" maxlength="150" required [ngbTypeahead]="projectCategoryTypeaheadInput" (focus)="projectCategoryTypeaheadInputFocus$.next($any($event).target.value)" [class.is-invalid]="category.invalid && (category.dirty || category.touched)">
            <div *ngIf="category.invalid && (category.dirty || category.touched)">
                <div *ngIf="category.errors.required" class="input-validation-msg">Category is required.</div>
                <div *ngIf="category.errors.maxlength" class="input-validation-msg">Category cannot exceed 150 characters.</div>
            </div>
        </div>
        <div>
            <label for="PROJECT_YEAR">Year</label>
            <input id="PROJECT_YEAR" type="number" class="form-control" placeholder="202X" formControlName="year" min="0" required [class.is-invalid]="year.invalid && (year.dirty || year.touched)">
            <div *ngIf="year.invalid && (year.dirty || year.touched)">
                <div *ngIf="year.errors.required" class="input-validation-msg">Year is required.</div>
                <div *ngIf="year.errors.min" class="input-validation-msg">Year cannot be less than 0.</div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addProject();">Save</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>