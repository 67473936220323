import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appPhoneMask]'
})
export class PhoneMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('input', ['$event']) onInput(event: InputEvent | CustomEvent) {
    const value = (event.target as HTMLInputElement).value;
    if (event instanceof InputEvent && event.inputType == 'insertText') {
      if (value !== null) this.onInputChange(value, false);
    } else if (event instanceof InputEvent && event.inputType == 'deleteContentBackward') {
      this.onInputChange(value, true);
    } else {
      if (value !== null) {
        const newVal = this.formatPhoneNumber(value.replace(/\D/g, ''));
        this.ngControl.control.setValue(newVal);
      }
    }
  }

  // On Input Change
  onInputChange(value: string, backspace: boolean) {
    value = value.replace(/\D/g, '');
    if (backspace && value.length <= 3) value = value.substring(0, value.length - 1);
    value = this.formatPhoneNumber(value);
    this.ngControl.valueAccessor.writeValue(value);
    
  }

  // Format Phone Number
  formatPhoneNumber(value: string): string {
    if (value.length == 0) return null;
    else if (value.length <= 3) value = value.replace(/^(\d{0,3})/, '($1)');
    else if (value.length <= 6) value = value.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    else if (value.length <= 10) value = value.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    else value = value.substring(0, 10).replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    return value;
  }
}
