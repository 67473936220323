import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import { ReportService } from '../report.service';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-reports-invoices',
  templateUrl: './reports-invoices.component.html',
  styleUrls: ['./reports-invoices.component.css']
})
export class ReportsInvoicesComponent implements OnInit {

  // Properties
  startDate: string;
  endDate: string;

  monthlyVolumeChart: Chart;

  monthlyVolumeAmountChartData: any[];
  monthlyVolumeAmountTotal: number = 0;

  monthlyVolumeCountChartData: any[];
  monthlyVolumeCountTotal: number = 0;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;

  constructor(private reportService: ReportService,
    public location: Location,
    private loaderService: LoaderService) { }

  ngOnInit(): void {

  }

  // Date Changed
  dateChanged(event: { startDate: string, endDate: string, isAuto: boolean }): void {
    this.loaderService.showSpinner();
    this.startDate = event.startDate;
    this.endDate = event.endDate;
    this.getMonthlyVolumeChartData();
  }

  // Get Monthly Volume Chart Data
  private getMonthlyVolumeChartData(): void {
    const params: any = {
      startDate: this.startDate,
      endDate: this.endDate
    };
    this.reportService.getInvoicesReport(params).subscribe(
      {
        next: (reportData) => {
          // Amount
          this.monthlyVolumeAmountChartData = (reportData.monthlyVolumeChartData as any[]).map((data: any) => {
            return { label: dayjs().month(data.month - 1).year(data.year).format('MMMM YYYY'), value: data.amount };
          });
          this.monthlyVolumeAmountTotal = (reportData.monthlyVolumeChartData as any[]).reduce((acc: number, data: any) => { return acc + data.amount; }, 0);
          // Count
          this.monthlyVolumeCountChartData = (reportData.monthlyVolumeChartData as any[]).map((data: any) => {
            return { label: dayjs().month(data.month - 1).year(data.year).format('MMMM YYYY'), value: data.count };
          });
          this.monthlyVolumeCountTotal =  (reportData.monthlyVolumeChartData as any[]).reduce((acc: number, data: any) => { return acc + data.count; }, 0);
          if (!this.monthlyVolumeChart) {
            this.generateMonthlyVolumeChart();
          } else {
            if (this.monthlyVolumeAmountChartData.length === 0) {
              this.monthlyVolumeChart.data.labels.length = 0;
              this.monthlyVolumeChart.data.datasets[0].data.length = 0;
            } else {
              this.monthlyVolumeChart.data.labels = this.monthlyVolumeAmountChartData.map((chartData) => { return chartData.label; });
              this.monthlyVolumeChart.data.datasets[0].data = this.monthlyVolumeAmountChartData.map((chartData) => { return chartData.value; });
              this.monthlyVolumeChart.data.datasets[1].data = this.monthlyVolumeCountChartData.map((chartData) => { return chartData.value; });
            }
            this.monthlyVolumeChart.update();
          }
          this.loaderService.hideSpinner(700);
        },
        error: () => {
          this.loaderService.hideSpinner(700);
        }
      }


      );
  }

  // Generate Monthly Volume Chart
  private generateMonthlyVolumeChart(): void {
    const ctx = (document.getElementById('MONTHLY_VOLUME_CHART') as HTMLCanvasElement);
    this.monthlyVolumeChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.monthlyVolumeAmountChartData.map((chartData) => { return chartData.label; }),
        datasets: [
          {
            label: 'Amount',
            data: this.monthlyVolumeAmountChartData.map((chartData) => { return chartData.value; }),
            backgroundColor: 'rgb(16, 94, 34, 0.5)',
            yAxisID: 'AMOUNT'
          },
          {
            label: 'Count',
            data: this.monthlyVolumeCountChartData.map((chartData) => { return chartData.value; }),
            backgroundColor: 'rgb(70, 130, 180, 0.5)',
            yAxisID: 'COUNT'
          },
        ]
      },
      plugins: [ChartDataLabels],
      options: {
        events: null,
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 15
          }
        },
        scales: {
          AMOUNT: {
            title: {
              display: true,
              text: 'Amount'
            },
            type: 'linear',
            position: 'left',
            ticks: {
              callback: (value) => {
                return '$' + value;
              }
            }
          },
          COUNT: {
            title: {
              display: true,
              text: 'Count'
            },
            grid: {
              drawOnChartArea: false
            },
            type: 'linear',
            position: 'right'
          }
        },
        plugins: {
          legend: {
            display: false,
            labels: {
              color: 'black',
              font: {
                family: "'Poppins', sans-serif"
              }
            },
            position: 'bottom'
          },
          tooltip: {
            enabled: false
          },
          datalabels: {
            align: 'top',
            anchor: 'end',
            color: 'black',
            font: {
              family: "'Poppins', sans-serif",
              weight: 600
            },
            formatter: (value, context) => {
              if (context.dataset.label == 'Amount') {
                return (value > 0) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value) : null;
              } else {
                return value;
              }
            },
            offset: -2.5,
            textAlign: 'center'
          }
        }
      }
    });
  }
}
