import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Address } from './address';
import { GeoPosition } from './geo-position';
import { State } from './state';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  // Search For Address
  searchForAddress(searchString: string): Observable<Address[]> {
    return this.http.get<Address[]>(`/location/searchForAddress`, {
      params: {
        searchString: searchString
      }
    });
  }

  // Get States
  getStates(): Observable<State[]> {
    return this.http.get<State[]>(`/location/states`);
  }

  // Calculate Shortest Route
  calculateShortestRoute(locations: string[], positions: number[][]): Observable<string[]> {
    return this.http.post<string[]>(`/location/calculateShortestRoute`, { locations: locations, positions: positions });
  }

  // Get Current Geo Position
  getCurrentGeoPosition(): Promise<GeoPosition> {
    return new Promise((resolve, reject) => {
      const options = {
        maximumAge: 0,
        timeout: 5000,
        enableHighAccuracy: true
      };
      navigator.geolocation.getCurrentPosition((pos) => {
        const geoPos: GeoPosition = {
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
          accuracy: pos.coords.accuracy
        };
        resolve(geoPos);
      }, (error) => {
        reject(error);
      }, options);
    });
  }

  // Parse Geo Position Error
  parseGeoPositionError(error: GeolocationPositionError): string {
    switch (error.code) {
      case 1:
        return 'Location Permission Denied';
      case 2:
        return 'Location Unavailable';
      case 3:
        return 'Location Timeout';
      default:
        return 'Unknown Error';
    }
  }
}
