import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FeatureItemService } from '../feature-item.service';
import { faTimesCircle, faShoppingCart, faCheck, faFilter, faSortDown, faSortUp, faRotateLeft, faCartArrowDown, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from '../alert.service';
import { ProjectPrepService } from '../project-prep.service';
import { ProjectPrepStatusPipe } from '../project-prep-status.pipe';
import { TableControlService } from '../table-control.service';
import { InventoryPrepService } from '../inventory-prep.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectPrepOrderModalComponent } from '../project-prep-order-modal/project-prep-order-modal.component';
import { InventoryPrep } from '../inventory-prep';
import { ProjectPrepCheckInModalComponent } from '../project-prep-check-in-modal/project-prep-check-in-modal.component';
import { ProjectPrepCancelOrderModalComponent } from '../project-prep-cancel-order-modal/project-prep-cancel-order-modal.component';
import { ProjectPrepCancelSplitMsgComponent } from '../project-prep-cancel-split-msg/project-prep-cancel-split-msg.component';
import { AdminSettingService } from '../admin-setting.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-project-prep-orders',
  templateUrl: './project-prep-orders.component.html',
  styleUrls: ['./project-prep-orders.component.css'],
  providers: [ProjectPrepStatusPipe]
})
export class ProjectPrepOrdersComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  @Input() projectId: string;
  @Output() allCheck = new EventEmitter<boolean>();

  // Properties
  projectPrepItems: any[] = [];
  count: number = 0;
  isMobileView: boolean = false;

  sortOptions = [
    { label: 'Item', value: 'i.item_name' },
    { label: 'Feature', value: 'f.feature_name' },
    { label: 'Project', value: 'p.project_name' },
    { label: 'Client', value: 'c.client_name' },
    { label: 'Source', value: 'i.item_source' },
    { label: 'Quantity', value: 'ip.inventory_prep_quantity' },
    { label: 'Status', value: 'ip.inventory_prep_status' },
    { label: 'Quantity Ordered', value: 'ip.inventory_prep_ordered' },
    { label: 'Updated', value: 'ip.inventory_prep_updated_at' },
  ];
  filterColumns = [
    { label: 'Item', value: 'i.item_name' },
    { label: 'Feature', value: 'f.feature_name' },
    { label: 'Project', value: 'p.project_name' },
    { label: 'Client', value: 'c.client_name' },
    { label: 'Source', value: 'i.item_source' },
    { label: 'Status', value: 'ip.inventory_prep_status' }
  ];
  sortBy: string = 'p.project_name';
  sortDirection: string = 'ASC';


  // Table Controls
  tableControls: TableControlService;
  storedSearchValue: string;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;
  selectedFilterColumn: string ='';

  // Font Awesome Properties
  faTimesCircle = faTimesCircle;
  faShoppingCart = faShoppingCart;
  faCheck = faCheck;
  faRotateLeft = faRotateLeft;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;
  faCartArrowDown = faCartArrowDown;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private featureItemService: FeatureItemService,
    private projectPrepService: ProjectPrepService,
    private inventoryPrepService: InventoryPrepService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private projectPrepStatusPipe: ProjectPrepStatusPipe,
    private adminSettingService: AdminSettingService) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
          this.tableControls.setLimit(20);
        }
      });
    }

  ngOnInit(): void {
    if (this.projectId) {
      this.tableControls = new TableControlService('ProjectPrep.Inventory', true, null, 'DESC', [['ip.inventory_prep_project_id', [this.projectId]], ['ip.inventory_prep_status', ['TO-BE-ORDERED', 'ORDERED', 'CHECK-IN-ORDERED']]]);
    } else {
      this.tableControls = new TableControlService('ProjectPrep.Inventory', true, null, 'DESC', [['ip.inventory_prep_status', ['TO-BE-ORDERED', 'ORDERED', 'CHECK-IN-ORDERED']]]);
    }
    this.checkScreenSize();
    if(this.isMobile) {
      let activeFilters = this.tableControls.getActiveFilters()
      let columns = [];
      for (const [key, value] of activeFilters) {
        columns.push(key)
      }
      this.tableControls.resetFilters(columns)
    }
    this.tableControls.resetTableConfiguration();
    this.tableControls.refresh.subscribe(() => {
      this.getProjectPrepItems();
    });

    if (this.tableControls.hasTableConfiguration()) this.storedSearchValue = this.tableControls.getSearchTerm();
    this.getProjectPrepItems();

  }

  /* ----- Project Prep Items ----- */

  // Get Project Prep Items
  private getProjectPrepItems(): void {
    let params = this.tableControls.getParams();
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
      for (const [key, value] of this.activeFilters) {
        if (key.includes('range')) params[`filter:${key}`] = value;
        else params[`filter:${key}`] = JSON.stringify(value);
      }
    };
    this.inventoryPrepService.getInventoryPrep(params).subscribe((res) => {
      this.projectPrepItems = res.inventoryPrep;
      this.count = res.count;
    });
  }

  // Reset Feature Item
  resetFeatureItem(itemPrep: InventoryPrep): void {
    if (itemPrep.fromSplit) {
      this.inventoryPrepService.getInventoryPrepStatAndQty(itemPrep.fromSplit).subscribe((res) => {
        if (res.status == 'PULLING') {
          const modalRef = this.modalService.open(ProjectPrepCancelSplitMsgComponent);
          modalRef.componentInstance.itemPrep = itemPrep;
          modalRef.componentInstance.itemName = itemPrep.itemName;
          modalRef.componentInstance.clickOn = 'Inventory Tab';
          modalRef.componentInstance.saved.subscribe((res) => {
            if (res == 1) {
              this.returnItem(itemPrep.itemId, itemPrep.id, itemPrep.quantity, itemPrep.fromSplit)
            }
          });
        } else {
          this.returnItem(itemPrep.itemId, itemPrep.id, itemPrep.quantity)
        }
      });
    } else {
      this.returnItem(itemPrep.itemId, itemPrep.id, itemPrep.quantity)
    }
  }

  returnItem(itemId: string, prepId: string, quantity: number, fromSplit?: string) {
    const qty = fromSplit ? quantity : 0
    this.inventoryPrepService.updateInventoryPrepStatus(itemId, prepId, 'PENDING', qty, fromSplit).subscribe(() => {
      this.alertService.showSuccessAlert('Item Reset');
      this.getProjectPrepItems();
    });
  }

  // Order Feature Item
  orderFeatureItem(itemName: string, itemId: string): void {
    const prepItems = this.projectPrepItems.filter(item => item.status == 'TO-BE-ORDERED' && item.itemId == itemId)

    const modalRef = this.modalService.open(ProjectPrepOrderModalComponent);
    modalRef.componentInstance.itemName = itemName;

    modalRef.componentInstance.projectPrepItems = prepItems;
    modalRef.componentInstance.saved.subscribe((res: any) => {
      if (res) {
        this.inventoryPrepService.orderInventoryPrep(res, itemId).subscribe(() => {
          this.alertService.showSuccessAlert('Item Ordered');
          this.getProjectPrepItems();
        });
      }
    });
  }

  // Cancel Order (From Ordered -> Needs to Order)
  cancelItemOrdered(itemPrep: InventoryPrep): void {
    const prepItems = this.projectPrepItems.filter(item => item.status == 'ORDERED' && item.itemId == itemPrep.itemId)

    const modalRef = this.modalService.open(ProjectPrepCancelOrderModalComponent, { size: 'lg' });
    modalRef.componentInstance.itemName = itemPrep.itemName;

    modalRef.componentInstance.projectPrepItems = prepItems;
    modalRef.componentInstance.saved.subscribe((res) => {
      if (res) {
        this.inventoryPrepService.orderInventoryPrep(res, itemPrep.itemId).subscribe(() => {
          this.alertService.showSuccessAlert('Order Canceled');
          this.getProjectPrepItems();
        });
      }
    });
  }

  moveBackToOrdered(itemPrep: InventoryPrep): void {
    const qty = itemPrep.quantityOrdered - itemPrep.quantity;

    this.inventoryPrepService.updateInventoryPrepStatus(itemPrep.itemId, itemPrep.id, 'ORDERED', qty).subscribe(() => {
      this.alertService.showSuccessAlert('Item Move back In');
      this.getProjectPrepItems();
    });
  }

  // Check In Feature Item
  async checkInFeatureItem(itemPrep: InventoryPrep): Promise<void> {
    if (itemPrep.quantity < itemPrep.quantityOrdered) {
      this.checkInExcess(itemPrep, itemPrep.quantityOrdered - itemPrep.quantity);
    } else {
      this.changePrepStatus(itemPrep, 0);
    }
  }

  changePrepStatus(itemPrep: InventoryPrep, qty: number) {
    this.inventoryPrepService.updateInventoryPrepStatus(itemPrep.itemId, itemPrep.id, 'CHECK-IN-ORDERED', qty).subscribe(() => {
      this.checkIfNeedsToMove(itemPrep.proposalId, itemPrep.projectId)
      // this.alertService.showSuccessAlert('Item Checked In');
      // this.getProjectPrepItems();
    });
  }

  checkIfNeedsToMove(proposalId: string, projectId: string) {
    this.inventoryPrepService.checkIfProjectNeedsToMove(proposalId, projectId)
      .subscribe((res: boolean) => {
        if (res) this.allCheck.emit(res);
        this.alertService.showSuccessAlert('Item Checked In');
        this.getProjectPrepItems();
      });
  }

  // Order Feature Item
  checkInExcess(itemPrep: InventoryPrep, excess: number): void {
    const modalRef = this.modalService.open(ProjectPrepCheckInModalComponent);
    modalRef.componentInstance.itemName = itemPrep.itemName;

    modalRef.componentInstance.quantity = excess;
    modalRef.componentInstance.choice.subscribe((res) => {
      this.changePrepStatus(itemPrep, res);

    });

  }

  /* ----- Filters and Sorting ----- */

  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    this.selectedFilterColumn = column;
    let params = null;

    if (!this.columnFilterValues.has(column)) {
      params = {
        searchTerm: null,
        sortBy: column,
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        column: column,
        'filter:ip.inventory_prep_status': JSON.stringify(['TO-BE-ORDERED', 'ORDERED', 'CHECK-IN-ORDERED'])
      };

      if (this.projectId) {
        params = {
          ...params,
          'filter:ip.inventory_prep_project_id': JSON.stringify([this.projectId])
        }
      }
      if(this.isMobile) {
        for (const [key, value] of this.activeFilters) {
          if (key.includes('range')) params[`filter:${key}`] = value;
          else params[`filter:${key}`] = JSON.stringify(value);
        }
      }
      this.projectPrepService.getDistinctColumnValues(params).subscribe((values) => {
        const tempArray = [];
        for (const value of values) {
          let name = null;
          switch (column) {
            case 'ip.inventory_prep_status':
              name = this.projectPrepStatusPipe.transform(value);
              break;
            default:
              name = (value === null || value.length == 0) ? 'BLANK' : value;
              break;
          }
          tempArray.push({ name: name, value: value, randomId: Math.random().toString(36) });
        }
        this.columnFilterValues.set(column, tempArray);
      });
    }
  }

  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getProjectPrepItems();
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getProjectPrepItems();
    this.getActiveFilterCount();
  }

   // Get Active Filter Count
   getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getProjectPrepItems();
    this.getActiveFilterCount();
  }

  // Show Column Filter Popover
  showColumnFilterPopover(popover, column: string): void {
    this.getDistinctColumnValues(column);
    if (popover.isOpen()) {
      popover.close();
    } else {
      setTimeout(() => {
        popover.open({ column });
      }, 250);
    }
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
    this.isMobileView ? this.tableControls.setLimit(5): this.tableControls.setLimit(20);
  }

}
