<div class="modal-header">
    <h5 class="modal-title">{{(payment) ? 'Edit' : 'New'}} Payment</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="paymentForm">
        <div *ngIf="showInvoiceLink && payment" class="mb-2">
            <button type="button" class="btn btn-outline-primary w-100" routerLink="/invoices/{{payment.invoiceId}}">
                <span>Go to Invoice #{{payment.invoiceNumber}}</span>
                <fa-icon class="button-icon-right" [icon]="faExternalLinkAlt"></fa-icon>
            </button>
        </div>
        <div class="mb-3">
            <label for="PAYMENT_AMOUNT">Amount</label>
            <div class="input-group">
                <div class="input-group-text">$</div>
                <input id="PAYMENT_AMOUNT" type="text" class="form-control" placeholder="5.00" step="1.00" formControlName="amount" min="0" required [class.is-invalid]="amount.invalid && (amount.dirty || amount.touched)">
            </div>
            <div *ngIf="amount.invalid && (amount.dirty || amount.touched)">
                <div *ngIf="amount.errors.required" class="input-validation-msg">Amount is required.</div>
                <div *ngIf="amount.errors.min" class="input-validation-msg">Amount cannot be less than 0.</div>
            </div>
        </div>
        <div *ngIf="payment && payment.surcharge" class="mb-3">
            <label for="PAYMENT_SURCHARGE">Surcharge</label>
            <div class="input-group">
                <div class="input-group-text">$</div>
                <input id="PAYMENT_SURCHARGE" type="text" class="form-control" [value]="payment.surcharge" disabled>
            </div>
        </div>
        <div class="mb-3">
            <label for="DATE">Date</label>
            <div class="input-group">
                <input id="DATE" class="form-control" formControlName="date" placeholder="YYYY-MM-DD" required ngbDatepicker #paymentDatepicker="ngbDatepicker" placement="left" [positionTarget]="paymentDatepickerBtn" [class.is-invalid]="date.invalid && (date.dirty || date.touched)">
                <button #paymentDatepickerBtn type="button" class="btn btn-outline-primary" (click)="paymentDatepicker.toggle();">
                    <fa-icon [icon]="faCalendar"></fa-icon>
                </button>
            </div>
            <div *ngIf="date.invalid && (date.dirty || date.touched)">
                <div *ngIf="date.errors.required" class="input-validation-msg">Payment Date is required.</div>
            </div>
        </div>
        <div class="mb-3">
            <label for="PAYMENT_CATEGORY">Category</label>
            <select id="PAYMENT_CATEGORY" class="form-select" formControlName="category">
                <option value="CARD">Credit/Debit</option>
                <option value="ACH">ACH</option>
                <option value="CHECK">Check</option>
                <option value="CASH">Cash</option>
                <option value="PAYPAL">PayPal</option>
                <option value="VENMO">Venmo</option>
                <option value="CASH APP">Cash App</option>
                <option value="ZELLE">Zelle</option>
                <option value="OTHER">Other</option>
            </select>
        </div>
        <div class="mb-3">
            <label for="PAYMENT_STATUS">Status</label>
            <select id="PAYMENT_STATUS" class="form-select" formControlName="status">
                <option value="PENDING">Pending</option>
                <option value="REJECTED">Rejected</option>
                <option value="CLOSED">Closed</option>
            </select>
        </div>
        <div class="mb-3">
            <label for="PAYMENT_NOTE">Note</label>
            <textarea id="PAYMENT_NOTE" class="form-control" formControlName="note" placeholder="Check # 123456789" rows="3" maxlength="65535"></textarea>
            <div *ngIf="note.invalid && (note.dirty || note.touched)">
                <div *ngIf="note.errors.maxlength" class="input-validation-msg">Note cannot exceed 65535 characters.</div>
            </div>
        </div>
        <div class="form-check mb-0">
            <input id="PROMPT_PAYMENT_EMAIL" type="checkbox" class="form-check-input" (change)="toggleShowPaymentEmail($event.target.checked);">
            <label for="PROMPT_PAYMENT_EMAIL" class="form-check-label">Send Email</label>
        </div>
        <div *ngIf="payment && payment.transaction" class="mt-2">
            <div style="font-weight: bold; cursor: pointer;" (click)="transactionDetailsCollapse.toggle();">
                <fa-icon [icon]="(transactionDetailsAreCollapsed) ? faCaretRight : faCaretDown" class="button-icon-left"></fa-icon>
                <span>Transaction Details</span>
            </div>
            <div #transactionDetailsCollapse="ngbCollapse" [(ngbCollapse)]="transactionDetailsAreCollapsed">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex">
                            <strong>Platform</strong>
                            <p class="mb-0 ms-auto">{{payment.transaction.platform}}</p>
                        </div>
                        <div class="d-flex">
                            <strong>Transaction ID</strong>
                            <p class="mb-0 ms-auto">{{payment.transaction.id}}</p>
                        </div>
                        <div *ngIf="payment.transaction.brand" class="d-flex">
                            <strong>Brand</strong>
                            <p class="mb-0 ms-auto">{{payment.transaction.brand}}</p>
                        </div>
                        <div *ngIf="payment.transaction.lastFour" class="d-flex">
                            <strong>Last Four</strong>
                            <p class="mb-0 ms-auto">{{payment.transaction.lastFour}}</p>
                        </div>
                        <div *ngIf="payment.transaction.expiration" class="d-flex">
                            <strong>Expiration</strong>
                            <p class="mb-0 ms-auto">{{payment.transaction.expiration}}</p>
                        </div>
                        <div *ngIf="payment.type == 'ACH' && payment.transaction.type" class="d-flex">
                            <strong>Type</strong>
                            <p class="mb-0 ms-auto">{{payment.transaction.type}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button *ngIf="payment && payment.type == 'DEBIT'" class="btn btn-info me-auto" (click)="openPaymentRefundModal();">Refund</button>
    <button type="button" class="btn btn-primary" (click)="savePayment();">Save</button>
    <button *ngIf="payment" type="button" class="btn btn-danger" (click)="openDeletePaymentConfirmationModal();">Delete</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
</div>