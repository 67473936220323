<div class="card mt-3">
    <div class="card-header d-xl-flex text-break">
        <h5>{{title}} <br class="d-none d-lg-block">Queue ({{scheduleItems.length}})</h5>
        <div class="ms-xl-auto mt-2 mt-xl-0">
            <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container>
            <span class="ms-2"></span>
            <ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container>
            <button type="button" class="btn btn-primary ms-2" (click)="openModal(QUEUE_SCHEDULE_ITEM_MODAL);">
                <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
                <span>New</span>
            </button>
        </div>
    </div>
    <div class="card-body">
        <ul class="list-group scrollable" style="height: 324px;">
            <li *ngFor="let scheduleItem of scheduleItems" class="list-group-item p-3">
                <div class="d-flex">
                    <div class="d-flex">
                        <fa-icon [icon]="faCircle" class="my-auto" size="xs" [style.color]="scheduleItem.countyColor" [ngbTooltip]="scheduleItem.siteCounty" placement="right"></fa-icon>
                    </div>
                    <div class="d-flex flex-column my-auto ms-2" style="width: 78%;">
                        <strong style="word-break:normal;">{{scheduleItem.projectName}}</strong>
                        <div style="word-break: normal;">{{scheduleItem.clientName}}</div>
                    </div>
                    <div class="d-flex flex-xl-row flex-column ms-auto my-auto">
                        <button class="btn btn-primary ms-2 my-xl-auto" type="button" (click)="openScheduleItemModal(scheduleItem);" ngbTooltip="Schedule {{title}}">
                            <fa-icon [icon]="faClock"></fa-icon>
                        </button>
                        <button class="btn btn-danger ms-2 my-xl-auto mt-2" type="button" (click)="deleteScheduleItem(scheduleItem.id);" ngbTooltip="Delete">
                            <fa-icon [icon]="faTrashCan"></fa-icon>
                        </button>
                    </div>
                </div>
            </li>
            <li *ngIf="scheduleItems.length == 0" class="list-group-item">No Queued Items</li>
        </ul>
    </div>
</div>

<!-- Queue Schedule Item Modal -->
<ng-template #QUEUE_SCHEDULE_ITEM_MODAL let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Queue Schedule Item</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="scheduleQueueItemForm">
            <div>
                <label for="SCHEDULE_QUEUE_ITEM_CLIENT">Client</label>
                <input *ngIf="clientTypeaheadInput" id="SCHEDULE_QUEUE_ITEM_CLIENT" type="text" class="form-control" formControlName="client" placeholder="Type to Search" [ngbTypeahead]="clientTypeaheadInput" (focus)="clientTypeaheadInputFocus$.next($any($event).target.value)" [resultFormatter]="clientResultFormatter" (selectItem)="selectClient($event);">
            </div>
            <div *ngIf="projects.length > 0" class="mt-3">
                <label for="SCHEDULE_QUEUE_ITEM_PROJECT">Project</label>
                <select id="SCHEDULE_QUEUE_ITEM_PROJECT" class="form-select" formControlName="project">
                    <option *ngFor="let project of projects" [value]="project.id">{{project.name}}</option>
                </select>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="addScheduleItem();" [disabled]="projects.length == 0">Save</button>
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
    </div>
</ng-template>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
    <div ngbDropdown class="d-inline-block" [autoClose]="true">
        <button class="btn btn-outline-primary" ngbDropdownToggle ngbTooltip="Sort">
            <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
        </button>
        <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="sort('sc.schedule_item_created_at');">
                Created
                <fa-icon *ngIf="sortBy == 'sc.schedule_item_created_at'" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
            </button>
            <button ngbDropdownItem (click)="sort('c.client_name');">
                Client
                <fa-icon *ngIf="sortBy == 'c.client_name'" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
            </button>
        </div>
    </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
    <div ngbDropdown class="d-inline-block" autoClose="outside" placement="bottom-left">
        <span *ngIf="activeFilterCount > 0" class="custom-badge-lg">{{activeFilterCount}}</span>
        <button class="btn btn-outline-primary" ngbDropdownToggle ngbTooltip="Filter">
            <fa-icon [icon]="faFilter"></fa-icon>
        </button>
        <div ngbDropdownMenu>
            <!-- Color -->
            <button ngbDropdownItem (click)="this.getDistinctColumnValues('cc.hex_color'); colorCollapse.toggle();">Color</button>
            <div class="px-4 py-1" #colorCollapse="ngbCollapse" [ngbCollapse]="true">
                <div *ngFor="let element of columnFilterValues.get('cc.hex_color');" class="form-check form-check-inline">
                    <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter('cc.hex_color', element.value);" [checked]="filterExists('cc.hex_color', element.value)">
                    <label [for]="element.randomId" class="form-check-label">
                        <fa-icon [icon]="faCircle" class="my-auto countyColorIcon" size="xs" [style.color]="element.value"></fa-icon>
                    </label>
                </div>
            </div>
            <!-- Client -->
            <button ngbDropdownItem (click)="this.getDistinctColumnValues('c.client_name'); clientCollapse.toggle();">Client</button>
            <div class="px-4 py-1" #clientCollapse="ngbCollapse" [ngbCollapse]="true">
                <div class="d-flex flex-column">
                    <div *ngFor="let element of columnFilterValues.get('c.client_name');" class="form-check form-check-inline">
                        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter('c.client_name', element.value);" [checked]="filterExists('c.client_name', element.value)">
                        <label [for]="element.randomId" class="form-check-label" style="white-space: nowrap;">{{element.value}}</label>
                    </div>
                </div>
            </div>
            <!-- Divider -->
            <div class="dropdown-divider"></div>
            <!-- Reset -->
            <button ngbDropdownItem (click)="resetActiveFilters();">Reset</button>
        </div>
    </div>
</ng-template>
