<div class="row mt-2" id="settingsStepper">
    <div class="col-lg-12 mt-lg-0 mt-2">
        <div class="card">
            <div class="card-header">
                <h5 class="my-auto">A2P Registration
                    <button type="button" class="btn btn-primary ms-auto" [ngbPopover]="a2pDescription"
                        triggers="manual" autoClose="outside" #a2pPopOver="ngbPopover"
                        (click)="$event.stopPropagation(); popOverOptions(a2pPopOver, 2);">
                        <fa-icon [icon]="faQuestion"></fa-icon>
                    </button>
                </h5>
            </div>
            <div class="card-body bg-a2p">
                <div class="col-lg-12">
                    <div id="stepperA2P" class="bs-stepper">
                        <div class="bs-stepper-header">
                            <div class="step" data-target="#number">
                                <button class="step-trigger">
                                    <span class="bs-stepper-circle">
                                        <ng-container *ngIf="!orgNumbers && orgNumbers.length == 0; else checkDone">
                                            <span>1</span>
                                        </ng-container>
                                    </span>
                                    <span class="bs-stepper-label">Show Numbers</span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#business-form">
                                <button class="step-trigger">
                                    <span class="bs-stepper-circle">
                                        <ng-container
                                            *ngIf="!(subAccountDetails && subAccountDetails.a2pDetails.step1); else checkDone">
                                            <span>2</span>
                                        </ng-container>
                                    </span>
                                    <span class="bs-stepper-label">Business Details</span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#contact-form">
                                <button class="step-trigger">
                                    <span class="bs-stepper-circle">3</span>
                                    <span class="bs-stepper-label">Authorized Representative</span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#address-form">
                                <button class="step-trigger">
                                    <span class="bs-stepper-circle">4</span>
                                    <span class="bs-stepper-label">Business Address</span>
                                </button>
                            </div>
                            <div class="line"></div>
                            <div class="step" data-target="#review-form">
                                <button class="step-trigger">
                                    <span class="bs-stepper-circle">5</span>
                                    <span class="bs-stepper-label">Summary and Agreement</span>
                                </button>
                            </div>
                        </div>
                        <div class="bs-stepper-content">
                            <div id="number" class="content">
                                <ng-container [ngTemplateOutlet]="numberStep"></ng-container>
                            </div>
                            <div id="business-form" class="content">
                                <app-business-identity-form></app-business-identity-form>
                            </div>
                            <div id="contact-form" class="content">
                                <app-contact-person-form></app-contact-person-form>
                            </div>
                            <div id="address-form" class="content">
                                <app-business-address-form></app-business-address-form>
                            </div>
                            <div id="review-form" class="content">
                                <ng-container [ngTemplateOutlet]="agreement"></ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #checkDone>
    <fa-icon [icon]="faCheck"></fa-icon>
</ng-template>
<ng-template #a2pDescription>
    <p>
        A2P registration is a verification process that US mobile phone carriers require to ensure that
        text messages sent from businesses to customers are legitimate. A2P stands for “application to phone,”
        meaning that this registration is intended for businesses that use software to send bulk SMS texts to their
        audience.
        Because most, if not all, companies probably use a text
        marketing platform (aka the “software”) to reach individuals through this channel, A2P registration applies in
        most cases.
    </p>
</ng-template>
<ng-template #brandDescription>
    <p class="mb-0">
        In this step, provide information about who is sending these messages so that carriers know that the number are
        a legitimate sender
    </p>
</ng-template>
<ng-template #campaignDescription>
    <p class="mb-0">
        In this step, provide information about how end-users can opt-in, opt-out, and receive help. It also involves
        providing a
        description of the purpose of your messages
    </p>
</ng-template>

<ng-template #numberStep>
    <div class="row mt-2">
        <div class="col-lg-12 mb-3">
            <div style="min-height: 360px;" class="card">
                <div class="card-header d-flex">
                    <h5 class="my-auto">Numbers
                    </h5>
                    <button (click)="openBuyNumber()" type="button" class="btn btn-primary ms-auto"
                        aria-label="Add Number">
                        <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
                        <span>Add</span>
                    </button>
                </div>
                <div class="card-body">
                    <div class="table-responsive mt-3">
                        <ng-container *ngIf="orgNumbers && orgNumbers.length !=0; else noNumbers">
                            <app-numbers [phoneNumbers]="orgNumbers"></app-numbers>
                        </ng-container>
                        <ng-template #noNumbers>
                            <div class="font-italic text-muted text-center">No number under your account</div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #agreement>
    <div class="row mx-auto">
        <p class="text-italic text-md text-800">
            I hereby consent to the collection, use, and sharing of the information provided in this application for the
            purpose of processing and vetting for A2P (Application-to-Person) registration. I understand that this process
            may include background checks and other necessary verification steps. Furthermore, I acknowledge that the
            organization is not liable for the approval or denial of my application.
        </p>
        <button type="button" class="btn btn-primary ms-2" (click)="submitForReview()">
            <span>Agree and Submit my Application</span>
        </button>
    </div>
    
</ng-template>