import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert.service';
import { Payment } from '../payment';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-payment-refund-modal',
  templateUrl: './payment-refund-modal.component.html',
  styleUrls: ['./payment-refund-modal.component.css']
})
export class PaymentRefundModalComponent implements OnInit {

  // Properties
  @Input() payment: Payment;
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();
  refundForm: UntypedFormGroup;

  remainingAmount: number = 0;
  remainingSurcharge: number = 0;
  remainingTotal: number = 0;

  constructor(private paymentService: PaymentService,
    private alertService: AlertService,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.refundForm = new UntypedFormGroup({
      amount: new UntypedFormControl(),
      surcharge: new UntypedFormControl(),
      total: new UntypedFormControl(),
      note: new UntypedFormControl()
    });
    this.refundForm.controls.surcharge.disable();
    this.refundForm.controls.total.disable();
    this.listenForRefundAmountChanges();
    this.getRemainingPaymentAmount();
  }

  // Listen For Refund Amount Changes
  private listenForRefundAmountChanges(): void {
    this.refundForm.controls.amount.valueChanges.subscribe((value) => {
      if (this.payment.surcharge === 0) return;
      const refundSurcharge = this.calculateSurcharge(value);
      this.refundForm.controls.surcharge.setValue(refundSurcharge);
      this.refundForm.controls.total.setValue(value + refundSurcharge);
    });
  }

  // Get Remaining Payment Amount
  private getRemainingPaymentAmount(): void {
    this.paymentService.getRemainingPaymentAmount(this.payment.id).subscribe((remainingPaymentAmount) => {
      this.remainingAmount = remainingPaymentAmount;
      this.remainingSurcharge = this.calculateSurcharge(this.remainingAmount);
      this.remainingTotal = this.remainingAmount + this.remainingSurcharge;
    });
  }

  // Process Refund
  processRefund(): void {
    if (this.refundForm.valid) {
      const data: any = {
        amount: this.refundForm.controls.amount.value,
        surcharge: this.refundForm.controls.surcharge.value,
        total: this.refundForm.controls.total.value,
        note: this.refundForm.controls.note.value
      };
      this.paymentService.processRefund(this.payment.id, data).subscribe(() => {
        this.alertService.showSuccessAlert('Refund Added');
        this.saved.emit();
        this.modal.close();
      });
    } else {
      this.refundForm.markAllAsTouched();
    }
  }

  // Refund Form Accessors
  get amount() { return this.refundForm.controls.amount; }
  get note() { return this.refundForm.controls.note; }

  /* ----- Helper Functions ----- */

  // Calculate Surcharge
  private calculateSurcharge(amount: number | string): number {
    if (typeof amount == 'string') amount = parseFloat(amount);
    const passFeePercent = environment.tinselPay.passFeePercent;
    return this.round(amount * (passFeePercent / 100), 2);
  }

  // Round
  private round(value: number, precision: number = 0): number {
    var multiplier = Math.pow(10, precision);
    return Math.round(value * multiplier) / multiplier;
  }
}
