import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailLog } from '../email-log';

@Component({
  selector: 'app-email-view',
  templateUrl: './email-view.component.html',
  styleUrls: ['./email-view.component.css']
})
export class EmailViewComponent implements OnInit {

  // Inputs and Outputs
  @Input() emailLog: EmailLog;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
    
  }
}
