<div class="modal-header">
    <h5 class="modal-title">Upload</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <div class="uploading-notes"><strong>Note: </strong>The file name must consist of alphanumeric characters. Hyphens, dashes, underscores, dots, parenthesis and spaces are allowed.</div>
    <p *ngIf="files.length == 0" style="margin: 0;">No Files Selected</p>
    <div *ngFor="let file of files; index as i;" class="row" [class.mt-2]="i > 0">
        <div class="input-group p-0">
            <input type="text" class="form-control" [value]="file.name" name="FILE_NAME">
            <button type="button" class="btn btn-danger" (click)="removeFile(i);" ngbTooltip="Remove">
                <fa-icon [icon]="faTimes"></fa-icon>
            </button>
        </div>
    </div>
    <ngb-progressbar *ngIf="uploadTotal != 0" class="mt-3" type="primary" [value]="uploadLoaded" [max]="uploadTotal" height="20px" [striped]="true" [showValue]="true"></ngb-progressbar>
</div>
<div class="modal-footer">
    <input id="FILE_INPUT" type="file" style="display: none;" multiple (change)="fileInputChanged($event.target.files);">
    <button type="button" class="btn btn-primary me-auto" (click)="browseFiles();" [disabled]="uploadTotal != 0">Browse</button>
    <button type="button" class="btn btn-primary" (click)="uploadFiles();" [disabled]="files.length == 0 || uploadTotal != 0">Upload</button>
    <button type="button" class="btn btn-secondary" (click)="cancelUploads();">Cancel</button>
</div>
