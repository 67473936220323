import { Component, OnInit } from '@angular/core';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';

@Component({
  selector: 'app-project-prep-overview',
  templateUrl: './project-prep-overview.component.html',
  styleUrls: ['./project-prep-overview.component.css']
})
export class ProjectPrepOverviewComponent implements OnInit {
  
  // Font Awesome Properties
  faAngleLeft = faAngleLeft;

  constructor(public location: Location) { }

  ngOnInit(): void {

  }
}
