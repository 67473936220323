import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectPrepService {

  constructor(private http: HttpClient) { }

  // Get Project Prep Items
  getProjectPrepItems(params: any): Observable<{ projectPrepItems: any[], count: number }> {
    return this.http.get<{ projectPrepItems: any[], count: number }>(`/projectPrep/items`, {
      params: params
    });
  }

  // Get Distinct Column Values
  getDistinctColumnValues(params: any): Observable<string[]> {
    return this.http.get<string[]>(`/projectPrep/distinctColumnValues`, {
      params: params
    });
  }
}
