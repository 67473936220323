import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { FormGroup, FormGroupDirective, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, fromEvent, lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { FirstInvoice, Proposal } from '../proposal';
import { ProposalService } from '../proposal.service';
import { SettingService } from '../setting.service';
import { AlertService } from '../alert.service';
import { SendEmailComponent } from '../send-email/send-email.component';
import { NgbDateParserFormatter, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faAngleLeft, faSave, faEye, faTrashCan, faEnvelope, faTimes, faCheck, faDollarSign, faCalendar, faPlus, faPencil, faQuestionCircle, faTriangleExclamation, faSms } from '@fortawesome/free-solid-svg-icons';
import { AcceptProposalComponent } from '../accept-proposal/accept-proposal.component';
import { Location } from '@angular/common';
import { ProposalTerm } from '../proposal-term';
import { Tax } from '../tax';
import { ProposalPricing } from '../proposal-pricing';
import { ProjectService } from '../project.service';
import { ProposalMultiAgreementTypeOptionsModalComponent } from '../proposal-multi-agreement-type-options-modal/proposal-multi-agreement-type-options-modal.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { ClientService } from '../client.service';
import { ProposalInteraction } from '../proposal-interaction';
import { ProposalBuilderComponent } from '../proposal-builder/proposal-builder.component';
import { ProposalRejectModalComponent } from '../proposal-reject-modal/proposal-reject-modal.component';
import { ProposalExcessModalComponent } from '../proposal-excess-modal/proposal-excess-modal.component';
import { LoaderService } from '../shared/services/loader.service';
import { ConversationService } from '../conversation.service';
import { SendSmsDialogComponent } from '../conversation/components/send-sms-dialog/send-sms-dialog.component';

@Component({
  selector: 'app-proposal-detail',
  templateUrl: './proposal-detail.component.html',
  styleUrls: ['./proposal-detail.component.css']
})
export class ProposalDetailComponent implements OnInit, OnDestroy {
  req$ = new Subscription();
  // Modals
  private modalReference: NgbModalRef;
  @ViewChild('proposalBuilder') private proposalBuilder: ProposalBuilderComponent;
  @ViewChild('ALL_PROPOSALS_REJECTED_MODAL') private allProposalsRejectedModal: NgbModalRef;
  @ViewChild('invoiceForm') invoiceForm: FormGroupDirective;

  // Proposal
  proposalId: string;
  proposal: Proposal;
  proposalForm: UntypedFormGroup;
  firstInvoiceForm: UntypedFormGroup;
  taxes: Tax[] = [];
  proposalTerms: ProposalTerm[] = [];
  proposalPricing: ProposalPricing;
  proposalInteractions: ProposalInteraction[] = [];
  showAdditionalInteractionData: boolean = false;
  proposalBuilderSubscription!: Subscription;
  amountType: string = 'PERCENT';
  isAccepted: boolean = false;
  invoiceRequired: boolean = true;

  // Multi Options
  multiOptionKeys: any[] = [];
  selectedMultiOptionKey: string;
  selectedMultiOptionPricing: any;

  // Proposal Rejected Modal
  // proposalRejectedModalForm: FormGroup;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faSave = faSave;
  faEye = faEye;
  faEnvelope = faEnvelope;
  faTrashCan = faTrashCan;
  faTimes = faTimes;
  faCheck = faCheck;
  faDollarSign = faDollarSign;
  faCalendar = faCalendar;
  faPlus = faPlus;
  faPencil = faPencil;
  faSms = faSms;
  faQuestionCircle = faQuestionCircle;
  faTriangleExclamation = faTriangleExclamation;
  twilioClient: any;

  componentDestroyed$: Subject<boolean> = new Subject()
  currentUserNumber = '+14782177074';

  constructor(private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private proposalService: ProposalService,
    private clientService: ClientService,
    private alertService: AlertService,
    private settingService: SettingService,
    private conversationSerivce: ConversationService,
    private modalService: NgbModal,
    public location: Location,
    private dateParserFormatter: NgbDateParserFormatter,
    private loaderService: LoaderService) {
    this.route.paramMap.subscribe((res: any) => {
      this.proposalId = res.params.proposalId;
      if (this.proposalId) {
        this.reloadData();
      }
    });

    // this.router.events.forEach((event) => {
    //   if (event instanceof NavigationStart) {
    //     if (event.navigationTrigger === 'popstate') {
    //       console.log('BACK')
    //       console.log(event);
    //       // this.router.navigateByUrl(`/projects/${this.proposal.projectId}`, { replaceUrl: true });
    //     }
    //   }
    // });
  }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   console.log('Back button pressed');
  //   history.replaceState(null, null, `/projects/${this.proposal.projectId}`);

  //   // history.pushState(null, null, `/projects/${this.proposal.projectId}`);
  // }

  ngOnInit(): void {
    this.proposalForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      tax: new UntypedFormControl(),
      discountType: new UntypedFormControl('PERCENT'),
      discount: new UntypedFormControl(0),
      agreementType: new UntypedFormControl('SINGLE'),
      term: new UntypedFormControl(),
      expirationDate: new UntypedFormControl(),
      taxMultiplierName: new UntypedFormControl()
    });
    this.proposalForm.controls.tax.disable();
    // this.proposalRejectedModalForm = new UntypedFormGroup({
    //   updateProjectStatus: new UntypedFormControl(false),
    //   updateClientStatus: new UntypedFormControl(false)
    // });
    this.firstInvoiceForm = new UntypedFormGroup({
      amount: new UntypedFormControl(),
      amountType: new UntypedFormControl(),
      daysUntilDue: new UntypedFormControl(0)
    });

    // this.req$.add(this.location.subscribe(
    //   ((value: PopStateEvent) => {
    //     console.log(value);
    //     this.router.navigateByUrl(`/projects/${this.proposal.projectId}`, { replaceUrl: true });
    //   }),
    //   (ex => {
    //     console.log("Error occured postate event")
    //     console.log(ex);
    //   })
    // ))

    this.reloadData();

    this.conversationSerivce.twilioClient
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.twilioClient = data;
    });
  }

  ngOnDestroy(): void {
    this.req$.unsubscribe();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Delete Proposal Confirmation Modal
  openDeleteProposalConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this proposal?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteProposal();
    });
  }

  // Open Send Email Modal
  openSendEmailModal(): void {
    if (this.firstInvoiceForm.valid || this.firstInvoiceForm.disabled) {
      const modalRef = this.modalService.open(SendEmailComponent);
      modalRef.componentInstance.clientId = this.proposal.clientId;
      modalRef.componentInstance.proposalId = this.proposal.id;
      modalRef.componentInstance.type = 'PROPOSAL';
      modalRef.componentInstance.subject = `${this.proposal.projectName} Proposal`;
      modalRef.componentInstance.showAttachments = true;
    } else {
      this.alertService.showWarningAlert('First Invoice amount must not be equal to zero');
      this.firstInvoiceForm.markAllAsTouched();
    }

  }

  openSendSmsModal():void {
    if (this.firstInvoiceForm.valid || this.firstInvoiceForm.disabled) {
      this.loaderService.showSpinner();
      this.twilioClient.getUser(this.currentUserNumber).then(res => {
        if (res) {
          this.loaderService.hideSpinner();
          const modalRef = this.modalService.open(SendSmsDialogComponent);
          modalRef.componentInstance.clientId = this.proposal.clientId;
          modalRef.componentInstance.proposalId = this.proposal.id;
          modalRef.componentInstance.contact = this.proposal.contacts;
          modalRef.componentInstance.clientName = this.proposal.clientName;
          modalRef.componentInstance.twilioClient = this.twilioClient;
          modalRef.componentInstance.defaultMessage = 'You have a new Christmas Light Proposal from <OrgName>! Click here to see your proposal now! <LINK>';
          modalRef.componentInstance.sent.subscribe((res) => {
            this.alertService.showSuccessAlert('Message sent');
          });
        }
      }).catch(err => {
        console.log(err)
        this.loaderService.hideSpinner();
        this.alertService.showWarningAlert('User not found in Twilio');
      });
    } else {
      this.alertService.showWarningAlert('First Invoice amount must not be equal to zero');
      this.firstInvoiceForm.markAllAsTouched();
    }
  }

  // Open Accept Proposal Modal
  openAcceptProposalModal(): void {
    if (this.firstInvoiceForm.valid || this.firstInvoiceForm.disabled) {
      this.updateProposal();

      const firstInvoiceAmount = this.proposal.amountType == 'DOLLAR' ? this.proposal.amount : (this.proposal.amount / 100) * this.proposal.initialAcceptedPrice;

      const modalRef = this.modalService.open(AcceptProposalComponent);
      modalRef.componentInstance.proposal = this.proposal;
      modalRef.componentInstance.firstInvoice = {
        ...this.firstInvoiceForm.value
      };
      modalRef.componentInstance.saved.subscribe((res: any) => {
        this.reloadData();

        if (firstInvoiceAmount > this.proposalPricing.total && res.invoiceNumber) {
          this.openInvoiceGreaterThanProposalAmount(res.invoiceNumber, res.invoiceId);
        }

      });
    } else {
      this.alertService.showWarningAlert('First Invoice amount must not be equal to zero');
      this.firstInvoiceForm.markAllAsTouched();
    }
  }

  openInvoiceGreaterThanProposalAmount(invoiceNumber: number, invoiceId: string) {
    const modalRef = this.modalService.open(ProposalExcessModalComponent);
    modalRef.componentInstance.invoiceNumber = invoiceNumber;
    modalRef.componentInstance.choice.subscribe((action) => {
      if (action == 1) {
        this.router.navigateByUrl(`/invoices/${invoiceId}`, { replaceUrl: true });
      }
    });
  }

  // Open Proposal Multi Agreement Type Options Modal
  openProposalMultiAgreementTypeOptionsModal(): void {
    const modalRef = this.modalService.open(ProposalMultiAgreementTypeOptionsModalComponent);
    modalRef.componentInstance.proposalId = this.proposal.id;
    modalRef.componentInstance.multiOptions = this.proposal.multiOptions;
    modalRef.componentInstance.multiOptionsUpdated.subscribe(() => {
      this.reloadData();
    });
  }

  // Multi Option Is Enabled
  multiOptionIsEnabled(key: string): boolean {
    return this.proposal.multiOptions[key].isEnabled;
  }

  // Reload Data
  private async reloadData(): Promise<void> {
    this.proposal = await lastValueFrom(this.proposalService.getProposal(this.proposalId));
    if (this.proposalBuilder) {
      this.proposalBuilder._proposal = this.proposal;
      await this.proposalBuilder.getFeaturesAndFeatureItems();
    };
    this.proposalPricing = await lastValueFrom(this.proposalService.calculateProposalPricing(this.proposalId));
    this.proposalInteractions = await lastValueFrom(this.proposalService.getProposalInteractions(this.proposalId));
    this.taxes = await lastValueFrom(this.settingService.getTaxes());
    this.proposalTerms = await lastValueFrom(this.settingService.getProposalTerms());
    // Multi Options
    if (this.proposal.agreementType == 'MULTI') {
      this.multiOptionKeys = Object.keys(this.proposal.multiOptions);
      this.selectedMultiOptionKey = this.multiOptionKeys[0];
    }

    // Prepare Proposal Form
    this.setProposalDetailFormValues();

    // Proposal Builder
    // if (this.proposalBuilderSubscription) return;
    // this.proposalBuilderSubscription = this.proposalBuilder.saved.subscribe(() => {
    //   this.reloadData();
    // });

    this.router.events.pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (event instanceof NavigationStart) {
          if (event.navigationTrigger === 'popstate') {
            // history.pushState(null, null, `/projects/${this.proposal.projectId}`);
            history.replaceState(null, null, `/projects/${this.proposal.projectId}`);

          }
        }
        //Do something with the NavigationEnd event object.
      });
  }

  // Set Proposal Detail Form Values
  private setProposalDetailFormValues(): void {
    this.proposalForm.controls.name.setValue(this.proposal.name);
    this.proposalForm.controls.tax.setValue(this.proposal.taxMultiplier * 100);
    this.proposalForm.controls.taxMultiplierName.setValue(this.proposal.taxMultiplierName);
    this.proposalForm.controls.discountType.setValue(this.proposal.discountType);
    this.proposalForm.controls.discount.setValue(this.proposal.discount);
    this.proposalForm.controls.agreementType.setValue(this.proposal.agreementType);
    this.proposalForm.controls.term.setValue(this.proposal.termId);
    this.proposalForm.controls.expirationDate.setValue(this.dateParserFormatter.parse(this.proposal.expirationDate));
    this.isAccepted = this.proposal.status == 'ACCEPTED' || this.proposal.status == 'MODIFIED';
    this.invoiceRequired = this.proposal.isInvoiceRequired
    this.firstInvoiceForm.controls.amount.setValue(this.proposal.amount);
    this.firstInvoiceForm.controls.daysUntilDue.setValue(this.proposal.daysUntilDue || 0);
    this.amountType = this.proposal.amountType ? this.proposal.amountType : this.amountType;
    this.firstInvoiceForm.controls.amountType.setValue(this.amountType);

    if (this.isAccepted) {
      this.proposalForm.disable();
      this.firstInvoiceForm.disable();
      this.proposalBuilder.featureForms.disable();
      this.proposalBuilder.featureItemForm.disable();
      this.proposalBuilder.newFeatureForm.disable();
      this.proposalBuilder.isAccepted = true;
    } else {
      this.proposalForm.enable();
      this.proposalForm.controls.tax.disable();
      this.firstInvoiceForm.enable();
      this.proposalBuilder.featureForms.enable();
      this.proposalBuilder.featureItemForm.enable();
      this.proposalBuilder.newFeatureForm.enable();
      this.proposalBuilder.isAccepted = false;
    }

    if (this.proposal.status == 'PENDING' && this.proposal.rowNumber > 1) {
      this.firstInvoiceForm.disable();
      this.proposalBuilder.featureForms.enable();
      this.proposalBuilder.featureItemForm.enable();
      this.proposalBuilder.newFeatureForm.enable();
      this.proposalBuilder.isAccepted = false;
    }

    if (this.invoiceRequired && !this.isAccepted) {
      this.firstInvoiceForm.get('amount').enable();
      this.firstInvoiceForm.get('daysUntilDue').enable();
      this.firstInvoiceForm.controls.amount.setValidators([Validators.required, Validators.min(1)]);
      this.firstInvoiceForm.controls.daysUntilDue.setValidators([Validators.required, Validators.min(0)]);
    } else {
      this.firstInvoiceForm.controls.amount.setValidators(null);
      this.firstInvoiceForm.get('amount').disable();
      this.firstInvoiceForm.get('daysUntilDue').disable();
    }
  }

  updateInvoiceRequired(event) {
    this.invoiceRequired = event.target.checked;

    if (this.invoiceRequired) {
      this.firstInvoiceForm.get('amount').enable();
      this.firstInvoiceForm.get('daysUntilDue').enable();
      this.firstInvoiceForm.controls.amount.setValidators([Validators.required, Validators.min(1)]);
      this.firstInvoiceForm.controls.daysUntilDue.setValidators([Validators.required, Validators.min(0)]);
    } else {
      this.firstInvoiceForm.controls.amount.setValidators(null);
      this.firstInvoiceForm.get('amount').disable();
      this.firstInvoiceForm.get('daysUntilDue').disable();
    }
    this.updateProposal();
  }

  // Update Proposal
 async updateProposal() {
    if (this.proposalForm.valid) {
      this.loaderService.showSpinner();
      const proposal = {
        ...this.firstInvoiceForm.value,
        id: this.proposalId,
        name: this.proposalForm.value.name,
        taxPercent: this.proposalForm.get('tax').value,
        discountType: this.proposalForm.value.discountType,
        discount: this.proposalForm.value.discount,
        agreementType: this.proposalForm.value.agreementType,
        termId: this.proposalForm.value.term,
        taxMultiplierName: this.proposalForm.value.taxMultiplierName,
        expirationDate: this.dateParserFormatter.format(this.proposalForm.value.expirationDate),
        amountType: this.amountType,
        isInvoiceRequired: this.invoiceRequired
      };

      this.proposalService.updateProposal(proposal).subscribe({
        next:async() => {
          this.alertService.showSuccessAlert('Proposal Updated');
          this.reloadData();
          this.loaderService.hideSpinner(1000);
        },
        error: () => {
          this.loaderService.hideSpinner(1000);
        }
      });
    } else {
      this.proposalForm.markAllAsTouched();
    }

  }

  // Modify Proposal
  private async modifyProposal(): Promise<void> {
    const data: any = {
      proposalId: this.proposal.id,
      projectId: this.proposal.projectId,
      name: this.proposal.name,
      taxPercent: this.proposal.taxMultiplier * 100,
      taxMultiplierName: this.proposal.taxMultiplierName
    };
    this.proposalService.modifyProposal(data).subscribe((proposalId) => {
      this.router.navigateByUrl(`/proposals/${proposalId}`);
    });
  }

  confirmModifyProposal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to modify the proposal?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Continue";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.modifyProposal();
    });
  }

  // Delete Proposal
  deleteProposal(): void {
    this.proposalService.deleteProposal(this.proposalId, this.proposal.projectId).subscribe(() => {
      this.router.navigateByUrl(`/projects/${this.proposal.projectId}`, { replaceUrl: true });
    });
  }

  // View Proposal
  viewProposal(): void {
    if (this.firstInvoiceForm.valid || this.firstInvoiceForm.disabled) {
      this.updateProposal();
      const url = this.router.serializeUrl(this.router.createUrlTree([`/proposals/${this.proposalId}/preview`]));
      window.open(url);
    } else {
      this.alertService.showWarningAlert('First Invoice amount must not be equal to zero');
      this.firstInvoiceForm.markAllAsTouched()
    }

  }

  // Reject Proposal
  // async rejectProposal(): Promise<void> {
  //   await lastValueFrom(this.proposalService.rejectProposal(this.proposalId, false));
  //   this.alertService.showSuccessAlert('Proposal Rejected');
  //   this.reloadData();
  //   const projectProposals = await this.getProposalsForProject();
  //   const shouldPrompt = projectProposals.reduce((acc, proposal) => { return acc && proposal.status == 'REJECTED' }, true);
  //   if (shouldPrompt) this.openAllProposalsRejectedModal();
  // }

  // Open All Proposals Rejected Modal
  openAllProposalsRejectedModal(): void {
    const rejectModal = this.modalService.open(ProposalRejectModalComponent);
    rejectModal.componentInstance.clientId = this.proposal.clientId;
    rejectModal.componentInstance.saved.subscribe((res: any) => {
      this.applyAllProposalsRejectedUpdates(res.updateProjectStatus, res.updateClientStatus);
    });
  }

  // Select Tax
  selectTax(tax: Tax): void {
    this.proposalForm.controls.tax.setValue(tax.multiplier * 100);
    this.proposalForm.controls.taxMultiplierName.setValue(tax.name);
    console.log(this.proposalForm)
    this.updateProposal();
  }

  // Get Proposals For Project
  async getProposalsForProject(): Promise<Proposal[]> {
    const params = {
      searchTerm: null,
      sortBy: 'proposal_name',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:prop.project_id': JSON.stringify([this.proposal.projectId])
    };
    const res = await lastValueFrom(this.proposalService.getProposals(params));
    return res.proposals;
  }

  // Apply All Proposals Rejected Updates
  async applyAllProposalsRejectedUpdates(updateProjectStatus: boolean, updateClientStatus: boolean): Promise<void> {
    // if (this.proposalRejectedModalForm.valid) {
    let anyUpdated = false;
    if (updateProjectStatus) {
      anyUpdated = true;
      await lastValueFrom(this.projectService.updateProjectStatus(this.proposal.projectId, 'LOST'));
    }
    if (updateClientStatus) {
      anyUpdated = true;
      await lastValueFrom(this.clientService.updateClientStatus(this.proposal.clientId, 'INACTIVE'));
    }
    this.proposalService.rejectProposal(this.proposalId, this.proposal.projectId, false)
      .pipe().subscribe(res => {
        if (res) this.alertService.showSuccessAlert('Proposal Rejected');
        this.reloadData();
      });

    // if (anyUpdated) this.alertService.showSuccessAlert('Statuses Updated');
    // this.modalReference.close();
    // } else {
    //   this.proposalRejectedModalForm.markAllAsTouched();
    // }
  }

  // Toggle Show Additional Interaction Data
  toggleShowAdditionInteractionData(): void {
    this.showAdditionalInteractionData = !this.showAdditionalInteractionData;
  }

  // Proposal Form Accessors
  get name() { return this.proposalForm.controls.name; }
  get tax() { return this.proposalForm.controls.tax; }
  get discount() { return this.proposalForm.controls.discount; }
  get expirationDate() { return this.proposalForm.controls.expirationDate; }
  get amount() { return this.firstInvoiceForm.controls.amount; }
  get daysUntilDue() { return this.firstInvoiceForm.controls.daysUntilDue; }

}
