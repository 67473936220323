<div class="table-responsive mt-2">
    <table *ngIf="!isMobile">
        <thead>
            <tr>
                <th>Vault ID</th>
                <th>Customer</th>
                <th>Routing #</th>
                <th>Account #</th>
                <th>Account Type</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let vault of vaults">
                <td data-label="Vault ID">{{vault.id}}</td>
                <td data-label="Customer">{{vault.customer.name}}</td>
                <td data-label="Routing #">{{vault.bank.routingNumber}}</td>
                <td data-label="Account #">{{vault.bank.maskedAccountNumber}}</td>
                <td data-label="Account Type">{{vault.bank.accountType}}</td>
                <td data-label="Status">{{(vault.isActive) ? 'Active' : 'Inactive'}}</td>
            </tr>
        </tbody>
    </table>

    <div class="scrollable" *ngIf="isMobile">
      <ul class="list-group">
          <li *ngFor="let client of clients" class="list-group-item">
              <div class="d-flex list-group-container">
                  <div>
                      <strong>{{vault.id}}</strong>
                      <div>{{vault.customer.name}}</div>
                      <div><strong>Routing #: </strong>{{vault.bank.routingNumber}}</div>
                      <div><strong>Account #: </strong>{{vault.bank.maskedAccountNumber}}</div>
                      <div><strong>Account Type: </strong>{{vault.bank.accountType}}</div>
                      <div><strong>Status: </strong>{{(vault.isActive) ? 'Active' : 'Inactive'}}</div>
                  </div>
              </div>
          </li>
          <li *ngIf="clients.length == 0" class="list-group-item">No Vault</li>
      </ul>
    </div>
</div>
