import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { User } from './user';
import { UserRole } from './user-role';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // Properties
  userAuthenticated = new Subject<boolean>();
  userAuthenticated$ = this.userAuthenticated.asObservable();

  constructor(private http: HttpClient,
    private router: Router) { }

  // Login
  login(email: string, password: string): Observable<any> {
    return this.http.post<any>(`/auth/login`, { email: email, password: password });
  }

  // Logout
  logout(): void {
    this.http.get<any>(`/auth/logout`).subscribe(() => {
      this.userAuthenticated.next(false);
      sessionStorage.removeItem('CurrentUser');
      localStorage.removeItem('login')
      this.router.navigateByUrl('/login', { replaceUrl: true });
    });
  }

  // Get Current User
  async getCurrentUser(): Promise<User> {
    const userSessionStorageItem = sessionStorage.getItem('CurrentUser');
    if (!userSessionStorageItem) {
      const user = await lastValueFrom(this.http.get<any>(`/auth/currentUser`));
      sessionStorage.setItem('CurrentUser', JSON.stringify(user));
      return user;
    } else {
      return JSON.parse(userSessionStorageItem);
    }
  }

  // Is Authenticated
  isAuthenticated(): boolean {
    const cookies = document.cookie.split(';');
    const cookieMap = new Map();
    for (const cookie of cookies) {
      cookieMap.set(cookie.trim().split('=')[0], cookie.trim().split('=')[1]);
    }
    return cookieMap.has('sessionId');
  }

  // Is Authorized
  isAuthorized(role: UserRole): boolean {
    const userLocalStorageItem = localStorage.getItem('login');
    if (userLocalStorageItem) {
      const userRole = (JSON.parse(userLocalStorageItem) as User).role;
      return (UserRole[userRole] >= role);
    } else {
      return false;
    }
  }
}
