<div class="content-container">
  <nav class="navbar navbar-white bg-white sticky-top">
    <div class="d-grid gap-2 d-flex navbar-brand">
      <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>
      <h2 class="me-md-0 me-4 mobileHeading">Site Detail</h2>
    </div>
    <div>
      <button type="button" class="btn btn-danger me-2" (click)="openDeleteSiteConfirmationModal();">
        <fa-icon class="button-icon-left" [icon]="faTrashCan"></fa-icon>
        <span>Delete</span>
      </button>
      <button type="button" class="btn btn-primary" (click)="updateSite();">
        <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
        <span>Save</span>
      </button>
    </div>
  </nav>
  <div class="content-wrap">
    <form [formGroup]="siteForm">
      <div class="row">
        <div class="col-lg-4 mt-3">
          <div class="card">
            <div class="card-header">
              <h5>General</h5>
            </div>
            <div class="card-body">
              <label for="SITE_NAME">Name</label>
              <input id="SITE_NAME" type="text" class="form-control" formControlName="name" placeholder="Residence" maxlength="250" required [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
              <div *ngIf="name.invalid && (name.dirty || name.touched)">
                <div *ngIf="name.errors.required" class="input-validation-msg">Name is required.</div>
                <div *ngIf="name.errors.maxlength" class="input-validation-msg">Name cannot exceed 250 characters.</div>
              </div>
            </div>
          </div>
          <!-- Notes -->
          <div class="mt-3">
            <app-notes [referenceId]="this.siteId" [referenceIds]="[this.siteId]" type="SITE" cardBodyHeight="209px"></app-notes>
          </div>
        </div>
        <div class="col-lg-4 mt-3">
          <div class="card" style="height: 100%;">
            <div class="card-header">
              <h5>Address</h5>
            </div>
            <div class="card-body">
              <app-address-form [form]="siteForm"></app-address-form>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-3">
          <div class="card">
            <div class="card-header">
              <h5>Projects</h5>
              <button class="btn btn-primary ms-auto" type="button" (click)="openNewProjectModal();">
                <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
                <span>New</span>
              </button>
            </div>
            <div class="card-body">
              <ul class="list-group scrollable" style="height: 352px;">
                <li *ngFor="let project of projects" class="list-group-item" style="cursor: pointer;" routerLink="/projects/{{project.id}}">{{project.name}} ({{project.stage | projectStage}})</li>
                <li *ngIf="projects.length == 0" class="list-group-item">No Projects</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>