import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Map as MapGL, LngLatBounds, NavigationControl, ScaleControl, Marker, LngLat, Popup, LngLatLike } from 'maplibre-gl';
import MapboxDraw, { DrawCreateEvent, DrawUpdateEvent } from '@mapbox/mapbox-gl-draw';
import { environment } from 'src/environments/environment';
import { ScheduleService } from '../schedule.service';
import { ScheduleItem } from '../schedule-item';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleItemModalComponent } from '../schedule-item-modal/schedule-item-modal.component';
import { faArrowCircleUp, faTools, faArrowCircleDown, faCalendarDay, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { BulkSchedulingModalComponent } from '../bulk-scheduling-modal/bulk-scheduling-modal.component';
import { lastValueFrom, Subscription } from 'rxjs';
import { SettingService } from '../setting.service';
import { FormatDateTimePipe } from '../format-date-time.pipe';
import dayjs from 'dayjs';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-schedule-map',
  templateUrl: './schedule-map.component.html',
  styleUrls: ['./schedule-map.component.css'],
  providers: [FormatDateTimePipe]
})
export class ScheduleMapComponent implements OnInit {

  // Properties
  map: MapGL;
  draw: MapboxDraw;

  calendarDateRangeChangeSubscription: Subscription;
  selectedDateControl: string = 'CALENDAR';

  unscheduledItems: ScheduleItem[] = [];
  scheduledItems: ScheduleItem[] = [];

  activeFeatures: any[] = [];
  coordMarkerMap: Map<string, Marker | Marker[]> = new Map<string, Marker | Marker[]>();
  activeMarkers: Marker[] = [];

  activeUnscheduledTypeFilters: Set<string> = new Set<string>(['INSTALL']);
  activeScheduledTypeFilters: Set<string> = new Set<string>(['INSTALL', 'MAINTENANCE', 'REMOVE']);

  @ViewChild('SCHEDULE_ITEM_CLUSTER_LIST', { static: false }) private scheduleItemClusterList: ElementRef;
  selectedClusterScheduleItems: ScheduleItem[] = [];

  organizationCoordinates: LngLat;

  routeActive: boolean = false;

  // Font Awesome Properties
  faExclamationCircle = faExclamationCircle;

  constructor(private scheduleService: ScheduleService,
    private settingService: SettingService,
    private formatDateTimePipe: FormatDateTimePipe,
    private modalService: NgbModal,
    private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.getOrganizationSettings();
    this.listenForScheduleUpdates();
    this.listenForDateSelections();
    this.listenForCalendarDateRangeChanges();
    const resizeObserver = new ResizeObserver(() => {
      if (!this.map.loaded()) {
        setTimeout(() => { this.reloadMap(); }, 100);
        return;
      }
      this.reloadMap();
    });
    resizeObserver.observe(document.getElementById('map-container'));
  }

  ngAfterViewInit(): void {
    // Initialize Map
    this.map = new MapGL({
      container: 'map',
      style: environment.apiUrl + '/maps/style-descriptor',
    });

    // Navigation Control
    this.map.addControl(new NavigationControl({ visualizePitch: true }), 'top-left');

    // Scale Control
    this.map.addControl(new ScaleControl({ maxWidth: 250, unit: 'imperial' }));

    // Draw Control
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      }
    });
    this.map.addControl(this.draw as any);
    this.map.on('draw.create', (event) => { return this.areaSelected(event); });
    // this.map.on('draw.update', (event) => { return this.areaSelected(event); });
    // this.map.on('draw.delete', (event) => { console.log(event); });

    // Load Images
    this.map.loadImage('../assets/scheduleItemIcons/arrow-up-solid.png', (error, image) => {
      if (error) throw error;
      if (!this.map.hasImage('arrow-up')) this.map.addImage('arrow-up', image);
    });

    // Resize On Load
    this.map.on('load', () => {
      this.map.resize();
      this.reloadAllData();
      this.loadRouteOnMapLoad();
    });
  }

  // Open Schedule Item Modal
  openScheduleItemModal(scheduleItem: ScheduleItem = null): void {
    const modalRef = this.modalService.open(ScheduleItemModalComponent);
    if (scheduleItem) modalRef.componentInstance.scheduleItem = scheduleItem;
  }

  // Open Bulk Scheduling Modal
  openBulkSchedulingModal(scheduleItems: ScheduleItem[]): void {
    const modalRef = this.modalService.open(BulkSchedulingModalComponent, { scrollable: true });
    modalRef.componentInstance.scheduleItems = scheduleItems;
    modalRef.componentInstance.saved.subscribe(() => { this.draw.deleteAll(); });
    modalRef.dismissed.subscribe(() => {
      this.draw.deleteAll();
    });
  }

  /* ----- Listeners ----- */

  // Listen For Schedule Updates
  private listenForScheduleUpdates(): void {
    this.scheduleService.scheduleUpdated$.subscribe(() => { this.reloadAllData(); });
  }

  // Listen For Date Selections
  private listenForDateSelections(): void {
    this.scheduleService.dateSelected.subscribe((date) => {
      this.routeActive = (date !== null);
      (date !== null) ? this.drawRoute() : this.clearRoute();
    });
  }

  // Listen For Calendar Date Range Changes
  private listenForCalendarDateRangeChanges(): void {
    this.calendarDateRangeChangeSubscription = this.scheduleService.dateRangeChanged$.subscribe(async (range) => {
      this.scheduledItems = (await this.getScheduleItemsInRange(range)).scheduleItems;
      this.generateMapFeaturesForScheduledItems();
      this.updateMarkers();
    });
  }

  /* ----- Data ----- */

  // Get Organization Settings
  private getOrganizationSettings(): void {
    this.settingService.getOrganizationSettings().subscribe((organizationSettings) => {
      this.organizationCoordinates = new LngLat(organizationSettings.location.coordinates.longitude, organizationSettings.location.coordinates.latitude);
    });
  }

  // Reload All Data
  private async reloadAllData(): Promise<void> {
    this.unscheduledItems = (await this.getScheduleItems()).scheduleItems;
    this.scheduledItems = (await this.getScheduleItemsInRange(this.scheduleService.currentDateRange)).scheduleItems;
    this.generateMapFeaturesForUnscheduledItems();
    this.generateMapFeaturesForScheduledItems();
    this.updateMarkers();
    if (this.routeActive) this.drawRoute();
  }

  // Load Route On Map Load
  private loadRouteOnMapLoad(): void {
    if (this.scheduleService.currentSelectedDate) {
      this.routeActive = true;
      this.drawRoute();
    }
  }

  // Get Schedule Items
  private getScheduleItems(): Promise<{ scheduleItems: ScheduleItem[], count: number }> {
    if (this.activeUnscheduledTypeFilters.size === 0) return Promise.resolve({ scheduleItems: [], count: 0 });
    const params = {
      searchTerm: null,
      sortBy: null,
      sortDirection: null,
      limit: null,
      offset: null,
      'filter:sc.schedule_item_type': JSON.stringify(Array.from(this.activeUnscheduledTypeFilters)),
      'filter:sc.schedule_item_status': JSON.stringify(['UNSCHEDULED'])
    };
    return lastValueFrom(this.scheduleService.getScheduleItems(params));
  }

  // Get Schedule Items In Range
  private getScheduleItemsInRange(range: { start: string, end: string }): Promise<{ scheduleItems: ScheduleItem[], count: number }> {
    if (this.activeScheduledTypeFilters.size === 0) return Promise.resolve({ scheduleItems: [], count: 0 });
    const params = {
      sortBy: 'sc.schedule_item_position',
      sortDirection: 'ASC',
      'filter:sc.schedule_item_type': JSON.stringify(Array.from(this.activeScheduledTypeFilters)),
      'filter:sc.schedule_item_status': JSON.stringify(['SCHEDULED']),
      'filter:range:sc.schedule_item_date': `${range.start}:${range.end}`
    };
    return lastValueFrom(this.scheduleService.getScheduleItems(params));
  }

  /* ----- Date Controls ----- */

  // Select Date Control
  async selectDateControl(control: string): Promise<void> {
    this.selectedDateControl = control;
    if (control == 'PICKER') {
      this.calendarDateRangeChangeSubscription.unsubscribe();
    } else {
      this.listenForCalendarDateRangeChanges();
      this.scheduledItems = (await this.getScheduleItemsInRange(this.scheduleService.currentDateRange)).scheduleItems;
      this.generateMapFeaturesForScheduledItems();
      this.updateMarkers();
    }
  }

  // Date Changed
  async dateChanged(event: { startDate: string, endDate: string, isAuto: boolean }): Promise<void> {
    this.loaderService.showSpinner();
    this.scheduledItems = (await this.getScheduleItemsInRange({ start: event.startDate, end: event.endDate })).scheduleItems;
    this.generateMapFeaturesForScheduledItems();
    this.updateMarkers();
    this.loaderService.hideSpinner(700);
  }

  /* ----- Map ----- */

  // Reload Map
  private reloadMap(): void {
    this.map.resize();
    if (!this.routeActive) this.resetBounds();
  }

  // Toggle Unscheduled Type Filter
  async toggleUnscheduledTypeFilter(type: string): Promise<void> {
    // Toggle Filter
    if (this.activeUnscheduledTypeFilters.has(type)) this.activeUnscheduledTypeFilters.delete(type);
    else this.activeUnscheduledTypeFilters.add(type);
    // Update Markers
    if (this.activeUnscheduledTypeFilters.size === 0) this.unscheduledItems.length = 0;
    else this.unscheduledItems = (await this.getScheduleItems()).scheduleItems;
    this.generateMapFeaturesForUnscheduledItems();
    this.updateMarkers();
  }

  // Is Unscheduled Type Filter Active
  isUnscheduledTypeFilterActive(type: string): boolean {
    return this.activeUnscheduledTypeFilters.has(type);
  }

  // Toggle Scheduled Type Filter
  async toggleScheduledTypeFilter(type: string): Promise<void> {
    // Toggle Filter
    if (this.activeScheduledTypeFilters.has(type)) this.activeScheduledTypeFilters.delete(type);
    else this.activeScheduledTypeFilters.add(type);
    // Update Markers
    if (this.activeScheduledTypeFilters.size === 0) this.scheduledItems.length = 0;
    else this.scheduledItems = (await this.getScheduleItemsInRange(this.scheduleService.currentDateRange)).scheduleItems;
    this.generateMapFeaturesForScheduledItems();
    this.updateMarkers();
  }

  // Is Scheduled Type Active
  isScheduledTypeActive(type: string): boolean {
    return this.activeScheduledTypeFilters.has(type);
  }

  // Generate Map Features For Unscheduled Items
  private generateMapFeaturesForUnscheduledItems(): void {
    const collection: any[] = [];
    for (const scheduleItem of this.unscheduledItems) {
      collection.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [scheduleItem.mapData.coordinates.longitude, scheduleItem.mapData.coordinates.latitude]
        },
        properties: {
          collectionId: 'Unscheduled',
          color: scheduleItem.mapData.countyColor,
          iconImage: this.getIconImage('UNSCHEDULED'),
          scheduleItem: scheduleItem
        }
      });
    }
    this.addMapCollection('Unscheduled', collection);
  }

  // Generate Map Features For Scheduled Items
  private generateMapFeaturesForScheduledItems(): void {
    const collection: any[] = [];
    for (const scheduleItem of this.scheduledItems) {
      collection.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [scheduleItem.mapData.coordinates.longitude, scheduleItem.mapData.coordinates.latitude]
        },
        properties: {
          collectionId: 'Scheduled',
          color: scheduleItem.crews && scheduleItem.crews.length > 0 ? scheduleItem.crews[0].crewColor: '#000000',
          iconImage: this.getIconImage(scheduleItem.type),
          scheduleItem: scheduleItem
        }
      });
    }
    this.addMapCollection('Scheduled', collection);
  }

  // Add Map Collection
  private addMapCollection(id: string, collection: any[]): void {
    this.activeFeatures = this.activeFeatures.filter((feature) => { return feature.properties.collectionId != id; });
    for (const coordMarkerKey of this.coordMarkerMap.keys()) {
      const coordMarkerValue = this.coordMarkerMap.get(coordMarkerKey);
      if (coordMarkerValue instanceof Marker) {
        if ((coordMarkerValue as any).collectionId == id) this.coordMarkerMap.delete(coordMarkerKey);
      } else {
        const tempArr = coordMarkerValue.filter((marker) => { return (marker as any).collectionId != id; });
        if (tempArr.length === 0) this.coordMarkerMap.delete(coordMarkerKey);
        else if (tempArr.length === 1) this.coordMarkerMap.set(coordMarkerKey, tempArr[0]);
        else this.coordMarkerMap.set(coordMarkerKey, tempArr);
      }
    }
    for (const activeMarker of this.activeMarkers) activeMarker.remove();

    for (const feature of collection) {
      this.activeFeatures.push(feature);
      const scheduleItem = feature.properties.scheduleItem;
      const popupElement = this.createPopupElement(scheduleItem);
      const popup = new Popup({ className: 'map-popup', offset: 15 }).setDOMContent(popupElement);
      const marker = new Marker(this.createMarkerElement(feature.properties.color, feature.properties.iconImage)).setLngLat(feature.geometry.coordinates).setPopup(popup);
      const markerElement = marker.getElement();
      markerElement.addEventListener('click', (event) => {
        event.stopPropagation();
        this.openScheduleItemModal(feature.properties.scheduleItem);
      });
      markerElement.addEventListener('touchend', (event) => {
        event.stopPropagation();
        this.openScheduleItemModal(feature.properties.scheduleItem);
      });
      markerElement.addEventListener('mouseenter', () => { marker.togglePopup(); });
      markerElement.addEventListener('mouseleave', () => { marker.togglePopup(); });
      (marker as any).collectionId = id;
      const coordMarkerKey = feature.geometry.coordinates[0] + '+' + feature.geometry.coordinates[1];
      if (this.coordMarkerMap.has(coordMarkerKey)) {
        const coordMarkerValue = this.coordMarkerMap.get(coordMarkerKey);
        if (coordMarkerValue instanceof Marker) {
          this.coordMarkerMap.set(coordMarkerKey, [coordMarkerValue, marker]);
        } else {
          coordMarkerValue.push(marker);
          this.coordMarkerMap.set(coordMarkerKey, coordMarkerValue);
        }
      } else {
        this.coordMarkerMap.set(coordMarkerKey, marker);
      }
    }
  }

  // Load Organization Marker
  private loadOrganizationMarker(): void {
    // Container
    const div = document.createElement('div');
    div.style.width = '30px';
    div.style.height = '30px';
    div.style.backgroundColor = 'black';
    div.style.borderRadius = '50%';
    div.style.padding = '6px';
    // Icon
    const icon = document.createElement('div');
    icon.style.mask = 'url(../assets/scheduleItemIcons/house-solid.svg) no-repeat center';
    icon.style.webkitMask = 'url(../assets/scheduleItemIcons/house-solid.svg) no-repeat center';
    icon.style.width = '100%';
    icon.style.height = '100%';
    icon.style.backgroundPosition = 'center';
    icon.style.backgroundRepeat = 'no-repeat';
    icon.style.backgroundColor = 'white'
    div.appendChild(icon);
    // Add Marker
    const marker = new Marker(div).setLngLat(this.organizationCoordinates).addTo(this.map);
    this.activeMarkers.push(marker);
  }

  // Create Marker Element
  private createMarkerElement(color: string, iconImage: string): HTMLDivElement {
    // Container
    const div = document.createElement('div');
    div.style.width = '30px';
    div.style.height = '30px';
    div.style.backgroundColor = color;
    div.style.borderRadius = '50%';
    div.style.padding = '4px';
    div.style.cursor = 'pointer';
    // Icon
    const icon = document.createElement('div');
    icon.style.mask = 'url(../assets/scheduleItemIcons/' + iconImage + ') no-repeat center';
    icon.style.webkitMask = 'url(../assets/scheduleItemIcons/' + iconImage + ') no-repeat center';
    icon.style.width = '100%';
    icon.style.height = '100%';
    icon.style.backgroundPosition = 'center';
    icon.style.backgroundRepeat = 'no-repeat';
    icon.style.backgroundColor = this.getIconColor(color);
    div.appendChild(icon);
    return div;
  }

  // Create Cluster Marker Element
  private createClusterMarkerElement(count: number): HTMLDivElement {
    const div = document.createElement('div');
    div.style.width = '30px';
    div.style.height = '30px';
    div.style.backgroundColor = 'black';
    div.style.color = 'white'
    div.style.borderRadius = '50%';
    div.style.padding = '4px';
    div.style.cursor = 'pointer';
    div.style.fontSize = '17px';
    div.style.fontWeight = 'bold';
    div.style.textAlign = 'center';
    div.innerText = count.toString();
    return div;
  }

  // Create Popup Element
  private createPopupElement(scheduleItem: ScheduleItem): HTMLDivElement {
    // Div Container
    const div = document.createElement('div');
    // Title
    const titleElement = document.createElement('strong');
    titleElement.style.fontSize = '16px'
    titleElement.style.fontWeight = '600';
    titleElement.innerText = scheduleItem.projectName;
    div.appendChild(titleElement);
    // Body Content
    const bodyContent = [scheduleItem.clientName, this.formatScheduleItemType(scheduleItem.type)];
    for (const content of bodyContent) {
      const bodyElement = document.createElement('p');
      bodyElement.classList.add('mb-0');
      bodyElement.style.fontSize = '14px';
      bodyElement.innerText = content;
      div.appendChild(bodyElement);
    }
    // Footer Element
    if (scheduleItem.status == 'UNSCHEDULED') return div;
    const footerElement = document.createElement('small');
    footerElement.classList.add('mb-0', 'text-muted');
    footerElement.style.fontSize = '12px';
    footerElement.innerText = this.formatDateTimePipe.transform(scheduleItem.date, scheduleItem.isAllDay);
    div.appendChild(footerElement);
    // Return
    return div;
  }

  // Show Schedule Item Cluster Modal
  private showScheduleItemClusterModal(coordMarkerKeySplit: any): void {
    this.selectedClusterScheduleItems = this.activeFeatures.filter((feature) => {
      return coordMarkerKeySplit[0] == feature.geometry.coordinates[0] && coordMarkerKeySplit[1] == feature.geometry.coordinates[1];
    }).map((feature) => { return feature.properties.scheduleItem; });
    this.modalService.open(this.scheduleItemClusterList);
  }

  // Update Markers
  private updateMarkers(): void {
    this.activeMarkers.length = 0;
    this.loadOrganizationMarker();
    for (const [coordMarkerKey, coordMarkerValue] of this.coordMarkerMap.entries()) {
      if (coordMarkerValue instanceof Marker) {
        coordMarkerValue.addTo(this.map);
        this.activeMarkers.push(coordMarkerValue);
      } else {
        const coordMarkerKeySplit = coordMarkerKey.split('+');
        const coordinates = [+coordMarkerKeySplit[0], +coordMarkerKeySplit[1]];
        const clusterMarkerElement = this.createClusterMarkerElement(coordMarkerValue.length);
        clusterMarkerElement.addEventListener('click', (event) => {
          event.stopPropagation();
          this.showScheduleItemClusterModal(coordMarkerKeySplit);
        });
        clusterMarkerElement.addEventListener('touchend', (event) => {
          event.stopPropagation();
          this.showScheduleItemClusterModal(coordMarkerKeySplit);
        });
        const clusterMarker = new Marker(clusterMarkerElement).setLngLat(coordinates as any).addTo(this.map);
        this.activeMarkers.push(clusterMarker);
      }
    }
    this.resetBounds();
  }

  // Reset Bounds
  private resetBounds(): void {
    const bounds = new LngLatBounds();
    for (const marker of this.activeMarkers) bounds.extend(marker.getLngLat());
    if (!bounds.isEmpty()) this.map.fitBounds(bounds, { padding: 100, maxZoom: 12 });
  }

  // Update Bounds
  private updateBounds(coordArr: number[][]): void {
    const bounds = new LngLatBounds();
    for (const coordinates of coordArr) bounds.extend(coordinates as LngLatLike);
    if (!bounds.isEmpty()) this.map.fitBounds(bounds, { padding: 100, maxZoom: 12 });
  }

  /* ----- Routing ----- */

  // Draw Route
  private drawRoute(): void {
    // Get Route Data
    const scheduleItemCoords: number[][] = this.scheduledItems.filter((scheduleItem) => {
      return dayjs(scheduleItem.date).isSame(this.scheduleService.currentSelectedDate, 'date');
    }).sort((s1, s2) => {
      return s1.position - s2.position;
    }).map((scheduleItem) => {
      return [scheduleItem.mapData.coordinates.longitude, scheduleItem.mapData.coordinates.latitude];
    });
    scheduleItemCoords.unshift([this.organizationCoordinates.lng, this.organizationCoordinates.lat]);
    // Update Bounds
    this.updateBounds(scheduleItemCoords);
    // Clear Route
    this.clearRoute();
    // Add Route Source
    this.map.addSource('Route', {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: scheduleItemCoords
        }
      }
    });
    // Add Route Layer
    this.map.addLayer({
      id: 'Route',
      type: 'line',
      source: 'Route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#888',
        'line-width': 6
      }
    });
    // Add Arrow Layer
    this.map.addLayer({
      id: 'Arrows',
      type: 'symbol',
      source: 'Route',
      layout: {
        'symbol-placement': 'line',
        'symbol-spacing': 75,
        'icon-image': 'arrow-up',
        'icon-size': 1.4,
        'icon-offset': [0, -1],
        'icon-rotate': 90
      }
    });
  }

  // Clear Route
  private clearRoute(): void {
    if (this.map.getLayer('Arrows')) this.map.removeLayer('Arrows');
    if (this.map.getLayer('Route')) this.map.removeLayer('Route');
    if (this.map.getSource('Route')) this.map.removeSource('Route');
  }

  /* ----- Drawing ----- */

  // Area Selected
  private areaSelected(event: DrawCreateEvent | DrawUpdateEvent): void {
    const coordinatePairs = (event.features[0].geometry as any).coordinates[0];
    const bounds = new LngLatBounds();
    for (const coordinates of coordinatePairs) bounds.extend(coordinates);
    const selectedScheduleItems = this.activeFeatures.filter((feature) => {
      return feature.properties.collectionId == 'Unscheduled' && bounds.contains(feature.geometry.coordinates);
    }).map((feature) => { return feature.properties.scheduleItem; });
    if (selectedScheduleItems.length > 0) this.openBulkSchedulingModal(selectedScheduleItems);
    else this.draw.deleteAll();
  }

  /* ----- Helper Functions ----- */

  // Get Icon Color
  private getIconColor(hexColor?: string): string {
    var returnColor ='#000000'
    if(hexColor){
      var r = parseInt(hexColor.substring(1, 2), 16);
      var g = parseInt(hexColor.substring(3, 2), 16);
      var b = parseInt(hexColor.substring(4, 2), 16);
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      returnColor = (yiq < 40) ? '#FFFFFF' : '#000000';
    }
    return returnColor;
  }

  // Get Icon Image
  private getIconImage(type: string): string {
    switch (type) {
      case 'INSTALL':
        return 'arrow-up-solid.svg';
      case 'REMOVE':
        return 'arrow-down-solid.svg';
      case 'MAINTENANCE':
        return 'screwdriver-wrench-solid.svg';
      case 'UNSCHEDULED':
        return 'exclamation-solid.svg';
      default:
        return 'question-solid.svg';
    }
  }

  // Format Schedule Item Type
  private formatScheduleItemType(type: string): string {
    switch (type) {
      case 'INSTALL':
        return 'Installation';
      case 'REMOVE':
        return 'Removal';
      case 'MAINTENANCE':
        return 'Maintenance';
      default:
        return 'Unknown';
    }
  }

  // Get Schedule Item Type Icon
  getScheduleItemTypeIcon(type: string) {
    switch (type) {
      case 'INSTALL':
        return faArrowCircleUp;
      case 'MAINTENANCE':
        return faTools;
      case 'REMOVE':
        return faArrowCircleDown;
      case 'CUSTOM':
        return faCalendarDay;
    }
  }
}
