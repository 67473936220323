<div class="modal-header">
    <h5 class="modal-title">New Site</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="siteForm">
        <div class="mb-3">
            <label for="CLIENT">Client</label>
            <input *ngIf="client" id="CLIENT" type="text" class="form-control" [value]="client.name" disabled readonly>
        </div>
        <div class="mb-3">
            <label for="SITE_NAME">Name</label>
            <input id="SITE_NAME" type="text" class="form-control" formControlName="name" placeholder="Residence" maxlength="250" required [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
                <div *ngIf="name.errors.required" class="input-validation-msg">Name is required.</div>
                <div *ngIf="name.errors.maxlength" class="input-validation-msg">Name cannot exceed 250 characters.</div>
            </div>
        </div>
        <app-address-form [form]="siteForm"></app-address-form>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="copyAddress();">
        <fa-icon class="button-icon-left" [icon]="faCopy"></fa-icon>
        <span>Autofill Address</span>
    </button>
    <button type="button" class="btn btn-primary ms-auto" (click)="addSite();">Save</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>