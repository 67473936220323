import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Project } from './project';
import { User } from './user';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  projectUpdated: EventEmitter<void> = new EventEmitter<void>();

  constructor(private http: HttpClient) { }

  // Add Project
  addProject(project: any): Observable<any> {
    return this.http.post<any>(`/projects`, project);
  }

  // Update Project
  updateProject(project: any): Observable<any> {
    return this.http.put<any>(`/projects/${project.id}`, project);
  }

  // Update Project Stage
  updateProjectStage(projectId: string, stage: string): Observable<any> {
    return this.http.put<any>(`/projects/${projectId}/stage`, { stage: stage });
  }

  // Update Project Storage Location
  updateProjectStorageLocation(projectId: string, storageLocation: string): Observable<any> {
    return this.http.put<any>(`/projects/${projectId}/storageLocation`, { storageLocation: storageLocation });
  }

  // Update Project Status
  updateProjectStatus(projectId: string, status: string): Observable<any> {
    return this.http.put<any>(`/projects/${projectId}/status`, { status: status });
  }

  // Delete Project
  deleteProject(projectId: string): Observable<any> {
    return this.http.delete<any>(`/projects/${projectId}`);
  }

  // Get Projects
  getProjects(params: any): Observable<{ projects: Project[], count: number }> {
    return this.http.get<{ projects: Project[], count: number }>(`/projects`, {
      params: params
    });
  }

  // Get Project
  getProject(projectId: string): Observable<{ project: Project, hasUnpaidInvoices: boolean, salesperson: User, createdBy: User, updatedBy: User }> {
    return this.http.get<{ project: Project, hasUnpaidInvoices: boolean, salesperson: User, createdBy: User, updatedBy: User }>(`/projects/${projectId}`);
  }

  // Get Project Summary
  getProjectSummary(projectId: string): Observable<any> {
    return this.http.get<any>(`/projects/${projectId}/summary`);
  }

  // Get Distinct Column Values
  getDistinctColumnValues(params: any): Observable<string[]> {
    return this.http.get<string[]>(`/projects/distinctColumnValues`, {
      params: params
    });
  }

  /* ----- Project Bases ----- */

  // Add Project Base
  addProjectBase(projectId: string, content: string): Observable<void> {
    return this.http.post<void>(`/projects/${projectId}/bases`, { content: content });
  }

  // Update Project Base
  updateProjectBase(projectId: string, base: any): Observable<any> {
    return this.http.put<any>(`/projects/${projectId}/bases/${base.id}`, base);
  }

  // Delete Project Base
  deleteProjectBase(projectId: string, baseId: string): Observable<void> {
    return this.http.delete<void>(`/projects/${projectId}/bases/${baseId}`);
  }
}
