import { Component, OnInit } from '@angular/core';
import { SettingService } from '../setting.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionInvoiceModalComponent } from '../subscription-invoice-modal/subscription-invoice-modal.component';
import { SubscriptionRefundModalComponent } from '../subscription-refund-modal/subscription-refund-modal.component';
import { InvoicePdfComponent } from '../invoice-pdf/invoice-pdf.component';

@Component({
  selector: 'app-settings-subscriptions-invoices',
  templateUrl: './settings-subscriptions-invoices.component.html',
  styleUrls: ['./settings-subscriptions-invoices.component.css']
})
export class SettingsSubscriptionsInvoicesComponent implements OnInit {

  // Properties
  invoices: any[] = [];

  constructor(private settingService: SettingService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getSubscriptionInvoices();
  }

  // Get Subscription Invoices
  private getSubscriptionInvoices(): void {
    this.settingService.getSubscriptionInvoices().subscribe((invoices) => {
      this.invoices = invoices;
      console.log(invoices)
    });
  }

  openSubscriptionInvoiceModal(subscriptionInvoice: any = null): void {
    let login = JSON.parse(localStorage.getItem("login"))
    console.log(subscriptionInvoice)

    if(subscriptionInvoice && subscriptionInvoice.isRefunded) {
      const modalReference = this.modalService.open(SubscriptionRefundModalComponent);
      if (subscriptionInvoice) modalReference.componentInstance.subscriptionInvoice = subscriptionInvoice;
    } else {
      const modalReference = this.modalService.open(SubscriptionInvoiceModalComponent);
      modalReference.componentInstance.organizationId = login?.organizationId;
      if (subscriptionInvoice) modalReference.componentInstance.subscriptionInvoice = subscriptionInvoice;
      modalReference.componentInstance.saved.subscribe(() => {
        this.getSubscriptionInvoices();
      });
    }
  }

  openInvoice(data: any){
    const modalRef = this.modalService.open(InvoicePdfComponent, { size: 'lg' });
  }
}
