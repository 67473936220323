import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { InventoryPrepService } from '../inventory-prep.service';
import { InventoryPrep } from '../inventory-prep';

@Component({
  selector: 'app-project-prep-split-modal',
  templateUrl: './project-prep-split-modal.component.html',
  styleUrls: ['./project-prep-split-modal.component.css']
})
export class ProjectPrepSplitModalComponent implements OnInit {
  @Input() itemName: string;
  @Input() maxQuantity: number;
  @Input() itemPrep: InventoryPrep;
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();

  itemForm: FormGroup;
  req$ = new Subscription();

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private inventoryPrepService: InventoryPrepService,
  ) { }

  ngOnInit(): void {
    this.itemForm = this.formBuilder.group({
      pullQty: new UntypedFormControl(null, [Validators.max(this.maxQuantity - 1), Validators.required]),
      orderQty: new UntypedFormControl(null, [Validators.max(this.maxQuantity - 1), Validators.required])
    });

    this.req$.add(this.itemForm.controls['pullQty'].valueChanges.subscribe(val => {
      if ((this.itemForm.controls['orderQty'].value + this.itemForm.controls['pullQty'].value) != this.maxQuantity) {
        this.itemForm.controls['orderQty'].setValue(this.maxQuantity - val);
      }
    }));

    this.req$.add(this.itemForm.controls['orderQty'].valueChanges.subscribe(val => {
      if ((this.itemForm.controls['orderQty'].value + this.itemForm.controls['pullQty'].value) != this.maxQuantity) {
        this.itemForm.controls['pullQty'].setValue(this.maxQuantity - val);
      }
    }));
  }

  submit() {
    if (this.itemForm.valid) {
      const splitItem = {
        pulling: {
          ...this.itemPrep,
          quantity: this.itemForm.controls['pullQty'].value
        },
        createNew: {
          ...this.itemPrep,
          quantity: this.itemForm.controls['orderQty'].value
        }
      }
      this.req$.add(
        this.inventoryPrepService.splitInventoryPrep(splitItem).subscribe(() => {
          this.saved.emit(1);
          this.modal.close();
        })
      );
    }
  }

  get pullQty() { return this.itemForm.controls.pullQty; }
  get orderQty() { return this.itemForm.controls.orderQty; }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.req$.unsubscribe();
  }

}
