import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project-proposal-no-contact-modal',
  templateUrl: './project-proposal-no-contact-modal.component.html',
  styleUrls: ['./project-proposal-no-contact-modal.component.css']
})
export class ProjectProposalNoContactModalComponent {
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public modal: NgbActiveModal) { }

  submit(quantity: number) {
    this.saved.emit(quantity);
    this.modal.close();
  }
}
