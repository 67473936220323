import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { faAngleLeft, faAngleRight, faCalendar, faFileDownload, faSortUp, faSortDown, faRotateLeft, faRotate, faFilter, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { environment } from 'src/environments/environment';
import { ProjectService } from '../project.service';
import { ReportService } from '../report.service';
import { TableControlService } from '../table-control.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-reports-preferred-installation-and-removal-dates',
  templateUrl: './reports-preferred-installation-and-removal-dates.component.html',
  styleUrls: ['./reports-preferred-installation-and-removal-dates.component.css']
})
export class ReportsPreferredInstallationAndRemovalDatesComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Properties
  entries: any[] = [];
  entryCount: number = 0;

  selectedYear: string;
  years: string[] = [];

  isReportLoading: boolean = true;
  isMobileView: boolean = false;

  sortOptions = [
    { label: 'Client', value: 'c.client_name' },
    { label: 'Project', value: 'proj.project_name' },
    { label: 'Site', value: 's.site_address_street' },
    { label: 'Installation Week', value: 'accepted_proposal_installation_week' },
    { label: 'Removal Type', value: 'accepted_proposal_removal_type' },
  ];
  filterColumns = [
    { label: 'Site', value: 's.site_address_street' },
    { label: 'Removal Type', value: 'accepted_proposal_removal_type' },
  ];
  sortBy: string = 'c.client_name';
  sortDirection: string = 'ASC';

  // Table Controls
  tableControls: TableControlService;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;
  selectedFilterColumn: string ='';

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  faCalendar = faCalendar;
  faFileDownload = faFileDownload;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faRotateLeft = faRotateLeft;
  faFilter = faFilter;
  faRotate = faRotate;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private reportService: ReportService,
    private projectService: ProjectService,
    public location: Location) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
          this.tableControls.setLimit(20);
        }
      });
    }

  ngOnInit(): void {
    this.tableControls = new TableControlService('Reports.PreferredInstallationAndRemovalDates', true, 'c.client_name', 'ASC');
    this.checkScreenSize();
    if(this.isMobile || this.isMobileView) {
      let activeFilters = this.tableControls.getActiveFilters()
      let columns = [];
      for (const [key, value] of activeFilters) {
        columns.push(key)
      }
      this.tableControls.resetFilters(columns)
    }
    this.tableControls.refresh.subscribe(() => {
      this.getPreferredInstallationAndRemovalDates();
    });
    this.getDistinctProjectYears();
  }

  // Year Selected
  yearSelected(year: string): void {
    this.selectedYear = year;
    this.getPreferredInstallationAndRemovalDates();
  }

  // Get Distinct Project Years
  private getDistinctProjectYears(): void {
    const params = {
      searchTerm: null,
      sortBy: 'p.project_year',
      sortDirection: 'DESC',
      limit: null,
      offset: null,
      column: 'p.project_year'
    };
    this.projectService.getDistinctColumnValues(params).subscribe((values) => {
      this.years = values.map((value) => { return value.toString(); });
      if (this.years.length > 0) {
        const currentYear = dayjs().year().toString();
        this.selectedYear = this.years.includes(currentYear) ? currentYear : this.years[0];
        this.getPreferredInstallationAndRemovalDates();
      }
    });
  }

  // Get Get Preferred Installation And Removal Dates Report
  private getPreferredInstallationAndRemovalDates(): void {
    let params = this.tableControls.getParams();
    params['filter:proj.project_year'] = JSON.stringify([this.selectedYear]);
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
      for (const [key, value] of this.activeFilters) {
        if (key.includes('range')) params[`filter:${key}`] = value;
        else params[`filter:${key}`] = JSON.stringify(value);
      }
    };
    this.reportService.getPreferredInstallationAndRemovalDatesReport(params).subscribe((reportData) => {
      this.entries = reportData.entries;
      this.entryCount = reportData.count;
      this.isReportLoading = false;
    });
  }

  // Download Report
  downloadReport(): void {
    const searchParams = this.tableControls.getUrlSearchParams();
    searchParams.delete('limit');
    searchParams.delete('offset');
    searchParams.set('filter:proj.project_year', JSON.stringify([this.selectedYear]));
    const url = new URL('/api/reports/schedule/preferredInstallationAndRemovalDates/download?' + searchParams.toString(), environment.apiUrl);
    window.open(url.toString());
  }

  /* ----- Filters and Sorting ----- */

  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    this.selectedFilterColumn = column;
    if (!this.columnFilterValues.has(column)) {
      const params = {
        searchTerm: null,
        sortBy: column,
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        column: column
      };
      this.reportService.getPiardrDistinctColumnValues(params).subscribe((values) => {
        const tempArray = [];
        for (let value of values) {
          let name = null;
          switch (column) {
            default:
              name = (value === null || value.length == 0) ? 'BLANK' : value;
              break;
          }
          tempArray.push({ name: name, value: value, randomId: Math.random().toString(36) });
        }
        this.columnFilterValues.set(column, tempArray);
      });
    }
  }


  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getPreferredInstallationAndRemovalDates();
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getPreferredInstallationAndRemovalDates();
    this.getActiveFilterCount();
  }

   // Get Active Filter Count
   getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getPreferredInstallationAndRemovalDates();
    this.getActiveFilterCount();
  }

  // Show Column Filter Popover
  showColumnFilterPopover(popover, column: string): void {
    this.getDistinctColumnValues(column);
    if (popover.isOpen()) {
      popover.close();
    } else {
      setTimeout(() => {
        popover.open({ column });
      }, 250);
    }
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
    this.isMobileView ? this.tableControls.setLimit(5): this.tableControls.setLimit(20);
  }
}
