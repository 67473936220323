import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Proposal } from './proposal';
import { AcceptedProposal } from './accepted-proposal';
import { ProposalPricing } from './proposal-pricing';
import { ProposalInteraction } from './proposal-interaction';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {

  constructor(private http: HttpClient) { }

  /* ----- Proposals ----- */

  // Clone Proposal
  cloneProposal(data: any): Observable<any> {
    return this.http.put<any>(`/proposals/clone/${data.proposalId}`, data);
  }

  // Modify Proposal
  modifyProposal(data: any): Observable<any> {
    return this.http.put<any>(`/proposals/modify/${data.proposalId}`, data);
  }

  // Add Proposal
  addProposal(proposal: any): Observable<any> {
    return this.http.post<any>(`/proposals`, proposal);
  }

  // Update Proposal
  updateProposal(proposal: any): Observable<any> {
    console.log(proposal)
    return this.http.put<any>(`/proposals/${proposal.id}`, proposal);
  }

  // Update Proposal Multi Options
  updateProposalMultiOptions(proposalId: string, multiOptions: any): Observable<any> {
    return this.http.put<any>(`/proposals/${proposalId}/multiOptions`, { multiOptions: multiOptions });
  }

  // Delete Proposal
  deleteProposal(proposalId: string, projectId:string): Observable<any> {
    return this.http.delete<any>(`/proposals/${proposalId}/${projectId}`);
  }

  // Get Proposals
  getProposals(params: any): Observable<{ proposals: Proposal[], count: number }> {
    return this.http.get<{ proposals: Proposal[], count: number }>(`/proposals`, {
      params: params
    });
  }
  
  // Get Proposal
  getProposal(proposalId: string): Observable<Proposal> {
    return this.http.get<Proposal>(`/proposals/${proposalId}`);
  }

  // Calculate Proposal Pricing
  calculateProposalPricing(proposalId: string): Observable<ProposalPricing> {
    return this.http.get<ProposalPricing>(`/proposals/${proposalId}/pricing`);
  }

  // Get Proposal Setting
  proposalSetting(proposalId: string): Observable<any> {
    return this.http.get<any>(`/proposals/setting/${proposalId}`);
  }

  // Print Proposal
  printProposal(proposalId: string): Observable<any> {
    return this.http.get<any>(`/proposals/${proposalId}/print`);
  }

  // Get Distinct Column Values
  getDistinctColumnValues(params: any): Observable<string[]> {
    return this.http.get<string[]>(`/proposals/distinctColumnValues`, {
      params: params
    });
  }

  /* ----- Accepted Proposals ----- */

  // Accept Proposal
  acceptProposal(acceptedProposal: any): Observable<any> {
    return this.http.put<any>(`/proposals/${acceptedProposal.id}/accept`, acceptedProposal);
  }

  // Update Proposal Feature
  updateFeature(proposalId: string, featureId: string, isSelected: boolean, organizationId: string): Observable<any> {
    return this.http.put<any>(`/proposals/${proposalId}/${featureId}/isselected`, { isSelected, organizationId });
  }

  // Update Accepted Proposal
  updateAcceptedProposal(acceptedProposal: any): Observable<any> {
    return this.http.put<any>(`/proposals/${acceptedProposal.id}/update`, acceptedProposal);
  }

  // Reject Proposal
  rejectProposal(proposalId: string, projectId: string, sendNotification: boolean): Observable<any> {
    return this.http.put<any>(`/proposals/${proposalId}/${projectId}/reject`, { sendNotification: sendNotification });
  }

  // Reset Project Proposals
  resetProjectProposals(projectId: string): Observable<any> {
    return this.http.get<any>(`/proposals/reset`, {
      params: {
        projectId: projectId
      }
    });
  }

  // Get Accepted Proposal For Project
  getAcceptedProposalForProject(projectId: string): Observable<AcceptedProposal> {
    return this.http.get<AcceptedProposal>(`/proposals/accepted`, {
      params: {
        projectId: projectId
      }
    });
  }

  // Get Accepted Proposal
  getAcceptedProposal(proposalId: string): Observable<AcceptedProposal> {
    return this.http.get<AcceptedProposal>(`/proposals/accepted/${proposalId}`);
  }

  // Get Total Value of Accepted Proposals
  getTotalValueOfAcceptedProposals(): Observable<number> {
    return this.http.get<number>(`/proposals/accepted/totalValue`);
  }

  /* ----- Proposal Interactions ----- */

  // Add Proposal Interaction
  addProposalInteration(data: any): Observable<any> {
    return this.http.post<any>(`/proposals/${data.proposalId}/interactions`, data);
  }

  // Get Proposal Interactions
  getProposalInteractions(proposalId: string): Observable<ProposalInteraction[]> {
    return this.http.get<ProposalInteraction[]>(`/proposals/${proposalId}/interactions`);
  }
}
