import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dayjs } from 'dayjs';
import { Observable, Subject, of } from 'rxjs';
import { RoutingType } from './routing-type';
import { Crew, ScheduleItem } from './schedule-item';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  // Properties
  scheduleUpdated = new Subject<void>();
  scheduleUpdated$ = this.scheduleUpdated.asObservable();

  dateSelected = new Subject<Dayjs>();
  dateSelected$ = this.dateSelected.asObservable();

  dateRangeChanged = new Subject<{ start: string, end: string }>();
  dateRangeChanged$ = this.dateRangeChanged.asObservable();

  currentSelectedDate: Dayjs;
  currentDateRange: { start: string, end: string };

  constructor(private http: HttpClient) {
    this.dateSelected.subscribe((date) => { this.currentSelectedDate = date; });
    this.dateRangeChanged.subscribe((range) => { this.currentDateRange = range; });
  }

  // Add Schedule Item
  addScheduleItem(scheduleItem: any): Observable<any> {
    return this.http.post<any>(`/schedule`, scheduleItem);
  }

  // Add Custom Schedule Item
  addCustomScheduleItem(scheduleItem: any): Observable<any> {
    return this.http.post<any>(`/schedule/custom`, scheduleItem);
  }

  // Bulk Update Schedule Items
  bulkUpdateScheduleItems(updateRequests: any[]): Observable<any> {
    return this.http.put<any>(`/schedule/bulk`, { updateRequests: updateRequests });
  }

  // Update Schedule Item
  updateScheduleItem(scheduleItem: any): Observable<any> {
    return this.http.put<any>(`/schedule/${scheduleItem.id}`, scheduleItem);
  }

  // Swap Schedule Item Positions
  swapScheduleItemPositions(data: any): Observable<any> {
    return this.http.put<any>(`/schedule/swapPositions`, data);
  }

  // Delete Schedule Item
  deleteScheduleItem(scheduleItemId: string): Observable<any> {
    return this.http.delete<any>(`/schedule/${scheduleItemId}`);
  }

  // Get Schedule Items
  getScheduleItems(params: any): Observable<{ scheduleItems: ScheduleItem[], count: number }> {
    return this.http.get<{ scheduleItems: ScheduleItem[], count: number }>(`/schedule`, {
      params: params
    });
  }

  // Add Item To Schedule
  addItemToSchedule(scheduleItem: any): Observable<any> {
    return this.http.put<any>(`/schedule/${scheduleItem.id}/add`, scheduleItem);
  }

  // Remove Item From Schedule
  removeItemFromSchedule(scheduleItemId: string): Observable<any> {
    return this.http.delete<any>(`/schedule/${scheduleItemId}/remove`);
  }

  // Get Up Next
  getUpNext(startDate: string, endDate: string): Observable<ScheduleItem[]> {
    return this.http.get<ScheduleItem[]>(`/schedule/up-next`, {
      params: {
        startDate: startDate,
        endDate: endDate
      }
    });
  }

  // Get Distinct Column Values
  getDistinctColumnValues(params: any): Observable<string[]> {
    return this.http.get<string[]>(`/schedule/distinctColumnValues`, {
      params: params
    });
  }

  /* ----- Routing ----- */

  // Optimize Route
  optimizeRoute(locations: string[], positions: number[][], date: string, type: 'DISTANCE' | 'DURATION'): Observable<string[]> {
    return this.http.post<string[]>(`/schedule/routing/optimizeRoute`, { locations: locations, positions: positions, date: date, type: type });
  }

  // Route By Time
  routeByTime(date: string): Observable<any> {
    return this.http.post<any>(`/schedule/routing/time`, { date: date });
  }

  /* ----- Routing Types ----- */

  // Get Routing Types
  getRoutingTypesInRange(startDate: string, endDate: string): Observable<RoutingType[]> {
    return this.http.get<RoutingType[]>(`/schedule/routingTypes`, {
      params: {
        startDate: startDate,
        endDate: endDate
      }
    });
  }

  /* ----- Crews ----- */
  saveNewCrew(crew: Crew): Observable<any> {
    if (crew?.crewId) {
      return this.http.put<any>(`/schedule/modifycrew`, crew);
    } else {
      return this.http.post<any>(`/schedule/createcrew`, crew);
    }
  }

  getCrews(): Observable<Crew[]> {
    return this.http.get<Crew[]>(`/schedule/crews`);
  }

  getArchiveCrews(): Observable<Crew[]> {
    return this.http.get<Crew[]>(`/schedule/archivedcrews`);
  }

  deleteCrew(crewId: string): Observable<any> {
    return this.http.put<any>(`/schedule/archivecrew`, {
      crewId
    });
  }

  updateCrewMembers(crewId: string, memberIds: string[]) {
    return this.http.put<any>(`/schedule/updatecrewmember`, {
      crewId, memberIds
    });
  }

  getCrewSchedule(crewId: string) {
    return this.http.get<ScheduleItem[]>(`/schedule/crewschedules/${crewId}`);
  }

  removeCrewToSchedules(crewId: string, scheduleIds: string[]) {
    return this.http.post<any>(`/schedule/removecrewinschedules`, { crewId, scheduleIds });
  }

  replaceCrewInSchedules(crewId: string, replacements) {
    return this.http.put<any>(`/schedule/replacecrewinschedules`, { crewId, replacements });
  }

  deleteCrewAndSchedules(crewId: string, scheduleIds: string[]): Observable<any> {
    return this.http.post<any>(`/schedule/deletecrewandschedules`, { crewId, scheduleIds });
  }


}
