<div class="row">
    <div class="col-sm-12">
        <form [formGroup]="numberConnectForm">
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Number</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                      <select formControlName="number" multiple class="form-control">
                        <option *ngFor="let option of numberArray" [value]="option">{{ option }}</option>
                      </select>
                    </div>
                  </div>
            </div>
        </form>
    </div>
</div>