import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { faAngleLeft, faAngleRight, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgbDate, NgbDateParserFormatter, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';

@Component({
  selector: 'app-date-picker-controller',
  templateUrl: './date-picker-controller.component.html',
  styleUrls: ['./date-picker-controller.component.css']
})
export class DatePickerControllerComponent implements OnInit {
  @ViewChild('dateRangePopover') dateRangePopover: NgbPopover;
  @ViewChild('startDatePicker', { static: true }) startDatePicker: any;
  @ViewChild('endDatePicker', { static: true }) endDatePicker: any;

  // Properties
  @Input() componentId: string;
  @Input() preset: string;
  @Input() showButtons: boolean = false;
  @Input() onLoad: boolean = true;
  @Output() dateChanged: EventEmitter<{ startDate: string, endDate: string, isAuto: boolean }> = new EventEmitter<{ startDate: string, endDate: string, isAuto: boolean }>();
  startDate: string = '';
  endDate: string = '';
  startDateNgb: NgbDate | null = null;
  endDateNgb: NgbDate | null = null;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  faCalendar = faCalendar;
  isMobile: boolean = true;
  public applyButtonDisabled: boolean = true;

  get isApplyButtonDisabled() {
    return this.applyButtonDisabled;
  }

  constructor(private dateParserFormatter: NgbDateParserFormatter) { }

  ngOnInit(): void {
    // if (this.hasTableConfiguration()) this.loadTableConfiguration();
    // else this.resetTableConfiguration();
    // this.dateChanged.subscribe(() => {
    //   this.updateTableConfiguration();
    // });
    // this.resetTableConfiguration();
    this.showPreset(this.preset, this.onLoad);
    this.isMobile = (window.screen.width <= 360 || window.screen.width <= 768);
    window.addEventListener('resize', () => {
      this.isMobile = (window.screen.width <= 360 || window.screen.width <= 768);
    });
  }

  // Finalize Date Change
  private finalizeDateChange(isAuto: boolean = false): void {
    this.onLoad = false;
    this.startDateNgb = NgbDate.from(this.dateParserFormatter.parse(this.startDate));
    this.endDateNgb = NgbDate.from(this.dateParserFormatter.parse(this.endDate));
    this.dateChanged.emit({ startDate: this.startDate, endDate: this.endDate, isAuto: isAuto });
    this.dateRangePopover?.close();
    this.applyButtonDisabled = true;
  }

  // Previous
  previous(): void {
    this.startDate = dayjs(this.startDate).subtract(1, 'week').startOf('week').format('YYYY-MM-DD');
    this.endDate = dayjs(this.endDate).subtract(1, 'week').endOf('week').format('YYYY-MM-DD');
    this.finalizeDateChange();
  }

  // Next
  next(): void {
    this.startDate = dayjs(this.startDate).add(1, 'week').startOf('week').format('YYYY-MM-DD');
    this.endDate = dayjs(this.endDate).add(1, 'week').endOf('week').format('YYYY-MM-DD');
    this.finalizeDateChange();
  }
  private updatingDate = false;
  // Start Date Selected
  startDateSelected(date: NgbDate): void {
    if (!this.updatingDate) {
      if (date !== null) {
        this.updatingDate = true;
        this.startDate = this.dateParserFormatter.format(date);
        this.startDateNgb = NgbDate.from(this.dateParserFormatter.parse(this.startDate));
        // this.dateChanged.emit({ startDate: this.startDate, endDate: this.endDate, isAuto: false });
      }
      this.updatingDate = false;
    }
  }

  // End Date Selected
  endDateSelected(date: NgbDate): void {
    if (!this.updatingDate) {
      this.updatingDate = true;
      this.endDate = this.dateParserFormatter.format(date);
      this.endDateNgb = NgbDate.from(this.dateParserFormatter.parse(this.endDate));
      this.updatingDate = false;
    }
    // this.dateChanged.emit({ startDate: this.startDate, endDate: this.endDate, isAuto: false });
  }

  // Show Preset
  showPreset(preset: string, onLoad = false): void {
    this.updatingDate = true;
    switch (preset) {
      case 'YTD':
        this.startDate = dayjs().startOf('year').format('YYYY-MM-DD');
        this.endDate = dayjs().endOf('day').format('YYYY-MM-DD');
        break;
      case 'LST_YR':
        this.startDate = dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
        this.endDate = dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
        break;
      case 'CUR_MTH':
        this.startDate = dayjs().startOf('month').format('YYYY-MM-DD');
        this.endDate = dayjs().endOf('month').format('YYYY-MM-DD');
        break;
      case 'LST_MTH':
        this.startDate = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
        this.endDate = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        break;
      case 'CUR_WK':
        this.startDate = dayjs().startOf('week').format('YYYY-MM-DD');
        this.endDate = dayjs().endOf('week').format('YYYY-MM-DD');
        break;
      default:
        break;
    }

    this.startDateNgb = NgbDate.from(this.dateParserFormatter.parse(this.startDate));
    this.endDateNgb = NgbDate.from(this.dateParserFormatter.parse(this.endDate));
    this.preset = preset;

    if (!onLoad) {
      this.applyButtonDisabled = false;
    }
    if(onLoad) {
      this.finalizeDateChange()
    }
    this.updatingDate = false;
    // this.finalizeDateChange(true);
  }

  /* ----- Table Configuration ----- */

  // Load Table Configuration
  private loadTableConfiguration(): void {
    const config: any = JSON.parse(localStorage.getItem('Date.' + this.componentId));
    this.startDate = config.startDate;
    this.endDate = config.endDate;
    this.finalizeDateChange();
  }

  // Update Table Configuration
  private updateTableConfiguration(): void {
    const config: any = {
      startDate: this.startDate,
      endDate: this.endDate
    };
    localStorage.setItem('Date.' + this.componentId, JSON.stringify(config));
  }

  // Reset Table Configuration
  resetTableConfiguration(): void {
    this.showPreset(this.preset);
    this.startDateNgb = NgbDate.from(this.dateParserFormatter.parse(this.startDate));
    this.endDateNgb = NgbDate.from(this.dateParserFormatter.parse(this.endDate));
    this.dateChanged.emit({ startDate: this.startDate, endDate: this.endDate, isAuto: true });
  }

  // Has Table Configuration
  private hasTableConfiguration(): boolean {
    return localStorage.getItem('Date.' + this.componentId) !== null;
  }
}
