import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';
import dayjs from 'dayjs';

@Injectable()
export class APIRequestInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
    private notificationService: NotificationService,
    private alertService: AlertService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('http')) {
      request = request.clone({
        headers: request.headers.set('Time-Zone', dayjs.tz.guess()),
        url: environment.apiUrl + request.url
      });
    }
    request = request.clone({
      withCredentials: true
    });
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event.headers.has('crm-app-version') && (event.headers.get('crm-app-version') !== environment.version)) this.notificationService.notificationBannerUpdated.emit({ show: true });
      }
    })).pipe(catchError((error: any) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401 && !error.url!.includes('/api/auth/login')) {
          this.authService.logout();
        } else if ((error.status >= 400 && error.status < 500) || error.status === 503) {
          if (typeof error.error === 'object') this.alertService.showWarningAlert(error.error.message);
          else this.alertService.showWarningAlert(error.error);
        } else if (error.status === 500) {
          if (typeof error.error === 'object') this.alertService.showErrorAlert(error.error.message);
          else this.alertService.showErrorAlert(error.error);
        } else if (error.status === 502) {
          this.alertService.showErrorAlert('Bad Gateway');
        } else {
          this.alertService.showErrorAlert('Server Error');
        }
      }
      return throwError(() => error);
    }));
  }
}
