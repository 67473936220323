<div class="modal-header">
    <h5 class="modal-title">New Call</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="sendSmsForm">
        <div class="row">
            <ng-template #list>
                <div *ngIf="contact">
                    <small><b>Name:</b> {{contact.firstName}} {{contact.lastName}} <br> <b>Client Name:</b> {{contact.clientName}} <br> <b>Phone Number:</b> {{contact.phone}}</small>
                </div>
                <div *ngIf="!contact"><span>{{sendSmsForm.controls['number'].value}}</span></div>
            </ng-template>
            <div class="col-sm-12 col-md-12">
                <div class="mb-3">
                    <label for="send_sms">Contact</label>
                    <input   [ngbTypeahead]="search"
                    (focus)="focus$.next($event.target.value)"
                    (click)="click$.next($event.target.value)"
                    #instance="ngbTypeahead"
                    [resultFormatter]="formatter"
                    (selectItem)="selectContact($event)"
                    formControlName="number" [ngbTooltip]="list" type="text" class="form-control">
                </div>
            </div>
            <div class="col-sm-4 col-md-4 col-4 mb-2 text-center">
                <button class="dialer-btn" (click)="inputValue('1')" type="button">1</button>
            </div>
            <div class="col-sm-4 col-md-4 col-4 mb-2 text-center">
                <button class="dialer-btn" (click)="inputValue('2')" type="button">2</button>
            </div>
            <div class="col-sm-4 col-md-4 col-4 mb-2 text-center">
                <button class="dialer-btn" (click)="inputValue('3')" type="button">3</button>
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center mb-2">
                <button class="dialer-btn" (click)="inputValue('4')" type="button">4</button>
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center mb-2">
                <button class="dialer-btn" (click)="inputValue('5')" type="button">5</button>
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center mb-2">
                <button class="dialer-btn" (click)="inputValue('6')" type="button">6</button>
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center mb-2">
                <button class="dialer-btn" (click)="inputValue('7')" type="button">7</button>
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center mb-2">
                <button class="dialer-btn" (click)="inputValue('8')" type="button">8</button>
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center mb-2">
                <button class="dialer-btn" (click)="inputValue('9')" type="button">9</button>
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center mb-2">
                <button class="dialer-btn" (click)="inputValue('*')" type="button">*</button>
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center mb-2">
                <button class="dialer-btn" (click)="inputValue('0')" type="button">0</button>
            </div>
            <div class="col-sm-4 col-md-4 col-4 text-center mb-2">
                <button class="dialer-btn" (click)="inputValue('#')" type="button">#</button>
            </div>
            <div class="col-sm-12 text-center mb-2">
                <button (click)="callNumber()" class="dialer-btn-call" type="button">
                    <fa-icon class="button-icon-center" [icon]="faPhone"></fa-icon>
                </button>
            </div>
        </div>
    </form>
</div> 