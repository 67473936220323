import { Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { faAngleLeft, faTriangleExclamation, faFilter, faRotateLeft, faSortDown, faSortUp, faFileDownload, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { DatePickerControllerComponent } from '../date-picker-controller/date-picker-controller.component';
import { Proposal } from '../proposal';
import { ProposalStatusPipe } from '../proposal-status.pipe';
import { ProposalService } from '../proposal.service';
import { TableControlService } from '../table-control.service';
import { AlertService } from '../alert.service';
import { environment } from 'src/environments/environment';
import { Subject, debounceTime } from 'rxjs';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-reports-proposals',
  templateUrl: './reports-proposals.component.html',
  styleUrls: ['./reports-proposals.component.css'],
  providers: [ProposalStatusPipe]
})
export class ReportsProposalsComponent implements OnInit, AfterViewInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();
  // Properties
  @ViewChild('datePickerController') private datePickerController: DatePickerControllerComponent;
  proposals: Proposal[] = [];
  proposalCount: number = 0;
  startDate: string;
  endDate: string;
  isMobileView: boolean = false;

  sortOptions = [
    { label: 'Client', value: 'c.client_name' },
    { label: 'Project', value: 'proj.project_name' },
    { label: 'Status', value: 'prop.proposal_status' },
    { label: 'Created', value: 'prop.proposal_created_at' },
    { label: 'Updated', value: 'prop.proposal_updated_at' },
  ];
  filterColumns = [
    { label: 'Client', value: 'c.client_name' },
    { label: 'Project', value: 'proj.project_name' },
    { label: 'Status', value: 'prop.proposal_status' },
  ];
  sortBy: string = 'prop.proposal_updated_at';
  sortDirection: string = 'ASC';

  // Table Controls
  tableControls: TableControlService;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;
  selectedFilterColumn: string ='';

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faSortDown = faSortDown;
  faSortUp = faSortUp;
  faFilter = faFilter;
  faRotateLeft = faRotateLeft;
  faTriangleExclamation = faTriangleExclamation;
  faFileDownload = faFileDownload;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private proposalService: ProposalService,
    private proposalStatusPipe: ProposalStatusPipe,
    private alertService: AlertService,
    public location: Location,
    private loaderService: LoaderService) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
          this.tableControls.setLimit(20);
        }
      });
    }

  ngOnInit(): void {
    this.tableControls = new TableControlService('Reports.Proposals', true, 'prop.proposal_updated_at', 'DESC');
    this.checkScreenSize();
    if(this.isMobile || this.isMobileView) {
      let activeFilters = this.tableControls.getActiveFilters()
      let columns = [];
      for (const [key, value] of activeFilters) {
        columns.push(key)
      }
      this.tableControls.resetFilters(columns)
    }
    this.tableControls.refresh.subscribe(() => {
      this.getProposals();
    });
  }

  ngAfterViewInit(): void {
    this.tableControls.datePickerController = this.datePickerController;
  }

  // Date Changed
  dateChanged(event: { startDate: string, endDate: string, isAuto: boolean }): void {
    this.loaderService.showSpinner();
    this.startDate = event.startDate;
    this.endDate = event.endDate;
    this.tableControls.addRangeFilter('prop.proposal_created_at', dayjs(this.startDate).unix(), dayjs(this.endDate).unix(), event.isAuto);
  }

  // Get Proposals
  private getProposals(): void {
    let params = this.tableControls.getParams();
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
      for (const [key, value] of this.activeFilters) {
        if (key.includes('range')) params[`filter:${key}`] = value;
        else params[`filter:${key}`] = JSON.stringify(value);
      }
    };
    this.proposalService.getProposals(params).subscribe({
      next: (res) => {
          this.proposals = res.proposals;
          this.proposalCount = res.count;
          this.loaderService.hideSpinner(700);
      },
      error: () => {
        this.loaderService.hideSpinner(700);
      }
    });
  }

  // Download Report
  exportProposal(): void {
    const url = new URL('/api/reports/proposals/download', environment.apiUrl);
    window.open(url.toString());
  }

  /* ----- Filters and Sorting ----- */

  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    this.selectedFilterColumn = column;
    if (!this.columnFilterValues.has(column)) {
      const params = {
        searchTerm: null,
        sortBy: column,
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        column: column
      };
      if(this.isMobile) {
        for (const [key, value] of this.activeFilters) {
          if (key.includes('range')) params[`filter:${key}`] = value;
          else params[`filter:${key}`] = JSON.stringify(value);
        }
      }
      this.proposalService.getDistinctColumnValues(params).subscribe((values) => {
        const tempArray = [];
        for (const value of values) {
          let name = null;
          switch (column) {
            case 'prop.proposal_status':
              name = this.proposalStatusPipe.transform(value);
              break;
            default:
              name = (value === null || value.length == 0) ? 'BLANK' : value;
              break;
          }
          tempArray.push({ name: name, value: value, randomId: Math.random().toString(36) });
        }
        this.columnFilterValues.set(column, tempArray);
      });
    }
  }

  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getProposals();
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getProposals();
    this.getActiveFilterCount();
  }

   // Get Active Filter Count
   getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getProposals();
    this.getActiveFilterCount();
  }

  // Show Column Filter Popover
  showColumnFilterPopover(popover, column: string): void {
    this.getDistinctColumnValues(column);
    if (popover.isOpen()) {
      popover.close();
    } else {
      setTimeout(() => {
        popover.open({ column });
      }, 250);
    }
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
    this.isMobileView ? this.tableControls.setLimit(5): this.tableControls.setLimit(20);
  }
}
