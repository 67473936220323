<div class="modal-header">
    <h5 class="modal-title">New User</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="userForm">
        <div class="row">
            <div class="col">
                <div class="mb-3">
                    <label for="NEW_USER_FIRST_NAME">First Name</label>
                    <input id="NEW_USER_FIRST_NAME" type="text" class="form-control" placeholder="John" formControlName="firstName" maxlength="150" required [class.is-invalid]="firstName.invalid && (firstName.dirty || firstName.touched)">
                    <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                        <div *ngIf="firstName.errors.required" class="input-validation-msg">First Name is required.</div>
                        <div *ngIf="firstName.errors.maxlength" class="input-validation-msg">First Name cannot exceed 150 characters.</div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="mb-3">
                    <label for="NEW_USER_LAST_NAME">Last Name</label>
                    <input id="NEW_USER_LAST_NAME" type="text" class="form-control" placeholder="Appleseed" formControlName="lastName" maxlength="150" required [class.is-invalid]="lastName.invalid && (lastName.dirty || lastName.touched)">
                    <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                        <div *ngIf="lastName.errors.required" class="input-validation-msg">Last Name is required.</div>
                        <div *ngIf="lastName.errors.maxlength" class="input-validation-msg">Last Name cannot exceed 150 characters.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="mb-3">
                    <label for="NEW_USER_EMAIL">Email</label>
                    <input id="NEW_USER_EMAIL" type="text" class="form-control" placeholder="jappleseed@example.com" formControlName="email" maxlength="320" pattern="^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$" required [class.is-invalid]="email.invalid && (email.dirty || email.touched)">
                    <div *ngIf="email.invalid && (email.dirty || email.touched)">
                        <div *ngIf="email.errors.required" class="input-validation-msg">Email is required.</div>
                        <div *ngIf="email.errors.maxlength" class="input-validation-msg">Email cannot exceed 320 characters.</div>
                        <div *ngIf="email.errors.pattern" class="input-validation-msg">Email is formatted incorrectly.</div>
                        <div *ngIf="email.errors.alreadyExists" class="input-validation-msg">Email already exists. Please try a different email.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label style="font-weight: bold;">Password Generation</label>
                <div [class.mb-0]="passwordGeneration.value == 'AUTO'" [class.mb-2]="passwordGeneration.value == 'MANUAL'">
                    <div class="form-check form-check-inline">
                        <input id="GENERATE_PASSWORD_AUTO" class="form-check-input" type="radio" formControlName="passwordGeneration" value="AUTO">
                        <label for="GENERATE_PASSWORD_AUTO" class="form-check-label" style="font-weight: normal;">Auto Generate</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input id="GENERATE_PASSWORD_MANUAL" class="form-check-input" type="radio" formControlName="passwordGeneration" value="MANUAL">
                        <label for="GENERATE_PASSWORD_MANUAL" class="form-check-label" style="font-weight: normal;">Manual</label>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="passwordGeneration.value == 'AUTO'">
            <small class="text-muted">Password will be sent to the user's email.</small>
        </div>
        <div *ngIf="passwordGeneration.value == 'MANUAL'">
            <div class="row">
                <div class="col">
                    <div class="mb-3">
                        <label for="NEW_USER_PASSWORD">Password</label>
                        <input id="NEW_USER_PASSWORD" type="password" class="form-control" placeholder="Password" formControlName="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" [class.is-invalid]="password.invalid && (password.dirty || password.touched)">
                        <div *ngIf="password.invalid && (password.dirty || password.touched)">
                            <div *ngIf="password.errors.pattern" class="input-validation-msg">Password must be 8 characters and contain uppercase, lowercase, and number characters.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div>
                        <label for="NEW_USER_CONFIRM_PASSWORD">Confirm Password</label>
                        <input id="NEW_USER_CONFIRM_PASSWORD" type="password" class="form-control" placeholder="Confirm Password" formControlName="confirmPassword" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" [class.is-invalid]="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                        <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                            <div *ngIf="confirmPassword.errors.pattern" class="input-validation-msg">Password must be 8 characters and contain uppercase, lowercase, and number characters.</div>
                            <div *ngIf="confirmPassword.errors.doesNotMatch" class="input-validation-msg">Passwords do not match.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addUser();" [disabled]="saveInProgress">
        <span>Save</span>
        <fa-icon *ngIf="saveInProgress" [icon]="faRotate" [spin]="true" class="button-icon-right"></fa-icon>
    </button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>