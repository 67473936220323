import { AfterViewInit, Component, OnInit } from '@angular/core';
export type AppTouchEvent = TouchEvent;

@Component({
  selector: 'app-signature-canvas',
  templateUrl: './signature-canvas.component.html',
  styleUrls: ['./signature-canvas.component.css']
})
export class SignatureCanvasComponent implements OnInit, AfterViewInit {

  // Properties
  private signatureCanvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private currX = 0;
  private currY = 0;
  private isSafari = navigator.vendor.match(/apple/i) &&
    !navigator.userAgent.match(/crios/i) &&
    !navigator.userAgent.match(/fxios/i) &&
    !navigator.userAgent.match(/Opera|OPT\//);

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.signatureCanvas = document.getElementById('SIGNATURE_CANVAS') as HTMLCanvasElement;
    this.context = this.signatureCanvas.getContext('2d');
    this.context.canvas.width = (document.getElementById('CANVAS_CONTAINER') as HTMLDivElement).clientWidth;
    this.context.canvas.height = 100;
    this.signatureCanvas.addEventListener('mousemove', (event) => {
      this.draw(event);
    });
    this.signatureCanvas.addEventListener('mousedown', (event) => {
      this.setPosition(event);
    });
    this.signatureCanvas.addEventListener('mouseenter', (event) => {
      this.setPosition(event);
    });
    this.signatureCanvas.addEventListener('touchmove', (event) => {
      this.draw(event);
    });
    this.signatureCanvas.addEventListener('touchstart', (event) => {
      this.setPosition(event);
    });
  }

  // Set Position
  private setPosition(event: MouseEvent | AppTouchEvent): void {
    const canvasRect = this.signatureCanvas.getBoundingClientRect();
    if (event instanceof MouseEvent) {
      this.currX = event.clientX - canvasRect.left;
      this.currY = event.clientY - canvasRect.top;
    }
    if (window.screen.availWidth < 1025 || !this.isSafari) {
      if (event instanceof TouchEvent) {
        this.currX = event.touches[0].clientX - canvasRect.left;
        this.currY = event.touches[0].clientY - canvasRect.top;
      }
    }
  }

  // Draw
  private draw(event: MouseEvent | AppTouchEvent): void {
    if (event instanceof MouseEvent && event.buttons !== 1) return;
    this.context.beginPath();
    this.context.strokeStyle = 'black';
    this.context.lineWidth = 2;
    this.context.moveTo(this.currX, this.currY);
    this.setPosition(event);
    this.context.lineTo(this.currX, this.currY);
    this.context.stroke();
    this.context.closePath();
  }

  // Erase Canvas
  eraseCanvas(): void {
    this.context.clearRect(0, 0, this.signatureCanvas.width, this.signatureCanvas.height);
  }
}