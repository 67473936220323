<div class="card mt-3">
    <div class="card-header d-xl-flex">
        <h5>{{cardTitle}} ({{projects.length}})</h5>
    </div>
    <div class="card-body">
        <ul class="list-group scrollable" style="height: 312px;">
            <li *ngFor="let project of projects" class="list-group-item d-flex" style="cursor: pointer;"
                routerLink="/projects/{{project.id}}">
                <div class="col-1 my-auto text-center">
                    <fa-icon *ngIf="project.schedules && project.schedules.length > 0" [icon]="faCircle"
                        class="countyColorIcon my-auto" size="xs"
                        [style.color]="project.schedules[0].crews && project.schedules[0].crews.length > 0 ? project.schedules[0].crews[0].crewColor:'#000000'"
                        [ngbTooltip]="project.site.county"></fa-icon>
                </div>
                <div class="d-flex flex-column">
                    <strong>{{project.clientName}}</strong>
                    <small class="text-muted">{{project.name}}</small>
                </div>
            </li>
            <li *ngIf="projects.length == 0" class="list-group-item">No Projects For Stage</li>
        </ul>
    </div>
</div>