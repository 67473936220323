import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {

  // Properties
  @Input() title: string = 'Confirm';
  @Input() message: string;
  @Input() actionBtnTitle: string;
  @Input() actionBtnType: string = 'DANGER';
  @Input() cancelBtnTitle: string = 'Cancel';
  @Output() confirmed: EventEmitter<void> = new EventEmitter<void>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {

  }

  // Action Confirmed
  actionConfirmed(): void {
    this.modal.close();
    this.confirmed.emit();
  }

  // Action Canceled
  actionCanceled(): void {
    this.modal.dismiss();
    this.canceled.emit();
  }
}

