import { CurrencyPipe, Location } from '@angular/common';
import { Component } from '@angular/core';
import { faAngleLeft, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { InvoiceService } from '../invoice.service';
import { PaymentService } from '../payment.service';
import { ReportService } from '../report.service';
import { environment } from 'src/environments/environment';
import { ExcelDownloaderService } from '../shared/services/excel-downloader.service';

@Component({
  selector: 'app-reports-sales-tax-paid',
  templateUrl: './reports-sales-tax-paid.component.html',
  styleUrls: ['./reports-sales-tax-paid.component.css']
})
export class ReportsSalesTaxPaidComponent {

  // constants
  QUARTERS = [
    'Quarter 1',
    'Quarter 2',
    'Quarter 3',
    'Quarter 4',
  ];
  MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  // Table labels and Data
  labels: string[] = [];
  quarterMap: any[] = []
  monthlyMap: any[] = []
  yearMap: any[] = [];

  // Properties
  startDate: string;
  endDate: string;

  salesTaxMap: Map<number, { name: string, amount: number, tax: number }>;
  salesTaxTotal: number = 0;
  salesAmountTotal: number = 0;

  quarterlySalesTaxMap: Map<number, any[]>;
  monthlySalesTaxMap: Map<number, any[]>;

  collectedSalesTaxMap: Map<number, { name: string, amount: number, tax: number }>;
  collectedSalesTaxTotal: number = 0;

  selectedYear: number;
  years: number[] = [];

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faFileDownload = faFileDownload;

  constructor(private reportService: ReportService,
    private paymentService: PaymentService,
    private excelDownloaderService: ExcelDownloaderService,
    private currencyPipe: CurrencyPipe,
    public location: Location) { }

  ngOnInit(): void {
    this.getDistinctPaymentDateYears();
  }

  // Year Selected
  yearSelected(year: number): void {
    this.selectedYear = year;
    this.getPaidSalesTaxReport();
  }

  // Get Distinct Payment Date Years
  private getDistinctPaymentDateYears(): void {
    this.paymentService.getDistinctPaymentDateYears().subscribe((years) => {
      this.years = years;
      if (this.years.length > 0) {
        const currentYear = dayjs().year();
        this.selectedYear = this.years.includes(currentYear) ? currentYear : this.years[0];
        this.getPaidSalesTaxReport();
      }
    });
  }

  // Get Paid Sales Tax Report
  private getPaidSalesTaxReport(): void {
    this.reportService.getPaidSalesTaxReport({ year: this.selectedYear }).subscribe((reportData) => {
      this.salesTaxMap = new Map(reportData.salesTaxMap);
      const salesTaxArr = Array.from(this.salesTaxMap);
      this.quarterMap = this.getQuarterTaxMap(reportData.quarterlySalesTaxMap)
      this.salesTaxTotal = salesTaxArr.reduce((acc, entry) => { return acc + entry[1].tax; }, 0);
      this.salesAmountTotal = salesTaxArr.reduce((acc, entry) => { return acc + entry[1].amount; }, 0);
      this.quarterlySalesTaxMap = new Map(reportData.quarterlySalesTaxMap);
      this.monthlyMap = this.getMonthTaxMap(reportData.monthlySalesTaxMap)
      this.monthlySalesTaxMap = new Map(reportData.monthlySalesTaxMap);
      this.yearMap = this.getYearTaxMap(reportData.salesTaxMap)
    });
  }

  getSalesTotal(taxMap: any, order: number, key: string) {
    if (!taxMap.has(order)) return 0;
    const reduceAmnt = taxMap.get(order).reduce((acc, entry) => { return acc + entry[key]; }, 0);
    return this.currencyPipe.transform(reduceAmnt, 'USD', 'symbol');
  }

  getYearTaxMap(rawData) {
    return rawData.map((data) => {
      return {
        label: data[1].name ? `${data[1].name} (${data[0] * 100}%)` : data[0] * 100 + '%',
        amount: data[1].amount ? data[1].amount : 0,
        tax: data[1].tax ? data[1].tax : 0,
        year: this.selectedYear
      }
    })
  }

  getMonthTaxMap(rawData) {
    let monthlyFormat = [[], [], [], [], [], [], [], [], [], [], [], []];
    return monthlyFormat.map((raw, index) => {
      const rawArr = rawData.filter(data => data[0] == index + 1);
      if (rawArr.length == 0) {
        return [];
      } else {
        return rawArr[0][1].map(data => {
          return {
            label: data.name ? `${data.name} (${data.taxMultiplier * 100}%)` : data.taxMultiplier * 100 + '%',
            amount: data.amount ? data.amount : 0,
            tax: data.tax ? data.tax : 0,
            month: rawArr[0][0]
          }
        });
      }
    })
  }

  getQuarterTaxMap(rawData) {
    let quartersFormat = [[], [], [], []];

    quartersFormat = quartersFormat.map((raw, index) => {
      const rawArr = rawData.filter(data => data[0] == index + 1);
      if (rawArr.length == 0) {
        return [];
      } else {
        return rawArr[0][1].map(data => {
          return {
            label: data.name ? `${data.name} (${data.taxMultiplier * 100}%)` : data.taxMultiplier * 100 + '%',
            amount: data.amount,
            tax: data.tax,
            quarter: rawArr[0][0]
          }
        });
      }
    })
    let max = Math.max(...quartersFormat.map(i => i.length))
    let patternedArr = quartersFormat.filter(i => i.length == max)
    let collatedDatasets = [];

    quartersFormat.forEach((qtr, index) => {
      for (let i = 0; i < max; i++) {
        const qtrPresent = qtr.length == 0 ? [] : qtr.filter(qtrLbl => qtrLbl.label == patternedArr[0][i].label)

        if (qtrPresent.length != 0) {
          collatedDatasets.push(qtrPresent[0])
        } else {
          collatedDatasets.push({
            label: patternedArr[0][i].label,
            amount: 0,
            quarter: index + 1,
            tax: 0
          })
        }
      }
    })

    this.labels = Array.from(new Set(collatedDatasets.map(data => data.label)));
    return quartersFormat;
  }

  filterMultiArrByLabelAndKey = (arr, label, key, divisionKey, divisionVal) => {
    const val = arr.filter(item => item.label == label && item[divisionKey] == divisionVal);
    return val && val.length != 0 ? this.currencyPipe.transform(val[0][key], 'USD', 'symbol') : 0
  }

  exportAll() {
    const filename = `Sales_tax_paid_${this.selectedYear}`;

    const occurence = ['year', 'quarter', 'month'];
    let multiExport = [];

    occurence.forEach(occur => {
      multiExport.push({
        label: occur.toLocaleUpperCase(),
        values: this.exportReport(occur, false)
      })
    });

    this.excelDownloaderService.exportMultiple(multiExport, filename);
  }

  exportReport(occurence: string, isSingle = true) {
    const keys = ['tax', 'amount']
    let headers = [];
    let filename = `Sales_tax_paid_${this.selectedYear}_${occurence}`;
    let data = [];
    let arr = [];
    let taxMap = null;
    const labels = [...this.labels, 'Total'];
    switch (occurence) {
      case 'year':
        headers = ['', this.selectedYear];
        arr = this.yearMap;
        taxMap = Array.from(this.salesTaxMap)
        break;
      case 'quarter':
        headers = ['', ...this.QUARTERS];
        arr = this.quarterMap;
        taxMap = this.quarterlySalesTaxMap;
        break;
      case 'month':
        headers = ['', ...this.MONTHS];
        arr = this.monthlyMap;
        taxMap = this.monthlySalesTaxMap;
        break;
    }

    labels.forEach((label, labelIndex) => {
      let rawData = {};
      headers.forEach((header, index) => {
        keys.forEach(key => {
          if (labelIndex + 1 != labels.length) {
            rawData[index != 0 ? `${header} (${key})` : header] = index != 0 ? this.filterMultiArrByLabelAndKey(occurence == 'year' ? arr : arr[index - 1], label, key, occurence, occurence == 'year' ? this.selectedYear : index)
              : label;
          } else {
            rawData[index != 0 ? `${header} (${key})` : header] = index == 0 ? label : occurence != 'year' ? this.getSalesTotal(taxMap, index, key) : taxMap.reduce((acc, entry) => { return acc + entry[1][key]; }, 0)
          }
        });
      });
      data.push(rawData);
    });
    if(isSingle) this.excelDownloaderService.export(data, filename, occurence.toLocaleUpperCase());
    return data;
  }
}
