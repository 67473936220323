<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <p class="mb-0">{{message}}</p>
</div>
<div class="modal-footer">
    <button ngbAutofocus type="button" class="btn" [class.btn-primary]="actionBtnType == 'PRIMARY'" [class.btn-danger]="actionBtnType == 'DANGER'" (click)="actionConfirmed();">{{actionBtnTitle}}</button>
    <button type="button" class="btn btn-secondary" (click)="actionCanceled();">{{cancelBtnTitle}}</button>
</div>