import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../alert.service';
import { BuyNumberModalComponent } from '../modals/buy-number-modal/buy-number-modal.component';
import { faCheck, faClose, faEllipsis, faGear, faPlus, faQuestion, faQuestionCircle, faSave, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import Stepper from 'bs-stepper';
import { NameDialogComponent } from '../../name-dialog/name-dialog.component';
import { SmsCallSettingsService } from '../../sms-call-settings.service';
import { SmsCallService } from '../sms-call.service';

@Component({
  selector: 'app-sms-call-registration',
  templateUrl: './sms-call-registration.component.html',
  styleUrls: ['./sms-call-registration.component.css']
})
export class SmsCallRegistrationComponent implements OnInit, OnChanges {
  private a2pStepper: Stepper;

  faPlus = faPlus;
  faQuestion = faQuestion;
  faCheck = faCheck;

  @Input() subAccountDetails!: any;
  @Input() orgNumbers!: any;
  brandList = [];

  constructor(
    private modalService: NgbModal,
    private alertService: AlertService, 
    private smsCallService: SmsCallService,
    private smsCallSettingService: SmsCallSettingsService
  ) { }


  ngOnInit(): void {
    if (this.subAccountDetails?.subAccountId) {
      this.initializeStepper();
      this.checkFormStep();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkFormStep()
  }

  initializeStepper() {
    if (document && document.querySelector('#settingsStepper')) {
      this.a2pStepper = new Stepper(document.querySelector('#stepperA2P'), {
        linear: false,
        animation: true
      })
    }
  }

  checkFormStep() {
    console.log(this.orgNumbers)

    if (this.orgNumbers.length > 0) {
      console.log('should nest row')
      this.a2pStepper.to(2)
    }

    if (this.subAccountDetails.a2pDetails.step1) {
      this.a2pStepper.to(3)
    }

    if (this.subAccountDetails.a2pDetails.step2) {
      this.a2pStepper.to(4)
    }
  }

  openBuyNumber() {
    const modaNumberRef = this.modalService.open(BuyNumberModalComponent, { size: 'lg' });
    modaNumberRef.componentInstance.buyNumber.subscribe(res => {
      if (res) this.alertService.showSuccessAlert('Successfully bought a number');
      // TODO emit to refresh
    })
  }

  submitForReview() {
    this.smsCallService.submitForReview()
      .subscribe((res) => {
        if (res) this.alertService.showSuccessAlert('Application Submitted. Kindly wait for an email regarding the status of your application');

        // TODO redirect to pending application
      })
  }

  openBrandRegistrationModal() {
    const modalRef = this.modalService.open(NameDialogComponent);
    modalRef.componentInstance.saved.subscribe((data: any) => {
      this.smsCallSettingService.getAddMessagingService(data).subscribe(() => {
        this.alertService.showSuccessAlert('Brand name submitted!');
      });
    });
  }

  popOverOptions(popover: NgbPopover, num: any): void {
    if (popover.isOpen()) popover.close();
    else popover.open({ popover, num });
  }
}
