import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AlertService } from '../alert.service';
import { SettingService } from '../setting.service';
import { faCheck, faQuestion, faSave, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { lastValueFrom } from 'rxjs';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthService } from '../auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TinselPaySignUpModalComponent } from '../tinsel-pay-sign-up-modal/tinsel-pay-sign-up-modal.component';

@Component({
  selector: 'app-settings-payments',
  templateUrl: './settings-payments.component.html',
  styleUrls: ['./settings-payments.component.css']
})
export class SettingsPaymentsComponent implements OnInit {

  // Properties
  tinselPayCardForm: UntypedFormGroup;
  tinselPayAchForm: UntypedFormGroup;
  squarePaymentForm: UntypedFormGroup;
  venmoPaymentForm: UntypedFormGroup;

  // Tinsel Pay
  tinselPayCardPaymentIsEnabled: boolean = false;
  tinselPayCardPassFee: boolean = false;
  tinselPayAchPaymentIsEnabled: boolean = false;

  // Square
  squarePaymentIsEnabled: boolean = false;

  // Venmo
  venmoPaymentIsEnabled: boolean = false;
  venmoProfileUrl: string;
  venmoCodeExists: boolean = false;
  venmoCodeUrl: string | SafeUrl;
  fileReadyForUpload: boolean = false;

  // Cash
  cashPaymentIsEnabled: boolean = false;

  // Check
  checkPaymentIsEnabled: boolean = false;

  // Font Awesome Properties
  faCheck = faCheck;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;
  faSave = faSave;
  faQuestion = faQuestion;

  constructor(private authService: AuthService,
    private settingService: SettingService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.tinselPayCardForm = new UntypedFormGroup({
      tokenizationKey: new UntypedFormControl(),
      securityKey: new UntypedFormControl()
    });
    this.tinselPayAchForm = new UntypedFormGroup({
      merchantId: new UntypedFormControl(),
      serviceKey: new UntypedFormControl()
    });
    this.squarePaymentForm = new UntypedFormGroup({
      applicationId: new UntypedFormControl(),
      locationId: new UntypedFormControl(),
      accessToken: new UntypedFormControl()
    });
    this.venmoPaymentForm = new UntypedFormGroup({
      url: new UntypedFormControl()
    });
    this.getTinselPaySettings();
    this.getSquareSettings();
    this.getVenmoSettings();
    this.getCashSettings();
    this.getCheckSettings();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Tinsel Pay Sign Up Modal
  openTinselPaySignUpModal(): void {
    this.modalService.open(TinselPaySignUpModalComponent);
  }

  /* ----- Tinsel Pay ----- */

  // Get Tinsel Pay Settings
  private getTinselPaySettings(): void {
    this.settingService.getTinselPaySettings().subscribe((tinselPaySettings) => {
      this.tinselPayCardPaymentIsEnabled = tinselPaySettings.card.isEnabled;
      this.tinselPayCardPassFee = tinselPaySettings.card.passFee;
      this.tinselPayAchPaymentIsEnabled = tinselPaySettings.ach.isEnabled;
      // Card
      this.tinselPayCardForm.controls.tokenizationKey.setValue(tinselPaySettings.card.tokenizationKey);
      this.tinselPayCardForm.controls.securityKey.setValue(tinselPaySettings.card.securityKey);
      // ACH
      this.tinselPayAchForm.controls.merchantId.setValue(tinselPaySettings.ach.merchantId);
      this.tinselPayAchForm.controls.serviceKey.setValue(tinselPaySettings.ach.serviceKey);
    });
  }

  // Update Tinsel Pay Settings
  updateTinselPaySettings(): void {
    const data: any = {
      tokenizationKey: this.tinselPayCardForm.value.tokenizationKey,
      securityKey: this.tinselPayCardForm.value.securityKey,
      merchantId: this.tinselPayAchForm.value.merchantId,
      serviceKey: this.tinselPayAchForm.value.serviceKey
    };
    this.settingService.updateTinselPaySettings(data).subscribe(() => {
      this.alertService.showSuccessAlert('Tinsel Pay Settings Updated');
      this.getTinselPaySettings();
    });
  }

  // Toggle Tinsel Pay Card
  toggleTinselPayCard(): void {
    if (this.tinselPayCardPaymentIsEnabled || this.tinselPayCardForm.valid) {
      this.settingService.updateTinselPaySettings({ cardIsEnabled: !this.tinselPayCardPaymentIsEnabled }).subscribe(() => {
        this.alertService.showSuccessAlert(`Tinsel Pay Card ${(!this.tinselPayCardPaymentIsEnabled) ? 'Enabled' : 'Disabled'}`);
        this.getTinselPaySettings();
      });
    } else {
      this.tinselPayCardForm.markAllAsTouched();
    }
  }

  // Toggle Tinsel Pay ACH
  toggleTinselPayAch(): void {
    if (this.tinselPayAchPaymentIsEnabled || this.tinselPayAchForm.valid) {
      this.settingService.updateTinselPaySettings({ achIsEnabled: !this.tinselPayAchPaymentIsEnabled }).subscribe(() => {
        this.alertService.showSuccessAlert(`Tinsel Pay ACH ${(!this.tinselPayAchPaymentIsEnabled) ? 'Enabled' : 'Disabled'}`);
        this.getTinselPaySettings();
      });
    } else {
      this.tinselPayAchForm.markAllAsTouched();
    }
  }

  // Tinsel Pay Card Form Accessors
  get tokenizationKey() { return this.tinselPayCardForm.controls.tokenizationKey; }
  get securityKey() { return this.tinselPayCardForm.controls.securityKey; }

  // Tinsel Pay ACH Form Accessors
  get merchantId() { return this.tinselPayAchForm.controls.merchantId; }
  get serviceKey() { return this.tinselPayAchForm.controls.serviceKey; }

  /* ----- Square ----- */

  // Get Square Settings
  private getSquareSettings(): void {
    this.settingService.getSquareSettings().subscribe((squareSettings) => {
      this.squarePaymentIsEnabled = squareSettings.isEnabled;
      this.squarePaymentForm.controls.applicationId.setValue(squareSettings.applicationId);
      this.squarePaymentForm.controls.locationId.setValue(squareSettings.locationId);
      this.squarePaymentForm.controls.accessToken.setValue(squareSettings.accessToken);
    });
  }

  // Update Square Settings
  updateSquareSettings(): void {
    this.settingService.updateSquareSettings(this.squarePaymentForm.value).subscribe(() => {
      this.alertService.showSuccessAlert('Square Settings Updated');
      this.getSquareSettings();
    });
  }

  // Toggle Square
  toggleSquare(): void {
    if (this.squarePaymentIsEnabled || this.squarePaymentForm.valid) {
      this.settingService.updateSquareSettings({ isEnabled: !this.squarePaymentIsEnabled }).subscribe(() => {
        this.alertService.showSuccessAlert(`Square ${(!this.squarePaymentIsEnabled) ? 'Enabled' : 'Disabled'}`);
        this.getSquareSettings();
      });
    } else {
      this.squarePaymentForm.markAllAsTouched();
    }
  }

  // Square Payment Form Accessors
  get applicationId() { return this.squarePaymentForm.controls.applicationId; }
  get locationId() { return this.squarePaymentForm.controls.locationId; }
  get accessToken() { return this.squarePaymentForm.controls.accessToken; }

  /* ----- Venmo ----- */

  // Get Venmo Settings
  private async getVenmoSettings(): Promise<void> {
    const currentUser = await this.authService.getCurrentUser();
    const venmoSettings = await lastValueFrom(this.settingService.getVenmoSettings());
    this.venmoPaymentIsEnabled = venmoSettings.isEnabled;
    this.venmoProfileUrl = venmoSettings.url;
    this.venmoCodeExists = venmoSettings.qrCodeExists;
    this.venmoPaymentForm.controls.url.setValue(venmoSettings.url);
    this.venmoCodeUrl = (venmoSettings.qrCodeExists) ? this.settingService.getVenmoCodeUrl(currentUser.organizationId) : null;
  }

  // Toggle Venmo
  toggleVenmo(): void {
    if (this.venmoPaymentIsEnabled || (this.venmoPaymentForm.valid && this.venmoCodeExists)) {
      this.settingService.updateVenmoProperty('isEnabled', !this.venmoPaymentIsEnabled).subscribe(() => {
        this.alertService.showSuccessAlert(`Venmo ${(!this.venmoPaymentIsEnabled) ? 'Enabled' : 'Disabled'}`);
        this.getVenmoSettings();
      });
    } else {
      if (!this.venmoCodeExists) this.alertService.showWarningAlert('QR Code is not uploaded.');
      this.venmoPaymentForm.markAllAsTouched();
    }
  }

  // Update Venmo Payment URL
  updateVenmoPaymentUrl(): void {
    this.settingService.updateVenmoProperty('url', this.venmoPaymentForm.value.url).subscribe(() => {
      this.alertService.showSuccessAlert('Venmo URL Updated');
      this.getVenmoSettings();
    });
  }

  // Upload Venmo Code
  async uploadVenmoCode(): Promise<void> {
    const file = (<HTMLInputElement>document.getElementById('VENMO_CODE_FILE')).files[0];
    const presignedUrl = await lastValueFrom(this.settingService.getVenmoCodePresignedUploadUrl(file.type));
    await new Promise<void>((resolve, reject) => {
      this.settingService.uploadVenmoCode(presignedUrl, file).subscribe((event: HttpEvent<any>) => {
        if (event.type == HttpEventType.Response) {
          (event.ok) ? resolve() : reject();
        }
      });
    });
    await lastValueFrom(this.settingService.updateVenmoProperty('qrCodeExists', true));
    this.alertService.showSuccessAlert('Venmo Code Uploaded');
    this.resetFileUpload();
    this.getVenmoSettings();
  }

  // Browse Files
  browseFiles(): void {
    document.getElementById('VENMO_CODE_FILE').click();
  }

  // File Input Changed
  fileInputChanged(files: File[]): void {
    this.venmoCodeUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
    this.fileReadyForUpload = true;
  }

  // Reset File Upload
  private resetFileUpload(): void {
    this.fileReadyForUpload = false;
  }

  // Delete Venmo Payment Code
  deleteVenmoPaymentCode(): void {
    this.settingService.deleteVenmoCode().subscribe(() => {
      this.alertService.showSuccessAlert('Venmo Code Deleted');
      this.getVenmoSettings();
    });
  }

  // Venmo Payment Form Accessors
  get url() { return this.venmoPaymentForm.controls.url; }

  /* ----- Cash ----- */

  // Get Cash Settings
  private async getCashSettings(): Promise<void> {
    const currentUser = await this.authService.getCurrentUser();
    const cashSettings = await lastValueFrom(this.settingService.getCashSettings(currentUser.organizationId));
    this.cashPaymentIsEnabled = cashSettings.isEnabled;
    (document.getElementById('CASH_MESSAGE') as HTMLTextAreaElement).innerHTML = cashSettings.message;
  }

  // Toggle Cash
  toggleCash(): void {
    this.settingService.updateCashProperty('isEnabled', !this.cashPaymentIsEnabled).subscribe(() => {
      this.alertService.showSuccessAlert(`Cash ${(!this.cashPaymentIsEnabled) ? 'Enabled' : 'Disabled'}`);
      this.getCashSettings();
    });
  }

  // Update Cash Message
  updateCashMessage(): void {
    const message = (document.getElementById('CASH_MESSAGE') as HTMLTextAreaElement).innerHTML;
    this.settingService.updateCashProperty('message', message).subscribe(() => {
      this.alertService.showSuccessAlert('Cash Message Updated');
      this.getCashSettings();
    });
  }

  /* ----- Check ----- */

  // Get Check Settings
  private async getCheckSettings(): Promise<void> {
    const currentUser = await this.authService.getCurrentUser();
    const checkSettings = await lastValueFrom(this.settingService.getCheckSettings(currentUser.organizationId));
    this.checkPaymentIsEnabled = checkSettings.isEnabled;
    (document.getElementById('CHECK_MESSAGE') as HTMLTextAreaElement).innerHTML = checkSettings.message;
  }

  // Toggle Check
  toggleCheck(): void {
    this.settingService.updateCheckProperty('isEnabled', !this.checkPaymentIsEnabled).subscribe(() => {
      this.alertService.showSuccessAlert(`Check ${(!this.checkPaymentIsEnabled) ? 'Enabled' : 'Disabled'}`);
      this.getCheckSettings();
    });
  }

  // Update Check Message
  updateCheckMessage(): void {
    const message = (document.getElementById('CHECK_MESSAGE') as HTMLTextAreaElement).innerHTML;
    this.settingService.updateCheckProperty('message', message).subscribe(() => {
      this.alertService.showSuccessAlert('Check Message Updated');
      this.getCheckSettings();
    });
  }
}
