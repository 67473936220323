import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormBuilder } from '@angular/forms';
import { lastValueFrom, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Contact } from '../contact';
import { ContactService } from '../contact.service';
import { EmailService } from '../email.service';
import { SettingService } from '../setting.service';
import { AlertService } from '../alert.service';
import { NgbActiveModal, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { faTimes, faBold } from '@fortawesome/free-solid-svg-icons';
import { LogService } from '../log.service';
import { EmailLog } from '../email-log';
import { EmailViewComponent } from '../email-view/email-view.component';
import { InvoiceService } from '../invoice.service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent implements OnInit {

  // Properties
  @Input() type: string;
  @Input() subject: string;
  @Input() showAttachments: boolean = false;
  @Input() clientId: string;
  @Input() projectId: string;
  @Input() proposalId: string;
  @Input() invoiceId: string;
  @Input() paymentId: string;
  @Output() emailSent: EventEmitter<void> = new EventEmitter<void>();
  sendEmailForm: UntypedFormGroup;
  contacts: Contact[] = [];
  emailLogs: EmailLog[] = [];

  private emailRegex: RegExp = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  // Email Addresses
  toEmailAddresses: string[] = [];
  ccEmailAddresses: string[] = [];

  toExistingEmailAddresses: string[] = [];
  toEmailAddressTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  toEmailAddressTypeaheadInputFocus$ = new Subject<string>();

  ccExistingEmailAddresses: string[] = [];
  ccEmailAddressTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  ccEmailAddressTypeaheadInputFocus$ = new Subject<string>();

  emailBody: any = '';

  // Font Awesome Properties
  faTimes = faTimes;
  faBold = faBold;

  constructor(private settingService: SettingService,
    private contactService: ContactService,
    private invoiceService: InvoiceService,
    private emailService: EmailService,
    private alertService: AlertService,
    private logService: LogService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.sendEmailForm = this.formBuilder.group({
      subject: [''],
      body: ['']
    });
    this.getContactsForClient();
  }

  // Get Contacts For Client
  private getContactsForClient(): void {
    const params = {
      searchTerm: null,
      sortBy: 'contact_first_name',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:client_id': JSON.stringify([this.clientId ? this.clientId : null])
    };

    if(!this.clientId){
      delete params['filter:client_id']
    }

    this.contactService.getContacts(params).subscribe((res) => {
      this.contacts = res.contacts;
      this.prepareToEmailAddressTypeahead();
      this.prepareCcEmailAddressTypeahead();
      if(this.clientId){
        this.setSendEmailFormValues();
      }
      

      if(this.type != 'GENERAL'){
        this.getEmailTemplate();
        this.getFilteredEmailLogs();
      }
    });
  }

  // Get Email Template
  async getEmailTemplate(): Promise<void> {
    let template = await lastValueFrom(this.settingService.getEmailTemplate(this.type));
    const primaryContact = this.contacts.find((contact) => { return contact.isPrimary; });
    if (primaryContact) template = template.replace('##FIRST_NAME##', primaryContact.firstName);
    if (this.paymentId) {
      const invoicePricing = await lastValueFrom(this.invoiceService.getInvoicePricing(this.invoiceId));
      const currentPayment = invoicePricing.payments.find((payment) => { return payment.id = this.paymentId; });
      template = template.replace('##AMOUNT_PAID##', `$${currentPayment.amount.toFixed(2)}`);
      template = template.replace('##INVOICE_BALANCE##', `$${invoicePricing.invoice.amountDue.toFixed(2)}`);
      template = template.replace('##PROPOSAL_BALANCE##', `$${invoicePricing.proposalBalance.toFixed(2)}`);
    }
    this.sendEmailForm.controls['body'].setValue(template.toString());
  }

  // Get Filtered Email Logs
  getFilteredEmailLogs(): void {
    let referenceId = null;
    switch (this.type) {
      case 'PROPOSAL':
        referenceId = this.proposalId;
        break;
      case 'INVOICE':
        referenceId = this.invoiceId;
        break;
      case 'PAYMENT':
        referenceId = this.paymentId;
        break;
      case 'REVIEW':
        referenceId = this.projectId;
        break;
      case 'GENERAL':
        //referenceId = this.projectId;
        break;
      default:
        this.alertService.showWarningAlert('Unknown Email Type');
        break;
    }
    if(referenceId){
      this.logService.getFilteredEmailLogs(this.type, referenceId).subscribe((logs) => {
        this.emailLogs = logs;
      });
    }

  }

  // Opem Email View Modal
  openEmailViewModal(emailLog: EmailLog): void {
    const modalRef = this.modalService.open(EmailViewComponent);
    modalRef.componentInstance.emailLog = emailLog;
  }

  // Prepare To Email Address Typeahead
  private prepareToEmailAddressTypeahead(): void {
    this.toEmailAddressTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      const inputFocus$ = this.toEmailAddressTypeaheadInputFocus$;
      this.toExistingEmailAddresses.length = 0;
      for (const contact of this.contacts) {
        if (contact.email != null) this.toExistingEmailAddresses.push(contact.email);
      }
      return merge(debouncedText$, inputFocus$).pipe(
        map((term) => {
          return ((term.length == 0) ? this.toExistingEmailAddresses : this.toExistingEmailAddresses.filter((element) => {
            return element.toLowerCase().indexOf(term.toLowerCase()) > -1;
          })).slice(0, 10);
        })
      );
    }
  }

  // Prepare Cc Email Address Typeahead
  private prepareCcEmailAddressTypeahead(): void {
    this.ccEmailAddressTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      const inputFocus$ = this.ccEmailAddressTypeaheadInputFocus$;
      this.ccExistingEmailAddresses.length = 0;
      for (const contact of this.contacts) {
        if (contact.email != null) this.ccExistingEmailAddresses.push(contact.email);
      }
      return merge(debouncedText$, inputFocus$).pipe(
        map((term) => {
          return ((term.length == 0) ? this.ccExistingEmailAddresses : this.ccExistingEmailAddresses.filter((element) => {
            return element.toLowerCase().indexOf(term.toLowerCase()) > -1;
          })).slice(0, 10);
        })
      );
    }
  }

  // Set Send Email Form Values
  private setSendEmailFormValues(): void {
    for (const contact of this.contacts) {
      if (contact.isPrimary && contact.email !== null) {
        this.toEmailAddresses.push(contact.email);
      }
    }
    this.sendEmailForm.controls.subject.setValue(this.subject);
  }

  // Add To Email Address
  addToEmailAddress(event: NgbTypeaheadSelectItemEvent): void {
    if (event) event.preventDefault();
    const toEmailInput = (<HTMLInputElement>document.getElementById('SEND_EMAIL_TO'));
    const emailAddress = (event) ? event.item : toEmailInput.value;
    try {
      if (emailAddress == undefined || emailAddress == null || emailAddress.length == 0) throw new Error('Email cannot be blank.');
      if (this.toEmailAddresses.includes(emailAddress)) throw new Error('Cannot add duplicate email.');
      if (this.toEmailAddresses.length == 10) throw new Error('Email cannot exceed 10 To recipients.');
      if (!this.emailRegex.test(emailAddress)) throw new Error('Email is not formatted correctly.');
      this.toEmailAddresses.push(emailAddress);
      toEmailInput.value = null;
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  // Remove To Email Address
  removeToEmailAddress(index: number): void {
    const tempArray = [];
    for (var i = 0; i < this.toEmailAddresses.length; i++) {
      if (i != index) tempArray.push(this.toEmailAddresses[i]);
    }
    this.toEmailAddresses = tempArray;
  }

  // Add Cc Email Address
  addCcEmailAddress(event: NgbTypeaheadSelectItemEvent): void {
    if (event) event.preventDefault();
    const ccEmailInput = (<HTMLInputElement>document.getElementById('SEND_EMAIL_CC'));
    const emailAddress = (event) ? event.item : ccEmailInput.value;
    try {
      if (emailAddress == undefined || emailAddress == null || emailAddress.length == 0) throw new Error('Email cannot be blank.');
      if (this.ccEmailAddresses.includes(emailAddress)) throw new Error('Cannot add duplicate email.');
      if (this.ccEmailAddresses.length == 10) throw new Error('Email cannot exceed 10 Cc recipients.');
      if (!this.emailRegex.test(emailAddress)) throw new Error('Email is not formatted correctly.');
      this.ccEmailAddresses.push(emailAddress);
      ccEmailInput.value = null;
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  // Remove Cc Email Address
  removeCcEmailAddress(index: number): void {
    const tempArray = [];
    for (var i = 0; i < this.ccEmailAddresses.length; i++) {
      if (i != index) tempArray.push(this.ccEmailAddresses[i]);
    }
    this.ccEmailAddresses = tempArray;
  }

  // Bold Selection
  boldSelection(): void {
    document.execCommand('bold');
  }

  // Send Email
  sendEmail(): void {
    switch (this.type) {
      case 'PROPOSAL':
        this.sendProposalEmail();
        break;
      case 'INVOICE':
        this.sendInvoiceEmail();
        break;
      case 'PAYMENT':
        this.sendPaymentEmail();
        break;
      case 'REVIEW':
        this.sendReviewEmail();
        break;
      case 'GENERAL':
          this.sendGeneralEmail();
          break;
      default:
        this.alertService.showWarningAlert('Unknown Email Type');
        break;
    }
  }

  // Send Proposal Email
  private sendProposalEmail(): void {
    try {
      this.alertService.showInfoAlert('Sending...');
      const subject = this.sendEmailForm.value.subject;
      //const body = document.getElementById('SEND_EMAIL_BODY').innerHTML;
      const body = this.sendEmailForm.value.body;
      if (this.toEmailAddresses.length == 0) throw new Error('No recipients have been added.');
      if (subject == undefined || subject == null || subject.length == 0) throw new Error('Subject cannot be blank.')
      if (body == undefined || body == null || body.length == 0) throw new Error('Body cannot be blank.');
      const data = {
        proposalId: this.proposalId,
        to: this.toEmailAddresses,
        cc: this.ccEmailAddresses,
        subject: subject,
        body: body
      };
      this.emailService.sendProposalEmail(data).subscribe(() => {
        this.alertService.showSuccessAlert('Email Sent');
        this.modal.close();
        this.emailSent.emit();
      });
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  // Send Invoice Email
  private sendInvoiceEmail(): void {
    try {
      this.alertService.showInfoAlert('Sending...');
      const subject = this.sendEmailForm.value.subject;
      //const body = document.getElementById('SEND_EMAIL_BODY').innerHTML;

      const body = this.sendEmailForm.value.body;
      if (this.toEmailAddresses.length == 0) throw new Error('No recipients have been added.');
      if (subject == undefined || subject == null || subject.length == 0) throw new Error('Subject cannot be blank.')
      if (body == undefined || body == null || body.length == 0) throw new Error('Body cannot be blank.');
      const data = {
        invoiceId: this.invoiceId,
        to: this.toEmailAddresses,
        cc: this.ccEmailAddresses,
        subject: subject,
        body: body
      };
      this.emailService.sendInvoiceEmail(data).subscribe(() => {
        this.alertService.showSuccessAlert('Email Sent');
        this.modal.close();
        this.emailSent.emit();
      });
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  // Send Payment Email
  private sendPaymentEmail(): void {
    try {
      this.alertService.showInfoAlert('Sending...');
      const subject = this.sendEmailForm.value.subject;
      //const body = document.getElementById('SEND_EMAIL_BODY').innerHTML;
      const body = this.sendEmailForm.value.body;
      if (this.toEmailAddresses.length == 0) throw new Error('No recipients have been added.');
      if (subject == undefined || subject == null || subject.length == 0) throw new Error('Subject cannot be blank.')
      if (body == undefined || body == null || body.length == 0) throw new Error('Body cannot be blank.');
      const data = {
        paymentId: this.paymentId,
        to: this.toEmailAddresses,
        cc: this.ccEmailAddresses,
        subject: subject,
        body: body
      };
      this.emailService.sendPaymentEmail(data).subscribe(() => {
        this.alertService.showSuccessAlert('Email Sent');
        this.modal.close();
        this.emailSent.emit();
      });
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  // Send Review Email
  private sendReviewEmail(): void {
    try {
      this.alertService.showInfoAlert('Sending...');
      const subject = this.sendEmailForm.value.subject;
      //const body = document.getElementById('SEND_EMAIL_BODY').innerHTML;

      const body = this.sendEmailForm.value.body;
      if (this.toEmailAddresses.length == 0) throw new Error('No recipients have been added.');
      if (subject == undefined || subject == null || subject.length == 0) throw new Error('Subject cannot be blank.')
      if (body == undefined || body == null || body.length == 0) throw new Error('Body cannot be blank.');
      const data = {
        projectId: this.projectId,
        to: this.toEmailAddresses,
        cc: this.ccEmailAddresses,
        subject: subject,
        body: body
      };
      this.emailService.sendReviewEmail(data).subscribe(() => {
        this.alertService.showSuccessAlert('Email Sent');
        this.modal.close();
        this.emailSent.emit();
      });
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  private sendGeneralEmail(): void {
    try {
      this.alertService.showInfoAlert('Sending...');
      const subject = this.sendEmailForm.value.subject;
      //const body = document.getElementById('SEND_EMAIL_BODY').innerHTML;
      const body = this.sendEmailForm.value.body;
      if (this.toEmailAddresses.length == 0) throw new Error('No recipients have been added.');
      if (subject == undefined || subject == null || subject.length == 0) throw new Error('Subject cannot be blank.')
      if (body == undefined || body == null || body.length == 0) throw new Error('Body cannot be blank.');
      const data = {
        to: this.toEmailAddresses,
        cc: this.ccEmailAddresses,
        subject: subject,
        body: body
      };
      this.emailService.sendGenericEmail(data).subscribe(() => {
        this.alertService.showSuccessAlert('Email Sent');
        this.modal.close();
        this.emailSent.emit();
      });
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  // Reset Send Email Form
  resetSendEmailForm(): void {
    this.sendEmailForm.reset();
    this.toEmailAddresses.length = 0;
    this.ccEmailAddresses.length = 0;
    for (const contact of this.contacts) {
      if (contact.isPrimary) this.toEmailAddresses.push(contact.email);
    }
    this.sendEmailForm.controls.subject.setValue(this.subject);
    this.getEmailTemplate();
  }

  // Send Email Form Accessors
  get sendEmailSubject() { return this.sendEmailForm.controls.subject; }
}
