import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { faAngleLeft, faAngleRight, faCalendar, faFileDownload, faRotateLeft, faSortDown, faSortUp, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { ReportService } from '../report.service';
import { TableControlService } from '../table-control.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-reports-storage-locations',
  templateUrl: './reports-storage-locations.component.html',
  styleUrls: ['./reports-storage-locations.component.css']
})
export class ReportsStorageLocationsComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Properties
  entries: any[] = [];
  entryCount: number = 0;
  maxSize: number = 10;
  isMobileView: boolean = false;

  sortOptions = [
    { label: 'Client', value: 'c.client_name' },
    { label: 'Project', value: 'proj.project_name' },
    { label: 'Storage Location', value: 'proj.project_storage_location' },
    { label: 'Install Date', value: 'install_date' },
    { label: 'Removal Date', value: 'remove_date' }
  ];
  sortBy: string = 'c.client_name';
  sortDirection: string = 'ASC';

  // Table Controls
  tableControls: TableControlService;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  faCalendar = faCalendar;
  faFileDownload = faFileDownload;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faRotateLeft = faRotateLeft;
  faSortAmountDownAlt = faSortAmountDownAlt;


  // FormGroup
  reportFormGroup: FormGroup;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(
    private fb: FormBuilder,
    private reportService: ReportService,
    public location: Location) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
          this.tableControls.setLimit(20);
        }
      });
    }

  ngOnInit(): void {
    this.tableControls = new TableControlService('Reports.StorageLocations', true, 'c.client_name', 'ASC');
    this.tableControls.refresh.subscribe(() => {
      this.getStorageLocationsReport();
    });
    this.getStorageLocationsReport();
    this.reportFormGroup = this.fb.group({
      projectYear: [0]
    });

    this.reportFormGroup.controls.projectYear.valueChanges.subscribe(val => {
      if (val != 0) {

        this.tableControls.setFilter('proj.project_year', [val.toString()])
        this.tableControls.refresh.subscribe(() => {
          this.getStorageLocationsReport();
        });
      } else {
        this.tableControls.resetFilters(['proj.project_year'])
      }
      this.getStorageLocationsReport();
    })
    this.checkScreenSize();
  }

  // Get Storage Locations Report
  private getStorageLocationsReport(): void {
    let params = this.tableControls.getParams();
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
    };
    this.reportService.getStorageLocationsReport(params).subscribe((reportData) => {
      this.entries = reportData.entries;
      this.entryCount = reportData.count;
    });
  }

  // Download Report
  downloadReport(): void {
    const url = new URL('/api/reports/projects/storageLocations/download', environment.apiUrl);
    window.open(url.toString());
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
    this.isMobileView ? this.tableControls.setLimit(5): this.tableControls.setLimit(20);
  }

  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getStorageLocationsReport();
  }

}
