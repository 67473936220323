import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { User } from '../user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../notification.service';
import { faBell, faMobile, faSms } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  // Properties
  user: User;
  unreadNotificationCount: number = 0;

  // Font Awesome Properties
  faBell = faBell;
  faMobile = faMobile;
  login = JSON.parse(localStorage.getItem("login"))

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {
    this.user = await this.authService.getCurrentUser();
    this.notificationService.refreshNotifications();
    this.listenForNotifications();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Listen For Notifications
  private listenForNotifications(): void {
    this.notificationService.notificationsUpdated.subscribe(() => {
      this.unreadNotificationCount = this.notificationService.notifications.filter((notification) => { return !notification.isRead; }).length;
    });
  }

  // Open Notification Container
  openNotificationContainer(): void {
    this.notificationService.openNotificationContainer();
  }

  openConversationNotificationContainer(): void {
    this.notificationService.openConversationNotificationContainer();
  }

  redirectToSubs() {
    this.router.navigate(['/settings'], { relativeTo: this.route })
    sessionStorage.setItem('settingsNavActiveId', 'SUBSCRIPTIONS');
  }
}
