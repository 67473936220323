import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Payment } from './payment';
import { TinselPayVault } from './tinsel-pay-vault';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  // Add Payment
  addPayment(payment: any): Observable<any> {
    return this.http.post<any>(`/payments`, payment);
  }

  // Update Payment
  updatePayment(payment: any): Observable<any> {
    return this.http.put<any>(`/payments/${payment.id}`, payment);
  }

  // Delete Payment
  deletePayment(paymentId: string): Observable<any> {
    return this.http.delete<any>(`/payments/${paymentId}`);
  }

  // Get Payments
  getPayments(params: any): Observable<{ payments: Payment[], count: number }> {
    return this.http.get<{ payments: Payment[], count: number }>(`/payments`, {
      params: params
    });
  }

  // Get Remaining Payment Amount
  getRemainingPaymentAmount(paymentId: string): Observable<number> {
    return this.http.get<number>(`/payments/${paymentId}/remainingPaymentAmount`);
  }

  // Get Distinct Column Values
  getDistinctColumnValues(params: any): Observable<string[]> {
    return this.http.get<string[]>(`/payments/distinctColumnValues`, {
      params: params
    });
  }

  // Get Distinct Payment Date Years
  getDistinctPaymentDateYears(): Observable<number[]> {
    return this.http.get<number[]>(`/payments/distinctPaymentDateYears`);
  }

  /* ----- Refunds ----- */

  // Process Refund
  processRefund(paymentId: string, data: any): Observable<void> {
    return this.http.post<void>(`/payments/${paymentId}/refund`, data);
  }

  /* ----- Square ----- */

  // Process Square Payment
  processSquarePayment(data: any): Observable<void> {
    return this.http.post<void>(`/payments/processSquarePayment`, data);
  }

  /* ----- Tinsel Pay ----- */

  // Process Tinsel Pay Card Sale
  processTinselPayCardSale(data: any): Observable<void> {
    return this.http.post<void>(`/payments/tinselPay/card/sale`, data);
  }

  // Process Tinsel Pay Card Refund
  processTinselPayCardRefund(data: any): Observable<void> {
    return this.http.post<void>(`/payments/tinselPay/card/refund`, data);
  }

  // Process Tinsel Pay ACH Sale
  processTinselPayAchSale(data: any): Observable<void> {
    return this.http.post<void>(`/payments/tinselPay/ach/sale`, data);
  }

  /* ----- Tinsel Pay Vaults ----- */

  // Get Tinsel Pay Vaults
  getTinselPayVaults(clientId: string, type: string): Observable<TinselPayVault[]> {
    return this.http.get<TinselPayVault[]>(`/payments/tinselPay/vaults`, {
      params: {
        clientId: clientId,
        type: type
      }
    });
  }

  // Delete Tinsel Pay Card Vault
  deleteTinselPayCardVault(organizationId: string, vaultId: string): Observable<void> {
    return this.http.delete<void>(`/payments/tinselPay/cardVault`, {
      params: {
        organizationId: organizationId,
        vaultId: vaultId
      }
    });
  }

  // Delete Tinsel Pay ACH Vault
  deleteTinselPayAchVault(organizationId: string, vaultId: string): Observable<void> {
    return this.http.delete<void>(`/payments/tinselPay/achVault`, {
      params: {
        organizationId: organizationId,
        vaultId: vaultId
      }
    });
  }

  /* ----- Other ----- */

  // Get Card Payment Icon
  getCardPaymentIcon(brand: string): string {
    if (brand !== null) brand = brand.toUpperCase();
    let url = 'https://static.tinselcrm.com/creditCardPaymentIcons/';
    switch (brand) {
      case 'VISA':
        return url + 'visa.svg';
      case 'MASTERCARD':
        return url + 'mastercard.svg';
      case 'AMERICAN EXPRESS':
        return url + 'amex.svg';
      case 'DISCOVER':
        return url + 'discover.svg';
      case 'DINER\'S CLUB':
        return url + 'diners.svg';
      case 'JCB':
        return url + 'jcb.svg';
      default:
        return url + 'generic.svg';
    }
  }
}
