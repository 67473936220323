import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../alert.service';
import { NewUserModalComponent } from '../new-user-modal/new-user-modal.component';
import { User } from '../user';
import { UserService } from '../user.service';
import { faSave, faArchive, faTrashRestore, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../auth.service';
import { UserRole } from '../user-role';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-settings-users',
  templateUrl: './settings-users.component.html',
  styleUrls: ['./settings-users.component.css']
})
export class SettingsUsersComponent implements OnInit {
   // View child for user roles template
   @ViewChild('userRolesTemplate') userRolesTemplate: TemplateRef<any>;

  // Modals
  private modalReference: NgbModalRef;

  // Forms
  userForm: UntypedFormGroup;
  userAccessForm: UntypedFormGroup;
  userAddressForm: UntypedFormGroup;
  userAttributesForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;

  // Properties
  currentUser: User = null;
  users: User[] = [];
  selectedUser: User = null;
  selectedUserHasHigherAuth: boolean = false;
  showArchived: boolean = false;

  // Font Awesome Imports
  faSave = faSave;
  faArchive = faArchive;
  faTrashRestore = faTrashRestore;
  faInfoCircle = faInfoCircle;

  constructor(private userService: UserService,
    private authService: AuthService,
    private alertService: AlertService,
    private modalService: NgbModal) { }

  async ngOnInit(): Promise<void> {
    this.userForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      email: new UntypedFormControl(),
      phone: new UntypedFormControl()
    });
    this.userAccessForm = new UntypedFormGroup({
      role: new UntypedFormControl('USER'),
      hasCrmAccess: new UntypedFormControl(false),
      hasEmployeeAccess: new UntypedFormControl(false),
      hasSalesAccess: new UntypedFormControl(true),
      isEnabled: new UntypedFormControl(false)
    });
    this.userAddressForm = new UntypedFormGroup({
      street: new UntypedFormControl(),
      city: new UntypedFormControl(),
      state: new UntypedFormControl('SAS'),
      postalCode: new UntypedFormControl()
    });
    this.userAttributesForm = new UntypedFormGroup({
      hourlyRate: new UntypedFormControl(0)
    });
    this.passwordForm = new UntypedFormGroup({
      passwordGeneration: new UntypedFormControl('AUTO'),
      password: new UntypedFormControl(),
      confirmPassword: new UntypedFormControl()
    });
    this.getUsers();
    this.currentUser = await this.authService.getCurrentUser();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Modal
  openModal(content: any): void {
    this.modalReference = this.modalService.open(content);
  }

  checkUserCount() {
    const count = this.validUserCount();
    if (count > 10) {
      const msg = "You are now adding an additional user. Your first 10 users are included in your Tinsel CRM subscription. Additionally users are $0.25 per user per day, only on the days they are active."
      const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
      confirmationModalRef.componentInstance.message = msg;
      confirmationModalRef.componentInstance.actionBtnTitle = "Continue";
      confirmationModalRef.componentInstance.confirmed.subscribe(() => {
        this.openNewUserModal()
      });
    } else {
      this.openNewUserModal()
    }
  }

  openNewUserModal(): void {
    this.modalReference = this.modalService.open(NewUserModalComponent);
    this.modalReference.componentInstance.userAdded.subscribe(() => {
      this.getUsers();
    });
  }

  // Get Users
  private getUsers(): void {
    this.userService.getUsers().subscribe((users) => {
      this.users = users;
      if (this.users.length > 0) this.selectUser(this.showArchived ? this.selectedUser.id: this.users[0].id);
    });
  }

  // Update User
  updateUser(): void {
    if (this.userForm.valid) {
      const user = {
        id: this.selectedUser.id,
        firstName: this.userForm.value.firstName,
        lastName: this.userForm.value.lastName,
        email: this.userForm.value.email,
        phone: this.userForm.value.phone
      };
      this.userService.updateUser(user).subscribe(() => {
        this.alertService.showSuccessAlert('User Updated');
        this.getUsers();
      });
    } else {
      this.userForm.markAllAsTouched();
    }
  }

  // Update User Enabled Status
  updateUserEnabledStatus(isEnabled: boolean): void {
    this.userService.updateUser({ id: this.selectedUser.id, isEnabled: isEnabled }).subscribe(() => {
      this.alertService.showSuccessAlert('User Updated');
      this.getUsers();
    });
  }

  // Update User Role
  updateUserRole(): void {
    if (this.userAccessForm.controls.role.valid) {
      this.userService.updateUser({ id: this.selectedUser.id, role: this.userAccessForm.value.role }).subscribe(() => {
        this.alertService.showSuccessAlert('User Updated');
        this.getUsers();
      });
    } else {
      this.alertService.showWarningAlert('Role is invalid.');
    }
  }

  // Update User CRM Access
  updateUserCrmAccess(hasCrmAccess: boolean): void {
    if (this.userAccessForm.valid) {
      this.userService.updateUser({ id: this.selectedUser.id, hasCrmAccess: hasCrmAccess }).subscribe(() => {
        this.alertService.showSuccessAlert('User Updated');
        this.getUsers();
      });
    } else {
      this.userAccessForm.markAllAsTouched();
    }
  }

  // Update User Employee Access
  updateUserEmployeeAccess(hasEmployeeAccess: boolean): void {
    if (this.userAccessForm.valid) {
      this.userService.updateUser({ id: this.selectedUser.id, hasEmployeeAccess: hasEmployeeAccess }).subscribe(() => {
        this.alertService.showSuccessAlert('User Updated');
        this.getUsers();
      });
    } else {
      this.userAccessForm.markAllAsTouched();
    }
  }

  updateSalesAccess(hasSalesAccess: boolean): void {
    if (this.userAccessForm.valid) {
      this.userService.updateUser({ id: this.selectedUser.id, hasSalesAccess: hasSalesAccess }).subscribe(() => {
        this.alertService.showSuccessAlert('User Updated');
        this.getUsers();
      });
    } else {
      this.userAccessForm.markAllAsTouched();
    }
  }

  // Update User Address
  updateUserAddress(): void {
    if (this.userAddressForm.valid) {
      const address = {
        street: this.userAddressForm.value.street,
        city: this.userAddressForm.value.city,
        county: this.userAddressForm.value.county,
        state: this.userAddressForm.value.state,
        postalCode: this.userAddressForm.value.postalCode
      };
      this.userService.updateUser({ id: this.selectedUser.id, address: address }).subscribe(() => {
        this.alertService.showSuccessAlert('User Updated');
        this.getUsers();
      });
    } else {
      this.alertService.showWarningAlert('Form is inavlid');
    }
  }

  // Update User Attributes
  updateUserAttributes(): void {
    if (this.userAttributesForm.valid) {
      const attributes = {
        hourlyRate: this.userAttributesForm.value.hourlyRate
      };
      this.userService.updateUser({ id: this.selectedUser.id, attributes: attributes }).subscribe(() => {
        this.alertService.showSuccessAlert('User Updated');
        this.getUsers();
      });
    } else {
      this.userAttributesForm.markAllAsTouched();
    }
  }

  // Reset User Password
  resetUserPassword(): void {
    if (this.passwordForm.valid) {
      this.userService.resetUserPassword(this.selectedUser.id, this.passwordForm.value).subscribe(() => {
        this.alertService.showSuccessAlert('User Password Reset');
        this.modalReference.close();
        this.resetPasswordForm();
      });
    } else {
      this.passwordForm.markAllAsTouched();
    }
  }

  // Reset Password Form
  private resetPasswordForm(): void {
    this.passwordForm.reset();
    this.passwordForm.controls.passwordGeneration.setValue('AUTO');
  }

  // Select User
  selectUser(userId: string) {
    this.selectedUser = this.users.find((user) => { return user.id == userId; });
    this.selectedUserHasHigherAuth = UserRole[this.selectedUser.role] > UserRole[this.currentUser.role];
    // User Form
    this.userForm.controls.firstName.setValue(this.selectedUser.firstName);
    this.userForm.controls.lastName.setValue(this.selectedUser.lastName);
    this.userForm.controls.email.setValue(this.selectedUser.email);
    this.userForm.controls.phone.setValue(this.selectedUser.phone);
    // User Access Form
    this.userAccessForm.controls.isEnabled.setValue(this.selectedUser.isEnabled);
    this.userAccessForm.controls.role.setValue(this.selectedUser.role);
    this.userAccessForm.controls.hasCrmAccess.setValue(this.selectedUser.hasCrmAccess);
    this.userAccessForm.controls.hasEmployeeAccess.setValue(this.selectedUser.hasEmployeeAccess);
    this.userAccessForm.controls.hasSalesAccess.setValue(this.selectedUser.hasSalesAccess);
    // User Address Form
    this.userAddressForm.controls.street.setValue(this.selectedUser.address.street);
    this.userAddressForm.controls.city.setValue(this.selectedUser.address.city);
    // this.userAddressForm.controls.county.setValue(this.selectedUser.address.county);
    this.userAddressForm.controls.state.setValue((this.selectedUser.address.state === null) ? 'SAS' : this.selectedUser.address.state);
    this.userAddressForm.controls.postalCode.setValue(this.selectedUser.address.postalCode);
    // User Attributes Form
    this.userAttributesForm.controls.hourlyRate.setValue(this.selectedUser.hourlyRate);
    // Authorization
    if (userId == this.currentUser.id || this.selectedUserHasHigherAuth) {
      this.userAccessForm.controls.isEnabled.disable();
      this.userAccessForm.controls.role.disable();
      this.userAccessForm.controls.hasCrmAccess.disable();
      this.userAccessForm.controls.hasSalesAccess.disable();
      if (this.selectedUserHasHigherAuth) this.userAccessForm.controls.hasEmployeeAccess.disable();
    } else {
      this.userAccessForm.controls.isEnabled.enable();
      this.userAccessForm.controls.role.enable();
      this.userAccessForm.controls.hasCrmAccess.enable();
      this.userAccessForm.controls.hasSalesAccess.enable();
      this.userAccessForm.controls.hasEmployeeAccess.enable();
    }
  }

  archiveUserConfirmation(archiveStatus: boolean) {
    const msg = archiveStatus ? 'Are you sure you want to archive this user? User will be removed from all future scheduled items.' : 'Are you sure you want to restore this user?'
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = msg;
    confirmationModalRef.componentInstance.actionBtnTitle = archiveStatus ? "Archive User" : "Restore User";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.changeUserStatus(archiveStatus)
    });
  }

  changeUserStatus(archiveStatus: boolean) {
    this.userService.archiveStatusChange(this.selectedUser.id, archiveStatus).subscribe(() => {
      this.alertService.showSuccessAlert(`User has been ${archiveStatus ? 'Archived' : 'Restored'}`);
      this.getUsers();
    });
  }

  showArchivedUsers(toShow: boolean) {
    this.showArchived = toShow;
  }

  filteredUsers(users: User[]) {
    if (!this.showArchived) return users.filter(user => !user.isArchived)
    return users;
  }

  validUserCount() {
    const userList = this.users.filter(user => !user.isArchived && user.isEnabled)
    return userList.length
  }

  openRolesModal() {
    this.modalService.open(this.userRolesTemplate, { centered: true, modalDialogClass: 'custom-modal-dialog', size: 'xl' });
  }

  // User Form Accessors
  get firstName() { return this.userForm.controls.firstName; }
  get lastName() { return this.userForm.controls.lastName; }
  get email() { return this.userForm.controls.email; }
  get phone() { return this.userForm.controls.phone; }

  // User Access Form Accessors
  get role() { return this.userAccessForm.controls.role; }

  // User Attributes
  get hourlyRate() { return this.userAttributesForm.controls.hourlyRate; }

  // Reset User Password Form Accessors
  get passwordGeneration() { return this.passwordForm.controls.passwordGeneration; }
  get password() { return this.passwordForm.controls.password; }
  get confirmPassword() { return this.passwordForm.controls.confirmPassword; }
}
