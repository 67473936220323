<div *ngIf="notificationService.activeNotifications.length > 0" class="d-flex flex-column mt-2">
    <button type="button" class="btn btn-primary" style="pointer-events: auto;" (click)="notificationService.dismissAllNotification();">Dismiss All</button>
</div>

<ngb-toast *ngFor="let notification of notificationService.activeNotifications" class="mt-2" [autohide]="false" (hidden)="notificationService.dismissNotification(notification);">
    <ng-template ngbToastHeader>
        <div class="d-flex w-100">
            <strong style="white-space: break-spaces;">{{notification.title}}</strong>
            <div class="ms-auto my-auto text-nowrap">{{formatTime(notification.createdAt)}}</div>
        </div>
    </ng-template>
    <div class="d-flex" [style]="{ 'cursor': notificationService.isClickable(notification.type) ? 'pointer' : 'normal' }" (click)="notificationService.goTo(notification);">
        <div class="ms-2" style="width: 100%; text-overflow: ellipsis; word-break: break-word;">{{notification.message}}</div>
        <fa-icon *ngIf="notificationService.isClickable(notification.type)" class="ms-auto my-auto" style="color: gray;" [icon]="faArrowRight"></fa-icon>
    </div>
</ngb-toast>

<ngb-toast style="background: rgb(16, 94, 34); color: white;" *ngFor="let msg of messages; let i = index;" class="mt-2" [autohide]="false" (hidden)="notificationService.dismissNotification(notification);">
    <ng-template ngbToastHeader>
        <div class="d-flex w-100">
            <strong style="white-space: break-spaces;">New Message From {{ msg?.conversation?.attributes?.contactName || msg?.author }}</strong>
            <div class="ms-auto my-auto text-nowrap">{{ msg.dateCreated | date: 'short' }}</div>
        </div>
    </ng-template>
    <div class="d-flex" style="cursor:pointer" (click)="messageClick(msg, i);">
        <div class="ms-2" style="width: 100%; text-overflow: ellipsis; word-break: break-word;">
            {{ msg.media ? getMediaType(msg.media) : msg.body }}
        </div>
        <fa-icon class="ms-auto my-auto" style="color: white;" [icon]="faArrowRight"></fa-icon>
    </div>
</ngb-toast>