<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-md-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary pull-left me-3 backBtn" aria-label="Back">
                <fa-icon class="button-icon-left" [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">User Time Clock</h2>
        </div>
    </nav>
    <div class="row">
        <div class="col-lg-6 mt-3">
            <div class="card h-100">
                <div class="card-header">
                    <h5>Clock In</h5>
                </div>
                <div class="card-body">
                    <div class="d-flex">
                        <h1 class="mx-auto">{{currentTimeString}}</h1>
                    </div>
                    <form [formGroup]="timeEntryForm">
                        <div class="mb-3">
                            <label for="TIME_ENTRY_CLIENT">Client</label>
                            <input *ngIf="clientTypeaheadInput" id="TIME_ENTRY_CLIENT" type="text" class="form-control" placeholder="Type to Search" formControlName="client" [ngbTypeahead]="clientTypeaheadInput" (focus)="clientTypeaheadInputFocus$.next($any($event).target.value)" [resultFormatter]="clientResultFormatter" (selectItem)="clientSelected($event);">
                        </div>
                        <div class="mb-3">
                            <label for="TIME_ENTRY_PROJECT">Project</label>
                            <select id="TIME_ENTRY_PROJECT" class="form-select" formControlName="project">
                                <option *ngFor="let project of projects" [value]="project.id">{{project.name}} ({{project.site.address.street}})</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="TIME_ENTRY_TYPE">Type</label>
                            <select id="TIME_ENTRY_TYPE" class="form-select" formControlName="type">
                                <option value="PREP IN WAREHOUSE">Prep - In Warehouse</option>
                                <option value="PREP OUT WAREHOUSE">Prep - Out Warehouse</option>
                                <option value="INSTALL">Installation</option>
                                <option value="MAINTENANCE">Maintenance</option>
                                <option value="REMOVE">Removal</option>
                                <option value="POST REMOVE">Post Removal</option>
                                <option value="TRANSPORTATION">Transportation</option>
                            </select>
                        </div>
                    </form>
                </div>
                <div class="card-footer text-center">
                    <button type="button" class="btn btn-primary" (click)="clockIn();" [disabled]="projects.length == 0">Clock In</button>
                </div>
            </div>
        </div>
        <div class="col-lg-6 mt-3">
            <div class="card h-100">
                <div class="card-header d-flex">
                    <h5>History</h5>
                </div>
                <div class="card-body" style="height: 320px;">
                    <div class="scrollable">
                        <ul class="list-group">
                            <li *ngFor="let timeEntry of timeEntries" class="list-group-item">
                                <div class="d-flex">
                                    <div>
                                        <div><strong>{{timeEntry.type}}</strong></div>
                                        <div><strong>{{timeEntry.clientName}}</strong></div>
                                        <div><strong>{{timeEntry.projectName}}</strong></div>
                                        <div><strong>Start:</strong> {{timeEntry.start | formatDateTime}}</div>
                                        <div *ngIf="timeEntry.end"><strong>End:</strong> {{timeEntry.end | formatDateTime}}</div>
                                    </div>
                                    <div class="ms-auto my-auto">
                                        <button *ngIf="!timeEntry.end" type="button" class="btn btn-primary btn-sm ms-2" (click)="clockOut(timeEntry.id);">Clock Out</button>
                                        <h5 *ngIf="timeEntry.duration">{{formatDuration(timeEntry.duration)}}</h5>
                                    </div>
                                </div>
                            </li>
                            <li *ngIf="timeEntries.length == 0" class="list-group-item">No Time Entries</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Already Clocked In Modal -->
<ng-template #ALREADY_CLOCKED_IN_MODAL let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Switch Clock?</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
    </div>
    <div class="modal-body">
        <p class="mb-0">You appear to have already clocked in for <strong>{{lastClockIn.type}}</strong> at <strong>{{lastClockIn.start | formatDateTime}}</strong>. Do you want to switch?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="switchClocks();" ngbAutofocus>Switch</button>
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
    </div>
</ng-template>