<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="me-md-0 me-4 mobileHeading">Bug Reports</h2>
        </div>
    </nav>
    <div class="row">
        <div class="col-lg-8 mt-3">
            <div class="card" style="height: 324px;">
                <div class="card-header">
                    <h5>New Bug Report</h5>
                    <div class="ms-auto">
                        <button type="button" class="btn btn-primary" (click)="addBugReport();">Submit</button>
                    </div>
                </div>
                <div class="card-body">
                    <form [formGroup]="bugReportForm">
                        <div class="mb-3">
                            <input type="text" class="form-control" formControlName="title" placeholder="Title" maxlength="150" required>
                        </div>
                        <div>
                            <textarea type="text" class="form-control" formControlName="report" style="height: 173px; resize: none;" placeholder="Please be specific as possible when describing the issue." maxlength="65535" required></textarea>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-lg-4 mt-3">
            <div class="card">
                <div class="card-header">
                    <h5>History</h5>
                </div>
                <div class="card-body">
                    <ul class="list-group scrollable" style="height: 227px;">
                        <li *ngFor="let bugReport of bugReports" class="list-group-item">
                            <div class="d-flex align-items-center">
                                <div class="d-flex flex-column">
                                    <strong>{{bugReport.title}} (<span [style.color]="getStatusColor(bugReport.status)">{{bugReport.status}}</span>)</strong>
                                    <p class="mb-0">{{bugReport.report}}</p>
                                    <small>{{bugReport.createdAt | formatDateTime}}</small>
                                </div>
                            </div>
                        </li>
                        <li *ngIf="bugReports.length == 0" class="list-group-item">No Bug Reports</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>