<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">Accounts Receivable</h2>
        </div>
        <div class="row w-100 button-container">
            <div class="col-auto mt-1">
                <button type="button" class="btn btn-primary" (click)="downloadReport();">
                    <fa-icon [icon]="faFileDownload"></fa-icon><span class="ms-2 d-md-inline">Export</span>
                </button>
            </div>
            <div class="col-auto" *ngIf="isMobile">
              <div class="sort"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>

            </div>
            <div class="col-auto"  *ngIf="isMobile">
              <div class="filter"><ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container></div>
            </div>
        </div>
    </nav>
    <div class="row mt-3">
        <div class="card p-0">
            <div class="card-body">
                <h5 class="card-title">Description</h5>
                <p class="card-text m-0">This report can be used to determine which invoices have not been paid.</p>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col table-responsive">
            <table *ngIf="!isMobile">
                <thead>
                    <tr>
                        <!-- Client -->
                        <th>
                            <div class="d-flex" style="position: relative;">
                                <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #clientName="ngbPopover" (click)="showColumnFilterPopover(clientName, 'c.client_name');">
                                    <span *ngIf="tableControls.hasActiveFilter('c.client_name')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('c.client_name')}}</span>
                                    <fa-icon [icon]="faFilter"></fa-icon>
                                </div>
                                <div class="w-100 px-3" (click)="tableControls.sort('c.client_name');">
                                    Client
                                </div>
                                <fa-icon *ngIf="tableControls.isSortedBy('c.client_name')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                            </div>
                        </th>
                        <!-- Project -->
                        <th>
                            <div class="d-flex" style="position: relative;">
                                <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #projectName="ngbPopover" (click)="showColumnFilterPopover(projectName, 'proj.project_name');">
                                    <span *ngIf="tableControls.hasActiveFilter('proj.project_name')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('proj.project_name')}}</span>
                                    <fa-icon [icon]="faFilter"></fa-icon>
                                </div>
                                <div class="w-100 px-3" (click)="tableControls.sort('proj.project_name');">
                                    Project
                                </div>
                                <fa-icon *ngIf="tableControls.isSortedBy('proj.project_name')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                            </div>
                        </th>
                        <!-- Invoice Number -->
                        <th (click)="tableControls.sort('i.invoice_number');">
                            Invoice #
                            <fa-icon *ngIf="tableControls.isSortedBy('i.invoice_number')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                        </th>
                        <th>Amount Due</th>
                        <!-- Due Date -->
                        <th (click)="tableControls.sort('i.invoice_due_date');">
                            Due Date
                            <fa-icon *ngIf="tableControls.isSortedBy('i.invoice_due_date')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                        </th>
                        <!-- Status -->
                        <th>
                            <div class="d-flex" style="position: relative;">
                                <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #invoiceStatus="ngbPopover" (click)="showColumnFilterPopover(invoiceStatus, 'i.invoice_status');">
                                    <span *ngIf="tableControls.hasActiveFilter('i.invoice_status')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('i.invoice_status')}}</span>
                                    <fa-icon [icon]="faFilter"></fa-icon>
                                </div>
                                <div class="w-100 px-3" (click)="tableControls.sort('i.invoice_status');">
                                    Status
                                </div>
                                <fa-icon *ngIf="tableControls.isSortedBy('i.invoice_status')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let entry of entries" routerLink="/invoices/{{entry.invoiceId}}">
                        <td data-label="Client">{{entry.clientName}}</td>
                        <td data-label="Project">{{entry.projectName}}</td>
                        <td data-label="Invoice #">{{entry.invoiceNumber}}</td>
                        <td data-label="Amount Due">{{entry.invoiceAmountDue | currency}}</td>
                        <td data-label="Due Date">{{entry.invoiceDueDate | formatDateTime: true}}</td>
                        <td data-label="Status">{{entry.invoiceStatus | invoiceStatus}}</td>
                    </tr>
                </tbody>
                <!-- <tfoot>
                    <tr>
                        <td>Total</td>
                        <td></td>
                        <td></td>
                        <td>{{totalAmountDue | currency}}</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot> -->
            </table>

            <div class="scrollable" *ngIf="isMobile">
              <ul class="list-group">
                  <li *ngFor="let entry of entries" class="list-group-item" style="cursor: pointer;" routerLink="/invoices/{{entry.invoiceId}}">
                      <div class="d-flex list-group-container">
                          <div>
                              <strong>{{entry.clientName}}</strong>
                              <div>{{entry.projectName}}</div>
                              <div><strong>Invoice #: </strong>{{entry.invoiceNumber}}</div>
                              <div><strong>Amount Due: </strong>{{entry.invoiceAmountDue | currency}}</div>
                              <div><strong>Due Due: </strong>{{entry.invoiceDueDate | formatDateTime: true}}</div>
                              <div><strong>Status: </strong>{{entry.invoiceStatus | invoiceStatus}}</div>
                          </div>
                      </div>
                  </li>
                  <li *ngIf="entries.length == 0" class="list-group-item">No Entries</li>
              </ul>
            </div>
        </div>
    </div>
    <div class="row table-controls">
        <div *ngIf="tableControls.isNotDefault() && !isMobile" class="col-auto mt-3 me-2 me-md-0">
            <button type="button" class="btn btn-outline-danger" (click)="tableControls.resetTableConfiguration();">
                <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
                <span>Reset</span>
            </button>
        </div>
        <div class="col mt-3">
            <div ngbDropdown placement="top-left" class="d-inline-block">
                <button class="btn btn-outline-primary" ngbDropdownToggle><span *ngIf="!isMobile">Page Size: </span> {{tableControls.getLimit()}}</button>
                <div ngbDropdownMenu class="page-size-dropdown" *ngIf="!isMobile">
                  <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(50);">50</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(100);">100</button>
                </div>
                <div ngbDropdownMenu class="page-size-dropdown" *ngIf="isMobile">
                  <button ngbDropdownItem (click)="tableControls.setLimit(5);">5</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(10);">10</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
                </div>
            </div>
        </div>
        <div class="col my-3">
            <ngb-pagination class="d-flex justify-content-end" *ngIf="entryCount > 0" [page]="tableControls.getCurrentPage()" [pageSize]="tableControls.getLimit()" [collectionSize]="entryCount" [boundaryLinks]="true" [maxSize]="(isMobile) ? 3 : 10" [ellipses]="true" [rotate]="true" (pageChange)="tableControls.setCurrentPage($event);"></ngb-pagination>
        </div>
    </div>
</div>

<!-- Filter -->
<ng-template #popTitleFilter>Filter</ng-template>
<ng-template let-column="column" #popContentFilter>
    <div *ngFor="let element of this.columnFilterValues.get(column)" class="form-check">
        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter(column, element.value);" [checked]="tableControls.filterExists(column, element.value)">
        <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
    </div>
</ng-template>


<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block" [autoClose]="true" placement="bottom-left">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
  <div ngbDropdown class="d-inline-block filter-container" autoClose="outside" placement="bottom-left">
      <span *ngIf="activeFilterCount > 0" class="custom-badge-lg">{{activeFilterCount}}</span>
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Filter">
          <fa-icon [icon]="faFilter"></fa-icon>
      </button>
      <div ngbDropdownMenu>
          <!-- Filter Options -->
          <div *ngFor="let filter of filterColumns">
            <button ngbDropdownItem class="button-filter" (click)="getDistinctColumnValues(filter.value); filters.toggle();">{{filter.label}}</button>
            <div class="px-4 py-1" #filters="ngbCollapse" [ngbCollapse]="filter.value === selectedFilterColumn ? false : true">
                <div class="d-flex flex-column">
                    <div *ngFor="let element of columnFilterValues.get(filter.value);" class="form-check form-check-inline">
                        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter(filter.value, element.value);" [checked]="filterExists(filter.value, element.value)">
                        <label [for]="element.randomId" class="form-check-label" style="white-space: nowrap;">{{element.name}}</label>
                    </div>
                </div>
            </div>
          </div>

          <!-- Divider -->
          <div class="dropdown-divider"></div>
          <!-- Reset -->
          <button class="reset-button" ngbDropdownItem (click)="resetActiveFilters(); selectedFilterColumn = ''">Reset</button>
      </div>
  </div>
</ng-template>
