import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project-prep-cancel-split-msg',
  templateUrl: './project-prep-cancel-split-msg.component.html',
  styleUrls: ['./project-prep-cancel-split-msg.component.css']
})
export class ProjectPrepCancelSplitMsgComponent {
  @Input() clickOn: string;
  @Input() itemName: string;
  @Output() saved: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    public modal: NgbActiveModal
  ) { }

  submit(chosen?: number) {
    this.saved.emit(chosen);
    this.modal.close();
  }
}
