import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Client } from './client';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }

  // Add Client
  addClient(client: any): Observable<any> {
    return this.http.post<any>(`/clients`, client);
  }

  // Update Client
  updateClient(client: any): Observable<any> {
    return this.http.put<any>(`/clients/${client.id}`, client);
  }

  // Update Client Status
  updateClientStatus(clientId: string, status: string): Observable<any> {
    return this.http.put<any>(`/clients/${clientId}/status`, { status: status });
  }

  // Delete Client
  deleteClient(clientId: string): Observable<any> {
    return this.http.delete<any>(`/clients/${clientId}`);
  }

  // Get Clients
  getClients(params: any): Observable<{ clients: Client[], count: number }> {
    return this.http.get<{ clients: Client[], count: number }>(`/clients`, {
      params: params
    });
  }

  // Get Client
  getClient(clientId: string): Observable<Client> {
    return this.http.get<Client>(`/clients/${clientId}`);
  }

  // Get Distinct Column Values
  getDistinctColumnValues(params: any): Observable<string[]> {
    return this.http.get<string[]>(`/clients/distinctColumnValues`, {
      params: params
    });
  }

  // Import Clients
  importClients(file: File): Observable<{ newClientCount: number, newContactCount: number }> {
    let formData = new FormData();
    formData.append('FILE', file);
    return this.http.put<{ newClientCount: number, newContactCount: number }>(`/clients/import`, formData);
  }
}
