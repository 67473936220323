<div class="modal-header">
    <h5 class="modal-title">A2P Registration Form
        <span class="ms-auto" style="cursor: pointer;" [ngbPopover]="a2pDescription"
            triggers="click">
            <fa-icon [icon]="faQuestionCircle"></fa-icon>
        </span>  
    </h5>
    
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="a2pForm">
        <div id="stepper1" class="bs-stepper">
            <div class="bs-stepper-header">
                <div class="step" data-target="#test-l-1">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </span>
                    <span class="bs-stepper-label">Business Details</span>
                </button>
                </div>
                <div class="line"></div>
                <div class="step" data-target="#test-l-2">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">2</span>
                    <span class="bs-stepper-label">Contact Person</span>
                </button>
                </div>
                <div class="line"></div>
                <div class="step" data-target="#test-l-3">
                <button class="step-trigger">
                    <span class="bs-stepper-circle">3</span>
                    <span class="bs-stepper-label">Business Address</span>
                </button>
                </div>
            </div>
            <div class="bs-stepper-content">
                <div id="test-l-1" class="content">
                    <app-business-identity-form (closeModal)="close()"></app-business-identity-form>
                </div>
                <div id="test-l-2" class="content">
                    <app-contact-person-form (closeModal)="close()"></app-contact-person-form>
                </div>
                <div id="test-l-3" class="content">
                    <app-business-address-form (closeModal)="close()"></app-business-address-form>
                </div>
            </div>
        </div>
    </form>
</div>

<ng-template #a2pDescription>
    <p>
        A2P 10DLC refers to a system in the United States that allows businesses to send Application-to-Person (A2P) 
        type messaging via standard 10-digit long code (10DLC) phone numbers. Carriers in the US consider all Twilio traffic 
        to be A2P. Carriers’ A2P 10DLC offerings provide better delivery quality and lower filtering risk than long code SMS of the past, 
        using the same phone numbers.
    </p>
</ng-template>