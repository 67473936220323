<div class="modal-header">
    <h5 class="modal-title">Invoice
        <!-- <span class="ms-auto" style="cursor: pointer;" [ngbPopover]="a2pDescription"
            triggers="click">
            <fa-icon [icon]="faQuestionCircle"></fa-icon>
        </span>  -->
    </h5>

    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <div id="invoice" class="invoice">
        <header>
          <img style="height: 50px" src="../../assets/TinselLogo.png">
          <h1>Invoice</h1>
        </header>
        <section class="company-details">
          <div class="billing">
            <h3>Gaston Velarde (Billing)</h3>
            <p>123 Main St</p>
            <p>Northeast Harbor, ME 04662</p>
            <p><a href="mailto:gvelarde@tinselcrm.com">gvelarde@tinselcrm.com</a></p>
            <p>(267) 934-3828</p>
          </div>
          <div class="site">
            <h3>Home (Site)</h3>
            <p>1 N Main St</p>
            <p>Greenville, SC 29601</p>
          </div>
        </section>
        <section class="dates">
          <p>Issue Date: 5/3/2024</p>
          <p>Due Date: 5/4/2024</p>
        </section>
        <section class="payments-pricing">
          <div class="pricing">
            <table class="invoice-table">
              <thead>
                <th>Item</th>
                <th>Date</th>
                <th>Quantity</th>
                <th>Price</th>
              </thead>
              <tbody>
                <tr>
                  <td>Monthly subscription</td>
                  <td>August 1, 2024</td>
                  <td>1</td>
                  <td>$89</td>
                </tr>
                <tr>
                  <td>SMS</td>
                  <td>August 1, 2024</td>
                  <td>3</td>
                  <td>$3</td>
                </tr>
              </tbody>
            </table>
            <div class="amount-due-container">
              <div class="amount-due">
                <div class="amount-label">Amount due:</div>
                <div class="amount-value">$92</div>
              </div>
            </div>
          </div>
        </section>
    </div>
    <button class="btn btn-primary" (click)="generatePDF()">Generate PDF</button>
</div>

