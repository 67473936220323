import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Output, EventEmitter, HostListener } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';
import { debounceTime, lastValueFrom, Subject } from 'rxjs';
import { CalendarEvent as AngularCalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarMonthViewDay, CalendarView } from 'angular-calendar';
import { ScheduleService } from '../schedule.service';
import { faArrowCircleUp, faArrowCircleDown, faTools, faCalendarDay, faFilter, faCheck, faFileDownload, faPlus, faSquareCaretUp, faSquareCaretDown, faCircleQuestion, faChartSimple, faRoute, faRoad } from '@fortawesome/free-solid-svg-icons';
import { ScheduleItem } from '../schedule-item';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleItemModalComponent } from '../schedule-item-modal/schedule-item-modal.component';
import { isSameDay, isSameMonth } from 'date-fns';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert.service';
import { SettingService } from '../setting.service';
import { UsageService } from '../usage.service';

@Component({
  selector: 'app-schedule-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './schedule-calendar.component.html',
  styleUrls: ['./schedule-calendar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleCalendarComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Properties
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  viewStartDate: Dayjs;
  viewEndDate: Dayjs;
  refresh: Subject<void> = new Subject();
  events: AngularCalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  @Output() mapToggled: EventEmitter<void> = new EventEmitter<void>();

  organizationCoordinates: number[];
  isMobileView: boolean = false;

  // Filters
  filters: string[] = [];

  // Routing Types
  routingTypes: Map<string, string> = new Map<string, string>();

  // Routing
  selectedRoutingOption: string = 'DISTANCE';
  routingUsage: number = 0;
  routingLimit: number = 0;

  // Font Awesome Properties
  faArrowCircleUp = faArrowCircleUp;
  faArrowCircleDown = faArrowCircleDown;
  faTools = faTools;
  faCalendarDay = faCalendarDay;
  faFilter = faFilter;
  faCheck = faCheck;
  faFileDownload = faFileDownload;
  faPlus = faPlus;
  faRoad = faRoad;
  faSquareCaretUp = faSquareCaretUp;
  faSquareCaretDown = faSquareCaretDown;
  faCircleQuestion = faCircleQuestion;
  faChartSimple = faChartSimple;
  faRoute = faRoute;
  hasChanges: boolean = false;


  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private scheduleService: ScheduleService,
    private settingService: SettingService,
    private usageService: UsageService,
    private alertService: AlertService,
    private modalService: NgbModal) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 1024) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
        }
      });
    }

  ngOnInit(): void {
    this.checkScreenSize();
    this.listenForScheduleUpdates();
    this.getOrganizationSettings();
    this.getRoutingUsage();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Schedule Item Modal
  openScheduleItemModal(scheduleItem: ScheduleItem = null): void {
    const modalRef = this.modalService.open(ScheduleItemModalComponent);
    if (scheduleItem) modalRef.componentInstance.scheduleItem = scheduleItem;
  }

  // Toggle Map
  toggleMap(): void {
    this.mapToggled.emit();
  }

  // Listen For Schedule Updates
  private listenForScheduleUpdates(): void {
    this.scheduleService.scheduleUpdated$.subscribe(() => {
      this.reloadData();
    });
  }

  // Before View Render
  beforeViewRender(event: CalendarMonthViewBeforeRenderEvent): void {
    if ((this.viewStartDate !== undefined && this.viewStartDate.isSame(event.period.start, 'day')) && (this.viewEndDate !== undefined && this.viewEndDate.isSame(event.period.end, 'day'))) return;
    this.viewStartDate = dayjs(event.period.start);
    this.viewEndDate = dayjs(event.period.end);
    this.scheduleService.dateRangeChanged.next({ start: this.viewStartDate.format('YYYY-MM-DD'), end: this.viewEndDate.format('YYYY-MM-DD') });
    this.reloadData();
  }

  // Get Organization Settings
  private getOrganizationSettings(): void {
    this.settingService.getOrganizationSettings().subscribe((organizationSettings) => {
      this.organizationCoordinates = [organizationSettings.location.coordinates.longitude, organizationSettings.location.coordinates.latitude];
    });
  }

  // Reload Data
  private async reloadData(): Promise<void> {
    const dateRange = {
      startDate: this.viewStartDate.startOf('date').format('YYYY-MM-DD'),
      endDate: this.viewEndDate.endOf('date').format('YYYY-MM-DD')
    };
    const params = {
      sortBy: 'sc.schedule_item_position',
      sortDirection: 'ASC',
      'filter:sc.schedule_item_status': JSON.stringify(['SCHEDULED']),
      'filter:range:sc.schedule_item_date': `${dateRange.startDate}:${dateRange.endDate}`
    };
    if (this.filters.length > 0) params['filter:sc.schedule_item_type'] = JSON.stringify(this.filters);
    // Get Schedule Items
    const scheduleItemsRes = await lastValueFrom(this.scheduleService.getScheduleItems(params));
    this.events = this.generateScheduleItemEvents(scheduleItemsRes.scheduleItems);
    // Get Routing Types
    const routingTypes = await lastValueFrom(this.scheduleService.getRoutingTypesInRange(dateRange.startDate, dateRange.endDate));
    this.routingTypes.clear();
    for (const routingType of routingTypes) this.routingTypes.set(routingType.date, routingType.type);
    // Refresh Calendar
    this.refresh.next();
  }

  /* ----- Schedule Items ----- */

  // Generate Schedule Item Events
  private generateScheduleItemEvents(scheduleItems: ScheduleItem[]): AngularCalendarEvent[] {
    const tempArray: AngularCalendarEvent[] = [];
    for (const scheduleItem of scheduleItems) {
      const color = (scheduleItem.type == 'CUSTOM') ? '#000000' : scheduleItem.countyColor ? scheduleItem.countyColor : '#808080';
      tempArray.push({
        start: dayjs(scheduleItem.date).toDate(),
        end: (scheduleItem.endDate) ? dayjs(scheduleItem.endDate).toDate() : null,
        title: (scheduleItem.type == 'CUSTOM') ? scheduleItem.name : scheduleItem.projectName,
        color: { primary: color, secondary: color },
        allDay: scheduleItem.isAllDay,
        draggable: false,
        resizable: {
          beforeStart: false,
          afterEnd: false
        },
        meta: {
          scheduleItem: scheduleItem
        }
      });
    }
    return tempArray;
  }

  // Swap Positions
  swapPositions(currScheduleItem: ScheduleItem, direction: 'UP' | 'DOWN'): void {
    const swapPos = currScheduleItem.position + ((direction == 'UP') ? -1 : 1);
    const findSwap = this.events.find((event) => {
      return event?.meta.scheduleItem.position == swapPos && this.dateParser(currScheduleItem.date) === this.dateParser(event.meta.scheduleItem.date);
    })
    console.log(findSwap)
    const swapScheduleItemId = findSwap.meta.scheduleItem.id;
    const data: any = {
      currScheduleItemId: currScheduleItem.id,
      currPosition: currScheduleItem.position,
      swapScheduleItemId: swapScheduleItemId,
      swapPosition: swapPos,
      date: dayjs(this.viewDate).format('YYYY-MM-DD')
    };
    this.scheduleService.swapScheduleItemPositions(data).subscribe(() => {
      this.alertService.showSuccessAlert('Schedule Items Updated');
      this.scheduleService.scheduleUpdated.next();

    });
  }

  dateParser(data: any){
    return data.split(" ")[0]
  }

  // Optimize Route
  optimizeRoute(events: AngularCalendarEvent[], type: 'DISTANCE' | 'DURATION'): void {
    const scheduleItems = events.map((event) => { return event.meta.scheduleItem; }) as ScheduleItem[];
    const locations: string[] = ['Start'];
    const positions: number[][] = [this.organizationCoordinates];
    for (const scheduleItem of scheduleItems) {
      locations.push(scheduleItem.id);
      positions.push([scheduleItem.mapData.coordinates.longitude, scheduleItem.mapData.coordinates.latitude]);
    }
    const date = dayjs(this.viewDate).format('YYYY-MM-DD');
    this.scheduleService.optimizeRoute(locations, positions, date, type).subscribe(() => {
      this.alertService.showSuccessAlert('Schedule Items Routed');
      this.scheduleService.scheduleUpdated.next();
      this.getRoutingUsage();
    });
  }

  // Route By Time
  routeByTime(): void {
    const date = dayjs(this.viewDate).format('YYYY-MM-DD');
    this.scheduleService.routeByTime(date).subscribe(() => {
      this.alertService.showSuccessAlert('Schedule Items Routed');
      this.scheduleService.scheduleUpdated.next();
    });
  }

  // Can Route By Time
  canRouteByTime(events: AngularCalendarEvent[]): boolean {
    return events.reduce((acc: boolean, event: AngularCalendarEvent) => { return acc && event.meta.scheduleItem.isAllDay; }, true);
  }

  // Toggle Event Popover
  toggleEventPopover(event: MouseEvent, popover: NgbPopover, scheduleItem: ScheduleItem): void {
    if (event.type == 'mouseenter') popover.open({ scheduleItem });
    if (event.type == 'mouseleave') popover.close();
  }

  // Download Schedule
  downloadSchedule(): void {
    const exportScheduleStartDate = (document.getElementById('EXPORT_SCHEDULE_START_DATE') as HTMLInputElement).value;
    const exportScheduleEndDate = (document.getElementById('EXPORT_SCHEDULE_END_DATE') as HTMLInputElement).value;
    if (exportScheduleStartDate.length === 0) {
      this.alertService.showWarningAlert('Start Date cannot be blank.');
      return;
    }
    if (exportScheduleEndDate.length === 0) {
      this.alertService.showWarningAlert('End Date cannot be blank.');
      return;
    }
    const startDate = dayjs(exportScheduleStartDate).startOf('date').format('YYYY-MM-DD');
    const endDate = dayjs(exportScheduleEndDate).endOf('date').format('YYYY-MM-DD');
    const searchParams = new URLSearchParams();
    searchParams.set('sortBy', 'sc.schedule_item_date, sc.schedule_item_position');
    searchParams.set('sortDirection', 'ASC');
    searchParams.set('startDate', startDate);
    searchParams.set('endDate', endDate);
    window.open(`${environment.apiUrl}/schedule/export?${searchParams.toString()}`);
  }

  /* ----- Filters ----- */

  // Toggle Filter
  toggleFilter(filter: string): void {
    if (this.filters.includes(filter)) this.filters = this.filters.filter((f) => { return f != filter; });
    else this.filters.push(filter);
    this.reloadData();
  }

  // Reset Filters
  resetFilters(): void {
    this.filters.length = 0;
    this.reloadData();
  }

  // Filter Is Active
  filterIsActive(filter: string): boolean {
    return this.filters.includes(filter);
  }

  /* ----- Calendar Functions ----- */

  // Select Day
  selectDay(day: CalendarMonthViewDay): void {
    if (isSameMonth(day.date, this.viewDate)) {
      if ((isSameDay(this.viewDate, day.date) && this.activeDayIsOpen) || day.events.length === 0) {
        this.activeDayIsOpen = false;
        this.scheduleService.dateSelected.next(null);
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = day.date;
        this.scheduleService.dateSelected.next(dayjs(this.viewDate));
      }
    }
  }

  // Set View
  setView(view: CalendarView): void {
    this.view = view;
    this.reloadData();
  }

  // View Date Changed
  viewDateChanged(): void {
    this.activeDayIsOpen = false;
    // this.reloadData();
  }

  /* ----- Routing Types ----- */

  // Get Routing Type
  getRoutingType(date: Date): string {
    let routingType = this.routingTypes.get(dayjs(date).format('YYYY-MM-DD'));
    switch (routingType) {
      case 'TIME':
        return 'Time';
      case 'DISTANCE':
        return 'Shortest Distance';
      case 'DURATION':
        return 'Fastest Time';
      default:
        return 'Unknown';
    }
  }

  // Routing Type Exists
  routingTypeExists(date: Date): boolean {
    return this.routingTypes.has(dayjs(date).format('YYYY-MM-DD'));
  }

  // Toggle Routing Type Popover
  toggleRoutingTypePopover(event: MouseEvent, popover: NgbPopover, scheduleItem: ScheduleItem): void {
    if (event.type == 'mouseenter') popover.open({ scheduleItem });
    if (event.type == 'mouseleave') popover.close();
  }

  /* ----- Usage ----- */

  // Get Routing Usage
  private getRoutingUsage(): void {
    this.usageService.getUsageMetric('ROUTING').subscribe((res) => {
      this.routingUsage = res.usage;
      this.routingLimit = res.limit;
    });
  }

  /* ----- Helper Functions ----- */

  // Get Schedule Item Type Icon
  getScheduleItemTypeIcon(type: string, event) {
    switch (type) {
      case 'INSTALL':
        return faArrowCircleUp;
      case 'MAINTENANCE':
        return faTools;
      case 'REMOVE':
        return faArrowCircleDown;
      case 'CUSTOM':
        return faCalendarDay;
    }
  }

  // Format Time
  formatTime(date: string): string {
    return dayjs(date).format('h:mm A');
  }

  getCrewsNames(crews) {
    let crewsNames = crews.map(crew => crew?.crewName);
    return crewsNames.join(", ")
  }

  // Set background color
  setBgColor(schedule) {
    let styles = null
    if(schedule.crews && schedule.crews.length > 0) {
      const breakpoint = 100/schedule.crews.length;
      const colors = schedule.crews.map((crew, index) => {
        if(index != 0) {
          return crew?.crewColor + ` ${breakpoint}%` + ', ' + crew?.crewColor + ` ${2 * breakpoint}%`
        } else {
          return crew?.crewColor + ', ' + crew?.crewColor + ` ${breakpoint}%`
        }

      })
      styles = {
        'background': `linear-gradient(to right, ${colors})`
      };

    } else {
      styles = {
        'background-color': '#000000',
        'color': '#ffffff'
      };
    }
    return styles;
  }

   //Set isMobile value and set table limit base on screen size
   checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 1024;
  }
}
