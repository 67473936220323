<div class="card">
    <div class="card-header d-xl-flex">
        <h5>Logs</h5>
        <app-date-picker-controller class="ms-auto" componentId="Settings.Emails.Logs" preset="CUR_MTH" [showButtons]="false" (dateChanged)="dateChanged($event);" [onLoad]="true"></app-date-picker-controller>
    </div>
    <div class="card-body" style="height: 456px;">
        <ul class="list-group scrollable" style="height: 424px;">
            <li *ngFor="let emailLog of emailLogs; index as i;" class="list-group-item" style="cursor: pointer;" (click)="openEmailViewModal(emailLog);">
                <div class="d-flex">
                    <div>{{emailLog.subject}}</div>
                    <small class="text-muted my-auto ms-auto">{{emailLog.createdAt | formatDateTime}}</small>
                </div>
            </li>
            <li *ngIf="emailLogs.length == 0" class="list-group-item">No Logs</li>
        </ul>
    </div>
</div>

<!-- LOADER -->
<ngx-spinner>
  <p class="loading-spinner">Loading...</p>
</ngx-spinner>
