import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentCategory'
})
export class PaymentCategoryPipe implements PipeTransform {

  transform(type: string): string {
    switch (type) {
      case 'CARD':
        return 'Credit/Debit';
      case 'ACH':
        return 'ACH';
      case 'CHECK':
        return 'Check';
      case 'CASH':
        return 'Cash';
      case 'PAYPAL':
        return 'PayPal';
      case 'VENMO':
        return 'Venmo';
      case 'CASH APP':
        return 'Cash App';
      case 'ZELLE':
        return 'Zelle';
      case 'OTHER':
        return 'Other';
      default:
        return 'Unknown';
    }
  }
}
