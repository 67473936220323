import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '../alert.service';
import { LocationService } from '../location.service';
import { RegistrationService } from '../registration.service';
import { State } from '../state';
import { faCheck, faRotate } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from '../subscription';
import { SubscriptionService } from '../subscription.service';
import { catchError, debounceTime, map, Observable, Subject, takeUntil, throwError } from 'rxjs';
import { SubscriptionPromo } from '../subcription-promo';
import { UserService } from '../user.service';

enum RegistrationStage {
  USER,
  ORGANIZATION,
  SUBSCRIPTION,
  BILLING,
  REVIEW
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  // Properties
  stage: RegistrationStage = RegistrationStage.USER;
  states: State[] = [];
  userForm: UntypedFormGroup;
  organizationForm: UntypedFormGroup;
  promoCodeForm: UntypedFormGroup;
  billingForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  selectedSubscription: Subscription;
  selectedPromotion: SubscriptionPromo;
  paymentSuccessful: boolean = false;
  paymentResponse: any;
  processingRegistration: boolean = false;
  registrationFailed: boolean = false;
  errorMsg: string;

  // Font Awesome Properties
  faCheck = faCheck;
  faRotate = faRotate;

  @ViewChild('promoCodeInput') input;

  private destroy$ = new Subject<void>();

  constructor(private registrationService: RegistrationService,
    private subscriptionService: SubscriptionService,
    private locationService: LocationService,
    private userService: UserService,
    private alertService: AlertService,
    private router: Router,
    private zone: NgZone) { }

  ngOnInit(): void {
    this.userForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      email: new UntypedFormControl(),
      phone: new UntypedFormControl(),
      password: new UntypedFormControl(),
      confirmPassword: new UntypedFormControl()
    });
    this.userForm.controls.email.addAsyncValidators(this.emailExistsValidator());
    this.userForm.controls.confirmPassword.addValidators(this.passwordMatchValidator());
    this.organizationForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      email: new UntypedFormControl(),
      street: new UntypedFormControl(),
      city: new UntypedFormControl(),
      state: new UntypedFormControl('SAS'),
      postalCode: new UntypedFormControl()
    });
    this.billingForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      street: new UntypedFormControl(),
      city: new UntypedFormControl(),
      state: new UntypedFormControl('SAS'),
      postalCode: new UntypedFormControl()
    });
    this.promoCodeForm = new UntypedFormGroup({
      promoCode: new UntypedFormControl(),
    });
    this.getStates();
    this.getSubscriptions();
  }

  // Email Exists Validator
  private emailExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this.userService.checkUserEmailExists(control.value).pipe(map((result: boolean) => {
        return result ? { alreadyExists: true } : null;
      }));
    };
  }

  // Password Match Validator
  private passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      return (control.value != this.userForm.value.password) ? { doesNotMatch: true } : null;
    }
  }

  // Back
  back(): void {
    this.stage = this.stage - 1;
  }

  // Get States
  getStates(): void {
    this.locationService.getStates().subscribe((states) => {
      this.states = states;
    });
  }

  // Validate User
  validateUser(): void {
    if (this.userForm.valid) {
      this.stage = RegistrationStage.ORGANIZATION;
    } else {
      this.userForm.markAllAsTouched();
    }
  }

  // Validate Organization
  validateOrganization(): void {
    if (this.organizationForm.valid) {
      this.stage = RegistrationStage.SUBSCRIPTION;
    } else {
      this.organizationForm.markAllAsTouched();
    }
  }

  // Toggle Same Address
  toggleSameAddress(event: Event): void {
    const checked = (event.target as HTMLInputElement).checked;
    if (checked) {
      const name = this.userForm.value.firstName + ' ' + this.userForm.value.lastName;
      this.billingForm.controls.name.setValue(name);
      this.billingForm.controls.street.setValue(this.organizationForm.value.street);
      this.billingForm.controls.city.setValue(this.organizationForm.value.city);
      this.billingForm.controls.state.setValue(this.organizationForm.value.state);
      this.billingForm.controls.postalCode.setValue(this.organizationForm.value.postalCode);
      this.billingForm.markAllAsTouched();
    } else {
      this.billingForm.reset();
      this.billingForm.controls.state.setValue('SAS');
    }
  }

  // Get Subscriptions
  private getSubscriptions(promoCode: string | null = null): void {
    if (typeof promoCode == 'string') promoCode = promoCode.toUpperCase();
    const params = {
      type: 'STANDARD',
      promoCode: promoCode
    };
    this.subscriptionService.getSubscriptions(params).subscribe((subscriptions) => {
      console.log(subscriptions)

      if (promoCode && this.subscriptions.length > 0) {
        const subscription = subscriptions.find((sub) => { return sub.promotions.map((p) => { return p.code }).includes(promoCode) });
        if (subscription) {
          this.selectedPromotion = subscription.promotions.find((p) => { 
            return p.code == promoCode && p.isActive; 
          });
          this.errorMsg = null;
          if(this.selectedPromotion) {
            console.log(subscription)

            console.log(subscriptions)
            this.subscriptions = subscriptions;
          } else {
            this.errorMsg = 'Invalid Promo Code'
          }
        } else {
          this.errorMsg = 'Invalid Promo Code'
        }
      } else {
        this.subscriptions = subscriptions;
      }
    });
  }

  // Apply Promo Code
  applyPromoCode(promoCode: string): void {
    try {
      if (!promoCode) return;
      
      if (promoCode === null || promoCode.length == 0) throw new Error('Promo Code cannot be blank.');
      this.getSubscriptions(promoCode);
      // (<HTMLInputElement>document.getElementById('PROMO_CODE')).value = null;
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  // Display Tagline
  displayTagline(subscription: Subscription): boolean {
    if(!this.selectedPromotion)
    return null

    const promotion = subscription.promotions.find((promo) => { return promo.code == this.selectedPromotion.code });
    return promotion !== undefined;
  }

  // Select Subscription
  selectSubscription(subscription: Subscription): void {
    this.selectedSubscription = subscription;
    if (this.selectedPromotion && this.selectedSubscription.promotions && !this.selectedSubscription.promotions.map((p) => { return p.code; }).includes(this.selectedPromotion.code)) {
      //this.selectedPromotion = null;
    }
    this.stage = RegistrationStage.BILLING;
  }

  // Validate Billing Form
  validateBillingForm(): void {
    if (this.billingForm.valid) {
      this.stage = RegistrationStage.REVIEW;
    } else {
      this.billingForm.markAllAsTouched();
    }
  }

  // Subscription Successful
  subscriptionSuccessful(response: { initialTransactionId: string, customerVaultId: string }): void {
    this.paymentSuccessful = true;
    this.paymentResponse = response;
    this.registerOrganization();
  }

  // Register Organization
  registerOrganization(): void {
    if (this.organizationForm.valid) {
      const data = {
        user: this.userForm.value,
        organization: {
          name: this.organizationForm.value.name,
          email: this.organizationForm.value.email,
          address: {
            street: this.organizationForm.value.street,
            city: this.organizationForm.value.city,
            state: this.organizationForm.value.state,
            postalCode: this.organizationForm.value.postalCode
          }
        },
        subscriptionId: this.selectedSubscription.id,
        promoCode: (this.selectedPromotion) ? this.selectedPromotion.code : null,
        billing: {
          tinselPay: {
            initialTransactionId: this.paymentResponse.initialTransactionId,
            customerVaultId: this.paymentResponse.customerVaultId
          },
          name: this.billingForm.value.name,
          address: {
            street: this.billingForm.value.street,
            city: this.billingForm.value.city,
            state: this.billingForm.value.state,
            postalCode: this.billingForm.value.postalCode
          }
        }
      };
      this.registrationFailed = false;
      this.processingRegistration = true;
      this.registrationService.registerOrganization(data).pipe(catchError(() => {
        this.processingRegistration = false;
        this.registrationFailed = true;
        return throwError(() => new Error('API Request Error'));
      })).subscribe(() => {
        this.alertService.showSuccessAlert('Registration Complete');
        this.zone.run(() => {
          this.router.navigateByUrl('/login', { replaceUrl: true })
        });
      });
    }
  }

  // User Form Accessors
  get firstName() { return this.userForm.controls.firstName; }
  get lastName() { return this.userForm.controls.lastName; }
  get userEmail() { return this.userForm.controls.email; }
  get phone() { return this.userForm.controls.phone; }
  get password() { return this.userForm.controls.password; }
  get confirmPassword() { return this.userForm.controls.confirmPassword; }

  // Organization Form Accessors
  get name() { return this.organizationForm.controls.name; }
  get orgEmail() { return this.organizationForm.controls.email; }
  get street() { return this.organizationForm.controls.street; }
  get city() { return this.organizationForm.controls.city; }
  get state() { return this.organizationForm.controls.state; }
  get postalCode() { return this.organizationForm.controls.postalCode; }

  // Billing Form Accessors
  get billingName() { return this.billingForm.controls.name; }
  get billingStreet() { return this.billingForm.controls.street; }
  get billingCity() { return this.billingForm.controls.city; }
  get billingState() { return this.billingForm.controls.state; }
  get billingPostalCode() { return this.billingForm.controls.postalCode; }
}
