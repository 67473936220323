import { Component, HostListener, OnInit } from '@angular/core';
import { TinselPayService } from '../tinsel-pay.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-tinsel-pay-overview-card-vaults',
  templateUrl: './tinsel-pay-overview-card-vaults.component.html',
  styleUrls: ['./tinsel-pay-overview-card-vaults.component.css']
})
export class TinselPayOverviewCardVaultsComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

    // Variable Subject
    private resizeSubject: Subject<number> = new Subject<number>();


  // Properties
  vaults: any[] = [];
  isMobileView: boolean = false;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private tinselPayService: TinselPayService) {
    this.resizeSubject.pipe(
      debounceTime(100)
    ).subscribe(width => {
      if (width <= 768) {
        this.checkScreenSize(width);
      } else {
        this.isMobileView = false;
      }
    });
  }

  ngOnInit(): void {
    this.getTinselPayCardVaults();
    this.checkScreenSize();
  }

  // Get Tinsel Pay Card Vaults
  private getTinselPayCardVaults(): void {
    this.tinselPayService.getTinselPayCardVaults({}).subscribe((vaults) => {
      this.vaults = vaults;
    });
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }
}
