<div class="main-wrapper">
    <div class="d-flex align-items-center position-relative min-vh-100 login-cover">
        <!-- start left panel  -->
        <div class="col-lg-5 col-xl-5 d-none d-lg-flex align-items-center px-0 bg-img"></div>
        <!-- end left panel  -->
        <div class="container">
            <div class="row justify-content-center justify-content-lg-start">
                <div class="col-md-8 col-lg-8 col-xl-5 m-auto">
                    <div>
                        <div class="text-center">
                            <picture>
                                <source type="image/webp" srcset="../assets/TinselLogo.webp">
                                <source type="image/png" srcset="../assets/TinselLogo.png">
                                <img src="../assets/TinselLogo.png" width="40%" class="logo" alt="Tinsel Logo" loading="eager">
                            </picture>
                        </div>
                        <h2 class="font-weight-normal mb-0">Forgot your password?</h2>
                    </div>
                    <div>
                        <form [formGroup]="resetPasswordForm">
                            <!-- Email -->
                            <div *ngIf="stage == 0">
                                <p class="mt-2">Enter your email address. A verification code will be sent to this email address.</p>
                                <div class="row">
                                    <div class="col">
                                        <div class="mb-3">
                                            <label for="EMAIL">Email</label>
                                            <input id="EMAIL" type="text" class="form-control" placeholder="jappleseed@example.com" formControlName="email" maxlength="320" pattern="^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$" required autocapitalize="off" autocomplete="email" [class.is-invalid]="email.invalid && (email.dirty || email.touched)" [class.is-valid]="email.valid && (email.dirty || email.touched)">
                                            <div *ngIf="email.invalid && (email.dirty || email.touched)">
                                                <div *ngIf="email.errors.required" class="input-validation-msg">Email is required.</div>
                                                <div *ngIf="email.errors.maxlength" class="input-validation-msg">Email cannot exceed 320 characters.</div>
                                                <div *ngIf="email.errors.pattern" class="input-validation-msg">Email is formatted incorrectly.</div>
                                                <div *ngIf="email.errors.doesNotExist" class="input-validation-msg">Email does not exist.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <button type="button" class="btn btn-primary ms-auto" (click)="alreadyHaveCode();">Already Have Code</button>
                                    <button type="button" class="btn btn-primary ms-2" (click)="initiatePasswordReset();">Send Code</button>
                                </div>
                            </div>
                            <!-- Verification Code -->
                            <div *ngIf="stage == 1">
                                <p class="mt-2">Enter the verification code sent to your email address.</p>
                                <div class="row">
                                    <div class="col">
                                        <div class="mb-3">
                                            <label for="VERIFICATION_CODE">Verification Code</label>
                                            <input id="VERIFICATION_CODE" type="text" class="form-control" placeholder="000000" formControlName="verificationCode" minlength="6" maxlength="6" required autocapitalize="off" [class.is-invalid]="verificationCode.invalid && (verificationCode.dirty || verificationCode.touched)" [class.is-valid]="verificationCode.valid && (verificationCode.dirty || verificationCode.touched)">
                                            <div *ngIf="verificationCode.invalid && (verificationCode.dirty || verificationCode.touched)">
                                                <div *ngIf="verificationCode.errors.required" class="input-validation-msg">Verification Code is required.</div>
                                                <div *ngIf="verificationCode.errors.minlength" class="input-validation-msg">Verification Code must be 6 numbers.</div>
                                                <div *ngIf="verificationCode.errors.maxlength" class="input-validation-msg">Verification Code must be 6 numbers.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <button type="button" class="btn btn-primary ms-auto" (click)="verifyVerificationCode();">Next</button>
                                </div>
                            </div>
                            <!-- New Password -->
                            <div *ngIf="stage == 2">
                                <p class="mt-2">Create a new password.</p>
                                <div class="row">
                                    <div class="col">
                                        <div class="mb-3">
                                            <label for="PASSWORD">New Password</label>
                                            <input id="PASSWORD" type="password" formControlName="password" class="form-control" placeholder="Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required autocomplete="new-password" [class.is-invalid]="password.invalid && (password.dirty || password.touched)" [class.is-valid]="password.valid && (password.dirty || password.touched)">
                                            <div *ngIf="password.invalid && (password.dirty || password.touched)">
                                                <div *ngIf="password.errors.required" class="input-validation-msg">Password is required.</div>
                                                <div *ngIf="password.errors.pattern" class="input-validation-msg">Password must be 8 characters and contain uppercase, lowercase, and number characters.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="mb-3">
                                            <label for="CONFIRM_PASSWORD">Confirm New Password</label>
                                            <input id="CONFIRM_PASSWORD" type="password" formControlName="confirmPassword" class="form-control" placeholder="Confirm Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required autocomplete="new-password" [class.is-invalid]="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)" [class.is-valid]="confirmPassword.valid && (confirmPassword.dirty || confirmPassword.touched)">
                                            <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                                                <div *ngIf="confirmPassword.errors.required" class="input-validation-msg">Confirm Password is required.</div>
                                                <div *ngIf="confirmPassword.errors.pattern" class="input-validation-msg">Confirm Password must be 8 characters and contain uppercase, lowercase, and number characters.</div>
                                                <div *ngIf="confirmPassword.errors.doesNotMatch" class="input-validation-msg">Passwords do not match.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <button type="button" class="btn btn-primary ms-auto" (click)="resetForgottenPassword();">Reset Password</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>