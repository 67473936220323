import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Prospect } from './prospect';

@Injectable({
  providedIn: 'root'
})
export class ProspectService {

  constructor(private http: HttpClient) { }

  // Accept Prospect
  acceptProspect(prospectId: string, waitList: boolean): Observable<any> {
    return this.http.put<any>(`/prospects/${prospectId}/accept`, { waitList: waitList.toString() });
  }

  // Reject Prospect
  rejectProspect(prospectId: string, rejectReason: string): Observable<any> {
    return this.http.put<any>(`/prospects/${prospectId}/reject`, { rejectReason: rejectReason });
  }

  // Delete Prospect
  deleteProspect(prospectId: string): Observable<any> {
    return this.http.delete<any>(`/prospects/${prospectId}`);
  }

  // Get Prospects
  getProspects(params: any): Observable<{ prospects: Prospect[], count: number }> {
    return this.http.get<{ prospects: Prospect[], count: number }>(`/prospects`, {
      params: params
    });
  }

  // Get Distinct Column Values
  getDistinctColumnValues(column: string): Observable<string[]> {
    return this.http.get<string[]>(`/prospects/distinctColumnValues`, {
      params: {
        column: column
      }
    });
  }
}
