<div class="row warning-banner my-2">
    <p>
        During the vetting process, Twilio may reach out to the authorized representatives included in your Business Profiles to confirm your business identity. You can view <a href="https://www.twilio.com/en-us/legal/privacy" target="_blank">Twilio's Privacy Statement</a> to learn more about how they retain these records.
    </p>
</div>
<div class="row">
    <div class="col-sm-12">
        <form [formGroup]="contactPersonForm">
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Business Title</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. President of the company" formControlName="business_title" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Email</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. support@tinselcrm.com" formControlName="email" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">First Name</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. Joe" formControlName="first_name" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Last Name</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input  placeholder="Ex. Marcus" formControlName="last_name" class="form-control" type="text">
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Job Position</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <select  class="form-select" formControlName="job_position" required>
                            <option selected disabled [value]="null">Ex. CEO</option>
                            <option *ngFor="let bus of jobPositionValues" [value]="bus.value">{{bus.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Phone Number</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. +12225557890" formControlName="phone_number" class="form-control" type="text">
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-sm-12 d-flex">
        <button (click)="submitForm()" type="button" class="btn btn-primary ms-auto" [disabled]="!contactPersonForm.valid">
            Submit
        </button>
    </div>
</div>