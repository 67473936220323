import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeoPosition } from './geo-position';
import { TimeEntry } from './time-entry';

@Injectable({
  providedIn: 'root'
})
export class TimeEntryService {

  constructor(private http: HttpClient) { }

  // Add Time Entry
  addTimeEntry(timeEntry: any): Observable<any> {
    return this.http.post<any>(`/time-entries`, timeEntry);
  }

  // Update Time Entry
  updateTimeEntry(timeEntry: any): Observable<any> {
    return this.http.put<any>(`/time-entries/${timeEntry.id}`, timeEntry);
  }

  // Clock In
  clockIn(timeEntry: any): Observable<any> {
    return this.http.post<any>(`/time-entries/clockIn`, timeEntry);
  }

  // Clock Out
  clockOut(timeEntryId: string, endLocation: GeoPosition): Observable<any> {
    return this.http.put<any>(`/time-entries/${timeEntryId}/clockOut`, { endLocation: endLocation });
  }

  // Delete Time Entry
  deleteTimeEntry(timeEntryId: string): Observable<any> {
    return this.http.delete<any>(`/time-entries/${timeEntryId}`);
  }

  // Get Time Entries For Project
  getTimeEntriesForProject(projectId: string): Observable<TimeEntry[]> {
    return this.http.get<TimeEntry[]>(`/time-entries/projects/${projectId}`);
  }

  // Get Time Entries For Current User
  getTimeEntriesForCurrentUser(): Observable<TimeEntry[]> {
    return this.http.get<TimeEntry[]>(`/time-entries/user`);
  }

  // Get Time Entries For Project User
  getTimeEntriesForProjectUser(projectId: string): Observable<TimeEntry[]> {
    return this.http.get<TimeEntry[]>(`/time-entries/projects/${projectId}/user`);
  }

  // Get Last Clock In Time Entry
  getLastClockInTimeEntry(): Observable<TimeEntry> {
    return this.http.get<TimeEntry>(`/time-entries/lastClockIn`);
  }

  // Get Time Clock
  getTimeClock(userId: string, startDate: number, endDate: number): Observable<{ timeEntries: TimeEntry[], totalDuration: number }> {
    return this.http.get<{ timeEntries: TimeEntry[], totalDuration: number }>(`/time-entries/timeClock`, {
      params: {
        userId: userId,
        startDate: startDate,
        endDate: endDate
      }
    });
  }
}
