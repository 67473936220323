<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
          <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
            <fa-icon [icon]="faAngleLeft"></fa-icon>
        </button>
        <h2 class="mobileHeading">Inventory Prep</h2>
        </div>
    </nav>
    <div class="row mt-3">
        <div class="col-12">
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Pending</a>
                    <ng-template ngbNavContent>
                        <app-project-prep-global></app-project-prep-global>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>Orders</a>
                    <ng-template ngbNavContent>
                        <app-project-prep-orders></app-project-prep-orders>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink>Inventory</a>
                    <ng-template ngbNavContent>
                        <app-project-prep-inventory></app-project-prep-inventory>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</div>
