import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { AlertService } from '../alert.service';
import { FirstInvoice, Proposal } from '../proposal';
import { ProposalPricing } from '../proposal-pricing';
import { ProposalService } from '../proposal.service';
import { Router } from '@angular/router';
import { EmailService } from '../email.service';
import { SettingService } from '../setting.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-accept-proposal',
  templateUrl: './accept-proposal.component.html',
  styleUrls: ['./accept-proposal.component.css']
})
export class AcceptProposalComponent implements OnInit {

  // Properties
  @Input() proposal: Proposal;
  @Input() firstInvoice: FirstInvoice;
  @Input() isInvoiceLess: boolean;

  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  followUpQuestionsForm: UntypedFormGroup;
  proposalPricing: ProposalPricing;
  installationWeeks: { date: string, label: string }[] = [];
  isAccepted: boolean = false;
  preferredInstallation: boolean = false;
  preferredInstallationOptions: { date: string, label: string }[] = [];
  preferredRemoval: boolean = false;
  toEmailAddresses: string;
  acceptedProposal: any;

  multiOptionKeys: any[] = [];
  selectedMultiOptionKey: any;

  signature: string;

  constructor(private proposalService: ProposalService,
    private alertService: AlertService,
    private router: Router,
    private emailService: EmailService,
    private settingService: SettingService,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.followUpQuestionsForm = new UntypedFormGroup({
      selectedMultiOption: new UntypedFormControl(),
      installationWeek: new UntypedFormControl('NONE'),
      removalType: new UntypedFormControl('NONE'),
      additionalInfo: new UntypedFormControl(),
      method: new UntypedFormControl()
    });
    this.calculateProposalPricing();
    this.generateInstallationWeeks();
    this.getProposalSetting();

    if (this.proposal.status != 'PENDING' || this.proposal.rowNumber > 1) this.prepareFollowUpQuestionsForm();
    if (this.proposal.status != 'PENDING') this.followUpQuestionsForm.disable();

    // Multi Options
    if (this.proposal.agreementType == 'MULTI') {
      this.multiOptionKeys = Object.keys(this.proposal.multiOptions);
      this.selectedMultiOptionKey = this.multiOptionKeys[0];
    }
  }

  // Multi Option Is Enabled
  multiOptionIsEnabled(key: string): boolean {
    return this.proposal.multiOptions[key]?.isEnabled;
  }

  // Prepare Follow Up Questions Form
  private prepareFollowUpQuestionsForm(): void {
    this.proposalService.getAcceptedProposalForProject(this.proposal.projectId).subscribe((acceptedProposal) => {
      this.acceptedProposal = acceptedProposal;
      console.log(acceptedProposal);

      this.followUpQuestionsForm.controls.selectedMultiOption.setValue(acceptedProposal.selectedMultiOption);
      this.followUpQuestionsForm.controls.installationWeek.setValue((acceptedProposal.installationWeek === null) ? 'NONE' : acceptedProposal.installationWeek);
      this.followUpQuestionsForm.controls.removalType.setValue(acceptedProposal.removalType);
      this.followUpQuestionsForm.controls.additionalInfo.setValue(acceptedProposal.additionalInfo);
      this.followUpQuestionsForm.controls.method.setValue(acceptedProposal.method);
      if (acceptedProposal.signature) this.signature = this.getSignatureImageDataURI(acceptedProposal.signature);
    });
  }

  // Generate Installation Weeks
  private generateInstallationWeeks(): void {
    const startDate = dayjs(`${dayjs().year()}-09-01`).startOf('week');
    for (var i = 0; i < 17; i++) {
      const date = dayjs(startDate).add(i, 'week');
      this.installationWeeks.push({
        date: date.format('YYYY-MM-DD'),
        label: `Week of ${date.format('MMMM D, YYYY')}`
      });
    }
  }

  private getProposalSetting() {
    this.proposalService.proposalSetting(this.proposal.id).subscribe((res) => {
      this.preferredInstallation = res.preferredInstallation;
      this.preferredRemoval = res.preferredRemoval;
      this.preferredInstallationOptions = res.preferredInstallationOptions;
    });
  }

  // Calculate Proposal Pricing
  calculateProposalPricing(): void {
    this.proposalService.calculateProposalPricing(this.proposal.id).subscribe((res) => {
      this.proposalPricing = res;
    });
  }

  // Accept Proposal
  acceptProposal(): void {
    if (this.followUpQuestionsForm.valid) {
      const acceptedProposal = {
        id: this.proposal.id,
        selectedMultiOption: (this.proposal.agreementType == 'MULTI') ? this.followUpQuestionsForm.value.selectedMultiOption : null,
        installationWeek: (this.followUpQuestionsForm.value.installationWeek == 'NONE') ? null : this.followUpQuestionsForm.value.installationWeek,
        removalType: this.followUpQuestionsForm.value.removalType,
        additionalInfo: this.followUpQuestionsForm.value.additionalInfo,
        method: this.followUpQuestionsForm.value.method,
        signature: null,
        ...this.firstInvoice
      };
      this.proposalService.acceptProposal(acceptedProposal).subscribe(async (res) => {
        this.alertService.showSuccessAlert('Proposal Accepted');


        if (this.proposal.rowNumber == 1 && this.proposal.isInvoiceRequired) {
          if (this.proposal.clientEmail) await this.sendInvoiceEmail(res.invoiceId, res.invoiceNumber);
          this.redirectToInvoice(res.invoiceId);
        }

        this.saved.emit({
          rowNumber: this.proposal.rowNumber,
          invoiceNumber: res.invoiceNumber,
          invoiceId: res.invoiceId
        });
        this.modal.close();
      });
    } else {
      this.followUpQuestionsForm.markAllAsTouched();
    }
  }

  private async sendInvoiceEmail(invoiceId, invoiceNumber): Promise<void> {
    try {
      let template = await lastValueFrom(this.settingService.getEmailTemplate('INVOICE'));
      template = template.replace('##FIRST_NAME##', this.proposal.clientName);
      const subject = `Invoice # ${invoiceNumber}`;
      const body = template;
      const data = {
        invoiceId: invoiceId,
        to: [this.proposal.clientEmail],
        cc: [],
        subject: subject,
        body: body
      };
      this.emailService.sendInvoiceEmail(data).subscribe(() => {
        // this.alertService.showSuccessAlert('Email Sent');
        console.log('INVOICE EMAIL SENT')
      });
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  redirectToInvoice(invoiceId: string): void {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/invoices/${invoiceId}/view`]));
    window.open(url);
  }

  // Update Accepted Proposal
  updateAcceptedProposal(): void {
    if (this.followUpQuestionsForm.valid) {
      const acceptedProposal = {
        id: this.proposal.id,
        selectedMultiOption: (this.proposal.agreementType == 'MULTI') ? this.followUpQuestionsForm.value.selectedMultiOption : null,
        installationWeek: (this.followUpQuestionsForm.value.installationWeek == 'NONE') ? null : this.followUpQuestionsForm.value.installationWeek,
        removalType: this.followUpQuestionsForm.value.removalType,
        additionalInfo: this.followUpQuestionsForm.value.additionalInfo,
        method: this.followUpQuestionsForm.value.method
      };
      this.proposalService.updateAcceptedProposal(acceptedProposal).subscribe(() => {
        this.alertService.showSuccessAlert('Accepted Proposal Updated');
        this.saved.emit();
        this.modal.close();
      });
    }
  }

  // Get Signature Image Data URI
  private getSignatureImageDataURI(signature: string): string {
    return 'data:image/png;base64,' + signature;
  }

  // Follow-Up Questions Form Accessors
  get selectedMultiOption() { return this.followUpQuestionsForm.controls.selectedMultiOption; }
  get installationWeek() { return this.followUpQuestionsForm.controls.installationWeek; }
  get removalType() { return this.followUpQuestionsForm.controls.removalType; }
  get additionalInfo() { return this.followUpQuestionsForm.controls.additionalInfo; }
  get method() { return this.followUpQuestionsForm.controls.method; }
}
