import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Site } from '../site';
import { SiteService } from '../site.service';
import { Project } from '../project';
import { ProjectService } from '../project.service';
import { AlertService } from '../alert.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faAngleLeft, faSave, faTrashCan, faCheck, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NewProjectModalComponent } from '../new-project-modal/new-project-modal.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-site-detail',
  templateUrl: './site-detail.component.html',
  styleUrls: ['./site-detail.component.css']
})
export class SiteDetailComponent implements OnInit {

  // Modals
  private modalReference: NgbModalRef;

  // Properties
  siteForm: UntypedFormGroup;
  site: Site;
  siteId: string;
  projects: Project[] = [];

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faSave = faSave;
  faTrashCan = faTrashCan;
  faCheck = faCheck;
  faPencil = faPencil;
  faPlus = faPlus;

  constructor(private route: ActivatedRoute,
    public location: Location,
    private siteService: SiteService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.siteId = this.route.snapshot.paramMap.get('siteId');
    this.siteForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      street: new UntypedFormControl(),
      city: new UntypedFormControl(),
      county: new UntypedFormControl(),
      state: new UntypedFormControl('SAS'),
      postalCode: new UntypedFormControl()
    });
    this.getSite();
    this.getProjectsForSite();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Delete Site Confirmation Modal
  openDeleteSiteConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this site?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteSite();
    });
  }

  // Open New Project Modal
  openNewProjectModal(): void {
    this.modalReference = this.modalService.open(NewProjectModalComponent);
    this.modalReference.componentInstance.clientId = this.site.clientId;
    this.modalReference.componentInstance.siteId = this.siteId;
  }

  // Get Site
  getSite(): void {
    this.siteService.getSite(this.siteId).subscribe((site) => {
      this.site = site;
      this.setSiteFormValues();
    });
  }

  // Set Site Form Values
  setSiteFormValues(): void {
    this.siteForm.controls.name.setValue(this.site.name);
    this.siteForm.controls.street.setValue(this.site.address.street);
    this.siteForm.controls.city.setValue(this.site.address.city);
    this.siteForm.controls.county.setValue(this.site.address.county);
    this.siteForm.controls.state.setValue(this.site.address.state);
    this.siteForm.controls.postalCode.setValue(this.site.address.postalCode);
  }

  // Update Site
  updateSite(): void {
    if (this.siteForm.valid) {
      const site = {
        id: this.siteId,
        name: this.siteForm.value.name,
        address: {
          street: this.siteForm.value.street,
          city: this.siteForm.value.city,
          county: this.siteForm.value.county,
          state: this.siteForm.value.state,
          postalCode: this.siteForm.value.postalCode
        }
      };
      this.siteService.updateSite(site).subscribe(() => {
        this.alertService.showSuccessAlert('Site Updated');
        this.getSite();
      });
    } else {
      this.siteForm.markAllAsTouched();
    }
  }

  // Delete Site
  deleteSite(): void {
    this.siteService.deleteSite(this.siteId).subscribe(() => {
      this.location.back();
    });
  }

  // Site Form Accessors
  get name() { return this.siteForm.controls.name; }

  /* ----- Projects ----- */

  // Get Projects For Site
  private getProjectsForSite() {
    const params = {
      searchTerm: null,
      sortBy: 'p.project_name',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:s.site_id': JSON.stringify([this.siteId])
    };
    this.projectService.getProjects(params).subscribe((res) => {
      this.projects = res.projects;
    });
  }
}
