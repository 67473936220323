import { Component, OnInit } from '@angular/core';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BugReportService } from '../bug-report.service';
import { AlertService } from '../alert.service';
import { BugReport } from '../bug-report';

@Component({
  selector: 'app-bug-report',
  templateUrl: './bug-report.component.html',
  styleUrls: ['./bug-report.component.css']
})
export class BugReportComponent implements OnInit {

  // Properties
  bugReportForm: UntypedFormGroup;
  bugReports: BugReport[] = [];

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;

  constructor(private bugReportService: BugReportService,
    private alertService: AlertService,
    public location: Location) { }

  ngOnInit(): void {
    this.bugReportForm = new UntypedFormGroup({
      title: new UntypedFormControl(),
      report: new UntypedFormControl()
    });
    this.getBugReportsForUser();
  }

  // Get Bug Reports For User
  private getBugReportsForUser(): void {
    this.bugReportService.getBugReportsForUser().subscribe((bugReports) => {
      this.bugReports = bugReports;
    });
  }

  // Add Bug Report
  addBugReport(): void {
    if (this.bugReportForm.valid) {
      const body = {
        title: this.bugReportForm.value.title,
        report: this.bugReportForm.value.report
      };
      this.bugReportService.addBugReport(body).subscribe(() => {
        this.alertService.showSuccessAlert('Bug Report Submitted');
        this.resetBugReportForm();
        this.getBugReportsForUser();
      });
    } else {
      this.bugReportForm.markAllAsTouched();
    }
  }

  // Reset Bug Report Form
  private resetBugReportForm(): void {
    this.bugReportForm.reset();
    this.bugReportForm.controls.title.setValue(null);
    this.bugReportForm.controls.report.setValue(null);
  }

  // Get Status Color
  getStatusColor(status: string): string {
    switch (status) {
      case 'OPEN':
        return 'green'
      case 'IN REVIEW':
        return 'darkorange';
      case 'CLOSED':
        return 'red';
      default:
        return 'black';
    }
  }
}
