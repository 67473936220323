import { Component, Input, OnInit } from '@angular/core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Project } from '../project';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-snapshot-project-list',
  templateUrl: './snapshot-project-list.component.html',
  styleUrls: ['./snapshot-project-list.component.css']
})
export class SnapshotProjectListComponent implements OnInit {

  // Inputs and Outputs
  @Input() cardTitle: string;
  @Input() stage: string;

  // Properties
  projects: Project[] = [];

  // Font Awesome Properties
  faCircle = faCircle;

  constructor(private projectService: ProjectService) { }

  ngOnInit(): void {
    this.getProjects();
  }

  // Get Projects
  private getProjects(): void {
    const params = {
      searchTerm: null,
      sortBy: 'c.client_name',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:p.project_stage': JSON.stringify([this.stage]),
      'filter:p.project_status': JSON.stringify(['IN PROGRESS'])
    };
    this.projectService.getProjects(params).subscribe((res) => {
      this.projects = res.projects;
      console.log(res.projects)
    });
  }
}
