import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { lastValueFrom } from 'rxjs';
import { AlertService } from '../alert.service';
import { AuthService } from '../auth.service';
import { SettingService } from '../setting.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UsageService } from '../usage.service';

@Component({
  selector: 'app-settings-organization',
  templateUrl: './settings-organization.component.html',
  styleUrls: ['./settings-organization.component.css']
})
export class SettingsOrganizationComponent implements OnInit {

  // Modal
  private modalReference: NgbModalRef;

  // Properties
  businessInformationForm: UntypedFormGroup;
  businessAddressForm: UntypedFormGroup;
  organizationLogoExists: boolean = false;
  organizationLogoUrl: string | SafeUrl;
  fileReadyForUpload: boolean = false;

  // Storage
  totalFileCount: number = 0;
  totalStorageSize: number = 0;
  storageSizeLimit: number = 0;

  // Font Awesome Imports
  faSave = faSave;

  constructor(private settingService: SettingService,
    private usageService: UsageService,
    private authService: AuthService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.businessInformationForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      email: new UntypedFormControl(),
      phone: new UntypedFormControl()
    });
    this.businessAddressForm = new UntypedFormGroup({
      street: new UntypedFormControl(),
      city: new UntypedFormControl(),
      state: new UntypedFormControl('SAS'),
      postalCode: new UntypedFormControl()
    });
    this.getOrganizationSettings();
    this.getOrganizationLogoUrl();
    this.getStorageMetricsForOrganization();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Get Organization Settings
  private getOrganizationSettings(): void {
    this.settingService.getOrganizationSettings().subscribe((organizationSettings) => {
      // Business Information
      this.businessInformationForm.controls.name.setValue(organizationSettings.name);
      this.businessInformationForm.controls.email.setValue(organizationSettings.email);
      this.businessInformationForm.controls.phone.setValue(organizationSettings.phone);
      // Business Address Form
      const address = organizationSettings.location.address;
      this.businessAddressForm.controls.street.setValue(address.street);
      this.businessAddressForm.controls.city.setValue(address.city);
      this.businessAddressForm.controls.state.setValue(address.state);
      this.businessAddressForm.controls.postalCode.setValue(address.postalCode);
      // Business Logo
      this.organizationLogoExists = organizationSettings.logoExists;
    });
  }

  // Update Business Information
  updateBusinessInformation(): void {
    if (this.businessInformationForm.valid) {
      this.settingService.updateOrganizationSettings(this.businessInformationForm.value).subscribe(() => {
        this.alertService.showSuccessAlert('Business Information Updated');
        this.getOrganizationSettings();
      });
    } else {
      this.businessInformationForm.markAllAsTouched();
    }
  }

  // Update Business Address
  updateBusinessAddress(): void {
    if (this.businessAddressForm.valid) {
      this.settingService.updateOrganizationSettings({ address: this.businessAddressForm.value }).subscribe(() => {
        this.alertService.showSuccessAlert('Business Address Updated');
        this.getOrganizationSettings();
      });
    } else {
      this.businessAddressForm.markAllAsTouched();
    }
  }

  // Get Organization Logo URL
  private async getOrganizationLogoUrl(): Promise<void> {
    const currentUser = await this.authService.getCurrentUser();
    this.organizationLogoUrl = this.settingService.getOrganizationLogoUrl(currentUser.organizationId);
  }

  // Upload Organization Logo
  async uploadOrganizationLogo(): Promise<void> {
    const file = (<HTMLInputElement>document.getElementById('ORGANIZATION_LOGO_FILE')).files[0];
    const presignedUrl = await lastValueFrom(this.settingService.getOrganizationLogoPresignedUploadUrl(file.type));
    await new Promise<void>((resolve, reject) => {
      this.settingService.uploadVenmoCode(presignedUrl, file).subscribe((event: HttpEvent<any>) => {
        if (event.type == HttpEventType.Response) {
          (event.ok) ? resolve() : reject();
        }
      });
    });
    await lastValueFrom(this.settingService.updateOrganizationSettings({ logoExists: true }));
    this.alertService.showSuccessAlert('Business Logo Uploaded');
    this.resetFileUpload();
    this.getOrganizationSettings();
  }

  // Browse Files
  browseFiles(): void {
    document.getElementById('ORGANIZATION_LOGO_FILE').click();
  }

  // File Input Changed
  fileInputChanged(files: File[]): void {
    this.organizationLogoUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(files[0]));
    this.fileReadyForUpload = true;
  }

  // Reset File Upload
  private resetFileUpload(): void {
    this.fileReadyForUpload = false;
  }

  // Delete Organization Logo
  deleteOrganizationLogo(): void {
    this.settingService.deleteOrganizationLogo().subscribe(() => {
      this.alertService.showSuccessAlert('Business Logo Deleted');
      this.getOrganizationSettings();
    });
  }

  // Business Information Form Accessors
  get businessName() { return this.businessInformationForm.controls.name; }
  get businessEmail() { return this.businessInformationForm.controls.email; }
  get businessPhone() { return this.businessInformationForm.controls.phone; }

  /* ------ Storage ----- */

  // Get Storage Metrics For Organization
  private async getStorageMetricsForOrganization(): Promise<void> {
    const storageMetricsRes = await lastValueFrom(this.settingService.getStorageMetricsForOrganization());
    this.totalFileCount = storageMetricsRes.totalFileCount;
    this.totalStorageSize = storageMetricsRes.totalStorageSize;
    this.storageSizeLimit = await lastValueFrom(this.usageService.getUsageLimit('FILE_STORAGE'));
  }
}
