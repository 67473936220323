import { Injectable } from '@angular/core';
import { Client, ConnectionState, Conversation, Message, Participant } from '@twilio/conversations';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConversationService } from './conversation.service';
import { CallService } from './call.service';
import { Device } from '@twilio/voice-sdk';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';
import { NotificationService } from './notification.service';

@Injectable({ providedIn: 'root' })
export class TwilioService {

  twilioClient: any;
  twilioDevice: any
  chatList: any[] = [];
  unreadMessages: any[] = [];
  subs = new Subscription()
  retryConnection: number = 0;

  twilioOutgoingCall = new BehaviorSubject(this.outgoingConnection)
  outGoingCallRinging = new BehaviorSubject(this.outgoingRinging)
  outGoingCallAnswered = new BehaviorSubject(this.outgoingAnswered)

  set outgoingConnection(value: any) {
    this.twilioOutgoingCall.next(value);
    console.log(value)
    if (value) {
      this.listenToOutgoingCallEvents(value)
    }
  }

  get outgoingConnection() {
    return this.twilioOutgoingCall;
  }

  set outgoingRinging(value: any) {
    this.outGoingCallRinging.next(value);
  }

  get outgoingRinging() {
    return this.outGoingCallRinging;
  }

  set outgoingAnswered(value: any) {
    this.outGoingCallAnswered.next(value);
  }

  get outgoingAnswered() {
    return this.outGoingCallAnswered;
  }

  constructor(
    private http: HttpClient,
    private conversationSerivce: ConversationService,
    private alertService: AlertService,
    private callService: CallService,
    private notificationService: NotificationService
  ) { }

  connectTwilio() {
    let token = localStorage.getItem('twilio')

    if (!token) {
      if (this.retryConnection < 6) {
        this.subs.add(
          this.conversationSerivce.getToken()
            .pipe().subscribe(async (tokenFromService) => {
              localStorage.setItem('twilio', tokenFromService)
              token = tokenFromService

              this.listenToEvents(token);
              this.listenToCallEvents(token);
              this.retryConnection++;
            })
        )
      } else {
        this.alertService.showErrorAlert("Cannot connect to SMS service. Please contact support.")
      }

    } else {
      this.listenToEvents(token);
      this.listenToCallEvents(token);
    }
  }

  fetchUserChats() {
    // let clientNumber = NonNullAssert;
    if (this.twilioClient) {
      this.twilioClient.getSubscribedConversations().then(convs => {
        let chats: any = [...convs.items];
        this.chatList = [];
        if (chats && chats.length != 0) {
          chats.map(async (chat: Conversation, index) => {
            const message = await chat.getMessages(1, chat?.lastMessage?.index, "backwards")
            const lastMsg = message && message.items.length != 0 ? message.items[0].body : 'No new Message'
            const lastMessageDateTime = message && message.items.length != 0 ? message.items[0].dateUpdated : null
            const lastMessageIndex = chat?.lastMessage?.index;
            const author = message?.items[0]?.author;

            let obj = {
              chat: chat,
              id: chat.sid,
              name: chat.friendlyName,
              clientNumber: chat && chat.attributes ? chat.attributes['contactNumber'] : null,
              lastConversation: lastMsg,
              lastMessageDateTime,
              contactId: chat.uniqueName ? chat.uniqueName: null,
              lastSender: author,
              unreadMessageCount: lastMessageIndex - (chat?.lastReadMessageIndex !== null ? chat?.lastReadMessageIndex : -1)
            }
            let checkExistingIndex = this.chatList.findIndex(o => o.id === obj.id);
            if (checkExistingIndex > -1) {
              this.chatList[checkExistingIndex] = obj;
            } else {
              this.chatList.push(obj);
            }


            if (this.chatList.length === chats.length) {
              this.conversationSerivce.chatList = this.chatList.sort((a, b) => new Date(b.lastMessageDateTime).getTime() - new Date(a.lastMessageDateTime).getTime());
              ;
            }
          })
        }
        //this.conversationSerivce.chatList = this.chatList;
      }).catch(error => console.log(error));
    } else {
      this.alertService.showErrorAlert('Twilio client not initialized');
    }
  }

  getActiveUsers() {
    this.twilioClient.getSubscribedUsers().then(users => {
      console.log(users)
    })
  }

  updateToken() {
    this.subs.add(
      this.conversationSerivce.getToken()
        .pipe().subscribe(async (token) => {
          localStorage.setItem('twilio', token)
          if(this.twilioClient) this.twilioClient = await this.twilioClient.updateToken(token);
          if(this.twilioDevice) this.twilioDevice = await this.twilioDevice.updateToken(token);
        })
    )
  }

  listenToOutgoingCallEvents(outgoingConnection) {
    outgoingConnection.on('error', (callError) => {
      console.log('Error on Call');
      console.log(callError);
    });
    outgoingConnection.on('ringing', () => {
      console.log('The call is ringing');
      this.outgoingRinging = true
    });

    outgoingConnection.on('accept', () => {
      console.log('The call has been accepted');
      this.outgoingRinging = false
      this.outgoingAnswered = true
    });

    outgoingConnection.on('reject', () => {
      console.log('The call was rejected');
      this.twilioDevice.destroy();

    });

    outgoingConnection.on('disconnect', () => {
      console.log('The call has been disconnected');
      this.twilioDevice.destroy();
      this.connectTwilio()
      this.outgoingAnswered = false;
      this.outgoingRinging = false;

    });
  }

  listenToCallEvents(token) {
    this.twilioDevice = new Device(token, {
      appName: 'ApplicationCall',
      closeProtection: true
    });
    this.callService.isCallEnded.next(false);
    this.callService.device = this.twilioDevice
    this.twilioDevice.addListener("connect", (device) => {
      console.log("Connect event listener added .....");
    });
    this.twilioDevice.on('registered', (device) => {
      console.log('The device is ready to receive incoming calls.')
    });
    this.twilioDevice.on('connect', (device) => {
      console.log('The call is connected')
      this.callService.activeCall.next(device);
    });
    this.twilioDevice.on('disconnect', (device) => {
      console.log('The call is disconnected')
      this.twilioDevice.destroy()
      this.connectTwilio()
      this.callService.isCallAccept = false;
      this.callService.isCallEnded.next(true);
    });

    this.twilioDevice.on('error', (twilioError, call) => {
      console.log('An error has occurred: ', twilioError);
    });

    this.twilioDevice.on('incoming', call => {
      console.log(call)
      this.callService.incomingCall = true;
      this.callService.isCallEnded.next(false);
      this.callService.activeCall.next(call);
      console.log('Someone is calling')
      this.callService.fromNumber.next(call.parameters.From)
    });

    this.twilioDevice.on('destroyed', call => {
      console.log('Call Destroyed')
      this.callService.isCallEnded.next(true);
    });

    this.twilioDevice.on('unregistered', () => {
      console.log('The device is no longer able to receive calls.')
    })

    this.twilioDevice.on('tokenWillExpire', () => {
      this.updateToken()
    });

    const events = this.twilioDevice.eventNames()
    console.log(events)
    this.twilioDevice.register()

  }

  listenToEvents(token: string) {
    this.twilioClient = new Client(token);
    this.conversationSerivce.client = this.twilioClient

    this.twilioClient.on('initialized', (data) => {
      console.log('Client has been initialized')
      this.conversationSerivce.connectionInitialiazed = true;
      setTimeout(async () => {
        this.fetchUserChats();
      }, 500);

    });

    this.twilioClient.on('initFailed', (error: any) => {
      localStorage.removeItem('twilio');
      this.connectTwilio();
      this.conversationSerivce.connectionInitialiazed = false;
    });

    this.twilioClient.on('connectionStateChanged', (state: ConnectionState) => {
      this.conversationSerivce.connectionStateFailed = state;
    });

    this.twilioClient.on('connectionError', (error: any) => {
      this.conversationSerivce.connectionError = error;
    });

    this.twilioClient.on('tokenAboutToExpire', () => {
      this.updateToken();
    });

    this.twilioClient.on('tokenExpired', () => {
      this.conversationSerivce.tokenExpired = true;
      this.twilioClient.removeAllListeners();
      this.connectTwilio();
    });

    this.twilioClient.on('conversationAdded', (conv: Conversation) => {
      console.log('Converstaion added', conv);
      this.conversationSerivce.conversationAdded = conv;
      this.fetchUserChats()
    });

    this.twilioClient.on('messageAdded', async (msg: any) => {
      console.log('Message added', msg);
      this.unreadMessages.push(msg?.author)
      this.conversationSerivce.unreadMessages = this.unreadMessages;
      this.conversationSerivce.messageAdded = msg;
      const defaultNumber = JSON.parse(localStorage.getItem('login'))?.defaultNumber;

      if (defaultNumber != msg?.author) {
        this.sendNotificationOnMessageReceive(msg)
      }
    });

    this.twilioClient.on('typingStarted', (user: Participant) => {
      console.log('typing..', user);
      let data = {
        ...user,
        isType: true
      }
      this.conversationSerivce.typingStarted = data;
      //   if (user.conversation.sid === this.currentConversation.sid) this.isTyping = true;
    });

    this.twilioClient.on('typingEnded', (user: Participant) => {
      let data = {
        ...user,
        isType: true
      }
      this.conversationSerivce.typingEnded = data;
    });
  }

  sendNotificationOnMessageReceive(msg: Message) {
    console.log('Is notif?')
    const sender = msg?.author;
    const msgType = msg?.type == 'text' ? 'Text Message' : 'MMS Message';
    const title = 'New Message received';
    const message = `A ${msgType} received from ${sender}`

    this.conversationSerivce.getContactByNumber(sender)
      .pipe().subscribe(res => {
        this.notificationService.sendNotifications({
          referenceId: msg?.conversation?.sid,
          title,
          message,
          type: 'SMS',
          externalUrl: `conversation/${msg?.conversation?.sid}`
        }).pipe().subscribe(res => {
          if (res) console.log(res)
        })

        this.notificationService.pushNotification({
          referenceId: msg?.conversation?.sid,
          title: 'New Message received',
          message: `A ${msgType} received from ${sender}`,
          type: 'SMS',
          externalUrl: `conversation/${msg?.conversation?.sid}`,
          data: {
            type: 'SMS',
            contactName: res,
            contactNumber: sender,
            title,
            message
          }
        })
      })
  }
}