import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'proposalStatus'
})
export class ProposalStatusPipe implements PipeTransform {

  transform(status: string): string {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'ACCEPTED':
        return 'Accepted';
      case 'REJECTED':
        return 'Rejected';
      case 'MODIFIED':
        return 'Accepted -> Modified';
      default:
        return 'Unknown';
    }
  }
}
