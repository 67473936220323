import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tinsel-pay-overview',
  templateUrl: './tinsel-pay-overview.component.html',
  styleUrls: ['./tinsel-pay-overview.component.css']
})
export class TinselPayOverviewComponent implements OnInit {

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;

  constructor(public location: Location) { }

  ngOnInit(): void {

  }
}
