<div class="row button-container">
    <div class="col-md-auto ms-auto">
        <input type="search" class="form-control" placeholder="Search" [value]="storedSearchValue" (keyup)="tableControls.search($event.target.value);" (search)="tableControls.search($event.target.value);">
    </div>
    <div class="col-auto" *ngIf="isMobile">
      <div class="sort"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>
    </div>
    <div class="col-auto"  *ngIf="isMobile">
      <div class="filter"><ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container></div>
    </div>
</div>
<div class="row mt-2">
    <div class="col table-responsive">
        <table *ngIf="!isMobile">
            <thead>
                <tr>
                    <!-- Item -->
                    <th>
                        <div class="d-flex" style="position: relative;">
                            <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #featureItemName="ngbPopover" (click)="showColumnFilterPopover(featureItemName, 'i.item_name');">
                                <span *ngIf="tableControls.hasActiveFilter('i.item_name')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('i.item_name')}}</span>
                                <fa-icon [icon]="faFilter"></fa-icon>
                            </div>
                            <div class="w-100 px-3" (click)="tableControls.sort('i.item_name');">Item</div>
                            <fa-icon *ngIf="tableControls.isSortedBy('i.item_name')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                        </div>
                    </th>
                    <!-- Feature Name -->
                    <th>
                        <div class="d-flex" style="position: relative;">
                            <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #featureName="ngbPopover" (click)="showColumnFilterPopover(featureName, 'f.feature_name');">
                                <span *ngIf="tableControls.hasActiveFilter('f.feature_name')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('f.feature_name')}}</span>
                                <fa-icon [icon]="faFilter"></fa-icon>
                            </div>
                            <div class="w-100 px-3" (click)="tableControls.sort('f.feature_name');">Feature</div>
                            <fa-icon *ngIf="tableControls.isSortedBy('f.feature_name')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                        </div>
                    </th>
                    <!-- Project -->
                    <th *ngIf="!projectId">
                        <div class="d-flex" style="position: relative;">
                            <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #projectName="ngbPopover" (click)="showColumnFilterPopover(projectName, 'p.project_name');">
                                <span *ngIf="tableControls.hasActiveFilter('p.project_name')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('p.project_name')}}</span>
                                <fa-icon [icon]="faFilter"></fa-icon>
                            </div>
                            <div class="w-100 px-3" (click)="tableControls.sort('p.project_name');">Project</div>
                            <fa-icon *ngIf="tableControls.isSortedBy('p.project_name')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                        </div>
                    </th>
                    <!-- Client -->
                    <th *ngIf="!projectId">
                        <div class="d-flex" style="position: relative;">
                            <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #clientName="ngbPopover" (click)="showColumnFilterPopover(clientName, 'c.client_name');">
                                <span *ngIf="tableControls.hasActiveFilter('c.client_name')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('c.client_name')}}</span>
                                <fa-icon [icon]="faFilter"></fa-icon>
                            </div>
                            <div class="w-100 px-3" (click)="tableControls.sort('c.client_name');">Client</div>
                            <fa-icon *ngIf="tableControls.isSortedBy('c.client_name')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                        </div>
                    </th>
                    <!-- Source -->
                    <th>
                        <div class="d-flex" style="position: relative;">
                            <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #itemSource="ngbPopover" (click)="showColumnFilterPopover(itemSource, 'i.item_source');">
                                <span *ngIf="tableControls.hasActiveFilter('i.item_source')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('i.item_source')}}</span>
                                <fa-icon [icon]="faFilter"></fa-icon>
                            </div>
                            <div class="w-100 px-3" (click)="tableControls.sort('i.item_source');">Source</div>
                            <fa-icon *ngIf="tableControls.isSortedBy('i.item_source')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                        </div>
                    </th>
                    <!-- Quantity -->
                    <th (click)="tableControls.sort('ip.inventory_prep_quantity');">
                        Quantity
                        <fa-icon *ngIf="tableControls.isSortedBy('ip.inventory_prep_quantity')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                    </th>
                    <!-- Status -->
                    <th>
                        <div class="d-flex" style="position: relative;">
                            <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #projectPrepStatus="ngbPopover" (click)="showColumnFilterPopover(projectPrepStatus, 'ip.inventory_prep_status');">
                                <span *ngIf="tableControls.hasActiveFilter('ip.inventory_prep_status')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('ip.inventory_prep_status')}}</span>
                                <fa-icon [icon]="faFilter"></fa-icon>
                            </div>
                            <div class="w-100 px-3" (click)="tableControls.sort('ip.inventory_prep_status');">Status</div>
                            <fa-icon *ngIf="tableControls.isSortedBy('ip.inventory_prep_status')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                        </div>
                    </th>
                    <!-- Quantity Ordered -->
                    <th (click)="tableControls.sort('ip.inventory_prep_ordered');">
                        Quantity Ordered
                        <fa-icon *ngIf="tableControls.isSortedBy('ip.inventory_prep_ordered')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                    </th>
                    <!-- Updated -->
                    <th (click)="tableControls.sort('ip.inventory_prep_updated_at');">
                        Updated
                        <fa-icon *ngIf="tableControls.isSortedBy('ip.inventory_prep_updated_at')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                    </th>
                    <!-- Actions -->
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let projectPrepItem of projectPrepItems">
                    <td data-label="Item">{{projectPrepItem.itemName}}</td>
                    <td data-label="Feature">{{projectPrepItem.featureName}}</td>
                    <td *ngIf="!projectId" data-label="Project">{{projectPrepItem.projectName}}</td>
                    <td *ngIf="!projectId" data-label="Client">{{projectPrepItem.clientName}}</td>
                    <td data-label="Source">{{projectPrepItem.itemSource}}</td>
                    <td data-label="Quantity">{{projectPrepItem.quantity}}</td>
                    <td data-label="Status">{{projectPrepItem.status | projectPrepStatus}}</td>
                    <td data-label="Quantity Ordered">{{projectPrepItem.quantityOrdered || 0}}</td>
                    <td data-label="Updated">{{projectPrepItem.updatedAt | formatDateTime}}</td>

                    <td class="actionBtnContainer" data-label="Actions">
                        <button type="button" class="btn btn-primary" (click)="resetFeatureItem(projectPrepItem);" ngbTooltip="Reset" *ngIf="projectPrepItem.status == 'TO-BE-ORDERED'">
                            <fa-icon [icon]="faRotateLeft"></fa-icon>
                        </button>
                        <button type="button" class="btn btn-primary ms-2" (click)="orderFeatureItem(projectPrepItem.itemName, projectPrepItem.itemId);" *ngIf="projectPrepItem.status == 'TO-BE-ORDERED'" ngbTooltip="Mark as Ordered">
                            <fa-icon [icon]="faShoppingCart"></fa-icon>
                        </button>
                        <button type="button" class="btn btn-primary ms-2" (click)="checkInFeatureItem(projectPrepItem);" *ngIf="projectPrepItem.status == 'ORDERED'" ngbTooltip="Check In">
                            <fa-icon [icon]="faCheck"></fa-icon>
                        </button>
                        <button type="button" class="btn btn-primary ms-2" (click)="moveBackToOrdered(projectPrepItem);" ngbTooltip="Cancel Checkin Order" *ngIf="projectPrepItem.status == 'CHECK-IN-ORDERED'" >
                            <fa-icon [icon]="faCartArrowDown"></fa-icon>
                        </button>
                        <button type="button" class="btn btn-primary ms-2" (click)="cancelItemOrdered(projectPrepItem);" ngbTooltip="Cancel Order" *ngIf="projectPrepItem.status == 'ORDERED'" >
                            <fa-icon [icon]="faTimesCircle"></fa-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="scrollable" *ngIf="isMobile">
            <ul class="list-group">
                <li *ngFor="let projectPrepItem of projectPrepItems" class="list-group-item">
                    <div class="d-flex list-group-container">
                        <div>
                            <strong>{{projectPrepItem.itemName}}</strong>
                            <div>{{projectPrepItem.featureName}}</div>
                            <div *ngIf="!projectId">{{projectPrepItem.projectName}}</div>
                            <div *ngIf="!projectId">{{projectPrepItem.clientName}}</div>
                            <div>{{projectPrepItem.itemSource}}</div>
                            <div><strong>Quantity: </strong>{{projectPrepItem.quantity}}</div>
                            <div><strong>Status: </strong>{{projectPrepItem.status | projectPrepStatus}}</div>
                            <div><strong>Quantity Ordered: </strong>{{projectPrepItem.quantityOrdered || 0}}</div>
                            <small class="text-muted">Updated: {{projectPrepItem.updatedAt | formatDateTime}}</small>
                            <div class="list-button">
                              <button type="button" class="btn btn-primary" (click)="resetFeatureItem(projectPrepItem);" ngbTooltip="Reset" *ngIf="projectPrepItem.status == 'TO-BE-ORDERED'">
                                <fa-icon [icon]="faRotateLeft"></fa-icon>&nbsp;Reset
                              </button>
                              <button type="button" class="btn btn-primary" (click)="orderFeatureItem(projectPrepItem.itemName, projectPrepItem.itemId);" *ngIf="projectPrepItem.status == 'TO-BE-ORDERED'" ngbTooltip="Mark as Ordered">
                                  <fa-icon [icon]="faShoppingCart"></fa-icon>&nbsp;Mark as Ordered
                              </button>
                              <button type="button" class="btn btn-primary" (click)="checkInFeatureItem(projectPrepItem);" *ngIf="projectPrepItem.status == 'ORDERED'" ngbTooltip="Check In">
                                  <fa-icon [icon]="faCheck"></fa-icon>&nbsp;Check In
                              </button>
                              <button type="button" class="btn btn-primary" (click)="moveBackToOrdered(projectPrepItem);" ngbTooltip="Cancel Checkin Order" *ngIf="projectPrepItem.status == 'CHECK-IN-ORDERED'" >
                                  <fa-icon [icon]="faCartArrowDown"></fa-icon>&nbsp;Cancel Checkin Order
                              </button>
                              <button type="button" class="btn btn-primary" (click)="cancelItemOrdered(projectPrepItem);" ngbTooltip="Cancel Order" *ngIf="projectPrepItem.status == 'ORDERED'" >
                                  <fa-icon [icon]="faTimesCircle"></fa-icon>&nbsp;Cancel Order
                              </button>
                            </div>
                          </div>
                    </div>
                </li>
                <li *ngIf="projectPrepItems.length == 0" class="list-group-item">No Orders</li>
            </ul>
        </div>
    </div>
</div>
<div class="row table-controls">
    <div *ngIf="tableControls.isNotDefault() && !isMobile" class="col-auto mt-3 me-2 me-md-0">
        <button type="button" class="btn btn-outline-danger" (click)="tableControls.resetTableConfiguration();">
            <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
            <span>Reset</span>
        </button>
    </div>
    <div class="col mt-3">
        <div ngbDropdown placement="top-left" class="d-inline-block">
            <button class="btn btn-outline-primary" ngbDropdownToggle><span *ngIf="!isMobile">Page Size: </span> {{tableControls.getLimit()}}</button>
            <div ngbDropdownMenu class="page-size-dropdown" *ngIf="!isMobile">
                <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
                <button ngbDropdownItem (click)="tableControls.setLimit(50);">50</button>
                <button ngbDropdownItem (click)="tableControls.setLimit(100);">100</button>
            </div>
            <div ngbDropdownMenu class="page-size-dropdown" *ngIf="isMobile">
                <button ngbDropdownItem (click)="tableControls.setLimit(5);">5</button>
                <button ngbDropdownItem (click)="tableControls.setLimit(10);">10</button>
                <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
            </div>
        </div>
    </div>
    <div class="col my-3">
        <ngb-pagination class="d-flex justify-content-end" *ngIf="count > 0" [page]="tableControls.getCurrentPage()" [pageSize]="tableControls.getLimit()" [collectionSize]="count" [boundaryLinks]="true" [maxSize]="(isMobile) ? 1 : 10" [ellipses]="true" [rotate]="true" (pageChange)="tableControls.setCurrentPage($event);"></ngb-pagination>
    </div>
</div>

<!-- Filter -->
<ng-template #popTitleFilter>Filter</ng-template>
<ng-template let-column="column" #popContentFilter>
    <div *ngFor="let element of this.columnFilterValues.get(column)" class="form-check">
        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter(column, element.value);" [checked]="tableControls.filterExists(column, element.value)">
        <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
    </div>
</ng-template>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block" [autoClose]="true" placement="bottom-left">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
  <div ngbDropdown class="d-inline-block filter-container" autoClose="outside" placement="bottom-left">
      <span *ngIf="activeFilterCount > 0" class="custom-badge-lg">{{activeFilterCount}}</span>
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Filter">
          <fa-icon [icon]="faFilter"></fa-icon>
      </button>
      <div ngbDropdownMenu>
          <!-- Filter Options -->
          <div *ngFor="let filter of filterColumns">
            <button ngbDropdownItem class="button-filter" (click)="getDistinctColumnValues(filter.value); filters.toggle();">{{filter.label}}</button>
            <div class="px-4 py-1" #filters="ngbCollapse" [ngbCollapse]="filter.value === selectedFilterColumn ? false : true">
                <div class="d-flex flex-column">
                    <div *ngFor="let element of columnFilterValues.get(filter.value);" class="form-check form-check-inline">
                        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter(filter.value, element.value);" [checked]="filterExists(filter.value, element.value)">
                        <label [for]="element.randomId" class="form-check-label" style="white-space: nowrap;">{{element.name}}</label>
                    </div>
                </div>
            </div>
          </div>

          <!-- Divider -->
          <div class="dropdown-divider"></div>
          <!-- Reset -->
          <button class="reset-button" ngbDropdownItem (click)="resetActiveFilters(); selectedFilterColumn = ''">Reset</button>
      </div>
  </div>
</ng-template>


