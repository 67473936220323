import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TinselPayService {

  constructor(private http: HttpClient) { }

  /* ----- Tinsel Pay Card ----- */

  // Get Tinsel Pay Card Transactions
  getTinselPayCardTransactions(params: any): Observable<any[]> {
    return this.http.get<any[]>('/tinsel-pay/card/transactions', {
      params: params
    });
  }

  // Get Tinsel Pay Card Vaults
  getTinselPayCardVaults(params: any): Observable<any[]> {
    return this.http.get<any[]>(`/tinsel-pay/card/vaults`, {
      params: params
    })
  }

  /* ----- Tinsel Pay ACH ----- */

  // Get Tinsel Pay ACH Not Closed Transactions
  getTinselPayAchNotClosedTransactions(params: any): Observable<any[]> {
    return this.http.get<any[]>('/tinsel-pay/ach/notClosedTransactions', {
      params: params
    });
  }

  // Get Tinsel Pay ACH Vaults
  getTinselPayAchVaults(params: any): Observable<any[]> {
    return this.http.get<any[]>(`/tinsel-pay/ach/vaults`, {
      params: params
    })
  }
}
