import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Mockup } from './mockup';
import { environment } from 'src/environments/environment';
import { MockupPresetAsset } from './mockup-preset-asset';
import { MockupCustomAsset } from './mockup-custom-asset';

@Injectable({
  providedIn: 'root'
})
export class MockupService {

  constructor(private http: HttpClient) { }

  // Add Mockup
  addMockup(mockup: any): Observable<string> {
    return this.http.post<string>(`/mockups`, mockup);
  }

  // Update Mockup
  updateMockup(mockup: any): Observable<any> {
    return this.http.put<any>(`/mockups/${mockup.id}`, mockup);
  }

  // Delete Mockup
  deleteMockup(mockupId: string): Observable<any> {
    return this.http.delete<any>(`/mockups/${mockupId}`);
  }

  // Get Mockups
  getMockups(projectId: string): Observable<Mockup[]> {
    return this.http.get<Mockup[]>(`/mockups`, {
      params: {
        projectId: projectId
      }
    });
  }

  // Get Mockup
  getMockup(mockupId: string): Observable<Mockup> {
    return this.http.get<Mockup>(`/mockups/${mockupId}`);
  }

  // Get Mockup File Direct URL
  getMockupFileDirectURL(mockupId: string): string {
    return environment.apiUrl + '/mockups/' + mockupId + '/file';
  }

  /* ----- Mockup Custom Assets ----- */

  // Add Mockup Custom Asset
  addMockupCustomAsset(data: any): Observable<string> {
    return this.http.post<string>(`/mockups/assets/custom`, data);
  }

  // Delete Mockup Custom Asset
  deleteMockupCustomAsset(assetId: string): Observable<any> {
    return this.http.delete<any>(`/mockups/assets/custom/${assetId}`);
  }

  // Get Mockup Custom Assets
  getMockupCustomAssets(): Observable<MockupCustomAsset[]> {
    return this.http.get<MockupCustomAsset[]>(`/mockups/assets/custom`);
  }

  // Get Mockup Custom Asset Direct URL
  getMockupCustomAssetDirectURL(assetId: string): string {
    return environment.apiUrl + '/mockups/assets/custom/' + assetId;
  }

  /* ----- Mockup Preset Assets ----- */

  // Get Mockup Preset Assets
  getMockupPresetAssets(): Observable<MockupPresetAsset[]> {
    return this.http.get<MockupPresetAsset[]>(`/mockups/assets/presets`);
  }

  // Get Mockup Preset Asset Direct URL
  getMockupPresetAssetDirectURL(assetId: string): string {
    return environment.apiUrl + '/mockups/assets/presets/' + assetId;
  }
}
