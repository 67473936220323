import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { TinselPayService } from '../tinsel-pay.service';
import { Subject, debounceTime } from 'rxjs';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-tinsel-pay-overview-card-transactions',
  templateUrl: './tinsel-pay-overview-card-transactions.component.html',
  styleUrls: ['./tinsel-pay-overview-card-transactions.component.css']
})
export class TinselPayOverviewCardTransactionsComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Properties
  @ViewChild('TRANSACTION_DETAILS_MODAL') private transactionDetailsModal: ElementRef;
  private modalReference: NgbModalRef;
  transactions: any[] = [];
  startDate: string;
  endDate: string;
  selectedTransaction: any;
  isMobileView: boolean = false;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private tinselPayService: TinselPayService,
    private modalService: NgbModal,
    private loaderService: LoaderService) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
        }
      });
  }

  ngOnInit(): void {
    this.checkScreenSize();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Get Tinsel Pay Card Transactions
  private getTinselPayCardTransactions(): void {
    const params: any = {
      resultLimit: null,
      pageNumber: 0,
      resultOrder: 'reverse',
      startDate: this.startDate,
      endDate: this.endDate
    };
    this.tinselPayService.getTinselPayCardTransactions(params).subscribe(
      {
        next: (transactions) => {
          this.transactions = transactions;
          this.loaderService.hideSpinner(700);
        },
        error: () => {
          this.loaderService.hideSpinner(700);
        }
      }

      );
  }

  // Date Changed
  dateChanged(event: { startDate: string, endDate: string, isAuto: boolean }): void {
    this.loaderService.showSpinner();
    this.startDate = event.startDate;
    this.endDate = event.endDate;
    this.getTinselPayCardTransactions();
  }

  // Select Transaction
  selectTransaction(transaction: any): void {
    this.selectedTransaction = transaction;
    this.modalReference = this.modalService.open(this.transactionDetailsModal, { scrollable: true });
  }

  /* ----- Helper Functions ----- */

  // Format Date Time
  formatDateTime(dateTime: number): string {
    return dayjs.utc(dateTime, 'YYYYMMDDhhmmss').local().format('M/D/YYYY, h:mm A');
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }
}
