<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="filterForm">
    <div class="form-group mb-3">
      <!-- <label for="datePicker">Date</label> -->
      <div class="input-group" *ngIf="!isSms">
        <div class="row">
          <div class="col mt-2">
            <label for="datePicker">Start Date</label>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="yyyy-mm-dd"
                formControlName="startDate"
                ngbDatepicker
                #d1="ngbDatepicker"
              />
              <button class="btn btn-outline-secondary" (click)="d1.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
          <div class="col mt-2">
            <label for="datePicker">End Date</label>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="yyyy-mm-dd"
                formControlName="endDate"
                ngbDatepicker
                #d2="ngbDatepicker"
              />
              <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="input-group" *ngIf="isSms">
        <div class="row w-100">
          <div class="col mt-2">
            <label for="datePicker">Date</label>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="yyyy-mm-dd"
                formControlName="dateSent"
                ngbDatepicker
                #dSent="ngbDatepicker"
              />
              <button class="btn btn-outline-secondary" (click)="dSent.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group mt-2">
      <label for="datePicker">From (Sender)</label>
      <div class="input-group">
        <input type="text" class="form-control" formControlName="fromContact" placeholder="Ex. +12225557890" maxlength="14" required>
      </div>
    </div>
    <div class="form-group mt-2">
      <label for="datePicker">To (Receiver)</label>
      <div class="input-group">
        <input type="text" class="form-control" formControlName="toContact" placeholder="Ex. +12225557890" maxlength="14" required>
      </div>
    </div>
    <div class="form-group mt-2" *ngIf="showStatus">
      <label for="status">Status</label>
      <div class="input-group">
        <div ngbDropdown class="d-inline-block d-flex w-100">
          <button class="btn btn-outline-primary dropdown-toggle form-control dropdown-button" id="statusDropdown" ngbDropdownToggle>
            {{ filterForm.get('status')?.value?.label || 'Select Status' }}
          </button>
          <div ngbDropdownMenu aria-labelledby="statusDropdown" class="form-control">
            <button class="dropdown-item" *ngFor="let stat of status" (click)="selectStatus(stat)">{{ stat.label }}</button>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="applyFilters()">{{ isSms ? 'Export to CSV' : 'Apply'}}</button>
</div>
