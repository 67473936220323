<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">Payroll Summary</h2>
        </div>
        <div class="row w-100 justify-content-end">
            <div class="col-auto pe-2">
                <app-date-picker-controller #datePickerController componentId="Reports.PayrollSummary" preset="CUR_WK" [showButtons]="true" (dateChanged)="dateChanged($event);" [onLoad]="true"></app-date-picker-controller>
            </div>
            <div class="col-auto" style="padding-top:.1rem">
                <button type="button" class="btn btn-primary" (click)="downloadReport();">
                    <fa-icon [icon]="faFileDownload"></fa-icon><span class="ms-2 d-none d-md-inline">Export</span>
                </button>
            </div>
        </div>
    </nav>
    <div class="row mt-3">
        <div class="card p-0">
            <div class="card-body">
                <h5 class="card-title">Description</h5>
                <p class="card-text m-0">This report can be used to determine total hours and gross pay for employees that utilize the Time Clock.</p>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-sm-12 col-md-4">
            <form [formGroup]="payrollForm">
                <div class="mb-3">
                    <label for="reqHours">Max Hours before Overtime</label>
                    <span class="ms-2" style="cursor: pointer;" ngbPopover="This is the number of hours before overtime will take into account" triggers="click">
                        <fa-icon [icon]="faQuestionCircle"></fa-icon>
                    </span>
                    <div class="input-group mb-3">
                        <input type="number" id="reqHours" class="form-control" formControlName="reqHours" placeholder="Max Hours before Overtime" required>
                        <div class="input-group-append">
                          <button (click)="getPayrollSummaryReport()" class="btn btn-outline-secondary" type="button">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-sm-12 col-md-8"></div>
    </div>

    <div class="row button-container" *ngIf="isMobile">
      <div class="sort"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>
    </div>

    <div class="row mt-1">
        <div class="col-sm-12" *ngIf="!isMobile" class="col table-responsive">
            <table>
                <thead>
                    <tr>
                        <!-- Employee -->
                        <th (click)="tableControls.sort('u.user_last_name');">
                            Employee
                            <fa-icon *ngIf="tableControls.isSortedBy('u.user_last_name')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                        </th>
                        <!-- Regular Hours -->
                        <th>Regular Hours</th>
                        <!-- Overtime Hours -->
                        <th>Overtime Hours</th>
                        <!-- Pay Rate -->
                        <th (click)="tableControls.sort('u.user_hourly_rate');">
                            Pay Rate
                            <fa-icon *ngIf="tableControls.isSortedBy('u.user_hourly_rate')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                        </th>
                        <!-- Gross Pay -->
                        <th>Gross Pay</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let entry of entries">
                        <td data-label="Employee">{{entry.lastName}}, {{entry.firstName}}</td>
                        <td data-label="Regular Hours">{{entry.regularHours}}</td>
                        <td data-label="Overtime Hours">{{entry.overtimeHours}}</td>
                        <td data-label="Pay Rate">${{entry.hourlyRate}}/hr</td>
                        <td data-label="Gross Pay">{{entry.grossPay | currency}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>Total</td>
                        <td>{{totalRegularHours}}</td>
                        <td>{{totalOvertimeHours}}</td>
                        <td></td>
                        <td>{{totalGrossPay | currency}}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div *ngIf="!isMobile" class="row table-controls">
        <div *ngIf="tableControls.isNotDefault()" class="col-md-auto my-3">
            <button type="button" class="btn btn-outline-danger" (click)="tableControls.resetTableConfiguration();">
                <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
                <span>Reset</span>
            </button>
        </div>
    </div>
    <div *ngIf="isMobile" class="mt-2">
        <div class="scrollable">
            <ul class="list-group">
                <li *ngFor="let entry of entries" class="list-group-item">
                    <div class="d-flex">
                        <div>
                            <strong>{{entry.lastName}}, {{entry.firstName}}</strong>
                            <div><strong>Regular Hours:</strong> {{entry.regularHours}}</div>
                            <div><strong>Overtime Hours:</strong> {{entry.overtimeHours}}</div>
                            <div><strong>Pay Rate:</strong> ${{entry.hourlyRate}}/hr</div>
                            <div><strong>Gross Pay:</strong> {{entry.grossPay | currency}}</div>
                        </div>
                    </div>
                </li>
                <li class="list-group-item list-group-item-secondary">
                    <div class="d-flex">
                        <div>
                            <strong>Total</strong>
                            <div><strong>Regular Hours:</strong> {{totalRegularHours}}</div>
                            <div><strong>Overtime Hours:</strong> {{totalOvertimeHours}}</div>
                            <div><strong>Gross Pay:</strong> {{totalGrossPay | currency}}</div>
                        </div>
                    </div>
                </li>
                <li *ngIf="entries.length == 0" class="list-group-item">No Payroll Entries</li>
            </ul>
        </div>
    </div>
</div>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block" [autoClose]="true" placement="bottom-left">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>
<!-- LOADER -->
<ngx-spinner>
  <p class="loading-spinner">Loading...</p>
</ngx-spinner>
