<div class="card">
    <div class="card-header">
        <h5 class="me-auto">{{title}} ({{vaults.length}})</h5>
    </div>
    <div class="card-body">
        <div class="scrollable">
            <ul class="list-group">
                <li *ngFor="let vault of vaults" class="list-group-item">
                    <!-- CARD -->
                    <div *ngIf="type == 'CARD'" class="d-flex">
                        <div class="my-auto">
                            <img [src]="getCardPaymentIcon(vault.details.type)" width="37.5" height="auto">
                        </div>
                        <div class="ms-3 my-auto">&#8226;&#8226;&#8226;&#8226; <span style="font-weight: 600;">{{vault.details.lastFour}}</span>, Exp <span style="font-weight: 600;">{{vault.details.expiration}}</span></div>
                        <button class="btn btn-danger ms-auto" (click)="openDeleteCardVaultConfirmationModal(vault);">
                            <fa-icon [icon]="faTrashCan"></fa-icon>
                        </button>
                    </div>
                    <!-- ACH -->
                    <div *ngIf="type == 'ACH'" class="d-flex">
                        <div class="my-auto">
                            <fa-icon [icon]="faLandmark" size="lg"></fa-icon>
                        </div>
                        <div class="ms-3 my-auto">Ending in <span style="font-weight: 600;">{{vault.details.lastFour}}</span></div>
                        <button class="btn btn-danger ms-auto" (click)="openDeleteAchVaultConfirmationModal(vault);">
                            <fa-icon [icon]="faTrashCan"></fa-icon>
                        </button>
                    </div>
                </li>
                <li *ngIf="vaults.length == 0" class="list-group-item">No Vaults</li>
            </ul>
        </div>
    </div>
</div>