<div class="modal-header">
    <h5 class="modal-title">Bulk Scheduling</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <div class="d-flex flex-column mb-2">
                <div class="form-check my-auto">
                    <input id="BULK_SCHEDULE_FINAL_INVOICE" type="checkbox" class="form-check-input" [checked]=true>
                    <label for="BULK_SCHEDULE_FINAL_INVOICE" class="form-check-label fw-bold">Final Invoice</label>
                    <span class="ms-2" style="cursor: pointer;" ngbPopover="Generate an invoice one day before the schedule date. This will only affect installations that have accepted proposals." triggers="click" placement="right">
                        <fa-icon [icon]="faQuestionCircle"></fa-icon>
                    </span>
                </div>
                <button type="button" class="btn btn-sm btn-primary mt-1" (click)="applyBulkScheduleFinalInvoice();">Apply Final Invoice to All</button>
            </div>
            <div class="d-flex flex-column mb-2">
                <label for="BULK_SCHEDULE_DATE" class="fw-bold my-auto">Date</label>
                <input id="BULK_SCHEDULE_DATE" type="date" class="form-control mt-1">
                <button type="button" class="btn btn-sm btn-primary mt-2" (click)="applyBulkScheduleDate();">Apply Date to All</button>
            </div>
            <div class="d-flex flex-column">
                <label for="BULK_SCHEDULE_USERS" class="fw-bold my-auto">Users</label>
                <div id="BULK_SCHEDULE_USERS" class="mt-1">
                    <ul class="list-group scrollable" style="max-height: 210px;">
                        <li *ngFor="let user of users" class="list-group-item" style="height: 42px;" (click)="selectBulkUser(user.id);">
                            <div class="d-flex">
                                <div>{{user.firstName}} {{user.lastName}}</div>
                                <fa-icon *ngIf="isBulkUserSelected(user.id);" [icon]="faCheck" class="ms-auto"></fa-icon>
                            </div>
                        </li>
                        <li *ngIf="users.length == 0" class="list-group-item">No Users</li>
                    </ul>
                </div>
                <button type="button" class="btn btn-sm btn-primary mt-2" (click)="applyBulkScheduleUsers();">Apply Users to All</button>
            </div>
        </div>
    </div>
    <div [class.mt-3]="scheduleItems.length > 0">
        <form [formGroup]="bulkScheduleForm">
            <ngb-accordion #accordion="ngbAccordion">
                <ngb-panel *ngFor="let scheduleItem of scheduleItems">
                    <ng-template ngbPanelHeader>
                        <button ngbPanelToggle class="accordion-button py-2 px-3">
                            <div class="d-flex">
                                <!-- <div class="d-flex flex-column">
                                    <button type="button" class="btn p-0" style="border: none;" (click)="$event.stopPropagation(); swapPositions(scheduleItem, 'UP')" [disabled]="scheduleItem.position === 1">
                                        <fa-icon [icon]="faSquareCaretUp" style="color: var(--bs-primary);"></fa-icon>
                                    </button>
                                    <button type="button" class="btn p-0" style="border: none;" (click)="$event.stopPropagation(); swapPositions(scheduleItem, 'DOWN')" [disabled]="scheduleItem.position === lastPosition">
                                        <fa-icon [icon]="faSquareCaretDown" style="color: var(--bs-primary);"></fa-icon>
                                    </button>
                                </div> -->
                                <div class="d-flex flex-column" style="margin-top: -0.5rem; margin-bottom: -0.5rem; margin-left: -1rem;">
                                    <div class="d-flex flex-column" style="height: 100%;">
                                        <button type="button" class="delete-btn" (click)="$event.stopPropagation(); removeScheduleItem(scheduleItem);">
                                            <fa-icon [icon]="faTrashCan"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                                <fa-icon [icon]="getScheduleItemTypeIcon(scheduleItem.type)" size="lg" class="my-auto ms-2" 
                                [style.color]="scheduleItem.crews && scheduleItem.crews.length > 0 ? scheduleItem.crews[0].crewColor ? scheduleItem.crews[0].crewColor: '#000000':'#000000'">
                                </fa-icon>
                                <div class="d-flex flex-column ms-3">
                                    <strong>{{scheduleItem.clientName}}</strong>
                                    <small class="text-muted">FI: {{(getNestedFormControl(scheduleItem.id, 'generateInvoice').value) ? 'Yes' : 'No'}}, Date: {{getNestedFormControl(scheduleItem.id, 'date').value | formatDateTime: getNestedFormControl(scheduleItem.id, 'isAllDay').value}}, Users: {{getUserCount(scheduleItem.id)}}</small>
                                </div>
                            </div>
                        </button>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <form [formGroupName]="scheduleItem.id">
                            <div *ngIf="scheduleItem.type == 'INSTALL'" class="mb-2">
                                <div class="form-check">
                                    <input id="SCHEDULE_ITEM_FINAL_INVOICE_{{scheduleItem.id}}" type="checkbox" class="form-check-input" formControlName="generateInvoice">
                                    <label for="SCHEDULE_ITEM_FINAL_INVOICE_{{scheduleItem.id}}" class="form-check-label">Final Invoice</label>
                                    <span *ngIf="scheduleItem.acceptedProposalExists" class="ms-2" style="cursor: pointer;" ngbPopover="Generate an invoice one day before the schedule date." triggers="click" placement="right">
                                        <fa-icon [icon]="faQuestionCircle"></fa-icon>
                                    </span>
                                    <span *ngIf="!scheduleItem.acceptedProposalExists" class="ms-2" style="cursor: pointer;" ngbPopover="Cannot schedule a final invoice because there is no accepted proposal for this project." triggers="click" placement="right">
                                        <fa-icon [icon]="faQuestionCircle" style="opacity: 0.5;"></fa-icon>
                                    </span>
                                </div>
                            </div>
                            <div class="mb-2" [class.mb-3]="!(scheduleItem && ((scheduleItem.type == 'INSTALL' && scheduleItem.installationWeek) || scheduleItem.type == 'REMOVE'))">
                                <label for="SCHEDULE_ITEM_DATE_{{scheduleItem.id}}">Date</label>
                                <div class="input-group">
                                    <input *ngIf="getNestedFormControl(scheduleItem.id, 'isAllDay').value" id="SCHEDULE_ITEM_DATE_{{scheduleItem.id}}" type="date" class="form-control" formControlName="date" required [class.is-invalid]="getNestedFormControl(scheduleItem.id, 'date').invalid && (getNestedFormControl(scheduleItem.id, 'date').dirty || getNestedFormControl(scheduleItem.id, 'date').touched)">
                                    <input *ngIf="!getNestedFormControl(scheduleItem.id, 'isAllDay').value" id="SCHEDULE_ITEM_DATE_{{scheduleItem.id}}" type="datetime-local" class="form-control" formControlName="date" required [class.is-invalid]="getNestedFormControl(scheduleItem.id, 'date').invalid && (getNestedFormControl(scheduleItem.id, 'date').dirty || getNestedFormControl(scheduleItem.id, 'date').touched)">
                                    <div class="input-group-text">
                                        <div class="form-check mb-0">
                                            <input id="SCHEDULE_ITEM_DATE_IS_ALL_DAY_{{scheduleItem.id}}" type="checkbox" class="form-check-input" formControlName="isAllDay" (change)="toggleAllDay(scheduleItem.id);">
                                            <label for="SCHEDULE_ITEM_DATE_IS_ALL_DAY_{{scheduleItem.id}}" class="form-check-label" style="font-weight: normal;">All Day</label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="getNestedFormControl(scheduleItem.id, 'date').invalid && (getNestedFormControl(scheduleItem.id, 'date').dirty || getNestedFormControl(scheduleItem.id, 'date').touched)">
                                    <div *ngIf="getNestedFormControl(scheduleItem.id, 'date').errors.required" class="input-validation-msg">Date is required.</div>
                                </div>
                                <small *ngIf="scheduleItem.type == 'INSTALL' && scheduleItem.installationWeek" class="text-muted">Preferred Installation Week: {{scheduleItem.installationWeek | formatDateTime: true}}</small>
                                <small *ngIf="scheduleItem.type == 'REMOVE'" class="text-muted">Preferred Removal Type: {{scheduleItem.removalType}}</small>
                            </div>
                            <div *ngIf="!getNestedFormControl(scheduleItem.id, 'isAllDay').value" class="mb-2" [class.mb-3]="!getNestedFormControl(scheduleItem.id, 'date').value">
                                <label for="SCHEDULE_ITEM_DURATION_{{scheduleItem.id}}">Duration</label>
                                <div class="input-group">
                                    <input id="SCHEDULE_ITEM_DURATION_{{scheduleItem.id}}" type="number" class="form-control" placeholder="1.50" formControlName="duration" step="0.25" [required]="!getNestedFormControl(scheduleItem.id, 'isAllDay').value">
                                    <span class="input-group-text">Hours</span>
                                </div>
                                <div *ngIf="getNestedFormControl(scheduleItem.id, 'duration').invalid && (getNestedFormControl(scheduleItem.id, 'duration').dirty || getNestedFormControl(scheduleItem.id, 'duration').touched)">
                                    <div *ngIf="getNestedFormControl(scheduleItem.id, 'duration').errors.required" class="input-validation-msg">Duration is required if not an all-day event.</div>
                                    <div *ngIf="getNestedFormControl(scheduleItem.id, 'duration').errors.min" class="input-validation-msg">Duration cannot be less than 0.01 hours.</div>
                                </div>
                                <small *ngIf="getNestedFormControl(scheduleItem.id, 'date').value" class="text-muted">End: {{getEndDateTime(scheduleItem.id)}}</small>
                            </div>
                            <div class="mb-3">
                                <label for="SCHEDULE_ITEM_USERS_{{scheduleItem.id}}">Users</label>
                                <div id="SCHEDULE_ITEM_USERS_{{scheduleItem.id}}">
                                    <ul class="list-group scrollable" style="max-height: 210px;">
                                        <li *ngFor="let user of users" class="list-group-item" (click)="selectUser(scheduleItem.id, user.id);">
                                            <div class="d-flex">
                                                <div>{{user.firstName}} {{user.lastName}}</div>
                                                <fa-icon *ngIf="isUserSelected(scheduleItem.id, user.id);" [icon]="faCheck" class="ms-auto"></fa-icon>
                                            </div>
                                        </li>
                                        <li *ngIf="users.length == 0" class="list-group-item">No Users</li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <label for="SCHEDULE_ITEM_NOTES_{{scheduleItem.id}}">Notes</label>
                                <textarea id="SCHEDULE_ITEM_NOTES_{{scheduleItem.id}}" class="form-control" formControlName="notes" placeholder="Client would prefer..." rows="2" maxlength="65535"></textarea>
                                <div *ngIf="getNestedFormControl(scheduleItem.id, 'notes').invalid && (getNestedFormControl(scheduleItem.id, 'notes').dirty || getNestedFormControl(scheduleItem.id, 'notes').touched)">
                                    <div *ngIf="getNestedFormControl(scheduleItem.id, 'notes').errors.maxlength" class="input-validation-msg">Notes cannot exceed 65535 characters.</div>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </form>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="bulkUpdateScheduleItems();" [disabled]="scheduleItems.length === 0">Save</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>