import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AuthService } from '../auth.service';
import { AlertService } from '../alert.service';
import { TwilioService } from '../twilio.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  // Properties
  employeePortalUrl!: string;
  loginForm: UntypedFormGroup;

  constructor(private authService: AuthService,
    private alertService: AlertService,
    private twilioService: TwilioService,
    private router: Router) { }

  ngOnInit(): void {
    this.employeePortalUrl = 'https://employee.' + document.location.host;
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(),
      password: new UntypedFormControl()
    });
  }

  // Login
  async login(): Promise<void> {
    let login = await lastValueFrom(this.authService.login(this.loginForm.value.email, this.loginForm.value.password));
    console.log(login)
    let currentUser = await this.authService.getCurrentUser();
    localStorage.setItem('login', JSON.stringify(login));

    const lastRoute = sessionStorage.getItem('LastRoute');
    if (lastRoute !== null) {
      this.router.navigateByUrl(lastRoute, { replaceUrl: true });
      sessionStorage.removeItem('LastRoute');
    } else if ((login.subscription === 29 || !login.isWithCardOnFile) && login.role === "SUPER") {
      this.router.navigateByUrl('/change-subscription', { replaceUrl: true });
    } else if ((login.subscription === 29 || !login.isWithCardOnFile) && login.role != "SUPER") {
      this.alertService.showWarningAlert("Your account is on-hold, please contact Tinsel CRM Support.");
    } else if (login.isExpired) {
      this.alertService.showWarningAlert("Subscription is Canceled. Contact support@tinselcrm.com")
    } else if (!login.isEnabled) {
      this.alertService.showWarningAlert('User Disabled');
    } else {
      // this.twilioService.connectTwilio();
      this.router.navigateByUrl('/dashboard', { replaceUrl: true });
    }
  }

  // Login Form Accessors
  get email() { return this.loginForm.controls.email; }
  get password() { return this.loginForm.controls.password; }
}
