import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmsCallService {

  constructor(private http: HttpClient) { }

  listOfNumbers(areaCode: number, contains: string, skip: number): Observable<any> {
    return this.http.post<any>(`/subacc/listofnumbers`, {
      areaCode, contains, skip
    });
  }
  
  buyNumber(phoneNumber: number): Observable<any> {
    return this.http.post<any>(`/subacc/buynumber/${phoneNumber}`, null);
  }

  sendMessage(data: any): Observable<any> {
    return this.http.post<any>(`/messaging/newmessage`, data);
  }
}
