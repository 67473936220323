<div class="card mt-3">
    <div class="card-header">
        <h5>Bases</h5>
        <div class="ms-auto">
            <button class="btn btn-primary" (click)="updateProjectStage();">
                <fa-icon class="button-icon-left" [icon]="faArrowRight"></fa-icon>
                <span>Next Stage</span>
            </button>
        </div>
    </div>
    <div class="card-body">
        <div class="input-group">
            <input id="NEW_PROJECT_BASE" type="text" class="form-control" placeholder="Did you follow up with the client?">
            <button type="button" class="btn btn-outline-primary" (click)="addProjectBase();">
                <fa-icon [icon]="faCheck"></fa-icon>
            </button>
        </div>
        <div *ngFor="let base of bases; index as i" class="input-group mt-2">
            <div class="input-group-text">
                <input type="checkbox" class="form-check-input mt-0" (change)="toggleBaseCompletion(base, $event.target.checked);" [checked]="base.isComplete">
            </div>
            <input type="text" class="form-control" [value]="base.content" (input)="projectBaseEdited(base, $event);">
            <button type="button" class="btn btn-outline-danger" style="width: 40px;" (click)="deleteProjectBase(base);">
                <fa-icon [icon]="faTimes"></fa-icon>
            </button>
        </div>
    </div>
</div>