import { Component, EventEmitter, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {  map, Observable } from 'rxjs';
import { AlertService } from '../alert.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-new-user-modal',
  templateUrl: './new-user-modal.component.html',
  styleUrls: ['./new-user-modal.component.css']
})
export class NewUserModalComponent implements OnInit {

  // Properties
  userForm: UntypedFormGroup;
  userAdded: EventEmitter<void> = new EventEmitter<void>();

  saveInProgress: boolean = false;

  // Font Awesome Properties
  faRotate = faRotate;

  constructor(private userService: UserService,
    private alertService: AlertService,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.userForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      email: new UntypedFormControl(),
      passwordGeneration: new UntypedFormControl('AUTO'),
      password: new UntypedFormControl(),
      confirmPassword: new UntypedFormControl()
    });
    this.userForm.controls.email.addAsyncValidators(this.emailExistsValidator());
    this.userForm.controls.confirmPassword.addValidators(this.passwordMatchValidator())
  }

  // Email Exists Validator
  private emailExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this.userService.checkUserEmailExists(control.value).pipe(map((result: boolean) => {
        return result ? { alreadyExists: true } : null;
      }));
    };
  }

  // Password Match Validator
  private passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      return (control.value != this.userForm.value.password) ? { doesNotMatch: true } : null;
    }
  }

  // Add User
  addUser(): void {
    if (this.userForm.valid) {
      const user = {
        firstName: this.userForm.value.firstName,
        lastName: this.userForm.value.lastName,
        email: this.userForm.value.email,
        passwordGeneration: this.userForm.value.passwordGeneration,
        password: this.userForm.value.password
      };
      this.saveInProgress = true;
      this.userService.addUser(user).subscribe(() => {
        this.alertService.showSuccessAlert('User Added');
        this.userAdded.emit();
        this.modal.close();
      });
    } else {
      this.saveInProgress = false;
      this.userForm.markAllAsTouched();
    }
  }

  // User Form Accessors
  get firstName() { return this.userForm.controls.firstName; }
  get lastName() { return this.userForm.controls.lastName; }
  get email() { return this.userForm.controls.email; }
  get passwordGeneration() { return this.userForm.controls.passwordGeneration; }
  get password() { return this.userForm.controls.password; }
  get confirmPassword() { return this.userForm.controls.confirmPassword; }
}
