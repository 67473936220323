import { Component, HostListener, Input, OnInit } from '@angular/core';
import { faArrowRight, faCheck, faCheckCircle,faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject, debounceTime, distinctUntilChanged, lastValueFrom, merge, switchMap } from 'rxjs';
import { Project } from '../project';
import { ProjectService } from '../project.service';
import { ProposalService } from '../proposal.service';
import { AcceptedProposal } from '../accepted-proposal';
import { FeatureItemService } from '../feature-item.service';
import { AlertService } from '../alert.service';
import { Feature } from 'maplibre-gl';
import { FeatureService } from '../feature.service';
import { LoaderService } from '../shared/services/loader.service';
import { FormatDateTimePipe } from '../format-date-time.pipe';

@Component({
  selector: 'app-project-removal',
  templateUrl: './project-removal.component.html',
  styleUrls: ['./project-removal.component.css'],
  providers: [FormatDateTimePipe]
})
export class ProjectRemovalComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Properties
  private _project: Project;
  @Input()
  set project(value: Project) {
    this._project = value;
    this.storageLocation = value.storageLocation;
  }
  get project(): Project {
    return this._project;
  }
  tooltipDefaultMessage: string = 'Mark this feature complete once it is fully installed. Once completed, come back here to see the user that marked it complete and the time it was marked complete. ';


  isMobileView: boolean = false;

  // Item Review
  acceptedProposal: AcceptedProposal = null;
  installedQuantitySubject: Subject<{ featureItemId: string, quantity: number }> = new Subject<{ featureItemId: string, quantity: number }>();
  features: Feature[] = [];
  featureItems = new Map();

  // Storage Location
  storageLocation: string;
  projectStorageLocationTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  projectStorageLocationTypeaheadInputFocus$ = new Subject<string>();

  // Font Awesome Properties
  faArrowRight = faArrowRight;
  faCheck = faCheck;
  faCheckCircle = faCheckCircle;
  faInfoCircle = faInfoCircle;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private projectService: ProjectService,
    private proposalService: ProposalService,
    private featureItemService: FeatureItemService,
    private alertService: AlertService,
    private featureService: FeatureService,
    private loaderService: LoaderService,
    private formatDateTimePipe: FormatDateTimePipe) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
        }
      });
    }

  ngOnInit(): void {
    this.getAcceptedProposalForProject();
    this.prepareProjectStorageLocationTypeahead();
    this.checkScreenSize();
    this.prepareInstalledQuantity();
  }

  /* ----- Item Review ----- */

  // Get Accepted Proposal For Project
  private getAcceptedProposalForProject(): void {
    this.proposalService.getAcceptedProposalForProject(this.project.id).subscribe((acceptedProposal) => {
      this.acceptedProposal = acceptedProposal;
      this.getFeaturesAndFeatureItems();
    });
  }

  // Get Feature Items
  private async getFeaturesAndFeatureItems(): Promise<void> {
    this.featureItems.clear();
    this.features = ((await lastValueFrom(this.featureService.getFeatures({ searchTerm: null, sortBy: 'f.feature_name', sortDirection: 'ASC', limit: null, offset: null, 'filter:f.proposal_id': JSON.stringify([(this.acceptedProposal as AcceptedProposal).proposalId]), 'filter:f.feature_included': JSON.stringify(['1']) }))) as any).features;
    const featureItems = ((await lastValueFrom(this.featureItemService.getFeatureItems({ searchTerm: null, sortBy: 'fi.feature_item_name', sortDirection: 'ASC', limit: null, offset: null, 'filter:f.proposal_id': JSON.stringify([(this.acceptedProposal as AcceptedProposal).proposalId]) }))) as any).featureItems;
    for (const featureItem of featureItems) {
      if (this.featureItems.has(featureItem.featureId)) {
        const tempArray = this.featureItems.get(featureItem.featureId);
        tempArray.push(featureItem);
        this.featureItems.set(featureItem.featureId, tempArray);
      } else {
        this.featureItems.set(featureItem.featureId, [featureItem]);
      }
    }
    this.loaderService.hideSpinner();
  }

  // Prepare Installed Quantity
  private prepareInstalledQuantity(): void {
    this.installedQuantitySubject.pipe(debounceTime(500), distinctUntilChanged()).subscribe((res) => {
      this.featureItemService.updateFeatureItemInstalledQuantity(res.featureItemId, res.quantity).subscribe({
        next:() => {
        this.alertService.showSuccessAlert('Actual Quantity Updated');
        this.getFeaturesAndFeatureItems();
      }, error: () => {
        this.loaderService.hideSpinner();
      }
      });
    });
  }

  // Update Feature Item Installed Quantity
  updateFeatureItemInstalledQuantity(event: Event, featureItemId: string): void {
    this.loaderService.showSpinner();
    const quantity = parseInt((event.target as HTMLInputElement).value);
    this.installedQuantitySubject.next({ featureItemId: featureItemId, quantity: quantity });
  }

  /* ----- Storage Location ----- */

  // Get Project Storage Locations
  private getProjectStorageLocations(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'project_category',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'project_storage_location',
      hideBlanks: true
    };
    return this.projectService.getDistinctColumnValues(params);
  }

  // Prepare Project Storage Location Typeahead
  private prepareProjectStorageLocationTypeahead(): void {
    this.projectStorageLocationTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.projectStorageLocationTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getProjectStorageLocations((term.length == 0) ? null : term);
      }));
    }
  }

  // Update Project Storage Location
  updateProjectStorageLocation(): void {
    this.loaderService.showSpinner();
    const storageLocation = (document.getElementById('STORAGE_LOCATION') as HTMLInputElement).value;
    this.projectService.updateProjectStorageLocation(this.project.id, storageLocation).subscribe(() => {
      this.projectService.projectUpdated.emit();
      this.loaderService.hideSpinner();
    });
  }

  /* ----- Project ----- */

  // Update Project Stage
  updateProjectStage(): void {
    this.projectService.updateProjectStage(this.project.id, 'REVIEWS').subscribe(() => {
      this.projectService.projectUpdated.emit();
    });
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }
  // Update Feature Removal Status
  updateFetureRemovalStatus(feature: any): void {
    this.loaderService.showSpinner();
    this.featureService.updateFetureRemovalStatus({status: !feature.isRemoved, featureId: feature.id}).subscribe(
    {
      next:() => {
        this.getFeaturesAndFeatureItems();
      },
      error: () => {
        this.loaderService.hideSpinner();
      }
    });
  }

  public getRemovalTooltip(feature: any, isCheck?: boolean) {
    let date = feature?.removedAt ? this.formatDateTimePipe.transform(feature?.removedAt, false) : '';
    let tooltip = `${feature?.removedBy || ''} ${date || ''}`;
    if(!isCheck) {
      tooltip = this.tooltipDefaultMessage;
    }
    return tooltip;
  }
}
