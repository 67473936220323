import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

  /* ------ Clients ----- */

  // Get Last Proposal Accepted Date Report
  getLastProposalAcceptedDateReport(params: any): Observable<{ entries: any[], count: number }> {
    return this.http.get<{ entries: any[], count: number }>(`/reports/clients/lastProposalAcceptedDate`, {
      params: params
    });
  }

  /* ----- Finance ----- */

  // Get Accounts Receivable Report
  getAccountsReceivableReport(params: any): Observable<any> {
    return this.http.get<any[]>(`/reports/finances/accountsReceivable`, {
      params: params
    });
  }

  // Get Accounts Receivable Report Distinct Column Values
  getAccountsReceivableReportDistinctColumnValues(params: any): Observable<string[]> {
    return this.http.get<string[]>(`/reports/finances/accountsReceivable/distinctColumnValues`, {
      params: params
    });
  }

  // Get At A Glance Report
  getAtAGlanceReport(year: string): Observable<any> {
    return this.http.get<any>(`/reports/finances/atAGlance`, {
      params: {
        year: year
      }
    });
  }

  // Get Invoices Report
  getInvoicesReport(params: any): Observable<any> {
    return this.http.get<any>(`/reports/finances/invoices`, {
      params: params
    });
  }

  // Get Partially Invoiced Projects Report
  getPartiallyInvoicedProjectsReport(params: any): Observable<any> {
    return this.http.get<any>(`/reports/finances/partiallyInvoicedProjects`, {
      params: params
    });
  }

  // Get Payroll Summary Report
  getPayrollSummaryReport(params: any): Observable<PayrollSummaryReportResponse> {
    return this.http.get<PayrollSummaryReportResponse>(`/reports/finances/payrollSummary`, {
      params: params
    });
  }

  getTimeClockReport(params: any): Observable<any> {
    return this.http.get<any>(`/reports/timeentries/getemployeestimeentries`, {
      params: params
    });
  }

  // Get Invoiced Sales Tax Report
  getInvoicedSalesTaxReport(params: any): Observable<any> {
    return this.http.get<any>(`/reports/finances/invoicedSalesTax`, {
      params: params
    });
  }
  getInvoicedSalesTaxReportQuarter(params: any): Observable<any> {
    return this.http.get<any>(`/reports/finances/invoicedSalesTaxQuarter`, {
      params: params
    });
  }
  getInvoicedSalesTaxReportMonth(params: any): Observable<any> {
    return this.http.get<any>(`/reports/finances/invoicedSalesTaxMonth`, {
      params: params
    });
  }

  // Get Paid Sales Tax Report
  getPaidSalesTaxReport(params: any): Observable<any> {
    return this.http.get<any>(`/reports/finances/paidSalesTax`, {
      params: params
    });
  }

  /* ----- Projects ----- */

  // Get Storage Locations Report
  getStorageLocationsReport(params: any): Observable<any> {
    return this.http.get<any>(`/reports/projects/storageLocations`, {
      params: params
    });
  }

  /* ----- Schedule ----- */

  // Get Preferred Installation And Removal Dates Report
  getPreferredInstallationAndRemovalDatesReport(params: any): Observable<any> {
    return this.http.get<any>(`/reports/schedule/preferredInstallationAndRemovalDates`, {
      params: params
    });
  }

  // Get PIARDR Distinct Column Values
  getPiardrDistinctColumnValues(params: any): Observable<string[]> {
    return this.http.get<string[]>(`/reports/schedule/preferredInstallationAndRemovalDates/distinctColumnValues`, {
      params: params
    });
  }
}

// Payroll Summary Report Response
interface PayrollSummaryReportResponse {
  entries: any[];
  totalRegularHours: number;
  totalOvertimeHours: number;
  totalGrossPay: number;
}