<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">Preferred Installation And Removal Dates</h2>
        </div>
        <div class="row d-flex gap-2">
            <div class="col-auto d-flex">
                <div *ngIf="isReportLoading" class="my-auto">
                    <fa-icon [icon]="faRotate" size="lg" spin="true"></fa-icon>
                </div>
                <select *ngIf="years.length > 0" class="form-select ms-2" style="width: fit-content;" (change)="yearSelected($event.target.value);">
                    <option *ngFor="let year of years" [value]="year" [selected]="year == selectedYear">{{year}}</option>
                </select>
            </div>
            <div class="col-auto">
                <button type="button" class="btn btn-primary" (click)="downloadReport();">
                    <fa-icon [icon]="faFileDownload"></fa-icon><span class="ms-2 d-md-inline">Export</span>
                </button>
            </div>
        </div>
    </nav>
    <div class="row mt-3">
        <div class="card p-0">
            <div class="card-body">
                <h5 class="card-title">Description</h5>
                <p class="card-text mb-1">This report can be used to review the preferred installation and removal dates all in one place.</p>
                <p class="mb-0" style="font-weight: 600;">Removal Types</p>
                <ul class="mb-0">
                    <li>NONE: No Preference</li>
                    <li>EARLY: January 1st - 7th</li>
                    <li>REGULAR: January 7th - 15th</li>
                    <li>LATE: January 15th - 30th</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="row button-container mt-2" *ngIf="isMobile">
      <div class="sort"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>
      <div class="filter"><ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container></div>
    </div>

    <div class="table-responsive mt-3">
        <table *ngIf="!isMobile">
            <thead>
                <tr>
                    <!-- Client -->
                    <th (click)="tableControls.sort('c.client_name');">
                        Client
                        <fa-icon *ngIf="tableControls.isSortedBy('c.client_name')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                    </th>
                    <!-- Project -->
                    <th (click)="tableControls.sort('proj.project_name');">
                        Project
                        <fa-icon *ngIf="tableControls.isSortedBy('proj.project_name')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                    </th>
                    <!-- Site -->
                    <th>
                        <div class="d-flex" style="position: relative;">
                            <div class="header-filter-container" [ngbPopover]="multiFilterPopContent" [popoverTitle]="multiFilterPopTitle" triggers="manual" autoClose="outside" placement="bottom" #site="ngbPopover" (click)="site.open();">
                                <span *ngIf="tableControls.getFilterCount(['s.site_address_city', 's.site_address_county', 's.site_address_state', 's.site_address_postal_code']) > 0" class="custom-badge">{{tableControls.getFilterCount(['s.site_address_city', 's.site_address_county', 's.site_address_state', 's.site_address_postal_code'])}}</span>
                                <fa-icon [icon]="faFilter"></fa-icon>
                            </div>
                            <div class="w-100 px-3" (click)="tableControls.sort('s.site_address_street');">
                                Site
                            </div>
                            <fa-icon *ngIf="tableControls.isSortedBy('s.site_address_street')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                        </div>
                    </th>
                    <!-- Installation Week -->
                    <th (click)="tableControls.sort('accepted_proposal_installation_week');">
                        Installation Week
                        <fa-icon *ngIf="tableControls.isSortedBy('accepted_proposal_installation_week')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                    </th>
                    <!-- Removal Type -->
                    <th>
                        <div class="d-flex" style="position: relative;">
                            <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #removalType="ngbPopover" (click)="showColumnFilterPopover(removalType, 'accepted_proposal_removal_type');">
                                <span *ngIf="tableControls.hasActiveFilter('accepted_proposal_removal_type')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('accepted_proposal_removal_type')}}</span>
                                <fa-icon [icon]="faFilter"></fa-icon>
                            </div>
                            <div class="w-100 px-3" (click)="tableControls.sort('accepted_proposal_removal_type');">
                                Removal Type
                            </div>
                            <fa-icon *ngIf="tableControls.isSortedBy('accepted_proposal_removal_type')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let entry of entries">
                    <td data-label="Client">{{entry.clientName}}</td>
                    <td data-label="Project">{{entry.projectName}}</td>
                    <td data-label="Site">{{entry.site.address.street}} ({{entry.site.name}})</td>
                    <td data-label="Installation Week">
                        <span *ngIf="entry.installationWeek">{{entry.installationWeek | formatDateTime: true}}</span>
                        <span *ngIf="!entry.installationWeek">No Preference</span>
                    </td>
                    <td data-label="Removal Type">{{entry.removalType}}</td>
                </tr>
            </tbody>
        </table>

        <div class="scrollable" *ngIf="isMobile">
          <ul class="list-group">
              <li *ngFor="let entry of entries" class="list-group-item">
                  <div class="d-flex list-group-container">
                      <div>
                          <strong>{{entry.clientName}}</strong>
                          <div>{{entry.projectName}}</div>
                          <div><strong>Site: </strong>{{entry.site.address.street}} ({{entry.site.name}})</div>
                          <div><strong>Installation Week: </strong>&nbsp;<span *ngIf="entry.installationWeek">{{entry.installationWeek | formatDateTime: true}}</span>
                            <span *ngIf="!entry.installationWeek">No Preference</span></div>
                          <div><strong>Removal Type: </strong>{{entry.site.address.street}} ({{entry.site.name}})</div>
                      </div>
                  </div>
              </li>
              <li *ngIf="entries.length == 0" class="list-group-item">No Entries</li>
          </ul>
        </div>
    </div>
    <div class="row table-controls">
        <div *ngIf="tableControls.isNotDefault() && !isMobile" class="col-auto mt-3 me-2 me-md-0">
            <button type="button" class="btn btn-outline-danger" (click)="tableControls.resetTableConfiguration();">
                <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
                <span>Reset</span>
            </button>
        </div>
        <div class="col mt-3">
            <div ngbDropdown placement="top-left" class="d-inline-block">
                <button class="btn btn-outline-primary" ngbDropdownToggle><span *ngIf="!isMobile">Page Size: </span> {{tableControls.getLimit()}}</button>
                <div ngbDropdownMenu class="page-size-dropdown" *ngIf="!isMobile">
                  <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(50);">50</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(100);">100</button>
                </div>
                <div ngbDropdownMenu class="page-size-dropdown" *ngIf="isMobile">
                  <button ngbDropdownItem (click)="tableControls.setLimit(5);">5</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(10);">10</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
                </div>
            </div>
        </div>
        <div class="col my-3">
            <ngb-pagination class="d-flex justify-content-end" *ngIf="entryCount > 0" [page]="tableControls.getCurrentPage()" [pageSize]="tableControls.getLimit()" [collectionSize]="entryCount" [boundaryLinks]="true" [maxSize]="(isMobile) ? 3 : 10" [ellipses]="true" [rotate]="true" (pageChange)="tableControls.setCurrentPage($event);"></ngb-pagination>
        </div>
    </div>
</div>

<!-- Filter -->
<ng-template #popTitleFilter>Filter</ng-template>
<ng-template let-column="column" #popContentFilter>
    <div *ngFor="let element of this.columnFilterValues.get(column)" class="form-check">
        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter(column, element.value);" [checked]="tableControls.filterExists(column, element.value)">
        <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
    </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #multiFilterPopTitle>Filters</ng-template>
<ng-template #multiFilterPopContent>
    <!-- City -->
    <div class="multi-filter-item" (click)="getDistinctColumnValues('s.site_address_city'); cityCollapse.toggle();">City</div>
    <div #cityCollapse="ngbCollapse" [ngbCollapse]="true" style="overflow-y: hidden; max-height: none;">
        <div *ngFor="let element of columnFilterValues.get('s.site_address_city');" class="form-check">
            <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter('s.site_address_city', element.value);" [checked]="tableControls.filterExists('s.site_address_city', element.value)">
            <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
        </div>
    </div>
    <!-- County -->
    <div class="multi-filter-item" (click)="getDistinctColumnValues('s.site_address_county'); countyCollapse.toggle();">County</div>
    <div #countyCollapse="ngbCollapse" [ngbCollapse]="true" style="overflow-y: hidden; max-height: none;">
        <div *ngFor="let element of columnFilterValues.get('s.site_address_county');" class="form-check">
            <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter('s.site_address_county', element.value);" [checked]="tableControls.filterExists('s.site_address_county', element.value)">
            <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
        </div>
    </div>
    <!-- State -->
    <div class="multi-filter-item" (click)="getDistinctColumnValues('s.site_address_state'); stateCollapse.toggle();">State</div>
    <div #stateCollapse="ngbCollapse" [ngbCollapse]="true" style="overflow-y: hidden; max-height: none;">
        <div *ngFor="let element of columnFilterValues.get('s.site_address_state');" class="form-check">
            <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter('s.site_address_state', element.value);" [checked]="tableControls.filterExists('s.site_address_state', element.value)">
            <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
        </div>
    </div>
    <!-- Postal Code -->
    <div class="multi-filter-item" (click)="getDistinctColumnValues('s.site_address_postal_code'); postalCodeCollapse.toggle();">Postal Code</div>
    <div #postalCodeCollapse="ngbCollapse" [ngbCollapse]="true" style="overflow-y: hidden; max-height: none;">
        <div *ngFor="let element of columnFilterValues.get('s.site_address_postal_code');" class="form-check">
            <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter('s.site_address_postal_code', element.value);" [checked]="tableControls.filterExists('s.site_address_postal_code', element.value)">
            <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
        </div>
    </div>
    <!-- Reset -->
    <div class="multi-filter-item" (click)="tableControls.resetFilters(['s.site_address_city', 's.site_address_county', 's.site_address_state', 's.site_address_postal_code']);">Reset</div>
</ng-template>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block sorting" [autoClose]="true" placement="bottom-left">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
  <div ngbDropdown class="d-inline-block filter-container" autoClose="outside" placement="bottom-left">
      <span *ngIf="activeFilterCount > 0" class="custom-badge-lg">{{activeFilterCount}}</span>
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Filter">
          <fa-icon [icon]="faFilter"></fa-icon>
      </button>
      <div ngbDropdownMenu>
          <!-- Filter Options -->
          <div *ngFor="let filter of filterColumns">
            <button ngbDropdownItem class="button-filter" (click)="getDistinctColumnValues(filter.value); filters.toggle();">{{filter.label}}</button>
            <div class="px-4 py-1" #filters="ngbCollapse" [ngbCollapse]="filter.value === selectedFilterColumn ? false : true">
                <div class="d-flex flex-column">
                    <div *ngFor="let element of columnFilterValues.get(filter.value);" class="form-check form-check-inline">
                        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter(filter.value, element.value);" [checked]="filterExists(filter.value, element.value)">
                        <label [for]="element.randomId" class="form-check-label" style="white-space: nowrap;">{{element.name}}</label>
                    </div>
                </div>
            </div>
          </div>

          <!-- Divider -->
          <div class="dropdown-divider"></div>
          <!-- Reset -->
          <button class="reset-button" ngbDropdownItem (click)="resetActiveFilters(); selectedFilterColumn = ''">Reset</button>
      </div>
  </div>
</ng-template>

