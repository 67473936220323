import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from '../alert.service';
import { TimeEntry } from '../time-entry';
import { TimeEntryService } from '../time-entry.service';
import dayjs from 'dayjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { ClientService } from '../client.service';
import { Client } from '../client';
import { Project } from '../project';
import { ProjectService } from '../project.service';
import { debounceTime, distinctUntilChanged, lastValueFrom, map, merge, Observable, Subject, switchMap } from 'rxjs';
import { LocationService } from '../location.service';
import { GeoPosition } from '../geo-position';

@Component({
  selector: 'app-user-time-clock',
  templateUrl: './user-time-clock.component.html',
  styleUrls: ['./user-time-clock.component.css']
})
export class UserTimeClockComponent implements OnInit {

  // Modals
  private modalReference!: NgbModalRef;
  @ViewChild('ALREADY_CLOCKED_IN_MODAL', { static: false }) private alreadyClockedInModal!: NgbModalRef;

  // Properties
  currentTimeString: string = dayjs().format('HH:mm:ss');
  timeEntryForm!: UntypedFormGroup;
  timeEntries: TimeEntry[] = [];
  lastClockIn!: TimeEntry;

  // Clients
  clientTypeaheadInput!: (text$: Observable<string>) => Observable<Client[]>;
  clientTypeaheadInputFocus$ = new Subject<string>();
  clientResultFormatter = (client: Client) => client.name;
  selectedClient!: Client;

  // Projects
  projects: Project[] = [];

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;

  constructor(private timeEntryService: TimeEntryService,
    private clientService: ClientService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private locationService: LocationService,
    private modalService: NgbModal,
    public location: Location) { }

  ngOnInit(): void {
    this.timeEntryForm = new UntypedFormGroup({
      client: new UntypedFormControl(),
      project: new UntypedFormControl(),
      type: new UntypedFormControl('PREP IN WAREHOUSE')
    });
    this.timeEntryForm.controls.project.disable();
    this.timeEntryForm.controls.type.disable();
    this.prepareClientTypeahead();
    this.getTimeEntriesForCurrentUser();
    this.startUpdatingCurrentTime();
  }

  // Open Modal
  openModal(content: any): void {
    this.modalReference = this.modalService.open(content);
  }

  // Get Clients
  private getClients(term: string | null): Observable<Client[]> {
    const params = {
      searchTerm: term,
      sortBy: 'client_name',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0
    };
    return this.clientService.getClients(params).pipe(map((res) => { return res.clients; }));
  }

  // Prepare Client Typeahead
  private prepareClientTypeahead(): void {
    this.clientTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.clientTypeaheadInputFocus$).pipe(switchMap((term) => {
        if (term.length == 0) {
          this.resetTimeEntryForm();
          return this.getClients(null);
        } else {
          return this.getClients(term);
        }
      }));
    }
  }

  // Client Selected
  clientSelected(event: NgbTypeaheadSelectItemEvent): void {
    event.preventDefault();
    this.selectedClient = event.item;
    this.timeEntryForm.controls.client.setValue(this.selectedClient.name);
    this.getProjectsForClient();
  }

  // Get Projects For Client
  private getProjectsForClient(): void {
    const params = {
      searchTerm: null,
      sortBy: 'p.project_name',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:c.client_id': JSON.stringify([this.selectedClient.id])
    };
    this.projectService.getProjects(params).subscribe((res) => {
      this.projects = res.projects;
      if (this.projects.length > 0) {
        this.timeEntryForm.controls.project.setValue(this.projects[0].id);
        this.timeEntryForm.controls.project.enable();
        this.timeEntryForm.controls.type.enable();
      } else {
        this.timeEntryForm.controls.project.disable();
        this.timeEntryForm.controls.type.disable();
      }
    });
  }

  /* ----- Time Entries ----- */

  // Start Updating Current Time
  private startUpdatingCurrentTime(): void {
    setInterval(() => {
      this.currentTimeString = dayjs().format('HH:mm:ss');
    }, 1000);
  }

  // Get Time Entries For Current User
  private getTimeEntriesForCurrentUser(): void {
    this.timeEntryService.getTimeEntriesForCurrentUser().subscribe((timeEntries) => {
      this.timeEntries = timeEntries;
    });
  }

  // Clock In
  async clockIn(): Promise<void> {
    let geoPos: GeoPosition = { longitude: null, latitude: null, accuracy: null };
    let geoErrorMsg!: string;
    try {
      geoPos = await this.locationService.getCurrentGeoPosition();
    } catch (error) {
      geoErrorMsg = this.locationService.parseGeoPositionError(error as GeolocationPositionError);
    }
    const lastClockIn = await lastValueFrom(this.timeEntryService.getLastClockInTimeEntry());
    if (lastClockIn) {
      this.lastClockIn = lastClockIn;
      this.openModal(this.alreadyClockedInModal);
    } else {
      if (this.timeEntryForm.valid) {
        const timeEntry = {
          projectId: this.timeEntryForm.value.project,
          type: this.timeEntryForm.value.type,
          startLocation: geoPos
        };
        await lastValueFrom(this.timeEntryService.clockIn(timeEntry));
        if (!geoPos.latitude && !geoPos.longitude && !geoPos.accuracy) this.alertService.showWarningAlert(`Clocked In - ${geoErrorMsg}`);
        else this.alertService.showSuccessAlert('Clocked In');
        this.getTimeEntriesForCurrentUser();
      } else {
        this.alertService.showWarningAlert('Form is invalid.');
      }
    }
  }

  // Clock Out
  async clockOut(timeEntryId: string): Promise<void> {
    let geoPos: GeoPosition = { longitude: null, latitude: null, accuracy: null };
    let geoErrorMsg!: string;
    try {
      geoPos = await this.locationService.getCurrentGeoPosition();
    } catch (error) {
      geoErrorMsg = this.locationService.parseGeoPositionError(error as GeolocationPositionError);
    }
    await lastValueFrom(this.timeEntryService.clockOut(timeEntryId, geoPos));
    if (!geoPos.latitude && !geoPos.longitude && !geoPos.accuracy) this.alertService.showWarningAlert(`Clocked Out - ${geoErrorMsg}`);
    else this.alertService.showSuccessAlert('Clocked Out');
    this.getTimeEntriesForCurrentUser();
  }

  // Switch Clocks
  async switchClocks(): Promise<void> {
    this.modalReference.close();
    await this.clockOut(this.lastClockIn.id);
    await this.clockIn();
  }

  // Reset Time Entry Form
  private resetTimeEntryForm(): void {
    this.projects.length = 0;
    this.timeEntryForm.controls.type.setValue('PREP IN WAREHOUSE');
    this.timeEntryForm.controls.project.disable();
    this.timeEntryForm.controls.type.disable();
  }

  /* ----- Helper Functions ----- */

  // Format Duration
  formatDuration(duration: number): string {
    return dayjs.duration(duration, 'seconds').asHours().toFixed(2) + ' Hour(s)';
  }
}
