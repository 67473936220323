<div class="card">
    <div class="card-header">
        <h5>County Colors</h5>
    </div>
    <div class="card-body">
        <ul class="list-group scrollable" style="height: 368px;">
            <li *ngFor="let countyColor of countyColors" class="list-group-item">
                <div class="d-flex">
                    <div class="my-auto">{{countyColor.name}}</div>
                    <input class="ms-auto" style="cursor: pointer;" type="color" [value]="countyColor.hexColor" (change)="updateCountyColor(countyColor, $event.target.value);">
                </div>
            </li>
            <li *ngIf="countyColors.length == 0" class="list-group-item">No County Colors</li>
        </ul>
    </div>
</div>