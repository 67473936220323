<div class="modal-header">
    <h5 class="modal-title">Email</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col">
            <label>Sent</label>
            <div>{{emailLog.createdAt | formatDateTime}}</div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            <div>
                <label>To</label>
                <div *ngFor="let toAddress of emailLog.toAddresses" class="selectedEmail">{{toAddress}}</div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            <div>
                <label>Cc</label>
                <div *ngFor="let ccAddress of emailLog.ccAddresses" class="selectedEmail">{{ccAddress}}</div>
                <div *ngIf="emailLog.ccAddresses.length == 0" class="selectedEmail">None</div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            <label>Subject</label>
            <div>{{emailLog.subject}}</div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col">
            <label>Message</label>
            <div *ngIf="emailLog" id="EMAIL_VIEW_BODY" class="form-control scrollable mt-1" [innerHTML]="emailLog.body | safeHTML"></div>
        </div>
    </div>
</div>