import { SmsCallFilterModalComponent } from 'src/app/shared/modals/sms-call-filter-modal/sms-call-filter-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from './services/loader.service';
import { ExcelDownloaderService } from './services/excel-downloader.service';



@NgModule({
  declarations: [
    SmsCallFilterModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgbModule
  ],
  providers:[LoaderService, ExcelDownloaderService],
  exports: [SmsCallFilterModalComponent]
})
export class SharedModule { }
