// Modules
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDatepickerConfig, NgbInputDatepickerConfig, NgbModalRef, NgbModule, NgbPopoverConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(weekOfYear);
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
import { ColorPickerModule } from 'ngx-color-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Pipes
import { ClientStatusPipe } from './client-status.pipe';
import { FormatDateTimePipe } from './format-date-time.pipe';
import { ProjectStagePipe } from './project-stage.pipe';
import { ProposalStatusPipe } from './proposal-status.pipe';
import { InvoiceStatusPipe } from './invoice-status.pipe';
import { ProjectPrepStatusPipe } from './project-prep-status.pipe';
import { SafeHTMLPipe } from './safe-html.pipe';
import { ProjectStatusPipe } from './project-status.pipe';
import { FormatBytesPipe } from './format-bytes.pipe';

// Directives
import { PhoneMaskDirective } from './phone-mask.directive';
import { ForbiddenValueDirective } from './forbidden-value.directive';

// Interceptors
import { APIRequestInterceptor } from './api-request.interceptor';

// Services
import { NotificationService } from './notification.service';

// Components
import { AppComponent } from './app.component';
import { App2Component } from './app2.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientsComponent } from './clients/clients.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProjectsComponent } from './projects/projects.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { LoginComponent } from './login/login.component';
import { ClientDetailComponent } from './client-detail/client-detail.component';
import { SiteDetailComponent } from './site-detail/site-detail.component';
import { AlertComponent } from './alert/alert.component';
import { SendEmailComponent } from './send-email/send-email.component';
import { InventoryComponent } from './inventory/inventory.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { SettingsComponent } from './settings/settings.component';
import { ProjectProposalComponent } from './project-proposal/project-proposal.component';
import { ProjectInvoicesComponent } from './project-invoices/project-invoices.component';
import { ProposalDetailComponent } from './proposal-detail/proposal-detail.component';
import { ProposalViewComponent } from './proposal-view/proposal-view.component';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import { ProjectPrepComponent } from './project-prep/project-prep.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ScheduleCalendarComponent } from './schedule-calendar/schedule-calendar.component';
import { ProjectInstallationComponent } from './project-installation/project-installation.component';
import { ProjectRemovalComponent } from './project-removal/project-removal.component';
import { ProjectFilesComponent } from './project-files/project-files.component';
import { ScheduleQueueComponent } from './schedule-queue/schedule-queue.component';
import { DashboardProspectsComponent } from './dashboard-prospects/dashboard-prospects.component';
import { DashboardUpNextComponent } from './dashboard-up-next/dashboard-up-next.component';
import { InvoiceViewComponent } from './invoice-view/invoice-view.component';
import { ProjectSummaryComponent } from './project-summary/project-summary.component';
import { AcceptProposalComponent } from './accept-proposal/accept-proposal.component';
import { ProjectPrepOverviewComponent } from './project-prep-overview/project-prep-overview.component';
import { ProjectPrepGlobalComponent } from './project-prep-global/project-prep-global.component';
import { ProjectPrepOrdersComponent } from './project-prep-orders/project-prep-orders.component';
import { ProjectPrepInventoryComponent } from './project-prep-inventory/project-prep-inventory.component';
import { NotesComponent } from './notes/notes.component';
import { MobileNavbarComponent } from './mobile-navbar/mobile-navbar.component';
import { SettingsUsersComponent } from './settings-users/settings-users.component';
import { SettingsProposalsComponent } from './settings-proposals/settings-proposals.component';
import { SettingsInvoicesComponent } from './settings-invoices/settings-invoices.component';
import { SettingsEmailsComponent } from './settings-emails/settings-emails.component';
import { SettingsPaymentsComponent } from './settings-payments/settings-payments.component';
import { DashboardStatsComponent } from './dashboard-stats/dashboard-stats.component';
import { SnapshotComponent } from './snapshot/snapshot.component';
import { SnapshotProjectListComponent } from './snapshot-project-list/snapshot-project-list.component';
import { EmailViewComponent } from './email-view/email-view.component';
import { ScheduleItemModalComponent } from './schedule-item-modal/schedule-item-modal.component';
import { NewUserModalComponent } from './new-user-modal/new-user-modal.component';
import { NewProjectModalComponent } from './new-project-modal/new-project-modal.component';
import { TimeClockComponent } from './time-clock/time-clock.component';
import { TimeEntryModalComponent } from './time-entry-modal/time-entry-modal.component';
import { SettingsGeneralComponent } from './settings-general/settings-general.component';
import { SettingsGeneralCountyColorsComponent } from './settings-general-county-colors/settings-general-county-colors.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NewClientModalComponent } from './new-client-modal/new-client-modal.component';
import { NewSiteModalComponent } from './new-site-modal/new-site-modal.component';
import { SettingsOrganizationComponent } from './settings-organization/settings-organization.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { RegistrationComponent } from './registration/registration.component';
import { ProjectReviewsComponent } from './project-reviews/project-reviews.component';
import { ProjectBasesComponent } from './project-bases/project-bases.component';
import { SettingsProjectsComponent } from './settings-projects/settings-projects.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { BugReportComponent } from './bug-report/bug-report.component';
import { BugReportOverlayComponent } from './bug-report-overlay/bug-report-overlay.component';
import { MapDisplayComponent } from './map-display/map-display.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportsPayrollSummaryComponent } from './reports-payroll-summary/reports-payroll-summary.component';
import { SettingsSubscriptionsComponent } from './settings-subscriptions/settings-subscriptions.component';
import { SettingsSubscriptionsListComponent } from './settings-subscriptions-list/settings-subscriptions-list.component';
import { SettingsSubscriptionsPaymentOptionsComponent } from './settings-subscriptions-payment-options/settings-subscriptions-payment-options.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CardOnFileModalComponent } from './card-on-file-modal/card-on-file-modal.component';
import { SquarePaymentFormComponent } from './square-payment-form/square-payment-form.component';
import { RegistrationPaymentFormComponent } from './registration-payment-form/registration-payment-form.component';
import { SettingsProposalsAgreementTypesComponent } from './settings-proposals-agreement-types/settings-proposals-agreement-types.component';
import { ProposalMultiAgreementTypeOptionsModalComponent } from './proposal-multi-agreement-type-options-modal/proposal-multi-agreement-type-options-modal.component';
import { ClientDetailProjectsComponent } from './client-detail-projects/client-detail-projects.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { TinselPayCardPaymentFormComponent } from './tinsel-pay-card-payment-form/tinsel-pay-card-payment-form.component';
import { TinselPayAchPaymentFormComponent } from './tinsel-pay-ach-payment-form/tinsel-pay-ach-payment-form.component';
import { TinselPaySignUpModalComponent } from './tinsel-pay-sign-up-modal/tinsel-pay-sign-up-modal.component';
import { SettingsEmailsLogsComponent } from './settings-emails-logs/settings-emails-logs.component';
import { ReportsAtAGlanceComponent } from './reports-at-a-glance/reports-at-a-glance.component';
import { ReportsAccountsReceivableComponent } from './reports-accounts-receivable/reports-accounts-receivable.component';
import { ReportsStorageLocationsComponent } from './reports-storage-locations/reports-storage-locations.component';
import { ReportsLastProposalAcceptedDateComponent } from './reports-last-proposal-accepted-date/reports-last-proposal-accepted-date.component';
import { UserTimeClockComponent } from './user-time-clock/user-time-clock.component';
import { NotificationContainerComponent } from './notification-container/notification-container.component';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { NotificationBannerComponent } from './notification-banner/notification-banner.component';
import { TinselPayOverviewComponent } from './tinsel-pay-overview/tinsel-pay-overview.component';
import { SettingsSubscriptionsInvoicesComponent } from './settings-subscriptions-invoices/settings-subscriptions-invoices.component';
import { ClientDetailVaultsComponent } from './client-detail-vaults/client-detail-vaults.component';
import { DatePickerControllerComponent } from './date-picker-controller/date-picker-controller.component';
import { ReportsPaymentsComponent } from './reports-payments/reports-payments.component';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { PaymentStatusPipe } from './payment-status.pipe';
import { TinselPayOverviewCardVaultsComponent } from './tinsel-pay-overview-card-vaults/tinsel-pay-overview-card-vaults.component';
import { TinselPayOverviewAchVaultsComponent } from './tinsel-pay-overview-ach-vaults/tinsel-pay-overview-ach-vaults.component';
import { TinselPayOverviewCardTransactionsComponent } from './tinsel-pay-overview-card-transactions/tinsel-pay-overview-card-transactions.component';
import { TinselPayOverviewAchTransactionsComponent } from './tinsel-pay-overview-ach-transactions/tinsel-pay-overview-ach-transactions.component';
import { SignatureCanvasComponent } from './signature-canvas/signature-canvas.component';
import { ProposalInteractionPipe } from './proposal-interaction.pipe';
import { PaymentRefundModalComponent } from './payment-refund-modal/payment-refund-modal.component';
import { PaymentCategoryPipe } from './payment-category.pipe';
import { ReportsProposalsComponent } from './reports-proposals/reports-proposals.component';
import { ReportsProjectBalancesComponent } from './reports-project-balances/reports-project-balances.component';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { ReportsPreferredInstallationAndRemovalDatesComponent } from './reports-preferred-installation-and-removal-dates/reports-preferred-installation-and-removal-dates.component';
import { ReportsInvoicesComponent } from './reports-invoices/reports-invoices.component';
import { ReportsSalesTaxInvoicedComponent } from './reports-sales-tax-invoiced/reports-sales-tax-invoiced.component';
import { ReportsSalesTaxPaidComponent } from './reports-sales-tax-paid/reports-sales-tax-paid.component';
import { ScheduleMapComponent } from './schedule-map/schedule-map.component';
import { BulkSchedulingModalComponent } from './bulk-scheduling-modal/bulk-scheduling-modal.component';
import { ProspectModalComponent } from './prospect-modal/prospect-modal.component';
import { MockupToolComponent } from './mockup-tool/mockup-tool.component';
import { ProjectMockupsComponent } from './project-mockups/project-mockups.component';
import { ProposalBuilderComponent } from './proposal-builder/proposal-builder.component';
import { MediaLightboxComponent } from './media-lightbox/media-lightbox.component';
import { MockupToolCustomAssetsComponent } from './mockup-tool-custom-assets/mockup-tool-custom-assets.component';
import { FileUploadModalComponent } from './file-upload-modal/file-upload-modal.component';
import { ProjectPrepOrderModalComponent } from './project-prep-order-modal/project-prep-order-modal.component';
import { ProjectPrepCheckInModalComponent } from './project-prep-check-in-modal/project-prep-check-in-modal.component';
import { ProjectPrepSplitModalComponent } from './project-prep-split-modal/project-prep-split-modal.component';
import { ProjectPrepCancelOrderModalComponent } from './project-prep-cancel-order-modal/project-prep-cancel-order-modal.component';
import { ProjectPrepCancelSplitMsgComponent } from './project-prep-cancel-split-msg/project-prep-cancel-split-msg.component';
import { ProposalRejectModalComponent } from './proposal-reject-modal/proposal-reject-modal.component';
import { ProjectProposalNoContactModalComponent } from './project-proposal-no-contact-modal/project-proposal-no-contact-modal.component';
import { ProposalExcessModalComponent } from './proposal-excess-modal/proposal-excess-modal.component';
import { QuillModule } from 'ngx-quill';
import { CurrencyPipe } from '@angular/common';
import { ReportsTimeClockComponent } from './reports-time-clock/reports-time-clock.component';
import { CrewManagementComponent } from './crew-management/crew-management.component';
import { PendingChangesGuard } from './pending-changes.guard';
import { CrewDeletionModalComponent } from './crew-deletion-modal/crew-deletion-modal.component';
import { ChangeSubscriptionPageComponent } from './change-subscription-page/change-subscription-page.component';
import { OrganizationUpdateSubscriptionComponent } from './organization-update-subscription/organization-update-subscription.component';
import { SubscriptionAlertModalComponent } from './subscription-alert-modal/subscription-alert-modal.component';
import { SubscriptionRefundModalComponent } from './subscription-refund-modal/subscription-refund-modal.component';
import { SubscriptionInvoiceModalComponent } from './subscription-invoice-modal/subscription-invoice-modal.component';
import { CallDialerDialogComponent } from './conversation/components/call-dialer-dialog/call-dialer-dialog.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { InCallDialogComponent } from './in-call-dialog/in-call-dialog.component';
import { ConversationService } from './conversation.service';
import { IncomingCallDialogComponent } from './incoming-call-dialog/incoming-call-dialog.component';
import { A2pModalComponent } from './a2p-modal/a2p-modal.component';
import { BusinessIdentityFormComponent } from './sms-call/business-identity-form/business-identity-form.component';
import { BusinessAddressFormComponent } from './sms-call/business-address-form/business-address-form.component';
import { BuyNumberModalComponent } from './sms-call/modals/buy-number-modal/buy-number-modal.component';
import { NumberConfigurationModalComponent } from './number-configuration-modal/number-configuration-modal.component';
import { NumberNameFormComponent } from './number-name-form/number-name-form.component';
import { NumberCampaignComponent } from './sms-call/number-campaign/number-campaign.component';
import { NumberConnectFormComponent } from './number-connect-form/number-connect-form.component';
import { FileDropDirective } from './directives/file-drop.directive';
import { InvoicePdfComponent } from './invoice-pdf/invoice-pdf.component';
import { ClientDetailFilesComponent } from './client-detail-files/client-detail-files.component';
import { ClientDetailInvoicesComponent } from './client-detail-invoices/client-detail-invoices.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SmsCallTermsModalComponent } from './sms-call/modals/sms-call-terms-modal/sms-call-terms-modal.component';
import { NameDialogComponent } from './name-dialog/name-dialog.component';
import { SmsCallRegistrationComponent } from './sms-call/sms-call-registration/sms-call-registration.component';
import { NumbersComponent } from './sms-call/numbers/numbers.component';
import { CampaignModalComponent } from './sms-call/modals/campaign-modal/campaign-modal.component';
import { SettingsSmsCallComponent } from './sms-call/settings-sms-call/settings-sms-call.component';
import { ContactPersonFormComponent } from './sms-call/contact-person-form/contact-person-form.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ClientsComponent,
    NavbarComponent,
    ProjectsComponent,
    InvoicesComponent,
    LoginComponent,
    ClientDetailComponent,
    SiteDetailComponent,
    AlertComponent,
    ClientStatusPipe,
    SendEmailComponent,
    PhoneMaskDirective,
    InventoryComponent,
    ProjectDetailComponent,
    SettingsComponent,
    ProjectProposalComponent,
    ProjectInvoicesComponent,
    ProposalDetailComponent,
    ProposalViewComponent,
    FormatDateTimePipe,
    ProjectStagePipe,
    ProposalStatusPipe,
    InvoiceDetailComponent,
    InvoiceStatusPipe,
    App2Component,
    ProjectPrepComponent,
    ScheduleComponent,
    ScheduleCalendarComponent,
    ProjectInstallationComponent,
    ProjectRemovalComponent,
    ForbiddenValueDirective,
    ProjectFilesComponent,
    ScheduleQueueComponent,
    DashboardProspectsComponent,
    InvoiceViewComponent,
    ProjectSummaryComponent,
    AcceptProposalComponent,
    ProjectPrepOverviewComponent,
    ProjectPrepGlobalComponent,
    ProjectPrepOrdersComponent,
    ProjectPrepInventoryComponent,
    NotesComponent,
    ProjectPrepStatusPipe,
    MobileNavbarComponent,
    SettingsUsersComponent,
    SettingsProposalsComponent,
    SettingsInvoicesComponent,
    SettingsEmailsComponent,
    SettingsPaymentsComponent,
    DashboardStatsComponent,
    SnapshotComponent,
    SnapshotProjectListComponent,
    EmailViewComponent,
    SafeHTMLPipe,
    ProjectStatusPipe,
    ScheduleItemModalComponent,
    NewUserModalComponent,
    NewProjectModalComponent,
    TimeClockComponent,
    TimeEntryModalComponent,
    DashboardUpNextComponent,
    SettingsGeneralComponent,
    SettingsGeneralCountyColorsComponent,
    NotFoundComponent,
    NewClientModalComponent,
    NewSiteModalComponent,
    SettingsOrganizationComponent,
    AddressFormComponent,
    RegistrationComponent,
    ProjectReviewsComponent,
    ProjectBasesComponent,
    SettingsProjectsComponent,
    UserSettingsComponent,
    BugReportComponent,
    BugReportOverlayComponent,
    FormatBytesPipe,
    MapDisplayComponent,
    ReportsComponent,
    ReportsPayrollSummaryComponent,
    SettingsSubscriptionsComponent,
    SettingsSubscriptionsListComponent,
    SettingsSubscriptionsPaymentOptionsComponent,
    ForgotPasswordComponent,
    CardOnFileModalComponent,
    SquarePaymentFormComponent,
    RegistrationPaymentFormComponent,
    SettingsProposalsAgreementTypesComponent,
    ProposalMultiAgreementTypeOptionsModalComponent,
    ClientDetailProjectsComponent,
    ContactModalComponent,
    ConfirmationModalComponent,
    TinselPayCardPaymentFormComponent,
    TinselPayAchPaymentFormComponent,
    TinselPaySignUpModalComponent,
    SettingsEmailsLogsComponent,
    ReportsAtAGlanceComponent,
    ReportsAccountsReceivableComponent,
    ReportsStorageLocationsComponent,
    ReportsLastProposalAcceptedDateComponent,
    UserTimeClockComponent,
    NotificationContainerComponent,
    NotificationCenterComponent,
    NotificationBannerComponent,
    TinselPayOverviewComponent,
    SettingsSubscriptionsInvoicesComponent,
    ClientDetailVaultsComponent,
    DatePickerControllerComponent,
    ReportsPaymentsComponent,
    PaymentModalComponent,
    PaymentStatusPipe,
    TinselPayOverviewCardVaultsComponent,
    TinselPayOverviewAchVaultsComponent,
    TinselPayOverviewCardTransactionsComponent,
    TinselPayOverviewAchTransactionsComponent,
    SignatureCanvasComponent,
    ProposalInteractionPipe,
    PaymentRefundModalComponent,
    PaymentCategoryPipe,
    ReportsProposalsComponent,
    ReportsProjectBalancesComponent,
    AuditLogComponent,
    ReportsPreferredInstallationAndRemovalDatesComponent,
    ReportsInvoicesComponent,
    ReportsSalesTaxInvoicedComponent,
    ReportsSalesTaxPaidComponent,
    ScheduleMapComponent,
    BulkSchedulingModalComponent,
    ProspectModalComponent,
    MockupToolComponent,
    ProjectMockupsComponent,
    ProposalBuilderComponent,
    MediaLightboxComponent,
    MockupToolCustomAssetsComponent,
    FileUploadModalComponent,
    ProjectPrepOrderModalComponent,
    ProjectPrepCheckInModalComponent,
    ProjectPrepSplitModalComponent,
    ProjectPrepCancelOrderModalComponent,
    ProjectPrepCancelSplitMsgComponent,
    ProposalRejectModalComponent,
    ProjectProposalNoContactModalComponent,
    ProposalExcessModalComponent,
    ReportsTimeClockComponent,
    CrewManagementComponent,
    CrewDeletionModalComponent,
    ChangeSubscriptionPageComponent,
    OrganizationUpdateSubscriptionComponent,
    SubscriptionAlertModalComponent,
    SubscriptionRefundModalComponent,
    SubscriptionInvoiceModalComponent,
    CallDialerDialogComponent,
    ContactPageComponent,
    SettingsSmsCallComponent,
    InCallDialogComponent,
    IncomingCallDialogComponent,
    A2pModalComponent,
    BusinessIdentityFormComponent,
    ContactPersonFormComponent,
    BusinessAddressFormComponent,
    BuyNumberModalComponent,
    NumberConfigurationModalComponent,
    NumberNameFormComponent,
    NumberCampaignComponent,
    NumberConnectFormComponent,
    FileDropDirective,
    InvoicePdfComponent,
    SmsCallTermsModalComponent,
    NameDialogComponent,
    SmsCallRegistrationComponent,
    NumbersComponent,
    CampaignModalComponent,
    ClientDetailFilesComponent,
    ClientDetailInvoicesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    DragDropModule,
    NgbModule,
    NgxSpinnerModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ColorPickerModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean', 'link'],                                         // remove formatting button
        ]
      }
    }),
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: APIRequestInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: (ns: NotificationService) => () => {
      return ns.load() }, deps: [NotificationService], multi: true
    },
    NgbDatepickerConfig,
    NgbInputDatepickerConfig,
    NgbPopoverConfig,
    NgbTooltipConfig,
    CurrencyPipe,
    PendingChangesGuard,
    ConversationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(datepickerConfig: NgbDatepickerConfig,
    inputDatepickerConfig: NgbInputDatepickerConfig,
    popoverConfig: NgbPopoverConfig,
    tooltipConfig: NgbTooltipConfig) {

    // Datepicker Config
    datepickerConfig.firstDayOfWeek = 7;

    // Input Datepicker Config
    inputDatepickerConfig.container = "body";

    // Popover Config
    popoverConfig.container = "body";

    // Tooltip Config
    tooltipConfig.container = "body";
  }
}
