import { Component, HostListener } from '@angular/core';
import { faAngleLeft, faEllipsis, faEnvelope, faFileDownload, faFilter, faPhone, faRotateLeft, faSms, faSortDown, faSortUp, faTriangleExclamation, faUser, fas, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { TableControlService } from '../table-control.service';
import { InvoiceService } from '../invoice.service';
import { InvoiceStatusPipe } from '../invoice-status.pipe';
import { Invoice } from '../invoice';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { ContactsSerivce } from '../contacts.service';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SendSmsDialogComponent } from '../conversation/components/send-sms-dialog/send-sms-dialog.component';
import { SendEmailComponent } from '../send-email/send-email.component';
import { CallDialerDialogComponent } from '../conversation/components/call-dialer-dialog/call-dialer-dialog.component';
import { ConversationService } from '../conversation.service';
import { CallService } from '../call.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { AlertService } from '../alert.service';
import { User } from './../user';
import { LoaderService } from '../shared/services/loader.service';
import { TwilioService } from '../twilio.service';


@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css'],
  providers: [InvoiceStatusPipe]
})
export class ContactPageComponent {
  //Screensize event Listener
 @HostListener('window:resize', ['$event'])
 onResize(event: Event) {
   this.resizeSubject.next((event.target as Window).innerWidth);
 }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  componentDestroyed$: Subject<boolean> = new Subject()
  twilioDevice: any;
  twilioClient: any;

  // Properties
  contacts: any[] = [];
  contactsCount: number = 0;
  hasSmsAndCalls:boolean = false;
  isMobileView: boolean = false;

  sortOptions = [
    { label: 'First Name', value: 'c.contact_first_name' },
    { label: 'Last Name', value: 'c.contact_last_name' },
    { label: 'Client Name', value: 'c.client_name' },
  ];
  filterColumns = [
    { label: 'Client Name', value: 'c.client_name' },
  ];
  sortBy: string = 'c.contact_last_name';
  sortDirection: string = 'DESC';



  // Table Controls
  tableControls: TableControlService;
  storedSearchValue: string;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;
  selectedFilterColumn: string ='';

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;
  faTriangleExclamation = faTriangleExclamation;
  faRotateLeft = faRotateLeft;
  faFileDownload = faFileDownload;
  faPhone = faPhone;
  faSms = faSms;
  faEnvelope = faEnvelope;
  faEllipsis = faEllipsis;
  faUser = faUser;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }


  constructor(private ContactsService: ContactsSerivce,
    private invoiceStatusPipe: InvoiceStatusPipe,
    private modalService: NgbModal,
    private conversationSerivce: ConversationService,
    private callService: CallService,
    private twilioService: TwilioService,
    private alertService: AlertService,
    public location: Location,
    private loaderService: LoaderService) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
          this.tableControls.setLimit(20);
        }
      });
     }

  ngOnInit(): void {
    const userLoginDetails = localStorage.getItem('login');
    if (userLoginDetails) {
      const user: User = JSON.parse(userLoginDetails);
      this.hasSmsAndCalls = user.twilioSID || user.defaultNumber ? true : false;
    }
    this.conversationSerivce.twilioClient
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        console.log(data)
        this.twilioClient = data;
      });

    this.tableControls = new TableControlService('Contacts', true, 'c.contact_last_name', 'DESC');
    this.checkScreenSize();
    if(this.isMobile) {
      let activeFilters = this.tableControls.getActiveFilters()
      let columns = [];
      for (const [key, value] of activeFilters) {
        columns.push(key)
      }
      this.tableControls.resetFilters(columns)
    }
    this.tableControls.refresh.subscribe(() => {
      this.getContacts();
    });
    if (this.tableControls.hasTableConfiguration()) this.storedSearchValue = this.tableControls.getSearchTerm();

    this.getContacts();

    this.callService.twilioDevice
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        console.log(data)
        this.twilioDevice = data;
      });
  }

  // Get Invoices
  private getContacts(): void {
    let params = this.tableControls.getParams();
    this.loaderService.showSpinner();
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
      for (const [key, value] of this.activeFilters) {
        if (key.includes('range')) params[`filter:${key}`] = value;
        else params[`filter:${key}`] = JSON.stringify(value);
      }
    };

    this.ContactsService.getContacts(params).subscribe(
      {
        next: (res) => {
          this.contacts = res.contacts;
          this.contactsCount = res.count;
          this.loaderService.hideSpinner();
        },
        error:() => {
          this.loaderService.hideSpinner();
        }
      });
  }

  // Download Invoices
  downloadContact(): void {
    window.open(`${environment.apiUrl}/contacts/clientcontacts/export`);
  }

  /* ----- Filters ----- */

  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    if (!this.columnFilterValues.has(column)) {
      const params = {
        searchTerm: null,
        sortBy: column,
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        column: column
      };
      // this.invoiceService.getDistinctColumnValues(params).subscribe((values) => {
      //   const tempArray = [];
      //   for (const value of values) {
      //     let name = null;
      //     switch (column) {
      //       case 'i.invoice_status':
      //         name = this.invoiceStatusPipe.transform(value);
      //         break;
      //       default:
      //         name = (value === null || value.length == 0) ? 'BLANK' : value;
      //         break;
      //     }
      //     tempArray.push({ name: name, value: value, randomId: Math.random().toString(36) });
      //   }
      //   this.columnFilterValues.set(column, tempArray);
      // });
    }
  }

  // Show Column Filter Popover
  showColumnFilterPopover(popover, column: string): void {
    this.getDistinctColumnValues(column);
    if (popover.isOpen()) {
      popover.close();
    } else {
      setTimeout(() => {
        popover.open({ column });
      }, 250);
    }
  }

  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getContacts();
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getContacts();
    this.getActiveFilterCount();
  }

   // Get Active Filter Count
   getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getContacts();
    this.getActiveFilterCount();
  }



  openSmsDialog(contact): void {
    if(!this.hasSmsAndCalls){
      return;
    }
    const modalRef = this.modalService.open(SendSmsDialogComponent);
    modalRef.componentInstance.twilioClient = this.twilioClient
    modalRef.componentInstance.contact = contact;
    modalRef.componentInstance.phoneNumber = contact.phone;
    modalRef.componentInstance.sent.subscribe((res) => {
      this.alertService.showSuccessAlert('Message sent');
    });
  }

  openEmailDialog(contact): void {
    const modalRef = this.modalService.open(SendEmailComponent);
    modalRef.componentInstance.clientId = contact.clientId;
    modalRef.componentInstance.type = 'GENERAL';
    //modalRef.componentInstance.toEmailAddresses = [contact.email];
  }

  popOverOptions(popover: NgbPopover, contact: any): void {
    if (popover.isOpen()) popover.close();
    else popover.open({ popover, contact });
  }

  openCallDialerDialog(data: any): void {
    if(!this.hasSmsAndCalls){
      return;
    }
    const modalRef = this.modalService.open(CallDialerDialogComponent, { windowClass: "callDialerDialog" });
    modalRef.componentInstance.passedContact = data;
    modalRef.componentInstance.call.subscribe((data) => {
      this.openInCallDialog(data);
    });
  }

  async openInCallDialog(data: any) {
    const phoneNumber = data.number.replace(/\D/g, '')
    const formattedNumber = Number(phoneNumber)
    console.log(formattedNumber)
    // TODO direct call
    const outGoingCall = await this.twilioDevice.connect({
      params: { To: `+1${formattedNumber}`, 'contactId': data.contact.id }
    });
    this.twilioService.outgoingConnection = outGoingCall
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
    this.isMobileView ? this.tableControls.setLimit(5): this.tableControls.setLimit(20);
    const userLoginDetails = localStorage.getItem('login');
    const user: User = JSON.parse(userLoginDetails);
    this.hasSmsAndCalls = user.twilioSID || user.defaultNumber ? true : false;
  }

}
