import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../alert.service';
import { SettingService } from '../setting.service';
import { faTrashCan, faBold, faItalic, faListOl, faListUl, faCheck, faSave, faPlus, faQuestion, faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { AcceptProposalTerm } from '../accept-proposal-term';
import { ProposalTerm } from '../proposal-term';
import { Tax } from '../tax';
import { AuthService } from '../auth.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-settings-proposals',
  templateUrl: './settings-proposals.component.html',
  styleUrls: ['./settings-proposals.component.css']
})
export class SettingsProposalsComponent implements OnInit {

  installationWeeks: { date: string, label: string }[] = [];

  // Modals
  private modalReference: NgbModalRef;

  /* ----- Proposal View ----- */

  // Forms
  editorFormGroup: UntypedFormGroup;
  businessInformationForm: UntypedFormGroup;
  businessAddressForm: UntypedFormGroup;

  displayAddress: boolean;

  /* ----- Taxes ----- */

  @ViewChild('TAX_MODAL', { static: false }) private taxModal;
  taxForm: UntypedFormGroup;
  selectedTax: Tax = null;
  taxes: Tax[] = [];

  /* ----- Accept Proposal Terms ----- */

  // Modals 
  @ViewChild('ACCEPT_PROPOSAL_TERM_MODAL', { static: false }) private acceptProposalTermModal;
  acceptProposalTermForm: UntypedFormGroup;
  selectedAcceptProposalTerm: AcceptProposalTerm = null;
  acceptProposalTerms: AcceptProposalTerm[] = [];

  /* ----- Proposal Terms ----- */

  proposalTerms: ProposalTerm[] = [];
  selectedProposalTerm: ProposalTerm = null;

  /* ----- Notifications ----- */
  onProposalView: boolean = false;
  onProposalAcceptance: boolean = false;
  onProposalRejection: boolean = false;
  sendACopyToSuperAdmin: boolean = false;

  /* ----- Signatures ----- */

  signaturesAreEnabled: boolean = false;

  // Preferences on installation
  preferredInstallation: boolean = false;
  preferredRemoval: boolean = false;
  chosenPreferrences = [];
  /* ----- Other ----- */

  // Font Awesome Imports
  faTrashCan = faTrashCan;
  faBold = faBold;
  faItalic = faItalic;
  faListOl = faListOl;
  faListUl = faListUl;
  faCheck = faCheck;
  faSave = faSave;
  faPlus = faPlus;
  faQuestion = faQuestion;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;

  constructor(private settingService: SettingService,
    private alertService: AlertService,
    private authService: AuthService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.editorFormGroup = new UntypedFormGroup({
      termsAndCondition: new UntypedFormControl(),
      multiPricingDescription: new UntypedFormControl()
    });
    this.businessInformationForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      email: new UntypedFormControl(),
      phone: new UntypedFormControl()
    });
    this.businessAddressForm = new UntypedFormGroup({
      street: new UntypedFormControl(),
      city: new UntypedFormControl(),
      state: new UntypedFormControl('SAS'),
      postalCode: new UntypedFormControl()
    });
    this.acceptProposalTermForm = new UntypedFormGroup({
      content: new UntypedFormControl()
    });
    this.taxForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      percent: new UntypedFormControl()
    });
    this.getTaxes();
    this.getProposalSettings();
    this.getAcceptProposalTerms();
    this.getMultiPricingDescription();
    this.getProposalTerms();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Modal
  openModal(content: any): void {
    this.modalReference = this.modalService.open(content);
  }

  /* ----- Proposal View ----- */

  // Get Proposal Settings
  private getProposalSettings(): void {
    this.settingService.getProposalSettings().subscribe((proposalSettings) => {
      // Business Information Form
      this.businessInformationForm.controls.name.setValue(proposalSettings.business.name);
      this.businessInformationForm.controls.email.setValue(proposalSettings.business.email);
      this.businessInformationForm.controls.phone.setValue(proposalSettings.business.phone);
      // Business Address Form
      const address = proposalSettings.business.address;
      this.businessAddressForm.controls.street.setValue(address.street);
      this.businessAddressForm.controls.city.setValue(address.city);
      this.businessAddressForm.controls.state.setValue(address.state);
      this.businessAddressForm.controls.postalCode.setValue(address.postalCode);
      this.displayAddress = proposalSettings.business.displayAddress;
      // Signatures
      this.signaturesAreEnabled = proposalSettings.signaturesAreEnabled;
      // Preferrences
      this.preferredInstallation = proposalSettings.preferredInstallation;

      if (this.preferredInstallation) {
        this.generateInstallationWeeks();
      }
      this.preferredRemoval = proposalSettings.preferredRemoval;
      this.chosenPreferrences = proposalSettings.preferredInstallationOptions;
      this.onProposalView = proposalSettings.notifications.onProposalView;
      this.onProposalAcceptance = proposalSettings.notifications.onProposalAcceptance;
      this.onProposalRejection = proposalSettings.notifications.onProposalRejection;
      this.sendACopyToSuperAdmin = proposalSettings.notifications.sendACopyToSuperAdmin;
    });
  }

  checkIfPrefIsSelected(dateSelected: Date): boolean {
    if (this.chosenPreferrences) {
      return this.chosenPreferrences.some((pref) => pref.date === dateSelected);
    } else {
      this.chosenPreferrences = this.installationWeeks;
      return true;
    }
  }

  // Update Business Information
  updateBusinessInformation(): void {
    if (this.businessInformationForm.valid) {
      this.settingService.updateProposalSettings(this.businessInformationForm.value).subscribe(() => {
        this.alertService.showSuccessAlert('Business Information Updated');
        this.getProposalSettings();
      });
    } else {
      this.businessInformationForm.markAllAsTouched();
    }
  }

  addChosenPreferrences(event, index) {
    const isSelected = event.target.checked;

    if (isSelected) {
      this.chosenPreferrences.push(this.installationWeeks[index]);
    } else {
      this.chosenPreferrences = this.chosenPreferrences.filter(pref => pref.date != this.installationWeeks[index].date)
    }
  }

  // Saving preferrence options
  updatePreferredInstallationOptions() {

    if (this.preferredInstallation && this.chosenPreferrences.length != 0) {
      const sortedArr = this.chosenPreferrences.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      console.log(sortedArr)

      this.settingService.updateProposalSettings({ preferredInstallationOptions: sortedArr, preferredInstallation: this.preferredInstallation }).subscribe(() => {
        this.alertService.showSuccessAlert(`Preferred Installation Options are saved`);
      });
    } else if (this.chosenPreferrences.length == 0) {
      this.alertService.showErrorAlert(`Should at least have one Preferred week option`);
    }
  }

  // Update Business Address
  updateBusinessAddress(): void {
    if (this.businessAddressForm.valid) {
      this.settingService.updateProposalSettings({ address: this.businessAddressForm.value }).subscribe(() => {
        this.alertService.showSuccessAlert('Business Address Updated');
        this.getProposalSettings();
      });
    } else {
      this.businessAddressForm.markAllAsTouched();
    }
  }

  // Toggle Display Business Address
  toggleDisplayBusinessAddress(): void {
    this.settingService.updateProposalSettings({ displayAddress: !this.displayAddress }).subscribe(() => {
      this.alertService.showSuccessAlert('Business Address Updated');
      this.getProposalSettings();
    });
  }

  // Business Information Form Accessors
  get businessName() { return this.businessInformationForm.controls.name; }
  get businessEmail() { return this.businessInformationForm.controls.email; }
  get businessPhone() { return this.businessInformationForm.controls.phone; }

  /* ----- Accept Proposal Terms ----- */

  // Get Accept Proposal Terms
  private getAcceptProposalTerms(): void {
    this.settingService.getAcceptProposalTerms().subscribe((acceptProposalTerms) => {
      this.acceptProposalTerms = acceptProposalTerms;
    });
  }

  // Save Accept Proposal Term
  saveAcceptProposalTerm(): void {
    if (this.selectedAcceptProposalTerm === null) {
      this.addAcceptProposalTerm();
    } else {
      this.updateAcceptProposalTerm();
    }
  }

  // Add Accept Proposal Term
  private addAcceptProposalTerm(): void {
    if (this.acceptProposalTermForm.valid) {
      this.settingService.addAcceptProposalTerm(this.acceptProposalTermForm.value.content).subscribe(() => {
        this.alertService.showSuccessAlert('Accept Proposal Term Added');
        this.modalReference.close();
        this.getAcceptProposalTerms();
      });
    } else {
      this.acceptProposalTermForm.markAllAsTouched();
    }
  }

  // Update Accept Proposal Term
  private updateAcceptProposalTerm(): void {
    if (this.acceptProposalTermForm.valid) {
      this.settingService.updateAcceptProposalTerm(this.selectedAcceptProposalTerm.id, this.acceptProposalTermForm.value.content).subscribe(() => {
        this.alertService.showSuccessAlert('Accept Proposal Term Updated');
        this.modalReference.close();
        this.getAcceptProposalTerms();
        this.selectedAcceptProposalTerm = null;
      });
    } else {
      this.acceptProposalTermForm.markAllAsTouched();
    }
  }

  // Delete Accept Proposal Term
  deleteAcceptProposalTerm(): void {
    this.settingService.deleteAcceptProposalTerm(this.selectedAcceptProposalTerm.id).subscribe(() => {
      this.alertService.showSuccessAlert('Accept Proposal Term Deleted');
      this.getAcceptProposalTerms();
      this.selectedAcceptProposalTerm = null;
    });
  }

  // Accept Proposal Term Selected
  acceptProposalTermSelected(acceptProposalTerm: AcceptProposalTerm): void {
    this.selectedAcceptProposalTerm = acceptProposalTerm;
    this.acceptProposalTermForm.controls.content.setValue(acceptProposalTerm.content);
    this.openModal(this.acceptProposalTermModal);
  }

  // Reset Accept Proposal Term Form
  resetAcceptProposalTermForm(): void {
    this.acceptProposalTermForm.reset();
    this.acceptProposalTermForm.controls.content.setValue(null);
  }

  /* ----- Multi Pricing Description ----- */

  // Get Multi Pricing Description
  private async getMultiPricingDescription(): Promise<void> {
    const currentUser = await this.authService.getCurrentUser();
    this.settingService.getMultiPricingDescription(currentUser.organizationId).subscribe((multiPricingDescription) => {
      this.editorFormGroup.controls.multiPricingDescription.setValue(multiPricingDescription.toString())
      // this.multiPricingDescription = multiPricingDescription.toString();
    });
  }

  // Update Multi Pricing Description
  updateMultiPricingDescription(): void {
    const multiPricingDescription = this.editorFormGroup.controls.multiPricingDescription.value;
    this.settingService.updateMultiPricingDescription(multiPricingDescription).subscribe(() => {
      this.alertService.showSuccessAlert('Multi Pricing Description Updated');
      this.getMultiPricingDescription();
    });
  }

  /* ----- Proposal Terms ----- */

  // Get Proposal Terms
  private getProposalTerms(): void {
    this.settingService.getProposalTerms().subscribe((proposalTerms) => {
      this.proposalTerms = proposalTerms;
      if (this.selectedProposalTerm !== null) {
        setTimeout(() => {
          this.selectProposalTerm(this.selectedProposalTerm.id);
        }, 5);
      } else {
        if (this.proposalTerms.length > 0) this.selectProposalTerm(this.proposalTerms[0].id);
      }
    });
  }

  // Add Proposal Term
  addProposalTerm(): void {
    const proposalTerm = {
      name: 'Untitled'
    };
    this.settingService.addProposalTerm(proposalTerm).subscribe(() => {
      this.alertService.showSuccessAlert('Terms & Conditions Added');
      this.getProposalTerms();
    });
  }

  // Update Proposal Term
  updateProposalTerm(): void {
    const proposalTerm = {
      id: this.selectedProposalTerm.id,
      name: (<HTMLInputElement>document.getElementById('PROPOSAL_TERM_NAME')).value,
      content: this.editorFormGroup.controls.termsAndCondition.value
    };
    this.settingService.updateProposalTerm(proposalTerm).subscribe(() => {
      this.alertService.showSuccessAlert('Terms & Conditions Updated');
      this.getProposalTerms();
    });
  }

  // Set Default Proposal Term
  setDefaultProposalTerm(): void {
    const proposalTerm = {
      id: this.selectedProposalTerm.id,
      isDefault: true
    };
    this.settingService.setDefaultProposalTerm(proposalTerm).subscribe(() => {
      this.alertService.showSuccessAlert('Terms & Conditions Updated');
      this.getProposalTerms();
    });
  }

  // Select Proposal Term
  selectProposalTerm(proposalTermId: string): void {
    this.selectedProposalTerm = this.proposalTerms.find((element) => { return element.id == proposalTermId; });
    (<HTMLSelectElement>document.getElementById('PROPOSAL_TERM_SELECT')).value = this.selectedProposalTerm.id;
    (<HTMLInputElement>document.getElementById('PROPOSAL_TERM_NAME')).value = this.selectedProposalTerm.name;
    this.editorFormGroup.controls.termsAndCondition.setValue(this.selectedProposalTerm.content);
    // this.termsAndCondition = this.selectedProposalTerm.content;
  }

  /* ----- Taxes ----- */

  // Get Taxes
  private getTaxes(): void {
    this.settingService.getTaxes().subscribe((taxes) => {
      this.taxes = taxes;
    });
  }

  // Save Tax
  saveTax(): void {
    if (this.selectedTax === null) {
      this.addTax();
    } else {
      this.updateTax();
    }
  }

  // Add Tax
  private addTax(): void {
    if (this.taxForm.valid) {
      const tax = {
        name: this.taxForm.value.name,
        percent: this.taxForm.value.percent
      };
      this.settingService.addTax(tax).subscribe(() => {
        this.alertService.showSuccessAlert('Tax Added');
        this.modalReference.close();
        this.getTaxes();
      });
    } else {
      this.taxForm.markAllAsTouched();
    }
  }

  // Update Tax
  private updateTax(): void {
    if (this.taxForm.valid) {
      const tax = {
        id: this.selectedTax['id'],
        name: this.taxForm.value.name,
        percent: this.taxForm.value.percent
      };
      this.settingService.updateTax(tax).subscribe(() => {
        this.alertService.showSuccessAlert('Tax Updated');
        this.modalReference.close();
        this.getTaxes();
        this.selectedTax = null;
      });
    } else {
      this.taxForm.markAllAsTouched();
    }
  }

  // Tax Selected
  taxSelected(tax: Tax): void {
    this.selectedTax = tax;
    this.taxForm.controls.name.setValue(tax.name);
    const taxPercent = tax.multiplier * 100;
    this.taxForm.controls.percent.setValue(taxPercent);
    this.openModal(this.taxModal);
  }

  // Reset Tax Form
  resetTaxForm(): void {
    this.taxForm.reset();
    this.taxForm.controls.name.setValue(null);
    this.taxForm.controls.percent.setValue(null);
  }

  /* ----- Tax Form Accessors ----- */

  get taxName() { return this.taxForm.controls.name; }
  get taxPercent() { return this.taxForm.controls.percent; }

  /* ----- Notifications ----- */
  updateNotifOnProposalView(event: Event): void {
    const isEnabled = (event.target as HTMLInputElement).checked;
    this.settingService.updateProposalSettings({ onProposalView: isEnabled }).subscribe(() => {
      this.alertService.showSuccessAlert(`Proposal View Notification ${(isEnabled) ? 'Enabled' : 'Disabled'}`);
    });
  }
  updateNotifOnProposalAcceptance(event: Event): void {
    const isEnabled = (event.target as HTMLInputElement).checked;
    this.settingService.updateProposalSettings({ onProposalAcceptance: isEnabled }).subscribe(() => {
      this.alertService.showSuccessAlert(`Proposal Acceptance Notification ${(isEnabled) ? 'Enabled' : 'Disabled'}`);
    });
  }
  updateNotifOnProposalRejection(event: Event): void {
    const isEnabled = (event.target as HTMLInputElement).checked;
    this.settingService.updateProposalSettings({ onProposalRejection: isEnabled }).subscribe(() => {
      this.alertService.showSuccessAlert(`Proposal Rejection Notification ${(isEnabled) ? 'Enabled' : 'Disabled'}`);
    });
  }
  updateSendACopyToSuperAdmin(event: Event): void {
    const isEnabled = (event.target as HTMLInputElement).checked;
    this.settingService.updateProposalSettings({ sendACopyToSuperAdmin: isEnabled }).subscribe(() => {
      this.alertService.showSuccessAlert(`Send a Copy to Super Admin ${(isEnabled) ? 'Enabled' : 'Disabled'}`);
    });
  }

  /* ----- Signatures ----- */

  // Update Signature Enabled Status
  updateSignatureEnabledStatus(event: Event): void {
    const isEnabled = (event.target as HTMLInputElement).checked;
    this.settingService.updateProposalSettings({ signaturesAreEnabled: isEnabled }).subscribe(() => {
      this.alertService.showSuccessAlert(`Signatures ${(isEnabled) ? 'Enabled' : 'Disabled'}`);
    });
  }

  // Update Preferred Installation Enabled Status
  updatePreferredInstallationEnabledStatus(event: Event): void {
    const isEnabled = (event.target as HTMLInputElement).checked;
    this.preferredInstallation = isEnabled;

    if (isEnabled) {
      this.generateInstallationWeeks();
    } else {
      this.installationWeeks = [];
      this.settingService.updateProposalSettings({ preferredInstallation: isEnabled }).subscribe(() => {
        this.alertService.showSuccessAlert(`Preferred Installation ${(isEnabled) ? 'Enabled' : 'Disabled'}`);
      });
    }


  }

  // Update Preferred Removal Enabled Status
  updatePreferredRemovalEnabledStatus(event: Event): void {
    const isEnabled = (event.target as HTMLInputElement).checked;
    this.settingService.updateProposalSettings({ preferredRemoval: isEnabled }).subscribe(() => {
      this.alertService.showSuccessAlert(`Preferred Removal ${(isEnabled) ? 'Enabled' : 'Disabled'}`);
    });
  }

  // Generate Installation Weeks
  private generateInstallationWeeks(): void {
    const startDate = dayjs(`${dayjs().year()}-08-01`).startOf('week');
    for (var i = 0; i < 22; i++) {
      const date = dayjs(startDate).add(i, 'week');
      this.installationWeeks.push({
        date: date.format('YYYY-MM-DD'),
        label: `Week of ${date.format('MMMM D, YYYY')}`
      });
    }
  }

  /* ----- Helper Functions ----- */

  // Bold Selection
  // boldSelection(): void {
  //   const selection = window.getSelection();
  //   const strongElement = `<strong>${selection.toString()}</strong>`;
  //   if (selection.anchorNode.parentElement.nodeName == 'STRONG') {
  //     selection.anchorNode.parentElement.parentElement.innerHTML = selection.anchorNode.parentElement.parentElement.innerHTML.replace(strongElement, selection.toString());
  //   } else {
  //     selection.anchorNode.parentElement.innerHTML = selection.anchorNode.parentElement.innerHTML.replace(selection.toString(), strongElement);
  //   }
  // }

  // Underline Selection
  // underlineSelection(): void {
  //   const selection = window.getSelection();
  //   const strongElement = `<span style="text-decoration: underline;">${selection.toString()}</span>`;
  //   if (selection.anchorNode.parentElement.nodeName == 'SPAN') {
  //     selection.anchorNode.parentElement.parentElement.innerHTML = selection.anchorNode.parentElement.parentElement.innerHTML.replace(strongElement, selection.toString());
  //   } else {
  //     selection.anchorNode.parentElement.innerHTML = selection.anchorNode.parentElement.innerHTML.replace(selection.toString(), strongElement);
  //   }
  // }
}
