<div class="card">
    <div class="card-header">
        <h5>Files ({{projectFiles.length}})</h5>
        <div class="ms-auto">
            <button type="button" class="btn btn-primary" (click)="openMediaLightbox();" [disabled]="!isGalleryAvailable()">
                <fa-icon class="button-icon-left" [icon]="faImages"></fa-icon>
                <span>Gallery</span>
            </button>
            <button type="button" class="btn btn-primary ms-2" (click)="openFileUploadModal();">
                <fa-icon class="button-icon-left" [icon]="faUpload"></fa-icon>
                <span>Upload</span>
            </button>
        </div>
    </div>
    <div class="card-body">
        <ul class="list-group scrollable" style="height: 227px;">
            <li *ngFor="let projectFile of projectFiles" class="list-group-item" [style.cursor]="isFileClickable(projectFile) ? 'pointer': 'default'" (click)="openMediaLightbox(projectFile);">
                <div class="d-flex flex-md-row flex-column">
                    <div>
                        <div><strong>{{projectFile.name}}</strong></div>
                        <div>{{(projectFile.includedOnProposal) ? 'Included on Proposal' : 'Excluded from Proposal'}}</div>
                        <small class="text-muted">{{projectFile.size | formatBytes}}, {{projectFile.createdAt | formatDateTime}}</small>
                    </div>
                    <div class="ms-md-auto justify-content-around d-flex">
                        <button type="button" class="btn btn-info my-auto" (click)="$event.stopPropagation(); updateProjectFileIncludedOnProposal(projectFile);" [ngbTooltip]="(projectFile.includedOnProposal) ? 'Exclude from Proposal' : 'Include on Proposal'">
                            <fa-icon [icon]="(projectFile.includedOnProposal) ? faEyeSlash : faEye"></fa-icon>
                        </button>
                        <button type="button" class="btn btn-danger my-auto ms-2" (click)="$event.stopPropagation(); openDeleteFileConfirmationModal(projectFile.id);" ngbTooltip="Delete">
                            <fa-icon [icon]="faTrashCan"></fa-icon>
                        </button>
                        <button type="button" class="btn btn-primary my-auto ms-2" [ngbPopover]="additionalProjectFileOptionsPopover" triggers="manual" autoClose="outside" #additionalProjectFileOptions="ngbPopover" (click)="$event.stopPropagation(); openAdditionalProjectFileOptions(additionalProjectFileOptions, projectFile);">
                            <fa-icon [icon]="faEllipsis" size="lg"></fa-icon>
                        </button>
                    </div>
                </div>
            </li>
            <li *ngIf="projectFiles.length == 0" class="list-group-item">No Files</li>
        </ul>
    </div>
</div>

<!-- Additional Project File Options Popover -->
<ng-template #additionalProjectFileOptionsPopover let-popover="popover" let-projectFile="projectFile">
    <div class="d-flex flex-column">
        <button type="button" class="btn btn-sm btn-primary" (click)="popover.close(); openRenameProjectFileModal(renameProjectFileModal, projectFile);">
            <fa-icon [icon]="faPencil" class="button-icon-left"></fa-icon>
            <span>Rename</span>
        </button>
        <button type="button" class="btn btn-sm btn-primary mt-2" (click)="addMockup(projectFile);" [disabled]="!isImage(projectFile)">
            <fa-icon [icon]="faPlus" class="button-icon-left"></fa-icon>
            <span>New Mockup</span>
        </button>
        <button type="button" class="btn btn-sm btn-primary mt-2" (click)="downloadFile(projectFile);">
            <fa-icon [icon]="faDownload" class="button-icon-left"></fa-icon>
            <span>Download</span>
        </button>
    </div>
</ng-template>

<!-- Rename Project File Modal -->
<ng-template #renameProjectFileModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Rename</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="projectFileForm">
            <div>
                <label for="PROJECT_FILE_NAME">Name</label>
                <input id="PROJECT_FILE_NAME" type="text" class="form-control" formControlName="name">
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button ngbAutofocus type="button" class="btn btn-primary" (click)="updateProjectFileName();">Save</button>
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
    </div>
</ng-template>

<!-- For Project Pdf File Modal -->
<ng-template #projectFilePdf let-modal>
  <div class="modal-header">
      <h5 class="modal-title">{{selectedPdfProjectFile.name}}</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
  </div>
  <div class="modal-body">
    <div class="iframe-container container">
      <iframe class="responsive-iframe" [src]="sanitizer.bypassSecurityTrustResourceUrl(selectedPdfProjectFile.presignedUrl)"></iframe>
    </div>
  </div>
  <div class="modal-footer">

  </div>
</ng-template>
