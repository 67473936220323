import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from '../../alert.service';
import { SmsCallService } from '../sms-call.service';


@Component({
  selector: 'app-business-identity-form',
  templateUrl: './business-identity-form.component.html',
  styleUrls: ['./business-identity-form.component.css']
})
export class BusinessIdentityFormComponent implements OnInit {

  businessIdentityForm!: FormGroup;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  public businessTypeValues: any[] = [
    {
      "name": "Co-operative",
      "value": "Co-operative"
    },
    {
      "name": "Corporation",
      "value": "Corporation"
    },
    {
      "name": "Limited Liability",
      "value": "Limited Liability"
    },
    {
      "name": "Non-profit Corporation",
      "value": "Non-profit Corporation"
    },
    {
      "name": "Partnership",
      "value": "Partnership"
    }
  ]

  public businessIndustryValues: any[] = [
    {
      "name": "agriculture",
      "value": "AGRICULTURE"
    },
    {
      "name": "automotive",
      "value": "AUTOMOTIVE"
    },
    {
      "name": "banking",
      "value": "BANKING"
    },
    {
      "name": "construction",
      "value": "CONSTRUCTION"
    },
    {
      "name": "consumer",
      "value": "CONSUMER"
    },
    {
      "name": "education",
      "value": "EDUCATION"
    },
    {
      "name": "electronics",
      "value": "ELECTRONICS"
    },
    {
      "name": "engineering",
      "value": "ENGINEERING"
    },
    {
      "name": "energy",
      "value": "ENERGY"
    },
    {
      "name": "fast_moving_consumer_goods",
      "value": "FAST_MOVING_CONSUMER_GOODS"
    },
    {
      "name": "financial",
      "value": "FINANCIAL"
    },
    {
      "name": "fintech",
      "value": "FINTECH"
    },
    {
      "name": "food_and_beverage",
      "value": "FOOD_AND_BEVERAGE"
    },
    {
      "name": "government",
      "value": "GOVERNMENT"
    },
    {
      "name": "healthcare",
      "value": "HEALTHCARE"
    },
    {
      "name": "hospitality",
      "value": "HOSPITALITY"
    },
    {
      "name": "insurance",
      "value": "INSURANCE"
    },
    {
      "name": "jewelry",
      "value": "JEWELRY"
    },
    {
      "name": "legal",
      "value": "LEGAL"
    },
    {
      "name": "manufacturing",
      "value": "MANUFACTURING"
    },
    {
      "name": "media",
      "value": "MEDIA"
    },
    {
      "name": "not_for_profit",
      "value": "NOT_FOR_PROFIT"
    },
    {
      "name": "oil_and_gas",
      "value": "OIL_AND_GAS"
    },
    {
      "name": "online",
      "value": "ONLINE"
    },
    {
      "name": "professional_services",
      "value": "PROFESSIONAL_SERVICES"
    },
    {
      "name": "raw_materials",
      "value": "RAW_MATERIALS"
    },
    {
      "name": "real_estate",
      "value": "REAL_ESTATE"
    },
    {
      "name": "religion",
      "value": "RELIGION"
    },
    {
      "name": "retail",
      "value": "RETAIL"
    },
    {
      "name": "technology",
      "value": "TECHNOLOGY"
    },
    {
      "name": "telecommunications",
      "value": "TELECOMMUNICATIONS"
    },
    {
      "name": "transportation",
      "value": "TRANSPORTATION"
    },
    {
      "name": "travel",
      "value": "TRAVEL"
    }

  ]

  public businessRegistrationIdentifierValues: any[] = [
    {
      "name": "EIN",
      "value": "EIN"
    },
    {
      "name": "DUNS",
      "value": "DUNS"
    },
    {
      "name": "CBN",
      "value": "CBN"
    },
    {
      "name": "CN",
      "value": "CN"
    },
    {
      "name": "ACN",
      "value": "ACN"
    },
    {
      "name": "CIN",
      "value": "CIN"
    },
    {
      "name": "VAT",
      "value": "VAT"
    },
    {
      "name": "VATRN",
      "value": "VATRN"
    },
    {
      "name": "RN",
      "value": "RN"
    },
    {
      "name": "Other",
      "value": "Other"
    }
  ]

  faQuestionCircle = faQuestionCircle;


  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private smsCallService: SmsCallService) { }

  ngOnInit(): void {
    this.businessIdentityForm = this.formBuilder.group({
      business_industry: ['', Validators.required],
      business_name: ['', Validators.required],
      business_registration_identifier: ['', Validators.required],
      business_registration_number: ['', Validators.required],
      business_type: ['', Validators.required],
      social_media_profile_urls: [''],
      website_url: ['']
    });
  }

  submitForm() {
    if (this.businessIdentityForm.valid) {

      let data = { ...this.businessIdentityForm.value }

      this.smsCallService.postBusinessInfo(data).subscribe(() => {
        this.alertService.showSuccessAlert('Business details saved!');
      });
    }
  }

  closeModal() {
    this.close.emit("true")
  }

}
