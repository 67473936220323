import { Component, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { ScheduleItem } from '../schedule-item';
import { ScheduleService } from '../schedule.service';
import { faCircle, faSortUp, faSortDown, faSortAmountDownAlt, faFilter, faArrowCircleDown, faArrowCircleUp, faCalendarDay, faTools } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard-up-next',
  templateUrl: './dashboard-up-next.component.html',
  styleUrls: ['./dashboard-up-next.component.css']
})
export class DashboardUpNextComponent implements OnInit {

  // Properties
  searchTerm: string = null;
  sortBy: string = 'sc.schedule_item_position';
  sortDirection: string = 'ASC';
  limit: number = null;
  offset: number = null;
  startDate: string;
  endDate: string;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;

  // Schedule Items
  scheduleItems: ScheduleItem[] = [];

  // Font Awesome Properties
  faCircle = faCircle;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faSortAmountDownAlt = faSortAmountDownAlt;
  faFilter = faFilter;

  constructor(private scheduleService: ScheduleService) { }

  ngOnInit(): void {
    const now = dayjs();
    this.startDate = now.format('M/D/YYYY');
    this.endDate = now.add(1, 'week').format('M/D/YYYY');
    this.getUpNext();
  }

  // Get Up Next
  private getUpNext(): void {
    const now = dayjs();
    const startDate = now.format('YYYY-MM-DD');
    const endDate = now.add(1, 'week').format('YYYY-MM-DD');
    this.scheduleService.getUpNext(startDate, endDate).subscribe((scheduleItems) => {
      this.scheduleItems = scheduleItems;
    });
  }

  /* ----- Helper Functions ----- */

  // Get Schedule Item Type Icon
  getScheduleItemTypeIcon(type: string) {
    switch (type) {
      case 'INSTALL':
        return faArrowCircleUp;
      case 'MAINTENANCE':
        return faTools;
      case 'REMOVE':
        return faArrowCircleDown;
      case 'CUSTOM':
        return faCalendarDay;
    }
  }

  getCrewsNames(crews) {
    let crewsNames = crews.map(crew => crew?.crewName);
    return crewsNames.join(", ")
  }
}
