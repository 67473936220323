<div class="modal-header">
    <h5 class="modal-title">{{(proposal.status == 'PENDING') ? 'Accept' : 'View Accepted'}} Proposal</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="followUpQuestionsForm">
        <!-- Single Pricing -->
        <div *ngIf="proposal && proposal.agreementType == 'SINGLE' && proposalPricing" class="mb-3">
            <label>Pricing</label>
            <div class="card">
                <div class="card-body" style="padding: 0.5rem 1rem;">
                    <div class="d-flex list-group-container">
                        <div class="my-auto">
                            <strong>Single</strong>
                        </div>
                        <div class="ms-auto d-flex flex-column">
                            <small class="text-muted"><strong>Subtotal</strong></small>
                            <!-- <small *ngIf="proposalPricing.discount > 0" class="text-muted"><strong>Discount</strong></small> -->
                            <small class="text-muted"><strong>Tax</strong></small>
                            <small class="text-muted"><strong>Total</strong></small>
                        </div>
                        <div *ngIf="proposalPricing" class="d-flex flex-column ms-1 text-end" style="min-width: 84px;">
                            <small class="text-muted">{{proposalPricing?.subtotal | currency}}</small>
                            <!-- <small *ngIf="proposalPricing.discount > 0 && proposalPricing.discountType == 'PERCENT'" class="text-muted">{{proposalPricing.discount}}%</small> -->
                            <!-- <small *ngIf="proposalPricing.discount > 0 && proposalPricing.discountType == 'DOLLAR'" class="text-muted">{{proposalPricing.discount | currency}}</small> -->
                            <small class="text-muted">{{proposalPricing?.tax | currency}}</small>
                            <small class="text-muted">{{proposalPricing?.total | currency}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Multi Pricing -->
        <div *ngIf="proposal && proposal.agreementType == 'MULTI' && proposalPricing" class="mb-3">
            <label>Pricing</label>
            <ul class="list-group">
                <ng-container *ngFor="let key of multiOptionKeys">
                    <li *ngIf="multiOptionIsEnabled(key);" class="list-group-item">
                        <div class="d-flex">
                            <div class="form-check my-auto">
                                <input type="radio" class="form-check-input" formControlName="selectedMultiOption"
                                    [value]="key">
                                <label class="form-check-label">{{proposal.multiOptions[key]?.acronym}}</label>
                            </div>
                            <div class="ms-auto d-flex flex-column">
                                <small class="text-muted"><strong>Subtotal</strong></small>
                                <!-- <small *ngIf="proposalPricing.discount > 0" class="text-muted"><strong>Discount</strong></small> -->
                                <small class="text-muted"><strong>Tax</strong></small>
                                <small class="text-muted"><strong>Total</strong></small>
                            </div>
                            <div *ngIf="proposalPricing" class="d-flex flex-column ms-1 text-end"
                                style="min-width: 84px;">
                                <small class="text-muted">{{proposalPricing.multiPricing[key]?.subtotal |
                                    currency}}</small>
                                <!-- <small *ngIf="proposalPricing.discount > 0 && proposalPricing.discountType == 'PERCENT'" class="text-muted">{{proposalPricing.discount}}%</small> -->
                                <!-- <small *ngIf="proposalPricing.discount > 0 && proposalPricing.discountType == 'DOLLAR'" class="text-muted">{{proposalPricing.discount | currency}}</small> -->
                                <small class="text-muted">{{proposalPricing.multiPricing[key]?.tax | currency}}</small>
                                <small class="text-muted">{{proposalPricing.multiPricing[key]?.total | currency}}</small>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>
        <!-- Preferred Installation Week -->
        <div class="mb-3"
            *ngIf="proposal.status == 'PENDING' ? preferredInstallation: acceptedProposal && acceptedProposal.installationWeek != null">
            <label for="INSTALL_WEEK" class="control-label">Preferred Installation Week</label>
            <select id="INSTALL_WEEK" class="form-select" formControlName="installationWeek">
                <ng-container *ngIf="acceptedProposal && acceptedProposal.installationWeek != null; else options">
                    <option [value]="acceptedProposal.installationWeek">{{acceptedProposal.installationWeek}}</option>
                </ng-container>
                <ng-template #options>
                    <option value="NONE">No Preference</option>
                    <option *ngFor="let installationWeek of preferredInstallationOptions"
                        [value]="installationWeek.date">
                        {{installationWeek.label}}</option>
                </ng-template>
            </select>
        </div>
        <!-- Preferred Removal Type -->
        <div class="mb-3"
            *ngIf="proposal.status == 'PENDING' ? preferredRemoval: acceptedProposal && acceptedProposal.removalType != null">
            <label for="REMOVAL_TYPE" class="control-label">Preferred Removal Type</label>
            <select id="REMOVAL_TYPE" class="form-select" formControlName="removalType">
                <option value="NONE">No Preference</option>
                <option value="EARLY">Early Removal (January 1-7)</option>
                <option value="REGULAR">Regular Removal (January 7-15)</option>
                <option value="LATE">Late Removal (January 15-30)</option>
            </select>
        </div>
        <!-- Additional Information -->
        <div class="mb-3">
            <label for="ADDITIONAL_INFORMATION">Additional Information</label>
            <textarea id="ADDITIONAL_INFORMATION" class="form-control" formControlName="additionalInfo"
                placeholder="Gate Entry Code is 12345" rows="3" maxlength="65535"></textarea>
            <div *ngIf="additionalInfo.invalid && (additionalInfo.dirty || additionalInfo.touched)">
                <div *ngIf="additionalInfo.errors.maxlength" class="input-validation-msg">Additional Info cannot exceed
                    65535 characters.</div>
            </div>
        </div>
        <!-- Signature -->
        <div *ngIf="signature" class="mb-3">
            <div class="d-flex flex-column">
                <label>Signature</label>
                <img [src]="signature" style="border: 1px solid #CED4DA; border-radius: 0.375rem;">
            </div>
        </div>
        <!-- Method -->
        <div>
            <label for="METHOD">Method</label>
            <input id="METHOD" type="text" class="form-control" formControlName="method" placeholder="View Proposal"
                maxlength="150">
            <div *ngIf="method.invalid && (method.dirty || method.touched)">
                <div *ngIf="method.errors.maxlength" class="input-validation-msg">Method cannot exceed 150 characters.
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button *ngIf="proposal.status == 'PENDING'" type="button" class="btn btn-primary"
        (click)="acceptProposal();">Accept</button>
    <button *ngIf="proposal.status != 'PENDING'" type="button" class="btn btn-primary"
        (click)="updateAcceptedProposal();">Save</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">{{(proposal.status == 'PENDING') ?
        'Cancel' : 'Close'}}</button>
</div>
