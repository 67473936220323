import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { Prospect } from '../prospect';
import { ProspectService } from '../prospect.service';

@Component({
  selector: 'app-prospect-modal',
  templateUrl: './prospect-modal.component.html',
  styleUrls: ['./prospect-modal.component.css']
})
export class ProspectModalComponent implements OnInit {

  // Properties
  @Input() prospect: Prospect;
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('REJECT_PROSPECT_CONFIRMATION_MODAL', { static: false }) private rejectProspectConfirmationModal: NgbModalRef;

  constructor(private prospectService: ProspectService,
    private router: Router,
    private modalService: NgbModal,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {

  }

  // Open Reject Prospect Confirmation Modal
  openRejectProspectConfirmationModal(): void {
    this.modalService.open(this.rejectProspectConfirmationModal);
  }

  // Open Delete Prospect Confirmation Modal
  openDeleteProspectConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this prospect?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteProspect();
    });
  }

  // Accept Prospect
  acceptProspect(waitList: boolean): void {
    this.prospectService.acceptProspect(this.prospect.id, waitList).subscribe((clientId) => {
      this.router.navigateByUrl(`/clients/${clientId}`);
    });
  }

  // Reject Prospect
  rejectProspect(): void {
    const rejectReason = (document.getElementById('REJECT_REASON') as HTMLTextAreaElement).value;
    this.prospectService.rejectProspect(this.prospect.id, rejectReason).subscribe(() => {
      this.saved.emit();
      this.modalService.dismissAll();
    });
  }

  // Delete Prospect
  deleteProspect(): void {
    this.prospectService.deleteProspect(this.prospect.id).subscribe(() => {
      this.saved.emit();
      this.modalService.dismissAll();
    });
  }
}
