import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmailLog } from './email-log';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private http: HttpClient) { }

  // Get Email Logs
  getEmailLogs(startDate: string, endDate: string): Observable<EmailLog[]> {
    return this.http.get<EmailLog[]>(`/logs/emails`, {
      params: {
        startDate: startDate,
        endDate: endDate
      }
    });
  }

  // Get Filtered Email Logs
  getFilteredEmailLogs(type: string, referenceId: string): Observable<EmailLog[]> {
    return this.http.get<EmailLog[]>(`/logs/emails/filtered`, {
      params: {
        type: type,
        referenceId: referenceId
      }
    });
  }
}
