<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom"
                class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">Sales Tax (Paid)</h2>
        </div>
        <div class="row">
            <div class="col-auto d-flex">
                <select *ngIf="years.length > 0" class="form-select ms-2" style="width: fit-content;"
                    (change)="yearSelected($event.target.value);">
                    <option *ngFor="let year of years" [value]="year" [selected]="year == selectedYear">{{year}}
                    </option>
                </select>
                <button type="button" class="btn btn-primary ms-2" (click)="exportAll();">
                    <fa-icon [icon]="faFileDownload"></fa-icon><span class="ms-2 d-none d-md-inline">Export All</span>
                </button>
            </div>
        </div>
    </nav>
    <div class="row mt-3">
        <div class="card p-0">
            <div class="card-body">
                <h5 class="card-title">Description</h5>
                <p class="card-text m-0">This report can be used to review the paid sales tax for the year. A payment is
                    included in this report if the payment date falls within selected year.</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div *ngIf="salesTaxMap" class="col-xl-4 mt-3">
            <div class="card h-100">
                <div class="card-header d-flex">
                    <h5>Year</h5>
                    <div class="d-flex flex-column ms-auto text-end" style="font-weight: 500;">

                        <button type="button" class="btn btn-primary" (click)="exportReport('year');">
                            <fa-icon [icon]="faFileDownload"></fa-icon><span
                                class="ms-2 d-none d-md-inline">Export</span>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <ul class="list-group scrollable" style="height: 261px;">
                        <li *ngFor="let entry of salesTaxMap | keyvalue" class="list-group-item d-flex">
                            <p class="my-auto"><strong>{{entry.value.name}}</strong> ({{entry.key | percent: '1.0-3'}})
                            </p>
                            <div class="d-flex flex-column ms-auto text-end">
                                <p class="mb-0">{{entry.value.tax | currency}} (Tax)</p>
                                <p class="mb-0">{{entry.value.amount | currency}} (Net Payments)</p>
                            </div>
                        </li>
                        <li *ngIf="salesTaxMap.size == 0" class="list-group-item">
                            <p class="mb-0">No Data Available</p>
                        </li>
                        <li class="list-group-item d-flex text-white bg-green">
                            <p class="my-auto"><strong>TOTAL</strong>
                            </p>
                            <div class="d-flex flex-column ms-auto text-end">
                                <p class="mb-0">{{salesTaxTotal | currency}} (Tax)</p>
                                <p class="mb-0">{{salesAmountTotal | currency}} (Net Payments)</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div *ngIf="quarterlySalesTaxMap" class="col-xl-8 mt-3">
            <div class="card">
                <div class="card-header d-flex">
                    <h5>Quarterly</h5>
                    <div class="d-flex flex-column ms-auto text-end" style="font-weight: 500;">
                        <button type="button" class="btn btn-primary" (click)="exportReport('quarter');">
                            <fa-icon [icon]="faFileDownload"></fa-icon><span
                                class="ms-2 d-none d-md-inline">Export</span>
                        </button>
                        <!-- <p class="mb-0">{{salesTaxTotal | currency}} (Tax)</p>
                            <p class="mb-0">{{salesAmountTotal | currency}} (Net Payments)</p> -->
                    </div>
                </div>
                <div class="card-body">
                    <table>
                        <tr>
                            <th></th>
                            <th *ngFor="let qtr of QUARTERS" colspan="2">
                                {{ qtr }}
                            </th>
                        </tr>
                        <tr>
                            <td></td>
                            <ng-container *ngFor="let arr of quarterMap; let i = index">
                                <td class="tax">Tax</td>
                                <td class="amount">Net</td>
                            </ng-container>
                        </tr>
                        <tr *ngFor="let label of labels">
                            <td> {{ label }}</td>
                            <ng-container *ngFor="let arr of quarterMap; let i = index">
                                <td>{{ filterMultiArrByLabelAndKey(arr, label, 'tax','quarter', i+1) }}</td>
                                <td>{{ filterMultiArrByLabelAndKey(arr, label, 'amount','quarter', i+1) }}
                                </td>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>TOTAL</th>
                            <ng-container *ngFor="let arr of quarterMap; let i = index">
                                <td class="tax">{{ getSalesTotal(quarterlySalesTaxMap, i+1, 'tax') }} </td>
                                <td class="amount">{{ getSalesTotal(quarterlySalesTaxMap, i+1, 'amount') }}
                                </td>
                            </ng-container>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="monthlySalesTaxMap">
            <div class="col-xl-12 mt-3">
                <div class="card">
                    <div class="card-header d-flex">
                        <h5>Monthly</h5>
                        <div class="d-flex flex-column ms-auto text-end" style="font-weight: 500;">
                            <button type="button" class="btn btn-primary" (click)="exportReport('month');">
                                <fa-icon [icon]="faFileDownload"></fa-icon><span
                                    class="ms-2 d-none d-md-inline">Export</span>
                            </button>
                            <!-- <p class="mb-0">{{salesTaxTotal | currency}} (Tax)</p>
                            <p class="mb-0">{{salesAmountTotal | currency}} (Net Payments)</p> -->
                        </div>
                    </div>
                    <div class="card-body">
                        <table>
                            <tr>
                                <th></th>
                                <th *ngFor="let mon of MONTHS" colspan="2">
                                    {{ mon.substring(0,3) }}
                                </th>
                            </tr>
                            <tr>
                                <td></td>
                                <ng-container *ngFor="let arr of monthlyMap; let i = index">
                                    <td class="tax">Tax</td>
                                    <td class="amount">Net</td>
                                </ng-container>
                            </tr>
                            <tr *ngFor="let label of labels">
                                <td> {{ label }}</td>
                                <ng-container *ngFor="let arr of monthlyMap; let i = index">
                                    <td>{{ filterMultiArrByLabelAndKey(arr, label, 'tax','month', i+1) }}
                                    </td>
                                    <td>{{ filterMultiArrByLabelAndKey(arr, label, 'amount','month', i+1) }}
                                    </td>
                                </ng-container>
                            </tr>
                            <tr>
                                <th>TOTAL</th>
                                <ng-container *ngFor="let arr of monthlyMap; let i = index">
                                    <td class="tax">{{ getSalesTotal(monthlySalesTaxMap, i+1, 'tax') }} </td>
                                    <td class="amount">{{ getSalesTotal(monthlySalesTaxMap, i+1, 'amount') }}
                                    </td>
                                </ng-container>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>