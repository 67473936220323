import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from '../client';
import { Contact } from '../contact';
import { Project } from '../project';
import { Invoice } from '../invoice';
import { InvoiceService } from '../invoice.service';
import { faPrint, faCreditCard, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { AcceptedProposal } from '../accepted-proposal';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SafeUrl } from '@angular/platform-browser';
import { Payment } from '../payment';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { Address } from '../address';
import { SettingService } from '../setting.service';
import { Site } from '../site';

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.css']
})
export class InvoiceViewComponent implements OnInit {

  // Modals
  private modalReference: NgbModalRef;

  // Properties
  invoiceLoaded: boolean = false;
  logoUrl: string;
  invoiceId: string;
  invoice: Invoice;
  payments: Payment[] = [];
  totalCreditAmount: number = 0;
  acceptedProposal: AcceptedProposal;
  project: Project;
  site: Site;
  client: Client;
  contacts: Contact[] = [];
  primaryContact: Contact;
  invoiceTerms: string;
  businessAttributes: { name: string, email: string, phone: string, address: Address, displayAddress: boolean };

  // Tinsel Pay
  tinselPayCardPaymentIsEnabled: boolean = false;
  tinselPayAchPaymentIsEnabled: boolean = false;

  // Square
  squarePaymentIsEnabled: boolean = false;

  // Venmo
  venmoPaymentIsEnabled: boolean = false;
  venmoProfileUrl: string;
  venmoCodeExists: boolean = false;
  venmoCodeUrl: string | SafeUrl;

  // Cash
  cashPaymentIsEnabled: boolean = false;
  cashMessage: string;

  // Check
  checkPaymentIsEnabled: boolean = false;
  checkMessage: string;

  // Font Awesome Properties
  faPrint = faPrint;
  faCreditCard = faCreditCard;
  faCircleNotch = faCircleNotch;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private invoiceService: InvoiceService,
    private settingService: SettingService) { }

  ngOnInit(): void {
    this.invoiceId = this.route.snapshot.paramMap.get('invoiceId');
    this.printInvoice();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Modal
  openModal(content: any): void {
    this.modalReference = this.modalService.open(content);
  }

  // Print Invoice
  private printInvoice(): void {
    this.invoiceService.printInvoice(this.invoiceId).pipe(catchError((error: any) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 404) this.router.navigateByUrl('/404', { replaceUrl: true });
      }
      return throwError(() => new Error('API Request Error'));
    })).subscribe((res) => {
      this.logoUrl = (res.organizationLogoExists) ? this.settingService.getOrganizationLogoUrl(res.invoice.organizationId) : '../assets/TinselLogo.png'
      this.invoice = res.invoice;
      this.payments = res.payments;
      this.totalCreditAmount = this.payments.reduce((acc, payment) => { return acc + ((payment.type == 'CREDIT') ? payment.amount : 0); }, 0);
      this.acceptedProposal = res.acceptedProposal;
      this.project = res.project;
      this.site = this.project.site;
      this.client = res.client;
      this.primaryContact = res.primaryContact;
      if (res.invoiceTerms) document.getElementById('INVOICE_TERMS').innerHTML = res.invoiceTerms;
      this.businessAttributes = res.businessAttributes;
      // Tinsel Pay
      this.tinselPayCardPaymentIsEnabled = res.paymentSettings.tinselPay.card.isEnabled;
      this.tinselPayAchPaymentIsEnabled = res.paymentSettings.tinselPay.ach.isEnabled;
      // Square
      this.squarePaymentIsEnabled = res.paymentSettings.square.isEnabled;
      // Venmo
      this.venmoPaymentIsEnabled = res.paymentSettings.venmo.isEnabled;
      this.venmoProfileUrl = res.paymentSettings.venmo.url;
      this.venmoCodeExists = res.paymentSettings.venmo.qrCodeExists;
      this.venmoCodeUrl = this.settingService.getVenmoCodeUrl(this.invoice.organizationId);
      // Payment Settings
      this.getPaymentSettings();
      this.invoiceLoaded = true;
    });
  }

  // Get Payment Settings
  private async getPaymentSettings(): Promise<void> {
    // Cash
    const cashSettings = await lastValueFrom(this.settingService.getCashSettings(this.invoice.organizationId));
    this.cashPaymentIsEnabled = cashSettings.isEnabled;
    this.cashMessage = cashSettings.message;
    // Check
    const checkSettings = await lastValueFrom(this.settingService.getCheckSettings(this.invoice.organizationId));
    this.checkPaymentIsEnabled = checkSettings.isEnabled;
    this.checkMessage = checkSettings.message;
  }

  // Payment Successful
  paymentSuccessful(): void {
    this.modalReference.close();
    this.printInvoice();
  }

  // Has Enabled Payment Option
  hasEnabledPaymentOption(): boolean {
    return this.tinselPayCardPaymentIsEnabled ||
      this.tinselPayAchPaymentIsEnabled ||
      this.squarePaymentIsEnabled ||
      this.venmoPaymentIsEnabled ||
      this.cashPaymentIsEnabled ||
      this.checkPaymentIsEnabled;
  }

  // Print
  print(): void {
    setTimeout(() => {
      window.print();
    }, 500);
  }
}
