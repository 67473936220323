<div class="row">
    <div class="col-auto ms-auto">
        <app-date-picker-controller componentId="TinselPayOverview.ACH" preset="CUR_WK" [showButtons]="true" (dateChanged)="dateChanged($event);" [onLoad]="true"></app-date-picker-controller>
    </div>
</div>
<div class="table-responsive mt-2">
    <table *ngIf="!isMobile">
        <thead>
            <tr>
                <th>Transaction ID</th>
                <th>Date</th>
                <th>Type</th>
                <th>Customer</th>
                <th>Amount</th>
                <th>Status</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let transaction of transactions" (click)="selectTransaction(transaction);">
                <td data-label="Transaction ID">{{transaction.transactionId}}</td>
                <td data-label="Date">{{formatDateTime(transaction.transactionDateTime)}}</td>
                <td data-label="Type">{{transaction.transactionType}}</td>
                <td data-label="Customer">{{transaction.name}}</td>
                <td data-label="Amount">{{transaction.amount | currency}}</td>
                <td data-label="Status">{{transaction.status}}</td>
            </tr>
        </tbody>
    </table>
    <div class="scrollable" *ngIf="isMobile">
      <ul class="list-group">
          <li *ngFor="let transaction of transactions" class="list-group-item" style="cursor: pointer;" (click)="selectTransaction(transaction);">
              <div class="d-flex list-group-container">
                  <div>
                      <strong>{{transaction.transactionId}}</strong>
                      <div><strong>Date: </strong>{{formatDateTime(transaction.transactionDateTime)}}</div>
                      <div><strong>Type: </strong>{{transaction.transactionType}}</div>
                      <div>{{transaction.name}}</div>
                      <div><strong>Amount: </strong>{{transaction.amount | currency}}</div>
                      <div><strong>Status: </strong>{{transaction.status}}</div>
                  </div>
              </div>
          </li>
          <li *ngIf="clients.length == 0" class="list-group-item">No Transactions</li>
      </ul>
    </div>
</div>

<!-- Transaction Details Modal -->
<ng-template #TRANSACTION_DETAILS_MODAL let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Transaction Details</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
    </div>
    <div class="modal-body">
        <ul class="list-group">
            <li class="list-group-item d-flex">
                <strong>Account Type</strong>
                <div class="ms-auto">{{selectedTransaction.accountType}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Amount</strong>
                <div class="ms-auto">{{selectedTransaction.amount | currency}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Close Date</strong>
                <div class="ms-auto">{{formatDateTime(selectedTransaction.closeDate)}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Created On</strong>
                <div class="ms-auto">{{formatDateTime(selectedTransaction.createdOn)}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Masked Account Number</strong>
                <div class="ms-auto">{{selectedTransaction.maskedAccountNumber}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Modified On</strong>
                <div class="ms-auto">{{formatDateTime(selectedTransaction.modifiedOn)}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Nacha Transaction Code</strong>
                <div class="ms-auto">{{selectedTransaction.nachaTransactionCode}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Name</strong>
                <div class="ms-auto">{{selectedTransaction.name}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Payment Related Info</strong>
                <div class="ms-auto">{{selectedTransaction.paymentRelatedInfo}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Reason Code</strong>
                <div class="ms-auto">{{selectedTransaction.reasonCode}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Reference ID</strong>
                <div class="ms-auto">{{selectedTransaction.referenceId}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Rejection Date</strong>
                <div class="ms-auto">{{formatDateTime(selectedTransaction.rejectionDate)}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Routing Number</strong>
                <div class="ms-auto">{{selectedTransaction.routingNumber}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>SEC Code</strong>
                <div class="ms-auto">{{selectedTransaction.secCode}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Settle Date</strong>
                <div class="ms-auto">{{formatDateTime(selectedTransaction.settleDate)}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Status</strong>
                <div class="ms-auto">{{selectedTransaction.status}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Transaction Date</strong>
                <div class="ms-auto">{{formatDateTime(selectedTransaction.transactionDateTime)}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Transaction ID</strong>
                <div class="ms-auto">{{selectedTransaction.transactionId}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Transaction Source</strong>
                <div class="ms-auto">{{selectedTransaction.transactionSoucre}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Transaction Type</strong>
                <div class="ms-auto">{{selectedTransaction.transactionType}}</div>
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
    </div>
</ng-template>

<!-- LOADER -->
<ngx-spinner>
  <p class="loading-spinner">Loading...</p>
</ngx-spinner>
