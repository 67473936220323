import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { faAngleLeft, faAngleRight, faCalendar, faFileDownload, faRotateLeft, faSortDown, faSortUp, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { ReportService } from '../report.service';
import { TableControlService } from '../table-control.service';
import { Subject, debounceTime } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reports-last-proposal-accepted-date',
  templateUrl: './reports-last-proposal-accepted-date.component.html',
  styleUrls: ['./reports-last-proposal-accepted-date.component.css']
})
export class ReportsLastProposalAcceptedDateComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Properties
  entries: any[] = [];
  entryCount: number = 0;
  isMobileView: boolean = false;

  sortOptions = [
    { label: 'Client', value: 'c.client_name' },
    { label: 'Project', value: 'proj.project_name' },
    { label: 'Accepted On', value: 'ap.accepted_proposal_created_at' },
  ];
  sortBy: string = 'c.client_name';
  sortDirection: string = 'ASC';

  // Table Controls
  tableControls: TableControlService;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;
  faCalendar = faCalendar;
  faFileDownload = faFileDownload;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faRotateLeft = faRotateLeft;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private reportService: ReportService,
    public location: Location,
    private modalService: NgbModal) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
          this.tableControls.setLimit(20);
        }
      });
    }

  ngOnInit(): void {
    this.tableControls = new TableControlService('Reports.LastProposalAcceptedDate', true, 'c.client_name', 'ASC');
    this.tableControls.refresh.subscribe(() => {
      this.getLastProposalAcceptedDateReport();
    });
    this.getLastProposalAcceptedDateReport();
    this.checkScreenSize();
  }

  // Get Last Proposal Accepted Date Report
  private getLastProposalAcceptedDateReport(): void {
    let params = this.tableControls.getParams();
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
    };
    this.reportService.getLastProposalAcceptedDateReport(params).subscribe((res) => {
      this.entries = res.entries;
      this.entryCount = res.count;
    });
  }

  // Download Report
  downloadReport(): void {
    const url = new URL('/api/reports/clients/lastProposalAcceptedDate/download', environment.apiUrl);
    window.open(url.toString());
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
    this.isMobileView ? this.tableControls.setLimit(5): this.tableControls.setLimit(20);
  }

  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getLastProposalAcceptedDateReport();
  }

}
