import { Component, OnInit } from '@angular/core';
import { faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../alert.service';
import { CardOnFile } from '../card-on-file';
import { CardOnFileModalComponent } from '../card-on-file-modal/card-on-file-modal.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { PaymentService } from '../payment.service';
import { SettingService } from '../setting.service';
import { SubscriptionAlertModalComponent } from '../subscription-alert-modal/subscription-alert-modal.component';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-settings-subscriptions-payment-options',
  templateUrl: './settings-subscriptions-payment-options.component.html',
  styleUrls: ['./settings-subscriptions-payment-options.component.css']
})
export class SettingsSubscriptionsPaymentOptionsComponent implements OnInit {

  // Properties
  modalReference: NgbModalRef;
  cardOnFile: CardOnFile;

  // Font Awesome Properties
  faPencil = faPencil;
  faTrashCan = faTrashCan;

  constructor(private settingService: SettingService,
    private paymentService: PaymentService,
    private alertService: AlertService,
    private authService: AuthService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getCardOnFile();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Delete Card On File Confirmation Modal
  openDeleteCardOnFileConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this payment method?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteCardOnFile();
    });
  }

  // Open Card On File Modal
  openCardOnFileModal(): void {
    this.modalReference = this.modalService.open(CardOnFileModalComponent);
    this.modalReference.componentInstance.cardSaved.subscribe(() => {
      this.getCardOnFile();
      let login = JSON.parse(localStorage.getItem("login"))
      if(login?.role === "SUPER" && !login?.isWithCardOnFile){
        this.openModalAlert();
      }
    });
  }

  openModalAlert(){
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size: 'sm'
    };
    const modalReference = this.modalService.open(SubscriptionAlertModalComponent, ngbModalOptions);
    modalReference.componentInstance.type = "Success";
    modalReference.componentInstance.message = "You have successfully updated your card! We need to log you out in order for the application to get the latest details.";
    modalReference.closed.subscribe(data => {
      this.authService.logout();
    })
  }

  // Get Card On File
  private getCardOnFile(): void {
    this.settingService.getCardOnFile().subscribe((cardOnFile) => {
      this.cardOnFile = cardOnFile;
      if (this.cardOnFile) this.cardOnFile.url = this.getCardPaymentIcon(this.cardOnFile.type);
    });
  }

  // Delete Card On File
  private deleteCardOnFile(): void {
    this.settingService.deleteCardOnFile().subscribe(() => {
      this.alertService.showSuccessAlert('Payment Method Deleted');
      this.getCardOnFile();
    });
  }


  /* ----- Helper Functions ----- */

  // Get Card Payment Icon
  private getCardPaymentIcon(brand: string = null): string {
    return this.paymentService.getCardPaymentIcon(brand);
  }
}
