<app-alert></app-alert>
<div *ngIf="!invoiceLoaded" class="loading-overlay d-flex">
  <div class="d-flex flex-column m-auto">
    <h2>Loading Invoice</h2>
    <fa-icon [icon]="faCircleNotch" size="2xl" spin="true" class="mx-auto mt-2"></fa-icon>
  </div>
</div>
<div class="invoice" [class.d-none]="!invoiceLoaded">
  <div class="container">
    <div class="row">
      <div class="col">
        <h2 *ngIf="project" style="font-weight: 600;">{{project.name}}</h2>
        <h2 *ngIf="invoice">Invoice #{{invoice.number}}</h2>
        <button type="button" class="btn btn-primary mt-1 d-print-none" (click)="print();">
          <fa-icon class="button-icon-left" [icon]="faPrint"></fa-icon>
          <span>Print Invoice</span>
        </button>
      </div>
      <div class="col text-md-end d-none d-sm-block">
        <img *ngIf="logoUrl" [src]="logoUrl" width="40%" class="logo" alt="Organization Logo">
      </div>
    </div>
    <!-- Client, Site, and Business Attributes -->
    <div class="row mt-2">
      <div class="col pe-3">
        <div class="row">
          <div class="col-md-8">
            <div *ngIf="client">
              <h5>{{client.name}} (Billing)</h5>
              <p class="mb-0">{{client.address.street}}</p>
              <p class="mb-0">{{client.address.city}}, {{client.address.state}} {{client.address.postalCode}}</p>
            </div>
            <div *ngIf="primaryContact" class="d-block text-break">
              <a href="mailto:{{primaryContact.email}}">{{primaryContact.email}}</a>
              <br>
              <a href="tel:{{primaryContact.phone}}">{{primaryContact.phone}}</a><span *ngIf="primaryContact.phoneExtension">x{{primaryContact.phoneExtension}}</span>
            </div>
          </div>
          <div class="col-md-4 mt-md-0 mt-2">
            <div *ngIf="site">
              <h5>{{site.name}} (Site)</h5>
              <p class="mb-0">{{site.address.street}}</p>
              <p class="mb-0">{{site.address.city}}, {{site.address.state}} {{site.address.postalCode}}</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="businessAttributes" class="col text-md-end">
        <div class="row">
          <div class="col">
            <div>
              <h5>{{businessAttributes.name}}</h5>
              <div *ngIf="businessAttributes.displayAddress">
                <p class="mb-0">{{businessAttributes.address.street}}</p>
                <p class="mb-0">{{businessAttributes.address.city}}, {{businessAttributes.address.state}} {{businessAttributes.address.postalCode}}</p>
              </div>
              <div class="d-block text-break">
                <a class="pe-0 ps-0" href="mailto:{{businessAttributes.email}}">{{businessAttributes.email}}</a>
                <br>
                <a class="pe-0 ps-0" href="tel:{{businessAttributes.phone}}">{{businessAttributes.phone}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 col-lg-6 mt-1">
        <p *ngIf="invoice" class="mb-0"><strong>Issue Date:</strong> {{invoice.issueDate | formatDateTime: true}}</p>
        <p *ngIf="invoice" class="mb-0"><strong>Due Date:</strong> {{invoice.dueDate | formatDateTime: true}}</p>
      </div>
      <div class="col-xs-4 col-lg-6 text-md-end mt-1">
        <!-- Make Payment Button -->
        <div *ngIf="invoice && invoice.status != 'PAID' && invoice.status != 'CANCELED' && hasEnabledPaymentOption()" class="d-print-none">
          <button type="button" class="btn btn-primary" (click)="openModal(PAYMENT_CATEGORY_MODAL);">
            <fa-icon class="button-icon-left" [icon]="faCreditCard"></fa-icon>
            <span>Make Payment</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <h2 class="p-0" style="border-bottom: 2px solid black; font-weight: 600;">Payments & Pricing</h2>
      <div class="col-lg-8 mt-2">
        <div class="card w-100">
          <h5 class="card-header">Payments</h5>
          <div class="card-body">
            <ul class="list-group scrollable" style="height: 230px;">
              <li *ngFor="let payment of payments" class="list-group-item">
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <div class="d-flex">
                      <strong style="line-height: 21px;">{{payment.category | paymentCategory}}</strong>
                      <div [ngSwitch]="payment.status" class="d-flex ms-2">
                        <div *ngSwitchCase="'PENDING'" class="badge text-bg-warning mb-auto">Pending</div>
                        <div *ngSwitchCase="'REJECTED'" class="badge text-bg-danger mb-auto">Rejected</div>
                        <div *ngSwitchCase="'CLOSED'" class="badge text-bg-success mb-auto">Closed</div>
                      </div>
                      <div *ngIf="payment.type == 'CREDIT'" class="badge ms-2" style="background-color: var(--pale-blue-color);">Refund</div>
                    </div>
                    <div *ngIf="payment.transaction" [ngSwitch]="payment.category">
                      <small *ngSwitchCase="'CARD'" class="text-muted">{{payment.transaction.brand}} (&#8226;&#8226;&#8226;&#8226; <span style="font-weight: 600;">{{payment.transaction.lastFour}}</span>)</small>
                      <small *ngSwitchCase="'ACH'" class="text-muted">{{payment.transaction.type}} (<span style="font-weight: 600;">{{payment.transaction.lastFour}}</span>)</small>
                    </div>
                    <small *ngIf="payment.surcharge !== 0" class="text-muted">Fee/Total: {{payment.surcharge | currency}}/{{payment.total | currency}}</small>
                    <small class="text-muted">{{payment.date | formatDateTime: true}}</small>
                  </div>
                  <div class="ms-auto my-auto fs-4 text-nowrap">{{payment.amount | currency}}</div>
                </div>
              </li>
              <li *ngIf="payments.length == 0" class="list-group-item">No Payments</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mt-2">
        <div class="card" style="width: 100%; height: 100%;">
          <h5 class="card-header">Pricing</h5>
          <div class="card-body">
            <!-- Accepted Proposal -->
            <div *ngIf="acceptedProposal" class="d-flex">
              <p class="mb-0" style="font-weight: 600;">Accepted Proposal</p>
              <p class="ms-auto my-auto">{{acceptedProposal.total | currency}}</p>
            </div>
            <!-- Invoice Amount -->
            <div *ngIf="invoice" class="d-flex mt-2">
              <p class="mb-0" style="font-weight: 600;">Invoice Amount</p>
              <p class="ms-auto my-auto">{{invoice.amount | currency}}</p>
            </div>
            <!-- Amount Paid -->
            <div *ngIf="invoice" class="d-flex mt-2">
              <p class="mb-0" style="font-weight: 600;">Amount Paid</p>
              <p class="ms-auto my-auto">{{invoice.totalPaymentAmount | currency}}</p>
            </div>
            <!-- Amount Credited -->
          <div *ngIf="totalCreditAmount" class="d-flex mt-2">
            <p class="mb-0" style="font-weight: 600;">Amount Credited</p>
            <p class="ms-auto my-auto">{{totalCreditAmount | currency}}</p>
          </div>
          </div>
          <div *ngIf="invoice" [ngSwitch]="invoice.status" class="card-footer">
            <div *ngSwitchCase="'PENDING'" class="d-flex">
              <p class="my-auto fs-4" style="font-weight: 700;">Amount Due</p>
              <p class="ms-auto my-auto fs-4">{{invoice.amountDue | currency}}</p>
            </div>
            <p *ngSwitchCase="'OVERDUE'" class="my-auto fs-4 text-center text-danger" style="font-weight: 700;">Overdue</p>
            <p *ngSwitchCase="'PAID'" class="my-auto fs-4 text-center" style="color: var(--green-color); font-weight: 700;">Paid</p>
            <p *ngSwitchCase="'CANCELED'" class="my-auto fs-4 text-center text-danger" style="font-weight: 700;">Canceled</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <h2 class="p-0" style="border-bottom: 2px solid black; font-weight: 600;">Terms & Conditions</h2>
      <div class="col">
        <div id="INVOICE_TERMS" style="padding-top: 1rem; padding-bottom: 1rem;"></div> <!-- /terms -->
      </div>
    </div>
  </div>
</div>

<!-- Payment Category Modal -->
<ng-template #PAYMENT_CATEGORY_MODAL let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Make Payment</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
  </div>
  <div class="modal-body">
    <ngb-accordion *ngIf="hasEnabledPaymentOption();" #paymentAccordion="ngbAccordion" closeOthers="true">
      <!-- Tinsel Pay Card -->
      <ngb-panel *ngIf="tinselPayCardPaymentIsEnabled" id="TINSEL_PAY_CARD" title="Tinsel Pay (Credit/Debit)">
        <ng-template ngbPanelContent>
          <app-tinsel-pay-card-payment-form [clientId]="client.id" [invoice]="invoice" [defaultAmount]="invoice.amountDue" (paymentSuccessful)="paymentSuccessful();"></app-tinsel-pay-card-payment-form>
        </ng-template>
      </ngb-panel>
      <!-- Tinsel Pay ACH -->
      <ngb-panel *ngIf="tinselPayAchPaymentIsEnabled" id="TINSEL_PAY_ACH" title="Tinsel Pay (ACH)">
        <ng-template ngbPanelContent>
          <app-tinsel-pay-ach-payment-form [clientId]="client.id" [invoice]="invoice" [defaultAmount]="invoice.amountDue" (paymentSuccessful)="paymentSuccessful();"></app-tinsel-pay-ach-payment-form>
        </ng-template>
      </ngb-panel>
      <!-- Square -->
      <ngb-panel *ngIf="squarePaymentIsEnabled" id="SQUARE" title="Square (Credit/Debit)">
        <ng-template ngbPanelContent>
          <app-square-payment-form [organizationId]="invoice.organizationId" [invoiceId]="invoice.id" [defaultAmount]="invoice.amountDue" (paymentSuccessful)="paymentSuccessful();"></app-square-payment-form>
        </ng-template>
      </ngb-panel>
      <!-- Venmo -->
      <ngb-panel *ngIf="venmoPaymentIsEnabled" id="VENMO" title="Venmo">
        <ng-template ngbPanelContent>
          <div class="d-flex flex-column">
            <div *ngIf="venmoCodeExists && venmoCodeUrl" class="mx-auto">
              <a [href]="venmoProfileUrl">
                <img id="VENMO_CODE_IMG" class="img-fluid" [src]="venmoCodeUrl" width="220" height="220" alt="Venmo Code">
              </a>
            </div>
            <p class="mb-0 text-center"><strong>Scan</strong> or <strong>Click</strong> on our Venmo code to make your payment through Venmo.</p>
          </div>
        </ng-template>
      </ngb-panel>
      <!-- Cash -->
      <ngb-panel *ngIf="cashPaymentIsEnabled" id="CASH" title="Cash">
        <ng-template ngbPanelContent>
          <div [innerHTML]="cashMessage"></div>
        </ng-template>
      </ngb-panel>
      <!-- Check -->
      <ngb-panel *ngIf="checkPaymentIsEnabled" id="CHECK" title="Check">
        <ng-template ngbPanelContent>
          <div [innerHTML]="checkMessage"></div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <p *ngIf="!hasEnabledPaymentOption();" class="mb-0">There are no payment options enabled. Please contact your representative for support.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
  </div>
</ng-template>
