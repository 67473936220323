<div class="container-fluid">
  <div class="table-responsive">
    <table class="number-table" *ngIf="phoneNumbers && phoneNumbers.length !=0 && !isMobile">
      <thead>
          <tr>
              <th>
                  Number
              </th>
              <th>
                  Caller ID Name
              </th>
              <th>
                  Assigned to
              </th>
              <th>
                  Capabilities
              </th>
              <th>Ready to Use</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let num of phoneNumbers">
              <td>{{ num.phoneNumber }}</td>
              <td>{{ num.phoneNumber }}</td>
              <td>{{ num.assignTo ? num.assignTo: 'All Users'}}</td>
              <td>
                  <span *ngIf="num.isSmsCapable" class="me-2">SMS,</span>
                  <span *ngIf="num.isMmsCapable" class="me-2">MMS,</span>
                  <span *ngIf="num.isVoiceCapable" class="me-2">Voice</span>
              </td>
              <td>
                  {{ num.isReadyToUse ? 'Yes':'No'}}
              </td>
          </tr>
      </tbody>
    </table>

    <div class="scrollable" *ngIf="isMobile">
      <ul class="list-group">
          <li *ngFor="let num of phoneNumbers" class="list-group-item">
              <div class="d-flex list-group-container">
                  <div>
                      <strong class="first-info">Number: {{num.phoneNumber}}</strong>
                      <div><strong>Caller ID Name:</strong> {{num.phoneNumber}}</div>
                      <div><strong>Assigned to:</strong> {{num.assignTo ? num.assignTo: 'All Users'}}</div>
                      <div><strong>Capabilities:</strong>
                        <span *ngIf="num.isSmsCapable" class="me-2">SMS,</span>
                        <span *ngIf="num.isMmsCapable" class="me-2">MMS,</span>
                        <span *ngIf="num.isVoiceCapable" class="me-2">Voice</span>
                      </div>
                      <div><strong>Ready to Use:</strong> {{num.isReadyToUse ? 'Yes':'No'}}</div>
                  </div>
              </div>
          </li>
          <li *ngIf="phoneNumbers.length == 0" class="list-group-item">No Numbers</li>
      </ul>
    </div>
  </div>
</div>
