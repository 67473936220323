import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faBold, faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/alert.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-campaign-modal',
  templateUrl: './campaign-modal.component.html',
  styleUrls: ['./campaign-modal.component.css']
})
export class CampaignModalComponent implements OnInit {
  @Output() saveCampaign = new EventEmitter()
  // Font Awesome Properties
  faTimes = faTimes;
  faBold = faBold;
  faQuestionCircle = faQuestionCircle;
  loading: boolean = false;
  public numbers: any[] = [];
  public isLoading: boolean = false;
  public campaignForm!: FormGroup;

  useCases = [
    {
      value: "LOW_VOLUME",
      label: "Low Volume Mixed",
    },
    {
      value: "ACCOUNT_NOTIFICATION",
      label: "Account Notification",
    },
    {
      value: "MARKETING",
      label: "Marketing",
    }
  ]

  constructor(
    public modal: NgbActiveModal,
    private loaderService: LoaderService,
    private fb: FormBuilder,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.campaignForm = this.fb.group({
      name: [null, Validators.required],
      description: [null, Validators.required],
      messageFlow: [null, Validators.required],
      messageSamples: this.fb.array([null, null], Validators.required),
      usAppToPersonUsecase: [null],
      hasEmbeddedLinks: [true, Validators.required],
      hasEmbeddedPhone: [true, Validators.required],
      numbersToUse: this.fb.array([], Validators.required),
    });
  }

  submit() {
    if (this.campaignForm.valid) {
      let data = {
        ...this.campaignForm.value
      }
      this.saveCampaign.emit(data)
      this.modal.close()
    }
  }

}
