import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../notification.service';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { TwilioService } from '../twilio.service';
import { ConversationService } from '../conversation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.css']
})
export class NotificationContainerComponent implements OnInit {

  // Font Awesome Properties
  faArrowRight = faArrowRight;
  messages: any[] = [];

  constructor(public notificationService: NotificationService,
    private router: Router,
    private conversationService: ConversationService) {

  }

  ngOnInit(): void {
    this.subscribeToTwilioMessages()
  }

  // Format Time
  formatTime(createdAt: number): string {
    return dayjs.unix(createdAt).format('h:mm A');
  }

  subscribeToTwilioMessages(){
    this.conversationService.messageAdded.subscribe((msg) => {
      if(msg && msg.conversation.attributes && `+1${msg.conversation.attributes.contactNumber}` == msg.author){
        this.messages.push(msg)
        let sound: HTMLAudioElement;
        sound = new Audio();
        sound.src = './assets/sounds/notification.wav';
        sound.load();
        sound.muted = true;
        sound.muted = false;
        sound.play();
      }
    });
  }

  removeMessage(i: any){
    this.messages.splice(i, 1)
  }

  messageClick(msg: any, ind: any){
    this.router.navigateByUrl(`conversation/${msg?.conversation?.sid}`);
    this.removeMessage(ind);

  }

  getMediaType(media) {
    const type = media.contentType.slice(0, media.contentType.indexOf('/'));
    return `Attachment: 1 ${type}`
  }
}
