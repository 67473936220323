<div class="modal-header">
    <h5 class="modal-title">Create Campaign
        <span class="ms-auto" style="cursor: pointer;" [ngbPopover]="numberConfiguration"
            triggers="click">
            <fa-icon [icon]="faQuestionCircle"></fa-icon>
        </span> 
    </h5>
    
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <app-number-campaign *ngIf="!isLoading" [campaignForm]="campaignForm" [useCases]="useCases" [numbers]="numbers"></app-number-campaign>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submit()">
        Submit
    </button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>

<ng-template #numberConfiguration>
    <p>
        A2P 10DLC refers to a system in the United States that allows businesses to send Application-to-Person (A2P) 
        type messaging via standard 10-digit long code (10DLC) phone numbers. Carriers in the US consider all Twilio traffic 
        to be A2P. Carriers’ A2P 10DLC offerings provide better delivery quality and lower filtering risk than long code SMS of the past, 
        using the same phone numbers.
    </p>
</ng-template>

<ngx-spinner>
    <p class="loading-spinner">Loading...</p>
</ngx-spinner>
  