import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FeatureItemService } from '../feature-item.service';
import { ProjectService } from '../project.service';
import { AlertService } from '../alert.service';
import { faCartPlus, faArrowCircleDown, faArrowCircleUp, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ProjectPrepService } from '../project-prep.service';
import { AdminSettingService } from '../admin-setting.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-project-prep',
  templateUrl: './project-prep.component.html',
  styleUrls: ['./project-prep.component.css']
})
export class ProjectPrepComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();



  // Properties
  @Input() projectId: string;
  projectPrepItems: any[] = [];
  count: number = 0;
  isMobileView: boolean = false;

  // Font Awesome Properties
  faCartPlus = faCartPlus;
  faArrowCircleDown = faArrowCircleDown;
  faArrowCircleUp = faArrowCircleUp;
  faArrowRight = faArrowRight;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private projectPrepService: ProjectPrepService,
    private featureItemService: FeatureItemService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private adminSettingService: AdminSettingService) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
        }
      });
    }

  ngOnInit(): void {
    this.getProjectPrepItems();
    this.checkScreenSize();
  }

  // Get Project Prep Items
  private getProjectPrepItems(): void {
    // const params: any = {
    //   searchTerm: null,
    //   sortBy: 'fi.feature_item_created_at',
    //   sortDirection: 'ASC',
    //   limit: null,
    //   offset: null,
    //   'filter:proj.project_id': JSON.stringify([this.projectId])
    // };
    const params: any = {
      searchTerm: null,
      sortBy: '',
      sortDirection: '',
      limit: null,
      offset: null
    };
    this.projectPrepService.getProjectPrepItems(params).subscribe((res) => {
      this.projectPrepItems = res.projectPrepItems;
      this.count = res.count;
    });
  }

  // Place On Order
  placeOnOrder(featureItemId: string): void {
    this.featureItemService.updateFeatureItemProjectPrepStatus(featureItemId, 'TO BE ORDERED').subscribe(() => {
      this.alertService.showSuccessAlert('Item Placed on Order');
      this.getProjectPrepItems();
    });
  }

  // Pull Feature Item From Inventory
  pullFeatureItemFromInventory(featureItemId: string): void {
    this.featureItemService.pullFeatureItemFromInventory(featureItemId).subscribe(() => {
      this.alertService.showSuccessAlert('Item Pulled');
      this.getProjectPrepItems();
    });
  }

  // Return Feature Item To Inventory
  returnFeatureItemToInventory(featureItemId: string): void {
    this.featureItemService.returnFeatureItemToInventory(featureItemId).subscribe(() => {
      this.alertService.showSuccessAlert('Item Returned');
      this.getProjectPrepItems();
    });
  }

  // Update Project Stage
  updateProjectStage(): void {
    this.projectService.updateProjectStage(this.projectId, 'INSTALLATION').subscribe(() => {
      this.projectService.projectUpdated.emit();
    });
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }
}
