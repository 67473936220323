<div class="content-container">
  <nav class="navbar navbar-white bg-white sticky-top">
    <div class="d-grid gap-2 d-flex navbar-brand">
      <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>
      <h2 class="mobileHeading">Schedule</h2>
    </div>
  </nav>
  <div class="row">
    <div class="col-lg-4">
      <app-schedule-queue type="INSTALLATION"></app-schedule-queue>
    </div>
    <div class="col-lg-4">
      <app-schedule-queue type="MAINTENANCE"></app-schedule-queue>
    </div>
    <div class="col-lg-4">
      <app-schedule-queue type="REMOVAL"></app-schedule-queue>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col pe-0">
      <div class="row text-center">
        <app-crew-management [showAlert]="showPendingChanges" (leaveNoSave)="leaveWithoutSaving($event)" (resetPendingChanges)="resetPendingChanges($event)" (hasChanges)="checkForCrewManagementChanges($event)" style="padding: 0px"></app-crew-management>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col pe-0" [class.pe-xl-2]="showMap">
      <app-schedule-calendar (mapToggled)="toggleMap();"></app-schedule-calendar>
    </div>
    <div *ngIf="(showMap && !isMobile) || isMobile" class="col mt-xl-0" [ngClass]="isMobile ? 'mt-3': ''">
      <app-schedule-map></app-schedule-map>
    </div>
  </div>
</div>
