import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmailTemplate } from './email-template';
import { AcceptProposalTerm } from './accept-proposal-term';
import { Tax } from './tax';
import { ProposalTerm } from './proposal-term';
import { CountyColor } from './county-color';
import { ProjectBase } from './project-base';
import { VenmoSetting } from './venmo-setting';
import { OrganizationSetting } from './organization-setting';
import { SquareSetting } from './square-setting';
import { CashSetting } from './cash-setting';
import { OrganizationSubscription } from './organization-subscription';
import { CardOnFile } from './card-on-file';
import { environment } from 'src/environments/environment';
import { TinselPaySetting } from './tinsel-pay-setting';
import { Subscription } from './subscription';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private http: HttpClient) { }

  /* ----- Organization Settings ----- */

  // Update Organization Settings
  updateOrganizationSettings(data: any): Observable<void> {
    return this.http.put<void>(`/settings/organization`, data);
  }

  // Get Organization Settings
  getOrganizationSettings(): Observable<OrganizationSetting> {
    return this.http.get<OrganizationSetting>(`/settings/organization`);
  }

  /* --- Logo --- */

  // Get Organization Logo Presigned Upload URL
  getOrganizationLogoPresignedUploadUrl(contentType: string): Observable<string> {
    return this.http.get<string>(`/settings/organization/logo/presignedUploadUrl`, {
      params: {
        contentType: contentType
      }
    });
  }

  // Get Organization Logo URL
  getOrganizationLogoUrl(organizationId: string): string {
    return `${environment.apiUrl}/settings/organization/logo?organizationId=${organizationId}`;
  }

  // Delete Organization Logo
  deleteOrganizationLogo(): Observable<void> {
    return this.http.delete<void>(`/settings/organization/logo`);
  }

  /* --- County Colors --- */

  // Update County Color
  updateCountyColor(countyColor: any): Observable<any> {
    return this.http.put<any>(`/settings/organization/countyColors/${countyColor.id}`, countyColor);
  }

  // Get County Colors
  getCountyColors(): Observable<CountyColor[]> {
    return this.http.get<CountyColor[]>(`/settings/organization/countyColors`);
  }

  /* --- Taxes --- */

  // Add Tax
  addTax(tax: any): Observable<any> {
    return this.http.post<any>(`/settings/organization/taxes`, tax);
  }

  // Update Tax
  updateTax(tax: any): Observable<any> {
    return this.http.put<any>(`/settings/organization/taxes/${tax.id}`, tax);
  }

  // Get Taxes
  getTaxes(): Observable<Tax[]> {
    return this.http.get<Tax[]>(`/settings/organization/taxes`);
  }

  /* --- Storage --- */

  // Get Storage Metrics For Organization
  getStorageMetricsForOrganization(): Observable<{ totalFileCount: number, totalStorageSize: number, storageSizeLimit: number }> {
    return this.http.get<{ totalFileCount: number, totalStorageSize: number, storageSizeLimit: number }>(`/settings/organization/storage/metrics`);
  }

  /* ----- Project Settings ----- */

  // Add Project Base
  addProjectBase(content: string): Observable<any> {
    return this.http.post<any>(`/settings/projects/bases`, { content: content });
  }

  // Delete Project Base
  deleteProjectBase(id: string): Observable<any> {
    return this.http.delete<any>(`/settings/projects/bases/${id}`);
  }

  // Get Project Bases
  getProjectBases(): Observable<ProjectBase[]> {
    return this.http.get<ProjectBase[]>(`/settings/projects/bases`);
  }

  /* ----- Proposal Settings ----- */

  // Update Proposal Settings
  updateProposalSettings(body: any): Observable<any> {
    return this.http.put<any>(`/settings/proposals`, body);
  }

  // Get Proposal Settings
  getProposalSettings(): Observable<any> {
    return this.http.get<any>(`/settings/proposals`);
  }

  /* --- Accept Proposal Terms --- */

  // Add Accept Proposal Term
  addAcceptProposalTerm(content: string): Observable<any> {
    return this.http.post<any>(`/settings/proposals/acceptProposalTerms`, { content: content });
  };

  // Update Accept Proposal Term
  updateAcceptProposalTerm(acceptProposalTermId: string, content: string): Observable<any> {
    return this.http.put<any>(`/settings/proposals/acceptProposalTerms/${acceptProposalTermId}`, { content: content });
  }

  // Delete Accept Proposal Term
  deleteAcceptProposalTerm(acceptProposalTermId: string): Observable<any> {
    return this.http.delete<any>(`/settings/proposals/acceptProposalTerms/${acceptProposalTermId}`);
  }

  // Get Accept Proposal Terms
  getAcceptProposalTerms(): Observable<AcceptProposalTerm[]> {
    return this.http.get<AcceptProposalTerm[]>(`/settings/proposals/acceptProposalTerms`);
  }

  /* --- Multi Agreement Type Options --- */

  // Get Multi Agreement Type Options
  getMultiAgreementTypeOptions(): Observable<any> {
    return this.http.get<any>(`/settings/proposals/agreementTypes/multi/options`);
  }

  // Update Multi Agreement Type Options
  updateMultiAgreementTypeOptions(data: any): Observable<void> {
    return this.http.put<void>(`/settings/proposals/agreementTypes/multi/options`, data);
  }

  /* --- Multi Pricing Description --- */

  // Get Multi Pricing Description
  getMultiPricingDescription(organizationId: string): Observable<string> {
    return this.http.get<string>(`/settings/proposals/multiPricingDescription`, {
      params: {
        organizationId: organizationId
      }
    });
  }

  // Update Multi Pricing Description
  updateMultiPricingDescription(multiPricingDescription: string): Observable<any> {
    return this.http.put<any>(`/settings/proposals/multiPricingDescription`, { multiPricingDescription: multiPricingDescription });
  }

  /* --- Proposal Terms --- */

  // Add Proposal Term
  addProposalTerm(proposalTerm: any): Observable<any> {
    return this.http.post<any>(`/settings/proposals/terms`, proposalTerm);
  }

  // Update Proposal Term
  updateProposalTerm(proposalTerm: any): Observable<any> {
    return this.http.put<any>(`/settings/proposals/terms/${proposalTerm.id}`, proposalTerm);
  }

  // Set Default Proposal Term
  setDefaultProposalTerm(proposalTerm: any): Observable<any> {
    return this.http.put<any>(`/settings/proposals/terms/${proposalTerm.id}/default`, proposalTerm);
  }

  // Get Proposal Terms
  getProposalTerms(): Observable<ProposalTerm[]> {
    return this.http.get<ProposalTerm[]>(`/settings/proposals/terms`);
  }

  /* ----- Invoice Settings ----- */

  // Update Invoice Settings
  updateInvoiceSettings(body: any): Observable<any> {
    return this.http.put<any>(`/settings/invoices`, body);
  }

  // Get Invoice Settings
  getInvoiceSettings(): Observable<any> {
    return this.http.get<any>(`/settings/invoices`);
  }

  /* --- Terms --- */

  // Set Invoice Terms
  setInvoiceTerms(invoiceTerms: string): Observable<any> {
    return this.http.put<any>(`/settings/invoices/terms`, { content: invoiceTerms });
  }

  // Get Invoice Terms
  getInvoiceTerms(): Observable<string> {
    return this.http.get<string>(`/settings/invoices/terms`);
  }

  /* ----- Email Settings ----- */

  /* --- Receiving --- */

  // Regenerate Prospects Email
  regenerateProspectsEmail(): Observable<any> {
    return this.http.get<any>(`/settings/emails/receiving/regenerateProspectsEmail`);
  }

  // Get Email Receiving Settings
  getEmailReceivingSettings(): Observable<any> {
    return this.http.get<any>(`/settings/emails/receiving`);
  }

  /* --- Sending --- */

  // Update Email Sending Settings
  updateEmailSendingSettings(data: any): Observable<any> {
    return this.http.put<any>(`/settings/emails/sending`, data);
  }

  // Get Email Sending Settings
  getEmailSendingSettings(): Observable<any> {
    return this.http.get<any>(`/settings/emails/sending`);
  }

  /* --- Templates --- */

  // Update Email Template
  updateEmailTemplate(template: any): Observable<any> {
    return this.http.put<any>(`/settings/emails/templates/${template.id}`, template);
  }

  // Get Email Templates
  getEmailTemplates(): Observable<EmailTemplate[]> {
    return this.http.get<EmailTemplate[]>(`/settings/emails/templates`);
  }

  // Get Email Template
  getEmailTemplate(templateId: string, organizationId?: string) {
    if (organizationId) {
      return this.http.get(`/settings/emails/templateswithoutguard`, {
        params: {
          templateId: templateId,
          organizationId
        },
        responseType: 'text'
      });
    } else {
      return this.http.get(`/settings/emails/template`, {
        params: {
          templateId: templateId
        },
        responseType: 'text'
      });
    }
  }

  /* ----- Payment Settings ----- */

  /* --- Tinsel Pay Settings --- */

  // Get Tinsel Pay Settings
  getTinselPaySettings(): Observable<TinselPaySetting> {
    return this.http.get<TinselPaySetting>(`/settings/payments/tinselPay`);
  }

  // Get Tinsel Pay Form Settings
  getTinselPayFormSettings(organizationId: string): Observable<{ tokenizationKey: string, passFee: boolean }> {
    return this.http.get<{ tokenizationKey: string, passFee: boolean }>(`/settings/payments/tinselPay/paymentForm`, {
      params: {
        organizationId: organizationId
      }
    });
  }

  // Update Tinsel Pay Settings
  updateTinselPaySettings(data: any): Observable<void> {
    return this.http.put<void>(`/settings/payments/tinselPay`, data);
  }

  /* --- Square Settings --- */

  // Get Square Settings
  getSquareSettings(): Observable<SquareSetting> {
    return this.http.get<SquareSetting>(`/settings/payments/square`);
  }

  // Get Square Form Settings
  getSquareFormSettings(organizationId: string): Observable<{ applicationId: string, locationId: string }> {
    return this.http.get<{ applicationId: string, locationId: string }>(`/settings/payments/square/paymentForm`, {
      params: {
        organizationId: organizationId
      }
    });
  }

  // Update Square Settings
  updateSquareSettings(data: any): Observable<void> {
    return this.http.put<void>(`/settings/payments/square`, data);
  }

  /* --- Venmo Settings --- */

  // Get Venmo Settings
  getVenmoSettings(): Observable<VenmoSetting> {
    return this.http.get<VenmoSetting>(`/settings/payments/venmo`);
  }

  // Update Venmo Property
  updateVenmoProperty(property: string, value: any): Observable<void> {
    return this.http.put<void>(`/settings/payments/venmo/${property}`, { value: value });
  }

  // Get Venmo Code Presigned Upload URL
  getVenmoCodePresignedUploadUrl(contentType: string): Observable<string> {
    return this.http.get<string>(`/settings/payments/venmo/code/presignedUploadUrl`, {
      params: {
        contentType: contentType
      }
    });
  }

  // Upload Venmo Code
  uploadVenmoCode(url: string, file: File): Observable<HttpEvent<any>> {
    const req = new HttpRequest('PUT', url, file, {
      reportProgress: true
    });
    return this.http.request(req);
  }

  // Get Venmo Code URL
  getVenmoCodeUrl(organizationId: string): string {
    return `${environment.apiUrl}/settings/payments/venmo/code?organizationId=${organizationId}`;
  }

  // Delete Venmo Code
  deleteVenmoCode(): Observable<void> {
    return this.http.delete<void>(`/settings/payments/venmo/code`);
  }

  /* --- Cash Settings --- */

  // Get Cash Settings
  getCashSettings(organizationId: string): Observable<CashSetting> {
    return this.http.get<CashSetting>(`/settings/payments/cash`, {
      params: {
        organizationId: organizationId
      }
    });
  }

  // Update Cash Property
  updateCashProperty(property: string, value: any): Observable<void> {
    return this.http.put<void>(`/settings/payments/cash/${property}`, { value: value });
  }

  /* --- Check Settings --- */

  // Get Check Settings
  getCheckSettings(organizationId: string): Observable<CashSetting> {
    return this.http.get<CashSetting>(`/settings/payments/check`, {
      params: {
        organizationId: organizationId
      }
    });
  }

  // Update Check Property
  updateCheckProperty(property: string, value: any): Observable<any> {
    return this.http.put<any>(`/settings/payments/check/${property}`, { value: value });
  }

  /* ----- Subscription Settings ----- */

  /* --- Subscriptions --- */

  // Get Subscriptions list
  getSubscriptionsList(): Observable<Subscription[]> {
    return this.http.get<Subscription[]>(`/settings/subscriptions/list`);
  }

  // Get Organization Subscriptions
  getOrganizationSubscriptions(): Observable<OrganizationSubscription[]> {
    return this.http.get<OrganizationSubscription[]>(`/settings/subscriptions`);
  }

  // Get Subscription Invoices
  getSubscriptionInvoices(): Observable<any[]> {
    return this.http.get<any[]>(`/settings/subscriptions/invoices`);
  }

  updateSubscription(data): Observable<OrganizationSubscription> {
    return this.http.put<OrganizationSubscription>(`/settings/subscriptions`, data);
  }

  /* --- Card On File --- */

  // Get Card On File
  getCardOnFile(): Observable<CardOnFile> {
    return this.http.get<CardOnFile>(`/settings/subscriptions/cardOnFile`);
  }

  // Delete Card On File
  deleteCardOnFile(): Observable<any> {
    return this.http.delete<any>(`/settings/subscriptions/cardOnFile`);
  }

  // Update Card On File
  updateCardOnFile(data: any): Observable<void> {
    return this.http.put<void>(`/settings/subscriptions/cardOnFile`, data);
  }

  /* --- Biling --- */

  // Get Billing Information
  getBillingInformation(): Observable<any> {
    return this.http.get<any>(`/settings/subscriptions/billing`);
  }

  // Update Billing Information
  updateBillingInformation(data: any): Observable<any> {
    return this.http.put<any>(`/settings/subscriptions/billing`, data);
  }

  getSubscriptions(organizationId: string): Observable<OrganizationSubscription[]> {
    return this.http.get<OrganizationSubscription[]>(`/organizations/${organizationId}/subscriptions`);
  }
}
