<div class="row mt-2">
  <div class="col-sm-3">
    <div class="d-flex justify-content-between align-items-center">
      <div class="form-check form-switch">
        <input id="SHOW_ARCHIVED" type="checkbox" class="form-check-input" role="switch"
          (change)="showArchivedUsers($event.target.checked);">
        <label for="SHOW_ARCHIVED" class="form-check-label">Show Archived Users</label>
      </div>
      <button type="button" class="btn btn-primary btn-lg" (click)="checkUserCount();">New</button>
    </div>
    <div class="scrollable mt-2">
      <ul class="list-group">
        <li *ngFor="let user of filteredUsers(users)" class="list-group-item"
          [class.archived]="user.isArchived"
          [class.selectedUser]="selectedUser && selectedUser.id == user.id" style="cursor: pointer;"
          (click)="selectUser(user.id);">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column">
              <i class="font-italic" *ngIf="user.isArchived">Archived</i>
              <strong>{{user.firstName}} {{user.lastName}}</strong>
              <span>({{user.email}})</span>
            </div>
            <div *ngIf="currentUser.id != user.id">
              <fa-icon class="button-icon-left h3" [icon]="user.isArchived ?  faTrashRestore: faArchive" [ngbTooltip]="user.isArchived ?  'Restore User': 'Archive User'" (click)="archiveUserConfirmation(!user.isArchived)"></fa-icon>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-sm-9 mt-lg-0 mt-2">
    <div class="row">
      <div class="col-sm-6">
        <div class="card" style="height: 100%;">
          <div class="card-header">
            <h5 class="my-auto">General</h5>
            <button type="button" class="btn btn-primary ms-auto" (click)="updateUser();">
              <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
              <span>Save</span>
            </button>
          </div>
          <div class="card-body">
            <form [formGroup]="userForm">
              <div class="row">
                <div class="col-sm">
                  <div class="mb-3">
                    <label for="USER_FIRST_NAME">First Name</label>
                    <input id="USER_FIRST_NAME" type="text" class="form-control" placeholder="John"
                      formControlName="firstName" maxlength="150" required
                      [class.is-invalid]="firstName.invalid && (firstName.dirty || firstName.touched)">
                    <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                      <div *ngIf="firstName.errors.required" class="input-validation-msg">First Name is required.</div>
                      <div *ngIf="firstName.errors.maxlength" class="input-validation-msg">First Name cannot exceed 150
                        characters.</div>
                    </div>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="mb-3">
                    <label for="USER_LAST_NAME">Last Name</label>
                    <input id="USER_LAST_NAME" type="text" class="form-control" placeholder="Appleseed"
                      formControlName="lastName" maxlength="150" required
                      [class.is-invalid]="lastName.invalid && (lastName.dirty || lastName.touched)">
                    <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                      <div *ngIf="lastName.errors.required" class="input-validation-msg">Last Name is required.</div>
                      <div *ngIf="lastName.errors.maxlength" class="input-validation-msg">Last Name cannot exceed 150
                        characters.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label for="USER_EMAIL">Email</label>
                    <input id="USER_EMAIL" type="text" class="form-control" formControlName="email"
                      placeholder="jappleseed@example.com" autocapitalize="off" maxlength="320"
                      pattern="^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$"
                      required [class.is-invalid]="email.invalid && (email.dirty || email.touched)">
                    <div *ngIf="email.invalid && (email.dirty || email.touched)">
                      <div *ngIf="email.errors.required" class="input-validation-msg">Email is required.</div>
                      <div *ngIf="email.errors.maxlength" class="input-validation-msg">Email cannot exceed 320
                        characters.</div>
                      <div *ngIf="email.errors.pattern" class="input-validation-msg">Email is formatted incorrectly.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div>
                    <label for="USER_PHONE">Phone</label>
                    <input id="USER_PHONE" type="tel" class="form-control" appPhoneMask formControlName="phone"
                      placeholder="(000) 000-0000" maxlength="14" pattern="^\((\d{3})\)\s(\d{3})-(\d{4})"
                      [class.is-invalid]="phone.invalid && (phone.dirty || phone.touched)">
                    <div *ngIf="phone.invalid && (phone.dirty || phone.touched)">
                      <div *ngIf="phone.errors.maxlength" class="input-validation-msg">Phone cannot exceed 14
                        characters.</div>
                      <div *ngIf="phone.errors.pattern" class="input-validation-msg">Phone is formatted incorrectly.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mt-2 mt-md-0">
        <div class="card">
          <div class="card-header">
            <h5>Address</h5>
            <button type="button" class="btn btn-primary ms-auto" (click)="updateUserAddress();">
              <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
              <span>Save</span>
            </button>
          </div>
          <div class="card-body">
            <app-address-form [form]="userAddressForm"></app-address-form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 mt-2">
        <div class="card">
          <div class="card-header">
            <h5 class="my-auto">Access</h5>
            <button *ngIf="!selectedUserHasHigherAuth" type="button" class="btn btn-primary ms-auto"
              (click)="openModal(RESET_PASSWORD_MODAL);">Reset Password</button>
          </div>
          <div class="card-body">
            <form [formGroup]="userAccessForm">
              <div class="mb-3">
                <div class="form-check form-switch">
                  <input id="USER_IS_ENABLED" type="checkbox" class="form-check-input" role="switch"
                    formControlName="isEnabled" (change)="updateUserEnabledStatus($event.target.checked);">
                  <label for="USER_IS_ENABLED" class="form-check-label">Enabled</label>
                </div>
              </div>
              <div class="mb-3">
                <label class="mb-2" for="USER_ROLE">Role
                  <span class="ms-auto" style="cursor: pointer;" ngbPopover="Click to open User Roles Table" triggers="hover" placement="auto" (click)="openRolesModal()">
                  <fa-icon [icon]="faInfoCircle"></fa-icon>
                  </span>
                </label>
                <select id="USER_ROLE" class="form-select" formControlName="role" (change)="updateUserRole();" required>
                  <option *ngIf="selectedUser && selectedUser.role == 'SUPER'" value="SUPER">Super</option>
                  <option value="ADMIN">Admin</option>
                  <option value="FRANCHISEE">Franchisee</option>
                  <option value="USER">User</option>
                </select>
                <div *ngIf="role.invalid && (role.dirty || role.touched)">
                  <div *ngIf="role.errors.required" class="input-validation-msg">Role is required.</div>
                </div>
              </div>
              <div>
                <label style="font-weight: bold;">CRM Access</label>
                <div class="mb-3">
                  <div class="form-check form-check-inline">
                    <input id="USER_CRM_ACCESS_ENABLED" class="form-check-input" type="radio"
                      formControlName="hasCrmAccess" [value]="true" (change)="updateUserCrmAccess(true);">
                    <label for="USER_CRM_ACCESS_ENABLED" class="form-check-label"
                      style="font-weight: normal;">Enabled</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="USER_CRM_ACCESS_DISABLED" class="form-check-input" type="radio"
                      formControlName="hasCrmAccess" [value]="false" (change)="updateUserCrmAccess(false);">
                    <label for="USER_CRM_ACCESS_DISABLED" class="form-check-label"
                      style="font-weight: normal;">Disabled</label>
                  </div>
                </div>
              </div>
              <div>
                <label style="font-weight: bold;">Employee Access</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input id="USER_EMPLOYEE_ACCESS_ENABLED" class="form-check-input" type="radio"
                      formControlName="hasEmployeeAccess" [value]="true" (change)="updateUserEmployeeAccess(true);">
                    <label for="USER_EMPLOYEE_ACCESS_ENABLED" class="form-check-label"
                      style="font-weight: normal;">Enabled</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="USER_EMPLOYEE_ACCESS_DISABLED" class="form-check-input" type="radio"
                      formControlName="hasEmployeeAccess" [value]="false" (change)="updateUserEmployeeAccess(false);">
                    <label for="USER_EMPLOYEE_ACCESS_DISABLED" class="form-check-label"
                      style="font-weight: normal;">Disabled</label>
                  </div>
                </div>
              </div>
              <div>
                <label style="font-weight: bold;">Sales Access</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input id="USER_EMPLOYEE_ACCESS_ENABLED" class="form-check-input" type="radio"
                      formControlName="hasSalesAccess" [value]="true" (change)="updateSalesAccess(true);">
                    <label for="USER_EMPLOYEE_ACCESS_ENABLED" class="form-check-label"
                      style="font-weight: normal;">Enabled</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="USER_EMPLOYEE_ACCESS_DISABLED" class="form-check-input" type="radio"
                      formControlName="hasSalesAccess" [value]="false" (change)="updateSalesAccess(false);">
                    <label for="USER_EMPLOYEE_ACCESS_DISABLED" class="form-check-label"
                      style="font-weight: normal;">Disabled</label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mt-2">
        <div class="card">
          <div class="card-header">
            <h5 class="my-auto">Attributes</h5>
            <button type="button" class="btn btn-primary ms-auto" (click)="updateUserAttributes();">
              <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
              <span>Save</span>
            </button>
          </div>
          <div class="card-body">
            <form [formGroup]="userAttributesForm">
              <div class="row">
                <div class="col">
                  <div>
                    <label for="USER_ATTR_HOURLY_RATE">Hourly Rate</label>
                    <div class="input-group">
                      <div class="input-group-text">$</div>
                      <input id="USER_ATTR_HOURLY_RATE" type="number" step="0.10" class="form-control"
                        formControlName="hourlyRate" placeholder="25.00" min="0" required
                        [class.is-invalid]="hourlyRate.invalid && (hourlyRate.dirty || hourlyRate.touched)">
                    </div>
                    <div *ngIf="hourlyRate.invalid && (hourlyRate.dirty || hourlyRate.touched)">
                      <div *ngIf="hourlyRate.errors.required" class="input-validation-msg">Hourly Rate is required.
                      </div>
                      <div *ngIf="hourlyRate.errors.min" class="input-validation-msg">Hourly Rate cannot be less than 0.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Reset Password Modal -->
<ng-template #RESET_PASSWORD_MODAL let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Reset Password</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="passwordForm">
      <div class="row">
        <div class="col">
          <label style="font-weight: bold;">Password Generation</label>
          <div [class.mb-0]="passwordGeneration.value == 'AUTO'" [class.mb-2]="passwordGeneration.value == 'MANUAL'">
            <div class="form-check form-check-inline">
              <input id="GENERATE_PASSWORD_AUTO" class="form-check-input" type="radio"
                formControlName="passwordGeneration" value="AUTO">
              <label for="GENERATE_PASSWORD_AUTO" class="form-check-label" style="font-weight: normal;">Auto
                Generate</label>
            </div>
            <div class="form-check form-check-inline">
              <input id="GENERATE_PASSWORD_MANUAL" class="form-check-input" type="radio"
                formControlName="passwordGeneration" value="MANUAL">
              <label for="GENERATE_PASSWORD_MANUAL" class="form-check-label" style="font-weight: normal;">Manual</label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="passwordGeneration.value == 'AUTO'">
        <small class="text-muted">Password will be sent to the user's email.</small>
      </div>
      <div *ngIf="passwordGeneration.value == 'MANUAL'">
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label for="NEW_USER_PASSWORD">Password</label>
              <input id="NEW_USER_PASSWORD" type="password" class="form-control" placeholder="Password"
                formControlName="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}">
              <div *ngIf="password.invalid && (password.dirty || password.touched)">
                <div *ngIf="password.errors.pattern" class="input-validation-msg">Password must be 8 characters and
                  contain uppercase, lowercase, and number characters.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div>
              <label for="NEW_USER_CONFIRM_PASSWORD">Confirm Password</label>
              <input id="NEW_USER_CONFIRM_PASSWORD" type="password" class="form-control" placeholder="Confirm Password"
                formControlName="confirmPassword" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}">
              <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                <div *ngIf="confirmPassword.errors.pattern" class="input-validation-msg">Password must be 8 characters
                  and contain uppercase, lowercase, and number characters.</div>
              </div>
              <div *ngIf="password.value != confirmPassword.value" class="input-validation-msg">Passwords do not match.
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-primary" (click)="resetUserPassword();">Reset</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
  </div>
</ng-template>



<!-- User Roles Table Modal -->
<ng-template #userRolesTemplate let-modal>
  <div class="modal-header">
      <h5 class="modal-title">Roles</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
  </div>
  <div class="modal-body">
    <div class="container roles-img-container">
      <img src="../../assets/roles_table.png">
    </div>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>
