<div class="modal-header">
    <div>
        <h5 class="modal-title">Checking In Item</h5>
        <h5 class="text-muted">{{ itemName }}</h5>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <div class="row mt-2 align-items-center">
        <div class="col-md-10 mx-auto text-center">
            <p> You have ordered {{ quantity }} more than what is needed for this project. Would you like to add the extra to your inventory? </p>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submit(quantity)">Add to Inventory</button>
    <button type="button" class="btn btn-secondary" (click)="submit(0)">Ignore</button>
</div>