<!-- Inventory Prep Table -->
<div class="card mt-3">
  <div class="card-header">
    <h5>Inventory Prep</h5>
    <div class="ms-auto">
      <button class="btn btn-primary" (click)="updateProjectStage();">
        <span>Next Stage</span>
        <fa-icon class="button-icon-right" [icon]="faArrowRight"></fa-icon>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table *ngIf="!isMobile">
        <thead>
          <tr>
            <th>Item</th>
            <th>Source</th>
            <th>Quantity</th>
            <th>Current Stock Level</th>
            <th>Adjusted Stock Level</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let projectPrepItem of projectPrepItems">
            <td data-label="Item">{{projectPrepItem.featureItem.name}}</td>
            <td data-label="Source">{{projectPrepItem.item.source}}</td>
            <td data-label="Quantity">{{projectPrepItem.featureItem.quantity}}</td>
            <td data-label="Current Stock Level">{{projectPrepItem.item.stockCurrentLevel}}</td>
            <td data-label="Adjusted Stock Level">{{projectPrepItem.item.stockAdjustedLevel}}</td>
            <td data-label="Status">{{projectPrepItem.featureItem.projectPrepStatus | projectPrepStatus}}</td>
            <td class="actionBtnContainer"  data-label="Actions">
              <button type="button" class="btn btn-primary me-2" (click)="pullFeatureItemFromInventory(projectPrepItem.featureItem.id);" [disabled]="projectPrepItem.featureItem.projectPrepStatus != 'PENDING'" ngbTooltip="Pull from Inventory">
                <fa-icon [icon]="faArrowCircleDown"></fa-icon>
              </button>
              <!-- <button *ngIf="projectPrepItem.featureItem.projectPrepStatus == 'PULLING'" type="button" class="btn btn-primary me-2" (click)="returnFeatureItemToInventory(projectPrepItem.featureItem.id);" ngbTooltip="Return to Inventory">
                <fa-icon [icon]="faArrowCircleUp"></fa-icon>
              </button> -->
              <button type="button" class="btn btn-primary" (click)="placeOnOrder(projectPrepItem.featureItem.id);" [disabled]="projectPrepItem.featureItem.projectPrepStatus != 'PENDING'" ngbTooltip="Place on Order">
                <fa-icon [icon]="faCartPlus"></fa-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="scrollable" *ngIf="isMobile">
        <ul class="list-group">
            <li *ngFor="let projectPrepItem of projectPrepItems" class="list-group-item">
                <div class="d-flex list-group-container">
                    <div>
                        <strong>{{projectPrepItem.featureItem.name}}</strong>
                        <div>{{projectPrepItem.item.source}}</div>
                        <div><strong>Quantity: </strong>{{projectPrepItem.featureItem.quantity}}</div>
                        <div><strong>Current Stock Level: </strong>{{projectPrepItem.item.stockCurrentLevel}}</div>
                        <div><strong>Adjusted Stock Level: </strong>{{projectPrepItem.item.stockAdjustedLevel}}</div>
                        <div><strong>Status:</strong>{{projectPrepItem.featureItem.projectPrepStatus | projectPrepStatus}}</div>
                        <div class="list-button">
                          <button type="button" class="btn btn-primary" (click)="pullFeatureItemFromInventory(projectPrepItem.featureItem.id);" [disabled]="projectPrepItem.featureItem.projectPrepStatus != 'PENDING'" ngbTooltip="Pull from Inventory">
                            <fa-icon [icon]="faArrowCircleDown"></fa-icon>
                          </button>
                          <!-- <button *ngIf="projectPrepItem.featureItem.projectPrepStatus == 'PULLING'" type="button" class="btn btn-primary me-2" (click)="returnFeatureItemToInventory(projectPrepItem.featureItem.id);" ngbTooltip="Return to Inventory">
                            <fa-icon [icon]="faArrowCircleUp"></fa-icon>
                          </button> -->
                          <button type="button" class="btn btn-primary" (click)="placeOnOrder(projectPrepItem.featureItem.id);" [disabled]="projectPrepItem.featureItem.projectPrepStatus != 'PENDING'" ngbTooltip="Place on Order">
                            <fa-icon [icon]="faCartPlus"></fa-icon>
                          </button>
                        </div>
                    </div>
                </div>
            </li>
            <li *ngIf="projectPrepItems.length == 0" class="list-group-item">No Project Prep Items</li>
        </ul>
      </div>
    </div>
  </div>
</div>
