import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, Observable, Subject, switchMap } from 'rxjs';
import { Address } from '../address';
import { LocationService } from '../location.service';
import { State } from '../state';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.css']
})
export class AddressFormComponent implements OnInit {

  // Properties
  @Input() form: UntypedFormGroup;
  @Input() isCompact: boolean = false;
  states: State[] = [];

  // Address Typeahead
  addressTypeaheadInput: (text$: Observable<string>) => Observable<Address[]>;
  addressTypeaheadInputFocus$ = new Subject<string>();
  addressTypeaheadInputClick$ = new Subject<string>();
  addressResultFormatter = (address: Address) => address.label;

  constructor(private locationService: LocationService) { }

  ngOnInit(): void {
    this.getStates();
    this.prepareAddressTypeahead();
  }

  // Get States
  getStates(): void {
    this.locationService.getStates().subscribe((states) => {
      this.states = states;
    });
  }

  // Prepare Address Typeahead
  private prepareAddressTypeahead(): void {
    this.addressTypeaheadInput = (text$: Observable<string>) => {
      return text$.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        switchMap((term) => { return (term.length == 0 || /^[0-9]*$/.test(term)) ? [] : this.locationService.searchForAddress(term); })
      );
    }
  }

  // Address Selected
  addressSelected(event: NgbTypeaheadSelectItemEvent): void {
    event.preventDefault();
    const address = event.item;
    this.form.controls.street.setValue(address.street);
    this.form.controls.city.setValue(address.city);
    if (this.form.controls.county) this.form.controls.county.setValue(address.county);
    this.form.controls.state.setValue(address.state);
    this.form.controls.postalCode.setValue(address.postalCode);
  }

  // Address Form Accessors
  get street() { return this.form.controls.street; }
  get city() { return this.form.controls.city; }
  get county() { return this.form.controls.county; }
  get state() { return this.form.controls.state; }
  get postalCode() { return this.form.controls.postalCode; }
}
