import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '../alert.service';
import { FeatureItemService } from '../feature-item.service';
import { faTimesCircle, faArrowCircleUp, faCheck, faFilter, faRotateLeft, faSortDown, faSortUp, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { ProjectPrepService } from '../project-prep.service';
import { ProjectPrepStatusPipe } from '../project-prep-status.pipe';
import { TableControlService } from '../table-control.service';
import { InventoryPrepService } from '../inventory-prep.service';
import { InventoryPrep } from '../inventory-prep';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectPrepCancelSplitMsgComponent } from '../project-prep-cancel-split-msg/project-prep-cancel-split-msg.component';
import { AdminSettingService } from '../admin-setting.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-project-prep-inventory',
  templateUrl: './project-prep-inventory.component.html',
  styleUrls: ['./project-prep-inventory.component.css'],
  providers: [ProjectPrepStatusPipe]
})
export class ProjectPrepInventoryComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  @Input() projectId: string;
  @Output() allCheck = new EventEmitter<boolean>();

  // Properties
  projectPrepItems: any[] = [];
  count: number = 0;
  isMobileView: boolean = false;

  sortOptions = [
    { label: 'Item', value: 'i.item_name' },
    { label: 'Feature', value: 'f.feature_name' },
    { label: 'Project', value: 'p.project_name' },
    { label: 'Client', value: 'c.client_name' },
    { label: 'Source', value: 'i.item_source' },
    { label: 'Quantity', value: 'ip.inventory_prep_quantity' },
    { label: 'Status', value: 'ip.inventory_prep_status' },
    { label: 'Updated', value: 'ip.inventory_prep_updated_at' },
  ];
  filterColumns = [
    { label: 'Item', value: 'i.item_name' },
    { label: 'Feature', value: 'f.feature_name' },
    { label: 'Project', value: 'p.project_name' },
    { label: 'Client', value: 'c.client_name' },
    { label: 'Source', value: 'i.item_source' },
    { label: 'Status', value: 'ip.inventory_prep_status' }
  ];
  sortBy: string = 'i.item_name';
  sortDirection: string = 'ASC';

  // Table Controls
  tableControls: TableControlService;
  storedSearchValue: string;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;
  selectedFilterColumn: string ='';

  // Font Awesome Properties
  faArrowCircleUp = faArrowCircleUp;
  faCheck = faCheck;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;
  faRotateLeft = faRotateLeft;
  faTimesCircle = faTimesCircle;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private featureItemService: FeatureItemService,
    private projectPrepService: ProjectPrepService,
    private inventoryPrepService: InventoryPrepService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private projectPrepStatusPipe: ProjectPrepStatusPipe,
    private adminSettingService: AdminSettingService) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
          this.tableControls.setLimit(20);
        }
      });
    }

  ngOnInit(): void {
    if (this.projectId) {
      this.tableControls = new TableControlService('ProjectPrep.Inventory', true, null, 'DESC', [['ip.inventory_prep_project_id', [this.projectId]], ['ip.inventory_prep_status', ['PULLING', 'CHECK-IN-PULLING']]]);
    } else {
      this.tableControls = new TableControlService('ProjectPrep.Inventory', true, null, 'DESC', [['ip.inventory_prep_status', ['PULLING', 'CHECK-IN-PULLING']]]);
    }
    this.checkScreenSize();
    if(this.isMobile) {
      let activeFilters = this.tableControls.getActiveFilters()
      let columns = [];
      for (const [key, value] of activeFilters) {
        columns.push(key)
      }
      this.tableControls.resetFilters(columns)
    }
    this.tableControls.resetTableConfiguration();

    this.tableControls.refresh.subscribe(() => {
      this.getProjectPrepItems();
    });
    if (this.tableControls.hasTableConfiguration()) this.storedSearchValue = this.tableControls.getSearchTerm();
    this.getProjectPrepItems();

  }

  /* ----- Project Prep Items ----- */

  // Get Project Prep Items
  private getProjectPrepItems(): void {
    let params = this.tableControls.getParams();
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
      for (const [key, value] of this.activeFilters) {
        if (key.includes('range')) params[`filter:${key}`] = value;
        else params[`filter:${key}`] = JSON.stringify(value);
      }
    };
    this.inventoryPrepService.getInventoryPrep(params).subscribe((res) => {
      this.projectPrepItems = res.inventoryPrep;
      this.count = res.count;
    });
  }

  // Return Feature Item To Inventory
  returnFeatureItemToInventory(itemPrep: InventoryPrep): void {
    if (itemPrep.fromSplit) {
      this.inventoryPrepService.getInventoryPrepStatAndQty(itemPrep.fromSplit).subscribe((res) => {
        if (res.status == 'TO-BE-ORDERED') {
          const modalRef = this.modalService.open(ProjectPrepCancelSplitMsgComponent);
          modalRef.componentInstance.itemPrep = itemPrep;
          modalRef.componentInstance.itemName = itemPrep.itemName;
          modalRef.componentInstance.clickOn = 'Order Tab';
          modalRef.componentInstance.saved.subscribe((res) => {
            if (res == 1) {
              this.returnItem(itemPrep.itemId, itemPrep.id, itemPrep.quantity, itemPrep.fromSplit)
            }
          });
        } else {
          this.returnItem(itemPrep.itemId, itemPrep.id, itemPrep.quantity)
        }
      });
    } else {
      this.returnItem(itemPrep.itemId, itemPrep.id, itemPrep.quantity)
    }
  }

  returnItem(itemId: string, prepId: string, quantity: number, fromSplit?: string) {
    this.inventoryPrepService.updateInventoryPrepStatus(itemId, prepId, 'PENDING', quantity, fromSplit)
      .subscribe(() => {
        this.alertService.showSuccessAlert('Item Returned');
        this.getProjectPrepItems();
      });
  }

  // Check In Feature Item
  checkInFeatureItem(itemPrep: InventoryPrep): void {
    this.inventoryPrepService.updateInventoryPrepStatus(itemPrep.itemId, itemPrep.id, 'CHECK-IN-PULLING', 0).subscribe(() => {
      this.checkIfNeedsToMove(itemPrep.proposalId, itemPrep.projectId)
      // this.alertService.showSuccessAlert('Item Checked In');
      // this.getProjectPrepItems();
    });
  }

  checkIfNeedsToMove(proposalId: string, projectId: string) {
    this.inventoryPrepService.checkIfProjectNeedsToMove(proposalId, projectId)
      .subscribe((res: boolean) => {
        if (res) this.allCheck.emit(res);
        this.alertService.showSuccessAlert('Item Checked In');
        this.getProjectPrepItems();
      });
  }

  cancelCheckIn(itemPrep: InventoryPrep): void {
    this.inventoryPrepService.updateInventoryPrepStatus(itemPrep.itemId, itemPrep.id, 'PULLING', 0).subscribe(() => {
      this.alertService.showSuccessAlert('Check In Cancelled');
      this.getProjectPrepItems();
    });
  }

  /* ----- Filters ----- */

  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    this.selectedFilterColumn = column;
    let params = null;

    if (!this.columnFilterValues.has(column)) {
      params = {
        searchTerm: null,
        sortBy: column,
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        column: column,
        'filter:ip.inventory_prep_status': JSON.stringify(['PULLING', 'CHECK-IN-PULLING']),
      };

      if (this.projectId) {
        params = {
          ...params,
          'filter:ip.inventory_prep_project_id': JSON.stringify([this.projectId])
        }
      }

      if(this.isMobile) {
        for (const [key, value] of this.activeFilters) {
          if (key.includes('range')) params[`filter:${key}`] = value;
          else params[`filter:${key}`] = JSON.stringify(value);
        }
      }

      this.projectPrepService.getDistinctColumnValues(params).subscribe((values) => {
        const tempArray = [];
        for (const value of values) {
          let name = null;
          switch (column) {
            case 'ip.inventory_prep_status':
              name = this.projectPrepStatusPipe.transform(value);
              break;
            default:
              name = (value === null || value.length == 0) ? 'BLANK' : value;
              break;
          }
          tempArray.push({ name: name, value: value, randomId: Math.random().toString(36) });
        }
        this.columnFilterValues.set(column, tempArray);
      });
    }
  }

  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getProjectPrepItems();
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getProjectPrepItems();
    this.getActiveFilterCount();
  }

   // Get Active Filter Count
   getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getProjectPrepItems();
    this.getActiveFilterCount();
  }

  // Show Column Filter Popover
  showColumnFilterPopover(popover, column: string): void {
    this.getDistinctColumnValues(column);
    if (popover.isOpen()) {
      popover.close();
    } else {
      setTimeout(() => {
        popover.open({ column });
      }, 250);
    }
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
    this.isMobileView ? this.tableControls.setLimit(5): this.tableControls.setLimit(20);
  }

}
