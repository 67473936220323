import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Client } from '../client';
import { SiteService } from '../site.service';

@Component({
  selector: 'app-new-site-modal',
  templateUrl: './new-site-modal.component.html',
  styleUrls: ['./new-site-modal.component.css']
})
export class NewSiteModalComponent implements OnInit {

  // Properties
  @Input() client: Client;
  @Output() siteCreated: EventEmitter<string> = new EventEmitter<string>();
  siteForm: UntypedFormGroup;

  // Font Awesome Properties
  faCopy = faCopy;

  constructor(public modal: NgbActiveModal,
    private siteService: SiteService) { }

  ngOnInit(): void {
    this.siteForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      street: new UntypedFormControl(),
      city: new UntypedFormControl(),
      county: new UntypedFormControl(),
      state: new UntypedFormControl('SAS'),
      postalCode: new UntypedFormControl()
    });
  }

  // Copy Address
  copyAddress(): void {
    this.siteForm.controls.street.setValue(this.client.address.street);
    this.siteForm.controls.city.setValue(this.client.address.city);
    this.siteForm.controls.county.setValue(this.client.address.county);
    this.siteForm.controls.state.setValue(this.client.address.state);
    this.siteForm.controls.postalCode.setValue(this.client.address.postalCode);
  }

  // Add Site
  addSite(): void {
    if (this.siteForm.valid) {
      const site = {
        clientId: this.client.id,
        name: this.siteForm.value.name,
        address: {
          street: this.siteForm.value.street,
          city: this.siteForm.value.city,
          county: this.siteForm.value.county,
          state: this.siteForm.value.state,
          postalCode: this.siteForm.value.postalCode
        }
      };
      this.siteService.addSite(site).subscribe((siteId) => {
        this.modal.close();
        this.siteCreated.emit(siteId);
      });
    } else {
      this.siteForm.markAllAsTouched();
    }
  }

  // Site Form Accessors
  get name() { return this.siteForm.controls.name; }
}
