<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">User Settings</h2>
        </div>
    </nav>
    <div class="row mt-3">
        <div class="col-lg-4">
            <div class="card">
                <div class="card-header d-flex">
                    <h5>Password</h5>
                    <div class="ms-auto">
                        <button type="button" class="btn btn-primary" (click)="changeUserPassword();">
                            <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                            <span>Save</span>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <form [formGroup]="passwordForm">
                        <div class="mb-3">
                            <label for="CURRENT_PASSWORD">Current Password</label>
                            <input id="CURRENT_PASSWORD" type="password" class="form-control" formControlName="currentPassword" required autocomplete="current-password" [class.is-invalid]="currentPassword.invalid && (currentPassword.dirty || currentPassword.touched)">
                            <div *ngIf="currentPassword.invalid && (currentPassword.dirty || currentPassword.touched)">
                                <div *ngIf="currentPassword.errors.required" class="input-validation-msg">Current Password is required.</div>
                                <!-- <div *ngIf="currentPassword.errors.pattern" class="input-validation-msg">Current Password must be 8 characters and contain uppercase, lowercase, and number characters.</div> -->
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="NEW_PASSWORD">New Password</label>
                            <input id="NEW_PASSWORD" type="password" class="form-control" formControlName="newPassword" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required autocomplete="new-password" [class.is-invalid]="newPassword.invalid && (newPassword.dirty || newPassword.touched)">
                            <div *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)">
                                <div *ngIf="newPassword.errors.required" class="input-validation-msg">New Password is required.</div>
                                <div *ngIf="newPassword.errors.pattern" class="input-validation-msg">Current Password must be 8 characters and contain uppercase, lowercase, and number characters.</div>
                            </div>
                        </div>
                        <div>
                            <label for="CONFIRM_NEW_PASSWORD">Confirm New Password</label>
                            <input id="CONFIRM_NEW_PASSWORD" type="password" class="form-control" formControlName="confirmNewPassword" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required autocomplete="new-password" [class.is-invalid]="confirmNewPassword.invalid && (confirmNewPassword.dirty || confirmNewPassword.touched)">
                            <div *ngIf="confirmNewPassword.invalid && (confirmNewPassword.dirty || confirmNewPassword.touched)">
                                <div *ngIf="confirmNewPassword.errors.required" class="input-validation-msg">Confirm New Password is required.</div>
                                <div *ngIf="confirmNewPassword.errors.pattern" class="input-validation-msg">Current Password must be 8 characters and contain uppercase, lowercase, and number characters.</div>
                                <div *ngIf="confirmNewPassword.errors.doesNotMatch" class="input-validation-msg">Passwords do not match.</div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="card">
                <div class="card-header">
                    <h5>Table Configurations</h5>
                </div>
                <div class="card-body">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex">
                                <div>
                                    <h5 class="card-title">Reset</h5>
                                    <p class="card-text mb-0">Clear all stored table configurations in the event that there is an issue.</p>
                                </div>
                                <button type="button" class="btn btn-danger ms-auto my-auto" (click)="resetTableConfigurations();">Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4"></div>
    </div>
</div>