<div class="modal-header">
    <div>
        <h5 class="modal-title">Brand Name Registration
        </h5>
        <small>Using your Business name might help increase the approval</small>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="nameForm">
        <div class="row">
            <div class="col-sm-12">
                <div class="mb-3">
                    <div class="row mb-2">
                        <div class="col d-flex">
                            <label class="my-auto">Brand Name</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <input formControlName="name" class="form-control" type="text">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submit();" [disabled]="!nameForm.valid">Submit</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>