<div class="modal-header">
    <h5 class="modal-title">Subscription Invoice Details</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="subscriptionInvoiceForm">
        <div class="mb-2">
            <label for="SUBSCRIPTION_ID">Subscription</label>
            <!-- <select class="form-select" formControlName="subscriptionId"
                (change)="selectSubscription($any($event.target).value);">
                <option *ngFor="let subscription of subscriptions" [value]="subscription.subscriptionId">
                    {{subscription.name}}</option> 
                </select>
            -->
            <input id="SUBSCRIPTION_NAME" type="text" class="form-control" formControlName="subscriptionName">
        </div>
        <div class="mb-2">
            <label for="TRANSACTION_ID">Transaction ID</label>
            <input id="TRANSACTION_ID" type="text" class="form-control" placeholder="0000000000"
                formControlName="transactionId">
        </div>
        <div class="mb-2">
            <label for="QUANTITY">Quantity</label>
            <input id="QUANTITY" type="number" class="form-control" placeholder="1" formControlName="quantity"
                min="1">
        </div>
        <div class="mb-2">
            <label for="TOTAL">Total</label>
            <input id="TOTAL" type="number" class="form-control" placeholder="50.00" formControlName="total">
        </div>
        <div>
            <label for="CREATED_AT">Created At</label>
            <input id="CREATED_AT" type="datetime-local" class="form-control" formControlName="createdAt">
        </div>
        <div *ngIf="showReason">
            <label for="REFUND_REASON">Refund Reason</label>
            <textarea id="REFUND_REASON" class="form-control" formControlName="refundReason"
                placeholder="Reason for refund (Ticket number of applicable)" rows="3" maxlength="65535"></textarea>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="buttn" class="btn btn-secondary"
        (click)="showReason ? showReason = false:modal.dismiss();">Confirm</button>
</div>