import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

@Component({
  selector: 'app-subscription-refund-modal',
  templateUrl: './subscription-refund-modal.component.html',
  styleUrls: ['./subscription-refund-modal.component.css']
})
export class SubscriptionRefundModalComponent implements OnInit {
  @Input() organizationId!: string;
  @Input() subscriptionInvoice: any;
  subscriptionRefundForm!: FormGroup;

  constructor(
    public modal: NgbActiveModal,
    private fb: FormBuilder) { }


  ngOnInit(): void {
    console.log(this.subscriptionInvoice)
    const createdAt = dayjs(this.subscriptionInvoice.transaction_date).utc().format('YYYY-MM-DDTHH:mm');
    const requestedAt = dayjs(this.subscriptionInvoice.requested_at).utc().format('YYYY-MM-DDTHH:mm');
    this.subscriptionRefundForm = this.fb.group({
      transactionId: [this.subscriptionInvoice.transactionId],
      transactionDate: [createdAt],
      refundAmount: [this.subscriptionInvoice.refund_amount],
      requestedAt: [requestedAt],
      refundReason: [this.subscriptionInvoice.refund_reason],
      apiResponse: [this.subscriptionInvoice.api_response],
    });

    this.subscriptionRefundForm.disable()
  }

}
