<div class="modal-header">
    <h5 class="modal-title">Prospect</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form>
        <div class="row">
            <div class="col">
                <div class="mb-2">
                    <label for="FIRST_NAME">First Name</label>
                    <input id="FIRST_NAME" type="text" class="form-control" [value]="prospect.firstName" disabled>
                </div>
            </div>
            <div class="col">
                <div class="mb-2">
                    <label for="LAST_NAME">Last Name</label>
                    <input id="LAST_NAME" type="text" class="form-control" [value]="prospect.lastName" disabled>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="mb-2">
                    <label for="EMAIL">Email</label>
                    <input id="EMAIL" type="text" class="form-control" [value]="prospect.email" disabled>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="mb-2">
                    <label for="PHONE">Phone Number</label>
                    <input id="PHONE" type="text" class="form-control" maxlength="14" [value]="prospect.phone" disabled>
                </div>
            </div>
            <!-- <div class="col-lg-4">
                <div class="mb-2">
                    <label for="PHONE_EXTENSION">Extension</label>
                    <input id="PHONE_EXTENSION" type="text" class="form-control" maxlength="5" disabled>
                </div>
            </div> -->
        </div>
        <div class="row">
            <div class="col">
                <div class="mb-2">
                    <label for="ADDRESS_STREET">Street</label>
                    <input id="ADDRESS_STREET" type="text" class="form-control" [value]="prospect.address.street" disabled>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="mb-2">
                    <label for="ADDRESS_CITY">City</label>
                    <input id="ADDRESS_CITY" type="text" class="form-control" [value]="prospect.address.city" disabled>
                </div>
            </div>
            <!-- <div class="col-lg-6">
                <div class="mb-2">
                    <label for="ADDRESS_COUNTY">County</label>
                    <input id="ADDRESS_COUNTY" type="text" class="form-control" [value]="prospect.address.county" disabled>
                </div>
            </div> -->
        </div>
        <div class="row">
            <div class="col-lg-6">
                <label for="ADDRESS_STATE">State</label>
                <div class="mb-2">
                    <input id="ADDRESS_STATE" type="text" class="form-control" [value]="prospect.address.state" disabled>
                </div>
            </div>
            <div class="col-lg-6">
                <label for="ADDRESS_POSTAL_CODE">Postal Code</label>
                <div class="mb-2">
                    <input id="ADDRESS_POSTAL_CODE" type="text" class="form-control" [value]="prospect.address.postalCode" disabled>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-lg-6">
                <label for="STATUS">Status</label>
                <div class="mb-2">
                    <input id="STATUS" type="text" class="form-control" [value]="prospect.status" disabled>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="mb-2">
                    <label for="CREATED">Created</label>
                    <input id="CREATED" type="text" class="form-control" value="{{prospect.createdAt | formatDateTime}}" disabled>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col">
                <div class="mb-2">
                    <label for="DESCRIPTION">Description</label>
                    <textarea id="DESCRIPTION" class="form-control" style="overflow-y: scroll;" rows="3" [value]="prospect.description" disabled></textarea>
                </div>
            </div>
        </div>
        <div *ngIf="prospect.additionalFields.length > 0" class="row">
            <div class="col">
                <label>Additional Fields</label>
                <div *ngFor="let field of prospect.additionalFields; index as i;" class="input-group" [class.mt-2]="i > 0">
                    <input type="text" class="form-control" [value]="field.key" disabled>
                    <input type="text" class="form-control" [value]="field.value" disabled>
                </div>
            </div>
        </div>
        <div *ngIf="prospect.status == 'REJECTED' && prospect.rejectReason" class="row">
            <div class="col">
                <label for="REJECT_REASON_STATIC">Reject Reason</label>
                <textarea id="REJECT_REASON_STATIC" class="form-control" rows="3" [value]="prospect.rejectReason" disabled></textarea>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button *ngIf="prospect.status == 'PENDING'" type="button" class="btn btn-primary" (click)="acceptProspect(false);">Accept</button>
    <button *ngIf="prospect.status == 'PENDING'" type="button" class="btn wait-list-btn" (click)="acceptProspect(true);">Wait List</button>
    <button *ngIf="prospect.status == 'PENDING'" type="button" class="btn btn-danger" (click)="openRejectProspectConfirmationModal();">Reject</button>
    <button *ngIf="prospect.status == 'REJECTED'" type="button" class="btn btn-danger" (click)="openDeleteProspectConfirmationModal();">Delete</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>

<!-- Reject Prospect Confirmation Modal -->
<ng-template #REJECT_PROSPECT_CONFIRMATION_MODAL let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Confirm Rejection</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
    </div>
    <div class="modal-body">
        <p class="mb-2">Are you sure you would like to reject this prospect?</p>
        <div>
            <label for="REJECT_REASON" class="fw-bold">Reason (Optional)</label>
            <textarea id="REJECT_REASON" class="form-control" placeholder="This prospect was rejected because..." rows="3" maxlength="65535"></textarea>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="rejectProspect();">Confirm</button>
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
    </div>
</ng-template>