import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../alert.service';
import { SmsCallService } from '../sms-call.service';

@Component({
  selector: 'app-contact-person-form',
  templateUrl: './contact-person-form.component.html',
  styleUrls: ['./contact-person-form.component.css']
})
export class ContactPersonFormComponent implements OnInit{

  contactPersonForm!: FormGroup;
  jobPositionValues: any[] =[
    {
      "name": "Director",
      "value": "DIRECTOR"
    },
    {
      "name": "Gm",
      "value": "GM"
    },
    {
      "name": "Vp",
      "value": "VP"
    },
    {
      "name": "Ceo",
      "value": "CEO"
    },
    {
      "name": "Cfo",
      "value": "CFO"
    },
    {
      "name": "General Counsel",
      "value": "GENERAL COUNSEL"
    },
    {
      "name": "Other",
      "value": "OTHER"
    }
  ]
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private smsCallService: SmsCallService) { }

  ngOnInit(): void {
    this.contactPersonForm = this.formBuilder.group({
      business_title: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      job_position: [null, Validators.required],
      phone_number: [null, [Validators.required]]
    });
  }

  submitForm(){
    if(this.contactPersonForm.valid){

      let data = {...this.contactPersonForm.value}

      this.smsCallService.postAuthorizedRep(data).subscribe(() => {
        this.alertService.showSuccessAlert('Authorized contact person saved!');
      });
    }

    if(this.contactPersonForm.errors) {
      console.log(this.contactPersonForm.errors)
    }
  }

  closeModal(){
    this.close.emit("true")
  }

}
