import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import dayjs from 'dayjs';
import { faAngleLeft, faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { TimeEntryService } from '../time-entry.service';
import { TimeEntry } from '../time-entry';
import { User } from '../user';
import { UserService } from '../user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeEntryModalComponent } from '../time-entry-modal/time-entry-modal.component';
import { MapDisplayComponent } from '../map-display/map-display.component';
import { GeoPosition } from '../geo-position';
import { FormatDateTimePipe } from '../format-date-time.pipe';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-time-clock',
  templateUrl: './time-clock.component.html',
  styleUrls: ['./time-clock.component.css'],
  providers: [FormatDateTimePipe]
})
export class TimeClockComponent implements OnInit {

  // Properties
  timeEntries: TimeEntry[];
  totalDuration: number = 0;
  users: User[] = [];
  selectedUser: User;
  startDate: string;
  endDate: string;
  @ViewChild('MAP', { static: false }) private mapDisplay: MapDisplayComponent;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faTrashCan = faTrashCan;
  faPencil = faPencil;

  constructor(private timeEntryService: TimeEntryService,
    private userService: UserService,
    private modalService: NgbModal,
    private formateDateTimePipe: FormatDateTimePipe,
    public location: Location,
    private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.getUsers();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Date Changed
  dateChanged(event: { startDate: string, endDate: string }): void {
    this.loaderService.showSpinner();
    this.startDate = event.startDate;
    this.endDate = event.endDate;
    setTimeout(() => {
      if (this.selectedUser) this.getTimeClock();
    }, 250);
  }

  // Open Time Entry Modal
  openTimeEntryModal() {
    const modalReference = this.modalService.open(TimeEntryModalComponent);
    modalReference.componentInstance.user = this.selectedUser;
    modalReference.componentInstance.refresh.subscribe(() => {
      this.getTimeClock();
    });
  }

  // Get Users
  private getUsers(): void {
    this.userService.getUsers().subscribe((users) => {
      this.users = users;
      if (this.users.length > 0) {
        this.selectedUser = this.users[0];
        this.getTimeClock();
      }
    });
  }

  // Get Time Clock
  private getTimeClock(): void {
    this.timeEntryService.getTimeClock(this.selectedUser.id, dayjs(this.startDate).startOf('date').unix(), dayjs(this.endDate).endOf('date').unix()).subscribe(
      {
        next:  (res) => {
          this.timeEntries = res.timeEntries;
          this.totalDuration = res.totalDuration;
          this.generateMapFeatures();
          this.loaderService.hideSpinner(700);
        },
        error: () => {
          this.loaderService.hideSpinner(700);
        }
      }
     );
  }

  // Select User
  selectUser(userId: string): void {
    this.selectedUser = this.users.find((user) => { return user.id == userId; });
    this.getTimeClock();
  }

  // Select Time Entry
  selectTimeEntry(timeEntry: TimeEntry): void {
    const modalReference = this.modalService.open(TimeEntryModalComponent);
    modalReference.componentInstance.timeEntry = timeEntry;
    modalReference.componentInstance.refresh.subscribe(() => {
      this.getTimeClock();
    });
  }

  /* ----- Map Features ----- */

  // Generate Map Features
  private generateMapFeatures(): void {
    let collection: any[] = [];
    for (const timeEntry of this.timeEntries) {
      const startLocation = timeEntry.startLocation as GeoPosition;
      const endLocation = timeEntry.endLocation as GeoPosition;
      if (this.hasLocationData(startLocation) && startLocation.accuracy < 100) {
        collection.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [startLocation.longitude, startLocation.latitude]
          },
          properties: {
            timestamp: timeEntry.start,
            popupHtml: `<div><strong>${timeEntry.type}</strong></div><div><strong>${timeEntry.clientName}, ${timeEntry.projectName}</strong></div><div><strong>Start:</strong> ${this.formateDateTimePipe.transform(timeEntry.start, false)}</div><div><strong>Accuracy:</strong> ${startLocation.accuracy.toFixed(0)} Meters / ${(startLocation.accuracy * 3.281).toFixed(0)} Feet</div>`,
            color: '#198754'
          }
        });
      }
      if (this.hasLocationData(endLocation) && endLocation.accuracy < 100) {
        collection.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [endLocation.longitude, endLocation.latitude]
          },
          properties: {
            timestamp: timeEntry.end,
            popupHtml: `<div><strong>${timeEntry.type}</strong></div><div><strong>${timeEntry.clientName}, ${timeEntry.projectName}</strong></div><div><strong>End:</strong> ${this.formateDateTimePipe.transform(timeEntry.end, false)}</div><div><strong>Accuracy:</strong> ${endLocation.accuracy.toFixed(0)} Meters / ${(endLocation.accuracy * 3.281).toFixed(0)} Feet</div>`,
            color: '#dc3545'
          }
        });
      }
    }
    collection = collection.sort((f1, f2) => { return f1.properties.timestamp - f2.properties.timestamp });
    this.mapDisplay.addMapCollection('TimeClock', collection, true);
  }

  /* ----- Helper Functions ----- */

  // Has Location Data
  hasLocationData(geoPosition: GeoPosition): boolean {
    return geoPosition !== null && geoPosition.longitude !== null && geoPosition.latitude !== null && geoPosition.accuracy !== null;
  }

  // Format Duration
  formatDuration(duration: number): string {
    return dayjs.duration(duration, 'seconds').asHours().toFixed(2) + ' Hours';
  }

  // Format Date Time Input
  formatDateTimeInput(timestamp: number): string {
    return dayjs.unix(timestamp).format('YYYY-MM-DDTHH:mm');
  }
}
