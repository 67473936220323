import { Injectable } from '@angular/core';
import { WorkBook, WorkSheet, read, utils, writeFile } from 'xlsx';

interface Sheet {
  label: string;
  headers?: string[];
  values: any[];
}

@Injectable({
  providedIn: 'root'
})
export class ExcelDownloaderService {

  constructor() { }

  export(data, filename: string, sheetName: string, headers?: string[]): void {
    /* generate worksheet */
    const ws: WorkSheet = utils.json_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: WorkBook = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);

    if (headers && headers.length != 0) {
      utils.sheet_add_aoa(ws, [headers], { origin: "A1" });
    }
    /* save to file */
    writeFile(wb, `${filename}.xlsx`);
  }

  exportMultiple(dataArr: Sheet[], filename: string): void {
    /* generate workbook */
    const wb: WorkBook = utils.book_new();

    dataArr.forEach(data => {
      /* generate worksheet */
      const ws: WorkSheet = utils.json_to_sheet(data.values);
      utils.book_append_sheet(wb, ws, data.label);
    })

    /* save to file */
    writeFile(wb, `${filename}.xlsx`);
  }
}
