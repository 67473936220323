import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectStatus'
})
export class ProjectStatusPipe implements PipeTransform {

  transform(status: string): string {
    switch (status) {
      case 'IN PROGRESS':
        return 'In Progress';
      case 'COMPLETED':
        return 'Completed';
      case 'LOST':
        return 'Lost';
      case 'CANCELED':
        return 'Canceled';
      default:
        return 'Unknown';
    }
  }
}
