<div style="background: rgb(16, 94, 34); color: white;" class="in-call-div" *ngIf="inCommingCall && !isMobileDevice">
    <div class="row">
        <div class="col-12">
            <h5 class="modal-title">Incoming Call...
                <br />
                <!-- TODO query number -->
                <!-- <span *ngIf="contact.contact?.hasOwnProperty('clientId')">
                    ({{contact.contact?.firstName}} {{contact.contact?.lastName}})
                </span> -->
                <span>{{ currentContactDisplay() }}</span>
            </h5>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <div class="action-div">
                <button style="font-size: 15px;" type="button" class="btn btn-danger" aria-label="Close"
                    (click)="rejectCall()">
                    <fa-icon class="button-icon-center" [icon]="faPhoneSlash"></fa-icon> Reject Call
                </button>
                <button style="font-size: 15px;" type="button" class="btn btn-success mr-1" aria-label="Close"
                    (click)="acceptCall()">
                    <fa-icon class="button-icon-center" [icon]="faPhone"></fa-icon> Accept Call
                </button>
            </div>
        </div>
    </div>
    <!-- <div>
       <div class="row">
        <div class="col-sm-8">
            <span>{{contact?.number}}</span>
        </div>
        <div class="col-sm-4">
            <span *ngIf="isIncall && getElapsedTime(callDetails) as elapsed">
                {{elapsed.hours}} h {{elapsed.minutes}} m {{elapsed.seconds}} s
              </span>
        </div>
       </div>
    </div>  -->
</div>