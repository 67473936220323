import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-proposal-excess-modal',
  templateUrl: './proposal-excess-modal.component.html',
  styleUrls: ['./proposal-excess-modal.component.css']
})
export class ProposalExcessModalComponent {
  @Input() invoiceNumber: number;
  @Output() choice: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    public modal: NgbActiveModal
  ) { }

  submit(quantity: number) {
    this.choice.emit(quantity);
    this.modal.close();
  }
}

