import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { OrganizationSubscription } from '../organization-subscription';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { SettingService } from '../setting.service';

@Component({
  selector: 'app-subscription-alert-modal',
  templateUrl: './subscription-alert-modal.component.html',
  styleUrls: ['./subscription-alert-modal.component.css']
})
export class SubscriptionAlertModalComponent implements OnInit {
  @Output() okay: EventEmitter<OrganizationSubscription> = new EventEmitter<OrganizationSubscription>();
  @Input() type: string;
  @Input() message: string;

  faCheck = faCheck;

  constructor(
    private settingService: SettingService,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
   
  }

  closeModal() {
    this.modal.close();
    this.okay.emit();
  }
}
