import { Component, Input, OnInit, ViewChild, EventEmitter, Output, OnDestroy, QueryList, ElementRef, ViewChildren, HostListener } from '@angular/core';
import { Feature } from '../feature';
import { faEllipsis, faPencil, faPlus, faQuestionCircle, faSave, faTrashAlt, faGripVertical, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FeatureService } from '../feature.service';
import { FeatureItemService } from '../feature-item.service';
import { AlertService } from '../alert.service';
import { NgbModal, NgbModalRef, NgbPopover, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, debounceTime, distinctUntilChanged, lastValueFrom, merge, switchMap } from 'rxjs';
import { Proposal } from '../proposal';
import { FormArray, FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { FeatureItem } from '../feature-item';
import { InventoryService } from '../inventory.service';
import { Item } from '../item';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-proposal-builder',
  templateUrl: './proposal-builder.component.html',
  styleUrls: ['./proposal-builder.component.css']
})
export class ProposalBuilderComponent implements OnInit, OnDestroy {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Properties
  _proposal!: Proposal;
  @Input()
  set proposal(value: Proposal) {
    this._proposal = value;
    this.loadMultiOptions();
  }
  get proposal(): Proposal {
    return this._proposal;
  }
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  isMobileView: boolean = false;

  // Modals
  modalReference!: NgbModalRef;
  @ViewChild('featureModal') private featureModal: NgbModalRef;
  @ViewChild('featureItemModal') private featureItemModal: NgbModalRef;

  // Features
  features: Feature[] = [];
  rawFeatures: Feature[] = [];
  featureForms!: UntypedFormGroup;
  newFeatureForm!: UntypedFormGroup;
  selectedFeatureId!: string;

  // Feature Name Typeaheads
  featureNameTypeaheads: Map<string, { input: (text$: Observable<string>) => Observable<string[]>, focus$: Subject<string> }> = new Map<string, { input: (text$: Observable<string>) => Observable<string[]>, focus$: Subject<string> }>();

  // New Feature Name Typeahead
  newFeatureNameTypeaheadInput!: (text$: Observable<string>) => Observable<string[]>;
  newFeatureNameTypeaheadFocus$!: Subject<string>;

  // Feature Items
  featureItems: Map<string, FeatureItem[]> = new Map<string, FeatureItem[]>();
  featureItemForm!: UntypedFormGroup;
  selectedFeatureItem!: FeatureItem;
  featureItemPropertyUpdated$: Subject<any> = new Subject<any>();
  featureUpdated$: Subject<any> = new Subject<any>();

  // Inventory Items
  itemCategories: string[] = [];
  itemCategoryItems = new Map();
  selectedItemCategoryItems: Item[] = [];
  selectedItemStockLevels: { stockCurrentLevel: number, stockAdjustedLevel: number };

  // Multi Options
  multiOptionKeys: any[] = [];
  selectedMultiOptionKey: string;
  selectedMultiOptionPricing: any;

  // Font Awesome Properties
  faPlus = faPlus;
  faSave = faSave;
  faPencil = faPencil;
  faTrashAlt = faTrashAlt;
  faQuestionCircle = faQuestionCircle;
  faEllipsis = faEllipsis;
  faGripVertical = faGripVertical;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  // Actions
  isCollapsed = false;
  isAccepted: boolean;
  isSaving: boolean;


  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private featureService: FeatureService,
    private featureItemService: FeatureItemService,
    private inventoryService: InventoryService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private alertService: AlertService) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
        }
      });
    }

  async ngOnInit(): Promise<void> {
    this.isAccepted = this.proposal.status == 'ACCEPTED' || this.proposal.status == 'MODIFIED';

    this.featureForms = new UntypedFormGroup({});
    this.newFeatureForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      description: new UntypedFormControl(),
      installHours: new UntypedFormControl(),
      removalHours: new UntypedFormControl(),
      costHidden: new UntypedFormControl(false),
      isOptional: new UntypedFormControl(false)
    });
    this.featureItemForm = new UntypedFormGroup({
      inventoryItemCategory: new UntypedFormControl(),
      inventoryItem: new UntypedFormControl(),
      quantity: new UntypedFormControl(),
      type: new UntypedFormControl('NEW'),
      cost: new UntypedFormControl(),
      price: new UntypedFormControl(),
      multiPrice: new UntypedFormControl(),
      hidden: new UntypedFormControl(false),
      quantityHidden: new UntypedFormControl(true),
      imageType: new UntypedFormControl('NONE'),
      isTaxable: new UntypedFormControl()
    });
    this.featureItemPropertyUpdated$.pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((request) => {
        this.updateFeatureItemProperty(request.featureItemId, request.property, request.value, true);
      });

    this.prepareFeatureNameTypeahead();
    await this.getFeaturesAndFeatureItems();
    this.getItemCategories();
    if (this.isAccepted) {
      this.featureForms.disable();
      this.featureItemForm.disable();
      this.newFeatureForm.disable();
    } else {
      this.featureForms.enable();
      this.featureItemForm.enable();
      this.newFeatureForm.enable();
    }
    this.checkScreenSize();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.features, event.previousIndex, event.currentIndex);
    this.updateFeatureOrder(this.features, true)
  }

  getIndex(id: string) {
    return this.rawFeatures.findIndex(feat => feat.id === id)
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
    if (this.featureItemPropertyUpdated$) {
      this.featureItemPropertyUpdated$.unsubscribe();
    }
  }

  // Open Feature Modal
  openFeatureModal(): void {
    this.modalReference = this.modalService.open(this.featureModal);
    this.modalReference.dismissed.subscribe(() => {
      this.resetFeatureForm();
    });
  }

  // Open Feature Item Modal
  openFeatureItemModal(featureId: string, shouldReset: boolean = true): void {
    this.selectedFeatureId = featureId;
    if (shouldReset) this.resetFeatureItemForm();
    this.modalReference = this.modalService.open(this.featureItemModal);
    this.modalReference.dismissed.subscribe(() => {
      this.resetFeatureItemForm();
    });
  }

  // Open Delete Feature Confirmation Modal
  openDeleteFeatureConfirmationModal(featureId: string): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this feature?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteFeature(featureId);
    });
  }

  // Open Delete Feature Item Confirmation Modal
  openDeleteFeatureItemConfirmationModal(featureItem?: FeatureItem): void {
    if (featureItem) this.selectedFeatureItem = featureItem;
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this feature item?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteFeatureItem();
    });
  }

  /* ----- Data ----- */

  // Get Features And Feature Items
  public async getFeaturesAndFeatureItems(): Promise<void> {
    const tempFeature = ((await lastValueFrom(this.featureService.getFeatures({ searchTerm: null, sortBy: 'f.feature_order', sortDirection: 'ASC', limit: null, offset: null, 'filter:f.proposal_id': JSON.stringify([this.proposal.id]) }))) as any).features;
    this.features = tempFeature;
    this.rawFeatures = tempFeature;
    await this.createFeatureFormGroups();
    const featureItems = ((await lastValueFrom(this.featureItemService.getFeatureItems({ searchTerm: null, sortBy: 'fi.feature_item_name', sortDirection: 'ASC', limit: null, offset: null, 'filter:f.proposal_id': JSON.stringify([this.proposal.id]) }))) as any).featureItems;
    this.featureItems.clear();
    for (const featureItem of featureItems) {
      if (this.featureItems.has(featureItem.featureId)) {
        const tempArray = this.featureItems.get(featureItem.featureId);
        tempArray.push(featureItem);
        this.featureItems.set(featureItem.featureId, tempArray);
      } else {
        this.featureItems.set(featureItem.featureId, [featureItem]);
      }
    }
  }

  // Load Multi Options
  private loadMultiOptions(): void {
    if (!this.proposal.multiOptions) return;
    this.multiOptionKeys = Object.keys(this.proposal.multiOptions);
    this.selectedMultiOptionKey = this.multiOptionKeys[0];
  }

  // Get Feature Items For Feature
  getFeatureItems(featureId: string): FeatureItem[] {
    if (!this.featureItems.has(featureId)) return [];
    return this.featureItems.get(featureId);
  }

  // Get Feature Item Count
  getFeatureItemCount(featureId: string): number {
    if (!this.featureItems.has(featureId)) return 0;
    return this.featureItems.get(featureId).length;
  }

  /* ----- Features ----- */

  // Add Feature
  addFeature(): void {
    if (this.newFeatureForm.valid) {
      const feature = {
        proposalId: this.proposal.id,
        name: this.newFeatureForm.value.name,
        description: this.newFeatureForm.value.description,
        installHours: this.newFeatureForm.value.installHours,
        removalHours: this.newFeatureForm.value.removalHours,
        costHidden: this.newFeatureForm.value.costHidden,
        isOptional: this.newFeatureForm.value.isOptional || false,
        isSelected: this.newFeatureForm.value.isSelected || true,
        order: this.features.length + 1
      };
      this.featureService.addFeature(feature).subscribe(() => {
        this.alertService.showSuccessAlert('Feature Added');
        this.modalReference.close();
        this.saved.emit();
        this.getFeaturesAndFeatureItems();
        this.resetFeatureForm();
      });
    } else {
      this.newFeatureForm.markAllAsTouched();
    }
  };

  // Update Feature
  updateFeature(featureId: string, property: string, value: any, refresh: boolean = false): void {
    const formGroup = this.featureForms.controls[featureId] as UntypedFormGroup;
    if (formGroup.controls[property].invalid) {
      formGroup.controls[property].markAsTouched();
      return;
    }
    formGroup.disable()

    this.featureService.updateFeature(featureId, { [property]: value }).subscribe(() => {
      this.alertService.showSuccessAlert('Feature Updated');
      formGroup.enable()
      this.saved.emit();
      this.getFeaturesAndFeatureItems();
    });
  }

  // Update Feature Order
  updateFeatureOrder(features: any, refresh: boolean = false): void {
    if (features.length != 0) {
      this.featureService.updateFeatureOrder(features).subscribe(() => {
        this.alertService.showSuccessAlert('Feature Order Updated');
        if (refresh) this.saved.emit();
      });
    }
  }

  // Delete Feature
  private deleteFeature(featureId: string): void {
    this.featureService.deleteFeature(featureId).subscribe(() => {
      this.alertService.showSuccessAlert('Feature Deleted');
      this.saved.emit();
      this.getFeaturesAndFeatureItems();
    });
  }

  // Bulk Update Feature Property
  bulkUpdateFeatureProperty(property: string, value: boolean): void {
    this.featureService.bulkUpdateFeatureProperty(this.proposal.id, property, value).subscribe(() => {
      this.alertService.showSuccessAlert('Features Updated');
      this.saved.emit();
      this.getFeaturesAndFeatureItems();
    });
  }

  // Prepare Feature Name Typeahead
  private prepareFeatureNameTypeahead(): void {
    this.newFeatureNameTypeaheadFocus$ = new Subject<string>();
    this.newFeatureNameTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.newFeatureNameTypeaheadFocus$).pipe(switchMap((term) => {
        return this.getFeatureNames((term.length == 0) ? null : term);
      }));
    }
  }

  // Get Feature Name
  private getFeatureNames(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'feature_name',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'feature_name'
    };
    return this.featureService.getDistinctColumnValues(params);
  }

  // Reset Feature Form
  private resetFeatureForm(): void {
    this.newFeatureForm.reset();
    this.newFeatureForm.controls.name.setValue(null);
    this.newFeatureForm.controls.description.setValue(null);
    this.newFeatureForm.controls.installHours.setValue(null);
    this.newFeatureForm.controls.removalHours.setValue(null);
    this.newFeatureForm.controls.costHidden.setValue(false);
  }

  // New Feature Form Accessors
  get newFeatureName() { return this.newFeatureForm.controls.name; }
  get newFeatureInstallHours() { return this.newFeatureForm.controls.installHours; }
  get newFeatureRemovalHours() { return this.newFeatureForm.controls.removalHours; }

  // Create Feature Form Groups
  private async createFeatureFormGroups(): Promise<void> {
    this.featureNameTypeaheads.clear();
    for (const formGroup in this.featureForms.controls) this.featureForms.removeControl(formGroup);
    for (const feature of this.features) {
      const formGroup = this.fb.group({
        name: [feature.name],
        description: [feature.description],
        installHours: [feature.installHours],
        removalHours: [feature.removalHours],
        costHidden: [feature.costHidden],
        // included: [!feature.included],
        isOptional: [feature.isOptional],
        isSelected: [feature.isSelected],
        isHidden: [feature.isHidden]
      });

      // formGroup.patchValue({ name: feature.name }, { emitEvent: false })
      // formGroup.patchValue({ description: feature.description }, { emitEvent: false })
      // formGroup.patchValue({ installHours: feature.installHours }, { emitEvent: false })
      // formGroup.patchValue({ removalHours: feature.removalHours }, { emitEvent: false })
      // formGroup.patchValue({ costHidden: feature.costHidden }, { emitEvent: false })
      // formGroup.patchValue({ included: !feature.included }, { emitEvent: false })
      // formGroup.patchValue({ isOptional: feature.isOptional }, { emitEvent: false })
      // formGroup.patchValue({ isSelected: feature.isSelected }, { emitEvent: false })

      formGroup.controls.costHidden.valueChanges.pipe(debounceTime(250), distinctUntilChanged())
        .subscribe((value) => {
          if (value != feature.costHidden) this.updateFeature(feature.id, 'costHidden', value);
        });

      formGroup.controls.isHidden.valueChanges.pipe(debounceTime(250), distinctUntilChanged())
        .subscribe((value) => {
          if (value != feature.isHidden) this.updateFeature(feature.id, 'isHidden', value, true);
        });

      formGroup.controls.isOptional.valueChanges.pipe(debounceTime(250), distinctUntilChanged())
        .subscribe((value) => {
          if (value != feature.isOptional) this.updateFeature(feature.id, 'isOptional', value);
        });

      formGroup.controls.isSelected.valueChanges.pipe(debounceTime(250), distinctUntilChanged())
        .subscribe((value) => {
          if (value != feature.isSelected) this.updateFeature(feature.id, 'isSelected', value);
        });

      this.featureForms.registerControl(feature.id, formGroup);
      this.featureNameTypeaheads.set(feature.id, this.createFeatureNameTypeahead());

    }
  }

  // Create Feature Name Typeahead
  private createFeatureNameTypeahead(): { input: (text$: Observable<string>) => Observable<string[]>, focus$: Subject<string> } {
    const typeaheadFocus$ = new Subject<string>();
    const typeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, typeaheadFocus$).pipe(switchMap((term) => {
        return this.getFeatureNames((term.length == 0) ? null : term);
      }));
    };
    return { input: typeaheadInput, focus$: typeaheadFocus$ };
  }

  // Get Feature Name Typeahead Input
  getFeatureNameTypeaheadInput(featureId: string): (text$: Observable<string>) => Observable<string[]> {
    return this.featureNameTypeaheads.get(featureId).input;
  }

  // Feature Name Typeahead Focused
  featureNameTypeaheadFocused(featureId: string, event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.featureNameTypeaheads.get(featureId).focus$.next(value);
  }

  // Is Nested Feature Form Control Invalid
  isNestedFeatureFormControlInvalid(featureId: string, controlName: string): boolean {
    const formControl = (this.featureForms.controls[featureId] as UntypedFormGroup).controls[controlName];
    return formControl.invalid && (formControl.dirty || formControl.touched);
  }

  // Get Nested Feature Form Control Errors
  getNestedFeatureFormControlErrors(featureId: string, controlName: string): ValidationErrors {
    return (this.featureForms.controls[featureId] as UntypedFormGroup).controls[controlName].errors;
  }

  /* ----- Feature Items ----- */

  // Save Feature Item
  saveFeatureItem(): void {
    this.selectedFeatureItem ? this.updateFeatureItem() : this.addFeatureItem();
  }

  // Add Feature Item
  private addFeatureItem(): void {
    try {
      this.validateMultiPricing(this.selectedMultiOptionPricing);
      if (this.featureItemForm.valid) {
        const featureItem = {
          featureId: this.selectedFeatureId,
          itemId: this.featureItemForm.value.inventoryItem,
          quantity: this.featureItemForm.value.quantity,
          type: this.featureItemForm.value.type,
          cost: this.featureItemForm.value.cost,
          price: this.featureItemForm.value.price,
          multiPricing: this.selectedMultiOptionPricing,
          hidden: this.featureItemForm.value.hidden || false,
          quantityHidden: this.featureItemForm.value.quantityHidden,
          imageType: this.featureItemForm.value.imageType,
          isTaxable: this.featureItemForm.value.isTaxable || this.featureItemForm.value.isTaxable == null ? true:false
        };
        this.featureItemService.addFeatureItem(featureItem).subscribe(() => {
          this.alertService.showSuccessAlert('Feature Item Added');
          this.modalReference.close();
          this.saved.emit();
          this.getFeaturesAndFeatureItems();
          this.resetFeatureItemForm();
          // NULL Feature ID variable?
        });
      } else {
        this.featureItemForm.markAllAsTouched();
      }
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  };

  // Update Feature Item
  private updateFeatureItem(): void {
    try {
      this.validateMultiPricing(this.selectedMultiOptionPricing);
      if (this.featureItemForm.valid) {
        const featureItem = {
          id: this.selectedFeatureItem.id,
          itemId: this.featureItemForm.value.inventoryItem,
          quantity: this.featureItemForm.value.quantity,
          type: this.featureItemForm.value.type,
          cost: this.featureItemForm.value.cost,
          price: this.featureItemForm.value.price,
          multiPricing: this.selectedMultiOptionPricing,
          hidden: this.featureItemForm.value.hidden || false,
          quantityHidden: this.featureItemForm.value.quantityHidden,
          imageType: this.featureItemForm.value.imageType,
          isTaxable: this.featureItemForm.value.isTaxable  || this.featureItemForm.value.isTaxable == null ? true:false
        };
        this.featureItemService.updateFeatureItem(featureItem).subscribe(() => {
          this.alertService.showSuccessAlert('Feature Item Updated');
          this.modalReference.close();
          this.saved.emit();
          this.getFeaturesAndFeatureItems();
          this.resetFeatureItemForm();
          this.selectedFeatureItem = null;
        });
      } else {
        this.featureItemForm.markAllAsTouched();
      }
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  // Delete Feature Item
  private deleteFeatureItem(): void {
    this.featureItemService.deleteFeatureItem(this.selectedFeatureItem.id).subscribe(() => {
      this.alertService.showSuccessAlert('Feature Item Deleted');
      if (this.modalReference) this.modalReference.close();
      this.saved.emit();
      this.getFeaturesAndFeatureItems();
      this.resetFeatureItemForm();
    });
  }

  // Edit Feature Item
  async editFeatureItem(featureItem: FeatureItem): Promise<void> {
    this.selectedFeatureItem = featureItem;
    this.featureItemForm.controls.inventoryItemCategory.setValue(featureItem.itemCategory);
    await this.itemCategorySelected(featureItem.itemCategory);
    this.featureItemForm.controls.quantity.setValue(featureItem.quantity);
    this.featureItemForm.controls.type.setValue(featureItem.type);
    this.featureItemForm.controls.cost.setValue(featureItem.cost);
    this.featureItemForm.controls.price.setValue(featureItem.price);
    this.selectedMultiOptionPricing = featureItem.multiPricing;
    if (this.proposal.agreementType == 'MULTI') this.selectMultiOption('A');
    this.featureItemForm.controls.hidden.setValue(featureItem.hidden ? featureItem.hidden: false);
    this.featureItemForm.controls.quantityHidden.setValue(featureItem.quantityHidden);
    this.featureItemForm.controls.imageType.setValue(featureItem.imageType);
    this.featureItemForm.controls.isTaxable.setValue(featureItem.isTaxable != null ? featureItem.isTaxable: true);
    this.openFeatureItemModal(featureItem.featureId, false);
  }

  collapseFeatures() {
    this.features.map(feature => this.updateCollapse(feature, true));
  }

  expandFeatures() {
    this.features.map(feature => this.updateCollapse(feature, false));
  }

  updateCollapse(feature: Feature, isCollapse?: boolean) {
    this.featureService.updateFeature(feature.id, {'isCollapse': isCollapse != null ? isCollapse:!feature.isCollapse }).subscribe(() => {
      feature.isCollapse = !feature.isCollapse;
    })
  }

  // Toggle Feature Item Options Popover
  toggleFeatureItemOptionsPopover(popover: NgbPopover, featureItem: FeatureItem): void {
    if (popover.isOpen()) popover.close();
    else popover.open({ featureItem: featureItem });
  }

  // Adjust Feature Item Property
  adjustFeatureItemProperty(featureItemId: string, property: string, value: any, refresh: boolean = false): void {
    this.featureItemPropertyUpdated$.next({ featureItemId: featureItemId, property: property, value: value, refresh: refresh });
  }

  // Update Feature Item Property
  updateFeatureItemProperty(featureItemId: string, property: string, value: any, refresh: boolean = false): void {
    this.isSaving = true;
    this.featureItemService.updateFeatureItemProperty(featureItemId, property, value).subscribe(() => {

      if (refresh) this.saved.emit();
      this.alertService.showSuccessAlert('Feature Item Updated');
      this.isSaving = false;

      this.getFeaturesAndFeatureItems();
    });
  }

  // Bulk Update Feature Item Property
  bulkUpdateFeatureItemProperty(property: string, value: boolean, refresh: boolean = false): void {
    this.featureItemService.bulkUpdateFeatureItemProperty(this.proposal.id, property, value).subscribe(() => {
      this.alertService.showSuccessAlert('Feature Items Updated');
      this.saved.emit();
      this.getFeaturesAndFeatureItems();

    });
  }

  // Multi Option Is Enabled
  multiOptionIsEnabled(key: string): boolean {
    return this.proposal.multiOptions[key].isEnabled;
  }

  // Select Multi Option
  selectMultiOption(key: string): void {
    this.selectedMultiOptionKey = key;
    if (this.selectedMultiOptionPricing && this.selectedMultiOptionPricing[key]) {
      this.featureItemForm.controls.multiPrice.setValue(this.selectedMultiOptionPricing[key]);
    } else {
      this.featureItemForm.controls.multiPrice.setValue(null);
    }
  }

  // Multi Option Pricing Changed
  multiOptionPricingChanged(price: number): void {
    if (!this.selectedMultiOptionPricing) this.selectedMultiOptionPricing = {};
    this.selectedMultiOptionPricing[this.selectedMultiOptionKey] = price;
  }

  // Adjust Multi Option Price
  adjustMultiOptionPrice(featureItem: FeatureItem, key: string, value: number): void {
    const multiOptionPricing = featureItem.multiPricing;
    multiOptionPricing[key] = value;
    this.featureItemPropertyUpdated$.next({ featureItemId: featureItem.id, property: 'multiPricing', value: multiOptionPricing, refresh: true });
  }

  // Validate Multi Pricing
  private validateMultiPricing(multiPricing: any): void {
    const max = Infinity;
    const min = 0;
    for (const option in multiPricing) {
      const field = `Price (Multi) ${this.proposal.multiOptions[option].acronym}`;
      const value = multiPricing[option];
      if (value > max) {
        this.selectMultiOption(option);
        throw new Error(`${field} cannot be greater than ${max}.`);
      }
      if (value < min) {
        this.selectMultiOption(option);
        throw new Error(`${field} cannot be less than ${min}.`);
      }
    }
  }

  // Reset Feature Item Form
  private async resetFeatureItemForm(): Promise<void> {
    this.selectedFeatureItem = null;
    this.featureItemForm.reset();
    this.featureItemForm.controls.quantity.setValue(null);
    this.featureItemForm.controls.type.setValue('NEW');
    this.featureItemForm.controls.inventoryItemCategory.setValue(this.itemCategories[0]);
    await this.itemCategorySelected(this.itemCategories[0]);
    this.featureItemForm.controls.hidden.setValue(false);
    this.featureItemForm.controls.quantityHidden.setValue(true);
    this.featureItemForm.controls.imageType.setValue('NONE');
    this.featureItemForm.controls.isTaxable.setValue(true);
  }

  // Feature Item Form Accessors
  get featureItemQuantity() { return this.featureItemForm.controls.quantity; }
  get featureItemCost() { return this.featureItemForm.controls.cost; }
  get featureItemPrice() { return this.featureItemForm.controls.price; }
  get featureItemMultiPrice() { return this.featureItemForm.controls.multiPrice; }

  /* ----- Inventory ----- */

  // Get Item Categories
  private getItemCategories(): void {
    const params = {
      searchTerm: null,
      sortBy: 'i.item_category',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      column: 'i.item_category'
    };
    this.inventoryService.getDistinctColumnValues(params).subscribe((values) => {
      this.itemCategories = values;
    });
  }

  // Item Category Selected
  async itemCategorySelected(category: string, isAutoSelected: boolean = true): Promise<void> {
    if (!this.itemCategoryItems.has(category)) {
      const params = {
        searchTerm: null,
        sortBy: 'item_name',
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        'filter:i.item_category': JSON.stringify([category])
      };
      const res = await lastValueFrom(this.inventoryService.getItems(params));
      this.selectedItemCategoryItems = res.items;
      this.itemCategoryItems.set(category, this.selectedItemCategoryItems);
    } else {
      this.selectedItemCategoryItems = this.itemCategoryItems.get(category);
    }
    const itemId = (isAutoSelected && this.selectedFeatureItem) ? this.selectedFeatureItem.itemId : this.selectedItemCategoryItems[0].id;
    this.featureItemForm.controls.inventoryItem.setValue(itemId);
    this.itemSelected(itemId, isAutoSelected);
  }

  // Item Selected
  itemSelected(itemId: string, isAutoSelected: boolean): void {
    const item = this.selectedItemCategoryItems.find((item) => { return item.id == itemId; });
    if (item) {
      this.selectedItemStockLevels = { stockCurrentLevel: item.stockCurrentLevel, stockAdjustedLevel: item.stockAdjustedLevel };
      if (!this.selectedFeatureItem || (this.selectedFeatureItem && ((this.selectedFeatureItem.itemId != item.id) || !isAutoSelected))) {
        this.featureItemForm.controls.cost.setValue(item.cost);
        this.featureItemForm.controls.price.setValue(item.price);
        this.selectedMultiOptionPricing = item.multiPricing;
        if (this.proposal.agreementType == 'MULTI') this.selectMultiOption('A');
      }
    }
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }
}
