import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  // Send Proposal Email
  sendProposalEmail(data: any): Observable<any> {
    return this.http.post<any>(`/sendEmail/proposal`, data);
  }

  sendGenericEmail(data: any): Observable<any> {
    return this.http.post<any>(`/sendEmail/generic`, data);
  }

  // Send Invoice Email
  sendInvoiceEmail(data: any, forClient?: boolean): Observable<any> {
    if (forClient) {
      return this.http.post<any>(`/sendEmail/invoiceclient`, data);
    }
    return this.http.post<any>(`/sendEmail/invoice`, data);
  }

  // Send Payment Email
  sendPaymentEmail(data: any): Observable<any> {
    return this.http.post<any>(`/sendEmail/payment`, data);
  }

  // Send Review Email
  sendReviewEmail(data: any): Observable<any> {
    return this.http.post<any>(`/sendEmail/review`, data);
  }
}
