import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../alert.service';
import { ProposalService } from '../proposal.service';

@Component({
  selector: 'app-proposal-multi-agreement-type-options-modal',
  templateUrl: './proposal-multi-agreement-type-options-modal.component.html',
  styleUrls: ['./proposal-multi-agreement-type-options-modal.component.css']
})
export class ProposalMultiAgreementTypeOptionsModalComponent implements OnInit {

  // Properties
  @Input() private proposalId: string;
  @Input() private multiOptions: any;
  @Output() multiOptionsUpdated: EventEmitter<void> = new EventEmitter<void>();
  multiAgreementTypeOptions: any;

  constructor(private proposalService: ProposalService,
    private alertService: AlertService,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.multiAgreementTypeOptions = structuredClone(this.multiOptions);
  }

  // Form Input Changed
  formInputChanged(key: string, property: string, value: any): void {
    this.multiAgreementTypeOptions[key][property] = value;
    const title = this.multiAgreementTypeOptions[key].title;
    const acronym = this.multiAgreementTypeOptions[key].acronym;
    if ((!title || title.length == 0) || (!acronym || acronym.length == 0)) this.multiAgreementTypeOptions[key].isEnabled = false;
  }

  // Multi Agreement Type Option Is Valid
  multiAgreementTypeOptionIsValid(key: string): boolean {
    const title = this.multiAgreementTypeOptions[key].title;
    const acronym = this.multiAgreementTypeOptions[key].acronym;
    return (title !== null && title.length > 0) && (acronym !== null && acronym.length > 0);
  }

  // Update Proposal Multi Options
  updateProposalMultiOptions(): void {
    this.proposalService.updateProposalMultiOptions(this.proposalId, this.multiAgreementTypeOptions).subscribe(() => {
      this.alertService.showSuccessAlert('Multi Options Updated');
      this.multiOptionsUpdated.emit();
      this.modal.close();
    });
  }
}
