import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SmsCallSettingsService {

  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error.error);
    return throwError('Something bad happened; please try again later.');
  }

  initiateA2p(): Observable<any> {
    return this.http.get<any>(`/subacc/initiatea2p`).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  postClientAddress(data: any): Observable<any> {
    return this.http.post<any>(`/subacc/clientaddress`, data).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  getCheckCompliantV1(): Observable<any> {
    return this.http.get<any>(`/subacc/checkcompliantv1`).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  getSubmitRegistration(): Observable<any> {
    return this.http.get<any>(`/subacc/submitregistration`).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  getBrandRegistration(): Observable<any> {
    return this.http.get<any>(`/subacc/brandregistration`).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  getAddMessagingService(name: string): Observable<any> {
    return this.http.get<any>(`/subacc/addMessagingService/${name}`).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  submitTwiML(data: any): Observable<any> {
    return this.http.post<any>(`/subacc/createtwiml`, data).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  getUseCases(): Observable<any> {
    return this.http.get<any>(`/subacc/usecases`).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  postCreateCampaign(data: any): Observable<any> {
    return this.http.post<any>(`/subacc/createcampaign`, data).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  postConnectNumber(phonenumber: string, data: any): Observable<any> {
    return this.http.post<any>(`/subacc/connectnumber/${phonenumber}`, data).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }

  getCheckCompliantV2(): Observable<any> {
    return this.http.get<any>(`/subacc/checkcompliantv2`).pipe(
      map((res: any) => <any>res),
      catchError(this.handleError)
    );
  }
}
