import { Component, OnInit } from '@angular/core';
import { faCheck, faSave, faTrashCan, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from '../alert.service';
import { ProjectBase } from '../project-base';
import { SettingService } from '../setting.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-settings-projects',
  templateUrl: './settings-projects.component.html',
  styleUrls: ['./settings-projects.component.css']
})
export class SettingsProjectsComponent implements OnInit {
  
  // Modal
  private modalReference: NgbModalRef;

  // Properties
  bases: ProjectBase[] = [];

  // Font Awesome Properties
  faSave = faSave;
  faCheck = faCheck;
  faTrashCan = faTrashCan;
  faQuestion = faQuestion;

  constructor(private settingService: SettingService,
    private alertService: AlertService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getProjectBases();
  }

  // Open Modal
  openModal(content: any): void {
    this.modalReference = this.modalService.open(content);
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  /* ----- Bases ----- */

  // Get Project Bases
  private getProjectBases(): void {
    this.settingService.getProjectBases().subscribe((bases) => {
      this.bases = bases;
    });
  }

  // Add Project Base
  addProjectBase(): void {
    const content = (<HTMLInputElement>document.getElementById('NEW_BASE_CONTENT')).value;
    if (content) {
      this.settingService.addProjectBase(content).subscribe(() => {
        this.alertService.showSuccessAlert('Project Base Added');
        this.getProjectBases();
        (<HTMLInputElement>document.getElementById('NEW_BASE_CONTENT')).value = null;
      });
    }
  }

  // Delete Project Base
  deleteProjectBase(id: string) {
    this.settingService.deleteProjectBase(id).subscribe(() => {
      this.alertService.showSuccessAlert('Project Base Deleted');
        this.getProjectBases();
    });
  }
}
