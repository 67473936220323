<div class="card mt-3">
  <div class="card-header">
    <h5>Features & Feature Items</h5>
    <button class="btn btn-primary ms-auto" (click)="updateProjectStage();">
      <span>Next Stage</span>
      <fa-icon class="button-icon-right" [icon]="faArrowRight"></fa-icon>
    </button>
  </div>
  <div class="card-body">
    <div class="col-md-12 col-lg-12 col-xl-12 mt-2 pe-md-0 pe-xl-2">
      <div class="card h-100" style="max-height: 600px;">
          <div class="card-body" style="height: 535px">
              <div class="scrollable">
                  <ul *ngIf="!acceptedProposal" class="list-group">
                      <li class="list-group-item">No Accepted Proposal</li>
                  </ul>
                  <ul *ngFor="let feature of features; index as i;" class="list-group">
                      <li [class.mt-2]="i > 0" class="list-group-item" style="border-radius: 0.375rem;">
                        <div class="container-fluid header-container">
                          <div class="title-header">
                            <h4>{{feature.name}}</h4>
                            <h6 *ngIf="feature.description" class="text-muted" style="word-break: break-word;">{{feature.description}}</h6>
                          </div>
                          <div class="feature-status">
                            <span>
                              <span [ngbTooltip]="getInstallationTooltip(feature, feature?.isInstalled)" placement="auto" [ngClass]="feature?.isInstalled ? 'feature-status-desc-complete': 'feature-status-desc-incomplete'">{{feature?.isInstalled ? 'Complete': 'Incomplete'}}</span> &nbsp; <fa-icon class="nav-icon" (click)="getInstallationTooltip(feature, feature?.isInstalled)" [ngbTooltip]="getInstallationTooltip(feature, feature?.isInstalled)" [icon]="faInfoCircle"></fa-icon>  &nbsp; <input [id]="feature.id" class="form-check-input" type="checkbox" (change)="updateFetureInstallationStatus(feature);" [checked]="feature?.isInstalled">
                            </span>
                          </div>
                        </div>
                      </li>
                      <ul class="list-group">
                          <li class="list-group-item mt-2">
                              <div style="display: grid; grid-template-columns: repeat(4, minmax(0, 1fr));">
                                  <div style="grid-column: 1;" class="my-auto">
                                      <strong>Item</strong>
                                  </div>
                                  <div style="grid-column: 2;" class="text-center my-auto">
                                      <strong>Type</strong>
                                  </div>
                                  <div style="grid-column: 3;" class="text-center my-auto">
                                      <strong>Quoted<br>Qty</strong>
                                  </div>
                                  <div style="grid-column: 4;" class="text-center my-auto">
                                      <strong>Actual<br>Qty</strong>
                                  </div>
                              </div>
                          </li>
                          <li *ngFor="let featureItem of featureItems.get(feature.id); index as j;" class="list-group-item">
                              <div style="display: grid; grid-template-columns: repeat(4, minmax(0, 1fr));">
                                  <div style="grid-column: 1;" class="my-auto">
                                      <strong style="word-break: break-word;">{{featureItem.name}}</strong>
                                  </div>
                                  <div style="grid-column: 2;" class="text-center my-auto">
                                      <p>{{featureItem.type}}</p>
                                  </div>
                                  <div style="grid-column: 3;" class="text-center my-auto">
                                      <p>{{featureItem.quantity}}</p>
                                  </div>
                                  <div style="grid-column: 4;" class="my-auto">
                                      <input type="number" class="inline-input" style="width: 100%;" placeholder="0" step="1" min="0" [value]="featureItem.installedQuantity" (change)="updateFeatureItemInstalledQuantity($event, featureItem.id);">
                                  </div>
                              </div>
                          </li>
                      </ul>
                  </ul>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>

<!-- LOADER -->
<ngx-spinner>
  <p class="loading-spinner">Loading...</p>
</ngx-spinner>
