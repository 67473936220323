import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryPrep } from '../inventory-prep';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject, debounceTime, distinctUntilChanged, merge, switchMap } from 'rxjs';
import { InventoryService } from '../inventory.service';

@Component({
  selector: 'app-project-prep-order-modal',
  templateUrl: './project-prep-order-modal.component.html',
  styleUrls: ['./project-prep-order-modal.component.css']
})
export class ProjectPrepOrderModalComponent implements OnInit, AfterViewInit {
  //Screensize event Listener
 @HostListener('window:resize', ['$event'])
 onResize(event: Event) {
   this.resizeSubject.next((event.target as Window).innerWidth);
 }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  @Input() itemName: string;
  @Input() projectPrepItems: InventoryPrep[];
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();

  totalQuantity: number;

  orderForm: FormGroup;
  orderQuantity: number = 0;
  checkBoxOptions: any;
  selectAllCheckbox = false;
  isMobileView: boolean = false;

  // Source Typeahead
  sourceTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  sourceTypeaheadInputFocus$ = new Subject<string>();

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private inventoryService: InventoryService,
  ) {
    this.resizeSubject.pipe(
      debounceTime(100)
    ).subscribe(width => {
      if (width <= 768) {
        this.checkScreenSize(width);
      } else {
        this.isMobileView = false;
      }
    });
  }

  ngOnInit(): void {
    this.orderForm = this.formBuilder.group({
      orderQty: new UntypedFormControl(0, [Validators.min(this.orderQuantity)]),
      itemSource: new UntypedFormControl(this.projectPrepItems[0].itemSource),
      allItem: [false],
      choices: new FormArray([])
    });

    this.totalQuantity = this.updateOrMerge(this.projectPrepItems, 'quantity');
    this.prepareSourceTypeahead();


    this.orderForm.controls['allItem'].valueChanges.subscribe(value => {
      const choices = this.orderForm.controls['choices'] as FormArray;

      if (this.orderForm.controls['choices'].value.length != 0) {
        choices.controls.forEach((fg: FormGroup, index) => {
          fg.controls[`${index}-checkbox`].setValue(value)
          fg.controls['isSelected'].setValue(value)
        })
      }
      this.orderForm.controls['orderQty'].setValue(value ? this.totalQuantity : 0);
      this.orderForm.controls['orderQty'].setValidators([Validators.required, Validators.min(this.totalQuantity)]);
    });

    this.checkScreenSize();
  }

  ngAfterViewInit(): void {
    this.setupForm();
  }

  setupForm(): void {
    let newFG = new FormArray([]);

    this.projectPrepItems.map((item, index) => {
      const buildCtrl = this.formBuilder.group({
        isSelected: false,
        [index + '-checkbox']: false
      }) as FormGroup;

      const orgCtrl = Object.keys(item);
      orgCtrl.forEach(value => buildCtrl.addControl(value, new FormControl(item[value])))
      newFG.push(buildCtrl);

    });

    this.orderForm.controls['choices'] = newFG
    this.orderForm.controls['choices'].patchValue(newFG.value, { emitEvent: false })
  }

  updateOrMerge(arrayOne, key) {
    return arrayOne.reduce((acc, obj) => acc + obj[key], 0);
  };

  flattenArray(arrObj) {
    return arrObj.reduce((acc, obj) => acc + parseInt(obj), 0);
  };

  changeSource(value: string, itemPrepId: string) {
    console.log(value, itemPrepId)
  }

  onSubmit() {
    let orderQty = this.orderForm.controls['orderQty'].value;
    const choices = this.orderForm.controls['choices'] as FormArray;
    let orders = [];

    let chosenItems = choices.controls.filter(choice => choice.value['isSelected']).map(choice => choice.value)

    if (chosenItems.length > 1) {
      chosenItems = this.arraySorDesct(chosenItems, 'quantity');

      chosenItems.forEach((item, index) => {
        if ((chosenItems.length - 1) != index) {
          orders.push({
            inventoryPrepId: item.id,
            quantity: item.quantity,
            source: this.orderForm.controls['itemSource'].value
          });
          orderQty = orderQty - item.quantity;
        } else {
          orders.push({
            inventoryPrepId: item.id,
            quantity: orderQty,
            source: this.orderForm.controls['itemSource'].value
          });
        }

      })
    } else {
      orders = chosenItems.map(item => {
        return {
          inventoryPrepId: item.id,
          quantity: orderQty,
          source: this.orderForm.controls['itemSource'].value
        }
      })
    }
    this.saved.emit(orders);
    this.modal.close();
  }

  chosenQuantity(event, qty: number, index: number) {
    const choices = this.orderForm.controls['choices'] as FormArray;
    const isChecked = event.target.checked;
    const choiceGroup = choices.controls[index] as FormGroup;
    if (isChecked) {
      this.orderQuantity += qty;
    } else {
      this.orderQuantity -= qty;
    }
    choiceGroup.controls['isSelected'].setValue(isChecked);
    this.orderForm.get('orderQty').setValue(this.orderQuantity);
    this.orderForm.controls['orderQty'].setValidators([Validators.required, Validators.min(this.totalQuantity)]);
  }

  private prepareSourceTypeahead(): void {
    this.sourceTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.sourceTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getSources((term.length == 0) ? null : term);
      }));
    }
  }

  // Get Sources
  private getSources(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'i.item_source',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'i.item_source',
      hideBlanks: true
    };
    return this.inventoryService.getDistinctColumnValues(params);
  }

  arraySorDesct(arrObj, key) {
    return arrObj.sort((a, b) => b[key] - a[key]);
  }

  renderForm(): boolean {
    return this.orderForm.controls['choices'].value.length === this.projectPrepItems.length;
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }

  get itemSource() { return this.orderForm.controls.itemSource; }
  get orderQty() { return this.orderForm.controls.orderQty; }

}
