<div class="modal-header">
    <h5 class="modal-title">{{(timeEntry) ? 'Edit' : 'New'}} Time Entry</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="timeEntryForm">
        <div class="mb-3">
            <label for="TIME_ENTRY_CLIENT">Client</label>
            <input *ngIf="clientTypeaheadInput" id="TIME_ENTRY_CLIENT" type="text" class="form-control" formControlName="client" placeholder="Type to Search" [ngbTypeahead]="clientTypeaheadInput" (focus)="clientTypeaheadInputFocus$.next($any($event).target.value)" [resultFormatter]="clientResultFormatter" (selectItem)="clientSelected($event);">
        </div>
        <div class="mb-3">
            <label for="TIME_ENTRY_PROJECT">Project</label>
            <select id="TIME_ENTRY_PROJECT" class="form-select" formControlName="project" required [class.is-invalid]="project.invalid && (project.dirty || project.touched)">
                <option *ngFor="let project of projects" [value]="project.id">{{project.name}}</option>
            </select>
            <div *ngIf="project.invalid && (project.dirty || project.touched)">
                <div *ngIf="project.errors.required" class="input-validation-msg">Project is required.</div>
            </div>
        </div>
        <div class="mb-3">
            <label for="TIME_ENTRY_TYPE">Type</label>
            <select id="TIME_ENTRY_TYPE" class="form-select" formControlName="type">
                <option value="PREP IN WAREHOUSE">Prep - In Warehouse</option>
                <option value="PREP OUT WAREHOUSE">Prep - Out Warehouse</option>
                <option value="INSTALL">Installation</option>
                <option value="MAINTENANCE">Maintenance</option>
                <option value="REMOVE">Removal</option>
                <option value="POST REMOVE">Post Removal</option>
                <option value="TRANSPORTATION">Transportation</option>
            </select>
        </div>
        <div class="mb-3">
            <label for="TIME_ENTRY_START">Start</label>
            <input id="TIME_ENTRY_START" type="datetime-local" class="form-control" formControlName="start" required [class.is-invalid]="start.invalid && (start.dirty || start.touched)">
            <div *ngIf="start.invalid && (start.dirty || start.touched)">
                <div *ngIf="start.errors.required" class="input-validation-msg">Start is required.</div>
                <div *ngIf="start.errors.isAfterEnd" class="input-validation-msg">Start cannot be the same or after the end.</div>
            </div>
        </div>
        <div>
            <label for="TIME_ENTRY_END">End</label>
            <input id="TIME_ENTRY_END" type="datetime-local" class="form-control" formControlName="end" required [class.is-invalid]="end.invalid && (end.dirty || end.touched)">
            <div *ngIf="end.invalid && (end.dirty || end.touched)">
                <div *ngIf="end.errors.required" class="input-validation-msg">End is required.</div>
                <div *ngIf="end.errors.isBeforeStart" class="input-validation-msg">End cannot be the same or before the start.</div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveTimeEntry();">Save</button>
    <button *ngIf="timeEntry" type="button" class="btn btn-danger" (click)="openDeleteTimeEntryConfirmationModal();">Delete</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
</div>