<div class="modal-header">
    <div>
        <h5 class="modal-title">Canceling Split item</h5>
        <h5 class="text-muted">{{ itemName }}</h5>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <div class="row mt-2 align-items-center">
        <div class="col-md-10 mx-auto text-center">
            <p> You are choosing to send this item back to the Pending Tab. Since this is a Split item, the other part of this item that is in the {{ clickOn }} will also be sent back to the Pending Tab.   </p>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submit(1)">Ok</button>
    <button type="button" class="btn btn-secondary" (click)="submit()">Cancel</button>
</div>