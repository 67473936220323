import { Component, OnInit } from '@angular/core';
import { SettingService } from '../setting.service';
import { faBold, faCheck, faCopy, faRotate, faSave, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { EmailTemplate } from '../email-template';
import { AlertService } from '../alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-settings-emails',
  templateUrl: './settings-emails.component.html',
  styleUrls: ['./settings-emails.component.css']
})
export class SettingsEmailsComponent implements OnInit {

  // Email Settings Form
  editorForm: UntypedFormGroup;
  emailSettingsForm: UntypedFormGroup;
  prospectEmail: string;

  // Templates
  templates: EmailTemplate[] = [];
  selectedEmailTemplate: EmailTemplate = null;

  // Font Awesome Properties
  faBold = faBold;
  faCheck = faCheck;
  faRotate = faRotate;
  faCopy = faCopy;
  faSave = faSave;
  faQuestion = faQuestion;

  constructor(private settingService: SettingService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.editorForm = this.formBuilder.group({
      emailBody: [null]
    });
    this.emailSettingsForm = this.formBuilder.group({
      displayName: [''],
      replyTo: [''],
    });
    this.getEmailSendingSettings();
    this.getEmailReceivingSettings();
    this.getEmailTemplates();

  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Regenerate Prospects Email Confirmation Modal
  openRegenerateProspectsEmailConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure that you want to regenerate the Prospects Email?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Confirm";
    confirmationModalRef.componentInstance.actionBtnType = "PRIMARY";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.regenerateProspectsEmail();
    });
  }

  /* ----- Email Receiving ----- */

  // Regenerate Prospect Email
  private regenerateProspectsEmail(): void {
    this.settingService.regenerateProspectsEmail().subscribe(() => {
      this.alertService.showSuccessAlert('Prospects Email Regenerated');
      this.getEmailReceivingSettings();
    });
  }

  // Get Prospect Email
  private getEmailReceivingSettings(): void {
    this.settingService.getEmailReceivingSettings().subscribe((settings) => {
      if (settings.prospects.emailAddress) this.prospectEmail = settings.prospects.emailAddress;
      else this.prospectEmail = 'Not Configured';
    });
  }

  // Copy Prospects Email
  async copyProspectsEmail(): Promise<void> {
    try {
      await navigator.clipboard.writeText(this.prospectEmail);
    } catch (error) {
      this.alertService.showErrorAlert('Unable to Copy Prospects Email');
    }
  }

  /* ----- Email Sending ----- */

  // Get Email Sending Settings
  private getEmailSendingSettings(): void {
    this.settingService.getEmailSendingSettings().subscribe((settings) => {
      this.emailSettingsForm.controls.displayName.setValue(settings.displayName);
      this.emailSettingsForm.controls.replyTo.setValue(settings.replyTo);
    });
  }

  // Update Email Sending Settings
  updateEmailSendingSettings(): void {
    if (this.emailSettingsForm.valid) {
      const data: any = {
        displayName: this.emailSettingsForm.value.displayName,
        replyTo: this.emailSettingsForm.value.replyTo
      };
      this.settingService.updateEmailSendingSettings(data).subscribe(() => {
        this.alertService.showSuccessAlert('Email Sending Updated');
      });
    } else {
      this.emailSettingsForm.markAllAsTouched();
    }
  }

  /* ----- Email Templates ----- */

  // Get Email Templates
  private getEmailTemplates(): void {
    this.settingService.getEmailTemplates().subscribe((templates) => {
      this.templates = templates;
      if (this.selectedEmailTemplate === null) {
        if (templates.length > 0) this.emailTemplateSelected(templates[0].id);
      } else {
        this.emailTemplateSelected(this.selectedEmailTemplate.id);
      }
    });
  }

  // Update Email Template
  updateEmailTemplate(): void {
    const template = {
      id: this.selectedEmailTemplate.id,
      content: this.editorForm.controls.emailBody.value
    };
    this.settingService.updateEmailTemplate(template).subscribe(() => {
      this.alertService.showSuccessAlert('Email Template Updated');
      this.getEmailTemplates();
    });
  }

  // Email Template Selected
  emailTemplateSelected(templateId: string): void {
    const selectedTemplate = this.templates.find((template) => { return template.id == templateId; });
    this.selectedEmailTemplate = selectedTemplate;
    this.editorForm.controls.emailBody.setValue(selectedTemplate.content ? selectedTemplate.content.toString() : '')
    // this.emailBody = selectedTemplate.content.toString();
  }

  // Bold Selection
  boldSelection(): void {
    document.execCommand('bold');
  }
}
