import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Prospect } from '../prospect';
import { ProspectService } from '../prospect.service';
import { faCheck, faClock, faSortAmountDownAlt, faSortUp, faSortDown, faFilter, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { ProspectModalComponent } from '../prospect-modal/prospect-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-prospects',
  templateUrl: './dashboard-prospects.component.html',
  styleUrls: ['./dashboard-prospects.component.css']
})
export class DashboardProspectsComponent implements OnInit {

  // Modals
  private modalReference: NgbModalRef;

  // Prospects
  prospects: Prospect[] = [];
  searchTerm: string = null;
  sortBy: string = 'prospect_created_at';
  sortDirection: string = 'ASC';
  limit: number = null;
  offset: number = null;
  isRefreshing: boolean = false;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;

  // Font Awesome Properties
  faCheck = faCheck;
  faClock = faClock;
  faSortAmountDownAlt = faSortAmountDownAlt;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;
  faRotateRight = faRotateRight;

  constructor(private prospectService: ProspectService,
    private router: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.activeFilters.set('prospect_status', ['PENDING']);
    this.getActiveFilterCount();
    this.getProspects();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Prospect Selected
  prospectSelected(prospect: Prospect): void {
    this.modalReference = this.modalService.open(ProspectModalComponent, { scrollable: true });
    this.modalReference.componentInstance.prospect = prospect;
    this.modalReference.componentInstance.saved.subscribe(() => {
      this.getProspects();
    });
  }

  // Get Prospects
  private getProspects(): void {
    let params = {
      searchTerm: this.searchTerm,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
      limit: this.limit,
      offset: this.offset
    };
    for (const [key, values] of this.activeFilters) {
      params[`filter:${key}`] = JSON.stringify(values);
    }
    this.isRefreshing = true;
    this.prospectService.getProspects(params).subscribe((res) => {
      this.prospects = res.prospects;
      this.isRefreshing = false;
    });
  }

  // Refresh Prospects
  refreshProspects(): void {
    this.getProspects();
  }

  // Accept Prospect
  acceptProspect(prospectId: string, waitList: boolean): void {
    this.prospectService.acceptProspect(prospectId, waitList).subscribe((clientId) => {
      this.router.navigateByUrl(`/clients/${clientId}`);
    });
  }

  /* ----- Sorting ----- */

  // Sort
  sort(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getProspects();
  }

  /* ----- Filters ----- */

  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    this.prospectService.getDistinctColumnValues(column).subscribe((values) => {
      const tempArray = [];
      for (const value of values) {
        let name = null;
        switch (column) {
          case 'prospect_status':
            if (value == 'PENDING') name = 'Pending';
            else if (value == 'ACCEPTED') name = 'Accepted';
            else name = 'Rejected';
            break;
          default:
            name = (value === null || value.length == 0) ? 'BLANK' : value;
            break;
        }
        tempArray.push({ name: name, value: value, randomId: Math.random().toString(36) });
      }
      this.columnFilterValues.set(column, tempArray);
    });
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getProspects();
    this.getActiveFilterCount();
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getProspects();
    this.getActiveFilterCount();
  }

  // Get Active Filter Count
  getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }
}
