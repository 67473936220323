import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { faAngleLeft, faFileInvoiceDollar, faProjectDiagram, faUsers, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { User } from '../user';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})

export class ReportsComponent implements OnInit {

  user: User;
  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faUsers = faUsers;
  faFileInvoiceDollar = faFileInvoiceDollar;
  faProjectDiagram = faProjectDiagram;
  faCalendar = faCalendar;

  constructor(public location: Location, private authService: AuthService,) { }

  async ngOnInit(): Promise<void> {
    this.user = await this.authService.getCurrentUser();
  }
}