<div style="background: rgb(16, 94, 34); color: white;" class="in-call-div" *ngIf="isIncall || ringing">
    <div class="row">
        <div class="col-8">
            <h6 class="modal-title">{{!isIncall ? 'Ringing...' : 'In a Call'}} <span>
                    {{isIncoming ? '(Incoming call)' : '(Outgoing call)'}}
                </span></h6>
        </div>
        <div class="col-4 text-end">
            <button style="font-size: 12px;
            border: 1px solid white;
            color: rgb(16, 94, 34);
            background: white;" type="button" class="btn " aria-label="Close" (click)="mute()">
                <fa-icon class="button-icon-center" [icon]="isCallMute ? faMicrophoneSlash : faMicrophone"></fa-icon>
            </button>
            <button style="font-size: 12px;" type="button" class="btn btn-danger ms-2" aria-label="Close"
                (click)="endCall()">
                <fa-icon class="button-icon-center" [icon]="faPhone"></fa-icon>
            </button>
        </div>
    </div>
    <hr>
    <div>
        <div class="row">
            <div class="col-sm-12">
                {{currentContactDisplay()}}
                <!-- <span *ngIf="isIncoming">{{activeCall?.parameters.From}}</span>
            <span *ngIf="!isIncoming">{{ currentContactDisplay()}}</span> -->
            </div>
            <div class="col-sm-12">
                <span *ngIf="isIncall && getElapsedTime(callDetails) as elapsed">
                    {{elapsed.hours}} h {{elapsed.minutes}} m {{elapsed.seconds}} s
                </span>
            </div>
        </div>
    </div>
</div>