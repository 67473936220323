import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { faArrowCircleUp, faTools, faArrowCircleDown, faCalendarDay, faQuestionCircle, faCheck, faSquareCaretDown, faSquareCaretUp, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { AlertService } from '../alert.service';
import { ScheduleItem } from '../schedule-item';
import { ScheduleService } from '../schedule.service';
import { User } from '../user';
import { UserService } from '../user.service';

@Component({
  selector: 'app-bulk-scheduling-modal',
  templateUrl: './bulk-scheduling-modal.component.html',
  styleUrls: ['./bulk-scheduling-modal.component.css']
})
export class BulkSchedulingModalComponent implements OnInit {

  // Properties
  scheduleItems: ScheduleItem[] = []
  scheduleItemUsersMap: Map<string, string[]> = new Map<string, string[]>();
  lastPosition: number = 1;
  users: User[] = [];
  bulkSelectedUsers: Set<string> = new Set<string>();
  bulkScheduleForm: FormGroup;
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();

  // Font Awesome Properties
  faQuestionCircle = faQuestionCircle;
  faCheck = faCheck;
  faSquareCaretUp = faSquareCaretUp;
  faSquareCaretDown = faSquareCaretDown;
  faTrashCan = faTrashCan;

  constructor(private userService: UserService,
    private scheduleService: ScheduleService,
    private alertService: AlertService,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.bulkScheduleForm = new UntypedFormGroup({});
    this.getUsers();
    this.createScheduleItemFormGroups();
  }

  /* ----- Users ----- */

  // Get Users
  private getUsers(): void {
    this.userService.getUsers().subscribe((users) => {
      this.users = users.filter(user => !user.isArchived);
    });
  }

  // Select User
  selectUser(scheduleItemId: string, userId: string): void {
    if (this.scheduleItemUsersMap.has(scheduleItemId)) {
      let usersArr = this.scheduleItemUsersMap.get(scheduleItemId);
      if (usersArr.includes(userId)) usersArr = usersArr.filter((id) => { return id != userId; });
      else usersArr.push(userId);
      (usersArr.length > 0) ? this.scheduleItemUsersMap.set(scheduleItemId, usersArr) : this.scheduleItemUsersMap.delete(scheduleItemId);
    } else {
      this.scheduleItemUsersMap.set(scheduleItemId, [userId]);
    }
  }

  // Is User Selected
  isUserSelected(scheduleItemId: string, userId: string): boolean {
    if (!this.scheduleItemUsersMap.has(scheduleItemId)) return false;
    return this.scheduleItemUsersMap.get(scheduleItemId).includes(userId);
  }

  // Select Bulk User
  selectBulkUser(userId: string): void {
    if (this.bulkSelectedUsers.has(userId)) this.bulkSelectedUsers.delete(userId);
    else this.bulkSelectedUsers.add(userId);
  }

  // Is Bulk User Selected
  isBulkUserSelected(userId: string): boolean {
    return this.bulkSelectedUsers.has(userId);
  }

  // Get User Count
  getUserCount(scheduleItemId: string): number {
    return (this.scheduleItemUsersMap.has(scheduleItemId)) ? this.scheduleItemUsersMap.get(scheduleItemId).length : 0;
  }

  /* ----- Schedule Items ----- */

  // Create Schedule Item Form Groups
  private createScheduleItemFormGroups(): void {
    for (const scheduleItem of this.scheduleItems) {
      const formGroup = new UntypedFormGroup({
        generateInvoice: new UntypedFormControl(),
        date: new UntypedFormControl(),
        duration: new UntypedFormControl(),
        isAllDay: new UntypedFormControl(true),
        notes: new UntypedFormControl()
      });
      if (scheduleItem.status == 'UNSCHEDULED') {
        // scheduleItem.position = this.lastPosition++;
        formGroup.controls.generateInvoice.setValue(scheduleItem.type == 'INSTALL' && scheduleItem.acceptedProposalExists);
        if (scheduleItem.type != 'INSTALL' || !scheduleItem.acceptedProposalExists) formGroup.controls.generateInvoice.disable();
      }
      this.bulkScheduleForm.registerControl(scheduleItem.id, formGroup);
    }
  }

  // Apply Bulk Schedule Final Invoice
  applyBulkScheduleFinalInvoice(): void {
    const checked = (document.getElementById('BULK_SCHEDULE_FINAL_INVOICE') as HTMLInputElement).checked;
    for (const scheduleItem of this.scheduleItems) {
      if (scheduleItem.type != 'INSTALL' || !scheduleItem.acceptedProposalExists) continue;
      const form = (this.bulkScheduleForm.controls[scheduleItem.id] as UntypedFormGroup);
      form.controls.generateInvoice.setValue(checked);
    }
  }

  // Apply Bulk Schedule Date
  applyBulkScheduleDate(): void {
    const date = (document.getElementById('BULK_SCHEDULE_DATE') as HTMLInputElement).value;
    for (const scheduleItem of this.scheduleItems) {
      const form = (this.bulkScheduleForm.controls[scheduleItem.id] as UntypedFormGroup);
      form.controls.date.setValue(date);
    }
  }

  // Apply Bulk Schedule Users
  applyBulkScheduleUsers(): void {
    if (this.bulkSelectedUsers.size === 0) {
      this.scheduleItemUsersMap.clear();
    } else {
      for (const scheduleItem of this.scheduleItems) this.scheduleItemUsersMap.set(scheduleItem.id, Array.from(this.bulkSelectedUsers));
    }
  }

  // Bulk Update Schedule Items
  bulkUpdateScheduleItems(): void {
    if (this.scheduleItems.length === 0) {
      this.alertService.showWarningAlert('No Schedule Items Selected');
      this.modal.close();
      return;
    }
    if (this.bulkScheduleForm.valid) {
      const scheduleItemUpdateRequests: any[] = [];
      for (const scheduleItem of this.scheduleItems) {
        const form = (this.bulkScheduleForm.controls[scheduleItem.id] as UntypedFormGroup);
        const updateRequest: any = {
          id: scheduleItem.id,
          clientId: scheduleItem.clientId,
          projectId: scheduleItem.projectId,
          generateInvoice: form.controls.generateInvoice.getRawValue(),
          name: scheduleItem.name,
          date: form.value.date,
          duration: form.value.duration,
          isAllDay: form.value.isAllDay,
          userIds: (this.scheduleItemUsersMap.has(scheduleItem.id)) ? this.scheduleItemUsersMap.get(scheduleItem.id) : null,
          notes: form.value.notes
        };
        scheduleItemUpdateRequests.push(updateRequest);
      }
      this.scheduleService.bulkUpdateScheduleItems(scheduleItemUpdateRequests).subscribe(() => {
        this.alertService.showSuccessAlert('Schedule Items Updated');
        this.scheduleService.scheduleUpdated.next();
        this.saved.emit();
        this.modal.close();
      });
    } else {
      this.bulkScheduleForm.markAllAsTouched();
    }
  }

  // Swap Positions
  swapPositions(currScheduleItem: ScheduleItem, direction: 'UP' | 'DOWN'): void {
    const currPos = currScheduleItem.position;
    const swapPos = currScheduleItem.position + ((direction == 'UP') ? -1 : 1);
    const swapScheduleItemId = this.scheduleItems.find((scheduleItem) => { return scheduleItem.position == swapPos; }).id;
    for (const scheduleItem of this.scheduleItems) {
      if (scheduleItem.id == currScheduleItem.id) scheduleItem.position = swapPos;
      if (scheduleItem.id == swapScheduleItemId) scheduleItem.position = currPos;
    }
    this.scheduleItems = this.scheduleItems.sort((s1, s2) => { return s1.position - s2.position; });
  }

  // Remove Schedule Item
  removeScheduleItem(scheduleItem: ScheduleItem): void {
    this.scheduleItems = this.scheduleItems.filter((sc) => { return sc.id != scheduleItem.id; });
  }

  // Toggle All Day
  toggleAllDay(scheduleItemId: string): void {
    const form = (this.bulkScheduleForm.controls[scheduleItemId] as UntypedFormGroup);
    if (form.value.date === null) return;
    setTimeout(() => {
      if (form.value.isAllDay) form.controls.date.setValue(dayjs(form.value.date).format('YYYY-MM-DD'));
      else form.controls.date.setValue(dayjs(form.value.date).format('YYYY-MM-DDTHH:mm'));
    }, 125);
  }

  // Get End Date Time
  getEndDateTime(scheduleItemId: string): string {
    const form = (this.bulkScheduleForm.controls[scheduleItemId] as UntypedFormGroup);
    return dayjs(form.value.date).add(form.value.duration, 'hours').format('MM/DD/YYYY, h:mm A');
  }

  /* ----- Helper Functions ----- */

  // Get Nested Form Control
  getNestedFormControl(scheduleItemId: string, controlName: string): any {
    return (this.bulkScheduleForm.controls[scheduleItemId] as UntypedFormGroup).controls[controlName];
  }

  // Get Schedule Item Type Icon
  getScheduleItemTypeIcon(type: string) {
    switch (type) {
      case 'INSTALL':
        return faArrowCircleUp;
      case 'MAINTENANCE':
        return faTools;
      case 'REMOVE':
        return faArrowCircleDown;
      case 'CUSTOM':
        return faCalendarDay;
    }
  }
}
