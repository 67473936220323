<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom"
                class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">Proposals</h2>
        </div>
        <div class="row">
            <div class="col-auto">
                <app-date-picker-controller #datePickerController componentId="Reports.Proposals" preset="YTD"
                    [showButtons]="false" (dateChanged)="dateChanged($event);" [onLoad]="true"></app-date-picker-controller>
            </div>
        </div>
    </nav>
    <div class="row mt-3">
        <div class="card p-0">
            <div class="card-body">

                <div class="row">
                    <div class="col-11">
                        <h5 class="card-title">Description</h5>
                        <p class="card-text m-0">This report can be used to review proposals for the year.</p>
                    </div>
                    <div class="col-1 text-end">
                        <button type="button" class="btn btn-primary" (click)="exportProposal();">
                            <fa-icon [icon]="faFileDownload"></fa-icon><span
                                class="ms-2 d-none d-md-inline">Export</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row button-container mt-2" *ngIf="isMobile">
      <div class="sort"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>
      <div class="filter"><ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container></div>
    </div>

    <div class="row mt-3">
        <div class="col table-responsive">
            <table *ngIf="!isMobile">
                <thead>
                    <tr>
                        <!-- Client -->
                        <th>
                            <div class="d-flex" style="position: relative;">
                                <div class="header-filter-container" [ngbPopover]="popContentFilter"
                                    [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside"
                                    #proposalClient="ngbPopover"
                                    (click)="showColumnFilterPopover(proposalClient, 'c.client_name');">
                                    <span *ngIf="tableControls.hasActiveFilter('c.client_name')"
                                        class="custom-badge">{{tableControls.getColumnActiveFilterCount('c.client_name')}}</span>
                                    <fa-icon [icon]="faFilter"></fa-icon>
                                </div>
                                <div class="w-100 px-3" (click)="tableControls.sort('c.client_name');">
                                    Client
                                </div>
                                <fa-icon *ngIf="tableControls.isSortedBy('c.client_name')" class="header-sort-container"
                                    [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                            </div>
                        </th>
                        <!-- Project -->
                        <th>
                            <div class="d-flex" style="position: relative;">
                                <div class="header-filter-container" [ngbPopover]="popContentFilter"
                                    [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside"
                                    #proposalProject="ngbPopover"
                                    (click)="showColumnFilterPopover(proposalProject, 'proj.project_name');">
                                    <span *ngIf="tableControls.hasActiveFilter('proj.project_name')"
                                        class="custom-badge">{{tableControls.getColumnActiveFilterCount('proj.project_name')}}</span>
                                    <fa-icon [icon]="faFilter"></fa-icon>
                                </div>
                                <div class="w-100 px-3" (click)="tableControls.sort('proj.project_name');">
                                    Project
                                </div>
                                <fa-icon *ngIf="tableControls.isSortedBy('proj.project_name')"
                                    class="header-sort-container"
                                    [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                            </div>
                        </th>
                        <!-- Name -->
                        <th>Name</th>
                        <!-- Tax -->
                        <th>Tax</th>
                        <!-- Subtotals -->
                        <th>Subtotal(s)</th>
                        <!-- Status -->
                        <th>
                            <div class="d-flex" style="position: relative;">
                                <div class="header-filter-container" [ngbPopover]="popContentFilter"
                                    [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside"
                                    #proposalStatus="ngbPopover"
                                    (click)="showColumnFilterPopover(proposalStatus, 'prop.proposal_status');">
                                    <span *ngIf="tableControls.hasActiveFilter('prop.proposal_status')"
                                        class="custom-badge">{{tableControls.getColumnActiveFilterCount('prop.proposal_status')}}</span>
                                    <fa-icon [icon]="faFilter"></fa-icon>
                                </div>
                                <div class="w-100 px-3" (click)="tableControls.sort('prop.proposal_status');">
                                    Status
                                </div>
                                <fa-icon *ngIf="tableControls.isSortedBy('prop.proposal_status')"
                                    class="header-sort-container"
                                    [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
                            </div>
                        </th>
                        <!-- Last Emailed -->
                        <th>Last Emailed</th>
                        <!-- Created -->
                        <th (click)="tableControls.sort('prop.proposal_created_at');">
                            Created
                            <fa-icon *ngIf="tableControls.isSortedBy('prop.proposal_created_at')"
                                [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"
                                class="sortButton"></fa-icon>
                        </th>
                        <!-- Updated -->
                        <th (click)="tableControls.sort('prop.proposal_updated_at');">
                            Updated
                            <fa-icon *ngIf="tableControls.isSortedBy('prop.proposal_updated_at')"
                                [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"
                                class="sortButton"></fa-icon>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let proposal of proposals" routerLink="/proposals/{{proposal.id}}">
                        <td data-label="Client">{{proposal.clientName}}</td>
                        <td data-label="Project">{{proposal.projectName}}</td>
                        <td data-label="Name">
                            <div style="display: grid; grid-template-columns: auto 1fr;">
                                <div class="d-flex" style="grid-column: 1; width: 16px;">
                                    <fa-icon *ngIf="proposal.status == 'ACCEPTED' && proposal.isDiffPriceFromAP"
                                        [icon]="faTriangleExclamation" style="color: var(--bs-red);"></fa-icon>
                                </div>
                                <div style="grid-column: 2;">
                                    <div>{{proposal.name}}</div>
                                </div>
                                <div style="grid-column: 3; width: 16px;"></div>
                            </div>
                        </td>
                        <td data-label="Tax">{{proposal.taxMultiplier | percent: '1.0-3'}}</td>
                        <td *ngIf="proposal.agreementType == 'SINGLE'" data-label="Subtotal(s)">{{proposal.pricing.total | currency}}</td>
                        <td *ngIf="proposal.agreementType == 'MULTI'" data-label="Subtotal(s)">
                            <span
                                *ngIf="proposal.multiOptions.A.isEnabled && proposal.pricing.multiPricing.A">{{proposal.multiOptions.A.acronym}}:
                                {{proposal.pricing.multiPricing.A.subtotal | currency}}</span>
                            <span *ngIf="proposal.multiOptions.B.isEnabled && proposal.pricing.multiPricing.B"
                                class="ms-2">{{proposal.multiOptions.B.acronym}}:
                                {{proposal.pricing.multiPricing.B.subtotal | currency}}</span>
                            <span *ngIf="proposal.multiOptions.C.isEnabled && proposal.pricing.multiPricing.C"
                                class="ms-2">{{proposal.multiOptions.C.acronym}}:
                                {{proposal.pricing.multiPricing.C.subtotal | currency}}</span>
                            <span *ngIf="proposal.multiOptions.D.isEnabled && proposal.pricing.multiPricing.D"
                                class="ms-2">{{proposal.multiOptions.D.acronym}}:
                                {{proposal.pricing.multiPricing.D.subtotal | currency}}</span>
                            <span *ngIf="proposal.multiOptions.E.isEnabled && proposal.pricing.multiPricing.E"
                                class="ms-2">{{proposal.multiOptions.E.acronym}}:
                                {{proposal.pricing.multiPricing.E.subtotal | currency}}</span>
                        </td>
                        <td data-label="Status">{{proposal.status | proposalStatus}}</td>
                        <td data-label="Last Emailed">{{proposal.lastEmailedAt | formatDateTime}}</td>
                        <td data-label="Created">{{proposal.createdAt | formatDateTime}}</td>
                        <td data-label="Updated">{{proposal.updatedAt | formatDateTime}}</td>
                    </tr>
                </tbody>
            </table>

            <div class="scrollable" *ngIf="isMobile">
              <ul class="list-group">
                  <li *ngFor="let proposal of proposals" class="list-group-item" style="cursor: pointer;" routerLink="/proposals/{{proposal.id}}">
                      <div class="d-flex list-group-container">
                          <div>
                              <strong>{{proposal.clientName}}</strong>
                              <div>{{proposal.projectName}}</div>
                              <div>{{proposal.name}}&nbsp;<fa-icon *ngIf="proposal.status == 'ACCEPTED' && proposal.isDiffPriceFromAP"
                                [icon]="faTriangleExclamation" style="color: var(--bs-red);"></fa-icon>
                              </div>
                              <div><strong>Tax: </strong>{{proposal.taxMultiplier | percent: '1.0-3'}}</div>
                              <div *ngIf="proposal.agreementType == 'SINGLE'"><strong>Subtotal(s): </strong>{{proposal.pricing.total | currency}}</div>
                              <div *ngIf="proposal.agreementType == 'MULTI'">
                                <strong>Subtotal(s): </strong>&nbsp;
                                <span
                                    *ngIf="proposal.multiOptions.A.isEnabled && proposal.pricing.multiPricing.A">{{proposal.multiOptions.A.acronym}}:
                                    {{proposal.pricing.multiPricing.A.subtotal | currency}}</span>
                                <span *ngIf="proposal.multiOptions.B.isEnabled && proposal.pricing.multiPricing.B"
                                    class="ms-2">{{proposal.multiOptions.B.acronym}}:
                                    {{proposal.pricing.multiPricing.B.subtotal | currency}}</span>
                                <span *ngIf="proposal.multiOptions.C.isEnabled && proposal.pricing.multiPricing.C"
                                    class="ms-2">{{proposal.multiOptions.C.acronym}}:
                                    {{proposal.pricing.multiPricing.C.subtotal | currency}}</span>
                                <span *ngIf="proposal.multiOptions.D.isEnabled && proposal.pricing.multiPricing.D"
                                    class="ms-2">{{proposal.multiOptions.D.acronym}}:
                                    {{proposal.pricing.multiPricing.D.subtotal | currency}}</span>
                                <span *ngIf="proposal.multiOptions.E.isEnabled && proposal.pricing.multiPricing.E"
                                    class="ms-2">{{proposal.multiOptions.E.acronym}}:
                                    {{proposal.pricing.multiPricing.E.subtotal | currency}}</span>
                            </div>
                              <div><strong>Status: </strong>{{proposal.status | proposalStatus}}</div>
                              <small class="text-muted">Last Emailed: {{proposal.lastEmailedAt | formatDateTime}}</small>
                              <br/>
                              <small class="text-muted">Created: {{proposal.createdAt | formatDateTime}}</small>
                              <br/>
                              <small class="text-muted">Updated: {{proposal.updatedAt | formatDateTime}}</small>
                          </div>
                      </div>
                  </li>
                  <li *ngIf="proposals.length == 0" class="list-group-item">No Proposals</li>
              </ul>
            </div>
        </div>
    </div>
    <div class="row table-controls">
        <div *ngIf="tableControls.isNotDefault() && !isMobile" class="col-auto mt-3 me-2 me-md-0">
            <button type="button" class="btn btn-outline-danger" (click)="tableControls.resetTableConfiguration();">
                <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
                <span>Reset</span>
            </button>
        </div>
        <div class="col mt-3">
            <div ngbDropdown placement="top-left" class="d-inline-block">
                <button class="btn btn-outline-primary" ngbDropdownToggle><span *ngIf="!isMobile">Page Size: </span> {{tableControls.getLimit()}}</button>
                <div ngbDropdownMenu class="page-size-dropdown" *ngIf="!isMobile">
                  <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(50);">50</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(100);">100</button>
                </div>
                <div ngbDropdownMenu class="page-size-dropdown" *ngIf="isMobile">
                  <button ngbDropdownItem (click)="tableControls.setLimit(5);">5</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(10);">10</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
                </div>
            </div>
        </div>
        <div class="col my-3">
            <ngb-pagination class="d-flex justify-content-end" *ngIf="proposalCount > 0" [page]="tableControls.getCurrentPage()"
                [pageSize]="tableControls.getLimit()" [collectionSize]="proposalCount" [boundaryLinks]="true"
                [maxSize]="(isMobile) ? 3 : 10" [ellipses]="true" [rotate]="true"
                (pageChange)="tableControls.setCurrentPage($event);"></ngb-pagination>
        </div>
    </div>
</div>

<!-- Filter -->
<ng-template #popTitleFilter>Filter</ng-template>
<ng-template let-column="column" #popContentFilter>
    <div *ngFor="let element of this.columnFilterValues.get(column)" class="form-check">
        <input [id]="element.randomId" class="form-check-input" type="checkbox"
            (change)="tableControls.toggleFilter(column, element.value);"
            [checked]="tableControls.filterExists(column, element.value)">
        <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
    </div>
</ng-template>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block" [autoClose]="true" placement="bottom-left">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
  <div ngbDropdown class="d-inline-block filter-container" autoClose="outside" placement="bottom-left">
      <span *ngIf="activeFilterCount > 0" class="custom-badge-lg">{{activeFilterCount}}</span>
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Filter">
          <fa-icon [icon]="faFilter"></fa-icon>
      </button>
      <div ngbDropdownMenu>
          <!-- Filter Options -->
          <div *ngFor="let filter of filterColumns">
            <button ngbDropdownItem class="button-filter" (click)="getDistinctColumnValues(filter.value); filters.toggle();">{{filter.label}}</button>
            <div class="px-4 py-1" #filters="ngbCollapse" [ngbCollapse]="filter.value === selectedFilterColumn ? false : true">
                <div class="d-flex flex-column">
                    <div *ngFor="let element of columnFilterValues.get(filter.value);" class="form-check form-check-inline">
                        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter(filter.value, element.value);" [checked]="filterExists(filter.value, element.value)">
                        <label [for]="element.randomId" class="form-check-label" style="white-space: nowrap;">{{element.name}}</label>
                    </div>
                </div>
            </div>
          </div>

          <!-- Divider -->
          <div class="dropdown-divider"></div>
          <!-- Reset -->
          <button class="reset-button" ngbDropdownItem (click)="resetActiveFilters(); selectedFilterColumn = ''">Reset</button>
      </div>
  </div>
</ng-template>
<!-- LOADER -->
<ngx-spinner>
  <p class="loading-spinner">Loading...</p>
</ngx-spinner>
