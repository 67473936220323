<div class="content-container">
  <nav class="navbar navbar-white bg-white sticky-top navbar-header">
    <div class="d-grid gap-2 d-flex navbar-brand">
      <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom"
        class="btn btn-primary backBtn" aria-label="Back">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>
      <h2 *ngIf="proposal" class="mobileHeading">
        <span>{{proposal.name}}</span>
        <br class="d-md-none"><small class="text-muted ms-md-3"
          routerLink="/clients/{{proposal.clientId}}" style="cursor: pointer;">({{proposal.clientName}})</small>
        <br class="d-md-none">
        <small class="text-muted ms-md-2">
          <span *ngIf="proposal.status == 'MODIFIED' || proposal.rowNumber > 1">
            (Version {{ proposal.rowNumber }})
          </span> {{proposal.status | proposalStatus}}</small>
      </h2>
    </div>
    <div *ngIf="proposal && proposal.status == 'ACCEPTED' && proposal.isDiffPriceFromAP" class="d-flex my-auto"
      style="user-select: none; cursor: pointer;" [ngbPopover]="proposalModificationWarningPopover" triggers="click">
      <fa-icon [icon]="faTriangleExclamation" size="lg" class="my-auto" style="color: var(--bs-red);"></fa-icon>
      <p class="ms-2 my-auto" style="color: var(--bs-red);">Proposal Modified</p>
    </div>
    <div *ngIf="proposal && proposal.status != 'REJECTED'" class="vd ms-2 d-none d-sm-block"></div>
    <div class="ms-lg-2 button-container">
      <button *ngIf="proposal && proposal.status == 'PENDING'" type="button" class="btn btn-primary mt-md-0 mt-1"
      (click)="invoiceForm.onSubmit(null)">
      <fa-icon class="button-icon-left" [icon]="faCheck"></fa-icon>
      <span>Accept</span>
      </button>
      <button *ngIf="proposal && proposal.status == 'PENDING'" type="button" class="btn btn-danger ms-md-2 mt-md-0 mt-1"
        (click)="openAllProposalsRejectedModal()">
        <fa-icon class="button-icon-left" [icon]="faTimes"></fa-icon>
        <span>Reject</span>
      </button>
      <button *ngIf="proposal && (isAccepted || proposal.rowNumber > 1)" type="button" class="btn btn-info ms-md-2 mt-md-0 mt-1"
        (click)="openAcceptProposalModal();">
        <span>View AP</span>
      </button>
      <button type="button" class="btn btn-danger ms-md-2 mt-md-0 mt-1" (click)="openDeleteProposalConfirmationModal();" *ngIf="!isAccepted">
        <fa-icon class="button-icon-left" [icon]="faTrashCan"></fa-icon>
        <span>Delete</span>
      </button>
      <button type="button" class="btn btn-info ms-md-2 mt-md-0 mt-1" (click)="viewProposal();">
        <fa-icon class="button-icon-left" [icon]="faEye"></fa-icon>
        <span>View</span>
      </button>
      <div ngbDropdown class="d-inline-block ms-md-2" display="dynamic" placement="bottom-right">
        <button id="SEND_DROPDOWN" type="button" class="btn btn-secondary mt-md-0 mt-1" ngbDropdownToggle>Send</button>
        <div ngbDropdownMenu aria-labelledby="SEND_DROPDOWN">
          <button ngbDropdownItem (click)="openSendEmailModal();">
            <fa-icon class="button-icon-left" [icon]="faEnvelope"></fa-icon>
            <span>Send Email</span>
          </button>
          <button ngbDropdownItem  (click)="openSendSmsModal();">
            <fa-icon class="button-icon-left" [icon]="faSms"></fa-icon>
            <span>Send SMS</span>
          </button>
        </div>
      </div>
      <br class="mobile-break">
      <button type="button" class="btn btn-primary ms-md-2  mt-md-0 mt-1" (click)="updateProposal();"
        *ngIf="proposal?.status == 'PENDING'">
        <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
        <span>Save</span>
      </button>
      <button type="button" class="btn btn-primary ms-md-2  mt-md-0 mt-1" (click)="confirmModifyProposal();"
        *ngIf="proposal && proposal.status == 'ACCEPTED'">
        <fa-icon class="button-icon-left" [icon]="faPencil"></fa-icon>
        <span>Modify</span>
      </button>
    </div>
  </nav>
  <div class="row">
    <div class="col-lg-3 mt-3">
      <div class="card">
        <div class="card-header">
          <h5>General</h5>
        </div>
        <div class="card-body" style="min-height: 341px;">
          <form [formGroup]="proposalForm">

            <div class="row">
              <div class="col">
                <div>
                  <label for="NAME">Name</label>
                  <input id="NAME" class="form-control" formControlName="name" placeholder="Name" maxlength="150"
                    required [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
                  <div *ngIf="name.invalid && (name.dirty || name.touched)">
                    <div *ngIf="name.errors.required" class="input-validation-msg">Name is required.</div>
                    <div *ngIf="name.errors.maxlength" class="input-validation-msg">Name cannot exceed 150 characters.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- <div class="col-12 col-xl-6 pe-0 pe-xl-2 mt-3">
                <div class="d-flex">
                  <label>Type</label>
                  <span class="ms-auto" style="cursor: pointer;" [ngbPopover]="proposalTypeDescriptionPopover" triggers="click">
                    <fa-icon [icon]="faQuestionCircle"></fa-icon>
                  </span>
                </div>
                <div style="margin-top: 0.4rem!important;">
                  <div class="form-check form-check-inline">
                    <input id="TYPE_TOTAL" type="radio" class="form-check-input" formControlName="type" value="TOTAL" (change)="updateProposal();">
                    <label for="TYPE_TOTAL" class="form-check-label" style="font-weight: normal;">Total</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="TYPE_FEATURES" type="radio" class="form-check-input" formControlName="type" value="FEATURES" (change)="updateProposal();">
                    <label for="TYPE_FEATURES" class="form-check-label" style="font-weight: normal;">Features</label>
                  </div>
                </div>
              </div> -->
              <div class="col-12 col-xl-12 mt-3">
                <div class="d-flex">
                  <label>Agreement Type</label>
                  <span class="ms-auto" style="cursor: pointer;" [ngbPopover]="proposalAgreementTypeDescriptionPopover"
                    triggers="click">
                    <fa-icon [icon]="faQuestionCircle"></fa-icon>
                  </span>
                </div>
                <div style="margin-top: 0.4rem!important;">
                  <div class="form-check form-check-inline">
                    <input id="AGREEMENT_TYPE_SINGLE" type="radio" class="form-check-input"
                      formControlName="agreementType" value="SINGLE" (change)="updateProposal();">
                    <label for="AGREEMENT_TYPE_SINGLE" class="form-check-label"
                      style="font-weight: normal;">Single</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="AGREEMENT_TYPE_MULTI" type="radio" class="form-check-input"
                      formControlName="agreementType" value="MULTI" (change)="updateProposal();">
                    <label for="AGREEMENT_TYPE_MULTI" class="form-check-label"
                      style="font-weight: normal;">Multi</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-xl-6 pe-0 pe-xl-2 mt-3">
                <label for="PROPOSAL_TAX">Tax <span *ngIf="proposal && proposal.taxMultiplierName">- ({{ proposal.taxMultiplierName }})</span></label>
                <div class="input-group">
                  <div ngbDropdown class="d-inline-block">
                    <button type="button" class="btn btn-outline-secondary" ngbDropdownToggle
                      style="border-top-right-radius: 0; border-bottom-right-radius: 0;"></button>
                    <div ngbDropdownMenu>
                      <button *ngFor="let tax of taxes" type="button" ngbDropdownItem [disabled]="isAccepted"
                        (click)="selectTax(tax);">{{tax.name}} ({{tax.multiplier | percent: '1.0-3'}})</button>
                    </div>
                  </div>
                  <input id="PROPOSAL_TAX" type="number" class="form-control" placeholder="0.00" formControlName="tax"
                    min="0" required [class.is-invalid]="tax.invalid && (tax.dirty || tax.touched)">
                  <span class="input-group-text">%</span>
                </div>
                <div *ngIf="tax.invalid && (tax.dirty || tax.touched)">
                  <div *ngIf="tax.errors.required" class="input-validation-msg">Tax is required.</div>
                  <div *ngIf="tax.errors.min" class="input-validation-msg">Tax cannot be less than 0.</div>
                </div>
              </div>
              <div class="col-12 col-xl-6 mt-3">
                <label for="DISCOUNT">Discount</label>
                <div class="input-group">
                  <button *ngIf="proposalForm.value.discountType == 'DOLLAR'" type="button"
                    class="btn btn-outline-secondary" [disabled]="isAccepted"
                    (click)="proposalForm.controls.discountType.setValue('PERCENT');">$</button>
                  <input id="DISCOUNT" class="form-control" type="number" formControlName="discount" placeholder="0"
                    (change)="updateProposal()" min="0" required
                    [class.is-invalid]="discount.invalid && (discount.dirty || discount.touched)">
                  <button *ngIf="proposalForm.value.discountType == 'PERCENT'" type="button"
                    class="btn btn-outline-secondary" [disabled]="isAccepted"
                    (click)="proposalForm.controls.discountType.setValue('DOLLAR');">%</button>
                </div>
                <div *ngIf="discount.invalid && (discount.dirty || discount.touched)">
                  <div *ngIf="discount.errors.required" class="input-validation-msg">Discount is required.</div>
                  <div *ngIf="discount.errors.min" class="input-validation-msg">Discount cannot be less than 0.</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-xl-6 pe-0 pe-xl-2 mt-3">
                <div class="mb-xl-0">
                  <label for="TERM">Terms & Conditions</label>
                  <select id="TERM" class="form-select" formControlName="term" (change)="updateProposal()">
                    <option *ngFor="let proposalTerm of proposalTerms" [ngValue]="proposalTerm.id">{{proposalTerm.name}}
                      <span *ngIf="proposalTerm.isDefault">(Default)</span>
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-6 mt-3">
                <div class="mb-xl-0">
                  <label for="EXPIRATION_DATE">Expiration Date</label>
                  <div class="input-group">
                    <input id="EXPIRATION_DATE" class="form-control" formControlName="expirationDate"
                      placeholder="YYYY-MM-DD" ngbDatepicker #expirationDatepicker="ngbDatepicker" placement="left"
                      [positionTarget]="expirationDatepickerBtn"
                      [class.is-invalid]="expirationDate.invalid && (expirationDate.dirty || expirationDate.touched)">
                    <button #expirationDatepickerBtn type="button" class="btn btn-outline-primary"
                      (click)="expirationDatepicker.toggle();" [disabled]="isAccepted">
                      <fa-icon [icon]="faCalendar"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Single Pricing -->
    <div *ngIf="proposal && proposal.agreementType == 'SINGLE'" class="col-lg-3 mt-3">
      <div class="card">
        <div class="card-header">
          <h5>Pricing</h5>
        </div>
        <div class="card-body scrollable" style="height: 341px;">
          <ul class="list-group">
            <!-- Cost -->
            <li *ngIf="proposalPricing" class="list-group-item">
              <div class="d-flex">
                <div class="my-auto">
                  <strong>Cost</strong>
                </div>
                <p class="ms-auto my-auto mb-0">{{proposalPricing.cost | currency}}</p>
              </div>
            </li>
            <!--Subtotal-->
            <li  *ngIf="proposalPricing" class="list-group-item">
              <div class="d-flex">
                <div class="my-auto">
                  <strong>Subtotal</strong>
                </div>
                <p class="ms-auto my-auto mb-0">{{proposalPricing.subtotal | currency}}</p>
              </div>
              <div class="d-flex">
                <div class="my-auto">
                  <strong>Discount</strong>
                </div>
                <div class="ms-auto my-auto">
                  <p *ngIf="proposal.discountType == 'PERCENT'" class="mb-0">{{proposal.discount}}%</p>
                  <p *ngIf="proposal.discountType == 'DOLLAR'" class="mb-0">{{proposal.discount | currency}}</p>
                </div>
              </div>
              <div class="d-flex">
                <div class="my-auto">
                  <strong>Tax</strong>
                </div>
                <p class="ms-auto my-auto mb-0">{{proposalPricing.tax | currency}}</p>
              </div>
            </li>
            <li *ngIf="proposalPricing" class="list-group-item">
              <!-- Total -->
              <div class="d-flex">
                <div class="my-auto">
                  <strong>Total</strong>
                </div>
                <p class="ms-auto my-auto mb-0">{{proposalPricing.total | currency}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Multi Pricing -->
    <div *ngIf="proposal && proposal.agreementType == 'MULTI'" class="col-lg-3 mt-3">
      <div class="card">
        <div class="card-header">
          <h5>Pricing</h5>
          <button type="button" class="btn btn-primary ms-auto" (click)="openProposalMultiAgreementTypeOptionsModal();">
            <fa-icon class="button-icon-left" [icon]="faPencil"></fa-icon>
            <span>Edit</span>
          </button>
        </div>
        <div class="card-body scrollable" style="height: 341px;">
          <ul class="list-group">
            <!-- Cost -->
            <li *ngIf="proposalPricing" class="list-group-item">
              <div class="d-flex">
                <div class="my-auto">
                  <strong>Cost</strong>
                </div>
                <p class="ms-auto my-auto mb-0">{{proposalPricing.cost | currency}}</p>
              </div>
            </li>
            <!-- Discount -->
            <li *ngIf="proposal.discount > 0" class="list-group-item">
              <div class="d-flex">
                <div class="my-auto">
                  <strong>Discount</strong>
                </div>
                <div class="ms-auto my-auto">
                  <p *ngIf="proposal.discountType == 'PERCENT'" class="mb-0">{{proposal.discount}}%</p>
                  <p *ngIf="proposal.discountType == 'DOLLAR'" class="mb-0">{{proposal.discount | currency}}</p>
                </div>
              </div>
            </li>
            <!-- Pricing Options -->
            <ng-container *ngFor="let key of multiOptionKeys">
              <li *ngIf="multiOptionIsEnabled(key);" class="list-group-item">
                <!-- Title -->
                <div style="border-bottom: 2px solid #212529;">
                  <strong>{{proposal.multiOptions[key].acronym}}</strong>
                </div>
                <!-- Subtotal -->
                <div class="d-flex">
                  <div class="my-auto">
                    <strong class="d-none d-xl-block">Subtotal</strong>
                    <strong class="d-xl-none">Sub.</strong>
                  </div>
                  <p class="ms-auto my-auto mb-0">{{proposalPricing.multiPricing[key]?.subtotal | currency}}</p>
                </div>
                <!-- Tax -->
                <div class="d-flex">
                  <div class="my-auto">
                    <strong>Tax</strong>
                  </div>
                  <p class="ms-auto my-auto mb-0">{{proposalPricing.multiPricing[key]?.tax | currency}}</p>
                </div>
                <!-- Total -->
                <div class="d-flex">
                  <div class="my-auto">
                    <strong>Total</strong>
                  </div>
                  <p class="ms-auto my-auto mb-0">{{proposalPricing.multiPricing[key]?.total | currency}}</p>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="proposal" class="col-lg-3 mt-3">
      <div class="card mb-2" style="height: 30%;">
        <div class="card-header">
          <h5 class="my-1">Metrics</h5>
        </div>
        <div class="card-body">
          <!-- <ul class="list-group">
            <li *ngIf="proposalPricing" class="list-group-item flex-column"> -->
          <ng-container *ngIf="proposalPricing">
            <div class="d-flex">
              <div class="my-auto">
                <strong class="d-none d-xl-block">Install Hours</strong>
                <strong class="d-xl-none">Install Hrs.</strong>
              </div>
              <p class="ms-auto my-auto mb-0">{{proposalPricing.totalInstallHours}}</p>
            </div>
            <div class="d-flex">
              <div class="my-auto">
                <strong class="d-none d-xl-block">Removal Hours</strong>
                <strong class="d-xl-none">Removal Hrs.</strong>
              </div>
              <p class="ms-auto my-auto mb-0">{{proposalPricing.totalRemovalHours}}</p>
            </div>
          </ng-container>


          <!-- </li> -->
          <!-- <li *ngIf="proposalPricing" class="list-group-item">
              <div class="d-flex">
                <div class="my-auto">
                  <strong class="d-none d-xl-block">Removal Hours</strong>
                  <strong class="d-xl-none">Removal Hrs.</strong>
                </div>
                <p class="ms-auto my-auto mb-0">{{proposalPricing.totalRemovalHours}}</p>
              </div>
            </li> -->
          <!-- </ul> -->
        </div>
      </div>

      <div class="card" style="height: 68%;">
        <div class="card-header">
          <div class="d-flex w-100">
            <h5>1st Invoice Details</h5>
            <span class="ms-auto" style="cursor: pointer;" [ngbPopover]="firstInvoicePopOver" triggers="click">
              <fa-icon [icon]="faQuestionCircle"></fa-icon>
            </span>
          </div>
        </div>
        <div class="card-body">
          <form [formGroup]="firstInvoiceForm" (ngSubmit)="openAcceptProposalModal()" #invoiceForm="ngForm" id="ngForm">
            <div class="row">
              <div class="form-check col-12 col-xl-12 mb-2">
                <input id="FIRST_INVOICE_REQUIRED" class="form-check-input" type="checkbox"
                  (change)="updateInvoiceRequired($event)" [checked]="proposal.isInvoiceRequired"
                  [disabled]="isAccepted">
                <label for="FIRST_INVOICE_REQUIRED" class="form-check-label">
                  Automatically create and send first invoice once client accepts proposal
                </label>
              </div>
              <div class="col-12 col-xl-12">
                <label for="AMOUNT">Amount</label>
                <div class="input-group">
                  <button *ngIf="amountType == 'DOLLAR'" type="button" class="btn btn-outline-secondary"
                    (click)="amountType = 'PERCENT'; updateProposal()"
                    [disabled]="isAccepted || proposal.rowNumber > 1 || !invoiceRequired">$</button>
                  <input id="AMOUNT" class="form-control" type="number" formControlName="amount" placeholder="0" min="1"
                    [max]="amountType == 'DOLLAR' ? proposalPricing.total : 100" (change)="updateProposal()"
                    [class.is-invalid]="amount.invalid && (amount.dirty || amount.touched)">
                  <button *ngIf="amountType == 'PERCENT'" type="button" class="btn btn-outline-secondary"
                    (click)="amountType = 'DOLLAR'; updateProposal()"
                    [disabled]="isAccepted || proposal.rowNumber > 1 || !invoiceRequired">%</button>
                </div>
                <div *ngIf="amount.invalid && (amount.dirty || amount.touched)">
                  <div *ngIf="amount.errors.required" class="input-validation-msg">Amount is required.</div>
                  <div *ngIf="amount.errors.min" class="input-validation-msg">Amount cannot be equal to or less than 0.
                  </div>
                  <div *ngIf="amount.errors.max" class="input-validation-msg">Amount cannot be more than the total
                    Proposal amount</div>
                </div>
              </div>
              <div class="col-12 col-xl-12 mt-2">
                <label for="DAYS_UNTIL_DUE">Days until due</label>
                <input id="DAYS_UNTIL_DUE" type="number" class="form-control"
                  formControlName="daysUntilDue" min="0" (change)="updateProposal()"
                  [class.is-invalid]="daysUntilDue.invalid && (daysUntilDue.dirty || daysUntilDue.touched)">
                <div *ngIf="daysUntilDue.invalid && (daysUntilDue.dirty || daysUntilDue.touched)">
                  <div *ngIf="daysUntilDue.errors.required" class="input-validation-msg">Due date is required.</div>
                  <div *ngIf="daysUntilDue.errors.min" class="input-validation-msg">Number of Days cannot be less than
                    0.
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div *ngIf="proposal" class="col-lg-3 mt-3">
      <div class="card">
        <div class="card-header">
          <h5>Customer Interactions</h5>
        </div>
        <div class="card-body" style="height: 341px;">
          <ul class="list-group scrollable">
            <li *ngFor="let interaction of proposalInteractions" class="list-group-item" style="cursor: pointer;"
              (click)="toggleShowAdditionInteractionData();">
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <strong>{{interaction.type | proposalInteraction}}</strong>
                  <p *ngIf="showAdditionalInteractionData" class="mb-0">{{interaction.ipAddress}}</p>
                  <small class="text-muted">{{interaction.dateTime | formatDateTime}}</small>
                </div>
              </div>
            </li>
            <li *ngIf="proposalInteractions.length == 0" class="list-group-item">No Interactions</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-3">
    <app-proposal-builder #proposalBuilder *ngIf="proposal" [proposal]="proposal"
      (saved)="reloadData()"></app-proposal-builder>
  </div>
</div>

<!-- Proposal Modification Warning Popover -->
<ng-template #proposalModificationWarningPopover>
  <p><strong>Issue</strong> The proposal was modified after it was accepted, resulting in a price discrepancy between
    the <span style="font-style: italic;">proposal</span> total and the <span
      style="font-style: italic;">accepted</span> total.</p>
  <p class="mb-0"><strong>Solution</strong> The <span style="font-style: italic;">accepted</span> total can be updated
    by performing the following steps:</p>
  <ol class="ps-4">
    <li class="py-1">Click the <span
        style="color: white; background-color: var(--purple-color); border-radius: 0.375rem; padding: 0.25rem 0.5rem; font-weight: 500;">View
        AP</span> button to the right.</li>
    <li class="py-1">Verify the pricing and selected multi-option (if applicable).</li>
    <li class="pt-1">Click the <span
        style="color: white; background-color: var(--green-color); border-radius: 0.375rem; padding: 0.25rem 0.5rem; font-weight: 500;">Save</span>
      button.</li>
  </ol>
  <p class="mb-0"><strong>Note</strong> You may need to adjust any invoices associated with this project.</p>
</ng-template>

<!-- Proposal Type Description Popover -->
<ng-template #proposalTypeDescriptionPopover>
  <p><strong>Total</strong> The proposal is presented as all-or-nothing; the customer cannot select which features they
    want included.</p>
  <p class="mb-0"><strong>Features</strong> The customer can select which features they want included.</p>
</ng-template>

<!-- Proposal Agreement Type Description Popover -->
<ng-template #proposalAgreementTypeDescriptionPopover>
  <p><strong>Single</strong> The proposal is presented with a single agreement option.</p>
  <p><strong>Multi</strong> The proposal is presented with multiple agreement options.</p>
  <p class="mb-0"><strong>Note</strong> Multi Agremeement Options can be preconfigured in Settings and selectively
    configured on a per-proposal basis.</p>
</ng-template>

<ng-template #firstInvoicePopOver>
  <p>
    Checking this box means as soon as the client accepts the proposal, they will be redirected to their first invoice.
    The first invoice will also automatically be emailed to the client immediately after they accept the Proposal. The
    1st Invoice Details include:
  </p>
  <p><strong>Amount:</strong>(amount due for the first invoice)</p>
  <p><strong>Days until Due:</strong> (days until the amount is due for the first proposal)</p>
  <p class="mb-0">If you enter 0, the proposal will show the due date as “immediately”.</p>
</ng-template>

<!-- LOADER -->
<ngx-spinner>
  <p class="loading-spinner">Loading...</p>
</ngx-spinner>
