import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { faCheck, faTrash, faLandmark } from '@fortawesome/free-solid-svg-icons';
import { catchError, throwError } from 'rxjs';
import { AlertService } from '../alert.service';
import { Invoice } from '../invoice';
import { PaymentService } from '../payment.service';
import { TinselPayVault } from '../tinsel-pay-vault';

@Component({
  selector: 'app-tinsel-pay-ach-payment-form',
  templateUrl: './tinsel-pay-ach-payment-form.component.html',
  styleUrls: ['./tinsel-pay-ach-payment-form.component.css']
})
export class TinselPayAchPaymentFormComponent implements OnInit {

  // Properties
  @Input() clientId: string;
  @Input() invoice: Invoice;
  @Input() defaultAmount: number;
  @Output() paymentSuccessful: EventEmitter<void> = new EventEmitter<void>();
  achPaymentForm: UntypedFormGroup;
  amount: string;
  remainingBalance: number = Infinity;
  amountConfirmed: boolean = false;
  paymentType: string;
  paymentInProgress: boolean = false;
  paymentButtonText: string;

  // ACH Vault
  tinselPayAchVaults: TinselPayVault[] = [];
  selectedAchVaultId: string;

  // Font Awesome Properties
  faCheck = faCheck;
  faTrash = faTrash;
  faLandmark = faLandmark;

  constructor(private paymentService: PaymentService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.amount = this.defaultAmount.toFixed(2);
    this.listenForAmountChanges();
    this.achPaymentForm = new UntypedFormGroup({
      customerName: new UntypedFormControl(),
      bankAccountNumber: new UntypedFormControl(),
      bankRoutingNumber: new UntypedFormControl(),
      bankAccountType: new UntypedFormControl('CHECKING')
    });
  }

  // Listen For Amount Changes
  private listenForAmountChanges(): void {
    (document.getElementById('AMOUNT') as HTMLInputElement).addEventListener('input', (event) => {
      const amount = (event.target as HTMLInputElement).value;
      this.remainingBalance = this.invoice.amountDue - parseFloat(amount);
    });
  }

  // Confirm Amount With ACH
  confirmAmountWithAch(): void {
    this.amount = (document.getElementById('AMOUNT') as HTMLInputElement).value;
    this.remainingBalance = this.invoice.amountDue - parseFloat(this.amount);
    if (this.remainingBalance > 0 && this.remainingBalance < 1) return;
    this.amountConfirmed = true;
    this.paymentType = 'ACH';
    this.paymentButtonText = `Pay $${this.amount}`;
    this.getTinselPayAchVaults();
  }

  // Get Tinsel Pay ACH Vaults
  private getTinselPayAchVaults(): void {
    this.paymentService.getTinselPayVaults(this.clientId, 'ACH').subscribe((vaults) => {
      this.tinselPayAchVaults = vaults;
    });
  }

  // Delete Tinsel Pay ACH Vault
  deleteTinselPayAchVault(vaultId: string): void {
    this.paymentService.deleteTinselPayAchVault(this.invoice.organizationId, vaultId).subscribe(() => {
      this.alertService.showSuccessAlert('Profile Deleted');
      this.getTinselPayAchVaults();
    });
  }

  // ACH Vault Selected
  achVaultSelected(id: string): void {
    if (this.selectedAchVaultId == id) {
      this.selectedAchVaultId = null;
      return;
    }
    this.selectedAchVaultId = id;
  }

  // Pay With ACH
  payWithAch(): void {
    this.paymentInProgress = true;
    this.paymentButtonText = 'Processing...';
    this.processTinselPayAchPayment();
  }

  // Process Tinsel Pay ACH Payment
  private processTinselPayAchPayment(): void {
    if (this.selectedAchVaultId || (!this.selectedAchVaultId && this.achPaymentForm.valid)) {
      const data: any = {
        organizationId: this.invoice.organizationId,
        invoiceId: this.invoice.id,
        type: (this.selectedAchVaultId) ? 'VAULT' : 'MANUAL',
        amount: this.amount
      };
      if (this.selectedAchVaultId) {
        data.vaultId = this.selectedAchVaultId;
      } else {
        data.customerName = this.achPaymentForm.value.customerName;
        data.bankAccountNumber = this.achPaymentForm.value.bankAccountNumber;
        data.bankRoutingNumber = this.achPaymentForm.value.bankRoutingNumber;
        data.bankAccountType = this.achPaymentForm.value.bankAccountType;
        data.saveProfile = (document.getElementById('SAVE_ACH_PROFILE') as HTMLInputElement).checked;
      }
      this.paymentService.processTinselPayAchSale(data).pipe(catchError(() => {
        this.paymentButtonText = `Pay $${this.amount}`;
        this.paymentInProgress = false;
        return throwError(() => new Error('API Request Error'));
      })).subscribe(() => {
        this.alertService.showSuccessAlert('Payment Successful');
        this.paymentSuccessful.emit();
      });
    } else {
      this.achPaymentForm.markAllAsTouched();
    }
  }

  // ACH Payment Form Accessors
  get customerName() { return this.achPaymentForm.controls.customerName; }
  get bankAccountNumber() { return this.achPaymentForm.controls.bankAccountNumber; }
  get bankRoutingNumber() { return this.achPaymentForm.controls.bankRoutingNumber; }

}
