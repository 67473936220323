import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project-prep-check-in-modal',
  templateUrl: './project-prep-check-in-modal.component.html',
  styleUrls: ['./project-prep-check-in-modal.component.css']
})
export class ProjectPrepCheckInModalComponent {
  @Input() itemName: string;
  @Input() quantity: number;

  @Output() choice: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    public modal: NgbActiveModal
  ) { }

  submit(quantity: number) {
    this.choice.emit(quantity);
    this.modal.close();
  }
}
