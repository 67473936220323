<div class="card mb-3">
    <div class="card-header d-flex align-items-center">
        <h5 class="mb-0">Features & Feature Items</h5>
        <div ngbDropdown class="d-inline-block ms-auto" display="dynamic" placement="bottom-right">
            <div ngbDropdown class="d-inline-block ms-auto" display="dynamic" placement="bottom-right">
                <button id="BULK_ACTIONS_DROPDOWN" type="button" class="btn btn-primary" ngbDropdownToggle>Bulk
                    Actions</button>
                <div ngbDropdownMenu aria-labelledby="BULK_ACTIONS_DROPDOWN">
                    <!-- Features -->
                    <strong class="px-3 py-1 w-100 d-block fs-5">Features</strong>
                    <div class="dropdown-divider"></div>
                    <!-- <div class="d-flex">
                        <strong class="px-3 py-1 d-block" style="min-width: 115px;">View</strong>
                        <button ngbDropdownItem class="text-center" style="min-width: 75px;"
                            (click)="accordion.expandAll(); expandFeatures()">Expand all</button>
                        <button ngbDropdownItem class="text-center ms-1" style="min-width: 75px"
                            (click)="accordion.collapseAll(); collapseFeatures()">Collapse all</button>
                    </div> -->
                    <div class="d-flex">
                        <strong class="px-3 py-1 d-block" style="min-width: 115px;">Prices</strong>
                        <button ngbDropdownItem class="text-center" style="min-width: 75px;" [disabled]="isAccepted"
                            (click)="bulkUpdateFeatureProperty('costHidden', false);">Show</button>
                        <button ngbDropdownItem class="text-center ms-1" style="min-width: 75px" [disabled]="isAccepted"
                            (click)="bulkUpdateFeatureProperty('costHidden', true);">Hide</button>
                    </div>
                    <div class="d-flex">
                        <strong class="px-3 py-1 d-block" style="min-width: 115px;">Optional</strong>
                        <button ngbDropdownItem class="text-center" style="min-width: 75px" [disabled]="isAccepted"
                            (click)="bulkUpdateFeatureProperty('isOptional', true);">Yes</button>
                        <button ngbDropdownItem class="text-center ms-1" style="min-width: 75px" [disabled]="isAccepted"
                            (click)="bulkUpdateFeatureProperty('isOptional', false);">No</button>
                    </div>
                    <!-- Divider -->
                    <div class="dropdown-divider"></div>
                    <!-- Feature Items -->
                    <strong class="px-3 py-1 w-100 d-block text-nowrap fs-5">Feature Items</strong>
                    <div class="dropdown-divider"></div>
                    <div class="d-flex">
                        <strong class="px-3 py-1 d-block" style="min-width: 115px;">Visibility</strong>
                        <button ngbDropdownItem class="text-center" style="min-width: 75px" [disabled]="isAccepted"
                            (click)="bulkUpdateFeatureItemProperty('hidden', false, true);">Show</button>
                        <button ngbDropdownItem class="text-center ms-1" style="min-width: 75px" [disabled]="isAccepted"
                            (click)="bulkUpdateFeatureItemProperty('hidden', true, true);">Hide</button>
                    </div>
                    <div class="d-flex">
                        <strong class="px-3 py-1 d-block" style="min-width: 115px;">Quantity</strong>
                        <button ngbDropdownItem class="text-center" style="min-width: 75px" [disabled]="isAccepted"
                            (click)="bulkUpdateFeatureItemProperty('quantityHidden', false);">Show</button>
                        <button ngbDropdownItem class="text-center ms-1" style="min-width: 75px" [disabled]="isAccepted"
                            (click)="bulkUpdateFeatureItemProperty('quantityHidden', true);">Hide</button>
                    </div>
                    <div class="d-flex">
                        <strong class="px-3 py-1 d-block" style="min-width: 115px;">Taxable</strong>
                        <button ngbDropdownItem class="text-center" style="min-width: 75px" [disabled]="isAccepted"
                            (click)="bulkUpdateFeatureItemProperty('isTaxable', true, true);">Yes</button>
                        <button ngbDropdownItem class="text-center ms-1" style="min-width: 75px" [disabled]="isAccepted"
                            (click)="bulkUpdateFeatureItemProperty('isTaxable', false, true);">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body" class="example-list" cdkDropList (cdkDropListDropped)="drop($event)">
        <form [formGroup]="featureForms">
            <div ngbAccordion class="my-2" #accordion="ngbAccordion">
                <div class="proposal-accordion" *ngFor="let feature of features; let i = index;" cdkDrag
                    [ngbAccordionItem]="i" [collapsed]="feature.isCollapse">
                    <!-- <div [ngbAccordionItem]="i"> -->
                    <form [formGroupName]="feature.id">
                        <h2 ngbAccordionHeader (click)="updateCollapse(feature)">
                            <button ngbAccordionButton>
                                <div class="row w-100 px-2">
                                    <div class="col-5 col-md-5 col-sm-12 d-flex align-items-center">
                                        <fa-icon [icon]="faGripVertical" style="font-size: 24px" class="me-2"
                                            cdkDragHandle></fa-icon>
                                        <input *ngIf="featureNameTypeaheads.has(feature.id)"
                                            id="FEATURE_NAME_{{feature.id}}" type="text"
                                            class="form-control featureHeading" formControlName="name"
                                            placeholder="Roofline" maxlength="150" required
                                            [ngbTypeahead]="getFeatureNameTypeaheadInput(feature.id)"
                                            (focus)="featureNameTypeaheadFocused(feature.id, $event)"
                                            [class.is-invalid]="isNestedFeatureFormControlInvalid(feature.id, 'name')"
                                            (click)="$event.stopPropagation();"
                                            (change)="updateFeature(feature.id, 'name', $event.target.value);">
                                    </div>
                                    <div class="col-md-2">
                                        <div class="accordion-icon">
                                            <fa-icon [icon]="(!feature.isCollapse ? faChevronUp : faChevronDown)"></fa-icon>
                                            <br>
                                            <small *ngIf="feature.isCollapse">Click to expand</small>
                                            <small *ngIf="!feature.isCollapse">Click to hide</small>
                                        </div>
                                    </div>
                                    <div class="col-5 col-md-5 col-sm-12 d-flex flex-column align-self-center">
                                        <p *ngIf="proposal.agreementType == 'SINGLE'"
                                            class="text-nowrap ms-auto mb-1 fs-5" style="line-height: 1;">
                                            <strong>Price</strong> {{feature.totalPrice | currency}}
                                        </p>
                                        <div *ngIf="proposal.agreementType == 'MULTI'" class="row ms-auto mb-1">
                                            <ng-container *ngFor="let key of multiOptionKeys; index as i;">
                                                <div *ngIf="multiOptionIsEnabled(key);"
                                                    class="col-auto p-0 d-md-flex flex-md-row fs-5" [class.ms-2]="!isMobile ? i > 0 : 0"
                                                    style="line-height: 1;">
                                                    <strong>{{proposal.multiOptions[key].acronym}}</strong>
                                                    <p class="mb-0 ms-md-1">{{feature.totalPricingMulti[key] |
                                                        currency}}</p>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </h2>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                                <ng-template>
                                    <div class="row">
                                        <div class="col-md-8 col-sm-12">
                                            <div class="row">
                                                <!-- <div class="col-12">
                                                        <input *ngIf="featureNameTypeaheads.has(feature.id)"
                                                            id="FEATURE_NAME_{{feature.id}}" type="text"
                                                            class="form-control featureHeading" formControlName="name"
                                                            placeholder="Roofline" maxlength="150" required
                                                            [ngbTypeahead]="getFeatureNameTypeaheadInput(feature.id)"
                                                            (focus)="featureNameTypeaheadFocused(feature.id, $event)"
                                                            [class.is-invalid]="isNestedFeatureFormControlInvalid(feature.id, 'name')"
                                                            (change)="updateFeature(feature.id, 'name', $event.target.value);">
                                                    </div> -->
                                                <div class="col-md-6 col-sm-12 mt-2 d-flex">
                                                    <!-- <label for="FEATURE_INSTALL_HOURS_{{feature.id}}">Install Hours</label> -->
                                                    <div class="input-group my-auto">
                                                        <span class="input-group-text">Install Hours</span>
                                                        <input id="FEATURE_INSTALL_HOURS_{{feature.id}}" type="number"
                                                            class="form-control" placeholder="2.50"
                                                            formControlName="installHours" min="0"
                                                            (change)="updateFeature(feature.id, 'installHours', $event.target.value);"
                                                            [class.is-invalid]="isNestedFeatureFormControlInvalid(feature.id, 'installHours')">
                                                    </div>
                                                    <div
                                                        *ngIf="isNestedFeatureFormControlInvalid(feature.id, 'installHours')">
                                                        <div *ngIf="getNestedFeatureFormControlErrors(feature.id, 'installHours').min"
                                                            class="input-validation-msg">Install Hours cannot be
                                                            less than 0.</div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-12 mt-2 d-flex">
                                                    <!-- <label for="FEATURE_REMOVAL_HOURS_{{feature.id}}">Removal Hours</label> -->
                                                    <div class="input-group my-auto">
                                                        <span class="input-group-text">Removal Hours</span>
                                                        <input id="FEATURE_REMOVAL_HOURS_{{feature.id}}" type="number"
                                                            class="form-control" placeholder="2.50"
                                                            formControlName="removalHours" min="0"
                                                            (change)="updateFeature(feature.id, 'removalHours', $event.target.value);"
                                                            [class.is-invalid]="isNestedFeatureFormControlInvalid(feature.id, 'removalHours')">
                                                    </div>
                                                    <div
                                                        *ngIf="isNestedFeatureFormControlInvalid(feature.id, 'removalHours')">
                                                        <div *ngIf="getNestedFeatureFormControlErrors(feature.id, 'removalHours').min"
                                                            class="input-validation-msg">Removal Hours cannot be
                                                            less than 0.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <textarea id="FEATURE_DESCRIPTION_{{feature.id}}"
                                                class="form-control featureDescription mt-2"
                                                formControlName="description"
                                                placeholder="The roofline will be decorated with..." rows="2"
                                                (change)="updateFeature(feature.id, 'description', $event.target.value);"></textarea>
                                        </div>
                                        <div class="col-md-4 col-sm-12 d-flex flex-column">
                                            <!-- Is Selected -->
                                            <div class="row ms-auto">
                                                <div class="col">
                                                    <div class="form-check form-check-reverse text-start">
                                                        <input id="F-SELECTED-{{feature.id}}" type="checkbox"
                                                            class="form-check-input" formControlName="isSelected">
                                                        <label for="F-SELECTED-{{feature.id}}"
                                                            class="form-check-label">Selected</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Optional -->
                                            <div class="row ms-auto">
                                                <div class="col">
                                                    <div class="form-check form-check-reverse text-start">
                                                        <input id="F-IS-OPTIONAL-{{feature.id}}" type="checkbox"
                                                            class="form-check-input" formControlName="isOptional">
                                                        <label for="F-IS-OPTIONAL-{{feature.id}}"
                                                            class="form-check-label">Optional</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Hide Price -->
                                            <div class="row ms-auto">
                                                <div class="col">
                                                    <div class="form-check form-check-reverse text-start">
                                                        <input id="F-HIDE-PRICE-{{feature.id}}" type="checkbox"
                                                            class="form-check-input" formControlName="costHidden">
                                                        <label for="F-HIDE-PRICE-{{feature.id}}"
                                                            class="form-check-label">Hide Price</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Is Included -->
                                            <div class="row ms-auto">
                                                <div class="col">
                                                    <div class="form-check form-check-reverse text-start">
                                                        <input id="F-INCLUDED-{{feature.id}}" type="checkbox"
                                                            class="form-check-input" formControlName="isHidden">
                                                        <label for="F-INCLUDED-{{feature.id}}"
                                                            class="form-check-label">Hide on Proposal</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Actions -->
                                            <div class="row ms-auto">
                                                <div class="col">
                                                    <button type="button" class="btn btn-sm btn-danger"
                                                        (click)="openDeleteFeatureConfirmationModal(feature.id);"
                                                        [disabled]="isAccepted">
                                                        <fa-icon [icon]="faTrashAlt" class="button-icon-left"></fa-icon>
                                                        <span>Delete</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="getFeatureItemCount(feature.id) > 0" class="table-responsive mt-2">
                                        <table  *ngIf="!isMobile">
                                            <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th>Qty</th>
                                                    <th>Cost</th>
                                                    <th *ngIf="proposal.agreementType == 'SINGLE'">Price</th>
                                                    <th *ngIf="proposal.agreementType == 'MULTI'">Multi Price</th>
                                                    <th>Subtotal<span *ngIf="proposal.agreementType == 'MULTI'">s</span>
                                                    </th>
                                                    <th>Type</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let featureItem of getFeatureItems(feature.id); index as j;">
                                                    <td data-label="Item">{{featureItem.name}}</td>
                                                    <td data-label="Qty">
                                                        <input type="number" class="form-control text-center"
                                                            [value]="featureItem.quantity"
                                                            [disabled]="isAccepted || isSaving"
                                                            (change)="adjustFeatureItemProperty(featureItem.id, 'quantity', $event.target.value, true);">
                                                    </td>
                                                    <td data-label="Cost">{{featureItem.totalCost | currency}}</td>
                                                    <td *ngIf="proposal.agreementType == 'SINGLE'" data-label="Price">
                                                        <input type="number" class="form-control text-center"
                                                            [value]="featureItem.price"
                                                            [disabled]="isAccepted || isSaving"
                                                            (change)="adjustFeatureItemProperty(featureItem.id, 'price', $event.target.value, true);">
                                                    </td>
                                                    <td
                                                        *ngIf="proposal.agreementType == 'MULTI' && featureItem.totalPricingMulti" data-label="Multi Price">
                                                        <table class="table-borderless">
                                                            <td
                                                                *ngIf="proposal.multiOptions.A.isEnabled && featureItem.totalPricingMulti.A">
                                                                <input type="number" [disabled]="isAccepted"
                                                                    class="form-control text-center"
                                                                    [value]="featureItem.multiPricing.A"
                                                                    (change)="adjustMultiOptionPrice(featureItem, 'A', $event.target.value);" />
                                                            </td>
                                                            <td
                                                                *ngIf="proposal.multiOptions.B.isEnabled && featureItem.totalPricingMulti.B">
                                                                <input type="number" [disabled]="isAccepted"
                                                                    class="form-control text-center"
                                                                    [value]="featureItem.multiPricing.B"
                                                                    (change)="adjustMultiOptionPrice(featureItem, 'B', $event.target.value);" />
                                                            </td>
                                                            <td
                                                                *ngIf="proposal.multiOptions.C.isEnabled && featureItem.totalPricingMulti.C">
                                                                <input type="number" [disabled]="isAccepted"
                                                                    class="form-control text-center"
                                                                    [value]="featureItem.multiPricing.C"
                                                                    (change)="adjustMultiOptionPrice(featureItem, 'C', $event.target.value);" />
                                                            </td>
                                                            <td
                                                                *ngIf="proposal.multiOptions.D.isEnabled && featureItem.totalPricingMulti.D">
                                                                <input type="number" [disabled]="isAccepted"
                                                                    class="form-control text-center"
                                                                    [value]="featureItem.multiPricing.D"
                                                                    (change)="adjustMultiOptionPrice(featureItem, 'D', $event.target.value);" />
                                                            </td>
                                                            <td
                                                                *ngIf="proposal.multiOptions.E.isEnabled && featureItem.totalPricingMulti.E">
                                                                <input type="number" [disabled]="isAccepted"
                                                                    class="form-control text-center"
                                                                    [value]="featureItem.multiPricing.E"
                                                                    (change)="adjustMultiOptionPrice(featureItem, 'E', $event.target.value);" />
                                                            </td>
                                                        </table>
                                                    </td>
                                                    <td *ngIf="proposal.agreementType == 'SINGLE'" data-label="Subtotal">
                                                        {{featureItem.totalPrice | currency}}</td>
                                                    <td
                                                        *ngIf="proposal.agreementType == 'MULTI' && featureItem.totalPricingMulti" data-label="Subtotals">
                                                        <table class="table-borderless">
                                                            <td
                                                                *ngIf="proposal.multiOptions.A.isEnabled && featureItem.totalPricingMulti.A">
                                                                {{proposal.multiOptions.A.acronym}} |
                                                                {{featureItem.totalPricingMulti.A | currency}}</td>
                                                            <td
                                                                *ngIf="proposal.multiOptions.B.isEnabled && featureItem.totalPricingMulti.B">
                                                                {{proposal.multiOptions.B.acronym}} |
                                                                {{featureItem.totalPricingMulti.B | currency}}</td>
                                                            <td
                                                                *ngIf="proposal.multiOptions.C.isEnabled && featureItem.totalPricingMulti.C">
                                                                {{proposal.multiOptions.C.acronym}} |
                                                                {{featureItem.totalPricingMulti.C | currency}}</td>
                                                            <td
                                                                *ngIf="proposal.multiOptions.D.isEnabled && featureItem.totalPricingMulti.D">
                                                                {{proposal.multiOptions.D.acronym}} |
                                                                {{featureItem.totalPricingMulti.D | currency}}</td>
                                                            <td
                                                                *ngIf="proposal.multiOptions.E.isEnabled && featureItem.totalPricingMulti.E">
                                                                {{proposal.multiOptions.E.acronym}} |
                                                                {{featureItem.totalPricingMulti.E | currency}}</td>
                                                        </table>
                                                    </td>
                                                    <td data-label="Type">{{featureItem.type}}</td>
                                                    <td class="actionBtnContainer" data-label="Actions">
                                                        <button type="button" class="btn btn-danger btn-sm"
                                                            (click)="openDeleteFeatureItemConfirmationModal(featureItem);"
                                                            [disabled]="isAccepted">
                                                            <fa-icon [icon]="faTrashAlt"></fa-icon>
                                                        </button>
                                                        <button type="button" class="btn btn-secondary btn-sm ms-2"
                                                            [disabled]="isAccepted" [ngbPopover]="featureItemOptions"
                                                            popoverTitle="Options" triggers="manual"
                                                            #featureItemOptionsPopover="ngbPopover"
                                                            (click)="toggleFeatureItemOptionsPopover(featureItemOptionsPopover, featureItem);">
                                                            <fa-icon [icon]="faEllipsis"></fa-icon>
                                                        </button>
                                                        <button type="button" class="btn btn-primary btn-sm ms-2"
                                                            [disabled]="isAccepted"
                                                            (click)="editFeatureItem(featureItem);">
                                                            <fa-icon [icon]="faPencil"></fa-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>


                                        <div class="scrollable" *ngIf="isMobile">
                                          <ul class="list-group">
                                              <li *ngFor="let featureItem of getFeatureItems(feature.id); index as j;" class="list-group-item">
                                                  <div class="d-flex list-group-container">
                                                      <div>
                                                          <strong>{{featureItem.name}}</strong>
                                                          <div class="container-fluid p-0 m-0 mb-2 mt-2">
                                                            <div class="list-item-container">
                                                                <div class="label-width" style="line-height:2.5;">
                                                                  <strong>Qty:</strong>
                                                                </div>
                                                                <div>
                                                                  <input type="number" class="form-control text-center"
                                                                  [value]="featureItem.quantity"
                                                                  [disabled]="isAccepted || isSaving"
                                                                  (change)="adjustFeatureItemProperty(featureItem.id, 'quantity', $event.target.value, true);">
                                                                </div>
                                                            </div>
                                                          </div>
                                                          <div class="container-fluid p-0 m-0 mb-2"><strong>Cost: </strong>{{featureItem.totalCost | currency}}</div>
                                                          <div class="container-fluid p-0 m-0 mb-2"><strong>Type: </strong>{{featureItem.type}}</div>

                                                          <div class="container-fluid p-0 m-0 mb-2" *ngIf="proposal.agreementType == 'SINGLE'">
                                                              <div class="list-item-container">
                                                                <div class="label-width">
                                                                  <strong>Price:</strong>
                                                                </div>
                                                                <div>
                                                                  <input type="number" class="form-control text-center"
                                                                  [value]="featureItem.price"
                                                                  [disabled]="isAccepted || isSaving"
                                                                  (change)="adjustFeatureItemProperty(featureItem.id, 'price', $event.target.value, true);">
                                                                </div>
                                                              </div>
                                                          </div>

                                                          <div class="container-fluid p-0 m-0 mb-2 multi-container" *ngIf="proposal.agreementType == 'MULTI' && featureItem.totalPricingMulti">
                                                            <div class="list-item-multi-container">
                                                              <div>
                                                                <strong>Multi Price:</strong>
                                                              </div>
                                                              <div>
                                                                <div class="multi-price-subtotals-container">
                                                                  <div
                                                                      *ngIf="proposal.multiOptions.A.isEnabled && featureItem.totalPricingMulti.A">
                                                                      <input type="number" [disabled]="isAccepted"
                                                                          class="form-control text-center"
                                                                          [value]="featureItem.multiPricing.A"
                                                                          (change)="adjustMultiOptionPrice(featureItem, 'A', $event.target.value);" />
                                                                  </div>
                                                                  <div
                                                                      *ngIf="proposal.multiOptions.B.isEnabled && featureItem.totalPricingMulti.B">
                                                                      <input type="number" [disabled]="isAccepted"
                                                                          class="form-control text-center"
                                                                          [value]="featureItem.multiPricing.B"
                                                                          (change)="adjustMultiOptionPrice(featureItem, 'B', $event.target.value);" />
                                                                  </div>
                                                                  <div
                                                                      *ngIf="proposal.multiOptions.C.isEnabled && featureItem.totalPricingMulti.C">
                                                                      <input type="number" [disabled]="isAccepted"
                                                                          class="form-control text-center"
                                                                          [value]="featureItem.multiPricing.C"
                                                                          (change)="adjustMultiOptionPrice(featureItem, 'C', $event.target.value);" />
                                                                  </div>
                                                                  <div
                                                                      *ngIf="proposal.multiOptions.D.isEnabled && featureItem.totalPricingMulti.D">
                                                                      <input type="number" [disabled]="isAccepted"
                                                                          class="form-control text-center"
                                                                          [value]="featureItem.multiPricing.D"
                                                                          (change)="adjustMultiOptionPrice(featureItem, 'D', $event.target.value);" />
                                                                  </div>
                                                                  <div
                                                                      *ngIf="proposal.multiOptions.E.isEnabled && featureItem.totalPricingMulti.E">
                                                                      <input type="number" [disabled]="isAccepted"
                                                                          class="form-control text-center"
                                                                          [value]="featureItem.multiPricing.E"
                                                                          (change)="adjustMultiOptionPrice(featureItem, 'E', $event.target.value);" />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>

                                                            <div class="list-item-multi-container">
                                                              <div>
                                                                <strong>Subtotals:</strong>
                                                              </div>
                                                              <div>
                                                                <div class="multi-price-subtotals-container">
                                                                  <div
                                                                      *ngIf="proposal.multiOptions.A.isEnabled && featureItem.totalPricingMulti.A">
                                                                        <div>{{proposal.multiOptions.A.acronym}} | {{featureItem.totalPricingMulti.A | currency}}</div>
                                                                  </div>
                                                                  <div
                                                                      *ngIf="proposal.multiOptions.B.isEnabled && featureItem.totalPricingMulti.B">
                                                                        <div >{{proposal.multiOptions.B.acronym}} | {{featureItem.totalPricingMulti.B | currency}}</div>
                                                                  </div>
                                                                  <div
                                                                      *ngIf="proposal.multiOptions.C.isEnabled && featureItem.totalPricingMulti.C">
                                                                        <div>{{proposal.multiOptions.C.acronym}} | {{featureItem.totalPricingMulti.C | currency}}</div>
                                                                  </div>
                                                                  <div
                                                                      *ngIf="proposal.multiOptions.D.isEnabled && featureItem.totalPricingMulti.D">
                                                                        <div>{{proposal.multiOptions.D.acronym}} | {{featureItem.totalPricingMulti.D | currency}}</div>
                                                                  </div>
                                                                  <div
                                                                      *ngIf="proposal.multiOptions.E.isEnabled && featureItem.totalPricingMulti.E">
                                                                        <div>{{proposal.multiOptions.E.acronym}} | {{featureItem.totalPricingMulti.E | currency}}</div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div class="container-fluid p-0 m-0 mb-2" *ngIf="proposal.agreementType == 'SINGLE'">
                                                            <strong>Subtotal: </strong>{{featureItem.totalPrice | currency}}
                                                          </div>

                                                          <div class="list-button">
                                                            <button type="button" class="btn btn-danger btn-sm"
                                                                (click)="openDeleteFeatureItemConfirmationModal(featureItem);"
                                                                [disabled]="isAccepted">
                                                                <fa-icon [icon]="faTrashAlt"></fa-icon>&nbsp;Delete
                                                            </button>
                                                            <button type="button" class="btn btn-secondary btn-sm"
                                                                [disabled]="isAccepted" [ngbPopover]="featureItemOptions"
                                                                popoverTitle="Options" triggers="manual"
                                                                #featureItemOptionsPopover="ngbPopover"
                                                                (click)="toggleFeatureItemOptionsPopover(featureItemOptionsPopover, featureItem);">
                                                                <fa-icon [icon]="faEllipsis"></fa-icon>&nbsp;Options
                                                            </button>
                                                            <button type="button" class="btn btn-primary btn-sm"
                                                                [disabled]="isAccepted"
                                                                (click)="editFeatureItem(featureItem);">
                                                                <fa-icon [icon]="faPencil"></fa-icon>&nbsp;Edit
                                                            </button>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </li>
                                              <li *ngIf="getFeatureItems(feature.id).length == 0" class="list-group-item">No Items</li>
                                          </ul>
                                        </div>

                                    </div>
                                    <!-- New Feature Item -->
                                    <button type="button" class="btn btn-outline-primary w-100 mt-4"
                                        (click)="openFeatureItemModal(feature.id);" [disabled]="isAccepted">New
                                        Feature Item</button>
                                </ng-template>
                            </div>
                        </div>
                    </form>
                    <!-- </div> -->

                </div>
            </div>
            <!-- New Feature -->
            <button type="button" class="btn btn-outline-primary w-100" (click)="openFeatureModal();"
                [disabled]="isAccepted">New
                Feature</button>
        </form>
    </div>
</div>

<!-- Feature Modal -->
<ng-template #featureModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">New Feature</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"
            [disabled]="isAccepted"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="newFeatureForm">
            <div class="mb-3">
                <label for="NEW_FEATURE_NAME">Name</label>
                <input *ngIf="newFeatureNameTypeaheadInput" id="NEW_FEATURE_NAME" type="text" class="form-control"
                    formControlName="name" placeholder="Roofline" maxlength="150" required
                    [ngbTypeahead]="newFeatureNameTypeaheadInput"
                    (focus)="newFeatureNameTypeaheadFocus$.next($any($event).target.value)"
                    [class.is-invalid]="newFeatureName.invalid && (newFeatureName.dirty || newFeatureName.touched)">
                <div *ngIf="newFeatureName.invalid && (newFeatureName.dirty || newFeatureName.touched)">
                    <div *ngIf="newFeatureName.errors.required" class="input-validation-msg">Name is required.</div>
                    <div *ngIf="newFeatureName.errors.maxlength" class="input-validation-msg">Name cannot exceed 150
                        characters.
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label for="NEW_FEATURE_DESCRIPTION">Description</label>
                <textarea id="NEW_FEATURE_DESCRIPTION" class="form-control" formControlName="description"
                    placeholder="The roofline will be decorated with..." rows="3"></textarea>
            </div>
            <div class="row">
                <div class="col">
                    <label for="NEW_FEATURE_INSTALL_HOURS">Install Hours</label>
                    <div class="mb-3">
                        <input id="NEW_FEATURE_INSTALL_HOURS" type="number" class="form-control" placeholder="2.50"
                            formControlName="installHours" min="0"
                            [class.is-invalid]="newFeatureInstallHours.invalid && (newFeatureInstallHours.dirty || newFeatureInstallHours.touched)">
                        <div
                            *ngIf="newFeatureInstallHours.invalid && (newFeatureInstallHours.dirty || newFeatureInstallHours.touched)">
                            <div *ngIf="newFeatureInstallHours.errors.min" class="input-validation-msg">Install Hours
                                cannot be less than 0.</div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <label for="NEW_FEATURE_REMOVAL_HOURS">Removal Hours</label>
                    <div class="mb-3">
                        <input id="NEW_FEATURE_REMOVAL_HOURS" type="number" class="form-control" placeholder="2.50"
                            formControlName="removalHours" min="0"
                            [class.is-invalid]="newFeatureRemovalHours.invalid && (newFeatureRemovalHours.dirty || newFeatureRemovalHours.touched)">
                        <div
                            *ngIf="newFeatureRemovalHours.invalid && (newFeatureRemovalHours.dirty || newFeatureRemovalHours.touched)">
                            <div *ngIf="newFeatureRemovalHours.errors.min" class="input-validation-msg">Removal Hours
                                cannot be less than 0.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-check mb-0">
                        <input id="NEW_FEATURE_COST_HIDDEN" type="checkbox" class="form-check-input"
                            formControlName="costHidden">
                        <label for="NEW_FEATURE_COST_HIDDEN" class="form-check-label">Hide Price</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-check mb-0">
                        <input id="NEW_FEATURE_IS_OPTIONAL" type="checkbox" class="form-check-input"
                            formControlName="isOptional">
                        <label for="NEW_FEATURE_IS_OPTIONAL" class="form-check-label">Optional</label>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="addFeature();" [disabled]="isAccepted">Save</button>
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss();"
            [disabled]="isAccepted">Close</button>
    </div>
</ng-template>

<!-- Feature Item Modal -->
<ng-template #featureItemModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{(selectedFeatureItem) ? 'Edit' : 'New'}} Feature Item</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"
            [disabled]="isAccepted"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="featureItemForm">
            <div class="row">
                <div class="col mb-3">
                    <label for="FEATURE_INVENTORY_ITEM_CATEGORY">Item Category</label>
                    <select id="FEATURE_INVENTORY_ITEM_CATEGORY" class="form-select"
                        formControlName="inventoryItemCategory"
                        (change)="itemCategorySelected($event.target.value, false);">
                        <option *ngFor="let category of itemCategories" [value]="category">{{category}}</option>
                    </select>
                </div>
                <div class="col mb-3">
                    <label for="FEATURE_INVENTORY_ITEM">Item</label>
                    <select id="FEATURE_INVENTORY_ITEM" class="form-select" formControlName="inventoryItem"
                        (change)="itemSelected($event.target.value, false);">
                        <option *ngFor="let item of selectedItemCategoryItems" [value]="item.id">{{item.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="mb-3">
                        <div class="d-flex">
                            <label for="FEATURE_ITEM_QUANTITY">Quantity</label>
                            <div class="ms-2 d-flex h-fit" style="margin-top: 0.15rem; width: 100%">
                                <small *ngIf="selectedItemStockLevels" class="ms-auto text-muted">Cur:
                                    {{selectedItemStockLevels.stockCurrentLevel}}</small>
                                <small *ngIf="selectedItemStockLevels" class="ms-2 text-muted">Adj:
                                    {{selectedItemStockLevels.stockAdjustedLevel}}</small>
                            </div>
                        </div>
                        <input id="FEATURE_ITEM_QUANTITY" type="number" class="form-control" placeholder="10"
                            formControlName="quantity" min="1" step="1" required
                            [class.is-invalid]="featureItemQuantity.invalid && (featureItemQuantity.dirty || featureItemQuantity.touched)">
                        <div
                            *ngIf="featureItemQuantity.invalid && (featureItemQuantity.dirty || featureItemQuantity.touched)">
                            <div *ngIf="featureItemQuantity.errors.required" class="input-validation-msg">Quantity is
                                required.</div>
                            <div *ngIf="featureItemQuantity.errors.min" class="input-validation-msg">Quantity cannot be
                                less than 1.</div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="d-flex">
                        <label>Type</label>
                        <span class="ms-auto" style="cursor: pointer;" [ngbPopover]="featureItemTypeDescriptionPopover"
                            triggers="click" placement="left">
                            <fa-icon [icon]="faQuestionCircle"></fa-icon>
                        </span>
                    </div>
                    <div style="margin-top: 0.4rem!important;">
                        <div class="form-check form-check-inline">
                            <input id="FEATURE_ITEM_TYPE_NEW" class="form-check-input" type="radio"
                                formControlName="type" value="NEW">
                            <label for="FEATURE_ITEM_TYPE_NEW" class="form-check-label"
                                style="font-weight: normal;">New</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input id="FEATURE_ITEM_TYPE_EXISTING" class="form-check-input" type="radio"
                                formControlName="type" value="EXISTING">
                            <label for="FEATURE_ITEM_TYPE_EXISTING" class="form-check-label"
                                style="font-weight: normal;">Existing</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label for="FEATURE_ITEM_COST">Cost</label>
                    <div class="input-group">
                        <div class="input-group-text">$</div>
                        <input id="FEATURE_ITEM_COST" type="number" class="form-control" placeholder="5.00"
                            formControlName="cost" min="0" required
                            [class.is-invalid]="featureItemCost.invalid && (featureItemCost.dirty || featureItemCost.touched)">
                    </div>
                    <div *ngIf="featureItemCost.invalid && (featureItemCost.dirty || featureItemCost.touched)">
                        <div *ngIf="featureItemCost.errors.required" class="input-validation-msg">Cost is required.
                        </div>
                        <div *ngIf="featureItemCost.errors.min" class="input-validation-msg">Cost cannot be less than 0.
                        </div>
                    </div>
                </div>
                <div *ngIf="proposal.agreementType == 'SINGLE'" class="col">
                    <label for="FEATURE_ITEM_PRICE_SINGLE">Price</label>
                    <div class="input-group">
                        <div class="input-group-text">$</div>
                        <input id="FEATURE_ITEM_PRICE_SINGLE" type="number" class="form-control" placeholder="30.00"
                            formControlName="price" min="0" required
                            [class.is-invalid]="featureItemPrice.invalid && (featureItemPrice.dirty || featureItemPrice.touched)">
                    </div>
                    <div *ngIf="featureItemPrice.invalid && (featureItemPrice.dirty || featureItemPrice.touched)">
                        <div *ngIf="featureItemPrice.errors.required" class="input-validation-msg">Price is required.
                        </div>
                        <div *ngIf="featureItemPrice.errors.min" class="input-validation-msg">Price cannot be less than
                            0.</div>
                    </div>
                </div>
                <div *ngIf="proposal.agreementType == 'MULTI'" class="col">
                    <label for="FEATURE_ITEM_PRICE_MULTI">Price (Multi)</label>
                    <div class="input-group">
                        <div ngbDropdown class="d-inline-block">
                            <button type="button" class="btn btn-outline-secondary" ngbDropdownToggle
                                style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
                                {{proposal.multiOptions[selectedMultiOptionKey].acronym}}
                            </button>
                            <div ngbDropdownMenu>
                                <ng-container *ngFor="let key of multiOptionKeys">
                                    <button *ngIf="multiOptionIsEnabled(key);" ngbDropdownItem
                                        (click)="selectMultiOption(key);">
                                        <span>{{proposal.multiOptions[key].acronym}}</span>
                                        <span *ngIf="selectedMultiOptionPricing && selectedMultiOptionPricing[key]"
                                            class="ms-2">(${{selectedMultiOptionPricing[key]}})</span>
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                        <input id="FEATURE_ITEM_PRICE_MULTI" type="number" class="form-control" placeholder="15.00"
                            formControlName="multiPrice" min="0"
                            [class.is-invalid]="featureItemMultiPrice.invalid && (featureItemMultiPrice.dirty || featureItemMultiPrice.touched)"
                            (change)="multiOptionPricingChanged($event.target.value);">
                    </div>
                    <div
                        *ngIf="featureItemMultiPrice.invalid && (featureItemMultiPrice.dirty || featureItemMultiPrice.touched)">
                        <div *ngIf="featureItemMultiPrice.errors.min" class="input-validation-msg">Price cannot be less
                            than 0.</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col mt-3">
                    <div class="form-check mb-0">
                        <input id="FEATURE_ITEM_HIDDEN" type="checkbox" class="form-check-input"
                            formControlName="hidden">
                        <label for="FEATURE_ITEM_HIDDEN" class="form-check-label">Hide on Proposal</label>
                    </div>
                </div>
                <div class="col mt-3">
                    <div class="form-check mb-0">
                        <input id="FEATURE_ITEM_QUANTITY_HIDDEN" type="checkbox" class="form-check-input"
                            formControlName="quantityHidden">
                        <label for="FEATURE_ITEM_QUANTITY_HIDDEN" class="form-check-label">Hide Quantity</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col mt-2">
                    <div class="form-check mb-0">
                        <input id="FEATURE_ITEM_IS_TAXABLE" type="checkbox" class="form-check-input"
                            formControlName="isTaxable">
                        <label for="FEATURE_ITEM_IS_TAXABLE" class="form-check-label">Taxable</label>
                    </div>
                </div>
                <div class="col mt-2">
                    <label>Image Type</label>
                    <div style="margin-top: 0.4rem!important;">
                        <div class="form-check form-check-inline">
                            <input id="IMAGE_TYPE_NONE" type="radio" class="form-check-input"
                                formControlName="imageType" value="NONE">
                            <label for="IMAGE_TYPE_NONE" class="form-check-label"
                                style="font-weight: normal;">None</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input id="IMAGE_TYPE_ITEM" type="radio" class="form-check-input"
                                formControlName="imageType" value="ITEM">
                            <label for="IMAGE_TYPE_ITEM" class="form-check-label"
                                style="font-weight: normal;">Item</label>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="saveFeatureItem();">Save</button>
        <button *ngIf="selectedFeatureItem" type="button" class="btn btn-danger me-1"
            (click)="openDeleteFeatureItemConfirmationModal();">Delete</button>
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
    </div>
</ng-template>

<!-- Feature Item Type Description Popover -->
<ng-template #featureItemTypeDescriptionPopover>
    <p><strong>New</strong> This is for a new product; typically one that you would need to order or pull from
        inventory.</p>
    <p><strong>Existing</strong> This is for a product that is alrealy allocated to a client, typically from a previous
        install. This is usually put in a box and labled for a customer.</p>
    <p class="mb-0"><strong>Note</strong> This decision is important for the Inventory Prep stage. Only "New" items will
        show up in the Inventory Prep stage, since "Existing" items are typically already put aside for the client.</p>
</ng-template>

<!-- Feature Item Options Popover -->
<ng-template #featureItemOptions let-featureItem="featureItem">
    <div>
        <div class="form-check form-check-reverse text-start">
            <input id="FI-HIDDEN-{{featureItem.id}}" type="checkbox" class="form-check-input"
                (change)="updateFeatureItemProperty(featureItem.id, 'hidden', $event.target.checked);"
                [checked]="featureItem.hidden">
            <label for="FI-HIDDEN-{{featureItem.id}}" class="form-check-label ms-1">Hide on Proposal</label>
        </div>
        <div class="form-check form-check-reverse text-start">
            <input id="FI-QTY-HIDDEN-{{featureItem.id}}" type="checkbox" class="form-check-input"
                (change)="updateFeatureItemProperty(featureItem.id, 'quantityHidden', $event.target.checked);"
                [checked]="featureItem.quantityHidden">
            <label for="FI-QTY-HIDDEN-{{featureItem.id}}" class="form-check-label ms-1">Hide Quantity</label>
        </div>
        <div class="form-check form-check-reverse text-start">
            <input id="FI-IS-TAXABLE-{{featureItem.id}}" type="checkbox" class="form-check-input"
                (change)="updateFeatureItemProperty(featureItem.id, 'isTaxable', $event.target.checked, true);"
                [checked]="featureItem.isTaxable">
            <label for="FI-IS-TAXABLE-{{featureItem.id}}" class="form-check-label ms-1">Taxable</label>
        </div>
    </div>
</ng-template>
