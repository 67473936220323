<div class="modal-header">
    <div>
        <h5 class="modal-title">Error creating Proposal</h5>
        <!-- <h5 class="text-muted">{{ itemName }}</h5> -->
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <div class="row mt-2 align-items-center">
        <div class="col-md-10 mx-auto text-center">
            <p> You are not allowed to create Proposal for Clients without primary contact email. </p>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submit(1)">Add Contact</button>
    <button type="button" class="btn btn-secondary" (click)="submit(0)">Cancel</button>
</div>