<div class="modal-header">
  <h5 class="modal-title">Send SMS</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
  <form [formGroup]="sendSmsForm">
      <ng-template #list>
          <div *ngIf="contact">
              <small><b>Name:</b> {{contact.firstName}} {{contact.lastName}} <br> <b>Client Name:</b> {{contact.clientName}} <br> <b>Phone Number:</b> {{contact.phone}}</small>
          </div>
          <div *ngIf="!contact"><span>{{sendSmsForm.controls['number'].value}}</span></div>
      </ng-template>
      <div class="mb-3">
          <label for="send_sms">Contact</label>
          <div class="input-group">
          <input [ngbTypeahead]="search"
          (focus)="focus$.next($event.target.value)"
          (click)="click$.next($event.target.value)"
          #instance="ngbTypeahead"
          [resultFormatter]="formatter"
          (selectItem)="selectContact($event)"
          formControlName="number" [ngbTooltip]="list" type="text" class="form-control">
          <div class="input-group-text" *ngIf="contacts.length === 0 && sendSmsForm.get('number').value">
            <fa-icon (click)="addToContactNumber(sendSmsForm.get('number').value)" [icon]="faPlus"></fa-icon>
          </div>
        </div>
          <div *ngFor="let contact of toContactNumbers; index as i;" class="selectedContact">
            <span>{{contact.firstName}} {{contact.lastName}} - {{contact.phone}} ({{contact.clientName || clientName}})</span>
            <button type="button" class="btn ms-1" (click)="removeToContactNumber(i);">
                <fa-icon [icon]="faTimes"></fa-icon>
            </button>
        </div>
        </div>
      <div class="mb-3">
          <div class="row mb-2">
              <div class="col d-flex">
                  <label class="my-auto">Message</label>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12">
                  <textarea formControlName="body" class="form-control" rows="5"></textarea>
              </div>
          </div>
      </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="prepareSMS();">
      <span *ngIf="!isLoading">Send</span>
      <fa-icon *ngIf="isLoading" [icon]="faRotateRight" [spin]="isLoading"></fa-icon>
  </button>
  <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>
