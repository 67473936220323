import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { lastValueFrom, merge, Observable, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Item } from '../item';
import { InventoryService } from '../inventory.service';
import { AlertService } from '../alert.service';
import { NgbModal, NgbModalRef, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { faAngleLeft, faPlus, faSortUp, faSortDown, faFilter, faCheck, faTimes, faFileDownload, faFileUpload, faEye, faEyeSlash, faMinusCircle, faRotateLeft, faInfoCircle, faPlusCircle, faTriangleExclamation, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SettingService } from '../setting.service';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { TableControlService } from '../table-control.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AdminSettingService } from '../admin-setting.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Modals
  private modalReference: NgbModalRef;
  private popoverReference: NgbPopover;
  @ViewChild('INVENTORY_ITEM_MODAL', { static: false }) private inventoryItemModal;

  // Properties
  items: Item[] = [];
  itemsCount: number = 0;
  selectedItem: Item = null;
  isMobileView: boolean = false;

  sortOptions = [
    { label: 'SKU', value: 'item_sku' },
    { label: 'Name', value: 'item_name' },
    { label: 'Category', value: 'item_category' },
    { label: 'Stor. Loc.', value: 'item_storage_location' },
    { label: 'Stock Item', value: 'item_stock' }
  ];
  filterColumns = [
    { label: 'Category', value: 'item_category' },
    { label: 'Stor. Loc.', value: 'item_storage_location' },
    { label: 'Stock Item', value: 'item_stock' }
  ];
  sortBy: string = 'item_sku';
  sortDirection: string = 'ASC';

  // Table Controls
  tableControls: TableControlService;
  storedSearchValue: string;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;
  selectedFilterColumn: string ='';

  multiAgreementTypeOptions: any;
  multiAgreementTypeOptionKeys: any[] = [];
  selectedMultiAgreementTypeOption: any;
  selectedMultiAgreementTypeOptionKey: string;
  selectedMultiAgreementTypePricing: any;

  // Item Form
  itemForm: UntypedFormGroup;

  // Item Image
  private tempItemImageUrl: string;
  itemImageUrl: string | SafeUrl;
  itemImageReadyForUpload: boolean = false;
  uploadTotal: number = 0;
  uploadLoaded: number = 0;
  lastUploadLoaded: number = 0;

  // Source Typeahead
  sourceTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  sourceTypeaheadInputFocus$ = new Subject<string>();

  // Category Typeahead
  categoryTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  categoryTypeaheadInputFocus$ = new Subject<string>();

  // Storage Location Typeahead
  storageLocationTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  storageLocationTypeaheadInputFocus$ = new Subject<string>();

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faPlus = faPlus;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;
  faCheck = faCheck;
  faTimes = faTimes;
  faFileDownload = faFileDownload;
  faFileUpload = faFileUpload;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faRotateLeft = faRotateLeft;
  faInfoCircle = faInfoCircle;
  faTriangleExclamation = faTriangleExclamation;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private inventoryService: InventoryService,
    private settingService: SettingService,
    private alertService: AlertService,
    private modalService: NgbModal,
    public location: Location,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private adminSettingService: AdminSettingService) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
          this.tableControls.setLimit(20);
        }
      });
    }

  ngOnInit(): void {
    this.itemForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      sku: new UntypedFormControl(),
      source: new UntypedFormControl(),
      category: new UntypedFormControl(),
      link: new UntypedFormControl(),
      storageLocation: new UntypedFormControl(),
      cost: new UntypedFormControl(),
      price: new UntypedFormControl(),
      multiPrice: new UntypedFormControl(),
      stockCurrentLevel: new UntypedFormControl(),
      stockWarningLevel: new UntypedFormControl(),
      stock: new UntypedFormControl()
    });
    this.tableControls = new TableControlService('Inventory', true, 'item_sku', 'ASC');
    this.checkScreenSize();
    if(this.isMobile) {
      let activeFilters = this.tableControls.getActiveFilters()
      let columns = [];
      for (const [key, value] of activeFilters) {
        columns.push(key)
      }
      this.tableControls.resetFilters(columns)
    }
    this.tableControls.refresh.subscribe(() => {
      this.getItems();
    });
    if (this.tableControls.hasTableConfiguration()) this.storedSearchValue = this.tableControls.getSearchTerm();
    this.getItems();
    this.prepareSourceTypeahead();
    this.prepareCategoryTypeahead();
    this.prepareStorageLocationTypeahead();
    this.getMultiAgreementTypeOptions();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Modal
  openModal(content: any): void {
    this.modalReference = this.modalService.open(content);
  }

  // Open Delete Item Confirmation Modal
  openDeleteItemConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this item?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteItem();
    });
  }

  // Multi Option Is Enabled
  multiOptionIsEnabled(key: string): boolean {
    return this.multiAgreementTypeOptions[key].isEnabled;
  }

  // Are All Muti Options Disabled
  areAllMultiOptionsDisabled(): boolean {
    for (const key in this.multiAgreementTypeOptions) {
      if (this.multiAgreementTypeOptions[key].isEnabled) return false;
    }
    return true;
  }

  // Get Items
  getItems(): void {
    let params = this.tableControls.getParams();
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
      for (const [key, value] of this.activeFilters) {
        if (key.includes('range')) params[`filter:${key}`] = value;
        else params[`filter:${key}`] = JSON.stringify(value);
      }
    };
    this.inventoryService.getItems(params).subscribe((res) => {
      this.items = res.items;
      this.itemsCount = res.count;
    });
  }

  // Get Sources
  private getSources(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'i.item_source',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'i.item_source',
      hideBlanks: true
    };
    return this.inventoryService.getDistinctColumnValues(params);
  }

  // Prepare Source Typeahead
  private prepareSourceTypeahead(): void {
    this.sourceTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.sourceTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getSources((term.length == 0) ? null : term);
      }));
    }
  }

  // Get Categories
  private getCategories(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'i.item_category',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'i.item_category',
      hideBlanks: true
    };
    return this.inventoryService.getDistinctColumnValues(params);
  }

  // Prepare Category Typeahead
  private prepareCategoryTypeahead(): void {
    this.categoryTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.categoryTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getCategories((term.length == 0) ? null : term);
      }));
    }
  }

  // Get Storage Locations
  private getStorageLocations(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'i.item_storage_location',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'i.item_storage_location',
      hideBlanks: true
    };
    return this.inventoryService.getDistinctColumnValues(params);
  }

  // Prepare Storage Location Typeahead
  private prepareStorageLocationTypeahead(): void {
    this.storageLocationTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.storageLocationTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getStorageLocations((term.length == 0) ? null : term);
      }));
    }
  }

  // Download Inventory
  downloadInventory(): void {
    window.open(`${environment.apiUrl}/inventory/export`);
  }

  // File Input Changed
  fileInputChanged(files: File[]): void {
    const file = files[0];
    if (files.length !== 0) {
      if (file.type != 'application/vnd.ms-excel' && file.type != 'text/csv') {
        this.alertService.showWarningAlert('File must be a CSV.');
      } else {
        this.alertService.showInfoAlert('Importing...');
        this.inventoryService.importInventory(file).pipe(catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 400) (document.getElementById('FILE_INPUT') as HTMLInputElement).value = null;
          }
          return throwError(() => new Error('API Request Error'));
        })).subscribe((res) => {
          this.alertService.showSuccessAlert(`Imported ${res.addItemCount} Items and Updated ${res.updateItemCount} Items`);
          this.getItems();
          (document.getElementById('FILE_INPUT') as HTMLInputElement).value = null;
        });
      }
    }
  }

  // Browse Files
  browseFiles(): void {
    (document.getElementById('FILE_INPUT') as HTMLInputElement).click();
  }

  // Show Item Stock Level Popover
  showItemStockLevelPopover(popover: NgbPopover, itemId: string, title: string, type: string): void {
    this.popoverReference = popover;
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({ itemId, title, type });
    }
  }

  // Adjust Item Stock Current Level
  adjustItemStockCurrentLevel(itemId: string, type: string): void {
    const value = (<HTMLInputElement>document.getElementById('ADJUST_STOCK_CURRENT_LEVEL_INPUT')).value;
    if (value.length > 0) {
      const amount = (type == 'REMOVE') ? -value : +value;
      this.inventoryService.adjustItemStockCurrentLevel(itemId, amount).subscribe(() => {
        this.alertService.showSuccessAlert('Item Updated');
        this.popoverReference.close();
        this.getItems();
      });
    } else {
      this.alertService.showWarningAlert('Invalid Value');
    }
  }

  /* ----- Filters and Sorting ----- */

  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    this.selectedFilterColumn = column;
    if (!this.columnFilterValues.has(column)) {
      const params = {
        searchTerm: null,
        sortBy: column,
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        column: column
      };
      if(this.isMobile) {
        for (const [key, value] of this.activeFilters) {
          if (key.includes('range')) params[`filter:${key}`] = value;
          else params[`filter:${key}`] = JSON.stringify(value);
        }
      }
      this.inventoryService.getDistinctColumnValues(params).subscribe((values) => {
        const tempArray = [];
        for (const value of values) {
          let name = null;
          switch (column) {
            case 'item_stock':
              name = this.formatStock(value);
              break;
            default:
              name = (value === null || value.length == 0) ? 'BLANK' : value;
              break;
          }
          tempArray.push({ name: name, value: value, randomId: Math.random().toString(36) });
        }
        this.columnFilterValues.set(column, tempArray);
      });
    }
  }

  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getItems();
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getItems();
    this.getActiveFilterCount();
  }

   // Get Active Filter Count
   getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getItems();
    this.getActiveFilterCount();
  }

  // Show Column Filter Popover
  showColumnFilterPopover(popover: NgbPopover, column: string): void {
    this.getDistinctColumnValues(column);
    if (popover.isOpen()) {
      popover.close();
    } else {
      setTimeout(() => {
        popover.open({ column });
      }, 250);
    }
  }

  /* ----- Item Form ----- */

  // Save Item
  saveItem(): void {
    if (this.selectedItem === null) {
      this.addItem();
    } else {
      this.updateItem();
    }
  }

  // Add Item
  private async addItem(): Promise<void> {
    try {
      this.validateMultiPricing(this.selectedMultiAgreementTypePricing);
      if (this.itemForm.valid) {
        const item = {
          sku: this.itemForm.value.sku,
          name: this.itemForm.value.name,
          source: this.itemForm.value.source,
          category: this.itemForm.value.category,
          link: this.itemForm.value.link,
          storageLocation: this.itemForm.value.storageLocation,
          cost: this.itemForm.value.cost,
          price: this.itemForm.value.price,
          multiPricing: this.selectedMultiAgreementTypePricing,
          stock: this.itemForm.value.stock,
          stockCurrentLevel: this.itemForm.value.stockCurrentLevel,
          stockWarningLevel: this.itemForm.value.stockWarningLevel,
          imageExists: this.itemImageReadyForUpload
        };
        const { id, imageUploadUrl } = await lastValueFrom(this.inventoryService.addItem(item));
        if (this.itemImageReadyForUpload) await this.uploadItemImage(imageUploadUrl);
        this.alertService.showSuccessAlert('Item Added');
        this.getItems();
        this.modalReference.close();
        if (this.itemImageReadyForUpload) this.resetItemImageUpload();
      } else {
        this.itemForm.markAllAsTouched();
      }
    } catch (error) {
      if (!(error instanceof HttpErrorResponse)) this.alertService.showWarningAlert(error.message);
    }
  }

  // Update Item
  private async updateItem(): Promise<void> {
    try {
      this.validateMultiPricing(this.selectedMultiAgreementTypePricing);
      if (this.itemForm.valid) {
        const item = {
          id: this.selectedItem.id,
          name: this.itemForm.value.name,
          source: this.itemForm.value.source,
          category: this.itemForm.value.category,
          link: this.itemForm.value.link,
          storageLocation: this.itemForm.value.storageLocation,
          cost: this.itemForm.value.cost,
          price: this.itemForm.value.price,
          multiPricing: this.selectedMultiAgreementTypePricing,
          stock: this.itemForm.value.stock,
          stockCurrentLevel: this.itemForm.value.stockCurrentLevel,
          stockWarningLevel: this.itemForm.value.stockWarningLevel,
          imageExists: this.itemImageReadyForUpload || this.selectedItem.imageExists
        };
        const { id, imageUploadUrl } = await lastValueFrom(this.inventoryService.updateItem(item));
        if (this.itemImageReadyForUpload) await this.uploadItemImage(imageUploadUrl);
        this.alertService.showSuccessAlert('Item Updated');
        this.getItems();
        this.modalReference.close();
        if (this.itemImageReadyForUpload) this.resetItemImageUpload();
      } else {
        this.itemForm.markAllAsTouched();
      }
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  // Delete Item
  deleteItem(): void {
    this.inventoryService.deleteItem(this.selectedItem.id).subscribe(() => {
      this.alertService.showSuccessAlert('Item Deleted');
      this.modalReference.close();
      this.resetItemForm();
      this.getItems();
      this.selectedItem = null;
    });
  }

  // Item Selected
  itemSelected(item: Item): void {
    this.selectedItem = item;
    this.selectedMultiAgreementTypePricing = item.multiPricing;
    this.itemImageUrl = item.imageUrl;
    this.prepareItemForm();
    this.selectMultiAgreementTypeOption('A');
  }

  // Prepare Item Form
  prepareItemForm(): void {
    this.itemForm.controls.name.setValue(this.selectedItem.name);
    this.itemForm.controls.sku.setValue(this.selectedItem.sku);
    this.itemForm.controls.sku.disable();
    this.itemForm.controls.source.setValue(this.selectedItem.source);
    this.itemForm.controls.category.setValue(this.selectedItem.category);
    this.itemForm.controls.link.setValue(this.selectedItem.link);
    this.itemForm.controls.storageLocation.setValue(this.selectedItem.storageLocation);
    this.itemForm.controls.cost.setValue(this.selectedItem.cost);
    this.itemForm.controls.price.setValue(this.selectedItem.price);
    this.itemForm.controls.stockCurrentLevel.setValue(this.selectedItem.stockCurrentLevel);
    this.itemForm.controls.stockWarningLevel.setValue(this.selectedItem.stockWarningLevel);
    this.itemForm.controls.stock.setValue(this.selectedItem.stock);
    this.openModal(this.inventoryItemModal);
  }

  // Reset Item Form
  resetItemForm(): void {
    this.selectedItem = null;
    this.selectedMultiAgreementTypePricing = null;
    this.itemForm.reset();
    this.itemForm.controls.name.setValue(null);
    this.itemForm.controls.sku.setValue(null);
    this.itemForm.controls.sku.enable();
    this.itemForm.controls.source.setValue(null);
    this.itemForm.controls.category.setValue(null);
    this.itemForm.controls.link.setValue(null);
    this.itemForm.controls.storageLocation.setValue(null);
    this.itemForm.controls.cost.setValue(null);
    this.itemForm.controls.price.setValue(null);
    this.itemForm.controls.multiPrice.setValue(null);
    this.itemForm.controls.stockCurrentLevel.setValue(null);
    this.itemForm.controls.stockWarningLevel.setValue(null);
    this.itemForm.controls.stock.setValue(false);
    this.selectMultiAgreementTypeOption(this.multiAgreementTypeOptionKeys[0]);
  }

  // Get Multi Agreement Type Options
  private getMultiAgreementTypeOptions(): void {
    this.settingService.getMultiAgreementTypeOptions().subscribe((options) => {
      this.multiAgreementTypeOptions = options;
      this.multiAgreementTypeOptionKeys = Object.keys(this.multiAgreementTypeOptions);
      this.selectedMultiAgreementTypeOption = this.multiAgreementTypeOptions.A;
      this.selectedMultiAgreementTypeOptionKey = this.multiAgreementTypeOptionKeys[0];
    });
  }

  // Select Multi Agreement Type Option
  selectMultiAgreementTypeOption(key: string): void {
    this.selectedMultiAgreementTypeOptionKey = key;
    this.selectedMultiAgreementTypeOption = this.multiAgreementTypeOptions[key];
    if (this.areAllMultiOptionsDisabled()) return;
    if (this.selectedMultiAgreementTypePricing && this.selectedMultiAgreementTypePricing[key]) {
      this.itemForm.controls.multiPrice.setValue(this.selectedMultiAgreementTypePricing[key]);
    } else {
      this.itemForm.controls.multiPrice.setValue(null);
    }
  }

  // Option Pricing Changed
  optionPricingChanged(price: number): void {
    if (!this.selectedMultiAgreementTypePricing) this.selectedMultiAgreementTypePricing = {};
    this.selectedMultiAgreementTypePricing[this.selectedMultiAgreementTypeOptionKey] = price;
  }

  /* ----- Item Image ----- */

  // Browse Image Files
  browseImageFiles(): void {
    (document.getElementById('IMAGE_FILE_INPUT') as HTMLInputElement).click();
  }

  // Item Image Input Changed
  itemImageInputChanged(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    this.tempItemImageUrl = URL.createObjectURL(file);
    this.itemImageUrl = this.sanitizer.bypassSecurityTrustUrl(this.tempItemImageUrl);
    this.itemImageReadyForUpload = true;
  }

  // Cancel Image Upload
  cancelImageUpload(): void {
    URL.revokeObjectURL(this.tempItemImageUrl);
    (document.getElementById('IMAGE_FILE_INPUT') as HTMLInputElement).value = null;
    this.itemImageUrl = (this.selectedItem.imageExists) ? this.selectedItem.imageUrl : null;
    this.itemImageReadyForUpload = false;
  }

  // Upload Item Image
  async uploadItemImage(uploadUrl: string): Promise<void> {
    const file = (document.getElementById('IMAGE_FILE_INPUT') as HTMLInputElement).files[0];
    this.uploadTotal = file.size;
    await new Promise<void>((resolve, reject) => {
      const req = new HttpRequest('PUT', uploadUrl, file, { reportProgress: true });
      this.http.request(req).subscribe((event: HttpEvent<any>) => {
        if (event.type == HttpEventType.UploadProgress) this.uploadLoaded = event.loaded + this.lastUploadLoaded;
        if (event.type == HttpEventType.Response) (event.ok) ? resolve() : reject();
      });
    });
  }

  // Reset Item Image Upload
  resetItemImageUpload(): void {
    (document.getElementById('IMAGE_FILE_INPUT') as HTMLInputElement).value = null;
    this.itemImageUrl = null;
    this.itemImageReadyForUpload = false;
    this.uploadTotal = 0;
    this.uploadLoaded = 0;
    this.lastUploadLoaded = 0;
  }

  // Delete Item Image
  deleteItemImage(): void {
    this.inventoryService.deleteItemImage(this.selectedItem.id).subscribe(() => {
      this.alertService.showSuccessAlert('Item Image Deleted');
      this.getItems();
      this.selectedItem.imageExists = false;
      this.selectedItem.imageUrl = null;
    });
  }

  /* ----- Helper Funtions ----- */

  // Format Stock
  private formatStock(value: string): string {
    switch (value.toString()) {
      case '0':
        return 'No';
      case '1':
        return 'Yes';
      default:
        return 'Unknown';
    }
  };

  // Validate Multi Pricing
  private validateMultiPricing(multiPricing: any): void {
    const max = Infinity;
    const min = 0;
    for (const option in multiPricing) {
      const field = `Price (Multi) ${this.multiAgreementTypeOptions[option].acronym}`;
      const value = multiPricing[option];
      if (value > max) {
        this.selectMultiAgreementTypeOption(option);
        throw new Error(`${field} cannot be greater than ${max}.`);
      }
      if (value < min) {
        this.selectMultiAgreementTypeOption(option);
        throw new Error(`${field} cannot be less than ${min}.`);
      }
    }
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
    this.isMobileView ? this.tableControls.setLimit(5): this.tableControls.setLimit(20);
  }

  /* ----- Item Form Accessors ----- */

  get name() { return this.itemForm.controls.name; }
  get sku() { return this.itemForm.controls.sku; }
  get source() { return this.itemForm.controls.source; }
  get category() { return this.itemForm.controls.category; }
  get link() { return this.itemForm.controls.link; }
  get storageLocation() { return this.itemForm.controls.storageLocation; }
  get cost() { return this.itemForm.controls.cost; }
  get price() { return this.itemForm.controls.price; }
  get multiPrice() { return this.itemForm.controls.multiPrice; }
  get stockCurrentLevel() { return this.itemForm.controls.stockCurrentLevel; }
  get stockWarningLevel() { return this.itemForm.controls.stockWarningLevel; }
  get stock() { return this.itemForm.controls.stock; }
}
