export const environment = {
  production: false,
  apiUrl: `https://dev2.tinselcrm.com/api`,
  square: {
    webSdkUrl: 'https://sandbox.web.squarecdn.com/v1/square.js'
  },
  tinselPay: {
    tokenizationKey: 'csfC34-2Ha8Nr-WMQV4E-28Rz6u',
    passFeePercent: 3.95
  },
  version: '1.86.7',
  map: 'v1.public.eyJqdGkiOiI3MzA3NGJmZi01MjZmLTQ3MjYtYjE5Ni00YWIzYTI4YTBiNTMifW7xLLCiscXOIPXRz5Tega5CxIG-5JuHDZsx3nHwAxyvOwX567FcH50qidMXinWq-S-irREWyHMfnNcp3C9z1xtKWFXyYvG5ksEDIf5_szBT-EW23evswABn16ZvWcK5Bo5oSv-n4nBnBhpi_bM5362CQKkekBxaFTnMcTh7oj13uc6VfSWGvL1QVfEN-aooepuTYFWhGXm2t69rZu8jaI6WnXAdzN1QZr6H4TmPychwLsjL2cPo0eE3NEw5ZMV74F89AKHWohQ6LqSOHvkfMyEg-Vn0ubSLDG4Rz4E7zpATvMzx9aWDq2bkd-Swhj0BqucHvljVl64CsXpvQaHgMFg.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx'
};
