import { Component, Input, OnInit, Output } from '@angular/core';
import { faCircle, faTrashCan, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../notification.service';
import { Notification } from '../notification';

@Component({
  selector: 'app-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.css']
})
export class NotificationCenterComponent implements OnInit {

  // Properties
  notifications: Notification[] = [];
  unreadNotificationCount: number = 0;

  @Input() isSMS: boolean = false;

  // Font Awesome Properties
  faCircle = faCircle;
  faTrashCan = faTrashCan;
  faEnvelopeOpen = faEnvelopeOpen;

  constructor(private notificationService: NotificationService,
    public offcanvas: NgbActiveOffcanvas) { }

  ngOnInit(): void {
    this.getNotifications();
    this.listenForNotifications();
  }

  // Listen For Notifications
  private listenForNotifications(): void {
    this.notificationService.notificationsUpdated.subscribe(() => {
      this.getNotifications();
    });
  }

  // Get Notifications
  private getNotifications(): void {
    this.notifications = this.isSMS ? this.notificationService.conversationNotifications : this.notificationService.notifications;
    this.unreadNotificationCount = this.notifications.filter((notification) => { return !notification.isRead; }).length;
  }

  // Delete Notification
  deleteNotification(notification: Notification): void {
    this.notificationService.deleteNotification(notification.id).subscribe(() => {
      this.notificationService.refreshNotifications();
    });
  }

  // Delete All Notifications
  deleteAllNotifications(): void {
    this.notificationService.deleteAllNotifications().subscribe(() => {
      this.notificationService.refreshNotifications();
    });
  }

  // Read All Notifications
  readAllNotifications(): void {
    this.notificationService.readAllNotifications().subscribe(() => {
      this.notificationService.refreshNotifications();
    });
  }

  // Read Notification
  readNotification(notification: Notification): void {
    this.notificationService.readNotification(notification.id).subscribe(() => {
      this.notificationService.refreshNotifications();
    });
  }
}
