import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { faCircleCheck, faCircleInfo, faTriangleExclamation, faCircleNotch, faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from '../alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  // Properties
  @ViewChild('ALERT_CONTAINER') private alertContainer: ElementRef;
  @ViewChild('ALERT_TITLE') private alertTitle: ElementRef;
  @ViewChild('ALERT_MSG') private alertMsg: ElementRef;

  private dismissTimeoutId: NodeJS.Timeout;
  private resetTimeoutId: NodeJS.Timeout;
  alertIcon: IconDefinition;
  type: string;

  uploadLoaded: number = 0;
  uploadTotal: number = 0;

  // Font Awesome Properties
  faCircleCheck = faCircleCheck;
  faCircleInfo = faCircleInfo;
  faTriangleExclamation = faTriangleExclamation;
  faCircleNotch = faCircleNotch;
  faTimes = faTimes;
  errorCustomClass: string = '';
  constructor(public alertService: AlertService) { }

  ngOnInit(): void {
    this.alertService.alertTriggered$.subscribe((value) => {
      this.type = value.type;
      switch (value.type) {
        case 'ERROR':
          this.showErrorAlert(value.msg, value.class);
          break;
        case 'WARNING':
          this.showWarningAlert(value.msg);
          break;
        case 'INFO':
          this.showInfoAlert(value.msg);
          break;
        case 'SUCCESS':
          this.showSuccessAlert(value.msg);
          break;
        default:
          break;
      }
    });
    this.alertService.alertProgressUpdated$.subscribe((progress) => {
      this.uploadLoaded = progress.loaded;
      this.uploadTotal = progress.total;
    });
  }

  // Show Error Alert
  private showErrorAlert(msg: string, customClass?: string): void {
    clearTimeout(this.dismissTimeoutId);
    clearTimeout(this.resetTimeoutId);
    this.errorCustomClass = customClass;
    this.resetAlert();
    this.alertIcon = faTriangleExclamation;
    this.alertContainer.nativeElement.classList.add('alert-danger');
    this.alertContainer.nativeElement.style.margin = '0.5rem 0 0 0.5rem';
    this.alertContainer.nativeElement.style.padding = '0.75rem 1.25rem';
    this.alertTitle.nativeElement.innerHTML = 'Error';
    this.alertMsg.nativeElement.innerHTML = msg;
    this.alertContainer.nativeElement.style.opacity = '1';
    this.dismissTimeoutId = setTimeout(() => {
      this.dismissAlert();
    }, 5000);
  }

  // Show Warning Alert
  private showWarningAlert(msg: string): void {
    clearTimeout(this.dismissTimeoutId);
    clearTimeout(this.resetTimeoutId);
    this.resetAlert();
    this.alertIcon = faTriangleExclamation;
    this.alertContainer.nativeElement.classList.add('alert-warning');
    this.alertContainer.nativeElement.style.margin = '0.5rem 0 0 0.5rem';
    this.alertContainer.nativeElement.style.padding = '0.75rem 1.25rem';
    this.alertTitle.nativeElement.innerHTML = 'Warning';
    this.alertMsg.nativeElement.innerHTML = msg;
    this.alertContainer.nativeElement.style.opacity = '1';
    this.dismissTimeoutId = setTimeout(() => {
      this.dismissAlert();
    }, 5000);
  }

  // Show Info Alert
  private showInfoAlert(msg: string): void {
    clearTimeout(this.dismissTimeoutId);
    clearTimeout(this.resetTimeoutId);
    this.resetAlert();
    this.alertIcon = faCircleInfo;
    this.alertContainer.nativeElement.classList.add('alert-info');
    this.alertContainer.nativeElement.style.margin = '0.5rem 0 0 0.5rem';
    this.alertContainer.nativeElement.style.padding = '0.75rem 1.25rem';
    this.alertTitle.nativeElement.innerHTML = 'Info';
    this.alertMsg.nativeElement.innerHTML = msg;
    this.alertContainer.nativeElement.style.opacity = '1';
  }

  // Show Success Alert
  showSuccessAlert(msg: string): void {
    clearTimeout(this.dismissTimeoutId);
    clearTimeout(this.resetTimeoutId);
    this.resetAlert();
    this.alertIcon = faCircleCheck;
    this.alertContainer.nativeElement.classList.add('alert-success');
    this.alertContainer.nativeElement.style.margin = '0.5rem 0 0 0.5rem';
    this.alertContainer.nativeElement.style.padding = '0.75rem 1.25rem';
    this.alertTitle.nativeElement.innerHTML = 'Success';
    this.alertMsg.nativeElement.innerHTML = msg;
    this.alertContainer.nativeElement.style.opacity = '1';
    this.dismissTimeoutId = setTimeout(() => {
      this.dismissAlert();
    }, 5000);
  }

  // Reset Alert
  private resetAlert(): void {
    this.alertContainer.nativeElement.classList.remove('alert-danger');
    this.alertContainer.nativeElement.classList.remove('alert-warning');
    this.alertContainer.nativeElement.classList.remove('alert-info');
    this.alertContainer.nativeElement.classList.remove('alert-success');
    this.alertContainer.nativeElement.style.margin = '0';
    this.alertContainer.nativeElement.style.padding = '0';
    this.alertTitle.nativeElement.innerHTML = null;
    this.alertMsg.nativeElement.innerHTML = null;
    this.uploadLoaded = 0;
    this.uploadTotal = 0;
  }

  // Dismiss Alert
  private dismissAlert(): void {
    clearTimeout(this.dismissTimeoutId);
    this.alertContainer.nativeElement.style.opacity = '0';
    this.resetTimeoutId = setTimeout(() => {
      this.resetAlert();
    }, 500);
  }
}
