import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AlertService } from '../alert.service';
import { UserService } from '../user.service';

enum ForgotPasswordStage {
  EMAIL,
  CODE,
  PASSWORD
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  // Properties
  stage: ForgotPasswordStage = ForgotPasswordStage.EMAIL;
  resetPasswordForm: UntypedFormGroup;

  constructor(private userService: UserService,
    private alertService: AlertService,
    private router: Router) { }

  ngOnInit(): void {
    this.resetPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl(),
      verificationCode: new UntypedFormControl(),
      password: new UntypedFormControl(),
      confirmPassword: new UntypedFormControl()
    });
    this.resetPasswordForm.controls.email.addAsyncValidators(this.emailExistsValidator());
    this.resetPasswordForm.controls.confirmPassword.addValidators(this.passwordMatchValidator());
  }

  // Email Exists Validator
  private emailExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return this.userService.checkUserEmailExists(control.value).pipe(map((result: boolean) => {
        return !result ? { doesNotExist: true } : null;
      }));
    };
  }

  // Password Match Validator
  private passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      return (control.value != this.resetPasswordForm.value.password) ? { doesNotMatch: true } : null;
    }
  }

  // Initiate Password Reset
  initiatePasswordReset(): void {
    if (this.resetPasswordForm.controls.email.valid) {
      this.userService.initiatePasswordReset(this.resetPasswordForm.value.email).subscribe(() => {
        this.stage = ForgotPasswordStage.CODE;
      });
    } else {
      this.resetPasswordForm.controls.email.markAsTouched();
    }
  }

  // Already Have Code
  alreadyHaveCode(): void {
    if (this.resetPasswordForm.controls.email.valid) {
      this.stage = ForgotPasswordStage.CODE;
    } else {
      this.resetPasswordForm.controls.email.markAsTouched();
    }
  }

  // Verify Verification Code
  verifyVerificationCode(): void {
    if (this.resetPasswordForm.controls.verificationCode.valid) {
      this.userService.verifyVerificationCode(this.resetPasswordForm.value.email, this.resetPasswordForm.value.verificationCode).subscribe(() => {
        this.stage = ForgotPasswordStage.PASSWORD;
      });
    } else {
      this.resetPasswordForm.controls.verificationCode.markAsTouched();
    }
  }

  // Reset Forgotten Password
  resetForgottenPassword(): void {
    if (this.resetPasswordForm.controls.password.valid && this.resetPasswordForm.controls.confirmPassword.valid) {
      const data: any = {
        email: this.resetPasswordForm.value.email,
        verificationCode: this.resetPasswordForm.value.verificationCode,
        password: this.resetPasswordForm.value.password,
        confirmPassword: this.resetPasswordForm.value.confirmPassword
      };
      this.userService.resetForgottenPassword(data).subscribe(() => {
        this.alertService.showSuccessAlert('Password Reset');
        this.router.navigateByUrl('/login', { replaceUrl: true });
      });
    } else {
      this.resetPasswordForm.controls.password.markAsTouched();
      this.resetPasswordForm.controls.confirmPassword.markAsTouched();
    }
  }

  // Reset Password Form Accessors
  get email() { return this.resetPasswordForm.controls.email; }
  get verificationCode() { return this.resetPasswordForm.controls.verificationCode; }
  get password() { return this.resetPasswordForm.controls.password; }
  get confirmPassword() { return this.resetPasswordForm.controls.confirmPassword; }
}
