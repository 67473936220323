<div class="content-container">
  <nav class="navbar navbar-white bg-white sticky-top">
    <div class="d-grid gap-2 d-flex navbar-brand">
      <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>
      <h2 class="mobileHeading">Inventory</h2>
    </div>
    <div class="row button-container">
      <div class="col-md-auto col-xs-12">
        <input type="search" class="form-control" placeholder="Search Inventory" [value]="storedSearchValue" (keyup)="tableControls.search($event.target.value);" (search)="tableControls.search($event.target.value);">
      </div>
      <div class="col-md-auto" *ngIf="!isMobile">
        <button type="button" class="btn btn-primary mt-md-0 mt-1" (click)="resetItemForm(); openModal(INVENTORY_ITEM_MODAL);">
          <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
          <span>New Item</span>
        </button>
      </div>
      <div class="col-md-auto" *ngIf="!isMobile">
        <div ngbDropdown class="d-inline-block" display="dynamic" placement="bottom-right">
          <button id="INVENTORY_DROPDOWN" type="button" class="btn btn-primary mt-md-0 mt-1" ngbDropdownToggle>Options</button>
          <div ngbDropdownMenu aria-labelledby="INVENTORY_DROPDOWN">
            <button ngbDropdownItem (click)="downloadInventory();">
              <fa-icon class="button-icon-left" [icon]="faFileDownload"></fa-icon>
              <span>Export</span>
            </button>
            <button ngbDropdownItem (click)="openModal(IMPORT_INVENTORY_NOTICE_MODAL);">
              <fa-icon class="button-icon-left" [icon]="faFileUpload"></fa-icon>
              <span>Import</span>
            </button>
          </div>
        </div>
      </div>
      <button type="button" *ngIf="isMobile" class="btn btn-primary mt-md-0 mt-1" (click)="resetItemForm(); openModal(INVENTORY_ITEM_MODAL);">
        <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
        <span>New Item</span>
      </button>
      <div ngbDropdown *ngIf="isMobile" class="d-inline-block" display="dynamic" placement="bottom-right">
        <button id="INVENTORY_DROPDOWN" type="button" class="btn btn-primary mt-md-0 mt-1" ngbDropdownToggle>Options</button>
        <div ngbDropdownMenu aria-labelledby="INVENTORY_DROPDOWN">
          <button ngbDropdownItem (click)="downloadInventory();">
            <fa-icon class="button-icon-left" [icon]="faFileDownload"></fa-icon>
            <span>Export</span>
          </button>
          <button ngbDropdownItem (click)="openModal(IMPORT_INVENTORY_NOTICE_MODAL);">
            <fa-icon class="button-icon-left" [icon]="faFileUpload"></fa-icon>
            <span>Import</span>
          </button>
        </div>
      </div>
      <div class="sort" *ngIf="isMobile"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>
      <div class="filter" *ngIf="isMobile"><ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container></div>
      <input id="FILE_INPUT" type="file" style="display: none;" accept="text/csv" (change)="fileInputChanged($event.target.files);">
    </div>
  </nav>
  <div class="table-responsive mt-3">
    <table *ngIf="!isMobile" style="height: 100%;">
      <thead>
        <tr>
          <!-- SKU -->
          <th (click)="tableControls.sort('item_sku');">
            SKU
            <fa-icon *ngIf="tableControls.isSortedBy('item_sku')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </th>
          <!-- Name -->
          <th (click)="tableControls.sort('item_name');">
            Name
            <fa-icon *ngIf="tableControls.isSortedBy('item_name')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </th>
          <!-- Category -->
          <th>
            <div class="d-flex" style="position: relative;">
              <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #itemCategory="ngbPopover" (click)="showColumnFilterPopover(itemCategory, 'item_category');">
                <span *ngIf="tableControls.hasActiveFilter('item_category')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('item_category')}}</span>
                <fa-icon [icon]="faFilter"></fa-icon>
              </div>
              <div class="w-100 px-3" (click)="tableControls.sort('item_category');">
                Category
              </div>
              <fa-icon *ngIf="tableControls.isSortedBy('item_category')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
            </div>
          </th>
          <!-- Storage Location -->
          <th>
            <div class="d-flex" style="position: relative;">
              <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #itemStorageLocation="ngbPopover" (click)="showColumnFilterPopover(itemStorageLocation, 'item_storage_location');">
                <span *ngIf="tableControls.hasActiveFilter('item_storage_location')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('item_storage_location')}}</span>
                <fa-icon [icon]="faFilter"></fa-icon>
              </div>
              <div class="w-100 px-3" (click)="tableControls.sort('item_storage_location');">
                Stor. Loc.
              </div>
              <fa-icon *ngIf="tableControls.isSortedBy('item_storage_location')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
            </div>
          </th>
          <th>Cost</th>
          <th>Single Price</th>
          <th>Multi Price</th>
          <th>Cur. Stock Lvl.</th>
          <th>Adj. Stock Lvl.</th>
          <th>
            <div class="d-flex" style="position: relative;">
              <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #itemStock="ngbPopover" (click)="showColumnFilterPopover(itemStock, 'item_stock');">
                <span *ngIf="tableControls.hasActiveFilter('item_stock')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('item_stock')}}</span>
                <fa-icon [icon]="faFilter"></fa-icon>
              </div>
              <div class="w-100 px-3" (click)="tableControls.sort('item_stock');">
                Stock Item
              </div>
              <fa-icon *ngIf="tableControls.isSortedBy('item_stock')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items" (click)="itemSelected(item);">
          <td data-label="SKU">{{item.sku}}</td>
          <td data-label="Name">{{item.name}}</td>
          <td data-label="Category">{{item.category}}</td>
          <td data-label="Stor. Loc.">{{item.storageLocation}}</td>
          <td data-label="Cost">{{item.cost | currency}}</td>
          <td data-label="Single Price">{{item.price | currency}}</td>
          <td *ngIf="item.multiPricing" data-label="Multi Price">
            <span *ngIf="multiAgreementTypeOptions && multiAgreementTypeOptions.A.isEnabled">{{multiAgreementTypeOptions.A.acronym}}: {{item.multiPricing.A | currency}}</span>
            <span *ngIf="multiAgreementTypeOptions && multiAgreementTypeOptions.B.isEnabled" class="ms-2">{{multiAgreementTypeOptions.B.acronym}}: {{item.multiPricing.B | currency}}</span>
            <span *ngIf="multiAgreementTypeOptions && multiAgreementTypeOptions.C.isEnabled" class="ms-2">{{multiAgreementTypeOptions.C.acronym}}: {{item.multiPricing.C | currency}}</span>
            <span *ngIf="multiAgreementTypeOptions && multiAgreementTypeOptions.D.isEnabled" class="ms-2">{{multiAgreementTypeOptions.D.acronym}}: {{item.multiPricing.D | currency}}</span>
            <span *ngIf="multiAgreementTypeOptions && multiAgreementTypeOptions.E.isEnabled" class="ms-2">{{multiAgreementTypeOptions.E.acronym}}: {{item.multiPricing.E | currency}}</span>
          </td>
          <td *ngIf="!item.multiPricing"  data-label="Multi Price">N/A</td>
          <td class="p-0" (click)="$event.stopPropagation();"  data-label="Cur. Stock Lvl.">
            <div class="d-flex stock-container" style="height: 100%;">
              <button type="button" class="subtract-btn" [ngbPopover]="popContentStockLevel" [popoverTitle]="popTitleStockLevel" triggers="manual" autoClose="outside" #itemStockLevelRemove="ngbPopover" (click)="showItemStockLevelPopover(itemStockLevelRemove, item.id, 'Remove Stock', 'REMOVE');">
                <fa-icon [icon]="faMinusCircle"></fa-icon>
              </button>
              <div class="d-flex m-auto stock-value" (click)="itemSelected(item);">
                <div>{{item.stockCurrentLevel}}</div>
                <fa-icon *ngIf="item.stockCurrentLevel < item.stockWarningLevel" [icon]="faTriangleExclamation" class="ms-2" style="color: var(--bs-red);" ngbPopover="Current Stock Level is less than {{item.stockWarningLevel}}." triggers="hover"></fa-icon>
              </div>
              <button type="button" class="add-btn" [ngbPopover]="popContentStockLevel" [popoverTitle]="popTitleStockLevel" triggers="manual" autoClose="outside" #itemStockLevelAdd="ngbPopover" (click)="showItemStockLevelPopover(itemStockLevelAdd, item.id, 'Add Stock', 'ADD');">
                <fa-icon [icon]="faPlusCircle"></fa-icon>
              </button>
            </div>
          </td>
          <td  data-label="Adj. Stock Lvl.">{{item.stockAdjustedLevel}}</td>
          <td  data-label="Stock Item">
            <fa-icon [style.color]="(item.stock) ? 'green' : 'red'" [icon]="(item.stock) ? faCheck : faTimes" size="xl"></fa-icon>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="scrollable" *ngIf="isMobile">
      <ul class="list-group">
          <li *ngFor="let item of items" class="list-group-item" style="cursor: pointer;" >
              <div class="list-group-container">
                  <div>
                      <div class="item-header mb-2" (click)="itemSelected(item);">
                        <div>
                          <strong>{{item.name}}</strong>
                        </div>
                        <div>
                          <strong style="font-size: 1rem; font-weight: normal;">Stock Item: </strong> <fa-icon [style.color]="(item.stock) ? 'green' : 'red'" [icon]="(item.stock) ? faCheck : faTimes" size="xl"></fa-icon>
                        </div>
                      </div>
                      <div (click)="itemSelected(item);"><strong>SKU: </strong>{{item.sku}}</div>
                      <div (click)="itemSelected(item);"><strong>Category: </strong>{{item.category}}</div>
                      <div (click)="itemSelected(item);"><strong>Stor. Loc.: </strong>{{item.storageLocation}}</div>
                      <div (click)="itemSelected(item);"><strong>Cost: </strong>{{item.cost | currency}}</div>
                      <div (click)="itemSelected(item);"><strong>Single Price: </strong>{{item.price | currency}}</div>
                      <div *ngIf="item.multiPricing" (click)="itemSelected(item);"><strong>Multi Price: </strong>
                        <br/>
                        <span *ngIf="multiAgreementTypeOptions && multiAgreementTypeOptions.A.isEnabled">{{multiAgreementTypeOptions.A.acronym}}: {{item.multiPricing.A | currency}}</span>
                        <span *ngIf="multiAgreementTypeOptions && multiAgreementTypeOptions.B.isEnabled" class="ms-2">{{multiAgreementTypeOptions.B.acronym}}: {{item.multiPricing.B | currency}}</span>
                        <span *ngIf="multiAgreementTypeOptions && multiAgreementTypeOptions.C.isEnabled" class="ms-2">{{multiAgreementTypeOptions.C.acronym}}: {{item.multiPricing.C | currency}}</span>
                        <span *ngIf="multiAgreementTypeOptions && multiAgreementTypeOptions.D.isEnabled" class="ms-2">{{multiAgreementTypeOptions.D.acronym}}: {{item.multiPricing.D | currency}}</span>
                        <span *ngIf="multiAgreementTypeOptions && multiAgreementTypeOptions.E.isEnabled" class="ms-2">{{multiAgreementTypeOptions.E.acronym}}: {{item.multiPricing.E | currency}}</span>
                      </div>
                      <div *ngIf="!item.multiPricing" (click)="itemSelected(item);"><strong>Multi Price: </strong>N/A</div>
                      <div class="d-flex stock-container pt-1 pb-1">
                        <strong>Cur. Stock Lvl.:</strong>
                        <button type="button" class="subtract-btn" [ngbPopover]="popContentStockLevel" [popoverTitle]="popTitleStockLevel" triggers="manual" autoClose="outside" #itemStockLevelRemove="ngbPopover" (click)="showItemStockLevelPopover(itemStockLevelRemove, item.id, 'Remove Stock', 'REMOVE');">
                          <fa-icon [icon]="faMinusCircle"></fa-icon>
                        </button>
                        <div class="d-flex m-auto stock-value" (click)="itemSelected(item);">
                          <div>{{item.stockCurrentLevel}}</div>
                          <fa-icon *ngIf="item.stockCurrentLevel < item.stockWarningLevel" [icon]="faTriangleExclamation" class="ms-2" style="color: var(--bs-red);" ngbPopover="Current Stock Level is less than {{item.stockWarningLevel}}." triggers="hover"></fa-icon>
                        </div>
                        <button type="button" class="add-btn" [ngbPopover]="popContentStockLevel" [popoverTitle]="popTitleStockLevel" triggers="manual" autoClose="outside" #itemStockLevelAdd="ngbPopover" (click)="showItemStockLevelPopover(itemStockLevelAdd, item.id, 'Add Stock', 'ADD');">
                          <fa-icon [icon]="faPlusCircle"></fa-icon>
                        </button>
                      </div>
                      <div (click)="itemSelected(item);"><strong>Adj. Stock Lvl.: </strong>{{item.stockAdjustedLevel}} </div>
                  </div>
              </div>
          </li>
          <li *ngIf="items.length == 0" class="list-group-item">No Items</li>
      </ul>
    </div>
  </div>
  <div class="row table-controls">
    <div *ngIf="tableControls.isNotDefault() && !isMobile" class="col-auto mt-3 me-2 me-md-0">
      <button type="button" class="btn btn-outline-danger" (click)="tableControls.resetTableConfiguration();">
        <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
        <span>Reset</span>
      </button>
    </div>
    <div class="col mt-3">
      <div ngbDropdown placement="top-left" class="d-inline-block">
        <button class="btn btn-outline-primary" ngbDropdownToggle><span *ngIf="!isMobile">Page Size: </span> {{tableControls.getLimit()}}</button>
        <div ngbDropdownMenu class="page-size-dropdown" *ngIf="!isMobile">
          <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
          <button ngbDropdownItem (click)="tableControls.setLimit(50);">50</button>
          <button ngbDropdownItem (click)="tableControls.setLimit(100);">100</button>
        </div>
        <div ngbDropdownMenu class="page-size-dropdown" *ngIf="isMobile">
          <button ngbDropdownItem (click)="tableControls.setLimit(5);">5</button>
          <button ngbDropdownItem (click)="tableControls.setLimit(10);">10</button>
          <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
        </div>
      </div>
    </div>
    <div class="col my-3">
      <ngb-pagination class="d-flex justify-content-end" *ngIf="itemsCount > 0" [page]="tableControls.getCurrentPage()" [pageSize]="tableControls.getLimit()" [collectionSize]="itemsCount" [boundaryLinks]="true" [maxSize]="(isMobile) ? 1 : 10" [ellipses]="true" [rotate]="true" (pageChange)="tableControls.setCurrentPage($event);"></ngb-pagination>
    </div>
  </div>
</div>

<!-- Filter Popover -->
<ng-template #popTitleFilter>Filter</ng-template>
<ng-template let-column="column" #popContentFilter>
  <div *ngFor="let element of this.columnFilterValues.get(column)" class="form-check">
    <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter(column, element.value);" [checked]="tableControls.filterExists(column, element.value)">
    <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
  </div>
</ng-template>

<!-- Stock Level Popover -->
<ng-template let-title="title" #popTitleStockLevel>{{title}}</ng-template>
<ng-template let-itemId="itemId" let-type="type" #popContentStockLevel>
  <div class="input-group">
    <input id="ADJUST_STOCK_CURRENT_LEVEL_INPUT" type="number" class="form-control" placeholder="10">
    <button type="button" class="btn btn-outline-primary" (click)="adjustItemStockCurrentLevel(itemId, type);">
      <fa-icon [icon]="faCheck"></fa-icon>
    </button>
  </div>
</ng-template>

<!-- Inventory Item Modal -->
<ng-template #INVENTORY_ITEM_MODAL let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{(selectedItem) ? 'Edit' : 'New'}} Item</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="itemForm">
      <div class="row">
        <div class="col me-md-0 me-2">
          <div class="d-flex">
            <label for="NAME">Name</label>
            <span *ngIf="selectedItem" class="ms-auto" style="cursor: pointer;" ngbPopover="Updating item name will not affect existing feature items." triggers="click" placement="left">
              <fa-icon [icon]="faInfoCircle"></fa-icon>
            </span>
          </div>
          <div [class.mb-3]="!selectedItem" [class.mb-2]="selectedItem">
            <input id="NAME" type="text" class="form-control" formControlName="name" placeholder="Light Bulb" maxlength="150" required autocomplete="off" [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
              <div *ngIf="name.errors.required" class="input-validation-msg">Name is required.</div>
              <div *ngIf="name.errors.maxlength" class="input-validation-msg">Name cannot exceed 150 characters.</div>
            </div>
          </div>
        </div>
        <div class="col">
          <label for="SKU">SKU</label>
          <div class="mb-3">
            <input id="SKU" type="text" class="form-control" formControlName="sku" placeholder="LB00001" maxlength="15" required [class.is-invalid]="sku.invalid && (sku.dirty || sku.touched)">
            <div *ngIf="sku.invalid && (sku.dirty || sku.touched)">
              <div *ngIf="sku.errors.required" class="input-validation-msg">SKU is required.</div>
              <div *ngIf="sku.errors.maxlength" class="input-validation-msg">SKU cannot exceed 15 characters.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col me-md-0 me-2">
          <label for="SOURCE">Source</label>
          <div class="mb-3">
            <input *ngIf="sourceTypeaheadInput" id="SOURCE" type="text" class="form-control" formControlName="source" placeholder="Bulb Manufacturer" maxlength="150" [ngbTypeahead]="sourceTypeaheadInput" (focus)="sourceTypeaheadInputFocus$.next($any($event).target.value)" [class.is-invalid]="source.invalid && (source.dirty || source.touched)">
            <div *ngIf="source.invalid && (source.dirty || source.touched)">
              <div *ngIf="source.errors.maxlength" class="input-validation-msg">Source cannot exceed 150 characters.</div>
            </div>
          </div>
        </div>
        <div class="col">
          <label for="CATEGORY">Category</label>
          <div class="mb-3">
            <input *ngIf="categoryTypeaheadInput" id="CATEGORY" type="text" class="form-control" formControlName="category" placeholder="Accessories" maxlength="150" [ngbTypeahead]="categoryTypeaheadInput" (focus)="categoryTypeaheadInputFocus$.next($any($event).target.value)" [class.is-invalid]="category.invalid && (category.dirty || sku.category)">
            <div *ngIf="category.invalid && (category.dirty || category.touched)">
              <div *ngIf="category.errors.maxlength" class="input-validation-msg">Category cannot exceed 150 characters.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col me-md-0 me-2">
          <label for="LINK">Link</label>
          <div class="mb-3">
            <input id="LINK" type="text" class="form-control" formControlName="link" placeholder="www.example.com" autocapitalize="off" maxlength="65535" [class.is-invalid]="link.invalid && (link.dirty || link.touched)">
          </div>
        </div>
        <div class="col">
          <label for="STORAGE_LOCATION">Storage Location</label>
          <div class="mb-3">
            <input *ngIf="storageLocationTypeaheadInput" id="STORAGE_LOCATION" type="text" class="form-control" formControlName="storageLocation" placeholder="Shelf 1A" autocapitalize="off" maxlength="150" [ngbTypeahead]="storageLocationTypeaheadInput" (focus)="storageLocationTypeaheadInputFocus$.next($any($event).target.value)" [class.is-invalid]="storageLocation.invalid && (storageLocation.dirty || storageLocation.touched)">
            <div *ngIf="storageLocation.invalid && (storageLocation.dirty || storageLocation.touched)">
              <div *ngIf="storageLocation.errors.maxlength" class="input-validation-msg">Storage Location cannot exceed 150 characters.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col me-md-0 me-2">
          <label for="COST">Cost</label>
          <div class="input-group mb-3">
            <div class="input-group-text">$</div>
            <input id="COST" type="number" class="form-control" placeholder="5.00" formControlName="cost" min="0" [class.is-invalid]="cost.invalid && (cost.dirty || cost.touched)">
            <div *ngIf="cost.invalid && (cost.dirty || cost.touched)">
              <div *ngIf="cost.errors.min" class="input-validation-msg">Cost cannot be less than 0.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col me-md-0 me-2">
          <label for="PRICE_SINGLE">Price (Single)</label>
          <div class="input-group mb-3">
            <div class="input-group-text">$</div>
            <input id="PRICE_SINGLE" type="number" class="form-control" placeholder="15.00" formControlName="price" min="0" [class.is-invalid]="price.invalid && (price.dirty || price.touched)">
            <div *ngIf="price.invalid && (price.dirty || price.touched)">
              <div *ngIf="price.errors.min" class="input-validation-msg">Price cannot be less than 0.</div>
            </div>
          </div>
        </div>
        <div class="col">
          <label for="PRICE_MULTI">Price (Multi)</label>
          <div *ngIf="!areAllMultiOptionsDisabled()" class="input-group">
            <div ngbDropdown class="d-inline-block">
              <button type="button" class="btn btn-outline-secondary" ngbDropdownToggle style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
                {{multiAgreementTypeOptions[selectedMultiAgreementTypeOptionKey].acronym}}
              </button>
              <div ngbDropdownMenu>
                <ng-container *ngFor="let key of multiAgreementTypeOptionKeys">
                  <button *ngIf="multiOptionIsEnabled(key);" ngbDropdownItem (click)="selectMultiAgreementTypeOption(key);">
                    <span>{{multiAgreementTypeOptions[key].acronym}}</span>
                    <span *ngIf="selectedMultiAgreementTypePricing && selectedMultiAgreementTypePricing[key]" class="ms-2">(${{selectedMultiAgreementTypePricing[key]}})</span>
                  </button>
                </ng-container>
              </div>
            </div>
            <input id="PRICE_MULTI" type="number" class="form-control" placeholder="15.00" formControlName="multiPrice" min="0" [class.is-invalid]="multiPrice.invalid && (multiPrice.dirty || multiPrice.touched)" (change)="optionPricingChanged($event.target.value);">
          </div>
          <div *ngIf="!areAllMultiOptionsDisabled() && multiPrice.invalid && (multiPrice.dirty || multiPrice.touched)">
            <div *ngIf="multiPrice.errors.min" class="input-validation-msg">Price cannot be less than 0.</div>
          </div>
          <div *ngIf="areAllMultiOptionsDisabled()" class="mt-2">Enable in Settings</div>
        </div>
      </div>
      <div class="row">
        <div class="col me-md-0 me-2">
          <label for="STOCK_CURRENT_LEVEL">Stock Cur. Lvl.</label>
          <div class="mb-3">
            <input id="STOCK_CURRENT_LEVEL" type="number" class="form-control" placeholder="200" formControlName="stockCurrentLevel" min="0" [class.is-invalid]="stockCurrentLevel.invalid && (stockCurrentLevel.dirty || stockCurrentLevel.touched)">
            <div *ngIf="stockCurrentLevel.invalid && (stockCurrentLevel.dirty || stockCurrentLevel.touched)">
              <div *ngIf="stockCurrentLevel.errors.min" class="input-validation-msg">Stock Current Level cannot be less than 0.</div>
            </div>
          </div>
        </div>
        <div class="col">
          <label for="STOCK_WARNING_LEVEL">Stock Warn. Lvl.</label>
          <div class="mb-3">
            <input id="STOCK_WARNING_LEVEL" type="number" class="form-control" placeholder="100" formControlName="stockWarningLevel" min="0" [class.is-invalid]="stockWarningLevel.invalid && (stockWarningLevel.dirty || stockWarningLevel.touched)">
            <div *ngIf="stockWarningLevel.invalid && (stockWarningLevel.dirty || stockWarningLevel.touched)">
              <div *ngIf="stockWarningLevel.errors.min" class="input-validation-msg">Stock Warning Level cannot be less than 0.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-check mb-2">
        <input id="STOCK" type="checkbox" class="form-check-input" formControlName="stock">
        <label for="STOCK" class="form-check-label">Stock Item</label>
      </div>
      <div class="row">
        <div class="col d-flex flex-column">
          <label>Image</label>
          <img *ngIf="(selectedItem && selectedItem.imageExists) || itemImageReadyForUpload" id="ITEM_IMAGE" class="img-fluid m-auto mb-2" width="100%" alt="Item Image" [src]="itemImageUrl">
          <button *ngIf="selectedItem && selectedItem.imageExists && !itemImageReadyForUpload" type="button" class="btn btn-danger" (click)="deleteItemImage();">Remove</button>
          <button *ngIf="itemImageReadyForUpload" type="button" class="btn btn-secondary" (click)="cancelImageUpload();">Cancel</button>
          <button type="button" class="btn btn-primary mt-2" (click)="browseImageFiles();">Browse</button>
          <input id="IMAGE_FILE_INPUT" type="file" style="display: none;" (change)="itemImageInputChanged($event);">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <ngb-progressbar *ngIf="uploadLoaded > 0" type="primary" [value]="uploadLoaded" [max]="uploadTotal" height="20px" [striped]="true" [showValue]="true" style="width: 50%;"></ngb-progressbar>
    <button type="button" class="btn btn-primary ms-auto" (click)="saveItem();">Save</button>
    <button *ngIf="selectedItem && !selectedItem.inUse" type="button" class="btn btn-danger" (click)="openDeleteItemConfirmationModal();">Delete</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
  </div>
</ng-template>

<!-- Import Inventory Notice Modal -->
<ng-template #IMPORT_INVENTORY_NOTICE_MODAL let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Import Inventory</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
  </div>
  <div class="modal-body">
    <p class="mb-0">Please review the documentation on <a href="https://support.tinselcrm.com/knowledge-base/importing-inventory-items/" target="_blank">Importing Inventory Items</a> before proceeding to ensure that your data is imported successfully.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss(); browseFiles();">Continue</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
  </div>
</ng-template>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block" [autoClose]="true" placement="bottom-right">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
  <div ngbDropdown class="d-inline-block filter-container" autoClose="outside" placement="bottom-right">
      <span *ngIf="activeFilterCount > 0" class="custom-badge-lg">{{activeFilterCount}}</span>
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Filter">
          <fa-icon [icon]="faFilter"></fa-icon>
      </button>
      <div ngbDropdownMenu>
          <!-- Filter Options -->
          <div *ngFor="let filter of filterColumns">
            <button ngbDropdownItem class="button-filter" (click)="getDistinctColumnValues(filter.value); filters.toggle();">{{filter.label}}</button>
            <div class="px-4 py-1" #filters="ngbCollapse" [ngbCollapse]="filter.value === selectedFilterColumn ? false : true">
                <div class="d-flex flex-column">
                    <div *ngFor="let element of columnFilterValues.get(filter.value);" class="form-check form-check-inline">
                        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter(filter.value, element.value);" [checked]="filterExists(filter.value, element.value)">
                        <label [for]="element.randomId" class="form-check-label" style="white-space: nowrap;">{{element.name}}</label>
                    </div>
                </div>
            </div>
          </div>

          <!-- Divider -->
          <div class="dropdown-divider"></div>
          <!-- Reset -->
          <button class="reset-button" ngbDropdownItem (click)="resetActiveFilters(); selectedFilterColumn = ''">Reset</button>
      </div>
  </div>
</ng-template>
