<div class="table-responsive mt-2">
    <table *ngIf="!isMobile">
        <thead>
            <tr>
                <th>Vault ID</th>
                <th>CC Number</th>
                <th>CC Exp</th>
                <th>CC Bin</th>
                <th>CC Type</th>
                <th>Created</th>
                <th>Updated</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let vault of vaults">
                <td data-label="Vault ID">{{vault.id}}</td>
                <td data-label="CC Number">{{vault.ccNumber}}</td>
                <td data-label="CC Exp">{{vault.ccExp}}</td>
                <td data-label="CC Bin">{{vault.ccBin}}</td>
                <td data-label="CC Type">{{vault.ccType}}</td>
                <td data-label="Created">{{vault.created | formatDateTime}}</td>
                <td data-label="Updated">{{vault.updated | formatDateTime}}</td>
            </tr>
        </tbody>
    </table>

    <div class="scrollable" *ngIf="isMobile">
      <ul class="list-group">
          <li *ngFor="let vault of vaults" class="list-group-item">
              <div class="d-flex list-group-container">
                  <div>
                      <strong>{{vault.id}}</strong>
                      <div><strong>CC Number: </strong>{{vault.ccNumber}}</div>
                      <div><strong>CC Exp: </strong>{{vault.ccExp}}</div>
                      <div><strong>CC Bin: </strong>{{vault.ccBin}}</div>
                      <div><strong>CC Type: </strong>{{vault.ccType}}</div>
                      <small class="text-muted">Created: {{vault.created | formatDateTime}}</small>
                      <br/>
                      <small class="text-muted">Updated: {{vault.updated | formatDateTime}}</small>
                  </div>
              </div>
          </li>
          <li *ngIf="clients.length == 0" class="list-group-item">No Card Vault</li>
      </ul>
    </div>
</div>
