<div class="card">
    <div class="card-header card-header-calender">
      <div class="col"><h5>Calendar</h5></div>
      <div class="col" style="display: flex; text-align: right;">
        <button type="button" class="btn btn-primary ms-auto" (click)="openScheduleItemModal();" ngbTooltip="New Custom Event">
          <fa-icon [icon]="faPlus" class="button-icon-left"></fa-icon>
          <span>{{isMobile ? 'New' : 'New Custom Event'}}</span>
        </button>
        <button type="button" class="btn btn-primary ms-2" [ngbPopover]="exportSchedulePopover" popoverTitle="Export Schedule" triggers="click" autoClose="outside" placement="left">
            <fa-icon class="button-icon-left" [icon]="faFileDownload"></fa-icon>
            <span>Export</span>
        </button>
        <button type="button" class="btn btn-primary ms-2" *ngIf="!isMobile" (click)="toggleMap();">
            <fa-icon class="button-icon-left" [icon]="faRoad"></fa-icon>
            <span>Map</span>
        </button>
      </div>
    </div>
    <div class="card-body">
        <div class="row text-center">
            <div class="col-md-4 mt-2">
                <div class="btn-group">
                    <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChanged();">Previous</div>
                    <div class="btn btn-outline-primary" mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="viewDateChanged();">Today</div>
                    <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="viewDateChanged();">Next</div>
                </div>
            </div>
            <div class="col-md-4 mt-2">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
            </div>
            <div class="col-md-4 mt-2">
                <div class="row">
                    <div class="col-2">
                        <ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container>
                    </div>
                    <div class="btn-group ms-2 col-2">
                        <div class="btn btn-primary" (click)="setView(CalendarView.Month);" [class.active]="view === CalendarView.Month">Month</div>
                        <div class="btn btn-primary" (click)="setView(CalendarView.Week);" [class.active]="view === CalendarView.Week">Week</div>
                        <div class="btn btn-primary" (click)="setView(CalendarView.Day);" [class.active]="view === CalendarView.Day">Day</div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [activeDayIsOpen]="activeDayIsOpen" [refresh]="refresh" (beforeViewRender)="beforeViewRender($event);" (dayClicked)="selectDay($event.day);" [cellTemplate]="CUSTOM_CELL_TEMPLATE" [openDayEventsTemplate]="CUSTOM_OPEN_DAY_TEMPLATE"></mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [eventTemplate]="CUSTOM_WEEK_TEMPLATE" [refresh]="refresh" (beforeViewRender)="beforeViewRender($event);"></mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [eventTemplate]="CUSTOM_DAY_TEMPLATE" [refresh]="refresh" (beforeViewRender)="beforeViewRender($event);"></mwl-calendar-day-view>
        </div>
    </div>
</div>

<!-- Custom Cell Template -->
<ng-template #CUSTOM_CELL_TEMPLATE let-day="day" let-locale="locale">
    <div class="cal-cell-top" style="min-height: unset;">
        <span *ngIf="day.badgeTotal > 0" class="cal-day-badge">{{day.badgeTotal}}</span>
        <span *ngIf="routingTypeExists(day.date);" class="cal-day-badge ms-1" ngbPopover="Routed by {{getRoutingType(day.date)}}" triggers="mouseenter:mouseleave">
            <fa-icon [icon]="faRoute" size="sm"></fa-icon>
        </span>
        <span class="cal-day-number">{{day.date | calendarDate:'monthViewDayNumber':locale}}</span>
    </div>
    <div style="max-height: 116px; overflow-x: hidden; overflow-y: auto;">
        <div class="custom-cell-row px-1" *ngFor="let event of day.events" [ngStyle]="setBgColor(event.meta.scheduleItem)">
            <div class="d-flex" (click)="$event.stopPropagation(); openScheduleItemModal(event.meta.scheduleItem);" [ngbPopover]="eventInformationPopover" triggers="manual" #eventPopover="ngbPopover" (mouseenter)="toggleEventPopover($event, eventPopover, event.meta.scheduleItem);" (mouseleave)="toggleEventPopover($event, eventPopover, event.meta.scheduleItem);">
                <fa-icon [icon]="getScheduleItemTypeIcon(event.meta.scheduleItem.type)" class="my-auto" [size]="(isMobile) ? 'xs' : 'sm'"
                [style.color]="'#ffffff'"></fa-icon>
                <div class="d-flex flex-column ms-1 my-auto" style="width: calc(100% - 16px);">
                    <small style="text-overflow: ellipsis; overflow-x: hidden; white-space: nowrap; font-weight: 600;">
                        <span *ngIf="!event.meta.scheduleItem.clientId">{{event.title}}</span>
                        <span *ngIf="event.meta.scheduleItem.clientId">{{event.meta.scheduleItem.clientName}}</span>
                    </small>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Custom Open Day Template -->
<ng-template #CUSTOM_OPEN_DAY_TEMPLATE let-isOpen="isOpen" let-events="events" let-date="date">
    <div *ngIf="isOpen" class="p-1" style="background-color: #EDEDED;">
        <div class="d-flex flex-column">
            <p class="mb-0 fs-3" style="text-decoration: underline; font-weight: 600; line-height: 1.25;">{{viewDate | formatDateTime: true}}</p>
            <div *ngIf="routingTypeExists(viewDate);" class="d-flex">
                <fa-icon [icon]="faRoute"></fa-icon>
                <p class="mb-0 ms-2">Routed by {{getRoutingType(viewDate)}}</p>
            </div>
        </div>
        <div *ngIf="events.length > 1" class="d-flex my-1">
            <div class="d-flex flex-column">
                <div class="btn-group">
                    <div ngbDropdown class="btn-group" role="group" placement="bottom-end">
                        <button ngbDropdownToggle type="button" class="btn btn-sm btn-primary">Optimize Route By</button>
                        <div ngbDropdownMenu class="dropdown-menu">
                            <button ngbDropdownItem type="button" class="btn btn-sm btn-primary" (click)="optimizeRoute(events, 'DISTANCE');">Shortest Distance</button>
                            <button ngbDropdownItem type="button" class="btn btn-sm btn-primary" (click)="optimizeRoute(events, 'DURATION');">Fastest Time</button>
                        </div>
                    </div>
                    <button type="button" class="btn btn-sm btn-primary" [ngbPopover]="routingUsagePopover" autoClose="outside">
                        <fa-icon [icon]="faChartSimple"></fa-icon>
                    </button>
                    <button type="button" class="btn btn-sm btn-primary" [ngbPopover]="routeOptimizationInformation" autoClose="outside">
                        <fa-icon [icon]="faCircleQuestion"></fa-icon>
                    </button>
                </div>
            </div>
            <div>
                <button type="button" class="btn btn-sm btn-primary ms-2" (click)="routeByTime();" [disabled]="canRouteByTime(events)">Sort By Time</button>
            </div>
        </div>
        <div *ngFor="let event of events">
            <div class="d-flex">
                <div *ngIf="events.length > 1">
                    <button type="button" class="btn p-0" style="border: none;" (click)="swapPositions(event.meta.scheduleItem, 'UP')" [disabled]="event.meta.scheduleItem.position === 1">
                        <fa-icon [icon]="faSquareCaretUp" style="color: var(--bs-primary);"></fa-icon>
                    </button>
                    <button type="button" class="btn ms-1 p-0" style="border: none;" (click)="swapPositions(event.meta.scheduleItem, 'DOWN')" [disabled]="event.meta.scheduleItem.position === events.length">
                        <fa-icon [icon]="faSquareCaretDown" style="color: var(--bs-primary);"></fa-icon>
                    </button>
                </div>
                <fa-icon [icon]="getScheduleItemTypeIcon(event.meta.scheduleItem.type, event)" class="my-auto" [class.ms-1]="events.length > 1"
                    [style.color]="event.meta.scheduleItem && event.meta.scheduleItem.crews && event.meta.scheduleItem.crews.length > 0 ? event.meta.scheduleItem.crews[0].crewColor : '#000000'"></fa-icon>
                <div class="d-flex ms-1 my-auto open-day-event-content align-items-center" style="cursor: pointer;" (click)="openScheduleItemModal(event.meta.scheduleItem);">
                    <small>{{event.title}}</small>
                    <small class="ms-1">
                        <strong *ngIf="event.meta.scheduleItem.clientId">{{event.meta.scheduleItem.clientName}}</strong>
                    </small>
                    <small class="ms-2" *ngIf="event.meta.scheduleItem.crews && event.meta.scheduleItem.crews.length > 0">
                        <span>
                            (<span *ngFor="let crew of event.meta.scheduleItem.crews; let i = index">
                                {{ crew.crewName }}{{i != event.meta.scheduleItem.crews.length - 1 ? ', ': ""}}
                            </span>)
                        </span>

                        <!-- <ul class="my-0">
                            <li *ngFor="let crew of event.meta.scheduleItem.crews">

                            </li>
                        </ul> -->
                    </small>
                </div>
                <div *ngIf="!event.meta.scheduleItem.isAllDay" class="ms-2" style="border-left: 1px solid black;"></div>
                <small *ngIf="!event.meta.scheduleItem.isAllDay" class="ms-2 my-auto">
                    <span>{{formatTime(event.meta.scheduleItem.date)}}</span>
                    <span *ngIf="event.meta.scheduleItem.endDate"> to {{formatTime(event.meta.scheduleItem.endDate)}}</span>
                </small>
            </div>
        </div>
    </div>
</ng-template>

<!-- Custom Week Template -->
<ng-template #CUSTOM_WEEK_TEMPLATE let-weekEvent="weekEvent">
    <div class="cal-week-day-event d-flex"
    [ngStyle]="setBgColor(weekEvent.event.meta.scheduleItem)"
    (click)="openScheduleItemModal(weekEvent.event.meta.scheduleItem);" [ngbPopover]="eventInformationPopover" triggers="manual" [autoClose]="false" #eventPopover="ngbPopover" (mouseenter)="toggleEventPopover($event, eventPopover, weekEvent.event.meta.scheduleItem);" (mouseleave)="toggleEventPopover($event, eventPopover, weekEvent.event.meta.scheduleItem);">
        <div class="d-flex mx-2 my-auto" style="width: calc(100% - 16px); height: fit-content;">
            <fa-icon [icon]="getScheduleItemTypeIcon(weekEvent.event.meta.scheduleItem.type)" class="my-auto"></fa-icon>
            <div class="d-flex flex-column ms-1 my-auto" style="width: calc(100% - 20px);">
                <small style="text-overflow: ellipsis; overflow-x: hidden; white-space: nowrap; font-weight: 600;">
                    <span *ngIf="!weekEvent.event.meta.scheduleItem.clientId">{{weekEvent.event.title}}</span>
                    <span *ngIf="weekEvent.event.meta.scheduleItem.clientId">{{weekEvent.event.meta.scheduleItem.clientName}}</span>
                </small>
            </div>
        </div>
    </div>
</ng-template>

<!-- Custom Day Template -->
<ng-template #CUSTOM_DAY_TEMPLATE let-weekEvent="weekEvent">
    <div class="cal-week-day-event d-flex"
    [ngStyle]="setBgColor(weekEvent.event.meta.scheduleItem)"
    (click)="openScheduleItemModal(weekEvent.event.meta.scheduleItem);" [ngbPopover]="eventInformationPopover" triggers="manual" #eventPopover="ngbPopover" (mouseenter)="toggleEventPopover($event, eventPopover, weekEvent.event.meta.scheduleItem);" (mouseleave)="toggleEventPopover($event, eventPopover, weekEvent.event.meta.scheduleItem);">
        <div class="d-flex mx-2 my-auto" style="width: calc(100% - 16px); height: fit-content;">
            <fa-icon [icon]="getScheduleItemTypeIcon(weekEvent.event.meta.scheduleItem.type)" class="my-auto"></fa-icon>
            <div class="d-flex flex-column ms-1 my-auto" style="width: calc(100% - 20px);">
                <small style="text-overflow: ellipsis; overflow-x: hidden; white-space: nowrap; font-weight: 600;">
                    <span *ngIf="!weekEvent.event.meta.scheduleItem.clientId">{{weekEvent.event.title}}</span>
                    <span *ngIf="weekEvent.event.meta.scheduleItem.clientId">{{weekEvent.event.meta.scheduleItem.clientName}}</span>
                </small>
            </div>
        </div>
    </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
    <div ngbDropdown class="d-inline-block" autoClose="outside" placement="bottom-left">
        <span *ngIf="filters.length > 0" class="custom-badge-lg">{{filters.length}}</span>
        <button class="btn btn-outline-primary" ngbDropdownToggle ngbTooltip="Filter">
            <fa-icon [icon]="faFilter"></fa-icon>
        </button>
        <div ngbDropdownMenu>
            <button ngbDropdownItem class="d-flex" (click)="toggleFilter('INSTALL');">
                <div>Installations</div>
                <fa-icon *ngIf="filterIsActive('INSTALL')" [icon]="faCheck" class="ms-auto"></fa-icon>
            </button>
            <button ngbDropdownItem class="d-flex" (click)="toggleFilter('MAINTENANCE');">
                <div>Maintenance</div>
                <fa-icon *ngIf="filterIsActive('MAINTENANCE')" [icon]="faCheck" class="ms-auto"></fa-icon>
            </button>
            <button ngbDropdownItem class="d-flex" (click)="toggleFilter('REMOVE');">
                <div>Removals</div>
                <fa-icon *ngIf="filterIsActive('REMOVE')" [icon]="faCheck" class="ms-auto"></fa-icon>
            </button>
            <button ngbDropdownItem class="d-flex" (click)="toggleFilter('CUSTOM');">
                <div>Custom</div>
                <fa-icon *ngIf="filterIsActive('CUSTOM')" [icon]="faCheck" class="ms-auto"></fa-icon>
            </button>
            <div class="dropdown-divider"></div>
            <!-- Reset -->
            <button ngbDropdownItem (click)="resetFilters();">Reset</button>
        </div>
    </div>
</ng-template>

<!-- Export Schedule Popover -->
<ng-template #exportSchedulePopover>
    <input id="EXPORT_SCHEDULE_START_DATE" type="date" class="form-control">
    <input id="EXPORT_SCHEDULE_END_DATE" type="date" class="form-control mt-2">
    <button type="button" class="btn btn-sm btn-primary w-100 mt-2" (click)="downloadSchedule();">Export</button>
</ng-template>

<!-- Event Information Popover -->
<ng-template #eventInformationPopover let-scheduleItem="scheduleItem">
    <strong *ngIf="scheduleItem.clientId">{{scheduleItem.clientName}}</strong>
    <p *ngIf="scheduleItem.type != 'CUSTOM'" class="mb-0">{{scheduleItem.projectName}}</p>
    <p *ngIf="scheduleItem.type == 'CUSTOM'" class="mb-0">{{scheduleItem.name}}</p>
    <p *ngIf="scheduleItem.isAllDay" class="mb-0">All Day</p>
    <p *ngIf="!scheduleItem.isAllDay" class="mb-0">
        <span>{{formatTime(scheduleItem.date)}}</span>
        <span *ngIf="scheduleItem.endDate"> to {{formatTime(scheduleItem.endDate)}}</span>
    </p>
    <p *ngIf="scheduleItem.crews && scheduleItem.crews.length > 0" class="mb-0 font-italic">
    {{ getCrewsNames(scheduleItem.crews) }}
    </p>
</ng-template>

<!-- Routing Usage Popover -->
<ng-template #routingUsagePopover>
    <div class="d-flex flex-column text-center">
        <strong>Usage</strong>
        <p class="mb-0">{{routingUsage}} of {{routingLimit}} Routes</p>
    </div>
</ng-template>

<!-- Route Optimization Information -->
<ng-template #routeOptimizationInformation>
    <p><strong>Route Optimization</strong> The site locations will be processed by the system to determine the optimal path.</p>
    <p><strong>Shortest Distance</strong> The route will be calculated by determining the shortest distance between all locations.</p>
    <p><strong>Fastest Time</strong> The route will be calculated by determining the fastest time between all locations.</p>
    <div>
        <strong>Limitations</strong>
        <ul class="ps-4 mb-0">
            <li class="py-1">A maximum of 20 locations can be optimized per request.</li>
            <li class="py-1">There is a limit to the number of route calculations an organization can make per month.</li>
            <li class="py-1">All locations must fall within a 112 mi (180 km) diameter circle.</li>
            <li class="pt-1">Routes that deviate more than 6.2 mi (10 km) outside a circle around the locations will not be calculated.</li>
        </ul>
        <!-- <small class="text-muted">Due to the nature of route optimization, the number of route calculations grows exponentially with the number of locations. For example, optimizing 20 locations would result in 400 route calculations.</small> -->
    </div>
</ng-template>
