import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePickerControllerComponent } from './date-picker-controller/date-picker-controller.component';

export class TableControlService {

  // Properties
  private tableId: string;
  private shouldStore: boolean;
  private searchTerm: string;
  private searchSubject: Subject<string> = new Subject();
  private sortBy: string;
  private sortDirection: 'ASC' | 'DESC';
  private limit: number;
  private offset: number;
  private currentPage: number;
  private activeFilters: Map<string, string[] | string> = new Map<string, string[] | string>();
  datePickerController: DatePickerControllerComponent;
  refresh: EventEmitter<void> = new EventEmitter<void>();

  // Defaults
  private defaultSearchTerm: string;
  private defaultSortBy: string;
  private defaultSortDirection: 'ASC' | 'DESC';
  private defaultLimit: number;
  private defaultOffset: number;
  private defaultCurrentPage: number;
  private defaultFilters: Map<string, string[] | string>;

  constructor(tableId: string, shouldStore: boolean, sortBy: string, sortDirection: 'ASC' | 'DESC', defaultFilters: any[] = [], limit: number = 20, offset: number = 0) {
    this.tableId = tableId;
    this.shouldStore = shouldStore;
    this.defaultSearchTerm = null;
    this.defaultSortBy = sortBy;
    this.defaultSortDirection = sortDirection;
    this.defaultLimit = limit;
    this.defaultOffset = offset;
    this.defaultCurrentPage = 1;
    this.defaultFilters = new Map(defaultFilters);
    if (this.hasTableConfiguration()) {
      this.loadTableConfiguration();
    } else {
      this.searchTerm = null;
      this.sortBy = sortBy;
      this.sortDirection = sortDirection;
      this.limit = limit;
      this.offset = offset;
      this.currentPage = 1;
      this.activeFilters = new Map(defaultFilters);
      this.updateTableConfiguration();
    }
    this.searchSubject.pipe(debounceTime(250), distinctUntilChanged()).subscribe((searchTerm) => {
      this.searchTerm = (searchTerm.length > 0) ? searchTerm : null;
      this.refresh.emit();
    });
    this.refresh.subscribe(() => {
      this.updateTableConfiguration();
    });
  }

  // Get Params
  getParams(): any {
    let params = {
      searchTerm: this.searchTerm,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
      limit: this.limit,
      offset: this.offset
    };
    for (const [key, value] of this.activeFilters) {
      if (key.includes('range')) params[`filter:${key}`] = value;
      else params[`filter:${key}`] = JSON.stringify(value);
    }
    return params;
  }

  // Get URL Search Params
  getUrlSearchParams(): URLSearchParams {
    const searchParams = new URLSearchParams();
    searchParams.set('searchTerm', this.searchTerm);
    searchParams.set('sortBy', this.sortBy);
    searchParams.set('sortDirection', this.sortDirection);
    searchParams.set('limit', this.limit.toString());
    searchParams.set('offset', this.offset.toString());
    for (const [key, value] of this.activeFilters) {
      if (key.includes('range')) searchParams.set(`filter:${key}`, value as string);
      else searchParams.set(`filter:${key}`, JSON.stringify(value));
    }
    return searchParams;
  }

  /* ----- Search ----- */

  // Get Search Term
  getSearchTerm(): string {
    return this.searchTerm;
  }

  // Search
  search(searchTerm: string): void {
    this.searchSubject.next(searchTerm);
  }

  /* ----- Pagination ----- */

  // Get Limit
  getLimit(): number {
    return this.limit;
  }

  // Set Limit
  setLimit(limit: number): void {
    this.limit = limit;
    this.refresh.emit();
  }

  // Get Current Page
  getCurrentPage(): number {
    return this.currentPage;
  }

  // Set Current Page
  setCurrentPage(page: number): void {
    this.currentPage = page;
    this.offset = this.limit * (page - 1);
    this.refresh.emit();
  }

  /* ----- Sort ----- */

  // Sort
  sort(column: string, sortDirection?: any, isMobile = false): void {
    if(isMobile) {
      this.sortBy = column;
      this.sortDirection = sortDirection?.toUpperCase() || 'ASC';
      this.refresh.emit();
    } else {
      if (column != this.sortBy) this.sortDirection = 'ASC';
      if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
      this.sortBy = column;
      this.refresh.emit();
    }
  }

  // Get Sort By
  getSortBy(): string {
    return this.sortBy;
  }

  // Is Sorted By
  isSortedBy(column: string): boolean {
    return this.sortBy == column;
  }

  // Get Sort Direction
  getSortDirection(): string {
    return this.sortDirection;
  }

  // Is Sorted ASC
  isSortedASC(): boolean {
    return this.sortDirection == 'ASC';
  }

  isSortedDesc(): boolean {
    return this.sortDirection == 'DESC';
  }

  /* ----- Filters ----- */

  // Get Active Filters
  getActiveFilters(): Map<string, any> {
    return this.activeFilters;
  }

  // Get Column Active Filter Count
  getColumnActiveFilterCount(column: string): number {
    return (this.activeFilters.get(column) as any[]).length;
  }

  // Has Active Filter
  hasActiveFilter(column: string): boolean {
    return this.activeFilters.has(column);
  }

  // Set Filter
  setFilter(column: string, values: string[]): void {
    this.activeFilters.set(column, values);
  }

  // Add Range Filter
  addRangeFilter(column: string, start: any, end: any, isDefault: boolean = false): void {
    this.activeFilters.set(`range:${column}`, start + ":" + end);
    if (isDefault) this.defaultFilters.set(`range:${column}`, start + ":" + end);
    this.refresh.emit();
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        const tempArray = (this.activeFilters.get(column) as any[]).filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) this.activeFilters.delete(column);
        else this.activeFilters.set(column, tempArray);
      } else {
        const tempArray = this.activeFilters.get(column) as string[];
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    if (this.currentPage !== 1) this.setCurrentPage(1);
    this.refresh.emit();
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Filters
  resetFilters(columns: string[]): void {
    for (const column of columns) this.activeFilters.delete(column);
    this.refresh.emit();
  }

  // Get Filter Count
  getFilterCount(columns: string[]): number {
    let count = 0;
    for (const column of columns) if (this.activeFilters.has(column)) count += this.activeFilters.get(column).length;
    return count;
  }

  /* ----- Table Configuration ----- */

  // Load Table Configuration
  private loadTableConfiguration(): void {
    const config: any = JSON.parse(localStorage.getItem('Table.' + this.tableId));
    this.searchTerm = config.searchTerm;
    this.sortBy = config.sortBy;
    this.sortDirection = config.sortDirection;
    this.limit = config.limit;
    this.offset = config.offset;
    this.currentPage = config.currentPage;
    this.activeFilters = new Map(config.activeFilters);
  }

  // Update Table Configuration
  private updateTableConfiguration(): void {
    if (!this.shouldStore) return;
    const config: any = {
      searchTerm: this.searchTerm,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
      limit: this.limit,
      offset: this.offset,
      currentPage: this.currentPage,
      activeFilters: Array.from(this.activeFilters)
    };
    localStorage.setItem('Table.' + this.tableId, JSON.stringify(config));
  }

  // Reset Table Configuration
  resetTableConfiguration(): void {
    this.searchTerm = this.defaultSearchTerm;
    this.sortBy = this.defaultSortBy;
    this.sortDirection = this.defaultSortDirection;
    this.limit = this.defaultLimit;
    this.offset = this.defaultOffset;
    this.currentPage = this.defaultCurrentPage;
    this.activeFilters = new Map(this.defaultFilters);
    if (this.datePickerController) this.datePickerController.resetTableConfiguration();
    else this.refresh.emit();
  }

  // Has Table Configuration
  hasTableConfiguration(): boolean {
    return this.shouldStore && localStorage.getItem('Table.' + this.tableId) !== null;
  }

  // Is Not Default
  isNotDefault(): boolean {
    return this.searchTerm != this.defaultSearchTerm ||
      this.sortBy != this.defaultSortBy ||
      this.sortDirection != this.defaultSortDirection ||
      this.limit != this.defaultLimit ||
      this.offset != this.defaultOffset ||
      this.currentPage != this.defaultCurrentPage ||
      !this.compareMaps(this.activeFilters, this.defaultFilters);
  }

  /* ----- Helper Functions ----- */

  // Compare Maps
  private compareMaps(m1: Map<string, string[] | string>, m2: Map<string, string[] | string>): boolean {
    if (m1.size !== m2.size) return false;
    for (const [key, value] of m1) {
      if (!m2.has(key)) return false;
      const testValue = m2.get(key);
      if (testValue === undefined) return false;
      if (testValue instanceof Array && value.length !== testValue.length) return false;
      if (typeof testValue === 'string' && value !== testValue) return false;
    }
    return true;
  }
}
