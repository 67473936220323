import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Item } from './item';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private http: HttpClient) { }

  // Add Item
  addItem(item: any): Observable<{ id: string, imageUploadUrl: string | null }> {
    return this.http.post<{ id: string, imageUploadUrl: string | null }>(`/inventory`, item);
  }

  // Update Item
  updateItem(item: any): Observable<{ id: string, imageUploadUrl: string | null }> {
    return this.http.put<{ id: string, imageUploadUrl: string | null }>(`/inventory/${item.id}`, item);
  }

  // Delete Item
  deleteItem(itemId: string): Observable<any> {
    return this.http.delete<any>(`/inventory/${itemId}`);
  }

  // Get Items
  getItems(params: any): Observable<{ items: Item[], count: number }> {
    return this.http.get<{ items: Item[], count: number }>(`/inventory`, {
      params: params
    });
  }

  // Get Distinct Column Values
  getDistinctColumnValues(params: any): Observable<string[]> {
    return this.http.get<string[]>(`/inventory/distinctColumnValues`, {
      params: params
    });
  }

  // Adjust Item Stock Current Level
  adjustItemStockCurrentLevel(itemId: string, amount: number): Observable<any> {
    return this.http.put<any>(`/inventory/${itemId}/currentLevel`, { amount: amount });
  }

  // Import Inventory
  importInventory(file: File): Observable<{ addItemCount: number, updateItemCount: number }> {
    let formData = new FormData();
    formData.append('FILE', file);
    return this.http.put<{ addItemCount: number, updateItemCount: number }>(`/inventory/import`, formData);
  }

  /* ----- Item Images ----- */

  // Delete Item Image
  deleteItemImage(itemId: string): Observable<any> {
    return this.http.delete<any>(`/inventory/${itemId}/image`);
  }
}
