import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { faEllipsis, faChevronLeft, faChevronRight, faSave, faPlus, faTrashCan, faSquare, faPencil } from '@fortawesome/free-solid-svg-icons';
import { ScheduleService } from '../schedule.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../alert.service';
import { NgbAlert, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Crew } from '../schedule-item';
import { UserService } from '../user.service';
import { User } from '../user';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { Observable } from 'rxjs';
import { CrewDeletionModalComponent } from '../crew-deletion-modal/crew-deletion-modal.component';

@Component({
  selector: 'app-crew-management',
  templateUrl: './crew-management.component.html',
  styleUrls: ['./crew-management.component.css']
})
export class CrewManagementComponent implements OnInit {

  @ViewChild('popOver') public popOver: NgbPopover;
  @ViewChild('popOverUpdate') public popOverUpdate: NgbPopover;
  @ViewChild('pendingChangesPopOver') public pendingChangesPopOver: NgbPopover;

  @Output() hasChanges: EventEmitter<any> = new EventEmitter<void>();
  @Output() resetPendingChanges: EventEmitter<any> = new EventEmitter<void>();
  @Output() leaveNoSave: EventEmitter<any> = new EventEmitter<void>();
  @Input() showAlert: boolean = false;

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faSave = faSave;
  faEllipsis = faEllipsis;
  faPlus = faPlus;
  faTrashCan = faTrashCan;
  faSquare = faSquare;
  faPencil = faPencil;

  crews: Crew[] = []
  selectedCrew: Crew;
  usersReference: User[] = [];
  membersOnCrew: User[] = [];
  membersNotOnCrew: User[] = [];
  orgLength: number = 0;
  showSaveChanges: boolean = false;
  orginalMembersOnCrew: any[] = [];
  isSaveDisabled:boolean = true;

  crewForm: FormGroup
  archiveCrewsFormGroup: FormGroup

  selectedMemberNotInCrew: User;
  selectedMemberInCrew: User;

  @ViewChild('closingAlert', { static: false }) selfClosingAlert: NgbAlert;

  constructor(
    private scheduleService: ScheduleService,
    private fb: FormBuilder,
    private alertService: AlertService,
    private userService: UserService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.crewForm = this.fb.group({
      crewName: [null, Validators.required],
      crewColor: [null, Validators.required],
      crewId: [null]
    });
    this.archiveCrewsFormGroup = this.fb.group({
      showArchive: [false]
    });

    this.getListOfCrews();

    this.archiveCrewsFormGroup.get('showArchive').valueChanges.subscribe(res => {
      if(res) {
        this.getListOfArchiveCrews();
      } else {
        this.getListOfCrews();
      }
    })
  }

  getListOfCrews() {
    this.scheduleService.getCrews().subscribe(res => {
      this.crews = res;
      if (res && res.length != 0) {
        this.setSelectedCrew(res[0])
        this.membersOnCrew = res[0].members;
        this.orgLength = res[0].members.length;
        this.getListOfUsers();
      } else {
        this.membersOnCrew = null;
        this.membersNotOnCrew = null;
      }
    })
  }

  getListOfArchiveCrews() {
    this.scheduleService.getArchiveCrews().subscribe(res => {
      this.crews = res;
      if (res && res.length != 0) {
        this.setSelectedCrew(res[0])
        this.membersOnCrew = res[0].members;
        this.orgLength = res[0].members.length;
        this.getListOfUsers();
      }
    })
  }

  getListOfUsers() {
    this.userService.getUsers().subscribe(users => {
      this.usersReference = users.filter(user => !user.isArchived);

      this.filterCrewMembers(this.usersReference);
    })
  }

  setSelectedCrew(crew: Crew) {
    this.resetValues();
    this.selectedCrew = crew;
    this.membersOnCrew = crew.members;
    this.filterCrewMembers(this.usersReference);
    this.orginalMembersOnCrew = [...crew.members];
    this.orgLength = crew.members.length;
    this.isSaveDisabled = true;

  }

  resetValues() {
    this.membersOnCrew = [];
    this.membersNotOnCrew = [];
    this.selectedMemberInCrew = null;
    this.selectedMemberNotInCrew = null;
    this.hasChanges.emit(false);
  }

  filterCrewMembers(users) {
    if (this.membersOnCrew && this.membersOnCrew.length != 0) {
      // filter users
      this.membersOnCrew.forEach(existingMember => {
        users = users.filter(member => member.id != existingMember.id)
      });
    }

    this.membersNotOnCrew = users;

    // this.membersNotOnCrew && this.membersNotOnCrew.length != 0 ? this.setSelectedMemberNotInCrew(this.membersNotOnCrew[0]) : null;

    // this.membersOnCrew && this.membersOnCrew.length != 0 ? this.setSelectedMemberInCrew(this.membersOnCrew[0]) : null;
  }

  setSelectedMemberInCrew(member: User) {
    this.selectedMemberInCrew = member;
    this.selectedMemberNotInCrew = null
  }

  setSelectedMemberNotInCrew(member: User) {
    this.selectedMemberNotInCrew = member;
    this.selectedMemberInCrew = null;

  }

  moveToMemberToCrew() {
    this.membersOnCrew = [...this.membersOnCrew, this.selectedMemberNotInCrew]
    this.membersNotOnCrew = this.membersNotOnCrew.filter(member => member.id != this.selectedMemberNotInCrew.id)
    this.hasChanges.emit(true);
    this.checkChanges();
    this.selectedMemberNotInCrew = null

    // this.setSelectedMemberNotInCrew(this.membersNotOnCrew[0])
    // this.setSelectedMemberInCrew(this.membersOnCrew[0])
  }

  removeToMemberToCrew() {
    this.membersNotOnCrew = [...this.membersNotOnCrew, this.selectedMemberInCrew]
    this.membersOnCrew = this.membersOnCrew.filter(member => member.id != this.selectedMemberInCrew.id)
    this.hasChanges.emit(true);
    this.checkChanges();
    this.selectedMemberInCrew = null;

    // this.setSelectedMemberInCrew(this.membersOnCrew[0])
    // this.setSelectedMemberNotInCrew(this.membersNotOnCrew[0])

  }

  updateCrewMembers() {
    const memberIds = this.membersOnCrew.map(member => member.id);
    this.scheduleService.updateCrewMembers(this.selectedCrew.crewId, memberIds).subscribe((value) => {
      this.alertService.showSuccessAlert("Crew is Updated");
      this.hasChanges.emit(false);
      this.resetPendingChanges.emit(false);
      this.orgLength = this.membersOnCrew.length;
      this.crews = this.crews.map(crew => {
        if (crew.crewId == value) {
          return {
            ...crew,
            members: this.membersOnCrew
          }
        } else {
          return crew;
        }
      })
    });
  }

  createCrew() {
    if (this.crewForm.valid) {
      this.scheduleService.saveNewCrew(this.crewForm.value).subscribe((values) => {
        this.crewForm.reset();
        this.popOver.close();
        this.alertService.showSuccessAlert("Crew is created");
        this.getListOfCrews();
      });
    } else {
      this.alertService.showWarningAlert("Crew name is required");
    }
  }

  deleteCrew(crewName: string, crewId: string) {
    this.scheduleService.getCrewSchedule(crewId).subscribe((schedules) => {
      if (schedules && schedules.length > 0) {
        const scheduleIds = schedules.map(sched => sched.id);
        const deletionModalRef = this.modalService.open(CrewDeletionModalComponent, { size: 'lg' });
        deletionModalRef.componentInstance.crewOptions = this.crews.filter(crew => crew.crewId != crewId)
        deletionModalRef.componentInstance.crewIdToReplace = crewId;
        deletionModalRef.componentInstance.crewNameToReplace = crewName;
        deletionModalRef.componentInstance.schedules = schedules;
        // Delete crew and schedules
        deletionModalRef.componentInstance.removeAll.subscribe(() => {
          this.scheduleService.deleteCrewAndSchedules(crewId, scheduleIds).subscribe((res) => {
            this.getListOfCrews();
            this.scheduleService.scheduleUpdated.next();
            this.alertService.showSuccessAlert("Crew and Schedules are removed");
          })
        })
        // remove Crew only
        deletionModalRef.componentInstance.removeCrewOnly.subscribe(() => {
          this.scheduleService.removeCrewToSchedules(crewId, scheduleIds).subscribe((res) => {
            this.getListOfCrews();
            this.scheduleService.scheduleUpdated.next();
            this.alertService.showSuccessAlert("Crew has been removed to schedules");
          });
        })
        deletionModalRef.componentInstance.replaceCrew.subscribe((replacements) => {
          this.scheduleService.replaceCrewInSchedules(crewId, replacements).subscribe((res) => {
            this.getListOfCrews();
            this.scheduleService.scheduleUpdated.next();
            this.alertService.showSuccessAlert("Crew has been replaced");
          });
        });
      } else {
        const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
        confirmationModalRef.componentInstance.message = `Are you sure you would like to delete ${crewName}?`;
        confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
        confirmationModalRef.componentInstance.confirmed.subscribe(() => {
          this.scheduleService.deleteCrew(crewId).subscribe((res) => {
            this.alertService.showSuccessAlert("Crew is Deleted");
            this.getListOfCrews();
          });
        });
      }
    });
  }

  modifyCrew(crew: Crew) {
    this.crewForm.controls.crewName.setValue(crew.crewName);
    this.crewForm.controls.crewColor.setValue(crew.crewColor);
    this.crewForm.controls.crewId.setValue(crew.crewId);
  }

  closeAlert() {
    this.resetPendingChanges.emit('');
    this.selfClosingAlert.close()
  }

  continueWithoutSave(){
    this.leaveNoSave.emit("")
  }

  checkChanges(){
    this.isSaveDisabled = this.membersOnCrew.length == this.orginalMembersOnCrew.length && // same length and
      this.membersOnCrew.every( // every element in a
        e1 => this.orginalMembersOnCrew.some( // has a match in b
            e2 => Object.keys(e1).every(key => e1[key] === e2[key])
        )
      )
  }

  get showArchive(){
    return this.archiveCrewsFormGroup.get('showArchive')
  }
}
