<div class="modal-header">
    <h5 class="modal-title">{{(selectedContact) ? 'Edit' : 'New'}} Contact</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="contactForm">
        <div class="row">
            <div class="col-lg-6">
                <label for="CONTACT_FIRST_NAME">First Name</label>
                <div class="mb-3">
                    <input id="CONTACT_FIRST_NAME" type="text" class="form-control" placeholder="John" formControlName="firstName" maxlength="150" [class.is-invalid]="firstName.invalid && (firstName.dirty || firstName.touched)">
                    <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                        <div *ngIf="firstName.errors.maxlength" class="input-validation-msg">First Name cannot exceed 150 characters.</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <label for="CONTACT_LAST_NAME">Last Name</label>
                <div class="mb-3">
                    <input id="CONTACT_LAST_NAME" type="text" class="form-control" placeholder="Appleseed" formControlName="lastName" maxlength="150" required [class.is-invalid]="lastName.invalid && (lastName.dirty || lastName.touched)">
                    <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                        <div *ngIf="lastName.errors.required" class="input-validation-msg">Last Name is required.</div>
                        <div *ngIf="lastName.errors.maxlength" class="input-validation-msg">Last Name cannot exceed 150 characters.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label for="CONTACT_TITLE">Title</label>
                <div class="mb-3">
                    <input *ngIf="titleTypeaheadInput" id="CONTACT_TITLE" type="text" class="form-control" formControlName="title" placeholder="Owner" maxlength="150" [ngbTypeahead]="titleTypeaheadInput" (focus)="titleTypeaheadInputFocus$.next($any($event).target.value)" [class.is-invalid]="title.invalid && (title.dirty || title.touched)">
                    <div *ngIf="title.invalid && (title.dirty || title.touched)">
                        <div *ngIf="title.errors.maxlength" class="input-validation-msg">Title cannot exceed 150 characters.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <label for="CONTACT_PHONE">Phone</label>
                <div class="mb-3">
                    <input id="CONTACT_PHONE" type="tel" class="form-control" appPhoneMask placeholder="(000) 000-0000" formControlName="phone" pattern="^.{14,}$" [class.is-invalid]="phone.invalid && (phone.dirty || phone.touched)">
                    <div *ngIf="phone.invalid && (phone.dirty || phone.touched)">
                        <div *ngIf="phone.errors.maxlength" class="input-validation-msg">Phone cannot exceed 14 characters.</div>
                        <div *ngIf="phone.errors.pattern" class="input-validation-msg">Phone is formatted incorrectly.</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <label for="CONTACT_PHONE_EXTENSION">Extension</label>
                <div class="mb-3">
                    <input id="CONTACT_PHONE_EXTENSION" type="text" class="form-control" placeholder="12345" formControlName="phoneExtension" maxlength="5" [class.is-invalid]="phoneExtension.invalid && (phoneExtension.dirty || phoneExtension.touched)">
                    <div *ngIf="phoneExtension.invalid && (phoneExtension.dirty || phoneExtension.touched)">
                        <div *ngIf="phoneExtension.errors.maxlength" class="input-validation-msg">Phone cannot exceed 5 characters.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label for="CONTACT_EMAIL">Email</label>
                <div class="mb-3">
                    <input id="CONTACT_EMAIL" type="text" class="form-control" placeholder="jappleseed@example.com" formControlName="email" autocapitalize="off" maxlength="320" pattern="^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$" [class.is-invalid]="email.invalid && (email.dirty || email.touched)" 
                    [required]="contactForm.controls.isPrimary.value">
                    <div *ngIf="email.invalid && (email.dirty || email.touched)">
                        <div *ngIf="email.errors.maxlength" class="input-validation-msg">Email cannot exceed 320 characters.</div>
                        <div *ngIf="email.errors.pattern" class="input-validation-msg">Email is formatted incorrectly.</div>
                        <div *ngIf="contactForm.controls.isPrimary.value" class="input-validation-msg">Email is required for Primary contacts</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-check mb-0">
            <input id="CONTACT_IS_PRIMARY" type="checkbox" class="form-check-input" formControlName="isPrimary">
            <label for="CONTACT_IS_PRIMARY" class="form-check-label">Primary Contact</label>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveContact();" [disabled]="!contactForm.valid">Save</button>
    <button *ngIf="selectedContact" (click)="openDeleteContactConfirmationModal();" type="button" class="btn btn-danger me-1">Delete</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>