<form [formGroup]="itemForm" (ngSubmit)="submit()">
    <div class="modal-header">
        <div>
            <h5 class="modal-title">Splitting Item</h5>
            <h5 class="text-muted">{{ itemName }}</h5>
        </div>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
    </div>

    <div class="modal-body">
        <div class="row mt-2 align-items-center">
            <div class="col-md-7">
                <label class="">Quantity Needed</label>
            </div>
            <div class="col-md-5 text-center">
               {{ maxQuantity }}
            </div>
        </div>
        <div class="row mt-2 align-items-center">
            <div class="col-md-7">
                <label for="PULL_QTY" class="">Quantity to Pull from Inventory</label>
            </div>
            <div class="col-md-5">
                <input id="PULL_QTY" type="number" class="form-control text-end" formControlName="pullQty">
                <div *ngIf="pullQty.invalid && (pullQty.dirty || pullQty.touched)">
                    <div *ngIf="pullQty.errors.max" class="input-validation-msg">
                        Quantity to pull can't be equal to quantity needed
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2 align-items-center">
            <div class="col-md-7">
                <label for="ORDER_QTY" class="">Quantity to be Ordered</label>
            </div>
            <div class="col-md-5">
                <input id="ORDER_QTY" type="number" class="form-control text-end" formControlName="orderQty">
                <div *ngIf="orderQty.invalid && (orderQty.dirty || orderQty.touched)">
                    <div *ngIf="orderQty.errors.max" class="input-validation-msg">
                        Quantity to order can't be equal to quantity needed
                    </div>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="pullQty.errors.mustMatch">Quantity must be Equal to the Quantity needed</div> -->

    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary" [disabled]="!itemForm.valid">Split Items</button>
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
    </div>
</form>