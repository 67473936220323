<div class="modal-header">
    <h5 class="modal-title">Refund Details</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="subscriptionRefundForm">
        <div class="mb-2">
            <label for="TRANSACTION_ID">Transaction ID</label>
            <input id="TRANSACTION_ID" type="text" class="form-control" placeholder="0000000000"
                formControlName="transactionId" minlength="10" maxlength="10">
        </div>
        <div class="mb-2">
            <label for="TRANSACTION_DATE">Transaction Date</label>
            <input id="TRANSACTION_DATE" type="datetime-local" class="form-control" formControlName="transactionDate">
        </div>
        <div class="mb-2">
            <label for="TOTAL">Amount</label>
            <input id="TOTAL" type="number" class="form-control" placeholder="50.00" formControlName="refundAmount">
        </div>
        <div>
            <label for="CREATED_AT">Requested At</label>
            <input id="CREATED_AT" type="datetime-local" class="form-control" formControlName="requestedAt">
        </div>
        <div>
            <label for="REFUND_REASON">Refund Reason</label>
            <textarea id="REFUND_REASON" class="form-control" formControlName="refundReason"
                placeholder="Reason for refund (Ticket number of applicable)" rows="3" maxlength="65535"></textarea>
        </div>
        <div>
            <label for="API_RESPONSE">API Response</label>
            <textarea id="API_RESPONSE" class="form-control" formControlName="apiResponse"
                placeholder="Cliq API REsponse" rows="3" maxlength="65535"></textarea>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="buttn" class="btn btn-secondary"
        (click)="modal.dismiss();">Close</button>
</div>