import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert.service';
import { RegistrationService } from '../registration.service';

@Component({
  selector: 'app-registration-payment-form',
  templateUrl: './registration-payment-form.component.html',
  styleUrls: ['./registration-payment-form.component.css']
})
export class RegistrationPaymentFormComponent implements OnInit, AfterViewInit {

  // Properties
  @Input() amount: number;
  @Input() payNow: boolean;
  @Input() subscriptionId: string;
  @Input() billingInformation: any;
  @Output() subscriptionSuccessful: EventEmitter<{ initialTransactionId: string, customerVaultId: string }> = new EventEmitter<{ initialTransactionId: string, customerVaultId: string }>();
  renewalTermsAccepted: boolean = false;
  // applePayIsAvailable: boolean = false;
  // googlePayIsAvailable: boolean = false;
  paymentInProgress: boolean = false;
  paymentButtonText: string;
  paymentFormValidation: any = {
    ccnumber: {
      isValid: false,
      hasError: false,
      errorMessage: null
    },
    ccexp: {
      isValid: false,
      hasError: false,
      errorMessage: null
    },
    cvv: {
      isValid: false,
      hasError: false,
      errorMessage: null
    }
  };

  // Font Awesome Properties
  faCircleExclamation = faCircleExclamation;

  constructor(private registrationService: RegistrationService,
    private alertService: AlertService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {

  }

  async ngAfterViewInit(): Promise<void> {
    await this.loadCollectJsScript();
  }

  // Load CollectJs Script
  private loadCollectJsScript(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (document.getElementById('COLLECT_JS_SCRIPT') !== null) return resolve();
      const script = document.createElement('script');
      script.id = 'COLLECT_JS_SCRIPT';
      script.type = 'text/javascript';
      script.src = 'https://secure.cardflexonline.com/token/Collect.js';
      script.async = true;
      script.defer = true;
      script.dataset.tokenizationKey = environment.tinselPay.tokenizationKey;
      script.addEventListener('load', () => {
        resolve();
      });
      script.addEventListener('error', (error) => {
        reject(error);
      });
      document.body.appendChild(script);
    });
  }

  // Accept Renewal Terms
  acceptRenewalTerms(): void {
    this.renewalTermsAccepted = true;
    setTimeout(() => {
      this.initializeCardForm();
    }, 250);
  }

  // Initialize Card Form
  private initializeCardForm(): void {
    (window as any).CollectJS.configure({
      variant: 'inline',
      fields: {
        ccnumber: {
          placeholder: '0000 0000 0000 0000'
        },
        ccexp: {
          placeholder: 'MM/YY'
        },
        cvv: {
          placeholder: 'CVV'
        }
      },
      styleSniffer: false,
      googleFont: 'Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
      customCss: {
        'height': '40px',
        'padding': '0.375rem 0.75rem',
        'font-family': "'Poppins', sans-serif",
        'font-size': '1rem',
        'font-weight': '400',
        'line-height': '1.5',
        'color': '#212529',
        'background-clip': 'padding-box',
        'border': '1px solid #CED4DA',
        '-webkit-appearance': 'none',
        'appearance': 'none',
        'border-radius': '0.25rem'
      },
      invalidCss: {
        'border-color': '#DC3545'
      },
      placeholderCss: {
        'color': 'rgb(200,200,200)',
        'opacity': '1',
        'font-family': "'Poppins', sans-serif"
      },
      validationCallback: (field, status, message) => {
        this.paymentFormValidation[field].isValid = status;
        this.paymentFormValidation[field].hasError = !status;
        if (!status) {
          if ((message as string).includes('Field')) {
            if (field == 'ccnumber') this.paymentFormValidation[field].errorMessage = `Card Number: ${message}.`;
            else if (field == 'ccexp') this.paymentFormValidation[field].errorMessage = `Expiration: ${message}.`;
            else if (field == 'cvv') this.paymentFormValidation[field].errorMessage = `CVV: ${message}.`;
            else this.paymentFormValidation[field].errorMessage = `Other: ${message}.`;
          } else {
            this.paymentFormValidation[field].errorMessage = message + '.';
          }
        } else {
          this.paymentFormValidation[field].errorMessage = null;
        }
        this.changeDetectorRef.detectChanges();
      },
      callback: (response) => {
        this.processInitialSubscriptionPayment(response.token);
      }
    });
    this.paymentButtonText = (this.payNow) ? `Pay $${this.amount}` : 'Subscribe with Card';
  }

  // Pay With Card
  payWithCard(): void {
    try {
      this.paymentInProgress = true;
      this.paymentButtonText = 'Processing...';
      (window as any).CollectJS.startPaymentRequest();
    } catch (error) {
      console.error(error);
    }
  }

  // Process Initial Subscription Payment
  private processInitialSubscriptionPayment(token: string): void {
    const data: any = {
      token: token,
      payNow: this.payNow,
      amount: (this.payNow) ? this.amount : null,
      subscriptionId: this.subscriptionId,
      billingInformation: this.billingInformation
    };
    this.registrationService.processInitialSubscriptionPayment(data).pipe(catchError(() => {
      this.paymentButtonText = (this.payNow) ? `Pay $${this.amount}` : 'Subscribe with Card';
      this.paymentInProgress = false;
      this.changeDetectorRef.detectChanges();
      return throwError(() => new Error('API Request Error'));
    })).subscribe((res) => {
      const successMessage = (this.payNow) ? 'Payment Successful' : 'Card Saved';
      this.alertService.showSuccessAlert(successMessage);
      this.subscriptionSuccessful.emit(res);
    });
  }

  // Is Payment Form Valid
  isPaymentFormValid(): boolean {
    return this.paymentFormValidation.ccnumber.isValid
      && this.paymentFormValidation.ccexp.isValid
      && this.paymentFormValidation.cvv.isValid;
  }

  // Does Payment Form Have Error
  doesPaymentFormHaveError(): boolean {
    return this.paymentFormValidation.ccnumber.hasError
      || this.paymentFormValidation.ccexp.hasError
      || this.paymentFormValidation.cvv.hasError;
  }
}
