import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Model from './sms-call';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SmsCallService {

    constructor(private http: HttpClient) { }

    settingsUrl = `/settings/smscall`;
    callUrl = `/call`;

    private handleError(error: HttpErrorResponse) {
        console.error('An error occurred:', error.error);
        return throwError('Something bad happened; please try again later.');
    }

    getOrgNumbers(): Observable<Model.OrgNumber[]> {
        return this.http.get<Model.OrgNumber[]>(`${this.settingsUrl}/numbers`);
    }

    createServiceAndCampaign(serviceAndCampaign: Model.ServiceAndCampaign) {
        return this.http.post(`${this.settingsUrl}/createserviceandcampaign`, serviceAndCampaign)
    }

    changeAccStatus(status: boolean): Observable<any> {
        return this.http.post<any>(`${this.settingsUrl}/changestatus?status=${status}`, null).pipe(
            map((res: any) => <any>res),
            catchError(this.handleError)
        );
    }

    getSmsCallSettings(): Observable<any> {
        return this.http.get<any>(`${this.settingsUrl}`).pipe(
            map((res: any) => <any>res),
            catchError(this.handleError)
        );
    }

    postBusinessInfo(data: any): Observable<any> {
        return this.http.post<any>(`${this.settingsUrl}/businessinfo`, data).pipe(
            map((res: any) => <any>res),
            catchError(this.handleError)
        );
    }

    postAuthorizedRep(data: any): Observable<any> {
        return this.http.post<any>(`${this.settingsUrl}/authorizedrep`, data).pipe(
            map((res: any) => <any>res),
            catchError(this.handleError)
        );
    }

    postBusinessAddress(data: any): Observable<any> {
        return this.http.post<any>(`${this.settingsUrl}/businessaddress`, data).pipe(
            map((res: any) => <any>res),
            catchError(this.handleError)
        );
    }

    submitForReview(): Observable<any> {
        return this.http.post<any>(`${this.settingsUrl}/profilereview`, null).pipe(
            map((res: any) => <any>res),
            catchError(this.handleError)
        );
    }

    callAnumber(to: string): Observable<any> {
        const defaultNumber = JSON.parse(localStorage.getItem('login'))?.defaultNumber;

        return this.http.post<any>(`${this.callUrl}/callanumber`, {
            to, from: defaultNumber
        }).pipe(
            map((res: any) => <any>res),
            catchError(this.handleError)
        );
    }

    exportMessageHistoryAndLogs(data) {
      return this.http.post(`${this.settingsUrl}/messagehistory`, data, { "responseType": 'text' }).subscribe({
        next: (csvData: string) => {
         return this.downloadFile(csvData, data, 'Message_History_and_Logs');
        },
        error: (error) => {
          console.error('An error occurred:', error);
        }
      });
    }

    private downloadFile(data: string, body: any, title: string) {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      const date = body.dateSent ? new Date(body.dateSent) : new Date();
      a.href = url;
      a.download = `${title}_${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    }

    callRecordingEnableDisable(status: boolean): Observable<any> {
      return this.http.put<any>(`${this.settingsUrl}/callrecord/${status}`, null).pipe(
          map((res: any) => <any>res),
          catchError(this.handleError)
      );
  }
}
