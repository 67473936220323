import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormBuilder } from '@angular/forms';
import { faBold, faMicrophone, faMicrophoneSlash, faPhone, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { interval, lastValueFrom, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { ContactsSerivce } from 'src/app/contacts.service';
import { TableControlService } from 'src/app/table-control.service';
import { ConversationService } from '../conversation.service';
import { CallService } from '../call.service';
import { TwilioService } from '../twilio.service';

@Component({
  selector: 'app-in-call-dialog',
  templateUrl: './in-call-dialog.component.html',
  styleUrls: ['./in-call-dialog.component.css'],
})
export class InCallDialogComponent implements OnInit {

  contact: any = {
    number: "",
    contact: {

    }
  };
  public activeCall: any = true;

  public isIncall: boolean = false;
  public ringing: boolean = false;
  private destroyed$ = new Subject();
  public callDetails: any = {
    created: null,
    id: null
  }

  public isCallMute: boolean = false;
  public isIncoming: boolean = false;

  faPhone = faPhone;
  faMicrophone = faMicrophone;
  faMicrophoneSlash = faMicrophoneSlash;


  constructor(
    private changeDetector: ChangeDetectorRef,
    private conversationSerivce: ConversationService,
    private callService: CallService,
    private twilioService: TwilioService,
  ) { }

  ngOnInit(): void {
    this.callService.activeCall
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        if (val) {
          console.log(val)
          this.activeCall = val;
          this.contact.number = this.activeCall?.parameters.From;
          this.getContactByNumber();
          this.callEvents();
        }
      });

    this.callService.callAccept
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        if (val) {
          this.initiateInCall();
          this.isIncoming = true;
        }
        this.isIncall = val;
      });

    this.callService.callEnded
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        if(val){
          this.endCall();
        }
      });

    this.twilioService.twilioOutgoingCall
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        if (val) {
          this.activeCall = val; 
          this.callEvents();
          this.contact.number = Array.from(val.customParameters.values()).map(c => c)[0]
          this.getContactByNumber();
        }
      });

    this.twilioService.outGoingCallRinging
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        if (val) {
          this.ringing = val;
        }
      });
  }

  initiateInCall() {
    let _this = this;
    setTimeout(
      () => {
        _this.callDetails = {
          created: new Date(),
          id: '123'
        }
        this.callTimer();
      });
  }

  mute() {
    this.isCallMute = !this.isCallMute;
    this.activeCall.mute(this.isCallMute);
  }

  endCall() {
    this.activeCall.disconnect();
    this.callService.isDialing = null;
    this.isIncall = false;
    this.ringing = false;
    this.callService.isCallAccept = false;
  }

  callTimer() {
    interval(1000).subscribe(() => {
      if (!this.changeDetector['destroyed']) {
        this.changeDetector.detectChanges();
      }
    });
    this.changeDetector.detectChanges();
  }

  getElapsedTime(entry: any): TimeSpan {
    let totalSeconds = Math.floor((new Date().getTime() - entry?.created?.getTime()) / 1000);

    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (totalSeconds >= 3600) {
      hours = Math.floor(totalSeconds / 3600);
      totalSeconds -= 3600 * hours;
    }

    if (totalSeconds >= 60) {
      minutes = Math.floor(totalSeconds / 60);
      totalSeconds -= 60 * minutes;
    }

    seconds = totalSeconds;

    return {
      hours: hours,
      minutes: minutes,
      seconds: seconds
    };
  }

  callEvents() {

    this.activeCall.on('disconnect', call => {
      this.callService.isCallAccept = false;
    });

    this.activeCall.on('cancel', call => {
      this.callService.isCallAccept = false;
    });

  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  getContactByNumber(){
    if(this.contact && this.contact.number) {
      this.conversationSerivce.getContactByNumber(this.contact.number).subscribe(res => {
        this.contact.contact = res;
      })
    }
  }

  currentContactDisplay(){
    return this.contact.contact?.hasOwnProperty('clientId') ? `${this.contact.contact?.firstName} ${this.contact.contact?.lastName} (${this.contact.number})` : this.contact.number;
  }

}

export interface TimeSpan {
  hours: number;
  minutes: number;
  seconds: number;
}