import { Component, OnInit } from '@angular/core';
import { faBug } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-bug-report-overlay',
  templateUrl: './bug-report-overlay.component.html',
  styleUrls: ['./bug-report-overlay.component.css']
})
export class BugReportOverlayComponent implements OnInit {
  // Font Awesome Properties
  faBug = faBug;

  constructor() { }

  ngOnInit(): void {
  }

}
