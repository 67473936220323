import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, merge, Observable, Subject, switchMap } from 'rxjs';
import { AlertService } from '../alert.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { Contact } from '../contact';
import { ContactService } from '../contact.service';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.css']
})
export class ContactModalComponent implements OnInit {

  // Properties
  private modalReference: NgbModalRef;
  @Output() saved: EventEmitter<void> = new EventEmitter<void>();
  contactForm: UntypedFormGroup;
  selectedContact: Contact;
  clientId: string;
  shouldBePrimary: boolean;

  // Title Typeahead
  titleTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  titleTypeaheadInputFocus$ = new Subject<string>();

  constructor(private contactService: ContactService,
    private alertService: AlertService,
    private modalService: NgbModal,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.contactForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      title: new UntypedFormControl(),
      phone: new UntypedFormControl(),
      phoneExtension: new UntypedFormControl(),
      email: new UntypedFormControl(),
      isPrimary: new UntypedFormControl(this.shouldBePrimary)
    });
    if (this.selectedContact) this.prepareContactForm();
    this.prepareTitleTypeahead();
  }

  // Open Delete Contact Confirmation Modal
  openDeleteContactConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this contact?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteContact();
    });
  }

  // Get Titles
  private getTitles(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'contact_title',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'contact_title',
      hideBlanks: true
    };
    return this.contactService.getDistinctColumnValues(params);
  }

  // Prepare Title Typeahead
  private prepareTitleTypeahead(): void {
    this.titleTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.titleTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getTitles((term.length == 0) ? null : term);
      }));
    }
  }

  // Save Contact
  saveContact(): void {
    if (this.selectedContact) {
      this.updateContact();
    } else {
      this.addContact();
    }
  }

  // Add Contact
  private addContact(): void {
    if (this.contactForm.valid) {
      const contact = {
        clientId: this.clientId,
        firstName: this.contactForm.value.firstName,
        lastName: this.contactForm.value.lastName,
        title: this.contactForm.value.title,
        phone: this.contactForm.value.phone.substring(0,14),
        phoneExtension: this.contactForm.value.phoneExtension,
        email: this.contactForm.value.email,
        isPrimary: this.contactForm.value.isPrimary
      };
      this.contactService.addContact(contact).subscribe(() => {
        this.alertService.showSuccessAlert('Contact Added');
        this.saved.emit();
        this.modal.close();
      });
    } else {
      this.contactForm.markAllAsTouched();
    }
  }

  // Update Contact
  private updateContact(): void {
    if (this.contactForm.valid) {
      const contact = {
        id: this.selectedContact.id,
        firstName: this.contactForm.value.firstName,
        lastName: this.contactForm.value.lastName,
        title: this.contactForm.value.title,
        phone: this.contactForm.value.phone.substring(0,14),
        phoneExtension: this.contactForm.value.phoneExtension,
        email: this.contactForm.value.email,
        isPrimary: this.contactForm.value.isPrimary
      };
      this.contactService.updateContact(contact).subscribe(() => {
        this.alertService.showSuccessAlert('Contact Updated');
        this.saved.emit();
        this.modal.close();
      });
    } else {
      this.contactForm.markAllAsTouched();
    }
  }

  // Delete Contact
  deleteContact(): void {
    this.contactService.deleteContact(this.selectedContact.id).subscribe(() => {
      this.alertService.showSuccessAlert('Contact Deleted');
      this.saved.emit();
      this.modal.close();
    });
  }

  // Prepare Contact Form
  prepareContactForm(): void {
    this.contactForm.controls.firstName.setValue(this.selectedContact.firstName);
    this.contactForm.controls.lastName.setValue(this.selectedContact.lastName);
    this.contactForm.controls.title.setValue(this.selectedContact.title);
    this.contactForm.controls.phone.setValue(this.selectedContact.phone);
    this.contactForm.controls.phoneExtension.setValue(this.selectedContact.phoneExtension);
    this.contactForm.controls.email.setValue(this.selectedContact.email);
    this.contactForm.controls.isPrimary.setValue(this.selectedContact.isPrimary);
  }

  // Contact Form Accessors
  get firstName() { return this.contactForm.controls.firstName; }
  get lastName() { return this.contactForm.controls.lastName; }
  get title() { return this.contactForm.controls.title; }
  get phone() { return this.contactForm.controls.phone; }
  get phoneExtension() { return this.contactForm.controls.phoneExtension; }
  get email() { return this.contactForm.controls.email; }
  get isPrimary() { return this.contactForm.controls.isPrimary; }
}
