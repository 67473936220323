<div class="card mt-3">
  <div class="card-header">
    <h5>Files</h5>
  </div>
  <div class="row button-container" *ngIf="isMobile">
    <div class="sort" *ngIf="isMobile"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>
    <!-- <div class="filter" *ngIf="isMobile"><ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container></div> -->
  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table *ngIf="!isMobile">
        <thead>
          <tr>
            <th (click)="tableControls.sort('pf.project_file_name');">
              File Name
              <fa-icon *ngIf="tableControls.isSortedBy('pf.project_file_name')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
            </th>
            <th>
              Project
            </th>
            <th (click)="tableControls.sort('s.site_name');">
              Site
              <fa-icon *ngIf="tableControls.isSortedBy('s.site_name')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
            </th>
            <th>
              Created
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of projectFiles" (click)="openFile(file);">
            <td data-label="File Name">{{file.name}}</td>
            <td data-label="Project">{{file.projectName}}</td>
            <td data-label="Site">{{file.site.address.street}} {{file.site.address.city}} {{file.site.address.state}} {{file.site.address.postalCode}} ({{file.site.name}})</td>
            <td data-label="Created">{{file.createdAt | formatDateTime}}</td>
            <td (click)="$event.stopPropagation();" data-label="Actions">
              <button type="button" class="btn btn-primary me-2" (click)="downloadFile(file);" ngbTooltip="Download">
                <fa-icon [icon]="faDownload"></fa-icon>
              </button>
              <button type="button" class="btn btn-danger" (click)="selectedProjectFile = file; openDeleteFileConfirmationModal();" ngbTooltip="Delete">
                <fa-icon [icon]="faTrashCan"></fa-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="scrollable" *ngIf="isMobile">
        <ul class="list-group">
            <li *ngFor="let file of projectFiles" class="list-group-item" [style.cursor]="isFileClickable(file) ? 'pointer': 'default'">
                <div class="d-flex list-group-container">
                    <div>
                        <strong (click)="openFile(file);">{{file.name}}</strong>
                        <div  (click)="openFile(file);">{{file.projectName}}</div>
                        <div  (click)="openFile(file);">{{file.site.address.street}} {{file.site.address.city}} {{file.site.address.state}} {{file.site.address.postalCode}} ({{file.site.name}})</div>
                        <small class="text-muted"  (click)="openFile(file);">Created: {{file.createdAt | formatDateTime}}</small>
                        <div class="list-button">
                          <button type="button" class="btn btn-primary" (click)="downloadFile(file);" ngbTooltip="Download">
                          <fa-icon [icon]="faDownload"></fa-icon>&nbsp;Download
                          </button>
                          <button type="button" class="btn btn-danger" (click)="selectedProjectFile = file; openDeleteFileConfirmationModal();" ngbTooltip="Delete">
                            <fa-icon [icon]="faTrashCan"></fa-icon>&nbsp;Delete
                          </button>
                      </div>
                    </div>
                </div>
            </li>
            <li *ngIf="projectFiles.length == 0" class="list-group-item">No Files</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- For Project Pdf File Modal -->
<ng-template #clientFilePdf let-modal>
  <div class="modal-header">
      <h5 class="modal-title">{{selectedPdfProjectFile.name}}</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
  </div>
  <div class="modal-body">
    <div class="iframe-container container">
      <iframe class="responsive-iframe" [src]="sanitizer.bypassSecurityTrustResourceUrl(selectedPdfProjectFile.presignedUrl)"></iframe>
    </div>
  </div>
  <div class="modal-footer">

  </div>
</ng-template>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block" [autoClose]="true" placement="bottom-left">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>
