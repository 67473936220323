<div class="modal-header">
    <h5 class="modal-title"><span *ngIf="scheduleItem && scheduleItem.status == 'SCHEDULED'">Edit</span> Schedule Item
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <form [formGroup]="scheduleItemForm">
        <div *ngIf="!scheduleItem || scheduleItem.type == 'CUSTOM'" class="mb-3">
            <label for="SCHEDULE_ITEM_CLIENT">Client</label>
            <input *ngIf="clientTypeaheadInput && !isClientUnassigned" id="SCHEDULE_ITEM_CLIENT" type="text"
                class="form-control" formControlName="client" placeholder="Type to Search"
                [ngbTypeahead]="clientTypeaheadInput"
                (focus)="clientTypeaheadInputFocus$.next($any($event).target.value)"
                [resultFormatter]="clientResultFormatter" (selectItem)="selectClient($event);">
            <div class="form-check mb-0" [class.mt-1]="!isClientUnassigned">
                <input id="SCHEDULE_ITEM_CLIENT_UNASSIGNED" type="checkbox" class="form-check-input"
                    [checked]="isClientUnassigned" (change)="toggleClientUnassigned($any($event).target.checked);">
                <label for="SCHEDULE_ITEM_CLIENT_UNASSIGNED" class="form-check-label"
                    style="font-weight: normal;">Unassigned</label>
            </div>
        </div>
        <div *ngIf="(!isClientUnassigned && selectedClient) && (!scheduleItem || scheduleItem.type == 'CUSTOM' || scheduleItem.status == 'SCHEDULED')"
            class="mb-3">
            <label for="SCHEDULE_ITEM_PROJECT">Project</label>
            <div *ngIf="!isProjectUnassigned" class="input-group">
                <select id="SCHEDULE_ITEM_PROJECT" class="form-select" formControlName="project">
                    <option *ngFor="let project of projects" [value]="project.id">{{project.name}}</option>
                </select>
                <button *ngIf="scheduleItem && scheduleItem.projectId" type="button" class="btn btn-outline-primary"
                    routerLink="/projects/{{scheduleItem.projectId}}">
                    <fa-icon [icon]="faExternalLinkAlt"></fa-icon>
                </button>
            </div>
            <div *ngIf="!scheduleItem || scheduleItem.type == 'CUSTOM'" class="form-check mb-0"
                [class.mt-1]="!isProjectUnassigned">
                <input id="SCHEDULE_ITEM_PROJECT_UNASSIGNED" type="checkbox" class="form-check-input"
                    [checked]="isProjectUnassigned" (change)="toggleProjectUnassigned($any($event).target.checked);">
                <label for="SCHEDULE_ITEM_PROJECT_UNASSIGNED" class="form-check-label"
                    style="font-weight: normal;">Unassigned</label>
            </div>
        </div>
        <div *ngIf="!scheduleItem || scheduleItem.type == 'CUSTOM'" class="mb-3">
            <label for="SCHEDULE_ITEM_NAME">Name</label>
            <input id="SCHEDULE_ITEM_NAME" type="text" class="form-control" formControlName="name"
                placeholder="Rain Date" maxlength="150" required
                [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
                <div *ngIf="name.errors.required" class="input-validation-msg">Name is required.</div>
                <div *ngIf="name.errors.maxlength" class="input-validation-msg">Name cannot exceed 150 characters.</div>
            </div>
        </div>
        <div *ngIf="scheduleItem && scheduleItem.type == 'INSTALL'" class="mb-2">
            <div class="form-check">
                <input id="SCHEDULE_ITEM_GENERATE_INVOICE" type="checkbox" class="form-check-input"
                    formControlName="generateInvoice">
                <label for="SCHEDULE_ITEM_GENERATE_INVOICE" class="form-check-label">Final Invoice</label>
                <span *ngIf="scheduleItem.acceptedProposalExists" class="ms-2" style="cursor: pointer;"
                    ngbPopover="Generate an invoice one day before the schedule date." triggers="click"
                    placement="right">
                    <fa-icon [icon]="faQuestionCircle"></fa-icon>
                </span>
                <span *ngIf="!scheduleItem.acceptedProposalExists" class="ms-2" style="cursor: pointer;"
                    ngbPopover="Cannot schedule a final invoice because there is no accepted proposal for this project."
                    triggers="click" placement="right">
                    <fa-icon [icon]="faQuestionCircle" style="opacity: 0.5;"></fa-icon>
                </span>
            </div>
        </div>
        <div class="mb-2"
            [class.mb-3]="!(scheduleItem && ((scheduleItem.type == 'INSTALL' && scheduleItem.installationWeek) || scheduleItem.type == 'REMOVE'))">
            <label for="SCHEDULE_ITEM_DATE">Date</label>
            <div class="input-group">
                <input *ngIf="isAllDay" id="SCHEDULE_ITEM_DATE" type="date" class="form-control" formControlName="date"
                    required [class.is-invalid]="date.invalid && (date.dirty || date.touched)">
                <input *ngIf="!isAllDay" id="SCHEDULE_ITEM_DATE" type="datetime-local" class="form-control"
                    formControlName="date" required [class.is-invalid]="date.invalid && (date.dirty || date.touched)">
                <div class="input-group-text">
                    <div class="form-check mb-0">
                        <input id="SCHEDULE_ITEM_DATE_IS_ALL_DAY" type="checkbox" class="form-check-input"
                            [checked]="isAllDay" (change)="toggleAllDay($any($event).target.checked);">
                        <label for="SCHEDULE_ITEM_DATE_IS_ALL_DAY" class="form-check-label"
                            style="font-weight: normal;">All Day</label>
                    </div>
                </div>
            </div>
            <div *ngIf="date.invalid && (date.dirty || date.touched)">
                <div *ngIf="date.errors.required" class="input-validation-msg">Date is required.</div>
            </div>
            <small *ngIf="scheduleItem && scheduleItem.type == 'INSTALL' && scheduleItem.installationWeek"
                class="text-muted">Preferred Installation Week: {{scheduleItem.installationWeek | formatDateTime:
                true}}</small>
            <small *ngIf="scheduleItem && scheduleItem.type == 'REMOVE'" class="text-muted">Preferred Removal Type:
                {{scheduleItem.removalType}}</small>
        </div>
        <div *ngIf="!isAllDay" class="mb-2" [class.mb-3]="!date.value">
            <label for="SCHEDULE_ITEM_DURATION">Duration</label>
            <div class="input-group">
                <input id="SCHEDULE_ITEM_DURATION" type="number" class="form-control" placeholder="1.50"
                    formControlName="duration" step="0.25" [required]="!isAllDay">
                <span class="input-group-text">Hours</span>
            </div>
            <div *ngIf="duration.invalid && (duration.dirty || duration.touched)">
                <div *ngIf="duration.errors.required" class="input-validation-msg">Duration is required if not an
                    all-day event.</div>
                <div *ngIf="duration.errors.min" class="input-validation-msg">Duration cannot be less than 0.01 hours.
                </div>
            </div>
            <small *ngIf="date.value" class="text-muted">End: {{getEndDateTime()}}</small>
        </div>
        <!-- <div class="mb-3">
            <div class="text-end">
                <label class="form-check-label mx-2">Select Crews</label>
                <ng-toggle
                [value]="true"
                [color]="{
                  unchecked: '#939da2',
                  checked: '#f62d51'
                }"
              ></ng-toggle>
                <label class="form-check-label mx-2" for="flexSwitchCheckDefault">Select Users</label>
            </div>
        </div> -->

        <ul ngbNav #nav="ngbNav" class="nav-tabs" [activeId]="getCurrentTab()">
            <!-- Organization -->
            <li ngbNavItem="CREW">
                <a ngbNavLink>
                    Crews
                    <span *ngIf="selectedCrewIds.length > 0" class="badge">
                        {{selectedCrewIds.length}}
                    </span>
                </a>
                <ng-template ngbNavContent>
                    <div id="SCHEDULE_ITEM_CREWS" class="scrollable" style="max-height: 164px;">
                        <ul class="list-group">
                            <li *ngFor="let crew of crews" class="list-group-item cursor-pointer"
                                (click)="selectCrews(crew);">
                                <div class="d-flex">
                                    <div>{{ crew.crewName }}</div>
                                    <fa-icon *ngIf="selectedCrewIds.includes(crew.crewId);" [icon]="faCheck"
                                        class="ms-auto"></fa-icon>
                                </div>
                            </li>
                            <li *ngIf="crews?.length == 0" class="list-group-item">No Crews</li>
                        </ul>
                    </div>
                </ng-template>
            </li>
            <!-- Users -->
            <li ngbNavItem="USERS">
                <a ngbNavLink>
                    Users
                    <span *ngIf="selectedUserIds.length > 0" class="badge">
                        {{selectedUserIds.length}}
                    </span>
                </a>
                <ng-template ngbNavContent>
                    <div id="SCHEDULE_ITEM_USERS" class="scrollable" style="max-height: 164px;">
                        <ul class="list-group">
                            <li *ngFor="let user of users" class="list-group-item" (click)="selectUser(user.id, user.isArchived);"
                                [class.disabled-user]="user.isArchived" [ngbTooltip]="user.isArchived ? 'User is disabled':null">
                                <div class="d-flex">
                                    <div>{{user.firstName}} {{user.lastName}}</div>
                                    <fa-icon *ngIf="selectedUserIds.includes(user.id);" [icon]="faCheck"
                                        class="ms-auto"></fa-icon>
                                </div>
                            </li>
                            <li *ngIf="users.length == 0" class="list-group-item">No Users</li>
                        </ul>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div class="mb-3">
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
        <div>
            <label for="SCHEDULE_ITEM_NOTES">Notes</label>
            <textarea id="SCHEDULE_ITEM_NOTES" class="form-control" placeholder="Notes" formControlName="notes" rows="4"
                maxlength="65535"></textarea>
            <div *ngIf="notes.invalid && (notes.dirty || notes.touched)">
                <div *ngIf="notes.errors.maxlength" class="input-validation-msg">Notes cannot exceed 65535 characters.
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button *ngIf="!scheduleItem" type="button" class="btn btn-primary"
        (click)="addCustomScheduleItem();">Schedule</button>
    <button *ngIf="scheduleItem && scheduleItem.status == 'UNSCHEDULED'" type="button" class="btn btn-primary"
        (click)="addItemToSchedule();">Schedule</button>
    <button *ngIf="scheduleItem && scheduleItem.status == 'SCHEDULED'" type="button" class="btn btn-primary"
        (click)="updateScheduleItem();">Save</button>
    <button *ngIf="scheduleItem && scheduleItem.status == 'SCHEDULED' && scheduleItem.type == 'CUSTOM'" type="button"
        class="btn btn-danger" (click)="deleteScheduleItem();">Delete</button>
    <button *ngIf="scheduleItem && scheduleItem.status == 'SCHEDULED' && scheduleItem.type != 'CUSTOM'" type="button"
        class="btn btn-danger" (click)="removeItemFromSchedule();">Unschedule</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>