<div #ALERT_CONTAINER id="ALERT_CONTAINER" class="alert fade" role="alert">
    <div class="d-flex">
        <fa-icon *ngIf="alertIcon" [icon]="alertIcon"></fa-icon>
        <strong #ALERT_TITLE id="ALERT_TITLE" class="ms-2"></strong>
        <p #ALERT_MSG id="ALERT_MSG" class="ms-2" [ngClass]="errorCustomClass"></p>
        <fa-icon *ngIf="type == 'INFO'" [icon]="faCircleNotch" class="ms-2" [spin]="true"></fa-icon>
    </div>
    <div *ngIf="uploadLoaded != 0 && uploadTotal != 0">
        <hr style="margin-top: 12px; margin-bottom: 12px;">
        <ngb-progressbar type="info" [value]="uploadLoaded" [max]="uploadTotal" height="15px" [striped]="true" [showValue]="true"></ngb-progressbar>
    </div>
</div>
