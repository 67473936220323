import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert.service';
import { PaymentService } from '../payment.service';
import { SettingService } from '../setting.service';
import { SquarePaymentService } from '../square-payment.service';

@Component({
  selector: 'app-square-payment-form',
  templateUrl: './square-payment-form.component.html',
  styleUrls: ['./square-payment-form.component.css']
})
export class SquarePaymentFormComponent implements OnInit, AfterViewInit {

  // Properties
  @Input() organizationId: string;
  @Input() invoiceId: string;
  @Input() defaultAmount: number;
  @Output() paymentSuccessful: EventEmitter<void> = new EventEmitter<void>();
  squarePaymentFormSettings: { applicationId: string, locationId: string };
  payments: any;
  amountConfirmed: boolean = false;
  amount: string;
  applePayIsAvailable: boolean = false;
  googlePayIsAvailable: boolean = false;
  payWithCardIsActive: boolean = false;
  payWithCardBtnText: string;
  paymentInProgress: boolean = false;

  // Font Awesome Properties
  faCheck = faCheck;

  constructor(private settingService: SettingService,
    private paymentService: PaymentService,
    private squarePaymentService: SquarePaymentService,
    private alertService: AlertService) { }

  async ngOnInit(): Promise<void> {
    this.amount = this.defaultAmount.toFixed(2);
  }

  async ngAfterViewInit(): Promise<void> {
    await this.loadSquareScript();
    await this.initializePayments();
  }

  // Load Square Script
  private loadSquareScript(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = environment.square.webSdkUrl;
      script.async = true;
      script.defer = true;
      script.addEventListener('load', () => {
        resolve();
      });
      script.addEventListener('error', (error) => {
        reject(error);
      });
      document.body.appendChild(script);
    });
  }

  // Initialize Payments
  private async initializePayments(): Promise<void> {
    this.squarePaymentFormSettings = await lastValueFrom(this.settingService.getSquareFormSettings(this.organizationId));
    this.payments = (window as any).Square.payments(this.squarePaymentFormSettings.applicationId, this.squarePaymentFormSettings.locationId);
  }

  // Confirm Amount
  async confirmAmount(): Promise<void> {
    this.amountConfirmed = true;
    this.amount = (document.getElementById('AMOUNT') as HTMLInputElement).value;

    // Apple Pay
    try {
      this.applePayIsAvailable = true;
      const applePay = await this.squarePaymentService.initializeApplePay(this.payments, this.amount, 'Total');
      (document.getElementById('apple-pay-button') as HTMLButtonElement).addEventListener('click', async (event) => {
        await this.handlePaymentMethodSubmission(event, applePay);
      });
    } catch (error) {
      console.error('Apple Pay Unavailable');
      console.error(error);
      this.applePayIsAvailable = false;
    }

    // Google Pay
    try {
      this.googlePayIsAvailable = true;
      const googlePay = await this.squarePaymentService.initializeGooglePay(this.payments, this.amount, 'Total');
      (document.getElementById('google-pay-button') as HTMLButtonElement).addEventListener('click', async (event) => {
        await this.handlePaymentMethodSubmission(event, googlePay);
      });
    } catch (error) {
      console.error('Google Pay Unavailable');
      console.error(error);
      this.googlePayIsAvailable = false;
    }
  }

  // Pay With Card
  payWithCard(): void {
    try {
      this.payWithCardBtnText = `Pay $${this.amount}`;
      this.payWithCardIsActive = true;
      setTimeout(async () => {
        const card = await this.squarePaymentService.initializeCard(this.payments);
        (document.getElementById('card-button') as HTMLButtonElement).addEventListener('click', async (event) => {
          await this.handlePaymentMethodSubmission(event, card);
        });
      }, 500);
    } catch (error) {
      console.error(error);
    }
  }

  // Handle Payment Method Submission
  private async handlePaymentMethodSubmission(event: Event, paymentMethod: any): Promise<void> {
    try {
      this.paymentInProgress = true;
      this.payWithCardBtnText = 'Processing...';
      event.preventDefault();
      const result = await paymentMethod.tokenize();
      if (result.status !== 'OK') {
        if (result.status === 'Invalid') throw new Error('Form is invalid.');
        if (result.status === 'Cancel') throw new Error('Payment Canceled');
        else throw new Error('Tokenization Error');
      }
      await this.processPayment(result.token);
      this.alertService.showSuccessAlert('Payment Successful');
      this.paymentSuccessful.emit();
    } catch (error) {
      this.paymentInProgress = false;
      this.payWithCardBtnText = `Pay $${this.amount}`;
      if (!(error instanceof HttpErrorResponse)) {
        this.alertService.showErrorAlert(error.message);
        console.error(error);
      }
    }
  }

  // Process Payment
  private async processPayment(token: string): Promise<void> {
    const data: any = {
      locationId: this.squarePaymentFormSettings.locationId,
      sourceId: token,
      amount: this.amount,
      organizationId: this.organizationId,
      invoiceId: this.invoiceId
    };
    await lastValueFrom(this.paymentService.processSquarePayment(data));
  }
}
