import { Component, Input, OnInit, HostListener } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Project } from '../project';
import { ProjectService } from '../project.service';
import { Invoice } from '../invoice';
import { InvoiceService } from '../invoice.service';
import { AlertService } from '../alert.service';
import { NgbModal, NgbModalRef, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ProposalService } from '../proposal.service';
import { faArrowRight, faCalendar, faTriangleExclamation, faPlus, faSortAmountDownAlt, faSortUp, faSortDown, faFilter } from '@fortawesome/free-solid-svg-icons';
import { Subject, combineLatest, debounceTime } from 'rxjs';
import { AcceptedProposal } from '../accepted-proposal';
import { InvoiceStatusPipe } from '../invoice-status.pipe';
import { TableControlService } from '../table-control.service';

@Component({
  selector: 'app-project-invoices',
  templateUrl: './project-invoices.component.html',
  styleUrls: ['./project-invoices.component.css'],
  providers: [InvoiceStatusPipe]
})
export class ProjectInvoicesComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Properties
  private modalReference: NgbModalRef;

  private _project: Project;
  @Input()
  set project(value: Project) {
    this._project = value;
  }
  get project(): Project {
    return this._project;
  }

  invoiceAmount: number = 0;
  invoices: Invoice[] = [];
  acceptedProposal: AcceptedProposal = null;
  invoiceForm: UntypedFormGroup;
  remainingBalance: number;
  isMobileView: boolean = false;

  sortOptions = [
    { label: 'Invoice #', value: 'i.invoice_number' },
    { label: 'Amount', value: 'i.invoice_amount' },
    { label: 'Status', value: 'i.invoice_status' },
    { label: 'Issue Date', value: 'i.invoice_issue_date' },
    { label: 'Due Date', value: 'i.invoice_due_date' },
  ];
  filterColumns = [
    { label: 'Status', value: 'i.invoice_status' },
  ];
  sortBy: string = 'i.invoice_number';
  sortDirection: string = 'ASC';

   // Filters
   columnFilterValues = new Map();
   activeFilters = new Map();
   activeFilterCount: number = 0;
   selectedFilterColumn: string ='';

  // Table Controls
  tableControls: TableControlService;
  storedSearchValue: string;

  // Font Awesome Properties
  faCalendar = faCalendar;
  faTriangleExclamation = faTriangleExclamation;
  faPlus = faPlus;
  faArrowRight = faArrowRight;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private projectService: ProjectService,
    private invoiceService: InvoiceService,
    private proposalService: ProposalService,
    private calendar: NgbCalendar,
    private modalService: NgbModal,
    private alertService: AlertService,
    private dateParserFormatter: NgbDateParserFormatter,
    private invoiceStatusPipe: InvoiceStatusPipe) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
        }
      });
    }

  ngOnInit(): void {
    this.tableControls = new TableControlService('Project.Invoices', true, 'i.invoice_number', 'DESC');
    this.invoiceForm = new UntypedFormGroup({
      balanceType: new UntypedFormControl('PERCENT'),
      balance: new UntypedFormControl(50, Validators.required),
      issueDate: new UntypedFormControl(this.calendar.getToday()),
      dueDate: new UntypedFormControl(this.calendar.getNext(this.calendar.getToday(), 'd', 7))
    });
    this.getAcceptedProposalForProject();
    this.tableControls.refresh.subscribe(() => {
      this.getInvoicesForProject();
    });
    this.getInvoicesForProject();
    this.listenForBalanceChanges();
    this.listenForIssueDateChanges();
    this.checkScreenSize();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Modal
  openModal(content: any): void {
    this.modalReference = this.modalService.open(content);
  }

  // Get Accepted Proposal For Project
  private getAcceptedProposalForProject(): void {
    this.proposalService.getAcceptedProposalForProject(this.project.id).subscribe((acceptedProposal) => {
      this.acceptedProposal = acceptedProposal;
    });
  }

  // Get Invoices For Project
  private getInvoicesForProject(): void {
    let params = this.tableControls.getParams();
    params['filter:proj.project_id'] = JSON.stringify([this.project.id]);

    // const params = {
    //   searchTerm: null,
    //   sortBy: 'i.invoice_number',
    //   sortDirection: 'DESC',
    //   limit: null,
    //   offset: null,
    //   'filter:proj.project_id': JSON.stringify([this.project.id])
    // };
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
      for (const [key, value] of this.activeFilters) {
        if (key.includes('range')) params[`filter:${key}`] = value;
        else params[`filter:${key}`] = JSON.stringify(value);
      }
    };
    this.invoiceService.getInvoices(params).subscribe((res) => {
      this.invoices = res.invoices;
    });
  };

  // Add Invoice
  addInvoice(): void {
    if (this.invoiceForm.valid) {
      const invoice = {
        projectId: this.acceptedProposal.projectId,
        balanceType: this.invoiceForm.controls['balanceType'].value,
        balance: this.invoiceForm.controls['balance'].value || this.invoiceAmount,
        issueDate: this.dateParserFormatter.format(this.invoiceForm.controls['issueDate'].value),
        dueDate: this.dateParserFormatter.format(this.invoiceForm.controls['dueDate'].value)
      };
      this.invoiceService.addInvoice(invoice).subscribe(() => {
        this.alertService.showSuccessAlert('Invoice Added');
        this.modalReference.close();
        this.getInvoicesForProject();
        this.resetInvoiceForm();
      });
    } else {
      this.invoiceForm.markAllAsTouched();
    }
  }

  // Reset Invoice Form
  resetInvoiceForm(): void {
    this.invoiceForm.reset();
    this.invoiceForm.controls.balanceType.setValue('PERCENT');
    // this.invoiceForm.controls.balance.setValue(50);
    this.generateBalance();
    this.invoiceForm.controls.issueDate.setValue(this.calendar.getToday());
    this.invoiceForm.controls.dueDate.setValue(this.calendar.getNext(this.calendar.getToday(), 'd', 7));
  }

  // Listen For Balance Changes
  private listenForBalanceChanges(): void {
    this.invoiceForm.controls.balanceType.valueChanges.subscribe((value) => {
      if (value == 'DOLLAR') {
        this.invoiceAmount = this.remainingBalance ? this.remainingBalance : (this.invoiceAmount ? this.invoiceAmount : parseFloat((this.acceptedProposal.total / 2).toFixed(2)));
        this.invoiceForm.controls.balance.setValue(this.invoiceAmount ? this.invoiceAmount : (this.acceptedProposal.total / 2).toFixed(2));
      } else {
        this.invoiceAmount = this.remainingBalance ? this.remainingBalance : (this.invoiceAmount ? this.invoiceAmount :
          parseFloat((this.acceptedProposal.total / 2).toFixed(2)));
        this.invoiceForm.controls.balance.setValue(((this.invoiceAmount * 100) / this.acceptedProposal.total).toFixed(2))
      }
    });

    this.invoiceForm.controls.balance.valueChanges.subscribe((value) => {
      const balanceType = this.invoiceForm.controls.balanceType.value;

      if (balanceType == 'DOLLAR') {
        this.invoiceAmount = value;
      } else {
        this.invoiceAmount = parseFloat((this.acceptedProposal.total * (value / 100)).toFixed(2))
      }
    });
  }

  // Listen For Issue Date Changes
  private listenForIssueDateChanges(): void {
    this.invoiceForm.controls.issueDate.valueChanges.subscribe((issueDate) => {
      if (issueDate !== null) this.invoiceForm.controls.dueDate.setValue(this.calendar.getNext(issueDate, 'd', 7));
    });
  }

  generateBalance() {
    const balanceType = this.invoiceForm.controls.balanceType.value;
    const paidAndPendingInvoices = this.invoices.filter(invoice => (invoice.status != 'CANCELED'));

    const totalInvoicesBalance = this.invoices.length == 0 ? 0 : this.summ(paidAndPendingInvoices, 'amount');
    const balancePercentage = (totalInvoicesBalance / this.acceptedProposal.total) * 100;

    this.remainingBalance = this.acceptedProposal.total - totalInvoicesBalance;
    this.invoiceAmount = this.remainingBalance;

    if (balanceType == 'PERCENT') {
      this.invoiceForm.controls.balance.setValue(((this.remainingBalance * 100) / this.acceptedProposal.total).toFixed(2))
    } else if (balanceType == 'DOLLAR') {
      this.invoiceForm.controls.balance.setValue(Math.round((this.remainingBalance + Number.EPSILON) * 100) / 100)// for rounding 2 decimal places
    }

    this.invoiceForm.controls.balance.disable();
  }

  unlockBalance() {
    this.invoiceForm.controls.balance.enable();
    this.remainingBalance = null;
  }

  summ = (arr: any[], property: string) => arr.reduce(function (acc, obj) { return acc + obj[property]; }, 0);


  // Update Project Stage
  updateProjectStage(): void {
    this.projectService.updateProjectStage(this.project.id, 'PREPARATION').subscribe(() => {
      this.projectService.projectUpdated.emit();
    });
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }

  /* ----- Filters and Sorting ----- */
  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    this.selectedFilterColumn = column;
    if (!this.columnFilterValues.has(column)) {
      const params = {
        searchTerm: null,
        sortBy: column,
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        column: column
      };
      if(this.isMobile) {
        for (const [key, value] of this.activeFilters) {
          if (key.includes('range')) params[`filter:${key}`] = value;
          else params[`filter:${key}`] = JSON.stringify(value);
        }
      }
      this.invoiceService.getDistinctColumnValues(params).subscribe((values) => {
        const tempArray = [];
        for (const value of values) {
          let name = null;
          switch (column) {
            case 'i.invoice_status':
              name = this.invoiceStatusPipe.transform(value);
              break;
            default:
              name = (value === null || value.length == 0) ? 'BLANK' : value;
              break;
          }
          tempArray.push({ name: name, value: value, randomId: Math.random().toString(36) });
        }
        this.columnFilterValues.set(column, tempArray);
      });
    }
  }

  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getInvoicesForProject();
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getInvoicesForProject();
    this.getActiveFilterCount();
  }

   // Get Active Filter Count
   getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getInvoicesForProject();
    this.getActiveFilterCount();
  }

  // Show Column Filter Popover
  showColumnFilterPopover(popover, column: string): void {
    this.getDistinctColumnValues(column);
    if (popover.isOpen()) {
      popover.close();
    } else {
      setTimeout(() => {
        popover.open({ column });
      }, 250);
    }
  }

  // Invoice Form Accessors
  get balance() { return this.invoiceForm.controls.balance; }
  get issueDate() { return this.invoiceForm.controls.issueDate; }
  get dueDate() { return this.invoiceForm.controls.dueDate; }
}
