import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) { }

  // Upload File Direct To S3
  uploadFileDirectToS3(url: string, file: File | Blob): Observable<HttpEvent<any>> {
    const req = new HttpRequest('PUT', url, file, {
      reportProgress: true
    });
    return this.http.request(req);
  }
}
