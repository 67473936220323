import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clientStatus'
})
export class ClientStatusPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'PROSPECT':
        return 'Prospect';
      case 'WAIT LIST':
        return 'Wait List';
      case 'CUSTOMER':
        return 'Customer';
      case 'INACTIVE':
        return 'Inactive';
      default:
        return 'Unknown';
    }
  }
}
