<div class="card">
    <div class="card-header">
        <h5>Invoices</h5>
    </div>
    <div class="card-body">
        <ul class="list-group scrollable" style="height: 296px;">
            <li *ngFor="let invoice of invoices" class="list-group-item" (click)="openInvoice(invoice);">
                <div class="d-flex">
                    <div class="d-flex flex-column">
                        <strong>{{invoice.subscription.name}}</strong>
                        <small class="text-muted">Billed on {{invoice.createdAt | formatDateTime: true}}</small>
                    </div>
                    <div class="ms-auto my-auto fs-4">
                        <span>${{invoice.total}}</span>
                    </div>
                </div>
            </li>
            <li *ngIf="invoices.length == 0" class="list-group-item">No Invoices</li>
        </ul>
    </div>
</div>