<div>
    <div class="d-flex flex-column">
        <div class="row scrollable" style="max-height: 224px;">
            <div *ngFor="let asset of customAssets; index as i;" class="text-center p-1" [class.col-auto]="customAssets.length === 1" [class.col-6]="customAssets.length > 1" [class.mt-2]="i > 1" style="position: relative;" [ngbTooltip]="asset.name">
                <span class="img-close-circle" style="cursor: pointer;" (click)="openDeleteMockupCustomAssetConfirmationModal(asset.id);">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
                <img [src]="getMockupCustomAssetDirectURL(asset.id)" class="img-thumbnail" width="100" height="100" style="width: 100px; height: 100px; cursor: pointer; object-fit: contain;" (click)="selectImage(asset.id);">
            </div>
        </div>
    </div>
    <button type="button" class="btn btn-primary w-100" [class.mt-2]="customAssets.length > 0" (click)="openFileUploadModal();">
        <fa-icon class="button-icon-left" [icon]="faUpload"></fa-icon>
        <span>Upload</span>
    </button>
</div>