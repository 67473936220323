import { Component, HostListener, OnInit } from '@angular/core';
import { Project } from '../project';
import { ProjectService } from '../project.service';
import { faAngleLeft, faPlus, faSortUp, faSortDown, faFilter, faCircle, faEye, faEyeSlash, faRotateLeft, faFileDownload, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { ProjectStagePipe } from '../project-stage.pipe';
import { Location } from '@angular/common';
import { ProjectStatusPipe } from '../project-status.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewProjectModalComponent } from '../new-project-modal/new-project-modal.component';
import { TableControlService } from '../table-control.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../shared/services/loader.service';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css'],
  providers: [ProjectStagePipe, ProjectStatusPipe]
})
export class ProjectsComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Properties
  projects: Project[] = [];
  projectCount: number = 0;
  isMobileView: boolean = false;

  sortOptions = [
    { label: 'Name', value: 'p.project_name' },
    { label: 'Client', value: 'c.client_name' },
    { label: 'Site', value: 's.site_address_city' },
    { label: 'Category', value: 'p.project_category' },
    { label: 'Stage', value: 'p.project_stage' },
    { label: 'Status', value: 'p.project_status' },
    { label: 'Updated', value: 'p.project_updated_at' },
  ];
  filterColumns = [
    { label: 'Name', value: 'p.project_name' },
    { label: 'Client', value: 'c.client_name' },
    { label: 'Category', value: 'p.project_category' },
    { label: 'Stage', value: 'p.project_stage' },
    { label: 'Status', value: 'p.project_status' },
  ];
  sortBy: string = 'p.project_name';
  sortDirection: string = 'ASC';

  // Actions
  selectedClientId: string;

  // Table Controls
  tableControls: TableControlService;
  storedSearchValue: string;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;
  selectedFilterColumn: string ='';

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faPlus = faPlus;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;
  faCircle = faCircle;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faRotateLeft = faRotateLeft;
  faFileDownload = faFileDownload;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private projectService: ProjectService,
    private projectStagePipe: ProjectStagePipe,
    private projectStatusPipe: ProjectStatusPipe,
    public location: Location,
    private modalService: NgbModal,
    private loaderService: LoaderService) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
          this.tableControls.setLimit(20);
        }
      });
    }

  ngOnInit(): void {
    this.tableControls = new TableControlService('Projects', true, 'p.project_updated_at', 'DESC');
    this.checkScreenSize();
    if(this.isMobile || this.isMobileView) {
      let activeFilters = this.tableControls.getActiveFilters()
      let columns = [];
      for (const [key, value] of activeFilters) {
        columns.push(key)
      }
      this.tableControls.resetFilters(columns)
    }
    this.tableControls.refresh.subscribe(() => {
      this.getProjects();
    });
    if (this.tableControls.hasTableConfiguration()) this.storedSearchValue = this.tableControls.getSearchTerm();
    this.getProjects();
  }

  // Open New Project Modal
  openNewProjectModal(): void {
    this.modalService.open(NewProjectModalComponent);
  }

  // Get Projects
  private getProjects(): void {
    const params = this.tableControls.getParams();
    this.loaderService.showSpinner();
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
      for (const [key, value] of this.activeFilters) {
        if (key.includes('range')) params[`filter:${key}`] = value;
        else params[`filter:${key}`] = JSON.stringify(value);
      }
    };
    this.projectService.getProjects(params).subscribe({
      next: (res) => {
        this.projects = res.projects;
        this.projectCount = res.count;
        this.loaderService.hideSpinner();
      },
      error:() => {
        this.loaderService.hideSpinner();
      }
    });
  }

  // Download Projects
  downloadProjects(): void {
    window.open(`${environment.apiUrl}/projects/export`);
  }

  /* ----- Filters and Sorting ----- */

  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    this.selectedFilterColumn = column;
    if (!this.columnFilterValues.has(column)) {
      const params = {
        searchTerm: null,
        sortBy: column,
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        column: column
      };
      if(this.isMobile) {
        for (const [key, value] of this.activeFilters) {
          if (key.includes('range')) params[`filter:${key}`] = value;
          else params[`filter:${key}`] = JSON.stringify(value);
        }
      }
      this.projectService.getDistinctColumnValues(params).subscribe((values) => {
        const tempArray = [];
        for (let value of values) {
          let name = null;
          switch (column) {
            case 'p.project_stage':
              name = this.projectStagePipe.transform(value);
              break;
            case 'p.project_status':
              name = this.projectStatusPipe.transform(value);
              break;
            default:
              name = (value === null || value.length == 0) ? 'BLANK' : value;
              break;
          }
          tempArray.push({ name: name, value: value, randomId: Math.random().toString(36) });
        }
        this.columnFilterValues.set(column, tempArray);
      });
    }
  }

  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getProjects();
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getProjects();
    this.getActiveFilterCount();
  }

   // Get Active Filter Count
   getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getProjects();
    this.getActiveFilterCount();
  }

  // Show Column Filter Popover
  showColumnFilterPopover(popover, column: string): void {
    this.getDistinctColumnValues(column);
    if (popover.isOpen()) {
      popover.close();
    } else {
      setTimeout(() => {
        popover.open({ column });
      }, 250);
    }
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
    this.isMobileView ? this.tableControls.setLimit(5): this.tableControls.setLimit(20);
  }
}
