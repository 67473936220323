import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private http: HttpClient) { }

  // Get Cash Flow Insights
  getCashFlowInsights(params: any): Observable<any> {
    return this.http.get<any>('/analytics/cashFlowInsights', {
      params: params
    });
  }

  // Get Payment Category Chart Data
  getPaymentCategoryChartData(params: any): Observable<any> {
    return this.http.get<any>(`/analytics/paymentCategoryChartData`, {
      params: params
    });
  }

  // Get Payment Monthly Volume Chart Data
  getPaymentMonthlyVolumeChartData(params: any): Observable<any> {
    return this.http.get<any>(`/analytics/paymentMonthlyVolumeChartData`, {
      params: params
    });
  }
}
