<div class="card" style="height: 100%;">
    <div class="card-header">
        <h5>Map</h5>
        <div *ngIf="selectedDateControl == 'PICKER'" class="ms-auto">
            <app-date-picker-controller #datePickerController componentId="Schedule.Map" preset="CUR_MTH" [showButtons]="true" (dateChanged)="dateChanged($event);" [onLoad]="true"></app-date-picker-controller>
        </div>
    </div>
    <div id="map-container" class="card-body" style="min-height: 644px;">
        <div class="d-flex label-section">
            <div>
                <strong>Date Control</strong>
                <div class="form-check mb-0">
                    <input id="DATE_CTRL_CALENDAR" type="radio" class="form-check-input" [checked]="selectedDateControl == 'CALENDAR'" (change)="selectDateControl('CALENDAR');">
                    <label for="DATE_CTRL_CALENDAR" class="form-check-label">Calendar View</label>
                </div>
                <div class="form-check mb-0">
                    <input id="DATE_CTRL_PICKER" type="radio" class="form-check-input" [checked]="selectedDateControl == 'PICKER'" (change)="selectDateControl('PICKER');">
                    <label for="DATE_CTRL_PICKER" class="form-check-label">Date Picker</label>
                </div>
            </div>
            <div class="ms-3">
                <strong>Scheduled</strong>
                <div class="form-check mb-0">
                    <input id="SCHEDULED_INSTALL" type="checkbox" class="form-check-input" [checked]="isScheduledTypeActive('INSTALL')" (change)="toggleScheduledTypeFilter('INSTALL');">
                    <label for="SCHEDULED_INSTALL" class="form-check-label">Installation</label>
                </div>
                <div class="form-check mb-0">
                    <input id="SCHEDULED_MAINTENANCE" type="checkbox" class="form-check-input" [checked]="isScheduledTypeActive('MAINTENANCE')" (change)="toggleScheduledTypeFilter('MAINTENANCE');">
                    <label for="SCHEDULED_MAINTENANCE" class="form-check-label">Maintenance</label>
                </div>
                <div class="form-check mb-0">
                    <input id="SCHEDULED_REMOVAL" type="checkbox" class="form-check-input" [checked]="isScheduledTypeActive('REMOVE')" (change)="toggleScheduledTypeFilter('REMOVE');">
                    <label for="SCHEDULED_REMOVAL" class="form-check-label">Removal</label>
                </div>
            </div>
            <div class="ms-3">
                <strong>Unscheduled</strong>
                <div class="form-check mb-0">
                    <input id="UNSCHEDULED_INSTALL" type="checkbox" class="form-check-input" [checked]="isUnscheduledTypeFilterActive('INSTALL')" (change)="toggleUnscheduledTypeFilter('INSTALL');">
                    <label for="UNSCHEDULED_INSTALL" class="form-check-label">Installation</label>
                </div>
                <div class="form-check mb-0">
                    <input id="UNSCHEDULED_MAINTENANCE" type="checkbox" class="form-check-input" [checked]="isUnscheduledTypeFilterActive('MAINTENANCE')" (change)="toggleUnscheduledTypeFilter('MAINTENANCE');">
                    <label for="UNSCHEDULED_MAINTENANCE" class="form-check-label">Maintenance</label>
                </div>
                <div class="form-check mb-0">
                    <input id="UNSCHEDULED_REMOVAL" type="checkbox" class="form-check-input" [checked]="isUnscheduledTypeFilterActive('REMOVE')" (change)="toggleUnscheduledTypeFilter('REMOVE');">
                    <label for="UNSCHEDULED_REMOVAL" class="form-check-label">Removal</label>
                </div>
            </div>
        </div>
        <div class="mt-3" style="width: 100%; height: calc(100% - 112px);">
            <div id="map" style="width: 100%; height: 100%;"></div>
        </div>
    </div>
</div>

<!-- Schedule Item Cluster List -->
<ng-template #SCHEDULE_ITEM_CLUSTER_LIST let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Schedule Item Cluster List</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
    </div>
    <div class="modal-body">
        <ul class="list-group">
            <li *ngFor="let scheduleItem of selectedClusterScheduleItems" class="list-group-item" (click)="openScheduleItemModal(scheduleItem);">
                <div class="d-flex">
                    <fa-icon *ngIf="scheduleItem.status == 'UNSCHEDULED'" [icon]="faExclamationCircle" class="my-auto" [style.color]="scheduleItem.countyColor"></fa-icon>
                    <fa-icon *ngIf="scheduleItem.status == 'SCHEDULED'" [icon]="getScheduleItemTypeIcon(scheduleItem.type)" class="my-auto" [style.color]="scheduleItem.crews && scheduleItem.crews.length > 0 ?  scheduleItem.crews[0].crewColor:'#000000'"></fa-icon>
                    <div class="ms-2">{{scheduleItem.clientName}}</div>
                </div>
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
    </div>
</ng-template>

<!-- LOADER -->
<ngx-spinner>
  <p class="loading-spinner">Loading...</p>
</ngx-spinner>
