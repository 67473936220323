import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormBuilder } from '@angular/forms';
import { faBold, faPhone, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { from, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map,switchMap } from 'rxjs/operators';
import { ContactsSerivce } from 'src/app/contacts.service';
import { CallService } from 'src/app/call.service';
import { TableControlService } from 'src/app/table-control.service';
import { PhoneMaskDirective } from '../../../phone-mask.directive'

@Component({
  selector: 'app-call-dialer-dialog',
  templateUrl: './call-dialer-dialog.component.html',
  styleUrls: ['./call-dialer-dialog.component.css']
})
export class CallDialerDialogComponent implements OnInit {
  @ViewChild(PhoneMaskDirective) phoneMaskDirective:PhoneMaskDirective;
  componentDestroyed$: Subject<boolean> = new Subject()

  // Properties
  sendSmsForm: UntypedFormGroup;
  // Font Awesome Properties
  faPhone = faPhone;
  contact: any = null;
  phoneNumber: any;
  contacts: any[] = [];

  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  @ViewChild('instance') instance: NgbTypeahead;

  @Output() call: EventEmitter<any> = new EventEmitter<any>();
  @Input() passedContact: any = null;

  constructor(
    private formBuilder: FormBuilder,
    private ContactsService: ContactsSerivce,
    private modalService: NgbModal,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    let contactsParam = new TableControlService('Contacts', true, 'c.contact_last_name', 'DESC');
    this.getContacts(contactsParam);

    this.sendSmsForm = this.formBuilder.group({
      number: [''],
    });

    if (this.passedContact) {
      this.contact = this.passedContact;
      this.sendSmsForm.controls['number'].setValue(this.passedContact.phone);
    }
  }

  sendSms() {
    if (this.sendSmsForm.valid) {
      this.modal.close()
    }
  }

  inputValue(data: any) {
    let number = this.sendSmsForm.controls["number"].value;
    this.sendSmsForm.controls['number'].setValue(number + data.toString());
  }

  private async getContacts(table: any): Promise<any> {
    const params = table.getParams();
    return new Promise((resolve, reject) => {
      this.ContactsService.getContacts(params).subscribe(
        (res) => {
          this.contacts = res.contacts;
          resolve(this.contacts);
        },
        (error) => reject(error)
      );
    });
  }

  search = (text$: Observable<string>) => {

    let params = new TableControlService('Contacts', false, 'c.contact_last_name', 'DESC');
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => this.instance && !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      switchMap((term: string) => {
        params['searchTerm'] = term;

        if (term === '') {
          return of(this.contacts); // convert array to observable using of()
        } else {
          // Convert array returned by promise getContacts to observable using from()
          return from(this.getContacts(params)).pipe(
            map((contacts) => {
              const formattedList = contacts.filter((v) => {
                let phone = v['phone'];
                if(this.isFormatted(v['phone'])) {
                  phone = v['phone']?.replace(/\D/g, ''); // remove phone format
                }
                return (
                  phone?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                  v['firstName']?.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                  v['lastName'].toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                  v['clientName']?.toLowerCase().indexOf(term.toLowerCase()) > -1
                );
              }).slice(0, 10);

              return formattedList;
            })
          );
        }
      })
    );
  };

  isFormatted(phone) {
    // Check if the phone number contains non-digit characters
    const nonDigitPattern = /\D/;
    return nonDigitPattern.test(phone);
  }


  formatter = (result: any) => `${result.firstName} ${result.lastName} - ${result.phone} (${result.clientName})`;

  selectContact(event: NgbTypeaheadSelectItemEvent): void {
    if (event) event.preventDefault();
    this.sendSmsForm.controls['number'].setValue(event.item?.phone);
    this.contact = event.item;
  }

  async callNumber() {
    if (this.sendSmsForm.valid) {
      const number = this.sendSmsForm.controls['number'].value;
      const formattedNumber = Number(number.replace(/\D/g, ''))
      console.log(formattedNumber)
      let data = {
        contact: this.contact,
        number
      }

      this.call.emit(data);
      this.modal.close();
    }
  }
}
