<div class="container my-auto">
    <div class="row">
        <div class="col-md-6 d-flex">
            <div class="m-auto">
                <picture>
                    <source type="image/webp" srcset="../assets/TinselLogo.webp">
                    <source type="image/png" srcset="../assets/TinselLogo.png">
                    <img src="../assets/TinselLogo.png" class="img-fluid" alt="Tinsel Logo" loading="eager">
                </picture>
            </div>
        </div>
        <div class="col-md-6 d-flex">
            <div class="m-auto">
                <h1>Page Not Found</h1>
                <p class="mt-2">The page that you requested cannot be found. Please check the URL and try again.</p>
            </div>
        </div>
    </div>
</div>