import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormBuilder } from '@angular/forms';
import { faBold, faTimes, faRotateRight, faQuestionCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import Stepper from 'bs-stepper';

@Component({
  selector: 'app-a2p-modal',
  templateUrl: './a2p-modal.component.html',
  styleUrls: ['./a2p-modal.component.css']
})
export class A2pModalComponent implements OnInit {

  a2pForm: UntypedFormGroup;
  // Font Awesome Properties
  faTimes = faTimes;
  faBold = faBold;
  faCheck = faCheck;
  faQuestionCircle = faQuestionCircle;
  loading:boolean = false;
  private stepper: Stepper;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    })
    this.a2pForm = this.formBuilder.group({
      // number: [this.phoneNumber ? this.phoneNumber : ''],
      // body: ['']
    });
  }

  close(){
    this.modal.close();
  }

}
