import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../alert.service';
import { SmsCallService } from '../sms-call.service';

@Component({
  selector: 'app-business-address-form',
  templateUrl: './business-address-form.component.html',
  styleUrls: ['./business-address-form.component.css']
})
export class BusinessAddressFormComponent implements OnInit{

  businessAddressForm!: FormGroup;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private smsCallService: SmsCallService) { }

  ngOnInit(): void {
    this.businessAddressForm = this.formBuilder.group({
      city: [null, Validators.required],
      customer_name: [null, Validators.required],
      iso_country: [null, Validators.required],
      postal_code: [null, Validators.required],
      region: [null],
      street: [null, Validators.required],
      street_secondary: [null]
    });
  }

  submitForm(){
    if(this.businessAddressForm.valid){
      let data = {...this.businessAddressForm.value}
      this.smsCallService.postBusinessAddress(data).subscribe(() => {
        this.alertService.showSuccessAlert('Business details saved!');
      });
    }
  }

  closeModal(){
    this.close.emit("true")
  }

}
