<div class="card">
    <div class="card-header d-flex">
        <h5 class="card-title my-auto mb-auto">Mockups ({{mockups.length}})</h5>
        <a href="https://support.tinselcrm.com/knowledge-base-category/mockup-tool/" target="_blank" class="btn btn-primary ms-auto" ngbTooltip="Help" aria-label="Help">
            <fa-icon [icon]="faQuestion"></fa-icon>
        </a>
    </div>
    <div class="card-body">
        <ul class="list-group scrollable" style="height: 227px;">
            <li *ngFor="let mockup of mockups" class="list-group-item" style="cursor: pointer;" (click)="$event.stopPropagation(); navigateToMockupTool(mockup.id);">
                <div class="d-flex flex-md-row flex-column">
                    <div class="d-flex flex-column my-auto">
                        <div><strong>{{mockup.name}}</strong></div>
                        <small class="text-muted">Updated: {{mockup.updatedAt | formatDateTime}}</small>
                    </div>
                    <div class="ms-md-auto justify-content-around d-flex">
                        <button class="btn btn-danger my-auto" type="button" (click)="$event.stopPropagation(); openDeleteMockupConfirmationModal(mockup.id);" ngbTooltip="Delete">
                            <fa-icon [icon]="faTrashCan"></fa-icon>
                        </button>
                    </div>
                </div>
            </li>
            <li *ngIf="mockups.length == 0" class="list-group-item">No Mockups</li>
        </ul>
    </div>
</div>
