<div class="modal-header">
    <div>
        <h5 class="modal-title">Change in Proposal Amount</h5>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <div class="row mt-2 align-items-center">
        <div class="col-md-10 mx-auto text-center">
            <p> Amount on Invoice # {{ invoiceNumber }} is greater than the total Proposal Amount. Adjust the invoice, or issue a refund.  </p>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submit(1)">Go To Invoice</button>
    <button type="button" class="btn btn-secondary" (click)="submit(0)">Ignore</button>
</div>