import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faCheck, faClose, faEllipsis, faGear, faPlus, faQuestion, faQuestionCircle, faSave, faToggleOff, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from '../../alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
// import { A2pModalComponent } from '../a2p-modal/a2p-modal.component';
// import { NumberConfigurationModalComponent } from '../number-configuration-modal/number-configuration-modal.component';
// import { SmsCallSettingsService } from '../sms-call-settings.service';
// import { SmsCallTermsModalComponent } from '../sms-call-terms-modal/sms-call-terms-modal.component';
import Stepper from 'bs-stepper';
// import { NameDialogComponent } from '../name-dialog/name-dialog.component';
// import { SmsCallRegistrationComponent } from '../sms-call-registration/sms-call-registration.component';
import { SmsCallService } from '../sms-call.service';
import { CampaignDetails, OrgNumber } from '../sms-call';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { BuyNumberModalComponent } from '../modals/buy-number-modal/buy-number-modal.component';
import { CampaignModalComponent } from '../modals/campaign-modal/campaign-modal.component';
import { SmsCallTermsModalComponent } from '../modals/sms-call-terms-modal/sms-call-terms-modal.component';
import { SmsCallFilterModalComponent } from 'src/app/shared/modals/sms-call-filter-modal/sms-call-filter-modal.component';

@Component({
  selector: 'app-settings-sms-call',
  templateUrl: './settings-sms-call.component.html',
  styleUrls: ['./settings-sms-call.component.css']
})
export class SettingsSmsCallComponent implements OnInit, OnDestroy {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  private stepper: Stepper;
  // @ViewChild(SmsCallRegistrationComponent) smsCallRegistrationComponent: SmsCallRegistrationComponent;
  subs = new Subscription()

  orgNumbers: OrgNumber[] = [];
  campaigns: CampaignDetails[] = [];
  phoneNumbers = [];
  smsCallStatus = null;
  isMobileView: boolean = false;

  // Font Awesome Properties
  faTrash = faTrash;
  faEllipsis = faEllipsis;
  faCheck = faCheck;
  faPlus = faPlus;
  faClose = faClose;
  faGear = faGear;
  faSave = faSave;
  faToggleOn = faToggleOn;
  faToggleOff = faToggleOff;
  faQuestionCircle = faQuestionCircle;
  faQuestion = faQuestion;

  fileObject: any = {
    filename: '',
    size: '',
    type: '',
    file: ''
  }

  uploadedFile: any;
  uploadedFileBase64: any;
  numberNameForm!: FormGroup;
  subAccountDetails: any
  isSmsSettingsEnabled!: boolean;
  isCallRecordingSettingsEnabled!: boolean;

   // Get isMobile value in reactive way
   public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(
    // private smsCallSettingService: SmsCallSettingsService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private smsCallService: SmsCallService
  ) {
    this.resizeSubject.pipe(
      debounceTime(100)
    ).subscribe(width => {
      if (width <= 768) {
        this.checkScreenSize(width);
      } else {
        this.isMobileView = false;
      }
    });
   }

  ngOnInit(): void {
    this.numberNameForm = this.formBuilder.group({
      numberName: ['', Validators.required],
    });
    this.checkScreenSize();
    this.getSettings();
  }

  navigateSettingsStepper(step: number) {
    // this.smsCallRegistrationComponent.navigateStepper(step);
  }

  popOverOptions(popover: NgbPopover, num: any): void {
    if (popover.isOpen()) popover.close();
    else popover.open({ popover, num });
  }

  getSettings() {
    this.subs.add(
      this.smsCallService.getSmsCallSettings().subscribe((data: any) => {
        this.subAccountDetails = data
        this.isCallRecordingSettingsEnabled = data.isRecordingCall
        console.log(this.subAccountDetails)
        this.isSmsSettingsEnabled = data ? data.isActive : false

        if(data?.isActive && (data.isA2pRegistered || data.isPendingReview)) {
          this.smsCallStatus = 'onSetting';
          this.getOrgNumbers()
        }

        if (this.isSmsSettingsEnabled && this.subAccountDetails.subAccountId && !data.isA2pRegistered && !data.isPendingReview) {
          this.smsCallStatus = 'onRegistration'
          this.getOrgNumbers()
          // this.phoneNumbers = data.numbers;
          // this.navigateStepper();
        }
      })
    )
  }

  toggleEnableSms() {
    const modalRef = this.modalService.open(SmsCallTermsModalComponent);
    modalRef.componentInstance.switchedOn = !this.isSmsSettingsEnabled;
    modalRef.componentInstance.agree.subscribe((isAgreed) => {
      console.log(isAgreed)
      this.isSmsSettingsEnabled = !this.isSmsSettingsEnabled;
      this.subs.add(
        this.smsCallService.changeAccStatus(this.isSmsSettingsEnabled)
          .subscribe(() => {
            this.getSettings()
          })
      )
    });
  }

  setUpSmsCall() {
    // this.smsCallSettingService.initiateA2p().subscribe((data: any) => {

    // });
  }

  openTermsAndConditionModal() {

  }

  // openTwiMLRegistrationModal() {
  //   const modalRef = this.modalService.open(NameDialogComponent);
  //   modalRef.componentInstance.saved.subscribe((data: any) => {
  //     this.smsCallSettingService.submitTwiML(data).subscribe(() => {
  //       this.alertService.showSuccessAlert('TwiML registration submitted!');
  //     });
  //   });
  // }

  onFileChangeEvent(event: any, dropped = false): void {
    const uploaded = dropped ? event : event.target.files;
    if (uploaded.length !== 0) {
      this.uploadedFile = uploaded[0];
      this.fileObject.filename = uploaded[0].name;
      this.fileObject.type = uploaded[0].type;
      this.fileObject.size = uploaded[0].size
      const reader = new FileReader();
      reader.readAsDataURL(uploaded[0]);
      reader.onload = (_event) => {
        this.uploadedFileBase64 = reader.result;
        this.fileObject.file = reader.result;
      }
    }
  }

  saveBrandName() {
    // if (this.numberNameForm.valid) {
    //   this.smsCallSettingService.getAddMessagingService(this.numberNameForm.controls['numberName'].value).subscribe(() => {
    //     this.alertService.showSuccessAlert('Brand name saved!');
    //   });
    // }
  }

  openBuyNumber() {
    const modaNumberRef = this.modalService.open(BuyNumberModalComponent, { size: 'lg' });
    modaNumberRef.componentInstance.buyNumber.subscribe(res => {
      if (res) this.alertService.showSuccessAlert('Successfully bought a number');
      // TODO emit to refresh
    })
  }

  addACampaign() {
    let numbers = [];
    if (this.orgNumbers && this.orgNumbers.length != 0) {
      this.orgNumbers.forEach((number, index) => {
        numbers.push({
          id: index + 1,
          text: number.phoneNumber,
          selected: false
        })
      })
    }
    const modalNumberRef = this.modalService.open(CampaignModalComponent, { size: 'lg' });
    modalNumberRef.componentInstance.numbers = numbers;
    modalNumberRef.componentInstance.saveCampaign.subscribe(res => {
      if (res) {
        this.subs.add(
          this.smsCallService.createServiceAndCampaign(res)
            .pipe().subscribe(response => {
              console.log(response)
            })
        )
      }
    })
  }

  exportLogs() {
    // TODO logs
  }

  getOrgNumbers() {
    this.subs.add(
      this.smsCallService.getOrgNumbers()
        .pipe().subscribe(res => {
          this.orgNumbers = res
        })
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  openFilterModal() {
    const modalRef = this.modalService.open(SmsCallFilterModalComponent);
    modalRef.componentInstance.title = "Message History and Logs";
    modalRef.componentInstance.isSms = true;

    modalRef.componentInstance.filtersApplied.subscribe((filters: any) => {
      this.onFiltersApplied(filters);
    });
  }

  onFiltersApplied(filters: any) {
    let year = filters?.dateSent?.year ? filters?.dateSent?.year : null;
    let month = filters?.dateSent?.month ? this.formatDayAndMonth(filters?.dateSent?.month) : null;
    let day = filters?.dateSent?.day ? this.formatDayAndMonth(filters?.dateSent?.day) : null;
    let requestBody = {
      dateSent: year && month && day ? new Date(`${year}-${month}-${day} 00:00:00`) : null,
      from: filters.fromContact || null,
      to: filters.toContact || null,
      limit: null
    };

    console.log(requestBody)
    this.smsCallService.exportMessageHistoryAndLogs(requestBody);
  }

  formatDayAndMonth(number: number) {
    return  number <= 9 ? `0${number}`: `${number}`;
  }

  toggleEnableCallRecording() {
    this.isCallRecordingSettingsEnabled = !this.isCallRecordingSettingsEnabled;
    this.smsCallService.callRecordingEnableDisable(this.isCallRecordingSettingsEnabled)
      .subscribe(() => {
        this.alertService.showSuccessAlert("Successfully updated Settings")
        this.getSettings()
      });
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }
}
