<div class="modal-header">
    <h5 class="modal-title">All Proposals Rejected</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
    <p class="mb-2">All proposals for the project have been rejected. Would you like to:</p>
    <form [formGroup]="proposalRejectedModalForm">
        <div class="mb-2">
            <label>Update Project status to LOST?</label>
            <div>
                <div class="form-check form-check-inline">
                    <input id="UPDATE_PROJECT_STATUS_YES" class="form-check-input" type="radio"
                        formControlName="updateProjectStatus" [value]="true">
                    <label for="UPDATE_PROJECT_STATUS_YES" class="form-check-label"
                        style="font-weight: normal;">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                    <input id="UPDATE_PROJECT_STATUS_NO" class="form-check-input" type="radio"
                        formControlName="updateProjectStatus" [value]="false">
                    <label for="UPDATE_PROJECT_STATUS_NO" class="form-check-label"
                        style="font-weight: normal;">No</label>
                </div>
            </div>
        </div>
        <div>
            <label>Update Client status to INACTIVE?</label>
            <div>
                <div class="form-check form-check-inline">
                    <input id="UPDATE_CLIENT_STATUS_YES" class="form-check-input" type="radio"
                        formControlName="updateClientStatus" [value]="true">
                    <label for="UPDATE_CLIENT_STATUS_YES" class="form-check-label"
                        style="font-weight: normal;">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                    <input id="UPDATE_CLIENT_STATUS_NO" class="form-check-input" type="radio"
                        formControlName="updateClientStatus" [value]="false">
                    <label for="UPDATE_CLIENT_STATUS_NO" class="form-check-label"
                        style="font-weight: normal;">No</label>
                </div>
            </div>
        </div>
        <div class="row mt-2"
            *ngIf="proposalRejectedModalForm.controls.updateClientStatus.value && projects && projects.length > 0">
            <label class="p-0">This client has another project In Progress. Are you sure you want to mark them as
                Inactive?</label>
            <ul class="mt-2">
                <li *ngFor="let proj of projects">
                    {{ proj.name }}
                </li>
            </ul>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onSubmit()">Save</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
</div>