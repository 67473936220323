import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth.service';
import { User } from '../user';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, AfterViewInit {

  // Properties
  version: string = environment.version;
  @ViewChild('nav') private nav: NgbNav;
  user: User;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;

  constructor(
    public location: Location,
    private authService: AuthService
  ) { }

  async ngOnInit(): Promise<void> {
    this.user = JSON.parse(localStorage.getItem('login'))
  }

  ngAfterViewInit(): void {
    this.nav.activeIdChange.subscribe((activeId) => {
      sessionStorage.setItem('settingsNavActiveId', activeId);
    });
  }

  // Get Current Tab
  getCurrentTab(): string {
    const activeId = sessionStorage.getItem('settingsNavActiveId');
    return (activeId === undefined) ? 'ORGANIZATION' : activeId;
  }
}
