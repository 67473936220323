import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Client } from '../client';
import { Contact } from '../contact';
import { Project } from '../project';
import { Proposal } from '../proposal';
import { ProposalService } from '../proposal.service';
import { Feature } from '../feature';
import { ProjectFile } from '../project-file';
import { NgbDateParserFormatter, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { faPrint, faCheck, faCircleNotch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AcceptProposalTerm } from '../accept-proposal-term';
import { Site } from '../site';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Address } from 'cluster';
import { ProposalPricing } from '../proposal-pricing';
import { AcceptedProposal } from '../accepted-proposal';
import { SettingService } from '../setting.service';
import { FeatureItem } from '../feature-item';
import { AlertService } from '../alert.service';
import { MediaLightboxComponent } from '../media-lightbox/media-lightbox.component';
import { FeatureItemService } from '../feature-item.service';
import { EmailService } from '../email.service';

@Component({
  selector: 'app-proposal-view',
  templateUrl: './proposal-view.component.html',
  styleUrls: ['./proposal-view.component.css']
})
export class ProposalViewComponent implements OnInit {

  // Preferences
  preferredInstallation: boolean = false;
  preferredInstallationOptions: { date: string, label: string }[] = [];
  preferredRemoval: boolean = false;

  // Modals
  private modalReference: NgbModalRef;
  @ViewChild('FOLLOW_UP_QUESTIONS_MODAL') private followUpQuestionsModal: NgbModal;

  // Properties
  proposalLoaded: boolean = false;
  logoUrl: string;
  proposalId: string;
  proposal: Proposal;
  taxMultiplier: number;
  proposalPricing: ProposalPricing;
  project: Project;
  projectFiles: ProjectFile[] = [];
  site: Site;
  client: Client;
  primaryContact: Contact;
  features: Feature[] = [];
  featureItems: Map<string, FeatureItem[]> = new Map<string, FeatureItem[]>();
  installationWeeks: { date: string, label: string }[] = [];
  acceptedProposal: AcceptedProposal;
  acceptProposalTerms: AcceptProposalTerm[] = [];
  businessAttributes: { name: string, email: string, phone: string, address: Address, displayAddress: boolean };
  allTermsAccepted: boolean = false;
  showTinselPayCardPassFee: boolean = false;
  followUpQuestionsForm: UntypedFormGroup;
  featureHasAtLeastOneImageMap: Map<string, boolean> = new Map<string, boolean>();
  signaturesAreEnabled: boolean = false;
  proposalIsExpired: boolean = false;

  // Multi Options
  multiOptionKeys: any[] = [];
  selectedMultiOptionKey: string;
  selectedMultiOption: string;

  // Font Awesome Properties
  faPrint = faPrint;
  faCheck = faCheck;
  faCircleNotch = faCircleNotch;
  faSpinner = faSpinner;

  loading: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private proposalService: ProposalService,
    private emailService: EmailService,
    private featureItemService: FeatureItemService,
    private modalService: NgbModal,
    private settingService: SettingService,
    private alertService: AlertService,
    public dateParserFormatter: NgbDateParserFormatter) { }

  ngOnInit(): void {
    this.proposalId = this.route.snapshot.paramMap.get('proposalId');
    this.followUpQuestionsForm = new UntypedFormGroup({
      installationWeek: new UntypedFormControl('NONE'),
      removalType: new UntypedFormControl('NONE'),
      additionalInfo: new UntypedFormControl()
    });
    // this.generateInstallationWeeks();
    this.printProposal();
    
  }

  // Open Modal
  openModal(content: any): void {
    this.modalReference = this.modalService.open(content);
  }

  // Open Media Lightbox
  openMediaLightbox(featureItem: FeatureItem): void {
    if (featureItem.imageType == 'NONE' || !featureItem.itemImageExists) return;
    const images = this.featureItems.get(featureItem.featureId).filter((featureItem) => { return featureItem.imageType == 'ITEM' && featureItem.itemImageExists; }).map((featureItem) => { return { id: featureItem.id, type: 'image', name: featureItem.name, url: featureItem.imageUrl }; });
    this.modalReference = this.modalService.open(MediaLightboxComponent, { centered: true, modalDialogClass: 'custom-modal-dialog', size: 'xl' });
    this.modalReference.componentInstance.lightboxItems = images;
    this.modalReference.componentInstance.selectedLightboxItemId = featureItem.id;
  }

  // Generate Installation Weeks
  private generateInstallationWeeks(): void {
    const startDate = dayjs(`${dayjs().year()}-09-01`).startOf('week');
    for (var i = 0; i < 17; i++) {
      const date = dayjs(startDate).add(i, 'week');
      this.installationWeeks.push({
        date: date.format('YYYY-MM-DD'),
        label: `Week of ${date.format('MMMM D, YYYY')}`
      });
    }
  }

  // Print Proposal
  private printProposal(): void {
    this.featureItems.clear();
    this.proposalService.printProposal(this.proposalId).pipe(catchError((error: any) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 404) this.router.navigateByUrl('/404', { replaceUrl: true });
      }
      return throwError(() => new Error('API Request Error'));
    })).subscribe((res) => {
      if (!this.hasBeenViewed() && !this.proposalLoaded) this.addProposalInteraction('VIEWED');
      this.proposal = res.proposal;
      console.log(this.proposal)

      this.proposalIsExpired = dayjs().unix() > dayjs(this.proposal.expirationDate).endOf('day').unix();
      // Multi Options
      if (this.proposal.agreementType == 'MULTI') {
        this.multiOptionKeys = Object.keys(this.proposal.multiOptions);
        this.selectedMultiOptionKey = this.multiOptionKeys[0];
      }
      this.project = res.project;
      this.site = this.project.site;
      this.client = res.client;
      this.primaryContact = res.primaryContact;
      this.features = res.features;
      const featureItems = (res.featureItems as FeatureItem[]).filter((featureItem) => { return !featureItem.hidden; });
      for (const featureItem of featureItems) {
        if (this.featureItems.has(featureItem.featureId)) {
          const tempArray = this.featureItems.get(featureItem.featureId);
          tempArray.push(featureItem);
          this.featureItems.set(featureItem.featureId, tempArray);
        } else {
          this.featureItems.set(featureItem.featureId, [featureItem]);
        }
      }
      this.taxMultiplier = parseFloat(res.taxMultiplier);
      this.projectFiles = res.projectFiles;
      this.acceptedProposal = 
      res.acceptedProposal ? 
      {
        ...res.acceptedProposal,
        dateSigned: dayjs.unix(res.acceptedProposal?.createdAt).format()
      } : null;

      if (this.acceptedProposal && this.acceptedProposal.agreementType == 'MULTI') this.selectedMultiOption = this.acceptedProposal.selectedMultiOption;
      this.logoUrl = (res.organizationLogoExists) ? this.settingService.getOrganizationLogoUrl(res.proposal.organizationId) : '../assets/TinselLogo.png';
      this.showTinselPayCardPassFee = res.showTinselPayCardPassFee;
      // Proposal Settings
      this.acceptProposalTerms = res.proposalSettings.acceptProposalTerms;
      this.businessAttributes = res.proposalSettings.businessAttributes;
      if (this.proposal.agreementType == 'MULTI') {
        (document.getElementById('MULTI_PRICING_DESCRIPTION') as HTMLDivElement).innerHTML = res.proposalSettings.multiPricingDescription;
        (document.getElementById('MULTI_PRICING_DESCRIPTION') as HTMLDivElement).hidden = false;
      }
      (document.getElementById('PROPOSAL_TERM') as HTMLDivElement).innerHTML = res.proposalSettings.proposalTerm.content;
      this.signaturesAreEnabled = res.proposalSettings.signaturesAreEnabled;
      this.calculateProposalPricing();
      this.getProposalSetting();

      this.proposalLoaded = true;
    });
  }

  // Calculate Proposal Pricing
  private calculateProposalPricing(): void {
    this.proposalService.calculateProposalPricing(this.proposalId).subscribe((res) => {
      this.proposalPricing = res;
    });
  }

  // Multi Option Is Enabled
  multiOptionIsEnabled(key: string): boolean {
    return this.proposal.multiOptions[key]?.isEnabled;
  }

  // Feature Item Has Image
  featureItemHasImage(featureItem: FeatureItem): boolean {
    return featureItem.imageType != 'NONE' && (featureItem.imageType == 'ITEM' && featureItem.itemImageExists);
  }

  // Feature Has At Least One Image
  featureHasAtLeastOneImage(featureId: string): boolean {
    if (this.featureHasAtLeastOneImageMap.has(featureId)) {
      return this.featureHasAtLeastOneImageMap.get(featureId);
    } else {
      const featureItems = this.featureItems.get(featureId);
      const hasAtLeastOneImage = featureItems.reduce((acc, featureItem) => { return acc || featureItem.imageType != 'NONE' && (featureItem.imageType == 'ITEM' && featureItem.itemImageExists); }, false);
      this.featureHasAtLeastOneImageMap.set(featureId, hasAtLeastOneImage);
      return hasAtLeastOneImage;
    }
  }

  // Print
  print(): void {
    setTimeout(() => {
      window.print();
    }, 500);
  }

  // Term Acceptance Changed
  termAcceptanceChanged(): void {
    const termElements = Array.prototype.slice.call(document.getElementsByName('ACCEPT_TERM'));
    this.allTermsAccepted = termElements.reduce((acc: boolean, cValue: HTMLInputElement) => { return acc && cValue.checked; }, true);
  }

  updateFeatureSelected(feature: Feature, event: any): void {
    this.proposalService.updateFeature(this.proposalId, feature.id, event.target.checked, this.proposal.organizationId).subscribe(() => {
      this.calculateProposalPricing();
    });

  }

  // Update Feature Included
  // updateFeatureIncluded(feature: Feature): void {
  //   this.featureService.updateFeatureIncluded(feature.id, feature.isHidden).subscribe(() => {
  //     const index = this.features.findIndex((f) => { return f.id == feature.id });
  //     this.features[index].included = feature.included;
  //     this.calculateProposalPricing();
  //   });
  // }

  // Select Multi Option
  selectMultiOption(option: string): void {
    this.selectedMultiOption = option;
  }

  // Select Proposal
  selectProposal(): void {
    this.allTermsAccepted = false;
    this.openModal(this.followUpQuestionsModal);
  }

  // Can Accept Proposal
  canAcceptProposal(): boolean {
    return !this.allTermsAccepted
      && this.acceptProposalTerms.length > 0;
  }

  // Accept Proposal
  acceptProposal(): void {
    if (this.signaturesAreEnabled && this.isSignatureCanvasBlank()) {
      this.alertService.showWarningAlert('Signature Cannot Be Blank');
    } else {
      this.loading = true;

      const signatureCanvas = document.getElementById('SIGNATURE_CANVAS') as HTMLCanvasElement;
      const signatureBase64 = (this.isSignatureCanvasBlank()) ? null : signatureCanvas.toDataURL('image/png').split(';base64,')[1];
      const acceptedProposal = {
        id: this.proposalId,
        selectedMultiOption: this.selectedMultiOption,
        installationWeek: (this.followUpQuestionsForm.value.installationWeek == 'NONE') ? null : this.followUpQuestionsForm.value.installationWeek,
        removalType: this.followUpQuestionsForm.value.removalType,
        additionalInfo: this.followUpQuestionsForm.value.additionalInfo,
        method: 'View Proposal',
        signature: signatureBase64,
        amount: this.proposal.amount,
        daysUntilDue: this.proposal.daysUntilDue,
        amountType: this.proposal.amountType
      };
      this.proposalService.acceptProposal(acceptedProposal).subscribe(async (res) => {
        this.alertService.showSuccessAlert('Proposal Accepted');
        this.modalReference.close();
        this.addProposalInteraction('ACCEPTED');
        this.printProposal();
        this.resetFollowUpQuestionsForm();
        this.loading = false;

        if (this.proposal.rowNumber == 1 && this.proposal.isInvoiceRequired) {
          if (this.proposal.clientEmail) await this.sendInvoiceEmail(res.invoiceId, res.invoiceNumber);
          this.redirectToInvoice(res.invoiceId);
        }
      });
    }
  }

  private async sendInvoiceEmail(invoiceId, invoiceNumber): Promise<void> {
    try {
      let template = await lastValueFrom(this.settingService.getEmailTemplate('INVOICE', this.proposal.organizationId));
      template = template.replace('##FIRST_NAME##', this.proposal.clientName);
      const subject = `Invoice # ${invoiceNumber}`;
      const body = template;
      const data = {
        organizationId: this.proposal.organizationId,
        invoiceId: invoiceId,
        to: [this.proposal.clientEmail],
        cc: [],
        subject: subject,
        body: body
      };
      this.emailService.sendInvoiceEmail(data, true).subscribe(() => {
        // this.alertService.showSuccessAlert('Email Sent');
        console.log('INVOICE EMAIL SENT')
      });
    } catch (error) {
      this.alertService.showWarningAlert(error.message);
    }
  }

  private getProposalSetting() {
    this.proposalService.proposalSetting(this.proposal.id).subscribe((res) => {
      console.log(res);
      this.preferredInstallation = res.preferredInstallation;
      this.preferredRemoval = res.preferredRemoval;
      this.preferredInstallationOptions = res.preferredInstallationOptions;
    });
  }

  redirectToInvoice(invoiceId: string): void {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/invoices/${invoiceId}/view`]));
    this.router.navigateByUrl(url);
  }

  // Reject Proposal
  rejectProposal(): void {
    this.proposalService.rejectProposal(this.proposalId, this.proposal.projectId, true).subscribe(() => {
      this.alertService.showSuccessAlert('Proposal Rejected');
      this.addProposalInteraction('REJECTED');
      this.printProposal();
    });
  }

  // Add Proposal Interaction
  private addProposalInteraction(type: string): void {
    if (!/proposals\/:proposalId\/view/g.test(this.route.snapshot.routeConfig.path)) return;
    this.proposalService.addProposalInteration({ proposalId: this.proposalId, type: type }).subscribe(() => {
      if (type == 'VIEWED') this.storeViewedInteractionToken();
    });
  }

  // Store Viewed Interaction Token
  private storeViewedInteractionToken(): void {
    sessionStorage.setItem(`${this.proposalId}.interaction.VIEWED`, true.toString());
  }

  // Has Been Viewed
  private hasBeenViewed(): boolean {
    return sessionStorage.getItem(`${this.proposalId}.interaction.VIEWED`) !== null;
  }

  // Reset Follow Up Questions Form
  private resetFollowUpQuestionsForm(): void {
    this.followUpQuestionsForm.reset();
    this.followUpQuestionsForm.controls.installationWeek.setValue('NONE');
    this.followUpQuestionsForm.controls.installationWeek.enable();
    this.followUpQuestionsForm.controls.removalType.setValue('NONE');
    this.followUpQuestionsForm.controls.removalType.enable();
    this.followUpQuestionsForm.controls.additionalInfo.setValue(null);
    this.followUpQuestionsForm.controls.additionalInfo.enable();
  }

  // View Follow Up Questions
  viewFollowUpQuestions(): void {
    this.followUpQuestionsForm.controls.installationWeek.setValue((this.acceptedProposal['installationWeek'] == null) ? 'NONE' : this.acceptedProposal['installationWeek']);
    this.followUpQuestionsForm.controls.installationWeek.disable();
    this.followUpQuestionsForm.controls.removalType.setValue(this.acceptedProposal['removalType']);
    this.followUpQuestionsForm.controls.removalType.disable();
    this.followUpQuestionsForm.controls.additionalInfo.setValue(this.acceptedProposal['additionalInfo']);
    this.followUpQuestionsForm.controls.additionalInfo.disable();
    this.openModal(this.followUpQuestionsModal);
  }

  // Get Signature Image Data URI
  getSignatureImageDataURI(): string {
    return 'data:image/png;base64,' + this.acceptedProposal.signature;
  }

  // Is Signature Canvas Blank
  private isSignatureCanvasBlank(): boolean {
    const signatureCanvas = document.getElementById('SIGNATURE_CANVAS') as HTMLCanvasElement;
    if (signatureCanvas === null) return true;
    const context = signatureCanvas.getContext('2d');
    const pixelBuffer = new Uint32Array(context.getImageData(0, 0, signatureCanvas.width, signatureCanvas.height).data.buffer);
    return !pixelBuffer.some((color) => { return color !== 0; });
  }

  // Follow-Up Questions Form Accessors
  get installationWeek() { return this.followUpQuestionsForm.controls.installationWeek; }
  get removalType() { return this.followUpQuestionsForm.controls.removalType; }
  get additionalInfo() { return this.followUpQuestionsForm.controls.additionalInfo; }
}
