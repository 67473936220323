<div class="modal-header">
  <h5 class="modal-title">
    Buy a mobile number
  </h5>

  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
</div>
<div class="modal-body">
  <div class="row mt-2">
    <div class="col-lg-12">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h5 class="my-auto">Numbers</h5>
              <button type="button" class="btn btn-primary ms-auto" aria-label="Add Number" (click)="getNumberList()">
                <fa-icon class="button-icon-left" [icon]="faRefresh"></fa-icon>
                <span>Refresh List</span>
              </button>
            </div>
            <div class="card-body">
              <div class="row">
                <form [formGroup]="numberFormGroup">
                  <div class="input-group">
                    <input type="number" class="form-control" formControlName="areaCode"
                      placeholder="Enter Area Code ex. 506" maxlength="3" />
                    <input type="text" class="form-control" formControlName="contains"
                      placeholder="Number contains ex. **117**" maxlength="7" />
                    <button type="button" class="btn btn-primary ms-auto" aria-label="Add Number"
                      (click)="tableControls.setCurrentPage(0); getNumberList()">
                      <span>Add Filter</span>
                    </button>
                  </div>
                </form>
              </div>
              <div class="table-responsive mt-3">
                <table>
                  <thead>
                    <tr>
                      <th>
                        Numbers
                      </th>
                      <th>
                        Capabilities
                      </th>
                      <th>
                        Type
                      </th>
                      <th>
                        Location
                      </th>
                      <th>
                        Price
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let num of availableNumbers">
                      <td>{{ num.phoneNumber }}</td>
                      <td *ngIf="num && num.capabilities; else noCapability">
                        <ng-container>
                          <fa-icon style="color: green" class="button-icon-center ms-2" [icon]="faPhone"
                            *ngIf="num?.capabilities.voice"></fa-icon>
                          <fa-icon style="color:blue" class="button-icon-center ms-2" [icon]="faMessage"
                            *ngIf="num?.capabilities.SMS"></fa-icon>
                          <fa-icon class="button-icon-center ms-2" [icon]="faImage"
                            *ngIf="num?.capabilities.MMS"></fa-icon>
                        </ng-container>
                        <ng-template #noCapability>
                          -
                        </ng-template>
                      </td>
                      <td>
                        Local
                      </td>
                      <td>
                        {{num.locality}}, {{num.region}} {{num.postalCode}}
                      </td>
                      <td>
                        $2.00 / month
                      </td>
                      <td>
                        <button type="button" class="btn btn-primary ms-auto" aria-label="Add Number"
                          (click)="confirmBuyNumber(num.phoneNumber)">
                          <fa-icon class="button-icon-left" [icon]="faCartShopping"></fa-icon>
                          <span>Buy</span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-3">
                <ngb-pagination *ngIf="availableNumbers.length > 0" [page]="tableControls.getCurrentPage()"
                  [pageSize]="tableControls.getLimit()" [collectionSize]="100"
                  [boundaryLinks]="true" [maxSize]="10" [ellipses]="true" [rotate]="true"
                  (pageChange)="getNumberList(tableControls.getCurrentPage() * 10)"></ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
</div>