<div class="content-container">
  <nav class="navbar navbar-white bg-white sticky-top">
    <div class="d-grid gap-2 d-flex navbar-brand">
      <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>
      <h2 class="mobileHeading">Clients</h2>
    </div>
    <div class="row button-container">
      <div class="col-md-auto">
        <input type="search" class="form-control" placeholder="Search Clients" [value]="storedSearchValue" (keyup)="tableControls.search($event.target.value);" (search)="tableControls.search($event.target.value);">
      </div>
      <div class="col-md-auto" *ngIf="!isMobile">
        <button type="button" class="btn btn-primary mt-md-0 mt-1" (click)="openNewClientModal();">
          <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
          <span>New Client</span>
        </button>
      </div>
      <div class="col-md-auto" *ngIf="!isMobile">
        <div ngbDropdown class="d-inline-block" display="dynamic" placement="bottom-right">
          <button id="CLIENT_DROPDOWN" type="button" class="btn btn-primary mt-md-0 mt-1" ngbDropdownToggle>Options</button>
          <div ngbDropdownMenu aria-labelledby="CLIENT_DROPDOWN">
            <button ngbDropdownItem (click)="exportClients();">
              <fa-icon class="button-icon-left" [icon]="faFileDownload"></fa-icon>
              <span>Export</span>
            </button>
            <button ngbDropdownItem (click)="openModal(IMPORT_CLIENT_NOTICE_MODAL);">
              <fa-icon class="button-icon-left" [icon]="faFileUpload"></fa-icon>
              <span>Import</span>
            </button>
          </div>
        </div>
      </div>
      <button type="button" *ngIf="isMobile" class="btn btn-primary mt-md-0 mt-1" (click)="openNewClientModal();">
        <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
        <span>New</span>
      </button>
      <div ngbDropdown *ngIf="isMobile" class="d-inline-block" display="dynamic" placement="bottom-right">
        <button id="CLIENT_DROPDOWN" type="button" class="btn btn-primary mt-md-0 mt-1" ngbDropdownToggle>Options</button>
        <div ngbDropdownMenu aria-labelledby="CLIENT_DROPDOWN">
          <button ngbDropdownItem (click)="exportClients();">
            <fa-icon class="button-icon-left" [icon]="faFileDownload"></fa-icon>
            <span>Export</span>
          </button>
          <button ngbDropdownItem (click)="openModal(IMPORT_CLIENT_NOTICE_MODAL);">
            <fa-icon class="button-icon-left" [icon]="faFileUpload"></fa-icon>
            <span>Import</span>
          </button>
        </div>
      </div>
      <div class="sort" *ngIf="isMobile"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>
      <div class="filter" *ngIf="isMobile"><ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container></div>
           <input id="FILE_INPUT" type="file" style="display: none;" accept="text/csv" (change)="fileInputChanged($event.target.files);">
    </div>
  </nav>
  <div class="table-responsive mt-3">
    <table *ngIf="!isMobile">
      <thead>
        <tr>
          <!-- Name -->
          <th (click)="tableControls.sort('client_name');">
            Name
            <fa-icon *ngIf="tableControls.isSortedBy('client_name')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </th>
          <!-- Street -->
          <th (click)="tableControls.sort('client_address_street');">
            Street
            <fa-icon *ngIf="tableControls.isSortedBy('client_address_street')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </th>
          <!-- City -->
          <th>
            <div class="d-flex" style="position: relative;">
              <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #clientCity="ngbPopover" (click)="showColumnFilterPopover(clientCity, 'client_address_city');">
                <span *ngIf="tableControls.hasActiveFilter('client_address_city')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('client_address_city')}}</span>
                <fa-icon [icon]="faFilter"></fa-icon>
              </div>
              <div class="w-100 px-3" (click)="tableControls.sort('client_address_city');">
                City
              </div>
              <fa-icon *ngIf="tableControls.isSortedBy('client_address_city')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
            </div>
          </th>
          <!-- County -->
          <th>
            <div class="d-flex" style="position: relative;">
              <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #clientCounty="ngbPopover" (click)="showColumnFilterPopover(clientCounty, 'client_address_county');">
                <span *ngIf="tableControls.hasActiveFilter('client_address_county')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('client_address_county')}}</span>
                <fa-icon [icon]="faFilter"></fa-icon>
              </div>
              <div class="w-100 px-3" (click)="tableControls.sort('client_address_county');">
                County
              </div>
              <fa-icon *ngIf="tableControls.isSortedBy('client_address_county')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
            </div>
          </th>
          <!-- Status -->
          <th>
            <div class="d-flex" style="position: relative;">
              <div class="header-filter-container" [ngbPopover]="popContentFilter" [popoverTitle]="popTitleFilter" triggers="manual" autoClose="outside" #clientStatus="ngbPopover" (click)="showColumnFilterPopover(clientStatus, 'client_status');">
                <span *ngIf="tableControls.hasActiveFilter('client_status')" class="custom-badge">{{tableControls.getColumnActiveFilterCount('client_status')}}</span>
                <fa-icon [icon]="faFilter"></fa-icon>
              </div>
              <div class="w-100 px-3" (click)="tableControls.sort('client_status');">
                Status
              </div>
              <fa-icon *ngIf="tableControls.isSortedBy('client_status')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
            </div>
          </th>
          <!-- Created -->
          <th (click)="tableControls.sort('client_created_at');">
            Created
            <fa-icon *ngIf="tableControls.isSortedBy('client_created_at')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let client of clients" routerLink="/clients/{{client.id}}">
          <td data-label="Name">{{client.name}}</td>
          <td data-label="Street">{{client.address.street}}</td>
          <td data-label="City">{{client.address.city}}</td>
          <td data-label="County">{{client.address.county}}</td>
          <td data-label="Status">{{client.status | clientStatus}}</td>
          <td data-label="Created">{{client.createdAt | formatDateTime}}</td>
        </tr>
      </tbody>
    </table>

    <div class="scrollable" *ngIf="isMobile">
      <ul class="list-group">
          <li *ngFor="let client of clients" class="list-group-item" style="cursor: pointer;" routerLink="/clients/{{client.id}}">
              <div class="d-flex list-group-container">
                  <div>
                      <strong>{{client.name}}</strong>
                      <div>{{client.address.street}}</div>
                      <div>{{client.address.city}}</div>
                      <div>{{client.address.country}}</div>
                      <div><strong>Status: </strong>{{client.status | clientStatus}}</div>
                      <small class="text-muted">Created: {{client.createdAt | formatDateTime}}</small>
                  </div>
              </div>
          </li>
          <li *ngIf="clients.length == 0" class="list-group-item">No Clients</li>
      </ul>
    </div>
  </div>
  <div class="row table-controls">
    <div *ngIf="tableControls.isNotDefault() && !isMobile" class="col-auto mt-3 me-2 me-md-0">
      <button type="button" class="btn btn-outline-danger" (click)="tableControls.resetTableConfiguration();">
        <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
        <span>Reset</span>
      </button>
    </div>
    <div class="col mt-3">
      <div ngbDropdown placement="top-left" class="d-inline-block">
        <button class="btn btn-outline-primary" ngbDropdownToggle><span *ngIf="!isMobile">Page Size: </span> {{tableControls.getLimit()}}</button>
        <div ngbDropdownMenu class="page-size-dropdown" *ngIf="!isMobile">
          <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
          <button ngbDropdownItem (click)="tableControls.setLimit(50);">50</button>
          <button ngbDropdownItem (click)="tableControls.setLimit(100);">100</button>
        </div>
        <div ngbDropdownMenu class="page-size-dropdown" *ngIf="isMobile">
          <button ngbDropdownItem (click)="tableControls.setLimit(5);">5</button>
          <button ngbDropdownItem (click)="tableControls.setLimit(10);">10</button>
          <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
        </div>
      </div>
    </div>
    <div class="col my-3">
      <ngb-pagination class="d-flex justify-content-end" *ngIf="clientCount > 0" [page]="tableControls.getCurrentPage()" [pageSize]="tableControls.getLimit()" [collectionSize]="clientCount" [boundaryLinks]="true" [maxSize]="(isMobile) ? 1 : 10" [ellipses]="true" [rotate]="true" (pageChange)="tableControls.setCurrentPage($event);"></ngb-pagination>
    </div>
  </div>
</div>

<!-- Filter -->
<ng-template #popTitleFilter>Filter</ng-template>
<ng-template let-column="column" #popContentFilter>
  <div *ngFor="let element of this.columnFilterValues.get(column)" class="form-check">
    <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter(column, element.value);" [checked]="tableControls.filterExists(column, element.value)">
    <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
  </div>
</ng-template>

<!-- Import Client Notice Modal -->
<ng-template #IMPORT_CLIENT_NOTICE_MODAL let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Import Clients</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
  </div>
  <div class="modal-body">
    <p class="mb-0">Please review the documentation on <a href="https://support.tinselcrm.com/knowledge-base/importing-clients/" target="_blank">Importing Clients And Contacts</a> before proceeding to ensure that your data is imported successfully.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.dismiss(); browseFiles();">Continue</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Cancel</button>
  </div>
</ng-template>

<ngx-spinner>
  <p class="loading-spinner">Loading...</p>
</ngx-spinner>
<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block" [autoClose]="true" placement="bottom-right">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
  <div ngbDropdown class="d-inline-block filter-container" autoClose="outside" placement="bottom-right">
      <span *ngIf="activeFilterCount > 0" class="custom-badge-lg">{{activeFilterCount}}</span>
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Filter">
          <fa-icon [icon]="faFilter"></fa-icon>
      </button>
      <div ngbDropdownMenu>
          <!-- Filter Options -->
          <div *ngFor="let filter of filterColumns">
            <button ngbDropdownItem class="button-filter" (click)="getDistinctColumnValues(filter.value); filters.toggle();">{{filter.label}}</button>
            <div class="px-4 py-1" #filters="ngbCollapse" [ngbCollapse]="filter.value === selectedFilterColumn ? false : true">
                <div class="d-flex flex-column">
                    <div *ngFor="let element of columnFilterValues.get(filter.value);" class="form-check form-check-inline">
                        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter(filter.value, element.value);" [checked]="filterExists(filter.value, element.value)">
                        <label [for]="element.randomId" class="form-check-label" style="white-space: nowrap;">{{element.name}}</label>
                    </div>
                </div>
            </div>
          </div>

          <!-- Divider -->
          <div class="dropdown-divider"></div>
          <!-- Reset -->
          <button class="reset-button" ngbDropdownItem (click)="resetActiveFilters(); selectedFilterColumn = ''">Reset</button>
      </div>
  </div>
</ng-template>
