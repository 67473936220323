import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Crew, ScheduleItem } from '../schedule-item';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-crew-deletion-modal',
  templateUrl: './crew-deletion-modal.component.html',
  styleUrls: ['./crew-deletion-modal.component.css']
})
export class CrewDeletionModalComponent implements OnInit {
  @Input() schedules: ScheduleItem[] = []
  @Input() crewOptions: Crew[] = [];
  @Input() crewIdToReplace: string;
  @Input() crewNameToReplace: string;

  @Output() replaceCrew: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() removeCrewOnly: EventEmitter<void> = new EventEmitter<void>();
  @Output() removeAll: EventEmitter<void> = new EventEmitter<void>();

  isReplacing: boolean = false;
  crewsFormGroup: FormGroup
  faExclamationCircle = faExclamationCircle;

  constructor(public modal: NgbActiveModal, private fb: FormBuilder) { }

  ngOnInit(): void {
    let group = {}
    this.schedules.forEach(sched => {
      group[sched.id] = new FormControl(null);
    })
    this.crewsFormGroup = new FormGroup(group);

    console.log(this.schedules)
  }

  // Action Confirmed
  onSelection(action: string): void {
    switch (action) {
      case 'removeAll':
        this.removeAll.emit();
        this.modal.close();

        break;
      case 'removeCrewOnly':
        this.removeCrewOnly.emit();
        this.modal.close();
        break;
      case 'replace':
        this.isReplacing = true
        break;
    }
  }

  saveReplacementBtn() {
    if (this.crewsFormGroup.valid) {
      let replacements = [];

      this.schedules.forEach(sched => {
        const val = this.crewsFormGroup.controls[sched.id].value;
        if (val) {
          const replaceObj = {
            orgCrewId: this.crewIdToReplace,
            scheduleId: sched.id,
            newCrewId: val
          };

          replacements.push(replaceObj)
          this.modal.close();
        }
      });

      this.replaceCrew.emit(replacements)
    }

  }

  applyToAll(index) {
    const ctrlName = this.schedules[index].id;
    const selectedValue = this.crewsFormGroup.get(ctrlName).value;
    this.schedules.forEach(sched => {
      let crewsIds = []
      sched.crews.forEach(crew => crewsIds.push(crew.crewId))
      if (crewsIds.includes(selectedValue)) {
        this.crewsFormGroup.get(sched.id)?.setValue(null);
        this.crewsFormGroup.get(sched.id)?.setErrors({ notInOption: true });
      } else {
        this.crewsFormGroup.controls[sched.id].setValue(selectedValue)
      }
    })
  }

  cancelReplacement() {
    this.isReplacing = false;
    this.crewsFormGroup.reset()
  }

  setBgColor(color: string) {
    return {
      'background-color': color ? color : '#000000',
      'padding': '0.3rem',
      'margin': '0.3rem 0.5rem',
      'border-radius': '5px'
    };
  }

  removeOptionOfExitingCrew(crewOptions, existingCrew) {
    let crews = []
    existingCrew.forEach(crew => crews.push(crew.crewId))
    const setCrews = new Set(crews)
    const options = crewOptions.filter(crew => !(setCrews.has(crew.crewId)))
    return options
  }

  onCrewSelection(scheduleId) {
    const currSelect = this.crewsFormGroup.get(scheduleId)
    if (currSelect?.value != null) currSelect?.setErrors({ notInOption: false });
  }
}

