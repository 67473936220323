import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import dayjs from 'dayjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { TimeEntry } from '../time-entry';
import { TimeEntryService } from '../time-entry.service';
import { AlertService } from '../alert.service';
import { Client } from '../client';
import { Project } from '../project';
import { ClientService } from '../client.service';
import { ProjectService } from '../project.service';
import { User } from '../user';
import { combineLatest, debounceTime, distinctUntilChanged, map, merge, Observable, Subject, switchMap } from 'rxjs';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-time-entry-modal',
  templateUrl: './time-entry-modal.component.html',
  styleUrls: ['./time-entry-modal.component.css']
})
export class TimeEntryModalComponent implements OnInit {

  // Properties
  @Input() timeEntry: TimeEntry;
  @Input() user: User;
  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();
  timeEntryForm: UntypedFormGroup;

  // Clients
  clientTypeaheadInput!: (text$: Observable<string>) => Observable<Client[]>;
  clientTypeaheadInputFocus$ = new Subject<string>();
  clientResultFormatter = (client: Client) => client.name;
  selectedClient!: Client;

  // Projects
  projects: Project[] = [];

  constructor(private timeEntryService: TimeEntryService,
    private clientService: ClientService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private modalService: NgbModal,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.timeEntryForm = new UntypedFormGroup({
      client: new UntypedFormControl(),
      project: new UntypedFormControl(),
      type: new UntypedFormControl('PREP IN WAREHOUSE'),
      start: new UntypedFormControl(),
      end: new UntypedFormControl()
    });
    this.timeEntryForm.controls.project.disable();
    this.timeEntryForm.controls.start.setErrors({ isAfterEnd: false });
    this.timeEntryForm.controls.end.setErrors({ isBeforeStart: false });
    this.prepareClientTypeahead();
    if (this.timeEntry) this.prepareTimeEntryForm();
    this.listenForStartEndChanges();
  }

  // Open Delete Time Entry Confirmation Modal
  openDeleteTimeEntryConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this time entry?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteTimeEntry();
    });
  }

  // Prepare Time Entry Form
  private prepareTimeEntryForm(): void {
    this.timeEntryForm.controls.client.setValue(this.timeEntry.clientName);
    this.getProjectsForClient(this.timeEntry.clientId);
    this.timeEntryForm.controls.type.setValue(this.timeEntry.type);
    this.timeEntryForm.controls.start.setValue(this.formatDateTimeInput(this.timeEntry.start));
    if (this.timeEntry.end) this.timeEntryForm.controls.end.setValue(this.formatDateTimeInput(this.timeEntry.end));
  }

  // Listen For Start End Changes
  private listenForStartEndChanges(): void {
    combineLatest([
      this.timeEntryForm.controls.start.valueChanges,
      this.timeEntryForm.controls.end.valueChanges
    ]).subscribe((values) => {
      const start = values[0];
      const end = values[1];
      if (start && end) {
        if (dayjs(end).isSameOrBefore(dayjs(start))) {
          this.timeEntryForm.controls.start.setErrors({ isAfterEnd: true });
          this.timeEntryForm.controls.end.setErrors({ isBeforeStart: true });
        } else {
          this.timeEntryForm.controls.start.setErrors(null);
          this.timeEntryForm.controls.end.setErrors(null);
        }
      }
    });
  }

  // Get Clients
  private getClients(term: string): Observable<Client[]> {
    const params = {
      searchTerm: term,
      sortBy: 'client_name',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0
    };
    return this.clientService.getClients(params).pipe(map((res) => { return res.clients; }));
  }

  // Prepare Client Typeahead
  private prepareClientTypeahead(): void {
    this.clientTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.clientTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getClients((term.length == 0) ? null : term);
      }));
    }
  }

  // Client Selected
  clientSelected(event: NgbTypeaheadSelectItemEvent): void {
    event.preventDefault();
    this.selectedClient = event.item;
    this.timeEntryForm.controls.client.setValue(this.selectedClient.name);
    this.getProjectsForClient(this.selectedClient.id);
  }

  // Get Projects For Client
  private getProjectsForClient(clientId: string): void {
    const params = {
      searchTerm: null,
      sortBy: 'p.project_name',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:c.client_id': JSON.stringify([clientId])
    };
    this.projectService.getProjects(params).subscribe((res) => {
      this.projects = res.projects;
      if (this.projects.length > 0) {
        this.timeEntryForm.controls.project.enable();
        if (this.timeEntry) {
          this.timeEntryForm.controls.project.setValue(this.timeEntry.projectId);
        } else {
          this.timeEntryForm.controls.project.setValue(this.projects[0].id);
        }
      } else {
        this.timeEntryForm.controls.project.disable();
      }
    });
  }

  // Save Time Entry
  saveTimeEntry(): void {
    if (this.timeEntry) {
      this.updateTimeEntry();
    } else {
      this.addTimeEntry();
    }
  }

  // Add Time Entry
  private addTimeEntry(): void {
    if (this.timeEntryForm.valid) {
      const timeEntry = {
        projectId: this.timeEntryForm.value.project,
        userId: this.user.id,
        type: this.timeEntryForm.value.type,
        start: dayjs(this.timeEntryForm.value.start).unix(),
        end: dayjs(this.timeEntryForm.value.end).unix()
      };
      this.timeEntryService.addTimeEntry(timeEntry).subscribe(() => {
        this.alertService.showSuccessAlert('Time Entry Added');
        this.refresh.emit();
        this.modal.close();
      });
    } else {
      this.timeEntryForm.markAllAsTouched();
    }
  }

  // Update Time Entry
  private updateTimeEntry(): void {
    const timeEntry = {
      id: this.timeEntry.id,
      projectId: this.timeEntryForm.value.project,
      type: this.timeEntryForm.value.type,
      start: dayjs(this.timeEntryForm.value.start).unix(),
      end: dayjs(this.timeEntryForm.value.end).unix()
    };
    this.timeEntryService.updateTimeEntry(timeEntry).subscribe(() => {
      this.alertService.showSuccessAlert('Time Entry Updated');
      this.refresh.emit();
      this.modal.close();
    });
  }

  // Delete Time Entry
  deleteTimeEntry(): void {
    this.timeEntryService.deleteTimeEntry(this.timeEntry.id).subscribe(() => {
      this.alertService.showSuccessAlert('Time Entry Deleted');
      this.refresh.emit();
      this.modal.close();
    });
  }

  // Time Entry Form Accessors
  get project() { return this.timeEntryForm.controls.project; }
  get start() { return this.timeEntryForm.controls.start; }
  get end() { return this.timeEntryForm.controls.end; }

  /* ----- Helper Functions ----- */

  // Format Date Time Input
  formatDateTimeInput(timestamp: number): string {
    return dayjs.unix(timestamp).format('YYYY-MM-DDTHH:mm');
  }
}
