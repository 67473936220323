<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">Tinsel Pay Overview (Beta)</h2>
        </div>
    </nav>
    <div class="row mt-3">
        <div class="col-12 mb-3">
            <ul ngbNav #nav="ngbNav" class="nav-tabs" activeId="CARD">
                <!-- CARD -->
                <li ngbNavItem="CARD">
                    <a ngbNavLink>Card Transactions</a>
                    <ng-template ngbNavContent>
                        <app-tinsel-pay-overview-card-transactions></app-tinsel-pay-overview-card-transactions>
                    </ng-template>
                </li>
                <!-- CARD VAULTS -->
                <li ngbNavItem="CARD VAULTS">
                    <a ngbNavLink>Card Vaults</a>
                    <ng-template ngbNavContent>
                        <app-tinsel-pay-overview-card-vaults></app-tinsel-pay-overview-card-vaults>
                    </ng-template>
                </li>
                <!-- ACH -->
                <li ngbNavItem="ACH">
                    <a ngbNavLink>ACH (Not Closed) Transactions</a>
                    <ng-template ngbNavContent>
                        <app-tinsel-pay-overview-ach-transactions></app-tinsel-pay-overview-ach-transactions>
                    </ng-template>
                </li>
                <!-- ACH VAULTS -->
                <li ngbNavItem="ACH VAULTS">
                    <a ngbNavLink>ACH Vaults</a>
                    <ng-template ngbNavContent>
                        <app-tinsel-pay-overview-ach-vaults></app-tinsel-pay-overview-ach-vaults>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</div>