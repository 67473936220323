import { Component, HostBinding, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { faHome, faUsers, faProjectDiagram, faFileInvoice, faLightbulb, faShoppingCart, faCalendar, faCogs, faChevronLeft, faChevronRight, faImage, faClock, faCircle, faCircleQuestion, faClipboardList, faBug, faHeadset, faCommentSms, faComments, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { UserRole } from '../user-role';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  // Properties
  @HostBinding('style.width') hostWidth = '70px';
  isOpen: boolean = false;
  userInitials: string = '##';
  public hasAccess:boolean = true;

  // Font Awesome Properties
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faHome = faHome;
  faUsers = faUsers;
  faProjectDiagram = faProjectDiagram;
  faFileInvoice = faFileInvoice;
  faLightbulb = faLightbulb;
  faShoppingCart = faShoppingCart;
  faCalendar = faCalendar;
  faImage = faImage;
  faClock = faClock;
  faClipboardList = faClipboardList;
  faCircleQuestion = faCircleQuestion;
  faBug = faBug;
  faCogs = faCogs;
  faCircle = faCircle;
  faHeadset = faHeadset;
  faComments = faComments;
  faAddressBook = faAddressBook;

  constructor(private authService: AuthService) { }

  async ngOnInit(): Promise<void> {
    this.userInitials = await this.getUserInitials();

    let login = JSON.parse(localStorage.getItem("login"))

    if((login?.subscription === 29 || !login?.isWithCardOnFile) && login?.role === "SUPER"){
      this.hasAccess = false;
    }

  }

  // Is Authorized
  isAuthorized(): boolean {
    return this.authService.isAuthorized(UserRole.FRANCHISEE);
  }

  // Toggle
  toggle(): void {
    if (!this.isOpen) {
      this.hostWidth = '210px';
    } else {
      this.hostWidth = '70px';
    }
    this.isOpen = !this.isOpen;
  }

  // Logout
  logout(): void {
    this.authService.logout();
  }

  /* ----- Helper Functions ----- */

  // Get User Initials
  private async getUserInitials(): Promise<string> {
    const user = await this.authService.getCurrentUser();
    return user.firstName.substring(0, 1) + user.lastName.substring(0, 1);
  }

  setNextRoute(route: any){
    sessionStorage.setItem("nextRoute", route)
  }
}
