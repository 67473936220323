import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from './invoice';
import { Payment } from './payment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  // Add Invoice
  addInvoice(invoice: any): Observable<any> {
    return this.http.post<any>(`/invoices`, invoice);
  }

  // Update Invoice
  updateInvoice(invoice: any): Observable<any> {
    return this.http.put<any>(`/invoices/${invoice.id}`, invoice);
  }

  // Delete Invoice
  deleteInvoice(invoiceId: string): Observable<any> {
    return this.http.delete<any>(`/invoices/${invoiceId}`);
  }

  // Get Invoices
  getInvoices(params: any): Observable<{ invoices: Invoice[], count: number }> {
    return this.http.get<{ invoices: Invoice[], count: number }>(`/invoices`, {
      params: params
    });
  }

  // Get Invoice
  getInvoice(invoiceId: string): Observable<Invoice> {
    return this.http.get<Invoice>(`/invoices/${invoiceId}`);
  }

  // Get Invoice Pricing
  getInvoicePricing(invoiceId: string): Observable<{ invoice: Invoice, payments: Payment[], proposalBalance: number }> {
    return this.http.get<{ invoice: Invoice, payments: Payment[], proposalBalance: number }>(`/invoices/${invoiceId}/pricing`);
  }

  // Print Invoice
  printInvoice(invoiceId: string): Observable<any> {
    return this.http.get<any>(`/invoices/${invoiceId}/print`);
  }

  // Get Distinct Column Values
  getDistinctColumnValues(params: any): Observable<string[]> {
    return this.http.get<string[]>(`/invoices/distinctColumnValues`, {
      params: params
    });
  }

  // Get Distinct Invoice Issue Date Years
  getDistinctInvoiceIssueDateYears(): Observable<number[]> {
    return this.http.get<number[]>(`/invoices/distinctInvoiceIssueDateYears`);
  }
}
