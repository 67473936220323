<div class="row flex-column mt-2">
    <div class="col">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">General</h3>
                <h6 class="card-subtitle text-muted">Manage your organization settings.</h6>
            </div>
        </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col-lg-4">
        <div class="card">
            <div class="card-header d-flex">
                <h5 class="card-title my-auto mb-auto">Business Information</h5>
                <button type="button" class="btn btn-primary ms-auto" (click)="updateBusinessInformation();">
                    <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                    <span>Save</span>
                </button>
            </div>
            <div class="card-body">
                <form [formGroup]="businessInformationForm">
                    <div class="mb-3">
                        <label for="ORGANIZATION_BUSINESS_NAME">Name</label>
                        <input id="ORGANIZATION_BUSINESS_NAME" type="text" class="form-control" placeholder="Appleseed Contractors" formControlName="name" maxlength="150" required [class.is-invalid]="businessName.invalid && (businessName.dirty || businessName.touched)">
                        <div *ngIf="businessName.invalid && (businessName.dirty || businessName.touched)">
                            <div *ngIf="businessName.errors.required" class="input-validation-msg">Name is required.</div>
                            <div *ngIf="businessName.errors.maxlength" class="input-validation-msg">Name cannot exceed 150 characters.</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="ORGANIZATION_BUSINESS_EMAIL">Email</label>
                        <input id="ORGANIZATION_BUSINESS_EMAIL" type="text" class="form-control" placeholder="jappleseed@example.com" formControlName="email" autocapitalize="off" maxlength="320" pattern="^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$" required [class.is-invalid]="businessEmail.invalid && (businessEmail.dirty || businessEmail.touched)">
                        <div *ngIf="businessEmail.invalid && (businessEmail.dirty || businessEmail.touched)">
                            <div *ngIf="businessEmail.errors.required" class="input-validation-msg">Email is required.</div>
                            <div *ngIf="businessEmail.errors.maxlength" class="input-validation-msg">Email cannot exceed 320.</div>
                            <div *ngIf="businessEmail.errors.pattern" class="input-validation-msg">Email is formatted incorrectly.</div>
                        </div>
                    </div>
                    <div>
                        <label for="ORGANIZATION_BUSINESS_PHONE">Phone</label>
                        <input id="ORGANIZATION_BUSINESS_PHONE" type="tel" class="form-control" appPhoneMask placeholder="(000) 000-0000" formControlName="phone" maxlength="14" pattern="^\((\d{3})\)\s(\d{3})-(\d{4})" [class.is-invalid]="businessPhone.invalid && (businessPhone.dirty || businessPhone.touched)">
                        <div *ngIf="businessPhone.invalid && (businessPhone.dirty || businessPhone.touched)">
                            <div *ngIf="businessPhone.errors.required" class="input-validation-msg">Phone is required.</div>
                            <div *ngIf="businessPhone.errors.maxlength" class="input-validation-msg">Phone cannot exceed 14 characters.</div>
                            <div *ngIf="businessPhone.errors.pattern" class="input-validation-msg">Phone is formatted incorrectly.</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-lg-4 mt-lg-0 mt-2">
        <div class="card">
            <div class="card-header d-flex">
                <h5 class="card-title my-auto mb-auto">Business Address</h5>
                <button type="button" class="btn btn-primary ms-auto" (click)="updateBusinessAddress();">
                    <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                    <span>Save</span>
                </button>
            </div>
            <div class="card-body">
                <app-address-form [form]="businessAddressForm"></app-address-form>
            </div>
        </div>
    </div>
    <div class="col-lg-4 mt-lg-0 mt-2">
        <div class="card" style="height: 100%;">
            <div class="card-header">
                <h5 class="my-auto">Business Logo</h5>
            </div>
            <div class="card-body">
                <div class="d-flex flex-column" [style.height]="(organizationLogoUrl) ? '100%' : 'inherit'">
                    <img *ngIf="(organizationLogoExists && organizationLogoUrl) || fileReadyForUpload" id="ORGANIZATION_LOGO_IMG" class="img-fluid m-auto" width="40%" alt="Organization Logo" [src]="organizationLogoUrl">
                    <button type="button" class="btn btn-secondary mt-md-auto mt-2" (click)="browseFiles();">Browse</button>
                    <button *ngIf="organizationLogoExists" type="button" class="btn btn-danger mt-2" (click)="deleteOrganizationLogo();">Remove</button>
                    <button *ngIf="fileReadyForUpload" type="button" class="btn btn-primary mt-2" (click)="uploadOrganizationLogo();">Upload</button>
                    <input id="ORGANIZATION_LOGO_FILE" type="file" style="display: none;" (change)="fileInputChanged($event.target.files);">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row flex-column mt-2">
    <div class="col">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">Tagging</h3>
                <h6 class="card-subtitle text-muted">Manage tagging settings.</h6>
            </div>
        </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col-lg-4">
        <app-settings-general-county-colors></app-settings-general-county-colors>
    </div>
</div>
<div class="row flex-column mt-2">
    <div class="col">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">Account</h3>
                <h6 class="card-subtitle text-muted">Manage your Tinsel CRM account.</h6>
            </div>
        </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col-lg-4">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title my-auto">Storage</h5>
            </div>
            <div class="card-body">
                <div class="d-flex mb-2">
                    <strong>Usage</strong>
                    <span class="ms-auto">{{totalStorageSize | formatBytes}} / {{storageSizeLimit | formatBytes}}</span>
                </div>
                <ngb-progressbar type="primary" [value]="totalStorageSize" [max]="storageSizeLimit" [showValue]="true"></ngb-progressbar>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title my-auto">Close Account</h5>
            </div>
            <div class="card-body">
                <p class="card-text">Please email <a href="mailto:support@tinselcrm.com">support@tinselcrm.com</a> to close your account.</p>
            </div>
        </div>
    </div>
</div>