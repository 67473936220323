import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { UserRole } from '../user-role';

@Component({
  selector: 'app-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.css']
})
export class MobileNavbarComponent implements OnInit {

  // Properties
  isNavbarCollapsed: boolean = true;
  public hasAccess: boolean = true;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    let login = JSON.parse(localStorage.getItem("login"))

    if ((login?.subscription === 29 || !login?.isWithCardOnFile) && login?.role === "SUPER") {
      this.hasAccess = false;
    }
  }

  // Is Authorized
  isAuthorized(): boolean {
    return this.authService.isAuthorized(UserRole.FRANCHISEE);
  }

  // Toggle
  toggle(): void {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  // Logout
  logout(): void {
    this.authService.logout();
  }
}
