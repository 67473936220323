import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AlertService } from '../alert.service';
import { CountyColor } from '../county-color';
import { SettingService } from '../setting.service';

@Component({
  selector: 'app-settings-general-county-colors',
  templateUrl: './settings-general-county-colors.component.html',
  styleUrls: ['./settings-general-county-colors.component.css']
})
export class SettingsGeneralCountyColorsComponent implements OnInit {

  // Properties
  countyColors: CountyColor[] = [];

  constructor(private settingService: SettingService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.getCountyColors();
  }

  // Get County Colors
  private getCountyColors(): void {
    this.settingService.getCountyColors().subscribe((countyColors) => {
      this.countyColors = countyColors;
    });
  }

  // Update County Color
  async updateCountyColor(countyColor: CountyColor, newColor: string): Promise<void> {
    try {
      countyColor.hexColor = newColor;
      await lastValueFrom(this.settingService.updateCountyColor(countyColor));
      this.alertService.showSuccessAlert('County Color Updated');
      this.getCountyColors();
    } catch (error) {
      this.getCountyColors()
    }
  }
}
