import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-invoice-pdf',
  templateUrl: './invoice-pdf.component.html',
  styleUrls: ['./invoice-pdf.component.css']
})
export class InvoicePdfComponent {

  constructor(
    private modalService: NgbModal,
    public modal: NgbActiveModal) { }

  async generatePDF() {
    try {
      let ret = null;
      await html2canvas(
        document.querySelector(`#invoice`), {
          logging: true,
          allowTaint: false,
          useCORS: true,
          scale: 2 // increase resolution of image
        }
      ).then(canvas => {
          // Create jsPDF document
            let pdf = new jsPDF('p', 'mm', 'a4');

            // Get the dimensions of the PDF page
            let pageWidth = pdf.internal.pageSize.getWidth();

            // Calculate the image width and height to maintain aspect ratio
            let imgWidth = canvas.width > pageWidth ? pageWidth * 0.9 : canvas.width * 0.9; // Scale down if wider than page
            let imgHeight = canvas.height * imgWidth / canvas.width; // Maintain aspect ratio

            // Calculate center horizontally
            let x = (pageWidth - imgWidth) / 2;

            let y = 10;
            let imgData = canvas.toDataURL("image/jpeg", 1);

            // Add image to PDF, top center
            pdf.addImage(imgData, 'JPEG', x, y, imgWidth, imgHeight);

            pdf.save(`invoice.pdf`);
        });
    } catch (e){

    }
  }

}
