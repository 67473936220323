import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MockupService } from '../mockup.service';
import { faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModalComponent } from '../file-upload-modal/file-upload-modal.component';
import { lastValueFrom } from 'rxjs';
import { MockupCustomAsset } from '../mockup-custom-asset';
import { AlertService } from '../alert.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-mockup-tool-custom-assets',
  templateUrl: './mockup-tool-custom-assets.component.html',
  styleUrls: ['./mockup-tool-custom-assets.component.css']
})
export class MockupToolCustomAssetsComponent implements OnInit {

  // Properties
  customAssets: MockupCustomAsset[] = [];
  @Output() imageSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() dismiss: EventEmitter<void> = new EventEmitter<void>();

  // Font Awesome Properties
  faUpload = faUpload;
  faTimes = faTimes;

  constructor(private mockupService: MockupService,
    private modalService: NgbModal,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.getMockupCustomAssets();
  }

  // Open File Upload Modal
  openFileUploadModal(): void {
    this.dismiss.emit();
    const modalReference = this.modalService.open(FileUploadModalComponent);
    modalReference.componentInstance.getPresignedURL = (data: any) => {
      return lastValueFrom(this.mockupService.addMockupCustomAsset(data));
    };
    // modalReference.componentInstance.completed.subscribe(() => {
    //   this.getMockupCustomAssets();
    // });
  }

  // Open Delete Mockup Custom Asset Confirmation Modal
  openDeleteMockupCustomAssetConfirmationModal(assetId: string): void {
    this.dismiss.emit();
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Deleting this custom asset will cause it to be unavailable for all existing mockups that use it. A warning placeholder will appear where it was located on the canvas. Any exported mockup images will not be affected. Are you sure you would like to delete this custom asset?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteMockupCustomAsset(assetId);
    });
  }

  // Get Mockup Custom Assets
  private getMockupCustomAssets(): void {
    this.mockupService.getMockupCustomAssets().subscribe((customAssets) => {
      this.customAssets = customAssets;
    });
  }

  // Delete Mockup Custom Asset
  private deleteMockupCustomAsset(assetId: string): void {
    this.mockupService.deleteMockupCustomAsset(assetId).subscribe(() => {
      this.alertService.showSuccessAlert('Mockup Custom Asset Deleted');
      this.getMockupCustomAssets();
    });
  }

  // Get Mockup Custom Asset Direct URL
  getMockupCustomAssetDirectURL(assetId: string): string {
    return this.mockupService.getMockupCustomAssetDirectURL(assetId);
  }

  // Select Image
  selectImage(assetId: string): void {
    this.imageSelected.emit(this.getMockupCustomAssetDirectURL(assetId));
  }
}
