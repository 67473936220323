<div class="card">
    <div class="card-header">
        <h5 class="my-auto">Subscriptions</h5>
        <button type="button" class="btn btn-primary ms-auto" (click)="openUpdateSubscriptionModal()">
            <span>Update Subscription</span>
        </button>
    </div>
    <div class="card-body">
        <ul class="list-group scrollable" style="height: 296px;">
            <li *ngFor="let subscription of subscriptions" class="list-group-item">
                <div class="d-flex">
                    <div>
                        <div class="d-flex">
                            <strong>{{subscription.name}}</strong>
                            <span *ngIf="!subscription.isExpired" class="badge text-bg-success my-auto ms-2">Active</span>
                            <span *ngIf="subscription.isExpired" class="badge text-bg-danger my-auto ms-2">Expired</span>
                        </div>
                        <small *ngIf="subscription.shouldRenew" class="text-muted">Renews on {{subscription.renewalDate | formatDateTime: true}}</small>
                        <small *ngIf="!subscription.shouldRenew" class="text-danger">Expires on {{subscription.renewalDate | formatDateTime: true}}</small>
                    </div>
                    <div class="ms-auto my-auto fs-4">
                        <span>${{subscription.price}}</span>
                        <span *ngIf="subscription.frequency == 'MONTHLY'" style="font-size: 16px;">/month</span>
                        <span *ngIf="subscription.frequency == 'ANNUALLY'" style="font-size: 16px;">/year</span>
                    </div>
                </div>
            </li>
            <li *ngIf="subscriptions.length == 0" class="list-group-item">No Active Subscriptions</li>
        </ul>
    </div>
</div>