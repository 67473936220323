<div class="row flex-column mt-2">
  <div class="col">
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div>
            <h3 class="card-title">
              Number {{ subAccountDetails && subAccountDetails.isA2pRegistered ? 'Settings': 'Registration'}}
              <!-- <button type="button" class="btn btn-primary ms-auto" [ngbPopover]="smsCallDescription" triggers="click">
                <fa-icon [icon]="faQuestion"></fa-icon>
              </button> -->
            </h3>
            <h6 class="card-subtitle text-muted">Manage your SMS/Call settings.</h6>
          </div>
          <button type="button" class="btn ms-auto" [class.btn-primary]="isSmsSettingsEnabled"
            [class.btn-danger]="!isSmsSettingsEnabled" (click)="toggleEnableSms();">
            <fa-icon [icon]="(isSmsSettingsEnabled) ? faToggleOn : faToggleOff"></fa-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-2">
  <ng-container [ngSwitch]="smsCallStatus">
    <ng-container *ngSwitchCase="'onSetting'" [ngTemplateOutlet]="settingTemplate"></ng-container>
    <ng-container *ngSwitchCase="'onRegistration'" [ngTemplateOutlet]="registration"></ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="instruction"></ng-container>
  </ng-container>
  <ng-template #instruction>
    <div>
      <p class="text-center">Before you can begin using Tinsel CRM’s Phone features, you must first choose a phone
        number, and then register
        your phone number via A2P registration.
        <span class="text-underlined text-primary cursor-pointer" (click)="toggleEnableSms()">Click here</span> to get
        started.
      </p>
    </div>
  </ng-template>

  <ng-template #registration>
    <ngb-alert [dismissible]="false">
      <span><strong>Important!</strong> You must register to A2P in order for you to use your number/s.
      </span>
      <button (click)="navigateSettingsStepper(2)" type="button" class="btn btn-sm btn-warning"
        style="margin-left: 20px;">
        Register A2P
      </button>
    </ngb-alert>
    <div>
      <ul>
        <li>
          <b>Effective August 31, 2023</b>, all SMS and MMS messages sent to U.S. phone numbers using
          10DLC
          phone numbers will fail if not registered with an application-to-person (A2P) Campaign.
        </li>
        <li>
          Only required if you are sending SMS/MMS to US using a Local or Mobile number.
        </li>
        <li>
          One time and monthly campaign fee is charged for <b>both</b> approved and failed campaigns
        </li>
        <li>
          <b>TinselCRM does not have control</b> over the approval process. Make sure to submit accurate information
        </li>
      </ul>
    </div>

    <app-sms-call-registration [subAccountDetails]="subAccountDetails"
      [orgNumbers]="orgNumbers"></app-sms-call-registration>
  </ng-template>

  <ng-template #settingTemplate>
    <div class="row mt-2">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header d-flex">
            <h5 class="card-title my-auto mb-auto">Account Info</h5>
          </div>
          <div class="card-body">
            <table *ngIf="!isMobile">
              <tr>
                <td class="text-left font-bold">SID</td>
                <td>{{subAccountDetails.subAccountId}}</td>
              </tr>
              <tr>
                <td class="text-left font-bold">Is Profile Approved?</td>
                <td>{{subAccountDetails.customerProfileBundleSID != null ? 'Yes':'No'}}</td>
              </tr>
              <tr>
                <td class="text-left font-bold">Is Brand Registration Approved?</td>
                <td>{{subAccountDetails.brandRegistrationSID != null ? 'Yes':'No'}}</td>
              </tr>
            </table>
          </div>

          <div class="scrollable" *ngIf="isMobile">
            <ul class="list-group">
                <li class="list-group-item">
                    <div class="d-flex list-group-container">
                        <div>
                            <strong class="first-info">SID: {{subAccountDetails.subAccountId}}</strong>
                            <div><strong>Is Profile Approved?</strong> {{subAccountDetails.customerProfileBundleSID != null ? 'Yes':'No'}}</div>
                            <div><strong>Is Brand Registration Approved?</strong> {{subAccountDetails.brandRegistrationSID != null ? 'Yes':'No'}}</div>
                        </div>
                    </div>
                </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-6 custom-margin-top">
        <div class="card">
          <div class="card-header d-flex">
            <h5 class="card-title my-auto mb-auto">List of Numbers</h5>
            <button type="button" class="btn btn-primary ms-auto" (click)="openBuyNumber();">
              <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
              <span>Add</span>
            </button>
          </div>
          <div class="card-body">
            <ng-container *ngIf="orgNumbers && orgNumbers.length !=0; else noNumber">
              <app-numbers [isMobile]="isMobile" [phoneNumbers]="orgNumbers"></app-numbers>
            </ng-container>
            <ng-template #noNumber>
              <p class="text-muted text-center mb-0">
                No Numbers available.
              </p>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-lg-6">

      </div>
    </div>
    <ng-container *ngIf="subAccountDetails && subAccountDetails.isA2pRegistered && !subAccountDetails.isPendingReview">

      <!-- TODO -->
    </ng-container>

    <div class="row flex-column mt-2">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">SMS and MMS</h3>
            <h6 class="card-subtitle text-muted">Messaging services, campaigns and settings</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header d-flex">
            <h5 class="card-title my-auto mb-auto">Messaging Service and Campaigns</h5>
            <button type="button" class="btn btn-primary ms-auto" (click)="addACampaign();"
              *ngIf="campaigns && campaigns.length <= 5">
              <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
              <span>Add</span>
            </button>
          </div>
          <div class="card-body">
            <table *ngIf="!isMobile">
              <thead>
                <th>Service Name</th>
                <th>Created at</th>
                <th>Status</th>
                <th></th>
              </thead>
              <tbody>
                <tr *ngFor="let campaign of campaigns">
                  <td>{{ campaign.campaignSID }}</td>
                  <td>{{ campaign.createdAt }}</td>
                  <td>{{ campaign.status }}</td>
                  <td (click)="viewCampign(campaign)" class="cursor-pointer text-underlined">View details</td>
                </tr>
              </tbody>
            </table>
            <ng-template *ngIf="!isMobile" #noCampaign>
              <p class="text-muted text-center mb-0">
                No Campaign created.
              </p>
            </ng-template>
            <div class="scrollable" *ngIf="isMobile">
              <ul class="list-group">
                  <li *ngFor="let campaign of campaigns" class="list-group-item"  (click)="viewCampign(campaign)" class="cursor-pointer text-underlined">
                      <div class="d-flex list-group-container">
                          <div>
                              <strong class="first-info">Service Name: {{campaign.campaignSID}}</strong>
                              <div><strong>Created at:</strong> {{campaign.createdAt}}</div>
                              <div><strong>Status:</strong> {{campaign.status}}</div>
                          </div>
                      </div>
                  </li>
                  <li *ngIf="campaigns.length == 0" class="list-group-item">No Campaign created.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header d-flex">
            <h5 class="card-title my-auto mb-auto">Message History and logs</h5>
          </div>
          <div class="card-body">
            <button type="button" class="btn btn-primary ms-auto" (click)="openFilterModal();">
              <fa-icon class="button-icon-left ms-2" [icon]="faSave"></fa-icon>
              <span>Download Message logs</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row flex-column mt-2">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <h3 class="card-title">Calls</h3>
                <h6 class="card-subtitle text-muted">Call related settings</h6>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-6 remove-padding-right">
        <div class="card">
          <div class="card-header d-flex">
            <h5 class="card-title my-auto mb-auto">Voicemail</h5>
            <!-- <button type="button" class="btn btn-primary ms-auto" (click)="addACampaign();">
              <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
              <span>Add</span>
            </button> -->
          </div>
          <div class="card-body">
          </div>
        </div>
      </div>
      <div class="col-lg-6 custom-margin-top">
        <div class="card">
          <div class="card-header d-flex">
            <h5 class="card-title my-auto mb-auto">Call Recordings
            </h5>
            <button type="button" class="btn ms-auto card-title" [class.btn-primary]="isCallRecordingSettingsEnabled"
              [class.btn-danger]="!isCallRecordingSettingsEnabled" (click)="toggleEnableCallRecording();">
              <fa-icon [icon]="(isCallRecordingSettingsEnabled) ? faToggleOn : faToggleOff"></fa-icon>
            </button>
          </div>
          <div class="card-body">
          </div>
        </div>
      </div>
    </div>
    <div class="row flex-column mt-2">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Billing</h3>
            <h6 class="card-subtitle text-muted">Here you can view running balance and subscriptions</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header d-flex">
            <h5 class="card-title my-auto mb-auto">Running Balance</h5>
            <!-- <button type="button" class="btn btn-primary ms-auto" (click)="addACampaign();">
              <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
              <span>Add</span>
            </button> -->
          </div>
          <div class="card-body">
          </div>
        </div>
      </div>
    </div>
  </ng-template>



</div>

<ng-template #smsCallDescription>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
    magna
    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    consequat.
    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
    sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
  </p>
</ng-template>
