import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SquarePaymentService {

  constructor() { }

  // Build Payment Request
  private buildPaymentRequest(payments: any, amount: string, totalLabel: string) {
    return payments.paymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        amount: amount,
        label: totalLabel
      }
    });
  }

  // Apple Pay
  async initializeApplePay(payments: any, amount: string, totalLabel: string): Promise<any> {
    const paymentRequest = this.buildPaymentRequest(payments, amount, totalLabel);
    const applePay = await payments.applePay(paymentRequest);
    return applePay;
  }

  // Google Pay
  async initializeGooglePay(payments: any, amount: string, totalLabel: string, type: string = 'plain'): Promise<any> {
    const paymentRequest = this.buildPaymentRequest(payments, amount, totalLabel);
    const googlePay = await payments.googlePay(paymentRequest);
    await googlePay.attach('#google-pay-button', {
      buttonColor: 'black',
      buttonSizeMode: 'fill',
      buttonType: type
    });
    return googlePay;
  }

  // Credit/Debit
  async initializeCard(payments: any): Promise<any> {
    const card = await payments.card({
      style: {
        '.input-container.is-focus': {
          borderColor: 'black'
        }
      }
    });
    await card.attach('#card-container');
    return card;
  }
}
