<div class="row flex-column mt-2">
    <div class="col">
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">General</h3>
                <h6 class="card-subtitle text-muted">Manage your payment settings.</h6>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <!-- Tinsel Pay -->
    <div class="col-lg-4 mt-2">
        <div class="card">
            <div class="card-header">
                <h5 class="my-auto">Tinsel Pay</h5>
                <button type="button" class="btn btn-primary ms-auto" (click)="openTinselPaySignUpModal();">
                    Sign Up
                </button>
                <!-- <button type="button" class="btn btn-primary ms-2" (click)="updateTinselPaySettings();">
                    <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                    <span>Save</span>
                </button> -->
            </div>
            <div class="card-body scrollable" style="height: 400px;">
                <!-- Card -->
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex">
                            <h5 class="card-title">Credit/Debit</h5>
                            <button type="button" class="btn ms-auto" [class.btn-primary]="tinselPayCardPaymentIsEnabled" [class.btn-danger]="!tinselPayCardPaymentIsEnabled" (click)="toggleTinselPayCard();">
                                <fa-icon [icon]="(tinselPayCardPaymentIsEnabled) ? faToggleOn : faToggleOff"></fa-icon>
                            </button>
                        </div>
                        <form [formGroup]="tinselPayCardForm">
                            <div class="mb-3">
                                <label for="TINSEL_PAY_CARD_TOKENIZATION_KEY">Tokenization Key</label>
                                <input id="TINSEL_PAY_CARD_TOKENIZATION_KEY" disabled type="text" class="form-control" formControlName="tokenizationKey" autocapitalize="off" autocomplete="off" required [class.is-invalid]="tokenizationKey.invalid && (tokenizationKey.dirty || tokenizationKey.touched)">
                                <div *ngIf="tokenizationKey.invalid && (tokenizationKey.dirty || tokenizationKey.touched)">
                                    <div *ngIf="tokenizationKey.errors.required" class="input-validation-msg">Tokenization Key is required.</div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="TINSEL_PAY_CARD_SECURITY_KEY">Security Key</label>
                                <input id="TINSEL_PAY_CARD_SECURITY_KEY" disabled type="text" class="form-control" formControlName="securityKey" autocapitalize="off" autocomplete="off" required [class.is-invalid]="securityKey.invalid && (securityKey.dirty || securityKey.touched)">
                                <div *ngIf="securityKey.invalid && (securityKey.dirty || securityKey.touched)">
                                    <div *ngIf="securityKey.errors.required" class="input-validation-msg">Security Key is required.</div>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex">
                                    <strong>Pass Fee:</strong>
                                    <span *ngIf="tinselPayCardPassFee" class="badge text-bg-success my-auto ms-2">Active</span>
                                    <span *ngIf="!tinselPayCardPassFee" class="badge text-bg-secondary my-auto ms-2">Inactive</span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- ACH -->
                <div class="card mt-2">
                    <div class="card-body">
                        <div class="d-flex">
                            <h5 class="card-title">ACH</h5>
                            <button type="button" class="btn ms-auto" [class.btn-primary]="tinselPayAchPaymentIsEnabled" [class.btn-danger]="!tinselPayAchPaymentIsEnabled" (click)="toggleTinselPayAch();">
                                <fa-icon [icon]="(tinselPayAchPaymentIsEnabled) ? faToggleOn : faToggleOff"></fa-icon>
                            </button>
                        </div>
                        <form [formGroup]="tinselPayAchForm">
                            <div class="mb-3">
                                <label for="TINSEL_PAY_ACH_MERCHANT_ID">Merchant ID</label>
                                <input id="TINSEL_PAY_ACH_MERCHANT_ID" disabled type="text" class="form-control" formControlName="merchantId" autocapitalize="off" autocomplete="off" maxlength="10" required [class.is-invalid]="merchantId.invalid && (merchantId.dirty || merchantId.touched)">
                                <div *ngIf="merchantId.invalid && (merchantId.dirty || merchantId.touched)">
                                    <div *ngIf="merchantId.errors.required" class="input-validation-msg">Merchant ID is required.</div>
                                    <div *ngIf="merchantId.errors.maxlength" class="input-validation-msg">Routing Number cannot exceed 10 characters.</div>
                                </div>
                            </div>
                            <div>
                                <label for="TINSEL_PAY_ACH_SERVICE_KEY">Service Key</label>
                                <input id="TINSEL_PAY_ACH_SERVICE_KEY" disabled type="text" class="form-control" formControlName="serviceKey" autocapitalize="off" autocomplete="off" maxlength="32" required [class.is-invalid]="serviceKey.invalid && (serviceKey.dirty || serviceKey.touched)">
                                <div *ngIf="serviceKey.invalid && (serviceKey.dirty || serviceKey.touched)">
                                    <div *ngIf="serviceKey.errors.required" class="input-validation-msg">Service Key is required.</div>
                                    <div *ngIf="serviceKey.errors.maxlength" class="input-validation-msg">Routing Number cannot exceed 32 characters.</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Square -->
    <div class="col-lg-4 mt-2">
        <div class="card">
            <div class="card-header">
                <h5 class="my-auto">Square</h5>
                <button type="button" class="btn btn-primary ms-auto" (click)="updateSquareSettings();">
                    <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                    <span>Save</span>
                </button>
                <button type="button" class="btn ms-2" [class.btn-primary]="squarePaymentIsEnabled" [class.btn-danger]="!squarePaymentIsEnabled" (click)="toggleSquare();">
                    <fa-icon [icon]="(squarePaymentIsEnabled) ? faToggleOn : faToggleOff"></fa-icon>
                </button>
            </div>
            <div class="card-body" style="height: 400px;">
                <form [formGroup]="squarePaymentForm">
                    <div class="mb-3">
                        <label for="SQUARE_APPLICATION_ID">Application ID</label>
                        <input id="SQUARE_APPLICATION_ID" type="text" class="form-control" formControlName="applicationId" autocapitalize="off" autocomplete="off" required [class.is-invalid]="applicationId.invalid && (applicationId.dirty || applicationId.touched)">
                        <div *ngIf="applicationId.invalid && (applicationId.dirty || applicationId.touched)">
                            <div *ngIf="applicationId.errors.required" class="input-validation-msg">Application ID is required.</div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="SQUARE_LOCATION_ID">Location ID</label>
                        <input id="SQUARE_LOCATION_ID" type="text" class="form-control" formControlName="locationId" autocapitalize="off" autocomplete="off" required [class.is-invalid]="locationId.invalid && (locationId.dirty || locationId.touched)">
                        <div *ngIf="locationId.invalid && (locationId.dirty || locationId.touched)">
                            <div *ngIf="locationId.errors.required" class="input-validation-msg">Location ID is required.</div>
                        </div>
                    </div>
                    <div>
                        <label for="SQUARE_ACCESS_TOKEN">Access Token</label>
                        <input id="SQUARE_ACCESS_TOKEN" type="text" class="form-control" formControlName="accessToken" autocapitalize="off" autocomplete="off" required [class.is-invalid]="accessToken.invalid && (accessToken.dirty || accessToken.touched)">
                        <div *ngIf="accessToken.invalid && (accessToken.dirty || accessToken.touched)">
                            <div *ngIf="accessToken.errors.required" class="input-validation-msg">Access Token is required.</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- Venmo -->
    <div class="col-lg-4 mt-2">
        <div class="card">
            <div class="card-header">
                <h5 class="my-auto">Venmo</h5>
                <button type="button" class="btn ms-auto" [class.btn-primary]="venmoPaymentIsEnabled" [class.btn-danger]="!venmoPaymentIsEnabled" (click)="toggleVenmo();">
                    <fa-icon [icon]="(venmoPaymentIsEnabled) ? faToggleOn : faToggleOff"></fa-icon>
                </button>
            </div>
            <div class="card-body scrollable" style="height: 400px;">
                <form [formGroup]="venmoPaymentForm">
                    <div>
                        <label for="VENMO_URL">URL</label>
                        <div class="input-group">
                            <input id="VENMO_URL" type="url" class="form-control" placeholder="https://venmo.com/user" formControlName="url" required [class.is-invalid]="url.invalid && (url.dirty || url.touched)">
                            <button type="button" class="btn btn-outline-primary" (click)="updateVenmoPaymentUrl();" ngbTooltip="Save">
                                <fa-icon [icon]="faCheck"></fa-icon>
                            </button>
                        </div>
                        <div *ngIf="url.invalid && (url.dirty || url.touched)">
                            <div *ngIf="url.errors.required" class="input-validation-msg">URL is required.</div>
                        </div>
                    </div>
                </form>
                <div class="mt-3">
                    <label style="font-weight: bold;">QR Code</label>
                    <div class="d-flex flex-column">
                        <div *ngIf="venmoCodeExists || fileReadyForUpload" class="mx-auto">
                            <img id="VENMO_CODE_IMG" class="img-fluid" [src]="venmoCodeUrl" width="220" height="220" alt="Venmo Code">
                        </div>
                        <button *ngIf="!venmoCodeExists" type="button" class="btn btn-secondary mt-2" (click)="browseFiles();">Browse</button>
                        <button *ngIf="venmoCodeExists" type="button" class="btn btn-danger mt-2" (click)="deleteVenmoPaymentCode();">Remove</button>
                        <button *ngIf="fileReadyForUpload" type="button" class="btn btn-primary mt-2" (click)="uploadVenmoCode();">Upload</button>
                        <input id="VENMO_CODE_FILE" type="file" style="display: none;" (change)="fileInputChanged($event.target.files);">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <!-- Cash -->
    <div class="col-lg-4 mt-2">
        <div class="card" style="height: 315px;">
            <div class="card-header">
                <h5 class="my-auto">Cash</h5>
                <button *ngIf="cashPaymentIsEnabled" type="button" class="btn btn-primary ms-auto" (click)="updateCashMessage();">
                    <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                    <span>Save</span>
                </button>
                <button type="button" class="btn ms-2" [class.ms-auto]="!cashPaymentIsEnabled" [class.btn-primary]="cashPaymentIsEnabled" [class.btn-danger]="!cashPaymentIsEnabled" (click)="toggleCash();">
                    <fa-icon [icon]="(cashPaymentIsEnabled) ? faToggleOn : faToggleOff"></fa-icon>
                </button>
            </div>
            <div class="card-body">
                <label for="CASH_MESSAGE" style="font-weight: bold;">Message</label>
                <div id="CASH_MESSAGE" class="form-control scrollable" style="height: 194px;" contenteditable="true"></div>
            </div>
        </div>
    </div>
    <!-- Check -->
    <div class="col-lg-4 mt-2">
        <div class="card" style="height: 315px;">
            <div class="card-header">
                <h5 class="my-auto">Check</h5>
                <button *ngIf="checkPaymentIsEnabled" type="button" class="btn btn-primary ms-auto" (click)="updateCheckMessage();">
                    <fa-icon class="button-icon-left" [icon]="faSave"></fa-icon>
                    <span>Save</span>
                </button>
                <button type="button" class="btn ms-2" [class.ms-auto]="!checkPaymentIsEnabled" [class.btn-primary]="checkPaymentIsEnabled" [class.btn-danger]="!checkPaymentIsEnabled" (click)="toggleCheck();">
                    <fa-icon [icon]="(checkPaymentIsEnabled) ? faToggleOn : faToggleOff"></fa-icon>
                </button>
            </div>
            <div class="card-body">
                <label for="CHECK_MESSAGE" style="font-weight: bold;">Message</label>
                <div id="CHECK_MESSAGE" class="form-control scrollable" style="height: 194px;" contenteditable="true"></div>
            </div>
        </div>
    </div>
    <div class="col-lg-4"></div>
</div>