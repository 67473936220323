<div class="card mt-3">
  <div class="card-header d-xl-flex">
    <h5>Insights</h5>
    <select *ngIf="years.length > 0" class="form-select ms-auto" style="width: fit-content;" (change)="yearSelected($event.target.value);">
      <option *ngFor="let year of years" [value]="year" [selected]="year == selectedYear">{{year}}</option>
    </select>
  </div>
  <div class="card-body" style="height: 400px;">
    <div *ngIf="years.length > 0" style="position: relative; width: auto; height: 368px;">
      <canvas id="CHART"></canvas>
    </div>
    <div *ngIf="years.length == 0" class="d-flex" style="position: relative; width: auto; height: 368px;">
      <p class="m-auto" style="font-weight: 600;">No Data Available</p>
    </div>
  </div>
</div>

<!-- Date Range Popover -->
<ng-template #dateRangePopTitle>Date Range</ng-template>
<ng-template #dateRangePopContent>
  <div class="input-group">
    <input id="START_DATE" class="form-control" [value]="startDate" readonly ngbDatepicker #startDatePicker="ngbDatepicker" [startDate]="startDateNgb" (dateSelect)="startDateSelected($event);" placement="bottom-right" placeholder="YYYY-MM-DD" [positionTarget]="startDatePickerBtn">
    <button #startDatePickerBtn type="button" class="btn btn-outline-primary" (click)="startDatePicker.toggle();">
      <fa-icon [icon]="faCalendar"></fa-icon>
    </button>
  </div>
  <div class="input-group mt-2">
    <input id="END_DATE" class="form-control" [value]="endDate" readonly ngbDatepicker #endDatePicker="ngbDatepicker" [startDate]="endDateNgb" (dateSelect)="endDateSelected($event);" placement="bottom-right" placeholder="YYYY-MM-DD" [positionTarget]="endDatePickerBtn">
    <button #endDatePickerBtn type="button" class="btn btn-outline-primary" (click)="endDatePicker.toggle();">
      <fa-icon [icon]="faCalendar"></fa-icon>
    </button>
  </div>
</ng-template>