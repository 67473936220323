import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { faCartShopping, faImage, faMessage, faPhone, faPlus, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SmsCallService } from '../../../sms-call.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertService } from '../../../alert.service';
import { TableControlService } from '../../../table-control.service';
import { ConfirmationModalComponent } from '../../../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-buy-number-modal',
  templateUrl: './buy-number-modal.component.html',
  styleUrls: ['./buy-number-modal.component.css']
})
export class BuyNumberModalComponent implements OnInit, OnDestroy {
  @Output() buyNumber: EventEmitter<any> = new EventEmitter<any>();

  subscription = new Subscription
  public availableNumbers: any[] = []

  faPhone = faPhone;
  faMessage = faMessage;
  faImage = faImage;
  faPlus = faPlus;
  faCartShopping = faCartShopping;
  faRefresh = faRefresh;
  numberFormGroup: FormGroup

  tableControls: TableControlService;

  constructor(
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private smsCallService: SmsCallService,
    private fb: FormBuilder,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.numberFormGroup = this.fb.group({
      areaCode: [null],
      contains: [null]
    });

    this.tableControls = new TableControlService('Number', false, null, null);

    this.getNumberList()
  }

  getNumberList(skip = 0) {
    const areaCode = this.numberFormGroup.controls.areaCode.value;
    const contains = this.numberFormGroup.controls.contains.value;
    this.subscription.add(
      this.smsCallService.listOfNumbers(
        areaCode,
        contains,
        skip
      ).pipe().subscribe(res =>  {
        this.availableNumbers = res
      })
    )
  }

  confirmBuyNumber(number: any) {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = `Are you sure you would like to buy ${number}? You will be charge a monthly fee  for this number until release`;
    confirmationModalRef.componentInstance.actionBtnTitle = "Confirm";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.buyANumber(number);
    });
  }

  buyANumber(number: any) {
    this.subscription.add(
      this.smsCallService.buyNumber(number).pipe().subscribe(res => {
        if(res) {
          this.buyNumber.emit(res)
          this.modal.close()
        }
      })
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe()
  }

}
