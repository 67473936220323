import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceStatus'
})
export class InvoiceStatusPipe implements PipeTransform {

  transform(status: string): string {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'OVERDUE':
        return 'Overdue';
      case 'PAID':
        return 'Paid';
      case 'CANCELED':
        return 'Canceled';
      default:
        return 'Unknown';
    }
  }
}
