import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { faAngleLeft, faFileDownload, faQuestionCircle, faRotateLeft, faSort, faSortDown, faSortUp, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { environment } from 'src/environments/environment';
import { DatePickerControllerComponent } from '../date-picker-controller/date-picker-controller.component';
import { ReportService } from '../report.service';
import { TableControlService } from '../table-control.service';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoaderService } from '../shared/services/loader.service';


@Component({
  selector: 'app-reports-time-clock',
  templateUrl: './reports-time-clock.component.html',
  styleUrls: ['./reports-time-clock.component.css']
})
export class ReportsTimeClockComponent implements OnInit, AfterViewInit {

  payrollForm: UntypedFormGroup;

  // Properties
  @ViewChild('datePickerController') private datePickerController: DatePickerControllerComponent;
  entries: any[] = [];
  totalRegularHours: number = 0;
  totalOvertimeHours: number = 0;
  totalGrossPay: number = 0;
  isMobile: boolean = true;
  startDate: string;
  endDate: string;

  sortOptions = [
    { label: 'Employee', value: 'user_last_name' },
    { label: 'Client', value: 'client_name' },
    { label: 'Project', value: 'project_name' },
    { label: 'Time IN', value: 'time_entry_start' },
    { label: 'Time OUT', value: 'time_entry_end' }
  ];
  sortBy: string = 'user_last_name';
  sortDirection: string = 'ASC';

  // Table Controls
  tableControls: TableControlService;

   // Font Awesome Properties
   faQuestionCircle = faQuestionCircle;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faFileDownload = faFileDownload;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faRotateLeft = faRotateLeft;
  faSort = faSort;
  faSortAmountDownAlt = faSortAmountDownAlt;

  constructor(private reportService: ReportService,
    public location: Location,
    private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.isMobile = (window.screen.width <= 360 || window.screen.width <= 768);
    window.addEventListener('resize', () => {
      this.isMobile = (window.screen.width <= 360 || window.screen.width <= 768);
    });
    this.tableControls = new TableControlService('Reports.TimeClock', true, 'project_name', 'ASC');
    this.tableControls.refresh.subscribe(() => {
      this.getEmployeeTimeClock();
    });
  }

  ngAfterViewInit(): void {
    this.tableControls.datePickerController = this.datePickerController;
  }

  // Date Changed
  dateChanged(event: { startDate: string, endDate: string, isAuto: boolean }): void {
    this.loaderService.showSpinner();
    this.startDate = event.startDate;
    this.endDate = event.endDate;
    this.tableControls.addRangeFilter('t.time_entry_start', dayjs(this.startDate).startOf('date').unix(), dayjs(this.endDate).endOf('date').unix(), event.isAuto);
  }

  // Get Payroll Summary Report
  public getEmployeeTimeClock(): void {
    let params = this.tableControls.getParams();
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
    };
    this.reportService.getTimeClockReport(params).subscribe({
      next:  (reportData) => {
        this.entries = reportData;
        this.loaderService.hideSpinner(700);
      },
      error: () => {
        this.loaderService.hideSpinner(700);
      }
    });
  }

  // Download Report
  downloadReport(): void {
    const searchParams = new URLSearchParams();
    searchParams.set('filter:range:t.time_entry_start', dayjs(this.startDate).unix() + ":" + dayjs(this.endDate).unix());
    const url = new URL('/api/reports/timeentries/getemployeestimeentries/download?' + searchParams.toString(), environment.apiUrl);
    window.open(url.toString());
  }

  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getEmployeeTimeClock();
  }
}
