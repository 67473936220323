<div>
    <div *ngIf="!renewalTermsAccepted" class="form-check">
        <input id="ACCEPT_RENEWAL_TERMS" type="checkbox" class="form-check-input" style="margin-left: -1.25em;" (click)="acceptRenewalTerms();">
        <label for="ACCEPT_RENEWAL_TERMS" class="form-check-label" style="font-weight: normal;">I acknowledge that my card will be stored securely for recurring payments.</label>
    </div>
    <div *ngIf="renewalTermsAccepted">
        <div class="row" [class.mb-2]="doesPaymentFormHaveError()">
            <div id="ccnumber" class="col-6"></div>
            <div id="ccexp" class="col-3"></div>
            <div id="cvv" class="col-3"></div>
        </div>
        <div class="d-flex flex-column">
            <small *ngIf="paymentFormValidation.ccnumber.hasError" class="input-validation-msg">
                <fa-icon [icon]="faCircleExclamation"></fa-icon>
                <span class="ms-2">{{paymentFormValidation.ccnumber.errorMessage}}</span>
            </small>
            <small *ngIf="paymentFormValidation.ccexp.hasError" class="input-validation-msg">
                <fa-icon [icon]="faCircleExclamation"></fa-icon>
                <span class="ms-2">{{paymentFormValidation.ccexp.errorMessage}}</span>
            </small>
            <small *ngIf="paymentFormValidation.cvv.hasError" class="input-validation-msg">
                <fa-icon [icon]="faCircleExclamation"></fa-icon>
                <span class="ms-2">{{paymentFormValidation.cvv.errorMessage}}</span>
            </small>
        </div>
        <div class="mt-2">
            <button type="button" class="btn btn-primary w-100" (click)="payWithCard();" [disabled]="!isPaymentFormValid() || paymentInProgress">{{paymentButtonText}}</button>
        </div>
    </div>
</div>