import { Component, OnInit } from '@angular/core';
import { faCalendar, faClipboardCheck, faDollarSign, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { AnalyticsService } from '../analytics.service';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ProjectService } from '../project.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-dashboard-stats',
  templateUrl: './dashboard-stats.component.html',
  styleUrls: ['./dashboard-stats.component.css']
})
export class DashboardStatsComponent implements OnInit {

  // Properties
  years: string[] = [];
  selectedYear: string;
  cashFlowInsights: any;
  chart: Chart;

  // Font Awesome Properties
  faClipboardCheck = faClipboardCheck;
  faDollarSign = faDollarSign;
  faHandHoldingUsd = faHandHoldingUsd;
  faCalendar = faCalendar;

  constructor(private analyticsService: AnalyticsService,
    private projectService: ProjectService) { }

  ngOnInit(): void {
    this.getDistinctProjectYears();
  }

  // Year Selected
  yearSelected(year: string): void {
    this.selectedYear = year;
    this.getCashFlowInsights();
  }

  // Get Distinct Project Years
  private getDistinctProjectYears(): void {
    const params = {
      searchTerm: null,
      sortBy: 'p.project_year',
      sortDirection: 'DESC',
      limit: null,
      offset: null,
      column: 'p.project_year'
    };
    this.projectService.getDistinctColumnValues(params).subscribe((values) => {
      this.years = values.map((value) => { return value.toString(); });
      if (this.years.length > 0) {
        const currentYear = dayjs().year().toString();
        this.selectedYear = this.years.includes(currentYear) ? currentYear : this.years[0];
        this.getCashFlowInsights();
      }
    });
  }

  // Get Cash Flow Insights
  private getCashFlowInsights(): void {
    const params = {
      year: this.selectedYear
    };
    this.analyticsService.getCashFlowInsights(params).subscribe((res) => {
      this.cashFlowInsights = res;
      if (!this.chart) {
        this.generateChart();
      } else {
        this.chart.data.datasets[0].data = [this.cashFlowInsights.acceptedProposalsTotal];
        this.chart.data.datasets[1].data = [0, this.cashFlowInsights.pendingPaymentsTotal];
        this.chart.data.datasets[2].data = [0, this.cashFlowInsights.closedPaymentsTotal];
        this.chart.data.datasets[3].data = [0, 0, this.cashFlowInsights.unpaidTotal];
        this.chart.update();
      }
    });
  }

  // Generate Chart
  private generateChart(): void {
    const ctx = (document.getElementById('CHART') as HTMLCanvasElement);
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: [['Accepted', 'Proposals'], 'Paid', 'Unpaid'],
        datasets: [
          {
            label: 'Accepted Proposals',
            data: [this.cashFlowInsights.acceptedProposalsTotal],
            backgroundColor: [
              'rgba(54, 162, 235, 0.5)'
            ]
          },
          {
            label: 'Payments (Pending)',
            data: [0, this.cashFlowInsights.pendingPaymentsTotal],
            backgroundColor: [
              'rgba(255, 159, 64, 0.5)'
            ]
          },
          {
            label: 'Payments (Closed)',
            data: [0, this.cashFlowInsights.closedPaymentsTotal],
            backgroundColor: [
              'rgba(75, 192, 192, 0.5)'
            ]
          },
          {
            label: 'Unpaid',
            data: [0, 0, this.cashFlowInsights.unpaidTotal],
            backgroundColor: [
              'rgba(255, 99, 132, 0.5)'
            ]
          }
        ]
      },
      plugins: [ChartDataLabels],
      options: {
        events: null,
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 15
          }
        },
        scales: {
          x: {
            display: false,
            stacked: true
          },
          y: {
            stacked: true,
            ticks: {
              callback: (value) => {
                return '$' + value;
              }
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            labels: {
              color: 'black',
              font: {
                family: "'Poppins', sans-serif"
              }
            },
            position: 'bottom'
          },
          tooltip: {
            enabled: false
          },
          datalabels: {
            align: 'top',
            anchor: 'end',
            color: 'black',
            font: {
              family: "'Poppins', sans-serif",
              weight: 600
            },
            formatter: (value) => {
              return (value > 0) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value) : null;
            },
            offset: -2.5,
            textAlign: 'center'
          }
        }
      }
    });
  }
}
