<div class="card mt-3">
  <div class="card-header flex">
    <h5 class="col-6">Proposals</h5>
    <div class="col-6 d-flex flex-row justify-content-end align-items-center">
      <div *ngIf="project" class="text-right button-container">
        <button type="button" class="btn btn-primary ms-md-2 mt-md-0 mx-2" (click)="confirmModifyProposal();"
          *ngIf="showModifyBtn()">
          <fa-icon class="button-icon-left" [icon]="faPencil"></fa-icon>
          <span class="d-none d-md-inline">Modify</span>
        </button>
        <button class="btn btn-danger" (click)="resetProjectProposals();" ngbTooltip="Reset" *ngIf="showResetBtn()">
          <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
          <span class="d-none d-md-inline">Reset</span>
        </button>
        <button class="btn btn-primary ms-2" (click)="openModal(NEW_PROPOSAL_MODAL);"
          [disabled]="project.stage != 'PROPOSAL'">
          <fa-icon class="button-icon-left" [icon]="faPlus"></fa-icon>
          <span class="d-none d-md-inline">New</span>
        </button>
      </div>
      <div class="vd ms-2"></div>
      <button class="btn btn-primary ms-2" (click)="updateProjectStage();">
        <span class="d-none d-md-inline">Next Stage</span>
        <fa-icon class="button-icon-right" [icon]="faArrowRight"></fa-icon>
      </button>
    </div>

  </div>
  <div class="card-body">
    <div class="table-responsive">
      <table *ngIf="!isMobile">
        <thead>
          <tr>
            <th>Name</th>
            <th>Tax</th>
            <th>Total(s)</th>
            <th>Status</th>
            <th>Last Emailed</th>
            <th>Updated</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let proposal of projectProposals; let i = index" routerLink="/proposals/{{proposal.id}}">
            <td data-label="Name">
              <div style="display: grid; grid-template-columns: auto 1fr;">
                <div class="d-flex" style="grid-column: 1; width: 16px;">
                  <fa-icon *ngIf="proposal.status == 'ACCEPTED' && proposal.isDiffPriceFromAP"
                    [icon]="faTriangleExclamation" style="color: var(--bs-red);"></fa-icon>
                </div>
                <div style="grid-column: 2;">
                  <div>{{proposal.name}} <span class="text-muted" *ngIf="projectProposals.length > 1">(Version {{ proposal.rowNumber }})</span></div>
                </div>
                <div style="grid-column: 3; width: 16px;"></div>
              </div>
            </td>
            <td data-label="Tax">{{proposal.taxMultiplier | percent: '1.0-3'}}</td>
            <td *ngIf="proposal.agreementType == 'SINGLE'" data-label="Total(s)">{{proposal.pricing.total | currency}}</td>
            <td *ngIf="proposal.agreementType == 'MULTI'" data-label="Total(s)">
              <span
                *ngIf="proposal.multiOptions.A.isEnabled && proposal.pricing.multiPricing.A">{{proposal.multiOptions.A.acronym}}:
                {{proposal.pricing.multiPricing.A.subtotal | currency}}</span>
              <span *ngIf="proposal.multiOptions.B.isEnabled && proposal.pricing.multiPricing.B"
                class="ms-2">{{proposal.multiOptions.B.acronym}}: {{proposal.pricing.multiPricing.B.subtotal |
                currency}}</span>
              <span *ngIf="proposal.multiOptions.C.isEnabled && proposal.pricing.multiPricing.C"
                class="ms-2">{{proposal.multiOptions.C.acronym}}: {{proposal.pricing.multiPricing.C.subtotal |
                currency}}</span>
              <span *ngIf="proposal.multiOptions.D.isEnabled && proposal.pricing.multiPricing.D"
                class="ms-2">{{proposal.multiOptions.D.acronym}}: {{proposal.pricing.multiPricing.D.subtotal |
                currency}}</span>
              <span *ngIf="proposal.multiOptions.E.isEnabled && proposal.pricing.multiPricing.E"
                class="ms-2">{{proposal.multiOptions.E.acronym}}: {{proposal.pricing.multiPricing.E.subtotal |
                currency}}</span>
            </td>
            <td data-label="Status">{{proposal.status | proposalStatus}}</td>
            <td data-label="Last Emailed">{{proposal.lastEmailedAt | formatDateTime}}</td>
            <td data-label="Updated">{{proposal.updatedAt | formatDateTime}}</td>
          </tr>
        </tbody>
      </table>

      <div class="scrollable" *ngIf="isMobile">
        <ul class="list-group">
            <li *ngFor="let proposal of projectProposals; let i = index" class="list-group-item" style="cursor: pointer;" routerLink="/proposals/{{proposal.id}}">
                <div class="d-flex list-group-container">
                    <div>
                        <div style="display: grid; grid-template-columns: auto 1fr;">
                          <div class="d-flex" *ngIf="proposal.status == 'ACCEPTED' && proposal.isDiffPriceFromAP"
                          style="grid-column: 1; width: 16px;line-height: 2;">
                            <fa-icon
                              [icon]="faTriangleExclamation" style="color: var(--bs-red);"></fa-icon>
                          </div>
                          <div style="grid-column: 2;">
                            <div><strong style="font-size: 1.25rem; margin-left:0px">{{proposal.name}}</strong> <span class="text-muted" *ngIf="projectProposals.length > 1">(Version {{ proposal.rowNumber }})</span></div>
                          </div>
                          <div style="grid-column: 3; width: 16px;"></div>
                        </div>
                        <div><strong>Tax: </strong>{{proposal.taxMultiplier | percent: '1.0-3'}}</div>
                        <div *ngIf="proposal.agreementType == 'SINGLE'">{{proposal.pricing.total | currency}}</div>
                        <div *ngIf="proposal.agreementType == 'MULTI'">
                          <span
                            *ngIf="proposal.multiOptions.A.isEnabled && proposal.pricing.multiPricing.A">{{proposal.multiOptions.A.acronym}}:
                            {{proposal.pricing.multiPricing.A.subtotal | currency}}</span>
                          <span *ngIf="proposal.multiOptions.B.isEnabled && proposal.pricing.multiPricing.B"
                            class="ms-2">{{proposal.multiOptions.B.acronym}}: {{proposal.pricing.multiPricing.B.subtotal |
                            currency}}</span>
                          <span *ngIf="proposal.multiOptions.C.isEnabled && proposal.pricing.multiPricing.C"
                            class="ms-2">{{proposal.multiOptions.C.acronym}}: {{proposal.pricing.multiPricing.C.subtotal |
                            currency}}</span>
                          <span *ngIf="proposal.multiOptions.D.isEnabled && proposal.pricing.multiPricing.D"
                            class="ms-2">{{proposal.multiOptions.D.acronym}}: {{proposal.pricing.multiPricing.D.subtotal |
                            currency}}</span>
                          <span *ngIf="proposal.multiOptions.E.isEnabled && proposal.pricing.multiPricing.E"
                            class="ms-2">{{proposal.multiOptions.E.acronym}}: {{proposal.pricing.multiPricing.E.subtotal |
                            currency}}</span>
                        </div>
                        <div><strong>Status:</strong> {{proposal.status | proposalStatus}}</div>
                        <small class="text-muted">Last Emailed: {{proposal.lastEmailedAt | formatDateTime}}</small>
                        <br/>
                        <small class="text-muted">Updated: {{proposal.updatedAt | formatDateTime}}</small>
                    </div>
                </div>
            </li>
            <li *ngIf="projectProposals.length == 0" class="list-group-item">No Project Proposals</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- New Proposal Modal -->
<ng-template #NEW_PROPOSAL_MODAL let-modal>
  <div class="modal-header">
    <h5 class="modal-title">New Proposal</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="proposalForm">
      <div class="mb-3">
        <label for="PROPOSAL_NAME">Name</label>
        <input id="PROPOSAL_NAME" type="text" class="form-control" placeholder="Proposal" formControlName="name"
          maxlength="150" required [class.is-invalid]="name.invalid && (name.dirty || name.touched)">
        <div *ngIf="name.invalid && (name.dirty || name.touched)">
          <div *ngIf="name.errors.required" class="input-validation-msg">Name is required.</div>
          <div *ngIf="name.errors.maxlength" class="input-validation-msg">Name cannot exceed 150 characters.</div>
        </div>
      </div>
      <div class="mb-3">
        <label for="PROPOSAL_TAX">Tax</label>
        <div class="input-group">
          <div ngbDropdown class="d-inline-block">
            <button type="button" class="btn btn-outline-secondary" ngbDropdownToggle
              style="border-top-right-radius: 0; border-bottom-right-radius: 0;"></button>
            <div ngbDropdownMenu>
              <button *ngFor="let tax of taxes" ngbDropdownItem (click)="selectTax(tax);">{{tax.name}} ({{tax.multiplier
                | percent: '1.0-3'}})</button>
            </div>
          </div>
          <input id="PROPOSAL_TAX" type="number" class="form-control" placeholder="0.00" formControlName="tax" required
            [class.is-invalid]="tax.invalid && (tax.dirty || tax.touched)">
          <span class="input-group-text">%</span>
        </div>
        <div *ngIf="tax.invalid && (tax.dirty || tax.touched)">
          <div *ngIf="tax.errors.required" class="input-validation-msg">Tax is required.</div>
        </div>
      </div>
      <div>
        <label for="CLONE_FROM">Clone From</label>
        <select id="CLONE_FROM" class="form-select" formControlName="cloneProposalId" required>
          <option value="NONE">None</option>
          <option *ngFor="let proposal of siteProposals" [value]="proposal.id">{{proposal.name}}
            ({{proposal.projectName}})</option>
        </select>
      </div>
      <div *ngIf="cloneProposalId.value != 'NONE'" class="mt-3">
        <label>Feature Item Type</label>
        <div style="margin-top: 0.4rem!important;">
          <div class="form-check form-check-inline">
            <input id="CLONE_FEATURE_ITEM_TYPE_NEW" class="form-check-input" type="radio"
              formControlName="featureItemType" value="NEW">
            <label for="CLONE_FEATURE_ITEM_TYPE_NEW" class="form-check-label" style="font-weight: normal;">New</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="CLONE_FEATURE_ITEM_TYPE_EXISTING" class="form-check-input" type="radio"
              formControlName="featureItemType" value="EXISTING">
            <label for="CLONE_FEATURE_ITEM_TYPE_EXISTING" class="form-check-label"
              style="font-weight: normal;">Existing</label>
          </div>
        </div>
        <small *ngIf="featureItemType.value == 'NEW'" class="text-muted">Recommended for cloning a proposal into the
          same project.</small>
        <small *ngIf="featureItemType.value == 'EXISTING'" class="text-muted">Recommended for cloning a proposal into a
          different project.</small>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="createProposal();">Save</button>
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
  </div>
</ng-template>
