import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Note } from './note';

@Injectable({
  providedIn: 'root'
})
export class NoteService {

  constructor(private http: HttpClient) { }

  // Add Note
  addNote(note: any): Observable<any> {
    return this.http.post<any>(`/notes`, note);
  }

  // Update Note
  updateNote(note: any): Observable<any> {
    return this.http.put<any>(`/notes/${note.id}`, note);
  }

  // Delete Note
  deleteNote(noteId: string): Observable<any> {
    return this.http.delete<any>(`/notes/${noteId}`);
  }

  // Get Notes
  getNotes(params: any): Observable<Note[]> {
    return this.http.get<Note[]>(`/notes`, {
      params: params
    });
  }
}
