import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../alert.service';
import { ScheduleService } from '../schedule.service';
import { faClock, faCircle, faTrashCan, faSortUp, faSortDown, faFilter, faSortAmountDownAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Client } from '../client';
import { Project } from '../project';
import { ClientService } from '../client.service';
import { ProjectService } from '../project.service';
import { ScheduleItem } from '../schedule-item';
import { ScheduleItemModalComponent } from '../schedule-item-modal/schedule-item-modal.component';
import { Observable, Subject, map, debounceTime, distinctUntilChanged, merge, switchMap } from 'rxjs';

@Component({
  selector: 'app-schedule-queue',
  templateUrl: './schedule-queue.component.html',
  styleUrls: ['./schedule-queue.component.css']
})
export class ScheduleQueueComponent implements OnInit {

  // Inputs and Outputs
  @Input() type: string = null;

  // Modals
  private modalReference: NgbModalRef;

  // Properties
  searchTerm: string = null;
  sortBy: string = 'sc.schedule_item_created_at';
  sortDirection: string = 'DESC';
  limit: number = null;
  offset: number = null;
  title: string = null;
  scheduleItemType: string = null;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;

  // Projects
  projects: Project[] = [];

  // Schedule Items
  scheduleItems: ScheduleItem[] = [];

  // Forms
  scheduleQueueItemForm: UntypedFormGroup;

  // Clients
  clientTypeaheadInput: (text$: Observable<string>) => Observable<Client[]>;
  clientTypeaheadInputFocus$ = new Subject<string>();
  clientResultFormatter = (client: Client) => client.name;
  selectedClient!: Client;

  // Font Awesome Properties
  faClock = faClock;
  faCircle = faCircle;
  faTrashCan = faTrashCan;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;
  faSortAmountDownAlt = faSortAmountDownAlt;
  faPlus = faPlus;

  constructor(private scheduleService: ScheduleService,
    private alertService: AlertService,
    private clientService: ClientService,
    private projectService: ProjectService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.scheduleQueueItemForm = new UntypedFormGroup({
      client: new UntypedFormControl(),
      project: new UntypedFormControl()
    });
    this.setQueueContext();
    this.listenForScheduleUpdates();
    this.getScheduleItems();
    this.prepareClientTypeahead();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Modal
  openModal(content: any): void {
    this.modalReference = this.modalService.open(content);
  }

  // Open Schedule Item Modal
  openScheduleItemModal(scheduleItem: ScheduleItem): void {
    const modalRef = this.modalService.open(ScheduleItemModalComponent);
    modalRef.componentInstance.scheduleItem = scheduleItem;
  }

  // Set Queue Context
  private setQueueContext(): void {
    switch (this.type) {
      case 'INSTALLATION':
        this.title = 'Installation';
        this.scheduleItemType = 'INSTALL';
        break;
      case 'MAINTENANCE':
        this.title = 'Maintenance';
        this.scheduleItemType = 'MAINTENANCE';
        break;
      case 'REMOVAL':
        this.title = 'Removal';
        this.scheduleItemType = 'REMOVE';
        break;
      default:
        break;
    }
  }

  // Listen For Schedule Updates
  private listenForScheduleUpdates(): void {
    this.scheduleService.scheduleUpdated$.subscribe(() => {
      this.getScheduleItems();
    });
  }

  /* ----- Clients ----- */

  // Get Clients
  private getClients(term: string): Observable<Client[]> {
    const params = {
      searchTerm: term,
      sortBy: 'client_name',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0
    };
    return this.clientService.getClients(params).pipe(map((res) => { return res.clients; }));
  }

  // Prepare Client Typeahead
  private prepareClientTypeahead(): void {
    this.clientTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.clientTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getClients((term.length == 0) ? null : term);
      }));
    }
  }

  // Select Client
  selectClient(event: NgbTypeaheadSelectItemEvent): void {
    event.preventDefault();
    this.selectedClient = event.item;
    this.scheduleQueueItemForm.controls.client.setValue(this.selectedClient.name);
    this.getProjectsForClient(this.selectedClient.id);
  }

  // Get Projects For Client
  getProjectsForClient(clientId: string): void {
    const params = {
      searchTerm: null,
      sortBy: 'p.project_name',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:c.client_id': JSON.stringify([clientId])
    };
    this.projectService.getProjects(params).subscribe((res) => {
      this.projects = res.projects;
      if (this.projects.length > 0) {
        this.scheduleQueueItemForm.controls.project.enable();
        this.scheduleQueueItemForm.controls.project.setValue(this.projects[0].id);
      } else {
        this.scheduleQueueItemForm.controls.project.disable();
      }
    });
  }

  // Get Schedule Items
  getScheduleItems(): void {
    let params = {
      searchTerm: this.searchTerm,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
      limit: null,
      offset: null,
      'filter:sc.schedule_item_type': JSON.stringify([this.scheduleItemType]),
      'filter:sc.schedule_item_status': JSON.stringify(['UNSCHEDULED'])
    };
    for (const [key, values] of this.activeFilters) {
      params[`filter:${key}`] = JSON.stringify(values);
    }
    this.scheduleService.getScheduleItems(params).subscribe((res) => {
      this.scheduleItems = res.scheduleItems;
    });
  }

  // Add Schedule Item
  addScheduleItem(): void {
    if (this.scheduleQueueItemForm.valid) {
      const scheduleItem = {
        clientId: this.selectedClient.id,
        projectId: this.scheduleQueueItemForm.value.project,
        type: this.scheduleItemType
      };
      this.scheduleService.addScheduleItem(scheduleItem).subscribe(() => {
        this.alertService.showSuccessAlert('Schedule Item Added');
        this.scheduleService.scheduleUpdated.next();
        this.modalReference.close();
        this.resetScheduleQueueItemForm();
        this.getScheduleItems();
      })
    } else {
      this.scheduleQueueItemForm.markAllAsTouched();
    }
  }

  // Delete Schedule Item
  deleteScheduleItem(scheduleItemId: string): void {
    this.scheduleService.deleteScheduleItem(scheduleItemId).subscribe(() => {
      this.alertService.showSuccessAlert('Schedule Item Deleted');
      this.scheduleService.scheduleUpdated.next();
      this.getScheduleItems();
    });
  }

  // Reset Schedule Queue Item Form
  private resetScheduleQueueItemForm(): void {
    this.scheduleQueueItemForm.reset();
    this.projects.length = 0;
  }

  /* ----- Sorting ----- */

  // Sort
  sort(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getScheduleItems();
  }

  /* ----- Filters ----- */

  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    if (!this.columnFilterValues.has(column)) {
      const params = {
        searchTerm: null,
        sortBy: column,
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        column: column,
        'filter:sc.schedule_item_type': JSON.stringify([this.scheduleItemType]),
        'filter:sc.schedule_item_status': JSON.stringify(['UNSCHEDULED'])
      };
      this.scheduleService.getDistinctColumnValues(params).subscribe((values) => {
        const tempArray = [];
        for (const value of values) {
          tempArray.push({ value: value, randomId: Math.random().toString(36) });
        }
        this.columnFilterValues.set(column, tempArray);
      });
    }
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    console.log(column)
    console.log(value)
    console.log(this.activeFilters)
    console.log(this.activeFilters.has(column))
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getScheduleItems();
    this.getActiveFilterCount();
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getScheduleItems();
    this.getActiveFilterCount();
  }

  // Get Active Filter Count
  getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }
}
