import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appForbiddenValue]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ForbiddenValueDirective, multi: true }]
})
export class ForbiddenValueDirective implements Validator {
  @Input('appForbiddenValue') forbiddenValue: string;

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.forbiddenValue ? this.forbiddenValueValidator(new RegExp(this.forbiddenValue, 'i'))(control) : null;
  }

  forbiddenValueValidator(valueRe: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = valueRe.test(control.value);
      return forbidden ? { forbiddenValue: { value: control.value } } : null;
    };
  }
}
