import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'formatDateTime'
})
export class FormatDateTimePipe implements PipeTransform {

  transform(dateTime: any, hideTime: boolean = false): string {
    const format = (hideTime) ? 'M/D/YYYY' : 'M/D/YYYY, h:mm A';
    if (dateTime === null || dateTime === undefined) {
      return 'N/A';
    } else if (typeof dateTime == 'number') {
      return dayjs.unix(dateTime).format(format);
    } else {
      return dayjs(dateTime).format(format);
    }
  }
}
