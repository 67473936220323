<div class="content-container">
  <nav class="navbar navbar-white bg-white sticky-top">
    <div class="d-grid gap-2 d-flex navbar-brand">
      <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>
      <h2 class="mobileHeading">At A Glance</h2>
    </div>
    <div class="row">
      <div class="col-auto d-flex">
        <div *ngIf="isReportLoading" class="my-auto">
          <fa-icon [icon]="faRotate" size="lg" spin="true"></fa-icon>
        </div>
        <select *ngIf="years.length > 0" class="form-select ms-2" style="width: fit-content;" (change)="yearSelected($event.target.value);">
          <option *ngFor="let year of years" [value]="year" [selected]="year == selectedYear">{{year}}</option>
        </select>
      </div>
    </div>
  </nav>
  <div class="row mt-3">
    <div class="card p-0">
      <div class="card-body">
        <h5 class="card-title">Description</h5>
        <p class="card-text m-0">This report can be used to quickly review key financial indicators for the year.</p>
      </div>
    </div>
  </div>
  <div *ngIf="reportData" class="row mt-3">
    <div class="col">
      <div>
        <table>
          <thead>
            <th colspan="2" class="title">Income</th>
          </thead>
          <tbody>
            <tr>
              <td class="title" data-label="Income">Gross Income<br><small class="text-muted">*Includes taxes and discounts.</small></td>
              <td class="number" data-label="Gross Income">{{reportData.income | currency}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Income">Material Costs</td>
              <td class="number" data-label="Material Costs">{{reportData.materialCost | currency}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Income">Labor Costs</td>
              <td class="number" data-label="Labor Costs">{{reportData.laborCost | currency}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Income">Gross Profits</td>
              <td class="number" data-label="Gross Profits">{{reportData.grossProfit | currency}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Income">Gross Profit Margin</td>
              <td class="number" data-label="Gross Profit Margin">{{reportData.grossProfitMargin | percent}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mt-2">
        <table>
          <thead>
            <th colspan="2" class="title">Labor</th>
          </thead>
          <tbody>
            <tr>
              <td class="title" data-label="Labor">Quoted Hours</td>
              <td class="number" data-label="Quoted Hours">{{reportData.quotedHours}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Labor">Actual Hours</td>
              <td class="number" data-label="Actual Hours">{{reportData.actualHours}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Labor">Labor Quote Ratio</td>
              <td class="number" data-label="Labor Quote Ratio">{{reportData.laborQuoteRatio}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mt-2">
        <table>
          <thead>
            <th colspan="2" class="title">Project Close Rate (#)</th>
          </thead>
          <tbody>
            <tr>
              <td class="title" data-label="Project Close Rate (#)">Quoted Project Count</td>
              <td class="number" data-label="Quoted Project Count">{{reportData.quotedProjectCount}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Project Close Rate (#)">Won Project Count</td>
              <td class="number" data-label="Won Project Count">{{reportData.wonProjectCount}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Project Close Rate (#)">Close Rate</td>
              <td class="number" data-label="Close Rate">{{reportData.closeRateCount | percent}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mt-2">
        <table>
          <thead>
            <th colspan="2" class="title">Project Close Rate ($)</th>
          </thead>
          <tbody>
            <tr>
              <td class="title" data-label="Project Close Rate ($)">Quoted Project Amount</td>
              <td class="number" data-label="Quoted Project Amount">{{reportData.quotedProjectAmount | currency}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Project Close Rate ($)">Won Project Amount</td>
              <td class="number" data-label="Won Project Amount">{{reportData.wonProjectAmount | currency}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Project Close Rate ($)">Close Rate</td>
              <td class="number" data-label="Close Rate">{{reportData.closeRateAmount | percent}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mt-2">
        <table>
          <thead>
            <th colspan="2" class="title">Average Tickets</th>
          </thead>
          <tbody>
            <tr>
              <td class="title" data-label="Average Tickets">Average Overall Ticket</td>
              <td class="number" data-label="Average Overall Ticket">{{reportData.averageTickets.all | currency}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Average Tickets">Average Residential Ticket</td>
              <td class="number" data-label="Average Residential Ticket">{{reportData.averageTickets.residential | currency}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Average Tickets">Average Commercial Ticket</td>
              <td class="number" data-label="Average Commercial Ticket">{{reportData.averageTickets.commercial | currency}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Average Tickets">Average Other Ticket</td>
              <td class="number" data-label="Average Other Ticket">{{reportData.averageTickets.other | currency}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mt-2 mb-2">
        <table>
          <thead>
            <th colspan="2" class="title">Inventory</th>
          </thead>
          <tbody>
            <tr>
              <td class="title" data-label="Inventory">Leased Inventory Costs<br><small class="text-muted">*Lease Model Only</small></td>
              <td class="number" data-label="Leased Inventory Costs">{{reportData.leasedInventoryCosts | currency}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Inventory">Unleased Inventory Costs</td>
              <td class="number" data-label="Unleased Inventory Costs">{{reportData.unleasedInventoryCosts | currency}}</td>
            </tr>
            <tr>
              <td class="title" data-label="Inventory">Potential Income From Unleased Inventory<br><small class="text-muted">*Based on Single price.</small></td>
              <td class="number" data-label="Potential Income From Unleased Inventory">{{reportData.potentialIncome | currency}}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>
