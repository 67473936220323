<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">Audit Log (Beta)</h2>
        </div>
    </nav>
    <div class="table-responsive mt-3">
        <table *ngIf="!isMobile" class="table-sm">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Source</th>
                    <th>Date</th>
                    <th>User</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let event of auditLogEvents" (click)="selectAuditLogEvent(event);">
                    <td data-label="Name">{{event.name}}</td>
                    <td data-label="Type">{{event.type}}</td>
                    <td data-label="Source">{{event.source}}</td>
                    <td data-label="Date">{{event.date | formatDateTime}}</td>
                    <td data-label="User">{{event.username}}</td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="isMobile" class="scrollable">
          <ul class="list-group">
              <li *ngFor="let event of auditLogEvents" class="list-group-item" style="cursor: pointer;" routerLink="/projects/{{project.id}}/summary">
                  <div class="d-flex list-group-container">
                      <div>
                          <strong>{{event.name}}</strong>
                          <div>{{event.type}}</div>
                          <div>{{event.source}}</div>
                          <div>{{event.date | formatDateTime}}</div>
                          <div>{{event.username}}</div>
                      </div>
                  </div>
              </li>
              <li *ngIf="auditLogEvents.length == 0" class="list-group-item">No Audit Logs</li>
          </ul>
        </div>
    </div>


    <div class="row table-controls">
        <div *ngIf="tableControls.isNotDefault() && !isMobile" class="col-auto mt-3 me-2 me-md-0">
            <button type="button" class="btn btn-outline-danger" (click)="tableControls.resetTableConfiguration();">
                <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
                <span>Reset</span>
            </button>
        </div>
        <div class="col mt-3">
            <div ngbDropdown placement="top-left" class="d-inline-block">
                <button class="btn btn-outline-primary" ngbDropdownToggle><span *ngIf="!isMobile">Page Size: </span> {{tableControls.getLimit()}}</button>
                <div ngbDropdownMenu class="page-size-dropdown" *ngIf="!isMobile">
                  <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(50);">50</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(100);">100</button>
                </div>
                <div ngbDropdownMenu class="page-size-dropdown" *ngIf="isMobile">
                  <button ngbDropdownItem (click)="tableControls.setLimit(5);">5</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(10);">10</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
                </div>
            </div>
        </div>
        <div class="col my-3">
            <ngb-pagination class="d-flex justify-content-end" *ngIf="auditLogEventCount > 0" [page]="tableControls.getCurrentPage()" [pageSize]="tableControls.getLimit()" [collectionSize]="auditLogEventCount" [boundaryLinks]="true" [maxSize]="(isMobile) ? 3 : 10" [ellipses]="true" [rotate]="true" (pageChange)="tableControls.setCurrentPage($event);"></ngb-pagination>
        </div>
    </div>
</div>

<!-- Audit Log Event Modal -->
<ng-template #AUDIT_LOG_EVENT_MODAL let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Audit Log Event</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss();"></button>
    </div>
    <div class="modal-body">
        <!-- Static Properties -->
        <ul class="list-group">
            <li class="list-group-item d-flex">
                <strong>Name</strong>
                <div class="ms-auto">{{selectedAuditLogEvent.name}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Type</strong>
                <div class="ms-auto">{{selectedAuditLogEvent.type}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Date</strong>
                <div class="ms-auto">{{selectedAuditLogEvent.date | formatDateTime}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>Source</strong>
                <div class="ms-auto">{{selectedAuditLogEvent.source}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>IP Address</strong>
                <div class="ms-auto">{{selectedAuditLogEvent.ipAddress}}</div>
            </li>
            <li class="list-group-item d-flex">
                <strong>User</strong>
                <div class="ms-auto">{{selectedAuditLogEvent.username}}</div>
            </li>
        </ul>
        <!-- Request Data -->
        <div *ngIf="selectedAuditLogEventRequestData" class="card mt-3">
            <div class="card-body">
                <strong class="card-title">Request Data</strong>
                <pre class="card-text">{{selectedAuditLogEventRequestData}}</pre>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss();">Close</button>
    </div>
</ng-template>
