import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, lastValueFrom, merge, Observable, Subject, switchMap } from 'rxjs';
import { ClientService } from '../client.service';
import { SiteService } from '../site.service';

@Component({
  selector: 'app-new-client-modal',
  templateUrl: './new-client-modal.component.html',
  styleUrls: ['./new-client-modal.component.css']
})
export class NewClientModalComponent implements OnInit {

  // Properties
  clientForm: UntypedFormGroup;

  // Lead Type Typeahead
  leadTypeTypeaheadInput: (text$: Observable<string>) => Observable<string[]>;
  leadTypeTypeaheadInputFocus$ = new Subject<string>();

  // Font Awesome Properties
  faQuestionCircle = faQuestionCircle;

  constructor(public modal: NgbActiveModal,
    private router: Router,
    private clientService: ClientService,
    private siteService: SiteService) { }

  ngOnInit(): void {
    this.clientForm = new UntypedFormGroup({
      name: new UntypedFormControl(),
      type: new UntypedFormControl('RESIDENTIAL'),
      leadType: new UntypedFormControl(),
      status: new UntypedFormControl('PROSPECT'),
      street: new UntypedFormControl(),
      city: new UntypedFormControl(),
      county: new UntypedFormControl(),
      state: new UntypedFormControl('SAS'),
      postalCode: new UntypedFormControl(),
      autoCreateSite: new UntypedFormControl(true)
    });
    this.prepareLeadTypeTypeahead();
  }

  // Get Lead Types
  private getLeadTypes(term: string): Observable<string[]> {
    const params = {
      searchTerm: term,
      sortBy: 'client_lead_type',
      sortDirection: 'ASC',
      limit: 10,
      offset: 0,
      column: 'client_lead_type',
      hideBlanks: true
    };
    return this.clientService.getDistinctColumnValues(params);
  }

  // Prepare Lead Type Typeahead
  private prepareLeadTypeTypeahead(): void {
    this.leadTypeTypeaheadInput = (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(250), distinctUntilChanged());
      return merge(debouncedText$, this.leadTypeTypeaheadInputFocus$).pipe(switchMap((term) => {
        return this.getLeadTypes((term.length == 0) ? null : term);
      }));
    }
  }

  // Add Client
  async addClient(): Promise<void> {
    if (this.clientForm.invalid) {
      this.clientForm.markAllAsTouched();
      return;
    }
    // Create Client
    const client = {
      name: this.clientForm.value.name,
      address: {
        street: this.clientForm.value.street,
        city: this.clientForm.value.city,
        county: this.clientForm.value.county,
        state: this.clientForm.value.state,
        postalCode: this.clientForm.value.postalCode,
      },
      type: this.clientForm.value.type,
      leadType: this.clientForm.value.leadType,
      status: this.clientForm.value.status
    };
    const clientId = await lastValueFrom(this.clientService.addClient(client));
    if (!this.clientForm.value.autoCreateSite) {
      this.router.navigateByUrl(`/clients/${clientId}`);
      return;
    }
    // Create Site
    const site = {
      clientId: clientId,
      name: (this.clientForm.value.type == 'RESIDENTIAL') ? 'Residence' : 'Site #1',
      address: {
        street: this.clientForm.value.street,
        city: this.clientForm.value.city,
        county: this.clientForm.value.county,
        state: this.clientForm.value.state,
        postalCode: this.clientForm.value.postalCode
      }
    };
    await lastValueFrom(this.siteService.addSite(site));
    // Navigate To Client Detail Page
    this.router.navigateByUrl(`/clients/${clientId}`);
  }

  // Client Form Accessors
  get name() { return this.clientForm.controls.name; }
  get type() { return this.clientForm.controls.type; }
  get leadType() { return this.clientForm.controls.leadType; }
  get status() { return this.clientForm.controls.status; }
}
