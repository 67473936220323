import { Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { faAngleLeft, faFileDownload, faFilter, faRotateLeft, faSortDown, faSortUp, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../analytics.service';
import { DatePickerControllerComponent } from '../date-picker-controller/date-picker-controller.component';
import { Payment } from '../payment';
import { PaymentCategoryPipe } from '../payment-category.pipe';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';
import { PaymentStatusPipe } from '../payment-status.pipe';
import { PaymentService } from '../payment.service';
import { TableControlService } from '../table-control.service';
import { Subject, debounceTime } from 'rxjs';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-reports-payments',
  templateUrl: './reports-payments.component.html',
  styleUrls: ['./reports-payments.component.css'],
  providers: [PaymentCategoryPipe, PaymentStatusPipe]
})
export class ReportsPaymentsComponent implements OnInit, AfterViewInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Properties
  @ViewChild('datePickerController') private datePickerController: DatePickerControllerComponent;
  payments: Payment[] = [];
  paymentCount: number = 0;
  startDate: string;
  endDate: string;
  isMobileView: boolean = false;

  categoryChartData: any[];
  categoryTotal: number = 0;
  categoryChart: Chart;

  monthlyVolumeChartData: any[];
  monthlyVolumeTotal: number = 0;
  monthlyVolumeChart: Chart;

  private CATEGORIES: any = {
    'CARD': { position: 1, backgroundColor: 'rgba(255, 99, 132, 0.5)' },
    'ACH': { position: 2, backgroundColor: 'rgba(255, 159, 64, 0.5)' },
    'CHECK': { position: 3, backgroundColor: 'rgba(0, 0, 255, 0.5)' },
    'CASH': { position: 4, backgroundColor: 'rgba(16, 94, 34, 0.5)' },
    'PAYPAL': { position: 5, backgroundColor: 'rgba(255, 204, 0, 0.5)' }, // Official Color
    'VENMO': { position: 6, backgroundColor: 'rgba(0, 140, 255, 0.5)' }, // Official Color
    'CASH APP': { position: 7, backgroundColor: 'rgba(4, 140, 44, 0.5)' }, // Official Color
    'ZELLE': { position: 8, backgroundColor: 'rgba(103, 28, 202, 0.5)' }, // Official Color
    'OTHER': { position: 9, backgroundColor: 'rgba(128, 128, 128, 0.5)' }
  };

  sortOptions = [
    { label: 'Client', value: 'c.client_name' },
    { label: 'Invoice #', value: 'i.invoice_number' },
    { label: 'Date', value: 'pay.payment_date, pay.payment_created_at' },
    { label: 'Category', value: 'pay.payment_category' },
    { label: 'Status', value: 'pay.payment_status' },
  ];
  filterColumns = [
    { label: 'Category', value: 'pay.payment_category' },
    { label: 'Status', value: 'pay.payment_status' },
  ];
  sortBy: string = 'c.client_name';
  sortDirection: string = 'ASC';

  // Table Controls
  tableControls: TableControlService;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;
  selectedFilterColumn: string ='';

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faRotateLeft = faRotateLeft;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;
  faFileDownload = faFileDownload;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(private paymentService: PaymentService,
    private analyticsService: AnalyticsService,
    private modalService: NgbModal,
    private paymentCategoryPipe: PaymentCategoryPipe,
    private paymentStatusPipe: PaymentStatusPipe,
    public location: Location,
    private loaderService: LoaderService) {
      this.resizeSubject.pipe(
        debounceTime(100)
      ).subscribe(width => {
        if (width <= 768) {
          this.checkScreenSize(width);
        } else {
          this.isMobileView = false;
          this.tableControls.setLimit(20);
        }
      });
    }

  ngOnInit(): void {
    this.tableControls = new TableControlService('Reports.Payments', true, 'pay.payment_date, pay.payment_created_at', 'DESC');

    this.checkScreenSize();
    if(this.isMobile || this.isMobileView) {
      let activeFilters = this.tableControls.getActiveFilters()
      let columns = [];
      for (const [key, value] of activeFilters) {
        columns.push(key)
      }
      this.tableControls.resetFilters(columns)
    }
    this.tableControls.refresh.subscribe(() => {
      this.getPayments();
      this.getCategoryChartData();
      this.getMonthlyVolumeChartData();
    });
  }

  ngAfterViewInit(): void {
    this.tableControls.datePickerController = this.datePickerController;
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Payment Modal
  openPaymentModal(payment: Payment): void {
    const modalRef = this.modalService.open(PaymentModalComponent);
    modalRef.componentInstance.payment = payment;
    modalRef.componentInstance.showInvoiceLink = true;
    modalRef.componentInstance.saved.subscribe(() => {
      this.getPayments();
    });
  }

  // Date Changed
  dateChanged(event: { startDate: string, endDate: string, isAuto: boolean }): void {
    this.loaderService.showSpinner();
    this.startDate = event.startDate;
    this.endDate = event.endDate;
    this.tableControls.addRangeFilter('pay.payment_date', this.startDate, this.endDate, event.isAuto);
  }

  // Get Payments
  private getPayments(): void {
    let params = this.tableControls.getParams();
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
      for (const [key, value] of this.activeFilters) {
        if (key.includes('range')) params[`filter:${key}`] = value;
        else params[`filter:${key}`] = JSON.stringify(value);
      }
    };
    this.paymentService.getPayments(params).subscribe( {
      next: (res) => {
        this.payments = res.payments;
        this.paymentCount = res.count;
        this.loaderService.hideSpinner(700);
      },
      error: () => {
        this.loaderService.hideSpinner(700);
      }
    });
  }

  // Get Category Chart Data
  private getCategoryChartData(): void {
    const params: any = {
      startDate: this.startDate,
      endDate: this.endDate
    };
    this.analyticsService.getPaymentCategoryChartData(params).subscribe((chartData) => {
      this.categoryChartData = (chartData as any[]).sort((d1, d2) => { return this.CATEGORIES[d1.category].position - this.CATEGORIES[d2.category].position; }).map((data: any) => {
        return { label: this.paymentCategoryPipe.transform(data.category), value: data.total, backgroundColor: this.CATEGORIES[data.category].backgroundColor };
      });
      this.categoryTotal = (chartData as any[]).reduce((acc: number, data: any) => { return acc + data.total; }, 0);
      if (!this.categoryChart) {
        this.generateCategoryChart();
      } else {
        if (this.categoryChartData.length === 0) {
          this.categoryChart.data.labels.length = 0;
          this.categoryChart.data.datasets[0].data.length = 0;
        } else {
          this.categoryChart.data.labels = this.categoryChartData.map((chartData) => { return chartData.label; });
          this.categoryChart.data.datasets[0].data = this.categoryChartData.map((chartData) => { return chartData.value; });
          this.categoryChart.data.datasets[0].backgroundColor = this.categoryChartData.map((chartData) => { return chartData.backgroundColor; });
        }
        this.categoryChart.update();
      }
    });
  }

  // Get Monthly Volume Chart Data
  private getMonthlyVolumeChartData(): void {
    const params: any = {
      startDate: this.startDate,
      endDate: this.endDate
    };
    this.analyticsService.getPaymentMonthlyVolumeChartData(params).subscribe((chartData) => {
      this.monthlyVolumeChartData = (chartData as any[]).map((data: any) => {
        return { label: dayjs().month(data.month - 1).format('MMMM'), value: data.total };
      });
      this.monthlyVolumeTotal = (chartData as any[]).reduce((acc: number, data: any) => { return acc + data.total; }, 0);
      if (!this.monthlyVolumeChart) {
        this.generateMonthlyVolumeChart();
      } else {
        if (this.monthlyVolumeChartData.length === 0) {
          this.monthlyVolumeChart.data.labels.length = 0;
          this.monthlyVolumeChart.data.datasets[0].data.length = 0;
        } else {
          this.monthlyVolumeChart.data.labels = this.monthlyVolumeChartData.map((chartData) => { return chartData.label; });
          this.monthlyVolumeChart.data.datasets[0].data = this.monthlyVolumeChartData.map((chartData) => { return chartData.value; });
        }
        this.monthlyVolumeChart.update();
      }
    });
  }

  /* ----- Chart ----- */

  // Generate Category Chart
  private generateCategoryChart(): void {
    const ctx = (document.getElementById('CATEGORY_CHART') as HTMLCanvasElement);
    this.categoryChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: this.categoryChartData.map((chartData) => { return chartData.label; }),
        datasets: [
          {
            label: null,
            data: this.categoryChartData.map((chartData) => { return chartData.value; }),
            backgroundColor: this.categoryChartData.map((chartData) => { return chartData.backgroundColor; })
          }
        ]
      },
      plugins: [
        ChartDataLabels
      ],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            labels: {
              color: 'black',
              font: {
                family: "'Poppins', sans-serif"
              }
            },
            position: 'bottom'
          },
          tooltip: {
            enabled: true,
            backgroundColor: 'rgba(0, 0, 0, 1)',
            xAlign: 'center',
            yAlign: 'bottom',
            boxPadding: 6,
            callbacks: {
              label: (context) => {
                let label = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed);
                label += ' (' + ((context.parsed / this.categoryTotal) * 100).toFixed(2) + '%)';
                return label;
              }
            }
          },
          datalabels: {
            color: 'black',
            font: {
              family: "'Poppins', sans-serif",
              weight: 600
            },
            display: 'auto',
            formatter: (value) => {
              if (value > 0) {
                let label = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
                label += '\n(' + ((value / this.categoryTotal) * 100).toFixed(2) + '%)';
                return label;
              }
              return null;
            },
            textAlign: 'center'
          }
        }
      }
    });
  }

  // Generate Monthly Volume Chart
  private generateMonthlyVolumeChart(): void {
    const ctx = (document.getElementById('MONTHLY_VOLUME_CHART') as HTMLCanvasElement);
    this.monthlyVolumeChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.monthlyVolumeChartData.map((chartData) => { return chartData.label; }),
        datasets: [
          {
            label: null,
            data: this.monthlyVolumeChartData.map((chartData) => { return chartData.value; }),
            backgroundColor: 'rgb(16, 94, 34, 0.5)'
          }
        ]
      },
      plugins: [ChartDataLabels],
      options: {
        events: null,
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 15
          }
        },
        scales: {
          x: {
            display: true,
            stacked: false
          },
          y: {
            stacked: false,
            ticks: {
              callback: (value) => {
                return '$' + value;
              }
            }
          }
        },
        plugins: {
          legend: {
            display: false,
            labels: {
              color: 'black',
              font: {
                family: "'Poppins', sans-serif"
              }
            },
            position: 'bottom'
          },
          tooltip: {
            enabled: false
          },
          datalabels: {
            align: 'top',
            anchor: 'end',
            color: 'black',
            font: {
              family: "'Poppins', sans-serif",
              weight: 600
            },
            formatter: (value) => {
              return (value > 0) ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value) : null;
            },
            offset: -2.5,
            textAlign: 'center'
          }
        }
      }
    });
  }

  // Download Report
  downloadReport(): void {
    const searchParams = this.tableControls.getUrlSearchParams();
    searchParams.delete('limit');
    searchParams.delete('offset');
    const url = new URL('/api/reports/finances/payments/download?' + searchParams.toString(), environment.apiUrl);
    window.open(url.toString());
  }

  /* ----- Filters and Sorting ----- */

  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    this.selectedFilterColumn = column;
    if (!this.columnFilterValues.has(column)) {
      const params = {
        searchTerm: null,
        sortBy: column,
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        column: column
      };
      if(this.isMobile) {
        for (const [key, value] of this.activeFilters) {
          if (key.includes('range')) params[`filter:${key}`] = value;
          else params[`filter:${key}`] = JSON.stringify(value);
        }
      }
      this.paymentService.getDistinctColumnValues(params).subscribe((values) => {
        const tempArray = [];
        for (const value of values) {
          let name = null;
          switch (column) {
            case 'pay.payment_category':
              name = this.paymentCategoryPipe.transform(value);
              break;
            case 'pay.payment_status':
              name = this.paymentStatusPipe.transform(value);
              break;
            default:
              name = (value === null || value.length == 0) ? 'BLANK' : value;
              break;
          }
          tempArray.push({ name: name, value: value, randomId: Math.random().toString(36) });
        }
        this.columnFilterValues.set(column, tempArray);
      });
    }
  }

  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getPayments();
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getPayments();
    this.getActiveFilterCount();
  }

   // Get Active Filter Count
   getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getPayments();
    this.getActiveFilterCount();
  }

  // Show Column Filter Popover
  showColumnFilterPopover(popover, column: string): void {
    this.getDistinctColumnValues(column);
    if (popover.isOpen()) {
      popover.close();
    } else {
      setTimeout(() => {
        popover.open({ column });
      }, 250);
    }
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
    this.isMobileView ? this.tableControls.setLimit(5): this.tableControls.setLimit(20);
  }
}
