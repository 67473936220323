<div *ngIf="!amountConfirmed">
    <div class="input-group">
        <span class="input-group-text">$</span>
        <input id="AMOUNT" type="number" class="form-control" step="0.01" [value]="amount" (input)="amountChanged($event);" [class.is-invalid]="remainingBalance > 0 && remainingBalance < 1">
        <button type="button" class="btn btn-outline-primary" (click)="confirmAmountWithCard();" [disabled]="remainingBalance > 0 && remainingBalance < 1">
            <fa-icon [icon]="faCheck" class="button-icon-left"></fa-icon>
            <span>Confirm</span>
        </button>
    </div>
    <div *ngIf="remainingBalance > 0 && remainingBalance < 1" class="input-validation-msg">Remaining balance cannot be less than $1.00.</div>
</div>
<div *ngIf="amountConfirmed">
    <div>
        <div class="d-flex">
            <div>
                <div class="fw-bold">Amount</div>
                <div *ngIf="passFee" class="fw-bold">Processing Fee</div>
                <div *ngIf="passFee" class="fw-bold">Total</div>
            </div>
            <div class="ms-auto text-end">
                <div>{{amount | currency}}</div>
                <div *ngIf="passFee">{{fee | currency}}</div>
                <div *ngIf="passFee">{{amountWithFee | currency}}</div>
            </div>
        </div>
    </div>
    <div *ngIf="tinselPayCardVaults.length > 0" class="mt-2">
        <ul class="list-group scrollable" style="max-height: 87px;">
            <li *ngFor="let tinselPayCardVault of tinselPayCardVaults" class="list-group-item" style="cursor: pointer;" (click)="cardVaultSelected(tinselPayCardVault.id);">
                <div class="d-flex">
                    <div class="my-auto">
                        <img [src]="getCardPaymentIcon(tinselPayCardVault.details.type)" width="37.5" height="auto">
                    </div>
                    <div class="ms-3">&#8226;&#8226;&#8226;&#8226; <span style="font-weight: 600;">{{tinselPayCardVault.details.lastFour}}</span>, Exp <span style="font-weight: 600;">{{tinselPayCardVault.details.expiration}}</span></div>
                    <div *ngIf="selectedCardVaultId && selectedCardVaultId == tinselPayCardVault.id" class="ms-auto">
                        <fa-icon [icon]="faCheck" size="lg"></fa-icon>
                    </div>
                    <div *ngIf="!selectedCardVaultId || (selectedCardVaultId && selectedCardVaultId != tinselPayCardVault.id)" class="ms-auto" (click)="$event.stopPropagation(); deleteTinselPayCardVault(tinselPayCardVault.id);">
                        <fa-icon [icon]="faTrash"></fa-icon>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div *ngIf="paymentFormIsLoading" class="mt-2 d-flex">
        <div class="mx-auto d-flex">
            <strong>Loading...</strong>
            <fa-icon [icon]="faCircleNotch" spin="true" class="ms-2"></fa-icon>
        </div>
    </div>
    <div *ngIf="!selectedCardVaultId" class="mt-2 row" [class.mb-2]="doesPaymentFormHaveError()">
        <div id="ccnumber" class="col-12 col-md-6"></div>
        <div id="ccexp" class="col-6 col-md-3 mt-2 mt-md-0"></div>
        <div id="cvv" class="col-6 col-md-3 mt-2 mt-md-0"></div>
    </div>
    <div class="d-flex flex-column">
        <small *ngIf="paymentFormValidation.ccnumber.hasError" class="input-validation-msg">
            <fa-icon [icon]="faCircleExclamation"></fa-icon>
            <span class="ms-2">{{paymentFormValidation.ccnumber.errorMessage}}</span>
        </small>
        <small *ngIf="paymentFormValidation.ccexp.hasError" class="input-validation-msg">
            <fa-icon [icon]="faCircleExclamation"></fa-icon>
            <span class="ms-2">{{paymentFormValidation.ccexp.errorMessage}}</span>
        </small>
        <small *ngIf="paymentFormValidation.cvv.hasError" class="input-validation-msg">
            <fa-icon [icon]="faCircleExclamation"></fa-icon>
            <span class="ms-2">{{paymentFormValidation.cvv.errorMessage}}</span>
        </small>
    </div>
    <div *ngIf="paymentProcessingError" class="d-flex mt-2">
        <small class="input-validation-msg">
            <fa-icon [icon]="faCircleExclamation"></fa-icon>
            <span class="ms-2">{{paymentProcessingError.message}}: {{paymentProcessingError.description}}</span>
        </small>
    </div>
    <div *ngIf="!selectedCardVaultId" class="mt-2">
        <div class="form-check">
            <input id="SAVE_CARD" type="checkbox" class="form-check-input" style="margin-left: -1.25em;">
            <label for="SAVE_CARD" class="form-check-label">Save Card</label>
        </div>
    </div>
    <div class="mt-2">
        <button type="button" class="btn btn-primary w-100" (click)="payWithCard();" [disabled]="(!isPaymentFormValid() && !selectedCardVaultId) || paymentInProgress">{{paymentButtonText}}</button>
    </div>
</div>