import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../alert.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-tinsel-pay-sign-up-modal',
  templateUrl: './tinsel-pay-sign-up-modal.component.html',
  styleUrls: ['./tinsel-pay-sign-up-modal.component.css']
})
export class TinselPaySignUpModalComponent implements OnInit {

  // Properties
  tinselPaySignUpForm: UntypedFormGroup;

  constructor(private authService: AuthService,
    private alertService: AlertService,
    public modal: NgbActiveModal) { }

  ngOnInit(): void {
    this.tinselPaySignUpForm = new UntypedFormGroup({
      processCard: new UntypedFormControl(false),
      passFee: new UntypedFormControl(false),
      processAch: new UntypedFormControl(false)
    });
  }

  // Go To Application
  async goToApplication(): Promise<void> {
    const currentUser = await this.authService.getCurrentUser();
    if (this.tinselPaySignUpForm.valid) {
      if (this.processCard.value && this.passFee.value && this.processAch.value) {
        window.open(`https://onlineapp.cliq.com/app/01H0NXAZVGRAP698PE5JQMWMHE?CustomerId=${currentUser.organizationId}`); // Cash Discount CC + ACH
      } else if (this.processCard.value && this.passFee.value && !this.processAch.value) {
        window.open(`https://onlineapp.cliq.com/app/01H0NXAK4H797191957A9ZZBFP?CustomerId=${currentUser.organizationId}`); // Cash Discount CC Only
      } else if (this.processCard.value && !this.passFee.value && this.processAch.value) {
        window.open(`https://onlineapp.cliq.com/app/01H0NXCX0Q2M5N1SBBCXMBK1R5?CustomerId=${currentUser.organizationId}`); // Flat Rate CC + ACH
      } else if (this.processCard.value && !this.passFee.value && !this.processAch.value) {
        window.open(`https://onlineapp.cliq.com/app/01H0NXBX9QY44P1BBYB1JQGHCK?CustomerId=${currentUser.organizationId}`); // Flat Rate CC Only
      } else if (!this.processCard.value && this.processAch.value) {
        window.open(`https://onlineapp.cliq.com/app/01H0NXDXD729SW9CPPG068WKJC?CustomerId=${currentUser.organizationId}`); // ACH Only
      } else {
        console.log('INVALID');
      }
    } else {
      this.alertService.showSuccessAlert('Form is invalid.');
    }
  }

  // Tinsel Pay Sign Up Form Accessors
  get processCard() { return this.tinselPaySignUpForm.controls.processCard; }
  get passFee() { return this.tinselPaySignUpForm.controls.passFee; }
  get processAch() { return this.tinselPaySignUpForm.controls.processAch; }
}
