import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';
import { faAngleLeft, faSave, faTrashCan, faStar, faDownload, faTriangleExclamation, faPlus, faSortUp, faSortDown, faFilter, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import { TableControlService } from '../table-control.service';
import { InvoiceService } from '../invoice.service';
import { Invoice } from '../invoice';
import { InvoiceStatusPipe } from '../invoice-status.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-client-detail-invoices',
  templateUrl: './client-detail-invoices.component.html',
  styleUrls: ['./client-detail-invoices.component.css'],
  providers: [InvoiceStatusPipe]
})
export class ClientDetailInvoicesComponent implements OnInit {
  //Screensize event Listener
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next((event.target as Window).innerWidth);
  }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  // Client
  @Input() clientId: string;

  // Filters
  columnFilterValues = new Map();
  activeFilters = new Map();
  activeFilterCount: number = 0;
  selectedFilterColumn: string ='';

  // Invoices
  invoices: Invoice[] = [];

  // Table Controls
  tableControls: TableControlService;
  storedSearchValue: string;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faSave = faSave;
  faTrashCan = faTrashCan;
  faStar = faStar;
  faDownload = faDownload;
  faTriangleExclamation = faTriangleExclamation;
  faPlus = faPlus;
  faSortUp = faSortUp;
  faSortDown = faSortDown;
  faFilter = faFilter;
  faSortAmountDownAlt = faSortAmountDownAlt;

  // Properties
  isMobileView: boolean = false;

  sortOptions = [
    { label: 'Invoice #', value: 'i.invoice_number' },
    { label: 'Amount', value: 'i.invoice_amount' },
    { label: 'Status', value: 'i.invoice_status' },
    { label: 'Issue Date', value: 'i.invoice_issue_date' },
    { label: 'Due Date', value: 'i.invoice_due_date' },
  ];
  filterColumns = [
    { label: 'Status', value: 'i.invoice_status' },
  ];
  sortBy: string = 'i.invoice_number';
  sortDirection: string = 'ASC';

  // Actions
  selectedClientId: string;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(
    private invoiceService: InvoiceService,
    private modalService: NgbModal,
    private invoiceStatusPipe: InvoiceStatusPipe) {
    this.resizeSubject.pipe(
      debounceTime(100)
    ).subscribe(width => {
      if (width <= 768) {
        this.checkScreenSize(width);
      } else {
        this.isMobileView = false;
      }
    });
  }

  ngOnInit() {
    this.tableControls = new TableControlService('ClientDetail.Invoices', true, 'i.invoice_number', 'DESC');
    this.checkScreenSize();
    if(this.isMobile) {
      let activeFilters = this.tableControls.getActiveFilters()
      let columns = [];
      for (const [key, value] of activeFilters) {
        columns.push(key)
      }
      this.tableControls.resetFilters(columns)
    }
    this.tableControls.refresh.subscribe(() => {
      this.getInvoicesForClient();
    });
    this.getInvoicesForClient();
  }

  // Get Invoices For Client
  getInvoicesForClient(): void {
    let params = this.tableControls.getParams();
    params['filter:c.client_id'] = JSON.stringify([this.clientId]);
    if(this.isMobile) {
      params.sortBy = this.sortBy;
      params.sortDirection = this.sortDirection;
      for (const [key, value] of this.activeFilters) {
        if (key.includes('range')) params[`filter:${key}`] = value;
        else params[`filter:${key}`] = JSON.stringify(value);
      }
    };
    this.invoiceService.getInvoices(params).subscribe((res) => {
      this.invoices = res.invoices;
    });
  };

  // Show Column Filter Popover
  showColumnFilterPopover(popover, column: string): void {
    this.getDistinctColumnValues(column);
    if (popover.isOpen()) {
      popover.close();
    } else {
      setTimeout(() => {
        popover.open({ column });
      }, 250);
    }
  }

  /* ----- Filters and Sorting ----- */
  // Get Distinct Column Values
  getDistinctColumnValues(column: string): void {
    this.selectedFilterColumn = column;
    if (!this.columnFilterValues.has(column)) {
      const params = {
        searchTerm: null,
        sortBy: column,
        sortDirection: 'ASC',
        limit: null,
        offset: null,
        column: column
      };
      if(this.isMobile) {
        for (const [key, value] of this.activeFilters) {
          if (key.includes('range')) params[`filter:${key}`] = value;
          else params[`filter:${key}`] = JSON.stringify(value);
        }
      }
      this.invoiceService.getDistinctColumnValues(params).subscribe((values) => {
        const tempArray = [];
        for (const value of values) {
          let name = null;
          switch (column) {
            case 'i.invoice_status':
              name = this.invoiceStatusPipe.transform(value);
              break;
            default:
              name = (value === null || value.length == 0) ? 'BLANK' : value;
              break;
          }
          tempArray.push({ name: name, value: value, randomId: Math.random().toString(36) });
        }
        this.columnFilterValues.set(column, tempArray);
      });
    }
  }

  // Sorting
  sorting(column: string): void {
    if (column != this.sortBy) this.sortDirection = 'ASC';
    if (column == this.sortBy) this.sortDirection = (this.sortDirection == 'ASC') ? 'DESC' : 'ASC';
    this.sortBy = column;
    this.getInvoicesForClient();
  }

  // Toggle Filter
  toggleFilter(column: string, value: string): void {
    if (this.activeFilters.has(column)) {
      if (this.activeFilters.get(column).includes(value)) {
        let tempArray = this.activeFilters.get(column);
        tempArray = tempArray.filter((filterValue) => { return filterValue != value; });
        if (tempArray.length == 0) {
          this.activeFilters.delete(column);
        } else {
          this.activeFilters.set(column, tempArray);
        }
      } else {
        const tempArray = this.activeFilters.get(column);
        tempArray.push(value);
        this.activeFilters.set(column, tempArray);
      }
    } else {
      this.activeFilters.set(column, [value]);
    }
    this.getInvoicesForClient();
    this.getActiveFilterCount();
  }

   // Get Active Filter Count
   getActiveFilterCount(): void {
    this.activeFilterCount = 0;
    for (const value of this.activeFilters.values()) {
      this.activeFilterCount += value.length;
    }
  }

  // Filter Exists
  filterExists(column: string, value: string): boolean {
    return this.activeFilters.has(column) ? this.activeFilters.get(column).includes(value) : false;
  }

  // Reset Active Filters
  resetActiveFilters(): void {
    this.activeFilters.clear();
    this.getInvoicesForClient();
    this.getActiveFilterCount();
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }
}
