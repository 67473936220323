import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectService } from '../project.service';
import { Project } from '../project';
import { TableControlService } from '../table-control.service';

@Component({
  selector: 'app-proposal-reject-modal',
  templateUrl: './proposal-reject-modal.component.html',
  styleUrls: ['./proposal-reject-modal.component.css']
})
export class ProposalRejectModalComponent implements OnInit {
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();

  proposalRejectedModalForm: FormGroup;
  projects: Project[];
  tableControls: TableControlService;
  clientId: string;

  constructor(
    public modal: NgbActiveModal,
    private projectService: ProjectService
  ) { }

  ngOnInit(): void {
    this.tableControls = new TableControlService('ProposalRejectModal.Projects', false, 'p.project_name', 'DESC', [['c.client_id', [this.clientId]], ['p.project_status', ['IN PROGRESS']]]);

    this.proposalRejectedModalForm = new UntypedFormGroup({
      updateProjectStatus: new UntypedFormControl(false),
      updateClientStatus: new UntypedFormControl(false)
    });

    this.proposalRejectedModalForm.controls.updateClientStatus.valueChanges.subscribe((val: boolean) => {
      if (val) this.onChangingClientStatus();
    })

  }

  onChangingClientStatus() {
    const params = this.tableControls.getParams();
    this.projectService.getProjects(params).subscribe((res) => {
      this.projects = res.projects;
    });
  }

  onSubmit() {
    if (this.proposalRejectedModalForm.valid) {
      this.saved.emit(this.proposalRejectedModalForm.value);
      this.modal.close();
    } else {
      this.proposalRejectedModalForm.markAllAsTouched();
    }
  }
}
