import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallService {
  incomingCallValue = new BehaviorSubject(this.incomingCall);
  twilioDevice = new BehaviorSubject(this.device)
  activeCall = new BehaviorSubject(this.call)
  fromNumber = new BehaviorSubject(this.caller)
  isDialingValue = new BehaviorSubject(this.isDialing);
  callAccept = new BehaviorSubject(this.isCallAccept);
  callEnded = new BehaviorSubject(this.isCallEnded);

  // connectionInitialiazedValue = new BehaviorSubject(this.isDialing);
  // connectionStateFailedValue = new BehaviorSubject(this.connectionStateFailed);
  // conversationAddedValue = new BehaviorSubject(this.conversationAdded);
  // tokenExpiredValue = new BehaviorSubject(this.tokenExpired);
  // messageAddedValue = new BehaviorSubject(this.messageAdded);
  // typingStartedValue = new BehaviorSubject(this.typingStarted);
  // connectionErrorValue = new BehaviorSubject(this.connectionError);
  // chatListValue = new BehaviorSubject(this.chatList);
  // typingEndedValue = new BehaviorSubject(this.typingEnded);
  // unreadMessagesValue = new BehaviorSubject(this.unreadMessages);
  selectedContact: any = null;

  set isCallAccept(value: any) {
    this.callAccept.next(value);
  }

  get isCallAccept() {
    return this.callAccept;
  }

  set isCallEnded(value: any) {
    this.callAccept.next(value);
  }

  get isCallEnded() {
    return this.callEnded;
  }


  set device(value: any) {
    this.twilioDevice.next(value);
  }

  get device() {
    return this.twilioDevice;
  }

  set incomingCall(value: any) {
    this.incomingCallValue.next(value);
    this.selectedContact = value;
  }

  get incomingCall() {
    return this.incomingCallValue;
  }

  set call(value: any) {
    this.activeCall.next(value);
  }

  get call() {
    return this.activeCall;
  }

  set caller(value: any) {
    this.fromNumber.next(value);
  }

  get caller() {
    return this.fromNumber;
  }

  set isDialing(value: any) {
    this.isDialingValue.next(value);
    this.selectedContact = value;
  }

  get isDialing() {
    return this.isDialingValue;
  }

  constructor(private http: HttpClient) { }

}