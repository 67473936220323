import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { InventoryPrep } from '../inventory-prep';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-project-prep-cancel-order-modal',
  templateUrl: './project-prep-cancel-order-modal.component.html',
  styleUrls: ['./project-prep-cancel-order-modal.component.css']
})
export class ProjectPrepCancelOrderModalComponent implements OnInit {
  //Screensize event Listener
 @HostListener('window:resize', ['$event'])
 onResize(event: Event) {
   this.resizeSubject.next((event.target as Window).innerWidth);
 }

  // Variable Subject
  private resizeSubject: Subject<number> = new Subject<number>();

  @Input() itemName: string;
  @Input() projectPrepItems: InventoryPrep[];
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();

  cancelForm: FormGroup;
  cancels: any[] = [];
  chosenItems: any[] = [];
  isMobileView: boolean = false;

  // Get isMobile value in reactive way
  public get isMobile(): boolean {
    return this.isMobileView;
  }

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {
    this.resizeSubject.pipe(
      debounceTime(100)
    ).subscribe(width => {
      if (width <= 768) {
        this.checkScreenSize(width);
      } else {
        this.isMobileView = false;
      }
    });
  }

  ngOnInit(): void {
    this.cancelForm = this.formBuilder.group({
      allItem: [false],
      choices: new FormArray([])
    });

    this.cancelForm.controls['allItem'].valueChanges.subscribe(value => {
      const choices = this.cancelForm.controls['choices'] as FormArray;

      if (this.cancelForm.controls['choices'].value.length != 0) {
        choices.controls.forEach((fg: FormGroup, index) => {
          fg.controls[`${index}-checkbox`].setValue(value)
          fg.controls['isSelected'].setValue(value)
        })
      }

      this.chosenItems = choices.controls.filter(choice => choice.value['isSelected']).map(choice => choice.value)

    });

    this.checkScreenSize();
  }

  ngAfterViewInit(): void {
    this.setupForm();
  }

  setupForm(): void {
    let newFG = new FormArray([]);

    this.projectPrepItems.map((item, index) => {
      const buildCtrl = this.formBuilder.group({
        isSelected: false,
        [index + '-checkbox']: false
      }) as FormGroup;

      const orgCtrl = Object.keys(item);
      orgCtrl.forEach(value => buildCtrl.addControl(value, new FormControl(item[value])))
      newFG.push(buildCtrl);

    });

    this.cancelForm.controls['choices'] = newFG
    this.cancelForm.controls['choices'].patchValue(newFG.value, { emitEvent: false })
  }

  onSubmit() {
    const choices = this.cancelForm.controls['choices'] as FormArray;

    this.chosenItems = choices.controls.filter(choice => choice.value['isSelected']).map(choice => choice.value)

    if (this.chosenItems.length > 1) {
      this.chosenItems.forEach((item, index) => {
        this.cancels.push({
          inventoryPrepId: item.id,
          quantity: 0,
          source: this.projectPrepItems[0].itemSource
        });
      })
    } else {
      this.cancels = this.chosenItems.map(item => {
        return {
          inventoryPrepId: item.id,
          quantity: 0,
          source: this.projectPrepItems[0].itemSource
        }
      })
    }

    this.saved.emit(this.cancels);
    this.modal.close();
  }

  chosenQuantity(event, qty: number, index: number) {
    const choices = this.cancelForm.controls['choices'] as FormArray;
    const isChecked = event.target.checked;
    const choiceGroup = choices.controls[index] as FormGroup;

    choiceGroup.controls['isSelected'].setValue(isChecked);
    this.chosenItems = choices.controls.filter(choice => choice.value['isSelected']).map(choice => choice.value)

  }

  renderForm(): boolean {
    return this.cancelForm.controls['choices'].value.length === this.projectPrepItems.length;
  }

  //Set isMobile value and set table limit base on screen size
  checkScreenSize(width?: number) {
    let screenWidth = width || window.innerWidth;
    this.isMobileView =  screenWidth <= 768;
  }
}
