<div class="content-container">
  <div class="row">
    <div class="col-lg-12">
      <ngb-alert *ngIf="showCardAlert && login" [dismissible]="false"t type="danger">
        <div>
          You currently have no payment method on file. As a result, your subscription has not been paid in {{ login?.monthDelinquent }} months. Please add a payment method to continue using Tinsel CRM. Once added,
          <strong> you will be automatically charged with the total amount of {{ login?.amountOverdue | currency: 'USD'
              }}</strong>. 
          For assistance, please email <b><a href="mailto:support@tinselcrm.com">support@tinselcrm.com</a></b>
        </div>
      </ngb-alert>
    </div>
    <div class="col-lg-12">
      <app-settings-subscriptions></app-settings-subscriptions>
    </div>
  </div>
</div>