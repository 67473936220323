<div class="content-container">
  <nav class="navbar navbar-white bg-white sticky-top">
    <div class="d-grid gap-2 d-flex navbar-brand w-100">
      <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>
      <!-- Mobile Header -->
      <div class="row d-flex d-sm-none">
        <h2 class="mobileHeading col-6 ps-0">Settings</h2>
        <div class="text-muted col-6 align-self-end float-end ps-0 pe-0 ms-auto me-auto" style="text-align: right;">Version: {{this.version}}</div>
      </div>
      <h2 class="mobileHeading d-none d-md-block">Settings</h2>
      <div class="text-muted h-fit my-auto ms-auto d-none d-md-block">Version: {{this.version}}</div>
    </div>
  </nav>
  <div class="row mt-3">
    <div class="col-12 mb-3">
      <ul ngbNav #nav="ngbNav" class="nav-tabs" [activeId]="getCurrentTab()">
        <!-- Organization -->
        <li ngbNavItem="ORGANIZATION">
          <a ngbNavLink>Organization</a>
          <ng-template ngbNavContent>
            <app-settings-organization></app-settings-organization>
          </ng-template>
        </li>
        <!-- Users -->
        <li ngbNavItem="USERS">
          <a ngbNavLink>Users</a>
          <ng-template ngbNavContent>
            <app-settings-users></app-settings-users>
          </ng-template>
        </li>
        <!-- Projects -->
        <li ngbNavItem="PROJECTS">
          <a ngbNavLink>Projects</a>
          <ng-template ngbNavContent>
            <app-settings-projects></app-settings-projects>
          </ng-template>
        </li>
        <!-- Proposals -->
        <li ngbNavItem="PROPOSALS">
          <a ngbNavLink>Proposals</a>
          <ng-template ngbNavContent>
            <app-settings-proposals></app-settings-proposals>
          </ng-template>
        </li>
        <!-- Invoices -->
        <li ngbNavItem="INVOICES">
          <a ngbNavLink>Invoices</a>
          <ng-template ngbNavContent>
            <app-settings-invoices></app-settings-invoices>
          </ng-template>
        </li>
        <!--SMS/Calls-->
        <li ngbNavItem="SMS/Calls">
          <a ngbNavLink>SMS/Calls</a>
          <ng-template ngbNavContent>
            <app-settings-sms-call></app-settings-sms-call>
          </ng-template>
        </li>
        <!-- Emails -->
        <li ngbNavItem="EMAILS">
          <a ngbNavLink>Emails</a>
          <ng-template ngbNavContent>
            <app-settings-emails></app-settings-emails>
          </ng-template>
        </li>
        <!-- Payments -->
        <li ngbNavItem="PAYMENTS">
          <a ngbNavLink>Payments</a>
          <ng-template ngbNavContent>
            <app-settings-payments></app-settings-payments>
          </ng-template>
        </li>
        <!-- Subscriptions -->
        <li ngbNavItem="SUBSCRIPTIONS" *ngIf="user.role != 'FRANCHISEE'">
          <a ngbNavLink>Subscriptions</a>
          <ng-template ngbNavContent>
            <app-settings-subscriptions></app-settings-subscriptions>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-4"></div>
    </div>
  </div>
</div>
