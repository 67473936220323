import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Invoice } from '../invoice';
import { InvoiceService } from '../invoice.service';
import { AlertService } from '../alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ProposalService } from '../proposal.service';
import { PaymentService } from '../payment.service';
import { Payment } from '../payment';
import { SendEmailComponent } from '../send-email/send-email.component';
import { NgbModal, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { faAngleLeft, faTrashCan, faEnvelope, faEye, faCalendar, faCheck, faTimes, faSave, faExternalLinkAlt, faPlus, faQuestionCircle, faPencil, faSms } from '@fortawesome/free-solid-svg-icons';
import { AcceptedProposal } from '../accepted-proposal';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';
import { SendSmsDialogComponent } from '../conversation/components/send-sms-dialog/send-sms-dialog.component';
import { ConversationService } from '../conversation.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.css']
})
export class InvoiceDetailComponent implements OnInit {

  // Forms
  invoiceForm: UntypedFormGroup;
  sendInvoiceForm: UntypedFormGroup;

  // Invoice
  invoiceId: string;
  invoice: Invoice;

  // Accepted Proposal
  acceptedProposal: AcceptedProposal;

  // Payments
  payments: Payment[] = [];
  totalDebitAmount: number = 0;
  totalCreditAmount: number = 0;
  isPaid: boolean = false;
  remainingBalance: number;

  // Font Awesome Properties
  faAngleLeft = faAngleLeft;
  faTrashCan = faTrashCan;
  faEnvelope = faEnvelope;
  faEye = faEye;
  faCalendar = faCalendar;
  faCheck = faCheck;
  faTimes = faTimes;
  faSave = faSave;
  faExternalLinkAlt = faExternalLinkAlt;
  faPlus = faPlus;
  faQuestionCircle = faQuestionCircle;
  faPencil = faPencil;
  faSms = faSms;
  invoices: Invoice[] = [];
  twilioClient: any;
  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(private invoiceService: InvoiceService,
    private paymentService: PaymentService,
    private proposalService: ProposalService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private modalService: NgbModal,
    private calendar: NgbCalendar,
    private dateParserFormatter: NgbDateParserFormatter,
    public location: Location,
    private conversationService: ConversationService) { }

  ngOnInit(): void {
    this.invoiceId = this.route.snapshot.paramMap.get('invoiceId');
    this.invoiceForm = new UntypedFormGroup({
      balanceType: new UntypedFormControl('PERCENT'),
      balance: new UntypedFormControl(50),
      issueDate: new UntypedFormControl(this.calendar.getToday()),
      dueDate: new UntypedFormControl(this.calendar.getNext(this.calendar.getToday(), 'd', 30)),
      status: new UntypedFormControl('PENDING')
    });
    this.getInvoice();
    this.listenForBalanceTypeChanges();
    this.listenForIssueDateChanges();

    this.conversationService.twilioClient
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.twilioClient = data;
    });

  }

  unlockInvoiceForm() {
    this.invoiceForm.enable();
  }

  ngOnDestroy(): void {
    this.modalService.hasOpenModals() && this.modalService.dismissAll();
  }

  // Open Delete Invoice Confirmation Modal
  openDeleteInvoiceConfirmationModal(): void {
    const confirmationModalRef = this.modalService.open(ConfirmationModalComponent);
    confirmationModalRef.componentInstance.message = "Are you sure you would like to delete this invoice?";
    confirmationModalRef.componentInstance.actionBtnTitle = "Delete";
    confirmationModalRef.componentInstance.confirmed.subscribe(() => {
      this.deleteInvoice();
    });
  }

  // Open Send Email Modal For Invoice
  openSendEmailModalForInvoice(): void {
    const modalRef = this.modalService.open(SendEmailComponent);
    modalRef.componentInstance.clientId = this.invoice.clientId;
    modalRef.componentInstance.invoiceId = this.invoice.id;
    modalRef.componentInstance.type = 'INVOICE';
    modalRef.componentInstance.subject = `Invoice #${this.invoice.number}`;
    modalRef.componentInstance.emailSent.subscribe(() => {
      this.getInvoice();
    });
  }

  // Open Payment Modal
  openPaymentModal(payment: Payment = null): void {
    const modalRef = this.modalService.open(PaymentModalComponent);
    modalRef.componentInstance.clientId = this.invoice.clientId;
    modalRef.componentInstance.invoiceId = this.invoice.id;
    modalRef.componentInstance.invoiceNumber = this.invoice.number;
    if (payment) modalRef.componentInstance.payment = payment;
    modalRef.componentInstance.saved.subscribe(() => {
      this.getInvoice();
    });
  }

  // Listen For Balance Type Changes
  private listenForBalanceTypeChanges(): void {
    this.invoiceForm.controls.balanceType.valueChanges.subscribe((value) => {
      if (value == 'DOLLAR') {
        this.invoiceForm.controls.balance.setValue(this.remainingBalance ? Math.round((this.remainingBalance + Number.EPSILON) * 100) / 100 : this.invoice.amount);
      } else {
        if (this.acceptedProposal) {
          const percent = parseFloat(this.invoiceForm.controls['balance'].value) / this.acceptedProposal.total;
          this.invoiceForm.controls.balance.setValue((percent * 100).toFixed(2));
        } else {
          this.invoiceForm.controls.balance.setValue(50);
        }
      }
    });
  }

  // Listen For Issue Date Changes
  private listenForIssueDateChanges(): void {
    this.invoiceForm.controls.issueDate.valueChanges.subscribe((issueDate) => {
      if (issueDate !== null) this.invoiceForm.controls.dueDate.setValue(this.calendar.getNext(issueDate, 'd', 7));
    });
  }

  // Get Invoice
  private getInvoice(): void {
    this.invoiceService.getInvoice(this.invoiceId).subscribe((invoice) => {
      this.invoice = invoice;
      this.getAcceptedProposal();
      this.setInvoiceFormValues();
      this.getPayments();
      this.getInvoicesForProject(invoice.projectId);
    });
  }

  private getInvoicesForProject(id: any): void {
    const params = {
      searchTerm: null,
      sortBy: 'i.invoice_number',
      sortDirection: 'DESC',
      limit: null,
      offset: null,
      'filter:proj.project_id': JSON.stringify([id])
    };
    this.invoiceService.getInvoices(params).subscribe((res) => {
      this.invoices = res.invoices;
    });
  };

  // Get Payments
  private getPayments(): void {
    let params = {
      searchTerm: null,
      sortBy: 'pay.payment_date',
      sortDirection: 'ASC',
      limit: null,
      offset: null,
      'filter:pay.invoice_id': JSON.stringify([this.invoiceId])
    };
    this.paymentService.getPayments(params).subscribe((res) => {
      this.payments = res.payments;
      const withoutRejects = res.payments.filter(payment => payment.status != 'REJECTED');

      this.totalDebitAmount = withoutRejects.reduce((acc, payment) => { return acc + ((payment.type == 'DEBIT') ? payment.amount : 0); }, 0);
      this.totalCreditAmount = withoutRejects.reduce((acc, payment) => { return acc + ((payment.type == 'CREDIT') ? payment.amount : 0); }, 0);
    });
  };

  // Get Accepted Proposal
  private getAcceptedProposal(): void {
    this.proposalService.getAcceptedProposalForProject(this.invoice.projectId).subscribe((acceptedProposal) => {
      this.acceptedProposal = acceptedProposal;

      if (this.invoice.balanceType == 'PERCENT') {
        let realPercentage = 0;
        if (this.invoice.amount != ((this.invoice.amount / this.acceptedProposal.total) * 100)) {
          realPercentage = ((this.invoice.amount / this.acceptedProposal.total) * 100)
        } else {
          realPercentage = this.invoice.balance;
        }

        this.invoiceForm.controls.balance.setValue(realPercentage.toFixed(2));
      }
    });
  }

  // Set Invoice Form Values
  private setInvoiceFormValues(): void {
    this.invoiceForm.controls.balanceType.setValue(this.invoice.balanceType);
    this.invoiceForm.controls.balance.setValue(this.invoice.balance);
    this.invoiceForm.controls.issueDate.setValue(this.dateParserFormatter.parse(this.invoice.issueDate));
    this.invoiceForm.controls.dueDate.setValue(this.dateParserFormatter.parse(this.invoice.dueDate));
    this.invoiceForm.controls.status.setValue(this.invoice.status);

    if (this.invoice.status == 'PAID') {
      this.invoiceForm.disable();
      this.isPaid = true;
    }
  }

  // Update Invoice
  updateInvoice(): void {
    if (this.invoiceForm.valid) {
      const invoice = {
        id: this.invoiceId,
        projectId: this.invoice.projectId,
        balanceType: this.invoiceForm.value.balanceType,
        balance: this.invoiceForm.value.balance,
        issueDate: this.dateParserFormatter.format(this.invoiceForm.value.issueDate),
        dueDate: this.dateParserFormatter.format(this.invoiceForm.value.dueDate),
        status: this.invoiceForm.value.status
      };
      this.invoiceService.updateInvoice(invoice).subscribe(() => {
        this.alertService.showSuccessAlert('Invoice Updated');
        this.getInvoice();
      });
    } else {
      this.invoiceForm.markAllAsTouched();
    }
  }

  // Delete Invoice
  deleteInvoice(): void {
    this.invoiceService.deleteInvoice(this.invoiceId).subscribe(() => {
      this.location.back();
    });
  }

  generateBalance() {
    this.invoiceForm.controls.balanceType.setValue('DOLLAR');

    const balanceType = this.invoiceForm.controls.balanceType.value;
    const paidAndPendingInvoices = this.invoices.filter(invoice => (invoice.status != 'CANCELED'));

    const totalInvoicesBalance = this.invoices.length == 0 ? 0 : this.summ(paidAndPendingInvoices, 'amount');
    const balancePercentage = (totalInvoicesBalance / this.acceptedProposal.total) * 100;

    let remainingBalanceFromOtherInvoice = this.acceptedProposal.total - totalInvoicesBalance;
    this.remainingBalance = this.invoice.amountDue + remainingBalanceFromOtherInvoice;

    //this.invoiceAmount = this.remainingBalance;

    if (balanceType == 'PERCENT') {
      this.invoiceForm.controls.balance.setValue(((this.remainingBalance * 100) / this.acceptedProposal.total).toFixed(2))
    } else if (balanceType == 'DOLLAR') {
      this.invoiceForm.controls.balance.setValue(Math.round((this.remainingBalance + Number.EPSILON) * 100) / 100)// for rounding 2 decimal places
    }

  }

  summ = (arr: any[], property: string) => arr.reduce(function (acc, obj) { return acc + obj[property]; }, 0);

  // Invoice Form Accessors
  get balance() { return this.invoiceForm.controls.balance; }
  get issueDate() { return this.invoiceForm.controls.issueDate; }
  get dueDate() { return this.invoiceForm.controls.dueDate; }

  // View Invoice
  viewInvoice(): void {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/invoices/${this.invoiceId}/view`]));
    window.open(url);
  }

  openSendSmsModal():void {
    const modalRef = this.modalService.open(SendSmsDialogComponent);
    modalRef.componentInstance.clientId = this.invoice.clientId;
    modalRef.componentInstance.proposalId = this.invoice.id;
    modalRef.componentInstance.contact = this.invoice.contacts;
    modalRef.componentInstance.clientName = this.invoice.clientName;
    modalRef.componentInstance.twilioClient = this.twilioClient;
    modalRef.componentInstance.defaultMessage = 'You have a new invoice from <OrgName>. Click here to pay: <LINK>';
    modalRef.componentInstance.sent.subscribe((res) => {
      this.alertService.showSuccessAlert('Message sent');
    });
  }

}
