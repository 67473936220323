<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
      <div class="d-grid gap-2 d-flex navbar-brand">
        <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom" class="btn btn-primary backBtn" aria-label="Back">
          <fa-icon [icon]="faAngleLeft"></fa-icon>
        </button>
        <h2 class="me-md-0 me-4 mobileHeading">Contact List</h2>
      </div>
      <div class="row">
        <div class="col-md-auto col-xs-12">
          <input type="search" class="form-control" placeholder="Search Contact List" [value]="storedSearchValue" (keyup)="tableControls.search($event.target.value);" (search)="tableControls.search($event.target.value);">
        </div>
        <div class="col-auto">
          <div ngbDropdown class="d-inline-block" display="dynamic" placement="bottom-left">
            <button id="INVOICES_DROPDOWN" type="button" class="btn btn-primary mt-md-0 mt-1" ngbDropdownToggle>Options</button>
            <div ngbDropdownMenu aria-labelledby="INVOICES_DROPDOWN">
              <button ngbDropdownItem (click)="downloadContact();">
                <fa-icon class="button-icon-left" [icon]="faFileDownload"></fa-icon>
                <span>Export</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-auto ms-2" *ngIf="isMobile">
          <div class="sort"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>
        </div>
       <!--  <div class="col-auto ms-2" *ngIf="isMobile">
          <div class="filter"><ng-container *ngTemplateOutlet="FILTER_DROPDOWN"></ng-container></div>
        </div> -->
      </div>
    </nav>
    <div class="table-responsive mt-3">
      <table *ngIf="!isMobile">
        <thead>
          <tr>
            <!-- Invoice # -->
            <th (click)="tableControls.sort('c.contact_first_name');">
              First Name
              <fa-icon *ngIf="tableControls.isSortedBy('c.contact_first_name')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
            </th>
            <!-- Client -->
            <th (click)="tableControls.sort('c.contact_last_name');">
              Last Name
              <fa-icon *ngIf="tableControls.isSortedBy('c.contact_last_name')" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
            </th>
            <!-- Project -->
            <th>
              Number
            </th>
            <th>
              Email
            </th>
            <th>
              <div class="d-flex" style="position: relative;">
                <div class="w-100 px-3" (click)="tableControls.sort('c.client_name');">
                  Client Name
                </div>
                <fa-icon *ngIf="tableControls.isSortedBy('c.client_name')" class="header-sort-container" [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)"></fa-icon>
              </div>
            </th>
            <!-- Amount -->
            <th>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let contact of contacts">
            <td>{{contact.firstName}}</td>
            <td>{{contact.lastName}}</td>
            <td>{{contact.phone}}</td>
            <td>{{contact.email}}</td>
            <td>{{contact.clientName}}</td>
            <td>
                <span class="ms-auto" style="cursor: pointer;" [ngbPopover]="!hasSmsAndCalls ? smsAndCallInfo: null" triggers="click">
                  <button *ngIf="hasSmsAndCalls" (click)="openCallDialerDialog(contact)" class="btn btn-primary" type="button">
                    <fa-icon class="button-icon-center" [icon]="faPhone"></fa-icon>
                  </button>
                </span>
                <span class="ms-auto" style="cursor: pointer;" [ngbPopover]="!hasSmsAndCalls ? smsAndCallInfo: null" triggers="click">
                  <button *ngIf="hasSmsAndCalls" (click)="openSmsDialog(contact)"  class="btn btn-primary ms-2" type="button">
                      <fa-icon class="button-icon-center" [icon]="faSms"></fa-icon>
                  </button>
                </span>
                <button (click)="openEmailDialog(contact)" class="btn btn-primary ms-2" type="button">
                  <fa-icon class="button-icon-center" [icon]="faEnvelope"></fa-icon>
                </button>
                <button [ngbPopover]="moreOptionsPopOver" triggers="manual" autoClose="outside" class="btn btn-primary ms-2" type="button" #moreOptions="ngbPopover" (click)="$event.stopPropagation(); popOverOptions(moreOptions, contact);">
                    <fa-icon class="button-icon-center" [icon]="faEllipsis"></fa-icon>
                </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="scrollable" *ngIf="isMobile">
        <ul class="list-group">
            <li *ngFor="let contact of contacts" class="list-group-item" style="cursor: pointer;">
                <div class="d-flex list-group-container">
                    <div>
                        <strong>{{contact.firstName}} {{contact.lastName}}</strong>
                        <div><strong>Phone: </strong>{{contact.phone}}</div>
                        <div><strong>Email: </strong>{{contact.email}}</div>
                        <div><strong>Client: </strong>{{contact.clientName}}</div>
                        <div class="list-button">
                          <span style="cursor: pointer;" [ngbPopover]="!hasSmsAndCalls ? smsAndCallInfo: null" triggers="click">
                            <button *ngIf="hasSmsAndCalls" (click)="openCallDialerDialog(contact)" class="btn btn-primary w-100" type="button">
                              <fa-icon class="button-icon-center" [icon]="faPhone"></fa-icon>&nbsp;Call
                            </button>
                          </span>
                          <span style="cursor: pointer;" [ngbPopover]="!hasSmsAndCalls ? smsAndCallInfo: null" triggers="click">
                            <button *ngIf="hasSmsAndCalls" (click)="openSmsDialog(contact)"  class="btn btn-primary w-100" type="button">
                                <fa-icon class="button-icon-center" [icon]="faSms"></fa-icon>&nbsp;SMS
                            </button>
                          </span>
                          <button (click)="openEmailDialog(contact)" class="btn btn-primary" type="button">
                            <fa-icon class="button-icon-center" [icon]="faEnvelope"></fa-icon>&nbsp;Email
                          </button>
                          <button [ngbPopover]="moreOptionsPopOver" triggers="manual" autoClose="outside" class="btn btn-primary" type="button" #moreOptions="ngbPopover" (click)="$event.stopPropagation(); popOverOptions(moreOptions, contact);">
                              <fa-icon class="button-icon-center" [icon]="faEllipsis"></fa-icon>
                          </button>
                      </div>
                    </div>
                </div>
            </li>
            <li *ngIf="contacts.length == 0" class="list-group-item">No Contacts</li>
        </ul>
      </div>
    </div>
    <div class="row table-controls">
      <div *ngIf="tableControls.isNotDefault() && !isMobile" class="col-auto mt-3 me-2 me-md-0">
        <button type="button" class="btn btn-outline-danger" (click)="tableControls.resetTableConfiguration();">
          <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
          <span>Reset</span>
        </button>
      </div>
      <div class="col mt-3">
        <div ngbDropdown placement="top-left" class="d-inline-block">
          <button class="btn btn-outline-primary" ngbDropdownToggle><span *ngIf="!isMobile">Page Size: </span> {{tableControls.getLimit()}}</button>
          <div ngbDropdownMenu class="page-size-dropdown" *ngIf="!isMobile">
            <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
            <button ngbDropdownItem (click)="tableControls.setLimit(50);">50</button>
            <button ngbDropdownItem (click)="tableControls.setLimit(100);">100</button>
          </div>
          <div ngbDropdownMenu class="page-size-dropdown" *ngIf="isMobile">
            <button ngbDropdownItem (click)="tableControls.setLimit(5);">5</button>
            <button ngbDropdownItem (click)="tableControls.setLimit(10);">10</button>
            <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
          </div>
        </div>
      </div>
      <div class="col my-3">
        <ngb-pagination class="d-flex justify-content-end" *ngIf="contactsCount > 0" [page]="tableControls.getCurrentPage()" [pageSize]="tableControls.getLimit()" [collectionSize]="contactsCount" [boundaryLinks]="true" [maxSize]="(isMobile) ? 1 : 10" [ellipses]="true" [rotate]="true" (pageChange)="tableControls.setCurrentPage($event);"></ngb-pagination>
      </div>
    </div>
  </div>

  <!-- Filter -->
  <ng-template #popTitleFilter>Filter</ng-template>
  <ng-template let-column="column" #popContentFilter>
    <div *ngFor="let element of this.columnFilterValues.get(column)" class="form-check">
      <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="tableControls.toggleFilter(column, element.value);" [checked]="tableControls.filterExists(column, element.value)">
      <label [for]="element.randomId" class="form-check-label">{{element.name}}</label>
    </div>
  </ng-template>

  <ng-template #moreOptionsPopOver let-popover="popover" let-contact="contact">
    <div class="d-flex flex-column">
        <button type="button" class="btn btn-sm btn-primary" routerLink="/clients/{{contact.clientId}}">
            <fa-icon [icon]="faUser" class="button-icon-left"></fa-icon>
            <span>Profile</span>
        </button>
    </div>
  </ng-template>

  <ng-template #smsAndCallInfo>
    <p>
      You need to enable SMS/Call Feature on the setting’s Tab to be able to use this feature
    </p>
  </ng-template>

  <ngx-spinner>
    <p class="loading-spinner">Loading...</p>
  </ngx-spinner>
<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block" [autoClose]="true" placement="bottom-right">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>

<!-- Filter Dropdown -->
<ng-template #FILTER_DROPDOWN>
  <div ngbDropdown class="d-inline-block filter-container" autoClose="outside" placement="bottom-right">
      <span *ngIf="activeFilterCount > 0" class="custom-badge-lg">{{activeFilterCount}}</span>
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Filter">
          <fa-icon [icon]="faFilter"></fa-icon>
      </button>
      <div ngbDropdownMenu>
          <!-- Filter Options -->
          <div *ngFor="let filter of filterColumns">
            <button ngbDropdownItem class="button-filter" (click)="getDistinctColumnValues(filter.value); filters.toggle();">{{filter.label}}</button>
            <div class="px-4 py-1" #filters="ngbCollapse" [ngbCollapse]="filter.value === selectedFilterColumn ? false : true">
                <div class="d-flex flex-column">
                    <div *ngFor="let element of columnFilterValues.get(filter.value);" class="form-check form-check-inline">
                        <input [id]="element.randomId" class="form-check-input" type="checkbox" (change)="toggleFilter(filter.value, element.value);" [checked]="filterExists(filter.value, element.value)">
                        <label [for]="element.randomId" class="form-check-label" style="white-space: nowrap;">{{element.name}}</label>
                    </div>
                </div>
            </div>
          </div>

          <!-- Divider -->
          <div class="dropdown-divider"></div>
          <!-- Reset -->
          <button class="reset-button" ngbDropdownItem (click)="resetActiveFilters(); selectedFilterColumn = ''">Reset</button>
      </div>
  </div>
</ng-template>
