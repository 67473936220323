import { Component, OnInit } from '@angular/core';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from '../alert.service';
import { SettingService } from '../setting.service';

@Component({
  selector: 'app-settings-proposals-agreement-types',
  templateUrl: './settings-proposals-agreement-types.component.html',
  styleUrls: ['./settings-proposals-agreement-types.component.css']
})
export class SettingsProposalsAgreementTypesComponent implements OnInit {

  // Properties
  multiAgreementTypeOptions: any;

  // Font Awesome Properties
  faSave = faSave;

  constructor(private settingService: SettingService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.getMultiAgreementTypeOptions();
  }

  // Get Multi Agreement Type Options
  private getMultiAgreementTypeOptions(): void {
    this.settingService.getMultiAgreementTypeOptions().subscribe((options) => {
      this.multiAgreementTypeOptions = options;
    });
  }

  // Form Input Changed
  formInputChanged(key: string, property: string, value: any): void {
    this.multiAgreementTypeOptions[key][property] = value;
    const title = this.multiAgreementTypeOptions[key].title;
    const acronym = this.multiAgreementTypeOptions[key].acronym;
    if ((!title || title.length == 0) || (!acronym || acronym.length == 0)) this.multiAgreementTypeOptions[key].isEnabled = false;
  }

  // Multi Agreement Type Option Is Valid
  multiAgreementTypeOptionIsValid(key: string): boolean {
    const title = this.multiAgreementTypeOptions[key].title;
    const acronym = this.multiAgreementTypeOptions[key].acronym;
    return (title !== null && title.length > 0) && (acronym !== null && acronym.length > 0);
  }

  // Update Multi Agreement Type Options
  updateMultiAgreementTypeOptions(): void {
    this.settingService.updateMultiAgreementTypeOptions(this.multiAgreementTypeOptions).subscribe(() => {
      this.alertService.showSuccessAlert('Agreement Types Updated');
      this.getMultiAgreementTypeOptions();
    });
  }
}
