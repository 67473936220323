<div class="main-wrapper">
    <div class="d-flex align-items-center position-relative min-vh-100 login-cover">
        <!-- start left panel  -->
        <div class="col-lg-5 col-xl-5 d-none d-lg-flex align-items-center px-0 bg-img"></div>
        <!-- end left panel  -->
        <div class="container">
            <div class="row justify-content-center justify-content-lg-start">
                <div class="col-md-8 col-lg-8 col-xl-5 m-auto">
                    <div>
                        <div class="mt-3 text-center">
                            <picture>
                                <source type="image/webp" srcset="../assets/TinselLogo.webp">
                                <source type="image/png" srcset="../assets/TinselLogo.png">
                                <img src="../assets/TinselLogo.png" width="40%" class="logo" alt="Tinsel Logo" loading="eager">
                            </picture>
                        </div>
                        <h2 class="font-weight-normal text-center mb-0">CRM Login</h2>
                        <!-- <p class="mt-3">Login to your account.</p> -->
                    </div>
                    <form [formGroup]="loginForm" class="mt-3" (ngSubmit)="login();">
                        <div class="mb-3">
                            <!-- <label for="LOGIN_EMAIL">Email</label> -->
                            <input id="LOGIN_EMAIL" type="email" class="form-control" formControlName="email" placeholder="Email" required autofocus autocapitalize="off" autocomplete="email" [class.is-invalid]="email.invalid && (email.dirty || email.touched)">
                        </div>
                        <div class="mb-3">
                            <!-- <label for="LOGIN_PASSWORD">Password</label> -->
                            <input id="LOGIN_PASSWORD" type="password" class="form-control" formControlName="password" placeholder="Password" required autocomplete="current-password" [class.is-invalid]="password.invalid && (password.dirty || password.touched)">
                        </div>
                        <div class="row">
                            <div class="col">
                                <button type="submit" class="btn btn-primary my-2">Login</button>
                            </div>
                            <div class="col align-self-center d-flex justify-content-end">
                                <a routerLink="/forgot">Forgot Password?</a>
                            </div>
                        </div>
                        <div class="text-center text-small mt-3">
                            <span>Looking for the <a [href]="employeePortalUrl">Tinsel Employee Portal</a>?</span><br>
                        </div>
                        <div class="text-center text-small mt-3">
                            <span>Not signed up? <a routerLink="/register">Register for an account here</a>.</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>