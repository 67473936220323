<div class="content-container">
    <nav class="navbar navbar-white bg-white sticky-top">
        <div class="d-grid gap-2 d-flex navbar-brand">
            <button (click)="this.location.back();" type="button" ngbTooltip="Back" placement="bottom"
                class="btn btn-primary backBtn" aria-label="Back">
                <fa-icon [icon]="faAngleLeft"></fa-icon>
            </button>
            <h2 class="mobileHeading">Storage Locations</h2>
        </div>
        <div class="row">
            <div class="col-auto">
                <button type="button" class="btn btn-primary" (click)="downloadReport();">
                    <fa-icon [icon]="faFileDownload"></fa-icon><span class="ms-2 d-none d-md-inline">Export</span>
                </button>
            </div>
        </div>
    </nav>
    <div class="row mt-3">
        <div class="card p-0">
            <div class="card-body">
                <h5 class="card-title">Description</h5>
                <p class="card-text m-0">This report can be used to quickly locate the storage location for a project.
                </p>
            </div>
        </div>
    </div>
    <div class="row my-3">
        <div [ngClass]="isMobile ? 'col-6 align-content-end': 'col-10'">
          <div class="sort" *ngIf="isMobile"> <ng-container *ngTemplateOutlet="SORT_DROPDOWN"></ng-container></div>
        </div>
        <div [ngClass]="isMobile ? 'col-6': 'col-2'">
            <form [formGroup]="reportFormGroup">
            <label for="yearFilter" class="control-label">Project Year:</label>
            <select id="yearFilter" class="form-select" formControlName="projectYear">
                <option [ngValue]="0">ALL</option>
                <option [ngValue]="2023">2023</option>
                <option [ngValue]="2022">2022</option>
                <option [ngValue]="2021">2021</option>
            </select>
            </form>
        </div>

    </div>
    <div class="table-responsive mt-3">
        <table *ngIf="!isMobile">
            <thead>
                <tr>
                    <!-- Client -->
                    <th (click)="tableControls.sort('c.client_name');">
                        Client
                        <fa-icon *ngIf="tableControls.isSortedBy('c.client_name')"
                            [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                    </th>
                    <!-- Project -->
                    <th (click)="tableControls.sort('proj.project_name');">
                        Project
                        <fa-icon *ngIf="tableControls.isSortedBy('proj.project_name')"
                            [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                    </th>
                    <th>Stage</th>
                    <!-- Storage Location -->
                    <th (click)="tableControls.sort('proj.project_storage_location');">
                        Storage Location
                        <fa-icon *ngIf="tableControls.isSortedBy('proj.project_storage_location')"
                            [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                    </th>
                    <!-- Install Date -->
                    <th (click)="tableControls.sort('install_date');">
                        Install Date
                        <fa-icon *ngIf="tableControls.isSortedBy('install_date')"
                            [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                    </th>
                    <!-- Removal Date -->
                    <th (click)="tableControls.sort('remove_date');">
                        Removal Date
                        <fa-icon *ngIf="tableControls.isSortedBy('remove_date')"
                            [icon]="(tableControls.isSortedASC() ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let entry of entries">
                    <td data-label="Client">{{entry.clientName}}</td>
                    <td data-label="Project">{{entry.projectName}}</td>
                    <td data-label="Stage">{{entry.projectStage | projectStage}}</td>
                    <td data-label="Storage Location">
                        <span *ngIf="entry.projectStorageLocation">{{entry.projectStorageLocation}}</span>
                        <span *ngIf="!entry.projectStorageLocation">N/A</span>
                    </td>
                    <td data-label="Install Date">
                        <span *ngIf="entry.installDate">{{entry.installDate | formatDateTime: true}}</span>
                        <span *ngIf="!entry.installDate">N/A</span>
                    </td>
                    <td data-label="Removal Date">
                        <span *ngIf="entry.removeDate">{{entry.removeDate | formatDateTime: true}}</span>
                        <span *ngIf="!entry.removeDate">N/A</span>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="scrollable" *ngIf="isMobile">
          <ul class="list-group">
              <li *ngFor="let entry of entries" class="list-group-item">
                  <div class="d-flex list-group-container">
                      <div>
                          <strong>{{entry.clientName}}</strong>
                          <div>{{entry.projectName}}</div>
                          <div><strong>Stage: </strong>{{entry.projectStage | projectStage}}</div>
                          <div>
                            <strong>Storage Location: </strong>
                            <span *ngIf="entry.projectStorageLocation">{{entry.projectStorageLocation}}</span>
                            <span *ngIf="!entry.projectStorageLocation">N/A</span>
                          </div>
                          <div>
                            <strong>Install Date: </strong>
                            <span *ngIf="entry.installDate">{{entry.installDate | formatDateTime: true}}</span>
                            <span *ngIf="!entry.installDate">N/A</span>
                          </div>
                          <div>
                            <strong>Removal Date: </strong>
                            <span *ngIf="entry.removeDate">{{entry.removeDate | formatDateTime: true}}</span>
                            <span *ngIf="!entry.removeDate">N/A</span>
                          </div>
                      </div>
                  </div>
              </li>
              <li *ngIf="entries.length == 0" class="list-group-item">No Entries</li>
          </ul>
        </div>
    </div>
    <div class="row table-controls">
        <div *ngIf="tableControls.isNotDefault() && !isMobile" class="col-auto mt-3 me-2 me-md-0">
            <button type="button" class="btn btn-outline-danger" (click)="tableControls.resetTableConfiguration();">
                <fa-icon class="button-icon-left" [icon]="faRotateLeft"></fa-icon>
                <span>Reset</span>
            </button>
        </div>
        <div class="col mt-3">
            <div ngbDropdown placement="top-left" class="d-inline-block">
                <button class="btn btn-outline-primary" ngbDropdownToggle><span *ngIf="!isMobile">Page Size: </span> {{tableControls.getLimit()}}</button>
                <div ngbDropdownMenu class="page-size-dropdown" *ngIf="!isMobile">
                  <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(50);">50</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(100);">100</button>
                </div>
                <div ngbDropdownMenu class="page-size-dropdown" *ngIf="isMobile">
                  <button ngbDropdownItem (click)="tableControls.setLimit(5);">5</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(10);">10</button>
                  <button ngbDropdownItem (click)="tableControls.setLimit(20);">20</button>
                </div>
            </div>
        </div>
        <div class="col my-3">
            <ngb-pagination class="d-flex justify-content-end" *ngIf="entryCount > 0" [page]="tableControls.getCurrentPage()"
                [pageSize]="tableControls.getLimit()" [collectionSize]="entryCount" [boundaryLinks]="true"
                [maxSize]="(isMobile) ? 3 : 10" [ellipses]="true" [rotate]="true"
                (pageChange)="tableControls.setCurrentPage($event);"></ngb-pagination>
        </div>
    </div>
</div>

<!-- Sort Dropdown -->
<ng-template #SORT_DROPDOWN>
  <div ngbDropdown class="d-inline-block sorting" [autoClose]="true" placement="bottom-left">
      <button class="btn btn-outline-primary mt-1" ngbDropdownToggle ngbTooltip="Sort">
          <fa-icon [icon]="faSortAmountDownAlt"></fa-icon>
      </button>
      <!-- Sort Options -->
      <div ngbDropdownMenu>
          <button *ngFor="let sort of sortOptions" ngbDropdownItem (click)="sorting(sort.value);">
            {{sort.label}}
            <fa-icon *ngIf="sortBy == sort.value" [icon]="(sortDirection == 'ASC' ? faSortDown : faSortUp)" class="sortButton"></fa-icon>
          </button>
      </div>
  </div>
</ng-template>
