import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailLog } from '../email-log';
import { EmailViewComponent } from '../email-view/email-view.component';
import { LogService } from '../log.service';
import { LoaderService } from '../shared/services/loader.service';

@Component({
  selector: 'app-settings-emails-logs',
  templateUrl: './settings-emails-logs.component.html',
  styleUrls: ['./settings-emails-logs.component.css']
})
export class SettingsEmailsLogsComponent implements OnInit {

  // Properties
  emailLogs: EmailLog[] = [];
  startDate: string;
  endDate: string;

  constructor(private logService: LogService,
    private modalService: NgbModal,
    private loaderService: LoaderService) { }

  ngOnInit(): void {

  }

  // Date Changed
  dateChanged(event: { startDate: string, endDate: string, isAuto: boolean }): void {
    this.loaderService.showSpinner();
    this.startDate = event.startDate;
    this.endDate = event.endDate;
    this.getEmailLogs();
  }

  // Get Email Logs
  private getEmailLogs(): void {
    this.logService.getEmailLogs(this.startDate, this.endDate).subscribe(
      {
        next: (logs) => {
          this.emailLogs = logs;
          this.loaderService.hideSpinner(700);
        },
        error: () => {
          this.loaderService.hideSpinner(700);
        }
      }
      );
  }

  // Opem Email View Modal
  openEmailViewModal(emailLog: EmailLog): void {
    const modalRef = this.modalService.open(EmailViewComponent);
    modalRef.componentInstance.emailLog = emailLog;
  }
}
