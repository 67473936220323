<div class="row">
    <div class="col-sm-12">
        <form [formGroup]="numberNameForm">
            <div class="mb-3">
                <div class="row mb-2">
                    <div class="col d-flex">
                        <label class="my-auto">Number</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <input placeholder="Ex. Philadelphia" formControlName="numberName" class="form-control" type="text">
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>